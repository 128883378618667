/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventPartySeating,
    JsonEventPartySeatingFromJSON,
    JsonEventPartySeatingFromJSONTyped,
    JsonEventPartySeatingToJSON,
} from './JsonEventPartySeating';

/**
 * 
 * @export
 * @interface JsonEventTicketInfo
 */
export interface JsonEventTicketInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventTicketInfo
     */
    allowedActions?: Array<JsonEventTicketInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    areas?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    contingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    eventDays?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventTicketInfo
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    formatCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    reason?: string;
    /**
     * 
     * @type {JsonEventPartySeating}
     * @memberof JsonEventTicketInfo
     */
    seating?: JsonEventPartySeating;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    status?: JsonEventTicketInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonEventTicketInfo
     */
    ticketId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketInfo
     */
    ticketType?: JsonEventTicketInfoTicketTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventTicketInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventTicketInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}/**
* @export
* @enum {string}
*/
export enum JsonEventTicketInfoTicketTypeEnum {
    SiwiVoucher = 'SIWI_VOUCHER',
    EgTicket = 'EG_TICKET'
}

export function JsonEventTicketInfoFromJSON(json: any): JsonEventTicketInfo {
    return JsonEventTicketInfoFromJSONTyped(json, false);
}

export function JsonEventTicketInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventTicketInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'areas': !exists(json, 'areas') ? undefined : json['areas'],
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'contingentOwner': !exists(json, 'contingentOwner') ? undefined : json['contingentOwner'],
        'eventDays': !exists(json, 'eventDays') ? undefined : json['eventDays'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'seating': !exists(json, 'seating') ? undefined : JsonEventPartySeatingFromJSON(json['seating']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'ticketType': !exists(json, 'ticketType') ? undefined : json['ticketType'],
    };
}

export function JsonEventTicketInfoToJSON(value?: JsonEventTicketInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'areas': value.areas,
        'barcode': value.barcode,
        'companyName': value.companyName,
        'contingentOwner': value.contingentOwner,
        'eventDays': value.eventDays,
        'eventId': value.eventId,
        'firstName': value.firstName,
        'formatCode': value.formatCode,
        'lastName': value.lastName,
        'reason': value.reason,
        'seating': JsonEventPartySeatingToJSON(value.seating),
        'status': value.status,
        'ticketId': value.ticketId,
        'ticketType': value.ticketType,
    };
}

