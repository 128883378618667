/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonReplyData,
    JsonReplyDataFromJSON,
    JsonReplyDataFromJSONTyped,
    JsonReplyDataToJSON,
} from './JsonReplyData';
import {
    JsonReplyProcessData,
    JsonReplyProcessDataFromJSON,
    JsonReplyProcessDataFromJSONTyped,
    JsonReplyProcessDataToJSON,
} from './JsonReplyProcessData';

/**
 * 
 * @export
 * @interface JsonInviteReply
 */
export interface JsonInviteReply {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonInviteReply
     */
    allowedActions?: Array<JsonInviteReplyAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonInviteReply
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReply
     */
    createdBy?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteReply
     */
    infoIsValid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReply
     */
    inviteId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteReply
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    newFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    newLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    newPhotoCvResult?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    newPhotoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReply
     */
    newPhotoId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReply
     */
    newUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    processAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReply
     */
    processBy?: number;
    /**
     * 
     * @type {JsonReplyProcessData}
     * @memberof JsonInviteReply
     */
    processData?: JsonReplyProcessData;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    replyAt?: string;
    /**
     * 
     * @type {JsonReplyData}
     * @memberof JsonInviteReply
     */
    replyData?: JsonReplyData;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReply
     */
    replyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    replyNote?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    replyType?: JsonInviteReplyReplyTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteReply
     */
    skipEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    status?: JsonInviteReplyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReply
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReply
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyReplyTypeEnum {
    Accept = 'ACCEPT',
    Reject = 'REJECT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyStatusEnum {
    Deleted = 'DELETED',
    Draft = 'DRAFT',
    Sent = 'SENT',
    Rejected = 'REJECTED',
    Accepted = 'ACCEPTED'
}

export function JsonInviteReplyFromJSON(json: any): JsonInviteReply {
    return JsonInviteReplyFromJSONTyped(json, false);
}

export function JsonInviteReplyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonInviteReply {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'infoIsValid': !exists(json, 'infoIsValid') ? undefined : json['infoIsValid'],
        'inviteId': !exists(json, 'inviteId') ? undefined : json['inviteId'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'newFirstName': !exists(json, 'newFirstName') ? undefined : json['newFirstName'],
        'newLastName': !exists(json, 'newLastName') ? undefined : json['newLastName'],
        'newPhotoCvResult': !exists(json, 'newPhotoCvResult') ? undefined : json['newPhotoCvResult'],
        'newPhotoGuid': !exists(json, 'newPhotoGuid') ? undefined : json['newPhotoGuid'],
        'newPhotoId': !exists(json, 'newPhotoId') ? undefined : json['newPhotoId'],
        'newUserId': !exists(json, 'newUserId') ? undefined : json['newUserId'],
        'processAt': !exists(json, 'processAt') ? undefined : json['processAt'],
        'processBy': !exists(json, 'processBy') ? undefined : json['processBy'],
        'processData': !exists(json, 'processData') ? undefined : JsonReplyProcessDataFromJSON(json['processData']),
        'replyAt': !exists(json, 'replyAt') ? undefined : json['replyAt'],
        'replyData': !exists(json, 'replyData') ? undefined : JsonReplyDataFromJSON(json['replyData']),
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'replyNote': !exists(json, 'replyNote') ? undefined : json['replyNote'],
        'replyType': !exists(json, 'replyType') ? undefined : json['replyType'],
        'skipEmail': !exists(json, 'skipEmail') ? undefined : json['skipEmail'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonInviteReplyToJSON(value?: JsonInviteReply | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'infoIsValid': value.infoIsValid,
        'inviteId': value.inviteId,
        'isActive': value.isActive,
        'newFirstName': value.newFirstName,
        'newLastName': value.newLastName,
        'newPhotoCvResult': value.newPhotoCvResult,
        'newPhotoGuid': value.newPhotoGuid,
        'newPhotoId': value.newPhotoId,
        'newUserId': value.newUserId,
        'processAt': value.processAt,
        'processBy': value.processBy,
        'processData': JsonReplyProcessDataToJSON(value.processData),
        'replyAt': value.replyAt,
        'replyData': JsonReplyDataToJSON(value.replyData),
        'replyId': value.replyId,
        'replyNote': value.replyNote,
        'replyType': value.replyType,
        'skipEmail': value.skipEmail,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

