/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonEventPartyArticle
 */
export interface JsonEventPartyArticle {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventPartyArticle
     */
    allowedActions?: Array<JsonEventPartyArticleAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    articleTypeId?: number;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventPartyArticle
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyArticle
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    eventPartyArticleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    eventPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    prefArticleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    prefCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    recArticleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    recCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyArticle
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticle
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyArticleAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventPartyArticleFromJSON(json: any): JsonEventPartyArticle {
    return JsonEventPartyArticleFromJSONTyped(json, false);
}

export function JsonEventPartyArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'articleTypeId': !exists(json, 'articleTypeId') ? undefined : json['articleTypeId'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventPartyArticleId': !exists(json, 'eventPartyArticleId') ? undefined : json['eventPartyArticleId'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'prefArticleId': !exists(json, 'prefArticleId') ? undefined : json['prefArticleId'],
        'prefCnt': !exists(json, 'prefCnt') ? undefined : json['prefCnt'],
        'recArticleId': !exists(json, 'recArticleId') ? undefined : json['recArticleId'],
        'recCnt': !exists(json, 'recCnt') ? undefined : json['recCnt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventPartyArticleToJSON(value?: JsonEventPartyArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'articleTypeId': value.articleTypeId,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventPartyArticleId': value.eventPartyArticleId,
        'eventPartyId': value.eventPartyId,
        'prefArticleId': value.prefArticleId,
        'prefCnt': value.prefCnt,
        'recArticleId': value.recArticleId,
        'recCnt': value.recCnt,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

