/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventPartyDay,
    ApiResultOfJsonEventPartyDayFromJSON,
    ApiResultOfJsonEventPartyDayToJSON,
    ApiResultOfJsonEventPartyDayInfo,
    ApiResultOfJsonEventPartyDayInfoFromJSON,
    ApiResultOfJsonEventPartyDayInfoToJSON,
    ApiResultOfListOfJsonEventPartyDayInfo,
    ApiResultOfListOfJsonEventPartyDayInfoFromJSON,
    ApiResultOfListOfJsonEventPartyDayInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventPartyDay,
    JsonEventPartyDayFromJSON,
    JsonEventPartyDayToJSON,
} from '../models';

export interface GetEventPartyDayCountUsingGETRequest {
    eventId?: number;
    partyId?: number;
    search?: string;
}

export interface GetEventPartyDayInfoUsingGETRequest {
    eventPartyDayId: number;
}

export interface GetEventPartyDayListUsingGETRequest {
    eventId?: number;
    partyId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventPartyDayUsingGETRequest {
    eventPartyDayId: number;
}

export interface SaveEventPartyDayUsingPOSTRequest {
    json: JsonEventPartyDay;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventPartyDayControllerApi extends runtime.BaseAPI {

    /**
     * getEventPartyDayCount
     */
    async getEventPartyDayCountUsingGETRaw(requestParameters: GetEventPartyDayCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyId !== undefined) {
            queryParameters['partyId'] = requestParameters.partyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-day/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventPartyDayCount
     */
    async getEventPartyDayCountUsingGET(requestParameters: GetEventPartyDayCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventPartyDayCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyDayInfo
     */
    async getEventPartyDayInfoUsingGETRaw(requestParameters: GetEventPartyDayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventPartyDayInfo>> {
        if (requestParameters.eventPartyDayId === null || requestParameters.eventPartyDayId === undefined) {
            throw new runtime.RequiredError('eventPartyDayId','Required parameter requestParameters.eventPartyDayId was null or undefined when calling getEventPartyDayInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-day/info/{eventPartyDayId}`.replace(`{${"eventPartyDayId"}}`, encodeURIComponent(String(requestParameters.eventPartyDayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyDayInfoFromJSON(jsonValue));
    }

    /**
     * getEventPartyDayInfo
     */
    async getEventPartyDayInfoUsingGET(requestParameters: GetEventPartyDayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventPartyDayInfo> {
        const response = await this.getEventPartyDayInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyDayList
     */
    async getEventPartyDayListUsingGETRaw(requestParameters: GetEventPartyDayListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventPartyDayInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyId !== undefined) {
            queryParameters['partyId'] = requestParameters.partyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-day/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventPartyDayInfoFromJSON(jsonValue));
    }

    /**
     * getEventPartyDayList
     */
    async getEventPartyDayListUsingGET(requestParameters: GetEventPartyDayListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventPartyDayInfo> {
        const response = await this.getEventPartyDayListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyDay
     */
    async getEventPartyDayUsingGETRaw(requestParameters: GetEventPartyDayUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventPartyDay>> {
        if (requestParameters.eventPartyDayId === null || requestParameters.eventPartyDayId === undefined) {
            throw new runtime.RequiredError('eventPartyDayId','Required parameter requestParameters.eventPartyDayId was null or undefined when calling getEventPartyDayUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-day/{eventPartyDayId}`.replace(`{${"eventPartyDayId"}}`, encodeURIComponent(String(requestParameters.eventPartyDayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyDayFromJSON(jsonValue));
    }

    /**
     * getEventPartyDay
     */
    async getEventPartyDayUsingGET(requestParameters: GetEventPartyDayUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventPartyDay> {
        const response = await this.getEventPartyDayUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventPartyDay
     */
    async saveEventPartyDayUsingPOSTRaw(requestParameters: SaveEventPartyDayUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventPartyDay>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventPartyDayUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-party-day`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventPartyDayToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyDayFromJSON(jsonValue));
    }

    /**
     * saveEventPartyDay
     */
    async saveEventPartyDayUsingPOST(requestParameters: SaveEventPartyDayUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventPartyDay> {
        const response = await this.saveEventPartyDayUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
