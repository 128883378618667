import {
    JsonEventDayInfo,
    JsonFile,
    JsonInviteData,
    JsonInviteReply,
    JsonInviteReplyDay,
    JsonInviteReplyDayReplyTypeEnum,
    JsonInviteReplyExtra,
    JsonInviteReplyExtraSexEnum,
    JsonInviteReplyReplyTypeEnum,
    JsonPartyInfo,
    JsonReplyData
} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import {useFormikContext} from "formik";
import * as React from "react";
import {useState} from "react";
import {Button, Card, CardContent, Grid} from "@mui/material";
import {PlusOneRounded} from "@mui/icons-material";
import {RadioGroupField} from "../form/RadioGroupField";
import {dayDate, replyIsParkOptions} from "../../model/invite";
import RsvpInvitePartyBox from "./RsvpInvitePartyBox";
import RsvpInviteExtraPartyForm from "./RsvpInviteExtraPartyForm";
import RsvpInviteExtraSelectPartyForm from "./RsvpInviteExtraSelectPartyForm";
import {createOption} from "../../model/form";

const dayReplyTypeOptions = [
    createOption(JsonInviteReplyReplyTypeEnum.Accept, 'Ano, mám zájem se zúčastnit tento den'),
    createOption(JsonInviteReplyReplyTypeEnum.Reject, 'Ne, o účast tento den nemám zájem'),
];

const addExtra = (extra: JsonInviteReplyExtra, replyData?: JsonReplyData): JsonInviteReplyExtra[] => {
    const extras = [...(replyData?.extras || [])];
    let i: number | undefined;
    if (!!extra.extraId && extras) {
        i = extras.findIndex((e) => e.extraId === extra.extraId);
    }
    if (i !== undefined) {
        extras[i] = extra;
    } else {
        extra.extraId = extras.length + 1;
        extras.push(extra);
    }
    return extras;
}

type RsvpInviteEventDayProps = {
    inviteData?: JsonInviteData,
    values: JsonInviteReply,
    ed: JsonEventDayInfo,
    prevParties?: JsonPartyInfo[],
    onFileUpload: (file: File, replyValues: JsonInviteReply) => Promise<JsonFile | undefined>,
    onReplySave: (values: JsonInviteReply) => void,
    isDaysLocked: boolean
}

const RsvpInviteEventDay = (props: RsvpInviteEventDayProps) => {
    const {inviteData, values, ed, prevParties, onFileUpload, onReplySave, isDaysLocked} = props;
    const {replyData} = values;

    const t = useAppTranslation();
    const {setFieldValue} = useFormikContext();
    const [editExtra, setEditExtra] = useState<JsonInviteReplyExtra | undefined>(undefined);
    const [selectExtra, setSelectExtra] = useState<JsonInviteReplyExtra[] | undefined>(undefined);

    const replyDay: JsonInviteReplyDay = (ed.dayNo && replyData?.replyDays?.[ed.dayNo]) || {};

    const isAccepted = replyDay.replyType === JsonInviteReplyDayReplyTypeEnum.Accept;
    const extras: JSX.Element[] = [];

    replyData?.extras?.forEach((extra, i) => {
        if (ed.dayNo && extra.eventDayNos && extra.eventDayNos.indexOf(ed.dayNo) >= 0) {
            extras.push(<Grid key={i} item md={4} sm={6} xs={12}>
                <RsvpInvitePartyBox key={i} extra={extra}
                    onDelete={(extra) => {
                        if (ed.dayNo) {
                            extra.eventDayNos?.splice(extra.eventDayNos?.indexOf(ed.dayNo), 1);

                            const extras = [...(replyData?.extras || [])];
                            let i = extras.findIndex((e) => e.extraId === extra.extraId);
                            if (i !== undefined) {
                                extras[i] = extra;
                            }
                            setFieldValue('replyData.extras', extras);
                            onReplySave({...values, replyData: {...values.replyData, extras}});
                        }
                    }}
                    onEdit={(extra) => {
                        setEditExtra(extra);
                    }}
                />
            </Grid>)
        }
    });

    if (extras.length < (inviteData?.extraLimit || 0)) {
        const otherDayExtras = replyData?.extras?.filter((e) => e.eventDayNos && ed.dayNo && e.eventDayNos.indexOf(ed.dayNo) < 0) || [];
        if (prevParties?.length) {
            prevParties.forEach(p => {
                if (replyData?.extras?.find(e => e.partyId === p.partyId)) {
                    return;
                }
                otherDayExtras.push({
                    firstName: p.firstName,
                    lastName: p.lastName,
                    sex: (p.sex === undefined ? undefined : JsonInviteReplyExtraSexEnum[p.sex]),
                    photoGuid: p.photoGuid,
                    partyId: p.partyId
                });
            })
        }

        extras.push(<Grid key={'add'} item md={4} sm={6} xs={12}>
            <Card className={'rsvp-extra-match-box'}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant={'contained'} size={'small'} title={t('Přidat volitelnou doprovodnou osobu na tento den')}
                                color={'secondary'}
                                onClick={() => {
                                    if (otherDayExtras?.length) {
                                        setSelectExtra(otherDayExtras);
                                    } else if (ed.dayNo) {
                                        setEditExtra({eventDayNos: [ed.dayNo]})
                                    }
                                }}>
                                <span>{t('Přidat doprovod?')}</span>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', flexFlow: 'column', alignItems: 'flex-end'}}>
                            <PlusOneRounded sx={{fontSize: '45px !important', color: 'silver'}}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>);
    }

    return <Card className={'rsvp-day rsvp-day-' + replyDay.replyType}>
        <CardContent>
            <Grid container>
                <Grid item sm={1} xs={2} className={'rsvp-day-date'}>
                    {dayDate(ed)}
                </Grid>
                <Grid item sm={11} xs={10} className={'rsvp-day-info'}>
                    {ed.description
                        && <p className={'rsvp-day-desc'}>{ed.description}</p>}
                    {!(isDaysLocked && isAccepted) && <RadioGroupField options={dayReplyTypeOptions} name={'replyData.replyDays.' + ed.dayNo + '.replyType'}/>}
                    {isAccepted && !!inviteData?.isPark
                        && <RadioGroupField name={'replyData.replyDays.' + ed.dayNo + '.isParking'} options={replyIsParkOptions}/>}
                    {isAccepted && !!inviteData?.extraLimit && inviteData.extraLimit > 0
                        && <Grid container spacing={2} sx={{marginTop: '0'}}>
                            {extras}
						</Grid>}
                </Grid>
            </Grid>
        </CardContent>
        {!!editExtra && <RsvpInviteExtraPartyForm
			item={editExtra}
			onCancel={() => setEditExtra(undefined)}
			onSave={(extra) => {
                const extras = addExtra(extra, replyData);
                setFieldValue('replyData.extras', extras);
                onReplySave({...values, replyData: {...values.replyData, extras}});
                setEditExtra(undefined);
            }}
			replyValues={values}
			onFileUpload={onFileUpload}
		/>}
        {!!selectExtra && <RsvpInviteExtraSelectPartyForm
			item={{extraId: undefined}}
			ed={ed}
			onCancel={() => setSelectExtra(undefined)}
			onSave={(selected) => {
                if (!ed.dayNo) {
                    return;
                }

                const extra = selected.extraId ? replyData?.extras?.find((e) => e.extraId === selected.extraId) : undefined;
                if (extra) {
                    if (!extra.eventDayNos?.length) {
                        extra.eventDayNos = [];
                    }
                    if (!(extra.eventDayNos.indexOf(ed.dayNo) >= 0)) {
                        extra.eventDayNos?.push(ed.dayNo);
                    }
                    setFieldValue('replyData.extras', replyData?.extras);
                    onReplySave({...values, replyData: {...values.replyData}});
                    setSelectExtra(undefined);
                    return;
                }

                const party = selected.partyId ? prevParties?.find(p => p.partyId === selected.partyId) : undefined;
                if (party) {
                    const extra: JsonInviteReplyExtra = {
                        ...selected,
                        eventDayNos: [ed.dayNo]
                    }
                    const extras = addExtra(extra, replyData);
                    setFieldValue('replyData.extras', extras);
                    onReplySave({...values, replyData: {...values.replyData, extras}});
                    setSelectExtra(undefined);
                    return;
                }

                setSelectExtra(undefined);
                setEditExtra({eventDayNos: [ed.dayNo]})
                return;

            }}
			extras={selectExtra}
		/>}
    </Card>;
}

export default RsvpInviteEventDay;
