/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonFloorShapeText
 */
export interface JsonFloorShapeText {
    /**
     * 
     * @type {number}
     * @memberof JsonFloorShapeText
     */
    a?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorShapeText
     */
    h: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorShapeText
     */
    w: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorShapeText
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorShapeText
     */
    y: number;
}

export function JsonFloorShapeTextFromJSON(json: any): JsonFloorShapeText {
    return JsonFloorShapeTextFromJSONTyped(json, false);
}

export function JsonFloorShapeTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFloorShapeText {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'a': !exists(json, 'a') ? undefined : json['a'],
        'h': json['h'],
        'w': json['w'],
        'x': json['x'],
        'y': json['y'],
    };
}

export function JsonFloorShapeTextToJSON(value?: JsonFloorShapeText | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'a': value.a,
        'h': value.h,
        'w': value.w,
        'x': value.x,
        'y': value.y,
    };
}

