import {Grid} from "@mui/material";
import {datetimeToGui} from "../helpers/date";
import {KeyboardDoubleArrowRightRounded} from "@mui/icons-material";
import {renderFile} from "../helpers/files";
import * as React from "react";
import {JsonEventPartyTravel, JsonEventPartyTravelVehicleEnum} from "../generated-api";
import {formatMoney, getOption} from "../model/form";
import {vehicleOptions} from "../model/invite";

type TravelInfoProps = {
    travel: JsonEventPartyTravel
}

export const TravelInfo = (props: TravelInfoProps) => {
    const {travel} = props;

    const o = getOption(travel.vehicle, vehicleOptions);

    return <Grid container columnSpacing={2} columns={22} className={'travel-info'}>
        <Grid item xs={10}>
            <small>{datetimeToGui(travel.beginDate, travel.beginTime)}</small><br/>
            <strong>{travel.beginLocation}</strong>
        </Grid>
        <Grid item xs={2} className={'rsvp-extra-match-chevron'}>
            <div>
                <KeyboardDoubleArrowRightRounded/>
            </div>
        </Grid>
        <Grid item xs={10}>
            <small>{datetimeToGui(travel.endDate, travel.endTime)}</small><br/>
            <strong>{travel.endLocation}</strong>
        </Grid>

        <Grid item xs={21}>
        </Grid>

        <Grid item xs={21} className={'travel-info-plate-num'}>
            <br/><span title={o.label}>{o.icon || travel.vehicle}</span>
            {travel.vehicle === JsonEventPartyTravelVehicleEnum.Car && travel.plateNum}
            {travel.vehicle === JsonEventPartyTravelVehicleEnum.Car && <strong> {travel.kmCnt} km</strong>}
            {travel.vehicle !== JsonEventPartyTravelVehicleEnum.Car && <strong>{formatMoney(travel.compAmount, true)}</strong>}
        </Grid>

        <Grid item xs={21}>
            {travel.files?.length && travel.files?.length > 0 && <div>
				<br/>
                {travel.files.map((f, i) => {
                    return <div key={i}><small>{renderFile(f)}</small></div>;
                })}
			</div>}
            <br/>
        </Grid>
    </Grid>
}