import {Grid} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {FormProps} from "../../model/form";
import {JsonParty, JsonPartyInfo, JsonPartyPartyTypeEnum} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import * as React from "react";
import {useCallback} from "react";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {sexOptions} from "../../model/party";
import {PhotoFormField} from "../form/PhotoFormField";
import {SelectFormField} from "../form/SelectFormField";
import {isEmailValid, isPhoneValid} from "../../helpers/validators";
import CompanyModal from "../../pages/CompanyModal";

export interface PartyFormProps extends FormProps<JsonParty> {
    partyInfo?: JsonPartyInfo,
    type: 'info' | 'contact' | 'quick'
}

const PartyForm = (props: PartyFormProps) => {

    const {partyInfo, type} = props;
    const photoGuid = partyInfo?.photoGuid;
    const userId = props.item.user?.userId;
    const partyType = props.item.partyType;

    const t = useAppTranslation();

    const validate = useCallback((values: JsonParty) => {
        let errors = {} as FormikErrors<JsonParty>;
        if (type === 'info' || type === 'quick') {
            if (!values.partyType) {
                errors.partyType = t('Zvolte prosím typ');
            }
            if (values.partyType === JsonPartyPartyTypeEnum.T) {
                if (!values.companyName || values.companyName.length < 2) {
                    errors.companyName = t('Zadejte prosím název');
                }

            } else {
                if (!values.firstName || values.firstName.length < 2) {
                    errors.firstName = t('Zadejte prosím jméno');
                }
                if (!values.lastName || values.lastName.length < 2) {
                    errors.lastName = t('Zadejte prosím příjmení');
                }
                if (!values.sex) {
                    errors.sex = t('Zvolte prosím');
                }
            }
        }
        if (type === 'contact' || type === 'quick') {
            if (values.email && !isEmailValid(values.email)) {
                errors.email = t('Neplatný email');
            }
            if (values.phone && !isPhoneValid(values.phone)) {
                errors.phone = t('Neplatný telefon');
            }
        }
        return errors;
    }, [t, type]);

    const children = useCallback(({values}: FormikProps<JsonParty>) => {
        if (partyType === JsonPartyPartyTypeEnum.T) {

            return <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextFormField name="companyName" label={t('Název')} type={'text'}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField name="email" label={t('E-mail')} type={'text'}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextFormField name="phone" label={t('Telefon')} type={'text'}/>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectFormField name={'tags'} label={t('Štítky')} codebookProps={{codebookName: 'tag'}} isMulti
                            getTagColor={(o) => o.params?.color as any as string || 'inherit'}/>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectFormField name={'companyId'} label={t('Firma')} codebookProps={{codebookName: 'company', asNumber: true}}
                            clearable editProps={{
                            modal: (value, onCancel, onSave) => {
                                return <CompanyModal item={{companyId: value}} onCancel={onCancel} onSave={(item) => onSave(item.companyId)}/>
                            }
                        }}/>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: '10px'}}>
                        <TextFormField name={'note'} label={t('Poznámka')} type={'textarea'} maxlength={1000} minRows={2} maxRows={10}/>
                    </Grid>
                </Grid>
            </Grid>;
        }

        return <Grid item xs={12}>
            {(type === 'info' || type === 'quick') && <Grid container columnSpacing={1}>
				<Grid item xs={6} sx={{display: 'flex', alignItems: 'start'}}>
					<Grid container columnSpacing={2} rowSpacing={1}>
						<Grid item xs={12}>
							<TextFormField name="firstName" label={t('Jméno')} type={'text'}/>
						</Grid>
						<Grid item xs={12}>
							<TextFormField name="lastName" label={t('Příjmení')} type={'text'}/>
						</Grid>
						<Grid item xs={6}>
							<ButtonGroupField name={'sex'} label={t('Pohlaví')} options={sexOptions}/>
						</Grid>
						<Grid item xs={6}>
							<TextFormField name={'birthDate'} label={t('Dat. nar.')} type={'date'}/>
						</Grid>
						<Grid item xs={12}>
                            {/*<TextFormField name={'cebId'} label={t('ČSB ID')} type={'text'} maxlength={10}/>*/}
							<SelectFormField name={'tags'} label={t('Štítky')} codebookProps={{codebookName: 'tag'}} isMulti
								getTagColor={(o) => o.params?.color as any as string || 'inherit'}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<PhotoFormField name={'photoGuid'} isInternal maxHeight={170}
						title={((values?.firstName || '') + ' ' + (values?.lastName || '')).trim()}
						defaultValue={photoGuid}
					/>
				</Grid>
				<Grid item xs={12} sx={{marginTop: '10px'}}>
					<TextFormField name={'note'} label={t('Poznámka')} type={'textarea'} maxlength={1000} minRows={2} maxRows={10}/>
				</Grid>
			</Grid>}
            {(type === 'contact' || type === 'quick') && <Grid container columnSpacing={1}>
				<Grid item xs={12} sx={{display: 'flex', alignItems: 'start', marginTop: '10px'}}>
					<Grid container columnSpacing={2} rowSpacing={1}>
						<Grid item xs={6}>
							<TextFormField name="email" label={t('E-mail')} type={'text'}/>
						</Grid>
						<Grid item xs={6}>
							<TextFormField name="phone" label={t('Telefon')} type={'text'}/>
						</Grid>
						<Grid item xs={6}>
							<SelectFormField name={'companyId'} label={t('Firma')} codebookProps={{codebookName: 'company', asNumber: true}}
								clearable editProps={{
                                modal: (value, onCancel, onSave) => {
                                    return <CompanyModal item={{companyId: value}} onCancel={onCancel} onSave={(item) => onSave(item.companyId)}/>
                                }
                            }}/>
						</Grid>
						<Grid item xs={6}>
							<TextFormField name={'bankAccount'} label={t('Bankovní účet')} type={'text'}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{marginTop: '0'}}>
					<h4>{t('Adresa')}</h4>
					<Grid container columnSpacing={2} rowSpacing={1} sx={{marginTop: '5px'}}>
						<Grid item xs={6}>
							<TextFormField name="permAddress.street" label={t('Ulice')} type={'text'}/>
						</Grid>
						<Grid item xs={6}>
							<TextFormField name="permAddress.zip" label={t('PSČ')} type={'text'}/>
						</Grid>
						<Grid item xs={6}>
							<TextFormField name={'permAddress.city'} label={t('Město')} type={'text'}/>
						</Grid>
						<Grid item xs={6}>
							<TextFormField name={'permAddress.country'} label={t('Stát')} type={'text'}/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>}
            {(type === 'quick') && !!userId && <Grid container columnSpacing={1}>
				<Grid item xs={12} sx={{marginTop: '0'}}>
					<h4>{t('EMS Přístup')}</h4>
					<Grid container columnSpacing={2} rowSpacing={1} sx={{marginTop: '5px'}}>
						<Grid item xs={6}>
							<SelectFormField name={'user.roleId'} label={t('Role')} codebookProps={{codebookName: 'role', asNumber: true}}/>
						</Grid>
						<Grid item xs={6}>
							<SelectFormField name={'user.userData.groupIds'} label={t('Skupiny')} codebookProps={{codebookName: 'group', asNumber: true}} isMulti/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>}
        </Grid>;
    }, [userId, partyType, photoGuid, type, t]);

    return <FormContainer {...props} validate={validate} children={children}/>;
}

export default PartyForm;
