/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventGroup,
    ApiResultOfJsonEventGroupFromJSON,
    ApiResultOfJsonEventGroupToJSON,
    ApiResultOfJsonEventGroupInfo,
    ApiResultOfJsonEventGroupInfoFromJSON,
    ApiResultOfJsonEventGroupInfoToJSON,
    ApiResultOfListOfJsonEventGroupInfo,
    ApiResultOfListOfJsonEventGroupInfoFromJSON,
    ApiResultOfListOfJsonEventGroupInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventGroup,
    JsonEventGroupFromJSON,
    JsonEventGroupToJSON,
} from '../models';

export interface GetEventGroupCountUsingGETRequest {
    eventId?: number;
    groupId?: number;
    groupType?: GetEventGroupCountUsingGETGroupTypeEnum;
    search?: string;
}

export interface GetEventGroupInfoUsingGETRequest {
    eventGroupId: number;
}

export interface GetEventGroupListUsingGETRequest {
    eventId?: number;
    groupId?: number;
    groupType?: GetEventGroupListUsingGETGroupTypeEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventGroupUsingGETRequest {
    eventGroupId: number;
}

export interface SaveEventGroupUsingPOSTRequest {
    json: JsonEventGroup;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventGroupControllerApi extends runtime.BaseAPI {

    /**
     * getEventGroupCount
     */
    async getEventGroupCountUsingGETRaw(requestParameters: GetEventGroupCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        if (requestParameters.groupType !== undefined) {
            queryParameters['groupType'] = requestParameters.groupType;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-group/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventGroupCount
     */
    async getEventGroupCountUsingGET(requestParameters: GetEventGroupCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventGroupCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventGroupInfo
     */
    async getEventGroupInfoUsingGETRaw(requestParameters: GetEventGroupInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventGroupInfo>> {
        if (requestParameters.eventGroupId === null || requestParameters.eventGroupId === undefined) {
            throw new runtime.RequiredError('eventGroupId','Required parameter requestParameters.eventGroupId was null or undefined when calling getEventGroupInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-group/info/{eventGroupId}`.replace(`{${"eventGroupId"}}`, encodeURIComponent(String(requestParameters.eventGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventGroupInfoFromJSON(jsonValue));
    }

    /**
     * getEventGroupInfo
     */
    async getEventGroupInfoUsingGET(requestParameters: GetEventGroupInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventGroupInfo> {
        const response = await this.getEventGroupInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventGroupList
     */
    async getEventGroupListUsingGETRaw(requestParameters: GetEventGroupListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventGroupInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        if (requestParameters.groupType !== undefined) {
            queryParameters['groupType'] = requestParameters.groupType;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-group/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventGroupInfoFromJSON(jsonValue));
    }

    /**
     * getEventGroupList
     */
    async getEventGroupListUsingGET(requestParameters: GetEventGroupListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventGroupInfo> {
        const response = await this.getEventGroupListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventGroup
     */
    async getEventGroupUsingGETRaw(requestParameters: GetEventGroupUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventGroup>> {
        if (requestParameters.eventGroupId === null || requestParameters.eventGroupId === undefined) {
            throw new runtime.RequiredError('eventGroupId','Required parameter requestParameters.eventGroupId was null or undefined when calling getEventGroupUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-group/{eventGroupId}`.replace(`{${"eventGroupId"}}`, encodeURIComponent(String(requestParameters.eventGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventGroupFromJSON(jsonValue));
    }

    /**
     * getEventGroup
     */
    async getEventGroupUsingGET(requestParameters: GetEventGroupUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventGroup> {
        const response = await this.getEventGroupUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventGroup
     */
    async saveEventGroupUsingPOSTRaw(requestParameters: SaveEventGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventGroup>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventGroupUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventGroupToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventGroupFromJSON(jsonValue));
    }

    /**
     * saveEventGroup
     */
    async saveEventGroupUsingPOST(requestParameters: SaveEventGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventGroup> {
        const response = await this.saveEventGroupUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetEventGroupCountUsingGETGroupTypeEnum {
    Org = 'ORG',
    Supp = 'SUPP',
    Guest = 'GUEST'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventGroupListUsingGETGroupTypeEnum {
    Org = 'ORG',
    Supp = 'SUPP',
    Guest = 'GUEST'
}
