import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonEventDay, ApiResultOfJsonEventDayInfo, EventDayControllerApi, GetEventDayCountUsingGETRequest, GetEventDayListUsingGETRequest, JsonEventDay, JsonEventDayInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const eventDaysApi = new EventDayControllerApi(API_CONFIG);

export const fetchEventDays = createAsyncThunk('eventDays/list', async (filter: GetEventDayListUsingGETRequest, thunkApi) => {
    return {...await eventDaysApi.getEventDayListUsingGET(filter), filter};
});

export const fetchEventDaysCount = createAsyncThunk('eventDays/count', async (params: GetEventDayCountUsingGETRequest = {}) => {
    return await eventDaysApi.getEventDayCountUsingGET(params);
});

export const fetchEventDayInfo = createAsyncThunk('eventDay/info', async (eventDayId: number): Promise<ApiResultOfJsonEventDayInfo> => {
    return await eventDaysApi.getEventDayInfoUsingGET({eventDayId});
});

export const fetchEventDay = createAsyncThunk('eventDay/fetch', async (eventDayId: number): Promise<ApiResultOfJsonEventDay> => {
    return await eventDaysApi.getEventDayUsingGET({eventDayId});
});

export const saveEventDay = createAsyncThunk('eventDay/save', async (eventDay: JsonEventDay) => {
    return await eventDaysApi.saveEventDayUsingPOST({json: eventDay});
});

export const eventDaysSlice = createSlice({
    name: 'eventDays',
    initialState: (): ItemsState<JsonEventDayInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchEventDays, fetchEventDaysCount);
    }
});

export const eventDaysReducer = eventDaysSlice.reducer;
