import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
    ApiResultOfJsonEventTicketInfo,
    EventTicketControllerApi,
    GetEventTicketCountUsingGETRequest,
    GetEventTicketListUsingGETRequest,
    JsonEventTicket,
    JsonEventTicketInfo,
    JsonEventTicketMassActionRequest
} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const eventTicketsApi = new EventTicketControllerApi(API_CONFIG);

export const fetchEventTickets = createAsyncThunk('eventTickets/list', async (filter: GetEventTicketListUsingGETRequest, thunkApi) => {
    return {...await eventTicketsApi.getEventTicketListUsingGET(filter), filter};
});

export const fetchEventTicketsCount = createAsyncThunk('eventTickets/count', async (params: GetEventTicketCountUsingGETRequest = {}) => {
    return await eventTicketsApi.getEventTicketCountUsingGET(params);
});

export const fetchEventTicketsOnline = createAsyncThunk('eventTickets/online', async (filter: GetEventTicketListUsingGETRequest, thunkApi) => {
    return await eventTicketsApi.getEventTicketListUsingGET(filter);
});

export const fetchEventTicketInfo = createAsyncThunk('eventTicket/info', async (ticketId: number): Promise<ApiResultOfJsonEventTicketInfo> => {
    return await eventTicketsApi.getEventTicketInfoUsingGET({ticketId});
});

export const fetchEventTicket = createAsyncThunk('eventTicket/fetch', async (ticketId: number) => {
    return await eventTicketsApi.getEventTicketUsingGET({ticketId});
});

export const saveEventTicket = createAsyncThunk('eventTicket/save', async (eventTicket: JsonEventTicket) => {
    return await eventTicketsApi.saveEventTicketUsingPOST({json: eventTicket});
});

export const massEventTicket = createAsyncThunk('eventTicket/mass', async (params: { request: JsonEventTicketMassActionRequest, testOnly?: boolean }) => {
    return await eventTicketsApi.massEventTicketActionUsingPOST({request: params.request, testOnly: params.testOnly});
});

export const eventTicketsSlice = createSlice({
    name: 'eventTickets',
    initialState: (): ItemsState<JsonEventTicketInfo> => createDefaultListState(),
    reducers: {
        clearEventTickets: state => createDefaultListState(),
    },
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchEventTickets, fetchEventTicketsCount);
    }
});

export const eventTicketsReducer = eventTicketsSlice.reducer;
export const {clearEventTickets} = eventTicketsSlice.actions;
