/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonReplyData,
    JsonReplyDataFromJSON,
    JsonReplyDataFromJSONTyped,
    JsonReplyDataToJSON,
} from './JsonReplyData';
import {
    JsonReplyProcessData,
    JsonReplyProcessDataFromJSON,
    JsonReplyProcessDataFromJSONTyped,
    JsonReplyProcessDataToJSON,
} from './JsonReplyProcessData';

/**
 * 
 * @export
 * @interface JsonInviteReplyInfo
 */
export interface JsonInviteReplyInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonInviteReplyInfo
     */
    allowedActions?: Array<JsonInviteReplyInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteReplyInfo
     */
    infoIsValid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyInfo
     */
    inviteId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    newFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    newLastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyInfo
     */
    newPhotoId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyInfo
     */
    newUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    processAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyInfo
     */
    processBy?: number;
    /**
     * 
     * @type {JsonReplyProcessData}
     * @memberof JsonInviteReplyInfo
     */
    processData?: JsonReplyProcessData;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    replyAt?: string;
    /**
     * 
     * @type {JsonReplyData}
     * @memberof JsonInviteReplyInfo
     */
    replyData?: JsonReplyData;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyInfo
     */
    replyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    replyNote?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    replyType?: JsonInviteReplyInfoReplyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    status?: JsonInviteReplyInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyInfoReplyTypeEnum {
    Accept = 'ACCEPT',
    Reject = 'REJECT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyInfoStatusEnum {
    Deleted = 'DELETED',
    Draft = 'DRAFT',
    Sent = 'SENT',
    Rejected = 'REJECTED',
    Accepted = 'ACCEPTED'
}

export function JsonInviteReplyInfoFromJSON(json: any): JsonInviteReplyInfo {
    return JsonInviteReplyInfoFromJSONTyped(json, false);
}

export function JsonInviteReplyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonInviteReplyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'infoIsValid': !exists(json, 'infoIsValid') ? undefined : json['infoIsValid'],
        'inviteId': !exists(json, 'inviteId') ? undefined : json['inviteId'],
        'newFirstName': !exists(json, 'newFirstName') ? undefined : json['newFirstName'],
        'newLastName': !exists(json, 'newLastName') ? undefined : json['newLastName'],
        'newPhotoId': !exists(json, 'newPhotoId') ? undefined : json['newPhotoId'],
        'newUserId': !exists(json, 'newUserId') ? undefined : json['newUserId'],
        'processAt': !exists(json, 'processAt') ? undefined : json['processAt'],
        'processBy': !exists(json, 'processBy') ? undefined : json['processBy'],
        'processData': !exists(json, 'processData') ? undefined : JsonReplyProcessDataFromJSON(json['processData']),
        'replyAt': !exists(json, 'replyAt') ? undefined : json['replyAt'],
        'replyData': !exists(json, 'replyData') ? undefined : JsonReplyDataFromJSON(json['replyData']),
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'replyNote': !exists(json, 'replyNote') ? undefined : json['replyNote'],
        'replyType': !exists(json, 'replyType') ? undefined : json['replyType'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonInviteReplyInfoToJSON(value?: JsonInviteReplyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'infoIsValid': value.infoIsValid,
        'inviteId': value.inviteId,
        'newFirstName': value.newFirstName,
        'newLastName': value.newLastName,
        'newPhotoId': value.newPhotoId,
        'newUserId': value.newUserId,
        'processAt': value.processAt,
        'processBy': value.processBy,
        'processData': JsonReplyProcessDataToJSON(value.processData),
        'replyAt': value.replyAt,
        'replyData': JsonReplyDataToJSON(value.replyData),
        'replyId': value.replyId,
        'replyNote': value.replyNote,
        'replyType': value.replyType,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

