/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonPlaceTotals,
    JsonPlaceTotalsFromJSON,
    JsonPlaceTotalsFromJSONTyped,
    JsonPlaceTotalsToJSON,
} from './JsonPlaceTotals';

/**
 * 
 * @export
 * @interface JsonPlaceInfo
 */
export interface JsonPlaceInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonPlaceInfo
     */
    allowedActions?: Array<JsonPlaceInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonPlaceInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceInfo
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceInfo
     */
    foodCapacity?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonPlaceInfo
     */
    foodServices?: Array<JsonPlaceInfoFoodServicesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof JsonPlaceInfo
     */
    isAcm?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonPlaceInfo
     */
    isFood?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonPlaceInfo
     */
    isMeet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonPlaceInfo
     */
    isPark?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonPlaceInfo
     */
    isWork?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceInfo
     */
    parkCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceInfo
     */
    placeId?: number;
    /**
     * 
     * @type {Array<JsonPlaceTotals>}
     * @memberof JsonPlaceInfo
     */
    placeTotals?: Array<JsonPlaceTotals>;
    /**
     * 
     * @type {string}
     * @memberof JsonPlaceInfo
     */
    status?: JsonPlaceInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPlaceInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPlaceInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonPlaceInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonPlaceInfoFoodServicesEnum {
    Br = 'BR',
    Lu = 'LU',
    Di = 'DI',
    Sn = 'SN'
}/**
* @export
* @enum {string}
*/
export enum JsonPlaceInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonPlaceInfoFromJSON(json: any): JsonPlaceInfo {
    return JsonPlaceInfoFromJSONTyped(json, false);
}

export function JsonPlaceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPlaceInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'foodCapacity': !exists(json, 'foodCapacity') ? undefined : json['foodCapacity'],
        'foodServices': !exists(json, 'foodServices') ? undefined : json['foodServices'],
        'isAcm': !exists(json, 'isAcm') ? undefined : json['isAcm'],
        'isFood': !exists(json, 'isFood') ? undefined : json['isFood'],
        'isMeet': !exists(json, 'isMeet') ? undefined : json['isMeet'],
        'isPark': !exists(json, 'isPark') ? undefined : json['isPark'],
        'isWork': !exists(json, 'isWork') ? undefined : json['isWork'],
        'parkCapacity': !exists(json, 'parkCapacity') ? undefined : json['parkCapacity'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
        'placeTotals': !exists(json, 'placeTotals') ? undefined : ((json['placeTotals'] as Array<any>).map(JsonPlaceTotalsFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonPlaceInfoToJSON(value?: JsonPlaceInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'foodCapacity': value.foodCapacity,
        'foodServices': value.foodServices,
        'isAcm': value.isAcm,
        'isFood': value.isFood,
        'isMeet': value.isMeet,
        'isPark': value.isPark,
        'isWork': value.isWork,
        'parkCapacity': value.parkCapacity,
        'placeId': value.placeId,
        'placeTotals': value.placeTotals === undefined ? undefined : ((value.placeTotals as Array<any>).map(JsonPlaceTotalsToJSON)),
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

