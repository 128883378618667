import {Button, Card, CardContent, Chip, Typography} from "@mui/material";
import * as React from "react";
import {useAppTranslation} from "../../services/i18n";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {Color} from "../../model/form";

export interface InfoBoxAction {
    title: string,
    tooltip?: string,
    color?: 'primary' | 'secondary' | 'error' | 'inherit',
    icon?: JSX.Element,
    action: () => any
}

export interface InfoBoxBadge {
    title?: string | JSX.Element,
    color?: Color,
    tooltip?: string
}

export interface InfoBoxProps {
    title?: string | JSX.Element;
    badges?: InfoBoxBadge[],
    actions?: InfoBoxAction[],
    children?: any;
    sx?: SxProps<Theme>;
}

const InfoBox = (props: InfoBoxProps): JSX.Element => {
    const {title, badges, actions, children, sx} = props;
    const t = useAppTranslation();

    return <Card variant={'elevation'} className={'info-box'} sx={sx}>
        <CardContent>
            {title && <Typography component="h5" variant={'h5'}>{typeof title === 'string' ? t(title) : title}</Typography>}
            {badges && badges.length > 0 && <div className={'info-box-badges'}>
                {badges.map((badge, i) => <Chip key={i} label={badge.title} color={badge.color || 'default'} title={badge.tooltip}/>)}
			</div>}
            {actions && actions.length > 0 && <div className={'info-box-actions'}>
                {actions.map((action, i) =>
                    <Button key={i} variant={'contained'} size={'small'} color={action.color} onClick={() => action.action()} title={action.tooltip || action.title}>
                        {action.icon ? <>{action.icon} <span>{t(action.title)}</span></> : t(action.title)}
                    </Button>)}
			</div>}
            {children}
        </CardContent>
    </Card>
}

export default InfoBox;
