/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonCompany,
    ApiResultOfJsonCompanyFromJSON,
    ApiResultOfJsonCompanyToJSON,
    ApiResultOfJsonCompanyInfo,
    ApiResultOfJsonCompanyInfoFromJSON,
    ApiResultOfJsonCompanyInfoToJSON,
    ApiResultOfListOfJsonCompanyInfo,
    ApiResultOfListOfJsonCompanyInfoFromJSON,
    ApiResultOfListOfJsonCompanyInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonCompany,
    JsonCompanyFromJSON,
    JsonCompanyToJSON,
} from '../models';

export interface GetCompanyCountUsingGETRequest {
    search?: string;
}

export interface GetCompanyInfoUsingGETRequest {
    companyId: number;
}

export interface GetCompanyListUsingGETRequest {
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetCompanyUsingGETRequest {
    companyId: number;
}

export interface SaveCompanyUsingPOSTRequest {
    json: JsonCompany;
    testOnly?: boolean;
}

/**
 * 
 */
export class CompanyControllerApi extends runtime.BaseAPI {

    /**
     * getCompanyCount
     */
    async getCompanyCountUsingGETRaw(requestParameters: GetCompanyCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getCompanyCount
     */
    async getCompanyCountUsingGET(requestParameters: GetCompanyCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getCompanyCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyInfo
     */
    async getCompanyInfoUsingGETRaw(requestParameters: GetCompanyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyInfo>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanyInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company/info/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyInfo
     */
    async getCompanyInfoUsingGET(requestParameters: GetCompanyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyInfo> {
        const response = await this.getCompanyInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyList
     */
    async getCompanyListUsingGETRaw(requestParameters: GetCompanyListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonCompanyInfo>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonCompanyInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyList
     */
    async getCompanyListUsingGET(requestParameters: GetCompanyListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonCompanyInfo> {
        const response = await this.getCompanyListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompany
     */
    async getCompanyUsingGETRaw(requestParameters: GetCompanyUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompany>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompanyUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyFromJSON(jsonValue));
    }

    /**
     * getCompany
     */
    async getCompanyUsingGET(requestParameters: GetCompanyUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompany> {
        const response = await this.getCompanyUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveCompany
     */
    async saveCompanyUsingPOSTRaw(requestParameters: SaveCompanyUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompany>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveCompanyUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonCompanyToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyFromJSON(jsonValue));
    }

    /**
     * saveCompany
     */
    async saveCompanyUsingPOST(requestParameters: SaveCompanyUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompany> {
        const response = await this.saveCompanyUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
