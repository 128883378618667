export const apiBasePath = (_lang?: string) => {
	switch (window.location.hostname) {
		case '192.168.84.101':
			return `http://192.168.84.101:8080`;
		case 'localhost':
			return `https://ems.codito.cz`;
		default:
			return 'https://' + window.location.hostname;
	}
}

export const isProdEnv = !['localhost', 'ems.codito.cz', '192.168.84.101'].includes(window.location.hostname);

const path = window.location.pathname;
export const isRsvp = path.startsWith('/rsvp/') || path.startsWith('/info/') || path.startsWith('/plan/');
export const isPublic = path.startsWith('/registration') || path.startsWith('/confirmation');
export const baseUrl = '/';
