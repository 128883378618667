import * as React from "react";
import {AttachFileRounded} from "@mui/icons-material";

export const getLink = (guid) => {
	if (process.env.NODE_ENV !== 'production') {
		return 'http://192.168.84.101:8080/download/?guid=' + guid
	}
	return '/download/?guid=' + guid
}

export const renderFile = (a/*: JsonFile*/) => {
	return <a href={getLink(a.guid)} rel="noreferrer" target={'_blank'}><AttachFileRounded sx={{fontSize: '100%', marginBottom: '-2px'}}/> {a.fileName} ({Math.round((a.fileSize || 0) / 1024)} kB)</a>;
}