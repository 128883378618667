import {useAppDispatch} from "../store";
import {useParams} from "react-router-dom";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {JsonRsvpFloorDayPlan} from "../generated-api";
import {fetchRsvpFloorDayPlan} from "../store/rsvp";
import {getApiResult} from "../helpers/api";
import {Container, LinearProgress} from "@mui/material";
import {SeatingPlanCanvas} from "../components/seating/SeatingPlanCanvas";
import {FloorDirtyValues} from "../components/seating/SeatingPlan";
import {dateToGui} from "../helpers/date";

const PlanPage = () => {
    const dispatch = useAppDispatch();
    const params = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [rsvpPlan, setRsvpPlan] = useState<JsonRsvpFloorDayPlan | undefined>(undefined);
    const refresh = useRef<any>();

    const dayDate = params.dayDate as string;
    const eventFloorDayId = parseInt(params.eventFloorDayId as string);

    const handleFetchFloorDay = useCallback(async () => {
        setRsvpPlan(getApiResult<JsonRsvpFloorDayPlan>(await dispatch(fetchRsvpFloorDayPlan({dayDate, eventFloorDayId}))));

        refresh.current = setTimeout(handleFetchFloorDay, 5 * 60 * 1000);

    }, [dayDate, eventFloorDayId, dispatch]);

    useEffect(() => {
        handleFetchFloorDay().then(() => {
            setIsLoading(false);
        });
        return () => {
            if (refresh.current) {
                clearTimeout(refresh.current);
            }
        }
    }, [handleFetchFloorDay])

    if (isLoading) {
        return <LinearProgress/>
    }

    return <>
        <Container className={'seating-wrapper seating-fullscreen'}>
            <h1>{rsvpPlan?.eventFloor?.title} {dateToGui(rsvpPlan?.eventFloorDay?.dayDate)}</h1>
            {!!rsvpPlan?.eventFloorDay && !!rsvpPlan?.eventFloor && <SeatingPlanCanvas
				eventFloorDay={rsvpPlan.eventFloorDay}
				eventFloor={rsvpPlan.eventFloor}
				eventDay={{dayNo: rsvpPlan.eventFloorDay.dayNo}}
				shapes={rsvpPlan.floorShapes}
				dirtyValues={{
                    items: rsvpPlan.eventFloorDay.floorData?.items
                } as FloorDirtyValues}
                // otherFloorsDirtyValues={Object.values(dirtyValuesByFloor)
                //     .filter(dv => dv.dayNo === rsvpPlan.dayNo && dv.eventFloorDayId !== rsvpPlan.eventFloorDayId)
                //     .map((dv => dv as FloorDirtyValues))}
                // seating={seating}
                // originalSeating={originalSeating.current}
                // groups={groups}
                // tables={tables}
			/>}
        </Container>
    </>
}

export default PlanPage;
