import {createAsyncThunk,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonFormat, ApiResultOfJsonFormatInfo, FormatControllerApi, GetFormatCountUsingGETRequest, GetFormatListUsingGETRequest, JsonFormat} from '../generated-api';

const formatsApi = new FormatControllerApi(API_CONFIG);

export const fetchFormats = createAsyncThunk('formats/list', async (filter: GetFormatListUsingGETRequest, thunkApi) => {
    return {...await formatsApi.getFormatListUsingGET(filter), filter};
});

export const fetchFormatsCount = createAsyncThunk('formats/count', async (params: GetFormatCountUsingGETRequest = {}) => {
    return await formatsApi.getFormatCountUsingGET(params);
});

export const fetchFormatInfo = createAsyncThunk('format/info', async (formatId: number): Promise<ApiResultOfJsonFormatInfo> => {
    return await formatsApi.getFormatInfoUsingGET({formatId});
});

export const fetchFormat = createAsyncThunk('format/fetch', async (formatId: number): Promise<ApiResultOfJsonFormat> => {
    return await formatsApi.getFormatUsingGET({formatId});
});

export const saveFormat = createAsyncThunk('format/save', async (format: JsonFormat): Promise<ApiResultOfJsonFormat> => {
    return await formatsApi.saveFormatUsingPOST({json: format});
});

// export const formatsSlice = createSlice({
//     name: 'formats',
//     initialState: (): ItemsState<JsonFormatInfo> => createDefaultListState(),
//     reducers: {},
//     extraReducers: builder => {
//         createDefaultListReducer(builder, fetchFormats, fetchFormatsCount);
//     }
// });
//
// export const formatsReducer = formatsSlice.reducer;
