/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventTicket,
    JsonEventTicketFromJSON,
    JsonEventTicketFromJSONTyped,
    JsonEventTicketToJSON,
} from './JsonEventTicket';

/**
 * 
 * @export
 * @interface JsonEventTicketMassActionRequest
 */
export interface JsonEventTicketMassActionRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicketMassActionRequest
     */
    action?: JsonEventTicketMassActionRequestActionEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonEventTicketMassActionRequest
     */
    eventId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonEventTicketMassActionRequest
     */
    ids?: Array<number>;
    /**
     * 
     * @type {Array<JsonEventTicket>}
     * @memberof JsonEventTicketMassActionRequest
     */
    items?: Array<JsonEventTicket>;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventTicketMassActionRequestActionEnum {
    Update = 'UPDATE',
    Seating = 'SEATING'
}

export function JsonEventTicketMassActionRequestFromJSON(json: any): JsonEventTicketMassActionRequest {
    return JsonEventTicketMassActionRequestFromJSONTyped(json, false);
}

export function JsonEventTicketMassActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventTicketMassActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JsonEventTicketFromJSON)),
    };
}

export function JsonEventTicketMassActionRequestToJSON(value?: JsonEventTicketMassActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'eventId': value.eventId,
        'ids': value.ids,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JsonEventTicketToJSON)),
    };
}

