import {Alert, Avatar, Box, Button, Card, CardContent, Container, Link, Typography} from '@mui/material';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {Link as RouterLink, useParams} from "react-router-dom";
import {useAppTranslation} from '../services/i18n';
import {useAppDispatch} from '../store';
import {Formik, FormikErrors} from 'formik';
import {JsonAuthRequest, JsonAuthRequestTokenTypeEnum} from '../generated-api';
import logo from '../assets/images/ems_logo_2024.png';
import {isEmailValid} from '../helpers/validators';
import {TextFormField} from "../components/form/TextFormField";
import {Key, LockOutlined, LockReset} from "@mui/icons-material";
import {changePassword, forgottenPassword, loginAuthUser} from "../store/auth";
import {CheckboxField} from "../components/form/CheckboxField";
import {getApiResult} from "../helpers/api";

const PASSWORD_MIN_LENGHT = 8;

const LoginForm = () => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validate = useCallback((values: JsonAuthRequest) => {
        const errors = {} as FormikErrors<JsonAuthRequest>;
        if (!values.email) {
            errors.email = t('Zadejte přihlašovací email');
        } else if (!isEmailValid(values.email)) {
            errors.email = t('Neplatný přihlašovací email');
        }
        if (!values.password) {
            errors['password'] = t('Zadejte heslo');
        }
        return errors;
    }, [t]);

    const handleLogin = useCallback(async (values: JsonAuthRequest) => {
        setIsSubmitting(true);
        dispatch(loginAuthUser(values)).then(() => {
            setIsSubmitting(false);
        })
    }, [dispatch])

    return <Formik
        initialValues={{
            email: '',
            password: '',
            rememberMe: false
        } as JsonAuthRequest}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={handleLogin}
    >
        {props => (
            <form onSubmit={props.handleSubmit} className={'login-form'}>
                <Card>
                    <CardContent>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Avatar sx={{m: 1}}>
                                <LockOutlined/>
                            </Avatar>
                            <Typography component="h1" variant="h5" sx={{marginBottom: "20px"}}>
                                {t('Přihlášení do EMS')}
                            </Typography>
                            <TextFormField name="email" label={t('E-mail')} autoFocus/>
                            <TextFormField name="password" label={t('Heslo')} type="password"/>
                            <CheckboxField name={'rememberMe'} label={t('Zapamatuj si mě')}/>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Button variant="contained" type="submit" fullWidth sx={{mt: 3}} disabled={isSubmitting}>{t('Přihlásit se')}</Button>
                        </Box>
                    </CardContent>
                </Card>
                <Link color='primary' to="/recover" sx={{mt: 3}} component={RouterLink}>
                    {t('Zapomenuté heslo')}
                </Link>
            </form>
        )}
    </Formik>
}

const SendRecoverLinkForm = () => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

    const validate = useCallback((values: JsonAuthRequest) => {
        const errors = {} as FormikErrors<JsonAuthRequest>;
        if (!values.email) {
            errors.email = t('Zadejte přihlašovací email');
        } else if (!isEmailValid(values.email)) {
            errors.email = t('Neplatný přihlašovací email');
        }
        return errors;
    }, [t]);

    const handleLogin = useCallback(async (values: JsonAuthRequest) => {
        setIsSubmitting(true);
        dispatch(forgottenPassword(values)).then((res) => {
            const item = getApiResult<string>(res);
            setSuccessMessage(item);
            setIsSubmitting(false);
        })
    }, [dispatch])

    return <Formik
        initialValues={{
            email: '',
        } as JsonAuthRequest}
        validate={validate}
        validateOnChange={false}
        onSubmit={handleLogin}
    >
        {props => (
            <form onSubmit={props.handleSubmit} className={'login-form'}>
                <Card><CardContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Avatar sx={{m: 1}}>
                            <LockReset/>
                        </Avatar>
                        <Typography component="h1" variant="h5" sx={{marginBottom: "20px"}}>
                            {t('Obnova hesla')}
                        </Typography>
                        {!!successMessage
                            ? <Alert severity={'success'}>
                                {successMessage}
                            </Alert>
                            : <>
                                <p>
                                    {t('Zadejte prosím vaši emailovou adresu, na kterou vám bude zaslán odkaz pro obnovu hesla.')}
                                </p>
                                <TextFormField name="email" label={t('E-mail')} autoFocus/>
                            </>}
                    </Box>
                    {!successMessage && <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						<Button variant="contained" type="submit" fullWidth sx={{mt: 3}} disabled={isSubmitting}>{t('Zaslat odkaz na obnovu hesla')}</Button>
					</Box>}
                </CardContent>
                </Card>
                <Link color='primary' to="/" sx={{mt: 3}} component={RouterLink}>
                    {t('Zpět na přihlášení')}
                </Link>
            </form>
        )}
    </Formik>;
}

type JsonAuthRecover = JsonAuthRequest & {
    passwordCheck: string
}

const isValidPassword = (password: string) => {
    return password && password.length >= PASSWORD_MIN_LENGHT && password.search(/[!#$%&? "'_\-@^*]/) >= 0
}

const ChangePasswordForm = ({token, tokenType}: { token: string, tokenType: JsonAuthRequestTokenTypeEnum }) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

    const validate = useCallback((values: JsonAuthRecover) => {
        const errors = {} as FormikErrors<JsonAuthRecover>;
        if (!values.email) {
            errors.email = t('Zadejte přihlašovací email');
        } else if (!isEmailValid(values.email)) {
            errors.email = t('Neplatný přihlašovací email');
        }
        if (!values.password) {
            errors.password = tokenType === JsonAuthRequestTokenTypeEnum.Activate ? t('Zadejte požadované heslo') : t('Zadejte nové heslo');
        } else if (!isValidPassword(values.password)) {
            errors.password = t('Heslo nesplňuje podmínky');
        } else if (!values.passwordCheck) {
            errors.passwordCheck = t('Zadejte heslo znovu');
        } else if (values.passwordCheck !== values.password) {
            errors.passwordCheck = t('Hesla se neshodují');
        }
        return errors;
    }, [tokenType, t]);

    const handleLogin = useCallback(async (values: JsonAuthRecover) => {
        setIsSubmitting(true);
        dispatch(changePassword(values)).then((res) => {
            const item = getApiResult<string>(res);
            setSuccessMessage(item);
            setIsSubmitting(false);
        })
    }, [dispatch])

    return <Formik
        initialValues={{
            email: '',
            password: '',
            passwordCheck: '',
            token,
            tokenType
        } as JsonAuthRecover}
        validate={validate}
        validateOnChange={false}
        onSubmit={handleLogin}
    >
        {props => (
            <form onSubmit={props.handleSubmit} className={'login-form'}>
                <Card><CardContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Avatar sx={{m: 1}}>
                            <Key/>
                        </Avatar>
                        <Typography component="h1" variant="h5" sx={{marginBottom: "20px"}}>
                            {tokenType === JsonAuthRequestTokenTypeEnum.Activate
                                ? t('Aktivace přístupu do EMS')
                                : t('Nastavení hesla do EMS')}
                        </Typography>
                        {!!successMessage
                            ? <Alert severity={'success'}>
                                {successMessage}
                                <br/>
                                <br/>
                                <Link color='primary' to="/" sx={{mt: 3}} component={RouterLink}>
                                    {t('Přejít na přihlášení')}
                                </Link>
                            </Alert>
                            : <>
                                <p>
                                    {tokenType === JsonAuthRequestTokenTypeEnum.Activate
                                        ? t('Zadejte prosím vaši emailovou adresu, na který vám byla zaslána emailová zpráva o aktivaci účtu (bude následně sloužit jako přihlašovací jméno) a požadované heslo pro přístup do aplikace.')
                                        : t('Zadejte prosím nové heslo pro přístup do aplikace.')}
                                </p>
                                <p>
                                    {t('Heslo musí být alespoň {{min}} znaků dlouhé a obsahovat speciální znak (!#$%&?_-@^*).', {min: PASSWORD_MIN_LENGHT})}
                                </p>
                                <TextFormField name="email" label={t('E-mail')} autoFocus/>
                                <TextFormField type={'password'} name="password" label={t('Nové heslo')}/>
                                <TextFormField type={'password'} name="passwordCheck" label={t('Heslo znovu')}/>
                            </>}
                    </Box>
                    {!successMessage && <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						<Button variant="contained" type="submit" fullWidth sx={{mt: 3}} disabled={isSubmitting}>{t('Nastavit heslo')}</Button>
					</Box>}
                </CardContent>
                </Card>
                {!successMessage && <>
					<Link color='primary' to="/recover" sx={{mt: 3}} component={RouterLink}>
                        {t('Vygenerovat nový odkaz')}
					</Link>
					<Link color='primary' to="/" sx={{mt: 3}} component={RouterLink}>
                        {t('Zpět na přihlášení')}
					</Link>
				</>}
            </form>
        )}
    </Formik>;
}

const LoginPage = ({tokenType}: { tokenType?: JsonAuthRequestTokenTypeEnum }) => {

    const {token} = useParams();

    let form;
    if (tokenType) {
        if (token) {
            form = <ChangePasswordForm token={token} tokenType={tokenType}/>
        } else {
            form = <SendRecoverLinkForm/>
        }
    } else {
        form = <LoginForm/>
    }

    // https://mui.com/material-ui/getting-started/usage/
    return <>
        <Box className={'login-header'}>
            <Container maxWidth="xl">
                <a href={'/'} title={'Biatlon EMS'}>
                    <img alt={'Biatlon EMS'} src={logo} style={{height: '46px', padding: "11px 0 8px"}}/>
                </a>
            </Container>
        </Box>
        {form}
    </>
}

export default LoginPage;
