import {Button, CircularProgress, Grid} from '@mui/material';
import {Formik, FormikProps} from 'formik';
import {ReactNode, useCallback} from 'react';
import {FormProps} from '../../model/form';
import {useAppTranslation} from '../../services/i18n';
import {ModalProps, useModal} from "../../services/modal";
import ScrollErrorsIntoView from "./ScrollErrorsIntoView";
import {FormikHelpers} from "formik/dist/types";

interface Props<T> extends FormProps<T> {
    children?: ((formikProps: FormikProps<T>) => ReactNode) | ReactNode | undefined;
    actions?: (formikProps: FormikProps<T>, props: FormProps<T>) => ReactNode;
}

const FormContainer = <T extends {}>(props: Props<T>) => {

    const {item, validate, children, onDelete, deleteButtonTitle, deleteConfirmText, onSave, isReadonly} = props;

    const t = useAppTranslation();
    const modal = useModal();

    const handleDelete = useCallback(async (item: T) => {
        if (!onDelete) {
            return;
        }
        const result = await modal.confirm({
            title: t('Potvrzení'),
            message: t(deleteConfirmText || 'Skutečně trvale smazat?', item),
            confirmColor: 'error',
            confirmText: t(deleteButtonTitle || 'Smazat')
        } as ModalProps);
        if (result !== 'CONFIRM') {
            return;
        }

        return onDelete(item);

    }, [modal, t, onDelete, deleteConfirmText, deleteButtonTitle]);

    const onSubmit = useCallback(async (values: T, actions: FormikHelpers<T>) => {
        if (!isReadonly && onSave) {
            await onSave(values);
        }
        actions.setSubmitting(false);

    }, [isReadonly, onSave]);

    const formActions = useCallback((formikProps: FormikProps<T>) => {
        return props.actions ? props.actions(formikProps, props) : <>
            {props.onDelete && <Grid item sx={{flexGrow: 1}}>
				<Button variant="text" color={'error'} onClick={() => handleDelete(props.item)}>{props.deleteButtonTitle || t('Smazat')}</Button>
			</Grid>}
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || (props.isReadonly ? t('Zavřít') : t('Storno'))}</Button>
			</Grid>}
            {!props.isReadonly && <Grid item>
				<Button variant="contained" type="submit" color={props.saveButtonColor || 'success'} disabled={formikProps.isSubmitting}>
                    {formikProps.isSubmitting ? <CircularProgress size={20}/> : null}
					<span>{props.saveButtonTitle || t('Uložit')}</span>
				</Button>
			</Grid>}
        </>;
    }, [props, handleDelete, t]);

    const form = useCallback((formikProps: FormikProps<T>) => {

        const fields = <Grid container columnSpacing={2} rowSpacing={1} className={'form-container'}>
            {typeof children === 'function' ? children(formikProps) : children}
        </Grid>

        return <form onSubmit={formikProps.handleSubmit}>
            <>
                {fields}
                <Grid container columnSpacing={2} marginTop={2} justifyContent="flex-end">
                    {formActions(formikProps)}
                </Grid>
            </>
            <ScrollErrorsIntoView/>
        </form>;
    }, [formActions, children]);

    return <Formik
        initialValues={item}
        enableReinitialize={true}
        validate={validate}
        validateOnChange={false}
        onSubmit={onSubmit}
    >
        {form}
    </Formik>;
}

export default FormContainer;
