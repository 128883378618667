import {JsonFindPartyResponse, JsonFindPartyResponseMatchTypesEnum, JsonPartyEventDetails, JsonPartyInfo} from "../../generated-api";
import {getOption, OptionValue} from "../../model/form";
import {eventPartyStatusOptions, isNameEqual, isStringEqualLenient} from "../../model/party";
import {Alert, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Link} from "@mui/material";
import PartyPhoto from "./PartyPhoto";
import CodebookValue from "../CodebookValue";
import {EventDetails} from "../../pages/PartiesPage";
import {AlternateEmailRounded, CameraAltRounded, CheckBoxOutlineBlankRounded, CheckBoxRounded, SpellcheckRounded} from "@mui/icons-material";
import * as React from "react";
import {useAppTranslation} from "../../services/i18n";
import PartyTags from "../PartyTags";
import {dateToGuiAs} from "../../helpers/date";
import {Link as RouterLink} from "react-router-dom";

type Props = {
    sourceParty: JsonPartyInfo,
    foundPartyResponse: JsonFindPartyResponse,
    targetParty?: JsonPartyInfo,
    eventId?: number,
    readonly?: boolean,
    handleValueChange?: (name: string, value: any) => void,
    maxHeight?: number,
    maxWidth?: number,
    withEmail?: boolean,
    noDetail?: boolean,
    isSiwi?: boolean,
    help?: JSX.Element
}

export type FoundPartyEventInfoProps = {
    foundParty: JsonPartyInfo,
    eventId?: number,
    noDetail?: boolean
}

export const FoundPartyEventInfo = (props: FoundPartyEventInfoProps) => {

    const {foundParty, eventId, noDetail} = props;

    const t = useAppTranslation();

    const currentPartyEvent: JsonPartyEventDetails | undefined = foundParty.eventDetails?.find((ed) => ed.eventId === eventId);
    const eventDetailsFilter: { eventIds: number[] } | undefined = currentPartyEvent?.eventId ? {eventIds: [currentPartyEvent.eventId]} : undefined;
    const currentPartyEventStatus: OptionValue | undefined = currentPartyEvent?.status ? getOption(currentPartyEvent.status, eventPartyStatusOptions) : undefined;

    return <>
        {!!currentPartyEventStatus && <Alert severity={'warning'} icon={false} title={currentPartyEventStatus.tooltip}>
            {t('V události ({{status}})', {
                status: currentPartyEventStatus.label,
            })}
		</Alert>}
        {noDetail ? <div></div> : <div>
            <EventDetails col={'groupId'} eventDetails={foundParty.eventDetails} filter={eventDetailsFilter}/>
            <EventDetails col={'reason'} eventDetails={foundParty.eventDetails} filter={eventDetailsFilter}/>
        </div>}
    </>;
}

export const FoundParty = (props: Props) => {
    const {eventId, readonly, sourceParty, targetParty, handleValueChange,
        withEmail, noDetail, isSiwi, help} = props;
    const fp = props.foundPartyResponse;

    const t = useAppTranslation();

    const foundParty = fp.party;
    if (!foundParty) {
        return null;
    }

    const chips = [];
    if (fp.matchTypes && fp.matchTypes.indexOf(JsonFindPartyResponseMatchTypesEnum.Name) >= 0) {
        const isExactMatch = isNameEqual(foundParty.firstName, sourceParty.firstName) && isNameEqual(foundParty.lastName, sourceParty.lastName);
        chips.push(<label key={'name'}
            title={isExactMatch ? t('Přesná shoda jména') : t('Částečná shoda jména')}
            style={{color: isExactMatch ? 'var(--color-success)' : 'var(--color-warning)'}}><SpellcheckRounded/></label>)
    }
    if (fp.matchTypes && fp.matchTypes.indexOf(JsonFindPartyResponseMatchTypesEnum.Photo) >= 0) {
        const score = Math.round((1 - (fp.photoScore || 0)) * 100);
        chips.push(<label key={'photo'}
            title={
                score > 90
                    ? t('Velmi vysoká shoda fotografie ({{score}} %)', {score})
                    : score >= 75
                        ? t('Vysoká shoda fotografie ({{score}} %)', {score})
                        : t('Částečná shoda fotografie ({{score}} %)', {score})}
            style={{
                color: score > 90
                    ? 'var(--color-success)'
                    : score >= 75
                        ? 'var(--color-info)'
                        : 'var(--color-warning)'
            }}><CameraAltRounded/></label>)
    }
    if (withEmail) {
        if (foundParty.email && foundParty.email === sourceParty.email) {
            chips.push(<label key={'email'}
                title={t('Přesná shoda emailové adresy')}
                style={{color: 'var(--color-success)'}}><AlternateEmailRounded/></label>)
        } else if (foundParty.email && sourceParty.email && foundParty.email !== sourceParty.email) {
            chips.push(<label key={'email'}
                title={t('Odlišná emailová adresa')}
                style={{color: 'var(--color-error)'}}><AlternateEmailRounded/></label>)
        }
    }

    const isSelected = targetParty?.partyId === foundParty.partyId;

    const isPhotoUsed = !!targetParty?.photoGuid && targetParty?.photoGuid === sourceParty.photoGuid;
    const isFirstNameEqual = isStringEqualLenient(foundParty.firstName, sourceParty.firstName);
    const isFirstNameUsed = isStringEqualLenient(targetParty?.firstName, sourceParty.firstName);
    const isLastNameEqual = isStringEqualLenient(foundParty.lastName, sourceParty.lastName);
    const isLastNameUsed = isStringEqualLenient(targetParty?.lastName, sourceParty.lastName);
    const isSexEqual = foundParty.sex === sourceParty.sex;
    const isSexUsed = targetParty?.sex === sourceParty.sex;
    const isEmailEqual = isStringEqualLenient(foundParty.email, sourceParty.email);
    const isEmailUsed = isStringEqualLenient(targetParty?.email, sourceParty.email);

    return <Grid item xs={(readonly || isSelected) ? 12 : 6} sx={{display: 'flex'}}>
        <Card className={'rsvp-extra-match-box' + (isSelected ? ' rsvp-extra-match-selected ' : '')}>
            <CardContent>
                <Grid container columns={isSelected && !readonly ? 24 : 12} sx={{flexGrow: 1}}>
                    <Grid item xs={5} className={'rsvp-extra-match-box-photo'} sx={{marginBottom: '-10px'}}>
                        <PartyPhoto photoGuid={isPhotoUsed ? sourceParty.photoGuid : foundParty.photoGuid} maxHeight={props.maxHeight} maxWidth={props.maxWidth}/>
                    </Grid>
                    <Grid item xs={7} className={'rsvp-extra-match-box-info'} sx={{display: 'flex', flexDirection: 'column'}}>
                        {!isSiwi && <h6>
                            <Link color='primary' underline={'hover'} to={'/parties/' + foundParty.partyId} target={'_blank'} component={RouterLink}>
                                {isFirstNameUsed ? sourceParty.firstName : foundParty.firstName}
                                <br/>
                                {isLastNameUsed ? sourceParty.lastName : foundParty.lastName}
                            </Link>
                        </h6>}
                        {isSiwi && <h6>
                            {isFirstNameUsed ? sourceParty.firstName : foundParty.firstName}
							<br/>
                            {isLastNameUsed ? sourceParty.lastName : foundParty.lastName}
                        </h6>}
                        <div className={'rsvp-extra-match-box-flags'}>{chips}</div>
                        <CodebookValue value={isSexUsed ? sourceParty.sex : foundParty.sex} name={'sex'} formatValue={(v) => v[0]}/>
                        {withEmail && <div style={{padding: '3px 0', fontSize: '80%'}}>
                            {isEmailUsed ? sourceParty.email : foundParty.email}
						</div>}
                        {!!foundParty.birthDate && <span style={{paddingBottom: '3px'}}>{dateToGuiAs(foundParty.birthDate, 'Y')}</span>}
                        {!noDetail && <PartyTags tags={foundParty.tags}/>}
                        {(isSiwi || !!help) && <pre style={{paddingBottom: '10px'}}>{foundParty.siwiId}</pre>}
                        <FoundPartyEventInfo foundParty={foundParty} eventId={eventId} noDetail={noDetail}/>
                        {!readonly && handleValueChange && <Button title={t('Spojit s osobou')} variant={'contained'} size={'small'} sx={{marginTop: '-10px'}}
							color={isSelected ? 'info' : 'inherit'}
							onClick={() => {
                                if (isSelected) {
                                    handleValueChange('partyId', undefined);
                                    handleValueChange('photoGuid', undefined);
                                    handleValueChange('firstName', undefined);
                                    handleValueChange('lastName', undefined);
                                } else {
                                    handleValueChange('partyId', foundParty.partyId);
                                }
                            }}>
                            {isSelected ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
							<span>{t('Spojit')}</span>
						</Button>}
                    </Grid>
                    {!readonly && isSelected && handleValueChange && <Grid item xs={12}>
						<div className={'rsvp-extra-match-toggles'}>
                            {!!sourceParty.photoGuid && sourceParty.photoGuid !== foundParty.photoGuid && <FormControlLabel sx={{alignSelf: 'flex-start'}}
								control={<Checkbox checked={isPhotoUsed} onChange={() => {
                                    handleValueChange('photoGuid',
                                        isPhotoUsed ? foundParty.photoGuid : sourceParty.photoGuid);
                                }}/>}
								label={t('Aktualizovat fotografii?')}
							/>}
                            {!isFirstNameEqual && <FormControlLabel sx={{alignSelf: 'flex-start'}}
								control={<Checkbox checked={isFirstNameUsed} onChange={() => {
                                    handleValueChange('firstName',
                                        isFirstNameUsed ? foundParty.firstName : sourceParty.firstName);
                                }}/>}
								label={t('Aktualizovat jméno?')}
							/>}
                            {!isLastNameEqual && <FormControlLabel sx={{alignSelf: 'flex-start'}}
								control={<Checkbox checked={isLastNameUsed} onChange={() => {
                                    handleValueChange('lastName',
                                        isLastNameUsed ? foundParty.lastName : sourceParty.lastName);
                                }}/>}
								label={t('Aktualizovat příjmení?')}
							/>}
                            {!isSexEqual && <FormControlLabel sx={{alignSelf: 'flex-start'}}
								control={<Checkbox checked={isSexUsed} onChange={() => {
                                    handleValueChange('sex',
                                        isSexUsed ? foundParty.sex : sourceParty.sex);
                                }}/>}
								label={t('Aktualizovat pohlaví?')}
							/>}
                            {withEmail && sourceParty.email && foundParty.email && !isEmailEqual && <FormControlLabel sx={{alignSelf: 'flex-start'}}
								control={<Checkbox checked={isEmailUsed} onChange={() => {
                                    handleValueChange('email',
                                        isEmailUsed ? foundParty.email : sourceParty.email);
                                }}/>}
								label={t('Aktualizovat email?')}
							/>}
						</div>
					</Grid>}
                </Grid>
                {!!help && <div style={{borderTop: '1px solid var(--light-gray-background)', margin: '5px 0 0 0', padding: '5px 0 0 0'}}>{help}</div>}
            </CardContent>
        </Card>
    </Grid>;
}
