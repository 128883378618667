import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonPlace, ApiResultOfJsonPlaceInfo, PlaceControllerApi, GetPlaceCountUsingGETRequest, GetPlaceListUsingGETRequest, JsonPlace, JsonPlaceInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const placesApi = new PlaceControllerApi(API_CONFIG);

export const fetchPlaces = createAsyncThunk('places/list', async (filter: GetPlaceListUsingGETRequest, thunkApi) => {
    return {...await placesApi.getPlaceListUsingGET(filter), filter};
});

export const fetchPlacesCount = createAsyncThunk('places/count', async (params: GetPlaceCountUsingGETRequest = {}) => {
    return await placesApi.getPlaceCountUsingGET(params);
});

export const fetchPlaceInfo = createAsyncThunk('place/info', async (placeId: number): Promise<ApiResultOfJsonPlaceInfo> => {
    return await placesApi.getPlaceInfoUsingGET({placeId});
});

export const fetchPlacesOnline = createAsyncThunk('places/online', async (filter: GetPlaceListUsingGETRequest, thunkApi) => {
    return {...await placesApi.getPlaceListUsingGET(filter), filter};
});

export const fetchPlace = createAsyncThunk('place/fetch', async (placeId: number): Promise<ApiResultOfJsonPlace> => {
    return await placesApi.getPlaceUsingGET({placeId});
});

export const savePlace = createAsyncThunk('place/save', async (place: JsonPlace): Promise<ApiResultOfJsonPlace> => {
    return await placesApi.savePlaceUsingPOST({json: place});
});

export const placesSlice = createSlice({
    name: 'places',
    initialState: (): ItemsState<JsonPlaceInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchPlaces, fetchPlacesCount);
    }
});

export const placesReducer = placesSlice.reducer;
