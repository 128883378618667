import {isPhoneValid} from "./validators";

export const PHONE_PREFIXES = ['420', '421'];

export function formatPhone(value?: string) {
    if (!value) {
        return '';
    }
    if (isPhoneValid(value)) {
        const formatted = (value && value.toString().replace(/\D/g, '')) || '';
        const phonePrefix = PHONE_PREFIXES.find((prefix: string) =>
            formatted.substr(0, formatted.length > prefix.length ? prefix.length : formatted.length) === prefix.substr(0, formatted.length > prefix.length ? prefix.length : formatted.length)
        )
        if (phonePrefix) {
            if (['420', '421'].includes(phonePrefix)) {
                return '+' + formatted.substring(0, 3)
                    + ' ' + formatted.substring(3, 6)
                    + ' ' + formatted.substring(6, 9)
                    + ' ' + formatted.substring(9);
            } else {
                return '+' + formatted.substring(0, phonePrefix.length) + ' ' + formatted.substring(phonePrefix.length);
            }
        }
        if (formatted.length === 9) {
            return formatted.substring(0, 3)
                + ' ' + formatted.substring(3, 6)
                + ' ' + formatted.substring(6);
        }
    }
    return value;
}