import {createAsyncThunk,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {InviteReplyExtraControllerApi, JsonInviteReplyExtra,} from '../generated-api';

const inviteReplyExtrasApi = new InviteReplyExtraControllerApi(API_CONFIG);


export const fetchInviteReplyExtraInfo = createAsyncThunk('invite-reply-extra/info', async (replyExtraId: number) => {
    return await inviteReplyExtrasApi.getInviteReplyExtraInfoUsingGET({replyExtraId});
});

export const fetchInviteReplyExtra = createAsyncThunk('invite-reply-extra/fetch', async (replyExtraId: number) => {
    return await inviteReplyExtrasApi.getInviteReplyExtraUsingGET({replyExtraId});
});

export const saveInviteReplyExtra = createAsyncThunk('invite-reply-extra/save', async (extra: JsonInviteReplyExtra) => {
    return await inviteReplyExtrasApi.saveInviteReplyExtraUsingPOST({json: extra});
});

