/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonTemplateData,
    JsonTemplateDataFromJSON,
    JsonTemplateDataFromJSONTyped,
    JsonTemplateDataToJSON,
} from './JsonTemplateData';

/**
 * 
 * @export
 * @interface JsonTemplate
 */
export interface JsonTemplate {
    /**
     * 
     * @type {string}
     * @memberof JsonTemplate
     */
    bodyBottom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonTemplate
     */
    bodyTop?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonTemplate
     */
    eventId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonTemplate
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonTemplate
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonTemplate
     */
    templateCode?: string;
    /**
     * 
     * @type {JsonTemplateData}
     * @memberof JsonTemplate
     */
    templateData?: JsonTemplateData;
    /**
     * 
     * @type {number}
     * @memberof JsonTemplate
     */
    templateId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTemplate
     */
    templateType?: string;
}

export function JsonTemplateFromJSON(json: any): JsonTemplate {
    return JsonTemplateFromJSONTyped(json, false);
}

export function JsonTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bodyBottom': !exists(json, 'bodyBottom') ? undefined : json['bodyBottom'],
        'bodyTop': !exists(json, 'bodyTop') ? undefined : json['bodyTop'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'isPrimary': !exists(json, 'isPrimary') ? undefined : json['isPrimary'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'templateCode': !exists(json, 'templateCode') ? undefined : json['templateCode'],
        'templateData': !exists(json, 'templateData') ? undefined : JsonTemplateDataFromJSON(json['templateData']),
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'templateType': !exists(json, 'templateType') ? undefined : json['templateType'],
    };
}

export function JsonTemplateToJSON(value?: JsonTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bodyBottom': value.bodyBottom,
        'bodyTop': value.bodyTop,
        'eventId': value.eventId,
        'isPrimary': value.isPrimary,
        'subject': value.subject,
        'templateCode': value.templateCode,
        'templateData': JsonTemplateDataToJSON(value.templateData),
        'templateId': value.templateId,
        'templateType': value.templateType,
    };
}

