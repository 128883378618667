/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonFormat
 */
export interface JsonFormat {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonFormat
     */
    allowedActions?: Array<JsonFormatAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonFormat
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonFormat
     */
    contingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFormat
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormat
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFormat
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFormat
     */
    formatCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormat
     */
    formatId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFormat
     */
    status?: JsonFormatStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonFormat
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFormat
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormat
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonFormatAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonFormatStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonFormatFromJSON(json: any): JsonFormat {
    return JsonFormatFromJSONTyped(json, false);
}

export function JsonFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFormat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'contingentOwner': !exists(json, 'contingentOwner') ? undefined : json['contingentOwner'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'formatId': !exists(json, 'formatId') ? undefined : json['formatId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonFormatToJSON(value?: JsonFormat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'contingentOwner': value.contingentOwner,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'formatCode': value.formatCode,
        'formatId': value.formatId,
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

