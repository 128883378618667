import FormModal from "../components/form/FormModal";
import {JsonInvite, JsonInviteStatusEnum} from "../generated-api";
import {useAppTranslation} from "../services/i18n";
import InviteForm, {InviteFormProps} from "../components/invite/InviteForm";
import * as React from "react";
import {useCallback} from "react";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import {saveInvite} from "../store/invites";
import {useAppDispatch} from "../store";

const inviteName = (item: JsonInvite) => {
    return item.inviteId;
}

interface InviteModalProps extends InviteFormProps {
}

const InviteModal = (props: InviteModalProps) => {

    const {item, onSave} = props;
    const {inviteId} = item;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const handleSaveInvite = useCallback(async (invite: JsonInvite, isDelete?: boolean) => {
        const res = await dispatch(saveInvite({...invite, status: isDelete ? JsonInviteStatusEnum.Deleted : invite.status}));
        const item = getApiResult<JsonInvite>(res);
        if (item) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: ['Pozvánka {{title}} ponechána beze změn', inviteName],
                [ApiChangeType.UPDATED]: [isDelete ? 'Pozvánka {{title}} úspěšně odstraněna' : 'Pozvánka {{title}} úspěšně upravena', inviteName],
                [ApiChangeType.CREATED]: ['Pozvánka {{title}} úspěšně vytvořena', inviteName]
            }, t, dispatch);

            if (onSave) {
                onSave(item);
            }
        }

    }, [onSave, dispatch, t])

    return (
        <FormModal title={inviteId
            ? t('Úprava pozvánky')
            : t('Nová pozvánka')} {...props}>
            <InviteForm {...props} onSave={handleSaveInvite}></InviteForm>
        </FormModal>
    );
}

export default InviteModal;
