import FormModal from "../components/form/FormModal";
import {JsonPlace} from "../generated-api";
import {FormProps} from "../model/form";
import {useAppTranslation} from "../services/i18n";
import PlaceForm from "../components/place/PlaceForm";

const PlaceModal = (props: FormProps<JsonPlace>) => {

    const t = useAppTranslation();

    return (
        <FormModal title={props.item?.placeId ? t('Úprava místa') : t('Nové místo')} {...props}>
            <PlaceForm {...props}></PlaceForm>
        </FormModal>
    );
}

export default PlaceModal;
