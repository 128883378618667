/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFloorItem,
    JsonFloorItemFromJSON,
    JsonFloorItemFromJSONTyped,
    JsonFloorItemToJSON,
} from './JsonFloorItem';

/**
 * 
 * @export
 * @interface JsonFloorDayData
 */
export interface JsonFloorDayData {
    /**
     * 
     * @type {Array<JsonFloorItem>}
     * @memberof JsonFloorDayData
     */
    items?: Array<JsonFloorItem>;
}

export function JsonFloorDayDataFromJSON(json: any): JsonFloorDayData {
    return JsonFloorDayDataFromJSONTyped(json, false);
}

export function JsonFloorDayDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFloorDayData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JsonFloorItemFromJSON)),
    };
}

export function JsonFloorDayDataToJSON(value?: JsonFloorDayData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JsonFloorItemToJSON)),
    };
}

