/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventData,
    JsonEventDataFromJSON,
    JsonEventDataFromJSONTyped,
    JsonEventDataToJSON,
} from './JsonEventData';

/**
 * 
 * @export
 * @interface JsonEventInfo
 */
export interface JsonEventInfo {
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    abbr?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventInfo
     */
    allowedActions?: Array<JsonEventInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    cleanFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    cleanTo?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    description?: string;
    /**
     * 
     * @type {JsonEventData}
     * @memberof JsonEventInfo
     */
    eventData?: JsonEventData;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    eventFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventInfo
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    eventTo?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventInfo
     */
    invitePendingCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventInfo
     */
    partyPendingCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventInfo
     */
    partyTotalCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    prepareFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    prepareTo?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    siwiId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    status?: JsonEventInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}

export function JsonEventInfoFromJSON(json: any): JsonEventInfo {
    return JsonEventInfoFromJSONTyped(json, false);
}

export function JsonEventInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'abbr': !exists(json, 'abbr') ? undefined : json['abbr'],
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'cleanFrom': !exists(json, 'cleanFrom') ? undefined : json['cleanFrom'],
        'cleanTo': !exists(json, 'cleanTo') ? undefined : json['cleanTo'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'eventData': !exists(json, 'eventData') ? undefined : JsonEventDataFromJSON(json['eventData']),
        'eventFrom': !exists(json, 'eventFrom') ? undefined : json['eventFrom'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventTo': !exists(json, 'eventTo') ? undefined : json['eventTo'],
        'invitePendingCnt': !exists(json, 'invitePendingCnt') ? undefined : json['invitePendingCnt'],
        'partyPendingCnt': !exists(json, 'partyPendingCnt') ? undefined : json['partyPendingCnt'],
        'partyTotalCnt': !exists(json, 'partyTotalCnt') ? undefined : json['partyTotalCnt'],
        'prepareFrom': !exists(json, 'prepareFrom') ? undefined : json['prepareFrom'],
        'prepareTo': !exists(json, 'prepareTo') ? undefined : json['prepareTo'],
        'siwiId': !exists(json, 'siwiId') ? undefined : json['siwiId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventInfoToJSON(value?: JsonEventInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'abbr': value.abbr,
        'allowedActions': value.allowedActions,
        'cleanFrom': value.cleanFrom,
        'cleanTo': value.cleanTo,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'description': value.description,
        'eventData': JsonEventDataToJSON(value.eventData),
        'eventFrom': value.eventFrom,
        'eventId': value.eventId,
        'eventTo': value.eventTo,
        'invitePendingCnt': value.invitePendingCnt,
        'partyPendingCnt': value.partyPendingCnt,
        'partyTotalCnt': value.partyTotalCnt,
        'prepareFrom': value.prepareFrom,
        'prepareTo': value.prepareTo,
        'siwiId': value.siwiId,
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

