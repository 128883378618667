/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEmailMessage,
    ApiResultOfJsonEmailMessageFromJSON,
    ApiResultOfJsonEmailMessageToJSON,
    ApiResultOfListOfJsonTemplate,
    ApiResultOfListOfJsonTemplateFromJSON,
    ApiResultOfListOfJsonTemplateToJSON,
    JsonEmailPreviewRequest,
    JsonEmailPreviewRequestFromJSON,
    JsonEmailPreviewRequestToJSON,
} from '../models';

export interface GetNotificationTemplatesUsingGETRequest {
    notificationType?: GetNotificationTemplatesUsingGETNotificationTypeEnum;
    eventId?: number;
    templateId?: number;
}

export interface PreviewNotificationUsingPOSTRequest {
    request: JsonEmailPreviewRequest;
    notificationType?: PreviewNotificationUsingPOSTNotificationTypeEnum;
}

/**
 * 
 */
export class NotificationControllerApi extends runtime.BaseAPI {

    /**
     * getNotificationTemplates
     */
    async getNotificationTemplatesUsingGETRaw(requestParameters: GetNotificationTemplatesUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonTemplate>> {
        const queryParameters: any = {};

        if (requestParameters.notificationType !== undefined) {
            queryParameters['notificationType'] = requestParameters.notificationType;
        }

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.templateId !== undefined) {
            queryParameters['templateId'] = requestParameters.templateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/notification/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonTemplateFromJSON(jsonValue));
    }

    /**
     * getNotificationTemplates
     */
    async getNotificationTemplatesUsingGET(requestParameters: GetNotificationTemplatesUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonTemplate> {
        const response = await this.getNotificationTemplatesUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * previewNotification
     */
    async previewNotificationUsingPOSTRaw(requestParameters: PreviewNotificationUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEmailMessage>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling previewNotificationUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.notificationType !== undefined) {
            queryParameters['notificationType'] = requestParameters.notificationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/notification/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEmailPreviewRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEmailMessageFromJSON(jsonValue));
    }

    /**
     * previewNotification
     */
    async previewNotificationUsingPOST(requestParameters: PreviewNotificationUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEmailMessage> {
        const response = await this.previewNotificationUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetNotificationTemplatesUsingGETNotificationTypeEnum {
    UserActivate = 'USER_ACTIVATE',
    UserRecover = 'USER_RECOVER',
    InviteGuest = 'INVITE_GUEST',
    InviteGuestReturn = 'INVITE_GUEST_RETURN',
    InviteGuestConfirm = 'INVITE_GUEST_CONFIRM',
    InviteGuestCancel = 'INVITE_GUEST_CANCEL',
    InviteOrg = 'INVITE_ORG',
    InviteOrgReturn = 'INVITE_ORG_RETURN',
    InviteOrgConfirm = 'INVITE_ORG_CONFIRM',
    InviteOrgCancel = 'INVITE_ORG_CANCEL',
    InviteSupp = 'INVITE_SUPP',
    InviteSuppReturn = 'INVITE_SUPP_RETURN',
    InviteSuppConfirm = 'INVITE_SUPP_CONFIRM',
    InviteSuppCancel = 'INVITE_SUPP_CANCEL',
    UpdateOrg = 'UPDATE_ORG',
    UpdateOrgReturn = 'UPDATE_ORG_RETURN',
    UpdateOrgConfirm = 'UPDATE_ORG_CONFIRM',
    UpdateOrgCancel = 'UPDATE_ORG_CANCEL',
    TravelOrg = 'TRAVEL_ORG',
    TravelOrgReturn = 'TRAVEL_ORG_RETURN',
    TravelOrgConfirm = 'TRAVEL_ORG_CONFIRM',
    TravelOrgCancel = 'TRAVEL_ORG_CANCEL',
    CompOrg = 'COMP_ORG',
    CompOrgReturn = 'COMP_ORG_RETURN',
    CompOrgConfirm = 'COMP_ORG_CONFIRM',
    CompOrgCancel = 'COMP_ORG_CANCEL',
    EventGroupOrg = 'EVENT_GROUP_ORG',
    SiwiPrinted = 'SIWI_PRINTED'
}
/**
    * @export
    * @enum {string}
    */
export enum PreviewNotificationUsingPOSTNotificationTypeEnum {
    UserActivate = 'USER_ACTIVATE',
    UserRecover = 'USER_RECOVER',
    InviteGuest = 'INVITE_GUEST',
    InviteGuestReturn = 'INVITE_GUEST_RETURN',
    InviteGuestConfirm = 'INVITE_GUEST_CONFIRM',
    InviteGuestCancel = 'INVITE_GUEST_CANCEL',
    InviteOrg = 'INVITE_ORG',
    InviteOrgReturn = 'INVITE_ORG_RETURN',
    InviteOrgConfirm = 'INVITE_ORG_CONFIRM',
    InviteOrgCancel = 'INVITE_ORG_CANCEL',
    InviteSupp = 'INVITE_SUPP',
    InviteSuppReturn = 'INVITE_SUPP_RETURN',
    InviteSuppConfirm = 'INVITE_SUPP_CONFIRM',
    InviteSuppCancel = 'INVITE_SUPP_CANCEL',
    UpdateOrg = 'UPDATE_ORG',
    UpdateOrgReturn = 'UPDATE_ORG_RETURN',
    UpdateOrgConfirm = 'UPDATE_ORG_CONFIRM',
    UpdateOrgCancel = 'UPDATE_ORG_CANCEL',
    TravelOrg = 'TRAVEL_ORG',
    TravelOrgReturn = 'TRAVEL_ORG_RETURN',
    TravelOrgConfirm = 'TRAVEL_ORG_CONFIRM',
    TravelOrgCancel = 'TRAVEL_ORG_CANCEL',
    CompOrg = 'COMP_ORG',
    CompOrgReturn = 'COMP_ORG_RETURN',
    CompOrgConfirm = 'COMP_ORG_CONFIRM',
    CompOrgCancel = 'COMP_ORG_CANCEL',
    EventGroupOrg = 'EVENT_GROUP_ORG',
    SiwiPrinted = 'SIWI_PRINTED'
}
