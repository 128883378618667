/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonEventFloorData
 */
export interface JsonEventFloorData {
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorData
     */
    svgFixedItems?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorData
     */
    svgHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorData
     */
    svgWidth?: number;
}

export function JsonEventFloorDataFromJSON(json: any): JsonEventFloorData {
    return JsonEventFloorDataFromJSONTyped(json, false);
}

export function JsonEventFloorDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventFloorData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'svgFixedItems': !exists(json, 'svgFixedItems') ? undefined : json['svgFixedItems'],
        'svgHeight': !exists(json, 'svgHeight') ? undefined : json['svgHeight'],
        'svgWidth': !exists(json, 'svgWidth') ? undefined : json['svgWidth'],
    };
}

export function JsonEventFloorDataToJSON(value?: JsonEventFloorData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'svgFixedItems': value.svgFixedItems,
        'svgHeight': value.svgHeight,
        'svgWidth': value.svgWidth,
    };
}

