/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventPartyDay,
    JsonEventPartyDayFromJSON,
    JsonEventPartyDayFromJSONTyped,
    JsonEventPartyDayToJSON,
} from './JsonEventPartyDay';
import {
    JsonEventPartyTravel,
    JsonEventPartyTravelFromJSON,
    JsonEventPartyTravelFromJSONTyped,
    JsonEventPartyTravelToJSON,
} from './JsonEventPartyTravel';
import {
    JsonInviteReplyExtra,
    JsonInviteReplyExtraFromJSON,
    JsonInviteReplyExtraFromJSONTyped,
    JsonInviteReplyExtraToJSON,
} from './JsonInviteReplyExtra';

/**
 * 
 * @export
 * @interface JsonReplyProcessData
 */
export interface JsonReplyProcessData {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: JsonEventPartyDay; }; }}
     * @memberof JsonReplyProcessData
     */
    eventPartyDays?: { [key: string]: { [key: string]: JsonEventPartyDay; }; };
    /**
     * 
     * @type {Array<JsonInviteReplyExtra>}
     * @memberof JsonReplyProcessData
     */
    extras?: Array<JsonInviteReplyExtra>;
    /**
     * 
     * @type {boolean}
     * @memberof JsonReplyProcessData
     */
    isInfoAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonReplyProcessData
     */
    isPhotoAccepted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonReplyProcessData
     */
    processNote?: string;
    /**
     * 
     * @type {Array<JsonEventPartyTravel>}
     * @memberof JsonReplyProcessData
     */
    travels?: Array<JsonEventPartyTravel>;
}

export function JsonReplyProcessDataFromJSON(json: any): JsonReplyProcessData {
    return JsonReplyProcessDataFromJSONTyped(json, false);
}

export function JsonReplyProcessDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonReplyProcessData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventPartyDays': !exists(json, 'eventPartyDays') ? undefined : json['eventPartyDays'],
        'extras': !exists(json, 'extras') ? undefined : ((json['extras'] as Array<any>).map(JsonInviteReplyExtraFromJSON)),
        'isInfoAccepted': !exists(json, 'isInfoAccepted') ? undefined : json['isInfoAccepted'],
        'isPhotoAccepted': !exists(json, 'isPhotoAccepted') ? undefined : json['isPhotoAccepted'],
        'processNote': !exists(json, 'processNote') ? undefined : json['processNote'],
        'travels': !exists(json, 'travels') ? undefined : ((json['travels'] as Array<any>).map(JsonEventPartyTravelFromJSON)),
    };
}

export function JsonReplyProcessDataToJSON(value?: JsonReplyProcessData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventPartyDays': value.eventPartyDays,
        'extras': value.extras === undefined ? undefined : ((value.extras as Array<any>).map(JsonInviteReplyExtraToJSON)),
        'isInfoAccepted': value.isInfoAccepted,
        'isPhotoAccepted': value.isPhotoAccepted,
        'processNote': value.processNote,
        'travels': value.travels === undefined ? undefined : ((value.travels as Array<any>).map(JsonEventPartyTravelToJSON)),
    };
}

