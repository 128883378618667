/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFileInfo,
    JsonFileInfoFromJSON,
    JsonFileInfoFromJSONTyped,
    JsonFileInfoToJSON,
} from './JsonFileInfo';

/**
 * 
 * @export
 * @interface JsonEmailMessage
 */
export interface JsonEmailMessage {
    /**
     * 
     * @type {Array<JsonFileInfo>}
     * @memberof JsonEmailMessage
     */
    attachFileInfos?: Array<JsonFileInfo>;
    /**
     * 
     * @type {string}
     * @memberof JsonEmailMessage
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEmailMessage
     */
    mailFromTitle?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEmailMessage
     */
    mailToTitles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JsonEmailMessage
     */
    subject?: string;
}

export function JsonEmailMessageFromJSON(json: any): JsonEmailMessage {
    return JsonEmailMessageFromJSONTyped(json, false);
}

export function JsonEmailMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEmailMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachFileInfos': !exists(json, 'attachFileInfos') ? undefined : ((json['attachFileInfos'] as Array<any>).map(JsonFileInfoFromJSON)),
        'body': !exists(json, 'body') ? undefined : json['body'],
        'mailFromTitle': !exists(json, 'mailFromTitle') ? undefined : json['mailFromTitle'],
        'mailToTitles': !exists(json, 'mailToTitles') ? undefined : json['mailToTitles'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
    };
}

export function JsonEmailMessageToJSON(value?: JsonEmailMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachFileInfos': value.attachFileInfos === undefined ? undefined : ((value.attachFileInfos as Array<any>).map(JsonFileInfoToJSON)),
        'body': value.body,
        'mailFromTitle': value.mailFromTitle,
        'mailToTitles': value.mailToTitles,
        'subject': value.subject,
    };
}

