import {Middleware, ResponseContext} from '../generated-api';
import {ApiSerializedError} from '../model/api';

const priorityHttpStatusCodes = [401, 403];

export function fetchErrorMiddlewareFactory(): Middleware {
    return {
        post: async (
            context: ResponseContext,
        ): Promise<ResponseContext['response'] | void> => {

            // check API ERROR in payload
            if (context.response.status === 200) {
                let json: any;
                try {
                    json = await context.response.clone().json();
                } catch (e) {
                    // not a json
                    json = {};
                }
                if (json.result === 'ERROR') {
                    const err = {
                        code: json.message,
                        message: json.messageValues?.error
                            || (json.message + (json.messageValues ? ' (' + JSON.stringify(json.messageValues) + ')' : ''))
                    };
                    if (err.message === 'VALIDATION_ERROR') {
                        if (json.messageValues?.description) {
                            err.message = json.messageValues.description;
                        }
                    }
                    throw err;
                }
            }

            if (context.response.status >= 200 && context.response.status < 300) {
                return context.response;
            }

            let err: ApiSerializedError;
            try {
                const json = await context.response.json();
                const status = context.response.status;
                err = {
                    code: priorityHttpStatusCodes.includes(status)
                        ? `${status}`
                        : json?.code || context.response.status,
                    message: json?.message || undefined,
                };
            } catch (e: any) {
                err = {
                    code: `${context.response.status}`,
                    message: undefined,
                };
            }

            console.log(
                '[API] Error:',
                context.url,
                {
                    body: context.init.body,
                    status: context.response.status,
                    ok: context.response.ok,
                },
                err,
            );

            throw err;
        },
    };
}
