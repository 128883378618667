import {Alert, Box, Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FormikErrors, FormikProps, useFormikContext} from 'formik';
import {createOption, FAKE_VALUE_EMPTY, FAKE_VALUE_RESET, formatMoney, FormProps, getOption, isNumeric, normalizeMoney, OptionValue, setNestedKey} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {SelectFormField} from "../form/SelectFormField";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {articleName, articleSexOptions, PartyMassActionProps, PartyMassActionValues, partyName} from "../../model/party";
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {
    JsonArticleInfo,
    JsonArticleTypeInfo,
    JsonAuthUserInfo,
    JsonEventDayInfo,
    JsonEventPartyArticle,
    JsonEventPartyCompInfo,
    JsonEventPartyDay,
    JsonEventPartyDayDetails,
    JsonEventPartyImportSexEnum,
    JsonEventPartyInfo,
    JsonEventPartyInfoPartyTypeEnum,
    JsonEventPartyMassActionRequest,
    JsonEventPartyMassActionRequestActionEnum,
    JsonEventPartyMassActionRequestContractStatusEnum,
    JsonEventPartyMassActionRequestUpdateTypeEnum,
    JsonPlaceFoodServicesEnum,
    JsonTariffInfo
} from "../../generated-api";
import CodebookValue from "../CodebookValue";
import {dateToGuiAs} from "../../helpers/date";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {getApiResult} from "../../helpers/api";
import {fetchEventDays} from "../../store/eventDays";
import {foodServiceFlags, foodServiceOptions, foodServiceProps} from "../../model/place";
import {CheckboxField, CheckboxPlain} from "../form/CheckboxField";
import {SimpleSelectFormField} from "../form/SimpleSelectFormField";
import {fetchArticleTypes} from "../../store/articleTypes";
import EventDaysValue from "../EventDaysValue";
import EventDayDetails from "./EventDayDetails";
import {ClearRounded, ContentPasteOffRounded, CopyAllRounded, FormatColorFillOutlined, KeyboardDoubleArrowLeft} from "@mui/icons-material";
import {fetchArticles} from "../../store/articles";
import {articleSize, shortenInitials} from "../../helpers/format";
import {TextFormField, TextFormFieldPlain} from "../form/TextFormField";
import {selectAuthInfo} from "../../store/selectors";
import {ROLE_ADMIN} from "../../store/codebooks";
import {fetchTariffs} from "../../store/tariffs";
import {EventPartyComp} from "../../pages/EventPartiesPage";
import {FormikHelpers} from "formik/dist/types";

interface Props extends PartyMassActionProps<PartyMassActionValues<any>, any> {

}

interface LocalFilter extends DataGridFilter {
    action?: JsonEventPartyMassActionRequestActionEnum;
    eventId?: number;
    eventDay?: number;
}

interface PartiesGridState extends DataGridState<JsonEventPartyInfo, LocalFilter> {
}

const defaultState: PartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: undefined,
        action: undefined,
        eventId: undefined
    },
};

const texts: { [key in JsonEventPartyMassActionRequestActionEnum]?: [string, string, string, ('error')?] } = {
    [JsonEventPartyMassActionRequestActionEnum.Update]: [
        'Uložit změny',
        'Upravujete data pro {{itemsCount}}',
        'Nezbyl nikdo, koho by bylo možné upravit'],
    [JsonEventPartyMassActionRequestActionEnum.Delete]: [
        'Odstranit z události',
        'Odstraňujete {{itemsCount}}',
        'Nezbyl nikdo, koho by bylo možné odstranit',
        'error'],
    [JsonEventPartyMassActionRequestActionEnum.Comp]: [
        'Uložit odměny',
        'Upravujete odměny pro {{itemsCount}}',
        'Nezbyl nikdo, koho by bylo možné upravit'],
};

const articleStyle = {
    padding: "3px 3px",
    '& .MuiInputBase-inputSizeSmall': {
        fontSize: '85%',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    '& .MuiSelect-select': {
        paddingRight: '14px !important',
        paddingLeft: '6px !important',
    },
    '& .MuiSelect-icon': {
        right: '2px',
    }
};

const getCommonValue = <E, T extends keyof E>(items: E[], col: T): E[T] | number | undefined => {
    let value: E[T] | number | undefined = FAKE_VALUE_RESET;
    items.forEach((ep) => {
        if (value === undefined) {
            return;
        }
        const v = ep[col] === undefined ? FAKE_VALUE_RESET : ep[col];
        if (value === FAKE_VALUE_RESET) {
            value = v;
        } else if (value !== v) {
            value = undefined;
        }
    });

    return value === undefined ? FAKE_VALUE_EMPTY : value;
}

const getCommonBoolean = <E, T extends keyof E>(items: E[], col: T): E[T] | boolean | undefined => {
    let value: E[T] | boolean | undefined = false;
    items.forEach((ep) => {
        if (value === undefined) {
            return;
        }
        const v = ep[col] === undefined ? false : ep[col];
        if (value === false) {
            value = v;
        } else if (value !== v) {
            value = undefined;
        }
    });

    return value;
}

const getCommonArticle = (items: JsonEventPartyInfo[], articleTypeId: number | undefined, col: keyof JsonEventPartyArticle) => {
    let value: number | undefined = FAKE_VALUE_RESET;
    items.forEach((ep) => {
        if (ep === undefined) {
            return;
        }
        const articleId = ep.eventArticleDetails?.find((epa) => epa.articleTypeId === articleTypeId)?.[col] as any as number;
        const v = articleId === undefined ? FAKE_VALUE_RESET : articleId;
        if (value === FAKE_VALUE_RESET) {
            value = v;
        } else if (value !== v) {
            value = undefined;
        }
    });

    return value === undefined ? FAKE_VALUE_EMPTY : value;
}

const getCommonContractStatus = (items: JsonEventPartyInfo[]): JsonEventPartyMassActionRequestContractStatusEnum | undefined => {
    let value: JsonEventPartyMassActionRequestContractStatusEnum | undefined = JsonEventPartyMassActionRequestContractStatusEnum.None;
    items.forEach((ep) => {
        if (ep === undefined) {
            return;
        }
        const v = ep.contractStatus === undefined
            ? JsonEventPartyMassActionRequestContractStatusEnum.None
            : ep.contractStatus as any as JsonEventPartyMassActionRequestContractStatusEnum;
        if (value === JsonEventPartyMassActionRequestContractStatusEnum.None) {
            value = v;
        } else if (value !== v) {
            value = undefined;
        }
    });

    return value === undefined ? undefined : value;
}

export const getCommonFoodCnt = (items: JsonEventPartyDayDetails[], allIncluded: boolean): { [key in JsonPlaceFoodServicesEnum]?: number | undefined } => {
    const common: { [key in JsonPlaceFoodServicesEnum]?: number | undefined } = {};
    if (!allIncluded) {
        // not all parties got all days - assume "false" as baseline
        Object.keys(foodServiceFlags).forEach((serviceType) => {
            common[serviceType as JsonPlaceFoodServicesEnum] = 0;
        })
    }
    items.forEach((ep) => {
        Object.keys(foodServiceFlags).forEach((st) => {
            const serviceType = st as JsonPlaceFoodServicesEnum;
            const foodProp = foodServiceProps[serviceType];
            const v = !!ep[foodProp] ? (ep.foodCnt?.[serviceType] || 1) : 0;
            if (Object.keys(common).indexOf(st) < 0) {
                common[serviceType] = v;
                return;
            }
            if (common[serviceType] === undefined) {
                return;
            }
            if (common[serviceType] !== v) {
                common[serviceType] = undefined;
            }
        });

    });
    return common;
}

const getCommonComp = (items: JsonEventPartyInfo[], tariffId: number | undefined, col: keyof JsonEventPartyCompInfo) => {
    let value: number | undefined = FAKE_VALUE_RESET;
    items.forEach((ep) => {
        if (ep === undefined) {
            return;
        }
        const compId = ep.eventCompDetails?.find((epc) => epc.tariffId === tariffId)?.[col] as any as number;
        const v = compId === undefined ? FAKE_VALUE_RESET : compId;
        if (value === FAKE_VALUE_RESET) {
            value = v;
        } else if (value !== v) {
            value = undefined;
        }
    });

    return value === undefined ? FAKE_VALUE_EMPTY : value;
}

const ActionButtons = ({item, clipboard, setClipboard, i, common}: {
    item: JsonEventPartyDay | undefined,
    clipboard: JsonEventPartyDay | undefined,
    setClipboard: (c: JsonEventPartyDay) => void
    i: number,
    common: JsonEventPartyDay | undefined
}) => {
    const t = useAppTranslation();
    const {setFieldValue} = useFormikContext<JsonEventPartyMassActionRequest>();

    if (!item) {
        return null;
    }

    const rows: JSX.Element[] = [];


    const isEqualWithClipboard = item?.foodBrId === clipboard?.foodBrId
        && item?.foodLuId === clipboard?.foodLuId
        && item?.foodDiId === clipboard?.foodDiId
        && item?.foodSnId === clipboard?.foodSnId
        && item?.acmId === clipboard?.acmId
        && item?.acmCnt === clipboard?.acmCnt
        && item?.parkId === clipboard?.parkId
        && item?.parkCnt === clipboard?.parkCnt
        && item?.parkRec === clipboard?.parkRec
        && item?.parkPlates === clipboard?.parkPlates;

    if (clipboard) {
        if (!isEqualWithClipboard) {
            rows.push(<Button key='paste' title={t('Vložit ze schránky', clipboard)} onClick={() => {
                for (const k of Object.keys(foodServiceProps)) {
                    const prop = foodServiceProps[k as JsonPlaceFoodServicesEnum];
                    setFieldValue('eventPartyDays.' + i + '.' + prop, common?.[prop] === FAKE_VALUE_RESET && clipboard?.[prop] === FAKE_VALUE_RESET
                        ? FAKE_VALUE_EMPTY : (clipboard?.[prop] === common?.[prop] ? FAKE_VALUE_EMPTY : clipboard?.[prop]));
                }
                setFieldValue('eventPartyDays.' + i + '.foodCnt', clipboard?.foodCnt);

                setFieldValue('eventPartyDays.' + i + '.acmId', common?.acmId === FAKE_VALUE_RESET && clipboard?.acmId === FAKE_VALUE_RESET
                    ? FAKE_VALUE_EMPTY : (clipboard?.acmId === common?.acmId ? FAKE_VALUE_EMPTY : clipboard?.acmId));
                setFieldValue('eventPartyDays.' + i + '.acmCnt', common?.acmCnt === undefined && clipboard?.acmCnt === undefined
                    ? undefined : clipboard?.acmCnt);

                setFieldValue('eventPartyDays.' + i + '.parkId', common?.parkId === FAKE_VALUE_RESET && clipboard?.parkId === FAKE_VALUE_RESET
                    ? FAKE_VALUE_EMPTY : (clipboard?.parkId === common?.parkId ? FAKE_VALUE_EMPTY : clipboard?.parkId));
                setFieldValue('eventPartyDays.' + i + '.parkCnt', common?.parkCnt === undefined && clipboard?.parkCnt === undefined
                    ? undefined : clipboard?.parkCnt);
                setFieldValue('eventPartyDays.' + i + '.parkRec', common?.parkRec === undefined && clipboard?.parkRec === undefined
                    ? undefined : clipboard?.parkRec);
                setFieldValue('eventPartyDays.' + i + '.parkPlates', common?.parkPlates === undefined && clipboard?.parkPlates === undefined
                    ? undefined : clipboard?.parkPlates);
            }}><KeyboardDoubleArrowLeft/></Button>)
        }
    }

    if (item?.foodBrId !== FAKE_VALUE_EMPTY
        || item?.foodLuId !== FAKE_VALUE_EMPTY
        || item?.foodDiId !== FAKE_VALUE_EMPTY
        || item?.foodSnId !== FAKE_VALUE_EMPTY
        || JSON.stringify(item?.foodCnt) !== '{}'
        || item?.acmId !== FAKE_VALUE_EMPTY
        || (item?.acmCnt !== undefined && (item?.acmCnt as any) !== '')
        || item?.parkId !== FAKE_VALUE_EMPTY
        || (item?.parkCnt !== undefined && (item?.parkCnt as any) !== '')
        || (item?.parkRec !== undefined && (item?.parkRec as any) !== '')
        || ((item?.parkPlates || '') !== (common?.parkPlates || ''))
    ) {
        if (!clipboard) {
            rows.push(<Button key='copy' title={t('Zkopírovat do schránky')} onClick={() => {
                setClipboard(item);
            }}><CopyAllRounded/></Button>)
        }

        rows.push(<Button key='clear' title={t('Vymazat')} onClick={() => {
            setFieldValue('eventPartyDays.' + i + '.foodBrId', FAKE_VALUE_EMPTY);
            setFieldValue('eventPartyDays.' + i + '.foodLuId', FAKE_VALUE_EMPTY);
            setFieldValue('eventPartyDays.' + i + '.foodDiId', FAKE_VALUE_EMPTY);
            setFieldValue('eventPartyDays.' + i + '.foodSnId', FAKE_VALUE_EMPTY);
            setFieldValue('eventPartyDays.' + i + '.foodCnt', {});
            setFieldValue('eventPartyDays.' + i + '.acmId', FAKE_VALUE_EMPTY);
            setFieldValue('eventPartyDays.' + i + '.acmCnt', undefined);
            setFieldValue('eventPartyDays.' + i + '.parkId', FAKE_VALUE_EMPTY);
            setFieldValue('eventPartyDays.' + i + '.parkCnt', undefined);
            setFieldValue('eventPartyDays.' + i + '.parkRec', undefined);
            setFieldValue('eventPartyDays.' + i + '.parkPlates', common?.parkPlates);
        }}><ClearRounded/></Button>)
    }

    return <div className={'event-details'}>
        {rows}
    </div>
}

const getArticleOption = (article: JsonArticleInfo): string => {
    const sexOption = getOption(article.sex, articleSexOptions);
    return articleSize(article?.sex, article?.size) + (sexOption.tooltip ? ' ' + sexOption.tooltip : '');
}

const isParkingPlateAllowed = (commonValues: JsonEventPartyMassActionRequest | undefined, values: JsonEventPartyMassActionRequest, i: number) => {
    const common = commonValues?.eventPartyDays ? commonValues?.eventPartyDays[i] : undefined;
    return !((common?.parkId === FAKE_VALUE_RESET && !((values.eventPartyDays?.[i]?.parkId || 0) > 0))
        || (common?.parkId !== FAKE_VALUE_RESET && values.eventPartyDays?.[i]?.parkId === FAKE_VALUE_RESET));
}

const isDayEnabled = (commonValues: JsonEventPartyMassActionRequest | undefined, values: JsonEventPartyMassActionRequest, i: number) => {
    const common = commonValues?.eventPartyDays ? commonValues?.eventPartyDays[i] : undefined;
    return !((common?.eventDayId === FAKE_VALUE_RESET && !((values.eventPartyDays?.[i]?.eventDayId || 0) > 0))
        || (common?.eventDayId !== FAKE_VALUE_RESET && values.eventPartyDays?.[i]?.eventDayId === FAKE_VALUE_RESET));
}

const autofillByKey = (key: keyof JsonEventPartyDay,
                       eventDays: JsonEventDayInfo[] | undefined,
                       values: JsonEventPartyMassActionRequest,
                       setFieldValue: FormikHelpers<JsonEventPartyMassActionRequest>['setFieldValue'],
                       check?: (values: JsonEventPartyMassActionRequest, i: number) => boolean) => {
    let lastValue: JsonEventPartyDay[typeof key];
    eventDays?.forEach((_, i) => {
        if (check && !check(values, i)) {
            return;
        }
        const v = values.eventPartyDays?.[i]?.[key];
        if (!!v && v !== FAKE_VALUE_EMPTY && v !== FAKE_VALUE_RESET) {
            lastValue = v;
            return;
        }
        if (lastValue) {
            setFieldValue('eventPartyDays.' + i + '.' + key, lastValue);
        }
    });
}

const clearAllByKey = (key: keyof JsonEventPartyDay,
                       eventDays: JsonEventDayInfo[] | undefined,
                       values: JsonEventPartyMassActionRequest,
                       setFieldValue: FormikHelpers<JsonEventPartyMassActionRequest>['setFieldValue'],
                       isRaw?: boolean) => {
    eventDays?.forEach((_, i) => {
        const v = values.eventPartyDays?.[i]?.[key];
        if (isRaw) {
            if (!!v) {
                setFieldValue('eventPartyDays.' + i + '.' + key, undefined);
            }
            return;
        }
        setFieldValue('eventPartyDays.' + i + '.' + key, FAKE_VALUE_EMPTY);
    });
}

const PartyMassUpdateForm = (props: Props) => {

    const {massAction} = props;
    const {eventParties} = massAction;
    const massActionValues = props.massAction.values;
    const {action, eventId} = massActionValues;
    const updateType = action === JsonEventPartyMassActionRequestActionEnum.Comp
        ? JsonEventPartyMassActionRequestUpdateTypeEnum.Comp
        : massActionValues.updateType;

    const isDelete = action === JsonEventPartyMassActionRequestActionEnum.Delete;
    const isArticles = (!updateType
        || updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.Article
        || updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.ArticleContract) && !isDelete;
    const isDays = (!updateType || updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.Days) && !isDelete;
    const isGroup = (!updateType || updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.Group) && !isDelete;
    const isComp = updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.Comp;
    const isParking = updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.Parking;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const {user} = useAppSelector<JsonAuthUserInfo>(selectAuthInfo);
    const isAdmin = user?.roleId === ROLE_ADMIN;

    const [eventDays, setEventDays] = useState<JsonEventDayInfo[] | undefined>(undefined);
    const [articleTypes, setArticleTypes] = useState<JsonArticleTypeInfo[] | undefined>(undefined);
    const [articles, setArticles] = useState<JsonArticleInfo[] | undefined>(undefined);
    const [tariffs, setTariffs] = useState<JsonTariffInfo[] | undefined>(undefined);

    const [initialValues, setInitialValues] = useState<JsonEventPartyMassActionRequest | undefined>(undefined);
    const [commonValues, setCommonValues] = useState<JsonEventPartyMassActionRequest | undefined>(undefined);
    const [clipboard, setClipboard] = useState<JsonEventPartyDay | undefined>(undefined);

    const handleFetchEvent = useCallback(async (eventId: number) => {
        const articleTypes = isArticles ? getApiResult<JsonArticleTypeInfo[]>(await dispatch(fetchArticleTypes({eventId}))) : undefined;
        const articles = isArticles ? getApiResult<JsonArticleInfo[]>(await dispatch(fetchArticles({eventId}))) : undefined;
        if (isArticles) {
            setArticleTypes(articleTypes);
            setArticles(articles);
        }
        const tariffs = isComp ? getApiResult<JsonTariffInfo[]>(await dispatch(fetchTariffs({eventId}))) : undefined;
        if (isComp) {
            setTariffs(tariffs);
        }

        const ed = getApiResult<JsonEventDayInfo[]>(await dispatch(fetchEventDays({eventId/*, statuses: [GetEventDayListUsingGETStatusesEnum.Active]*/})));
        if (ed?.length) {
            const commonPrefArticles = articleTypes?.map((at) => {
                const commonPrefCnt = getCommonArticle(eventParties, at.articleTypeId, 'prefCnt');
                return {
                    articleTypeId: at.articleTypeId,
                    articleId: getCommonArticle(eventParties, at.articleTypeId, 'prefArticleId'),
                    purchasedCnt: commonPrefCnt > 0 ? commonPrefCnt : undefined,
                }
            });
            const commonRecArticles = articleTypes?.map((at) => {
                const commonRecCnt = getCommonArticle(eventParties, at.articleTypeId, 'recCnt');
                return {
                    articleTypeId: at.articleTypeId,
                    articleId: getCommonArticle(eventParties, at.articleTypeId, 'recArticleId'),
                    purchasedCnt: commonRecCnt > 0 ? commonRecCnt : undefined,
                };
            });
            const hasAnyRecArticle = !!commonRecArticles?.find((at) => at.articleId > 0);
            const commonValues: JsonEventPartyMassActionRequest = {
                ...massActionValues,
                groupId: getCommonValue(eventParties, 'groupId'),
                prefAcm: getCommonBoolean(eventParties, 'prefAcm'),
                eventPartyDays: ed.map((d, i) => {
                    const details: JsonEventPartyDayDetails[] = [];
                    let commonDay: number | undefined = FAKE_VALUE_RESET;
                    eventParties.forEach((ep) => {
                        const detail = ep?.eventDayDetails?.find((ed) => ed.dayNo === d.dayNo);
                        if (detail) {
                            details.push(detail);
                        } else {
                            details.push({dayNo: d.dayNo});
                        }
                        if (commonDay === undefined) {
                            return;
                        }
                        const v = (ep.eventDays && ep.eventDays[i] === '1' ? d.eventDayId : 0);
                        if (commonDay === FAKE_VALUE_RESET) {
                            commonDay = v;
                        } else if (commonDay !== v) {
                            commonDay = undefined;
                        }
                    });
                    const eventDayId = commonDay === undefined
                        ? undefined
                        : (commonDay === 0 ? FAKE_VALUE_RESET : FAKE_VALUE_EMPTY);

                    const isCommonPossible = eventDayId === FAKE_VALUE_EMPTY;

                    const commonAcmCnt = isCommonPossible ? getCommonValue(details, 'acmCnt') : undefined;
                    const commonParkCnt = isCommonPossible ? getCommonValue(details, 'parkCnt') : undefined;
                    const commonParkRec = isCommonPossible && eventParties.length === 1 ? getCommonValue(details, 'parkRec') : undefined;
                    const commonParkPlates = isCommonPossible && eventParties.length === 1 ? getCommonValue(details, 'parkPlates') : undefined;

                    const x: JsonEventPartyDay = {
                        eventDayId,
                        foodCnt: getCommonFoodCnt(details, isCommonPossible),
                        foodBrId: isCommonPossible ? getCommonValue(details, 'foodBrId') : eventDayId,
                        foodLuId: isCommonPossible ? getCommonValue(details, 'foodLuId') : eventDayId,
                        foodDiId: isCommonPossible ? getCommonValue(details, 'foodDiId') : eventDayId,
                        foodSnId: isCommonPossible ? getCommonValue(details, 'foodSnId') : eventDayId,
                        acmId: isCommonPossible ? getCommonValue(details, 'acmId') : eventDayId,
                        acmCnt: (commonAcmCnt !== undefined && commonAcmCnt < 0) ? undefined : commonAcmCnt,
                        parkId: isCommonPossible ? getCommonValue(details, 'parkId') : eventDayId,
                        parkCnt: (commonParkCnt !== undefined && commonParkCnt < 0) ? undefined : commonParkCnt,
                        parkRec: (commonParkRec !== undefined && commonParkRec < 0) ? undefined : commonParkRec,
                        parkPlates: !(typeof commonParkPlates === 'string') ? undefined : commonParkPlates,
                    };
                    return x;
                }),
                prefArticles: commonPrefArticles,
                recArticles: commonRecArticles,
                contractStatus: getCommonContractStatus(eventParties),
                eventPartyComps: tariffs?.map((t) => {
                    const eventPartyId = getCommonComp(eventParties, t.tariffId, 'tariffId');
                    const unitCnt = getCommonComp(eventParties, t.tariffId, 'unitCnt');
                    const unitAmount = getCommonComp(eventParties, t.tariffId, 'unitAmount');
                    const compAmount = getCommonComp(eventParties, t.tariffId, 'compAmount');
                    return {
                        eventPartyId,
                        tariffId: t.tariffId,
                        unitCnt: unitCnt > 0 ? unitCnt : undefined,
                        unitAmount: unitAmount > 0 ? unitAmount : undefined,
                        compAmount: compAmount > 0 ? compAmount : undefined
                    };
                })
            };
            setCommonValues(commonValues);
            setInitialValues({
                ...massActionValues,
                groupId: FAKE_VALUE_EMPTY,
                prefAcm: undefined,
                eventPartyDays: ed.map((_, i) => {
                    return {
                        eventDayId: FAKE_VALUE_EMPTY, // d.eventDayId,
                        foodBrId: FAKE_VALUE_EMPTY,
                        foodLuId: FAKE_VALUE_EMPTY,
                        foodDiId: FAKE_VALUE_EMPTY,
                        foodSnId: FAKE_VALUE_EMPTY,
                        foodCnt: {},
                        acmId: FAKE_VALUE_EMPTY,
                        acmCnt: undefined,
                        parkId: FAKE_VALUE_EMPTY,
                        parkCnt: undefined,
                        parkRec: commonValues.eventPartyDays?.[i]?.parkRec,
                        parkPlates: commonValues.eventPartyDays?.[i]?.parkPlates,
                    } as JsonEventPartyDay;
                }),
                prefArticles: articleTypes?.map((at) => ({
                    articleTypeId: at.articleTypeId,
                    articleId: FAKE_VALUE_EMPTY,
                    purchasedCnt: undefined
                })),
                recArticles: articleTypes?.map((at) => {
                    const prefArticleId = commonPrefArticles?.find((pa) => pa.articleTypeId === at.articleTypeId)?.articleId;
                    const recArticleId = commonRecArticles?.find((pa) => pa.articleTypeId === at.articleTypeId)?.articleId;
                    return {
                        articleTypeId: at.articleTypeId,
                        articleId: massActionValues.updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.ArticleContract
                            ? !!prefArticleId && prefArticleId > 0
                            && prefArticleId !== recArticleId
                            && !hasAnyRecArticle ? prefArticleId : FAKE_VALUE_EMPTY
                            : FAKE_VALUE_EMPTY,
                        purchasedCnt: undefined
                    }
                }),
                contractStatus: undefined,
                eventPartyComps: tariffs?.map((t) => {
                    return {
                        eventPartyId: FAKE_VALUE_EMPTY,
                        tariffId: t.tariffId,
                        unitCnt: undefined,
                        unitAmount: undefined,
                        compAmount: undefined
                    }
                }),
                items: eventParties.map(({eventPartyId, partyId}) => ({eventPartyId, partyId}))
            });
            setEventDays(ed);
        }
    }, [massActionValues, isArticles, isComp, eventParties, dispatch]);

    const validate = useCallback((values: JsonEventPartyMassActionRequest) => {
        const errors: FormikErrors<JsonEventPartyMassActionRequest> = {};
        values.eventPartyDays?.forEach((epd, i) => {
            if (!!epd.acmCnt && !isNumeric(epd.acmCnt, true)) {
                setNestedKey(errors, 'eventPartyDays.' + i + '.acmCnt', t('Číslo'));
            }
            if (!!epd.parkCnt && !isNumeric(epd.parkCnt, true)) {
                setNestedKey(errors, 'eventPartyDays.' + i + '.parkCnt', t('Číslo'));
            }
            if (!!epd.parkRec && !isNumeric(epd.parkRec, true)) {
                setNestedKey(errors, 'eventPartyDays.' + i + '.parkRec', t('Číslo'));
            }
        });
        if (isComp) {
            values.eventPartyComps?.forEach((epc, i) => {
                if (!!epc.unitAmount && !isNumeric(epc.unitAmount)) {
                    setNestedKey(errors, 'eventPartyComps.' + i + '.unitAmount', t('Neplatné číslo'));
                }
                if (!!epc.unitCnt && !isNumeric(epc.unitCnt, true)) {
                    setNestedKey(errors, 'eventPartyComps.' + i + '.unitCnt', t('Neplatné číslo'));
                }
            });
        }
        return errors;
    }, [isComp, t]);

    const cols = useMemo(() => {
        const cols: DataGridCol<JsonEventPartyInfo, LocalFilter>[] = [
            createCol('Jméno', 'fullName', 100, undefined, (_, item) => partyName(item)),
            createCol('P', 'sex', 15, 'Pohlaví', (v) => v ? <CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/> : null),
            createCol('R', 'birthDate', 35, 'Rok narození', (v) => v ? dateToGuiAs(v, 'Y') : null),
            // createCol('Org', 'orgCompanyName', 100, 'Organizace'),
            createCol('Skupina', 'groupId', 80, 'Pracovní skupina', (v) => v ? <CodebookValue value={v} name={'group'}/> : null),
            createCol('Dny', 'eventDays', 50, undefined, (v, item) => <EventDaysValue eventDays={v} eventId={item.eventId} siwiEventDays={item.siwiEventDays}/>),
            createCol('Strava', 'eventDayDetails', 50, 'Strava, dle dnů',
                (v, item, filter) => <EventDayDetails eventId={item.eventId} dd={v} filter={filter} type={'food'}/>),
            createCol('Ubyt', 'eventDayDetails', 50, 'Ubytování, dle dnů',
                (v, item, filter) => <EventDayDetails eventId={item.eventId} dd={v} filter={filter} type={'acm'}/>),
            createCol('Park', 'eventDayDetails', 50, 'Parkování, dle dnů',
                (v, item, filter) => <EventDayDetails eventId={item.eventId} dd={v} filter={filter} type={'park'}/>),
        ];
        if (articles && articleTypes) {
            articleTypes.forEach((at) => cols.push({
                    col: 'eventArticleDetails',
                    title: shortenInitials(at?.title) || '',
                    tooltip: at?.title,
                    size: 28,
                    align: 'left',
                    renderValue: (v, item) => {
                        const a = articles.find((a) => a.articleId === v?.find((a) => a.articleTypeId === at.articleTypeId)?.prefArticleId)
                        return a ? articleName(a, true, item.sex ? JsonEventPartyImportSexEnum[item.sex] : undefined) : null;
                    }
                })
            );
        }
        if (tariffs) {
            cols.push({
                col: 'eventCompDetails',
                title: '$',
                tooltip: 'Odměny',
                size: 40,
                align: 'center',
                renderValue: (v, item) => <EventPartyComp item={item} eventPartyComps={v}/>
            })
        }

        return cols;
    }, [articleTypes, articles, tariffs]);

    const actions = useCallback(({values, isSubmitting}: FormikProps<JsonEventPartyMassActionRequest>, props: FormProps<JsonEventPartyMassActionRequest>) => {
        const {eventId, items} = values;
        const itemsCount = items?.length || 0;
        if (!action) {
            return null;
        }
        const textValues = texts[action];
        if (!textValues) {
            return null; // should never happen
        }

        return <>
            <Grid item sx={{flexGrow: 1}}>
                {(itemsCount > 0
                    ? <Alert severity={'info'} className={'event-action-errors'}>
                        {t(textValues[1], {itemsCount: t('{{count}} osob', {count: itemsCount})})}
                    </Alert>
                    : <Alert severity={'warning'} className={'event-action-errors'}>{t(textValues[2])}</Alert>)}
            </Grid>
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
			</Grid>}
            <Grid item>
                <Button variant="contained" type="submit" color={textValues[3] || 'success'} disabled={!eventId || isSubmitting || !itemsCount}>
                    {t(textValues[0])}
                </Button>
            </Grid>
        </>
    }, [action, t]);

    const grid = useMemo(() => {
        const itemsState = {
            loading: false,
            count: 0,
            items: eventParties,
            filter: {action, eventId}
        } as ItemsState<JsonEventPartyInfo, LocalFilter>;

        return <>
            <DataGrid
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                mode={DataGridMode.CLIENT}
                tableProps={{
                    minHeight: '150px',
                    maxHeight: "clamp(100px, calc(100vh - 100px), 300px)"
                }}
                emptyListMessage={t('Není možné upravit žádné osoby')}
            />
        </>;
    }, [cols, action, eventParties, eventId, t]);

    const articleTypeOptions = useMemo(() => {
        const articleTypeOptions: { [key in 'pref' | 'rec']: { [key in number]: { options: OptionValue[], cnt: number | undefined } } } = {'pref': {}, 'rec': {}};
        articleTypes?.forEach((at) => {
            if (!at.articleTypeId || !at.variants?.sizes) {
                return;
            }
            Object.keys(articleTypeOptions).forEach((k) => {
                const commonValue = commonValues?.[k === 'rec' ? 'recArticles' : 'prefArticles']?.find((c) => c.articleTypeId === at.articleTypeId);
                const commonId = commonValue?.articleId;
                const commonArticle = articles?.find(a => a.articleId === commonId);
                const options: OptionValue[] = [];
                if (commonId === FAKE_VALUE_RESET) {
                    options.push(createOption(FAKE_VALUE_EMPTY, t('(nic)')))
                } else if (commonId !== FAKE_VALUE_EMPTY && commonArticle) {
                    options.push(createOption(FAKE_VALUE_EMPTY, t('({{title}})', {title: getArticleOption(commonArticle)})));
                    options.push(createOption(FAKE_VALUE_RESET, t('(vymazat)')));
                } else {
                    options.push(createOption(FAKE_VALUE_EMPTY, t('(neměnit)')))
                    options.push(createOption(FAKE_VALUE_RESET, t('(vymazat)')));
                }
                if (at?.variants?.sizes) {
                    Object.keys(at.variants.sizes).forEach((sex) => {
                        const sizes = at.variants?.sizes ? at.variants.sizes[sex] : undefined;
                        sizes?.forEach((size) => {
                            const article = articles?.find((a) => a.articleTypeId === at.articleTypeId && a.size === size && a.sex === sex);
                            if (article?.articleId) {
                                options.push(createOption(article.articleId, getArticleOption(article)));
                            }
                        })
                    });
                }
                if (at.articleTypeId) {
                    articleTypeOptions[k as 'pref' | 'rec'][at.articleTypeId] = {options, cnt: commonValue?.purchasedCnt};
                }
            });
        })
        return articleTypeOptions;
    }, [commonValues, articleTypes, articles, t]);

    const isMulti = eventParties?.find((ep) => ep.partyType === JsonEventPartyInfoPartyTypeEnum.T)
        && !eventParties?.find((ep) => ep.partyType !== JsonEventPartyInfoPartyTypeEnum.T);
    const isParkingPlates = eventParties?.length === 1;

    const children = useCallback(({values, setFieldValue}: FormikProps<JsonEventPartyMassActionRequest>) => {
        const addEmpty: string = t('(neměnit)') as string;
        const addReset: string = t('(vymazat)') as string;
        const addEmptyReset: string = t('(nic)') as string;

        const totalCols = articleTypes?.length
            ? articleTypes.length * 3 - (articleTypes?.filter(at => !!at.variants?.sizes?.['U']).length || 0)
            : 0;

        return <>
            {isGroup && <>
				<Grid item sm={6} xs={6}>
					<SelectFormField name="groupId" label={'Skupina'} useColors
						codebookProps={{codebookName: 'group', asNumber: true, addEmpty, emptyValue: commonValues?.groupId}}
					/>
				</Grid>
				<Grid item sm={6} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
					<CheckboxField name={'prefAcm'} triState offColor={'error'} emptyValue={commonValues?.prefAcm} label={t('Má zájem o ubytování')}/>
				</Grid>
			</>}
            {(isDays || isParking) && <Grid item xs={12} sx={articleStyle}>
				<TableContainer component={Paper}>
					<Table className={'data-grid mass-update'}>
						<TableHead>
							<TableRow>
								<TableCell style={{width: isParkingPlates && isDays ? '6%' : '8%', textAlign: 'center'}} colSpan={2}><span>{t('Den')}</span></TableCell>
                                {isDays && foodServiceOptions.map((fs, i) => <TableCell key={i} style={
                                    {width: Math.round((isParkingPlates ? 48 : 56) / foodServiceOptions.length) + '%', textAlign: 'center'}}><span>{fs.tooltip}</span></TableCell>)}
                                {isDays && <TableCell style={{width: isParkingPlates ? '12%' : '13%', textAlign: 'center'}}><span>{t('Ubytování')}</span></TableCell>}
								<TableCell style={{width: isParkingPlates ? '16%' : '18%', textAlign: 'center'}}>
									<span>{t('Parkování')}</span>
                                    {!!values.eventPartyDays?.find((epd, i) => isDayEnabled(commonValues, values, i) && !!epd.parkId && epd.parkId !== FAKE_VALUE_EMPTY && epd.parkId !== FAKE_VALUE_RESET)
                                        && !!values.eventPartyDays?.find((epd, i) => isDayEnabled(commonValues, values, i) && (!epd.parkId || epd.parkId === FAKE_VALUE_EMPTY || epd.parkId === FAKE_VALUE_RESET))
                                        && <Button size={'small'} title={t('Doplnit všechny prázdné')} onClick={() => {
                                            autofillByKey('parkId', eventDays, values, setFieldValue, (values, i) => isDayEnabled(commonValues, values, i));
                                            autofillByKey('parkCnt', eventDays, values, setFieldValue, (values, i) => isDayEnabled(commonValues, values, i));
                                        }}><FormatColorFillOutlined/></Button>}
                                    {!!values.eventPartyDays?.find(epd => epd.parkId !== FAKE_VALUE_EMPTY)
                                        && <Button size={'small'} title={t('Smazat vše')} onClick={() => {
                                            clearAllByKey('parkId', eventDays, values, setFieldValue);
                                            clearAllByKey('parkCnt', eventDays, values, setFieldValue, true);
                                        }}><ClearRounded/></Button>}
								</TableCell>
                                {isParkingPlates && <TableCell style={{width: '17%', textAlign: 'center'}}>
									<span>{t('Vydáno / SPZ')}</span>
                                    {!!values.eventPartyDays?.find((epd, i) => isParkingPlateAllowed(commonValues, values, i) && !!epd.parkPlates)
                                        && !!values.eventPartyDays?.find((epd, i) => isParkingPlateAllowed(commonValues, values, i) && !epd.parkPlates)
                                        && <Button size={'small'} title={t('Doplnit všechny prázdné')} onClick={() => {
                                            autofillByKey('parkPlates', eventDays, values, setFieldValue, (values, i) => isParkingPlateAllowed(commonValues, values, i));
                                            autofillByKey('parkRec', eventDays, values, setFieldValue, (values, i) => isParkingPlateAllowed(commonValues, values, i));
                                        }}><FormatColorFillOutlined/></Button>}
                                    {!!values.eventPartyDays?.find(epd => !!epd.parkPlates)
                                        && <Button size={'small'} title={t('Smazat vše')} onClick={() => {
                                            clearAllByKey('parkPlates', eventDays, values, setFieldValue, true);
                                            clearAllByKey('parkRec', eventDays, values, setFieldValue, true);
                                        }}><ClearRounded/></Button>}
								</TableCell>}
                                {!isParking &&
									<TableCell style={{width: isParkingPlates && !isDays ? '4%' : '5%', textAlign: 'right'}}>{clipboard && <Button title={t('Smazat schránku')} onClick={() => {
                                        setClipboard(undefined);
                                    }}><ContentPasteOffRounded/></Button>}</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
                            {eventDays?.map((eventDay, i) => {
                                const common = commonValues?.eventPartyDays ? commonValues?.eventPartyDays[i] : undefined;
                                const dayNo = eventDay.dayNo;
                                const prefix = 'eventPartyDays.' + i;
                                const eventDayId = values.eventPartyDays ? values.eventPartyDays[i].eventDayId : undefined; // ID, -1 (true), -2 (false)
                                const commonDayValue = (common === undefined || common.eventDayId === undefined) // undefined, -1 (all true), -2 (all false)
                                    ? undefined
                                    : (common.eventDayId === FAKE_VALUE_EMPTY);
                                const currentDayValue = eventDayId === FAKE_VALUE_EMPTY
                                    ? commonDayValue
                                    : (eventDayId === undefined ? undefined : (eventDayId > 0));
                                return <TableRow key={i}>
                                    <TableCell>
                                        <Box sx={{display: 'flex', flexFlow: 'column', alignItems: 'center', '.MuiFormControlLabel-root': {margin: 0}, '.MuiCheckbox-root': {margin: 0}}}
                                            title={dateToGuiAs(eventDay.dayDate, 'eeeee d.M.') + ' (#' + dayNo + ')'}>
                                            <CheckboxPlain name={prefix + '.isActive'}
                                                currentValue={currentDayValue === commonDayValue ? undefined : currentDayValue}
                                                emptyValue={commonDayValue}
                                                onChange={(v) => {
                                                    setFieldValue(prefix + '.eventDayId', v === true
                                                        ? eventDay.eventDayId
                                                        : (v === false
                                                            ? FAKE_VALUE_RESET
                                                            : FAKE_VALUE_EMPTY));
                                                }}
                                                triState
                                                offColor={'error'}
                                                disabled={!isAdmin || !isDays}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{textAlign: 'center'}}>
                                        {dateToGuiAs(eventDay.dayDate, "eeeee d.M.")}
                                    </TableCell>
                                    {currentDayValue !== false ? <>
                                        {isDays && foodServiceOptions.map((fs, j) => {
                                            // const commonIsSet = !!common?.foodServiceUpdate ? common.foodServiceUpdate[fs.value as string] : undefined;
                                            const serviceType = fs.value as JsonPlaceFoodServicesEnum;
                                            const foodProp = foodServiceProps[serviceType];
                                            const commonCount = common?.foodCnt?.[serviceType];
                                            return <TableCell key={j} /*className={'form-grid-checkbox'}*/ sx={{textAlign: 'center', padding: "2px 5px 2px 0 !important"}}>
                                                <SimpleSelectFormField name={prefix + '.' + foodProp} placeholder={'Strava den ' + dayNo} useColors
                                                    codebookProps={{
                                                        codebookName: 'placeFood', asNumber: true, scope: eventId, addEmpty,
                                                        addReset: (common?.[foodProp] === FAKE_VALUE_RESET ? addEmptyReset : addReset),
                                                        emptyValue: common?.[foodProp] as number,
                                                    }}
                                                />
                                                {isMulti && !(
                                                    (values.eventPartyDays && values.eventPartyDays[i]?.[foodProp] === FAKE_VALUE_RESET)
                                                    || (common?.[foodProp] === FAKE_VALUE_RESET && !(values.eventPartyDays && ((values.eventPartyDays[i]?.[foodProp] || 0) > 0)))
                                                ) && <div style={{maxWidth: '40px', margin: '2px auto'}}><TextFormFieldPlain name={prefix + '.foodCnt.' + serviceType}
													placeholder={common?.foodCnt?.[serviceType] === undefined ? '' : ('' + commonCount)} maxlength={3}
													currentValue={values.eventPartyDays?.[i]?.foodCnt?.[serviceType]}
													onChange={(v) => {
                                                        if (v === '' || v === undefined) {
                                                            // setFieldValue(prefix + '.foodServiceUpdate.' + fs.value, undefined);
                                                            setFieldValue(prefix + '.foodCnt.' + serviceType, undefined);
                                                        } else {
                                                            v = parseInt(v, 10);
                                                            if (isNaN(v)) {
                                                                setFieldValue(prefix + '.foodCnt.' + serviceType, undefined);
                                                            } else {
                                                                setFieldValue(prefix + '.foodCnt.' + serviceType, v);
                                                            }
                                                            // if (v > 0) {
                                                            //     setFieldValue(prefix + '.foodServiceUpdate.' + fs.value, true);
                                                            // } else {
                                                            //     setFieldValue(prefix + '.foodServiceUpdate.' + fs.value, false);
                                                            // }
                                                        }
                                                    }}/></div>}
                                            </TableCell>;
                                        })}
                                        {isDays && <TableCell sx={{textAlign: 'center'}}>
											<div style={{padding: "0 0 0 5px"}}>
												<SimpleSelectFormField name={prefix + '.acmId'} placeholder={'Ubytování den ' + dayNo} useColors
													codebookProps={{
                                                        codebookName: 'placeAcm', asNumber: true, scope: eventId, addEmpty,
                                                        addReset: (common?.acmId === FAKE_VALUE_RESET ? addEmptyReset : addReset),
                                                        emptyValue: common?.acmId
                                                    }}
												/>
                                                {isMulti && !(
                                                    (values.eventPartyDays && values.eventPartyDays[i]?.acmId === FAKE_VALUE_RESET)
                                                    || (common?.acmId === FAKE_VALUE_RESET && !((values.eventPartyDays?.[i]?.acmId || 0) > 0))
                                                ) && <div style={{maxWidth: '40px', margin: '2px auto'}}>
													<TextFormField name={prefix + '.acmCnt'} placeholder={common?.acmCnt === undefined ? '' : ('' + common?.acmCnt)} maxlength={3}/>
												</div>}
											</div>
										</TableCell>}
                                        <TableCell sx={{textAlign: 'center'}}>
                                            <Grid container columnSpacing={0} style={{padding: "0 5px 0 10px"}} columns={18}>
                                                <Grid item xs={13}>
                                                    <SimpleSelectFormField name={prefix + '.parkId'} placeholder={'Parkování den ' + dayNo} useColors
                                                        codebookProps={{
                                                            codebookName: 'placePark', asNumber: true, scope: eventId, addEmpty,
                                                            addReset: (common?.parkId === FAKE_VALUE_RESET ? addEmptyReset : addReset),
                                                            emptyValue: common?.parkId
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={5} style={{paddingLeft: '5px'}}>
                                                    <TextFormField name={prefix + '.parkCnt'}
                                                        title={t('Počet objednaných míst')}
                                                        placeholder={common?.parkCnt === undefined ? '' : ('' + common?.parkCnt)} maxlength={3}/>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        {isParkingPlates && <TableCell sx={{textAlign: 'center'}}><Grid container>
											<Grid item xs={3} sx={{paddingRight: '5px', '& label > .MuiCheckbox-root': {paddingTop: '5px'}}}>
                                                {!values?.eventPartyDays?.[i]?.parkRec && !common?.parkRec
                                                    ? <CheckboxPlain name={prefix + '.isParkRec'}
                                                        title={t('Označit jako vydané')}
                                                        currentValue={false}
                                                        onChange={() => {
                                                            const v = values?.eventPartyDays?.[i]?.parkCnt || common?.parkCnt || 1;
                                                            setFieldValue(prefix + '.parkRec', v);
                                                        }}
                                                        triState
                                                        disabled={!isParkingPlateAllowed(commonValues, values, i)}
                                                    />
                                                    : <TextFormField name={prefix + '.parkRec'}
                                                        title={t('Počet vydaných karet')}
                                                        placeholder={common?.parkRec === undefined ? '' : ('' + common?.parkRec)} maxlength={3}/>}
											</Grid>
											<Grid item xs={9}
												className={(values.eventPartyDays?.[i]?.parkPlates || '') === (common?.parkPlates || '') ? 'data-unchanged' : undefined}>
												<TextFormField name={prefix + '.parkPlates'} disabled={!isParkingPlateAllowed(commonValues, values, i)} maxlength={255}/>
											</Grid>
										</Grid></TableCell>}
                                        {!isParking && <TableCell sx={{textAlign: 'right'}}>
											<ActionButtons item={values.eventPartyDays?.[i]} clipboard={clipboard} setClipboard={setClipboard} i={i} common={common}/>
										</TableCell>}
                                    </> : <TableCell colSpan={(isDays ? (3 + foodServiceOptions.length) : 1) + (isParkingPlates ? 1 : 0)}>
                                        <div style={{minHeight: '34px'}}>&nbsp;</div>
                                    </TableCell>}
                                </TableRow>;
                            })}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>}
            {isArticles && articleTypes && articleTypes.length > 0 && <Grid item xs={12}>
				<TableContainer component={Paper} sx={{margin: "10px 0 5px 0"}}>
					<Table className={'data-grid mass-update'}>
						<TableHead>
							<TableRow>
								<TableCell style={{width: '10%', textAlign: 'center'}}>
									<span>{t('Artikl')}</span>
								</TableCell>
                                {articleTypes.map((at, i) => {
                                    const coef = !!at.variants?.sizes?.['U'] ? 2 : 3;
                                    return <TableCell key={i} sx={{textAlign: 'center', width: (90 / totalCols * coef) + '%'}}>
                                        {at.title}
                                    </TableCell>
                                })}
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									<span>{t('Preference')}</span>
								</TableCell>
                                {articleTypes.map((at, i) => {
                                    if (!at.articleTypeId || !at.variants?.sizes) {
                                        return <TableCell key={i}>-</TableCell>;
                                    }
                                    const commonType = articleTypeOptions['pref'][at.articleTypeId];
                                    return <TableCell key={i}>
                                        <Grid container columnSpacing={1} sx={articleStyle} columns={13}>
                                            <Grid item xs={isMulti ? 9 : 13} title={'Preference ' + at.title}>
                                                <SimpleSelectFormField placeholder={'Preference ' + at.title} useColors
                                                    name={'prefArticles.' + i + '.articleId'}
                                                    options={commonType.options}
                                                    onChange={(v) => {
                                                        if (v <= 0) {
                                                            return;
                                                        }
                                                        const a = articles?.find(a => a.articleId === v);
                                                        setFieldValue('prefArticles.' + i + '.sex', a?.sex);
                                                        setFieldValue('prefArticles.' + i + '.size', a?.size);
                                                    }}/>
                                            </Grid>
                                            {isMulti && <Grid item xs={4}>
												<TextFormField name={'prefArticles.' + i + '.purchasedCnt'}
													placeholder={commonType.cnt === undefined ? '' : ('' + commonType.cnt)} maxlength={3}/>
											</Grid>}
                                        </Grid>
                                    </TableCell>;
                                })}
							</TableRow>
							<TableRow>
								<TableCell>
									<span style={{paddingTop: '8px', paddingBottom: 0}}>{t('Vydáno')}</span>
								</TableCell>
                                {articleTypes.map((at, i) => {
                                    if (!at.articleTypeId || !at.variants?.sizes) {
                                        return <TableCell key={i}>-</TableCell>;
                                    }
                                    const commonType = articleTypeOptions['rec'][at.articleTypeId];
                                    return <TableCell key={i}>
                                        <Grid container columnSpacing={1} sx={articleStyle} columns={13}>
                                            <Grid item xs={isMulti ? 9 : 13} title={'Vydáno ' + at.title}>
                                                <SimpleSelectFormField placeholder={'Vydáno ' + at.title} useColors
                                                    name={'recArticles.' + i + '.articleId'}
                                                    options={commonType.options}
                                                    onChange={(v) => {
                                                        if (v <= 0) {
                                                            return;
                                                        }
                                                        const a = articles?.find(a => a.articleId === v);
                                                        setFieldValue('recArticles.' + i + '.sex', a?.sex);
                                                        setFieldValue('recArticles.' + i + '.size', a?.size);
                                                    }}/>
                                            </Grid>
                                            {isMulti && <Grid item xs={4}>
												<TextFormField name={'recArticles.' + i + '.purchasedCnt'}
													placeholder={commonType.cnt === undefined ? '' : ('' + commonType.cnt)} maxlength={3}/>
											</Grid>}
                                        </Grid>
                                    </TableCell>;
                                })}
							</TableRow>
							<TableRow className={'data-grid-fake-row'}>
								<TableCell colSpan={1 + articleTypes.length}>
									<span style={{paddingLeft: '8px'}}><CheckboxPlain name={'contractStatus'}
										label={'Smlouva podepsána'}
										currentValue={commonValues?.contractStatus === values.contractStatus || values.contractStatus === undefined
                                            ? undefined
                                            : values.contractStatus === JsonEventPartyMassActionRequestContractStatusEnum.Signed}
										emptyValue={commonValues?.contractStatus === JsonEventPartyMassActionRequestContractStatusEnum.Signed
                                            ? true
                                            : commonValues?.contractStatus === JsonEventPartyMassActionRequestContractStatusEnum.None
                                                ? false
                                                : undefined}
										onChange={(v) => {
                                            setFieldValue('contractStatus', v === true
                                                ? JsonEventPartyMassActionRequestContractStatusEnum.Signed
                                                : (v === false
                                                    ? JsonEventPartyMassActionRequestContractStatusEnum.None
                                                    : commonValues?.contractStatus));
                                        }}
										triState
										offColor={'error'}
									/>
                                    </span>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>}
            {isComp && <Grid item xs={12}>
				<TableContainer component={Paper}>
					<Table className={'data-grid mass-update'}>
						<TableHead>
							<TableRow>
								<TableCell style={{width: '4%', textAlign: 'center'}}></TableCell>
								<TableCell style={{width: '41%', textAlign: 'left'}}><span>{t('Tarif')}</span></TableCell>
								<TableCell style={{width: '15%', textAlign: 'center'}}><span>{t('Sazba')}</span></TableCell>
								<TableCell style={{width: '15%', textAlign: 'center'}}><span>{t('Jednotek')}</span></TableCell>
								<TableCell style={{width: '15%', textAlign: 'center'}}><span>{t('Celkem')}</span></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
                            {tariffs?.map((tariff, i) => {
                                const commonEpc = commonValues?.eventPartyComps?.find((epc) => epc.tariffId === tariff.tariffId);
                                const prefix = 'eventPartyComps.' + i;
                                const valueEpc = values?.eventPartyComps?.find((epc) => epc.tariffId === tariff.tariffId); // ID, -1 (true), -9 (false)
                                const commonToggleValue = commonEpc?.eventPartyId === FAKE_VALUE_RESET
                                    ? false
                                    : (commonEpc?.eventPartyId && commonEpc.eventPartyId > 0 ? true : undefined)
                                const currentToggleValue = valueEpc?.eventPartyId === FAKE_VALUE_EMPTY
                                    ? commonToggleValue
                                    : (valueEpc?.eventPartyId === undefined ? undefined : (valueEpc?.eventPartyId > 0));

                                const commonTotal = ((commonEpc?.unitAmount || 0)
                                    * (commonEpc?.unitCnt || 0)) || (commonEpc?.compAmount || 0);
                                const currentTotal = ((tariff.unitRate || valueEpc?.unitAmount || commonEpc?.unitAmount || 0)
                                    * (valueEpc?.unitCnt || commonEpc?.unitCnt || 0)) || (commonEpc?.compAmount || 0);

                                return <TableRow key={i}>
                                    <TableCell className={'form-grid-checkbox'}>
                                        <CheckboxPlain name={prefix + '.isActive'}
                                            currentValue={currentToggleValue === commonToggleValue ? undefined : currentToggleValue}
                                            emptyValue={commonToggleValue}
                                            onChange={(v) => {
                                                setFieldValue(prefix + '.eventPartyId', v === true
                                                    ? tariff.tariffId /* anything >0 */
                                                    : (v === false
                                                        ? FAKE_VALUE_RESET
                                                        : FAKE_VALUE_EMPTY));
                                            }}
                                            triState
                                            offColor={'error'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <span>{tariff.unitTitle}</span>
                                    </TableCell>
                                    {currentToggleValue !== false ? <>
                                        <TableCell sx={{textAlign: 'center'}}><span>
                                            {!!tariff.unitRate && <span>{formatMoney(tariff.unitRate, true)}</span>}
                                            {!tariff.unitRate && <TextFormField name={prefix + '.unitAmount'}
												placeholder={commonEpc?.unitAmount === undefined ? '' : ('' + commonEpc?.unitAmount)} maxlength={6}
												formatValue={formatMoney} normalizeValue={normalizeMoney}/>}</span>
                                        </TableCell>
                                        <TableCell sx={{textAlign: 'center'}}><span>
                                            <TextFormField name={prefix + '.unitCnt'}
                                                placeholder={commonEpc?.unitCnt === undefined ? '' : ('' + commonEpc?.unitCnt)} maxlength={3}/></span>
                                        </TableCell>
                                        <TableCell sx={{textAlign: 'right'}}>
                                            <span style={commonTotal === currentTotal ? undefined : {color: 'var(--color-secondary)'}}>{formatMoney(currentTotal, true)}</span>
                                        </TableCell>
                                    </> : <TableCell colSpan={3}>
                                        <div style={{minHeight: '42px'}}>&nbsp;</div>
                                    </TableCell>}
                                </TableRow>;
                            })}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
            }
            <Grid item xs={12}>
                {grid}
            </Grid>
        </>;
    }, [eventId, isAdmin, isMulti, isParkingPlates, eventDays, articles, articleTypes, articleTypeOptions, tariffs,
        commonValues, clipboard, setClipboard,
        isGroup, isDays, isParking, isArticles, isComp, grid, t]);

    useEffect(() => {
        if (eventId) {
            handleFetchEvent(eventId).then();
        }
    }, [eventId, handleFetchEvent]);

    if (!eventDays || (isArticles && !articleTypes) || !initialValues) {
        return <div style={{textAlign: "center", padding: "100px 200px"}}><CircularProgress size={50}/></div>;
    }

    return <FormContainer
        item={initialValues}
        validate={validate}
        actions={actions}
        children={children}
        onCancel={props.onCancel}
        onSave={(values) => {
            if (props.onSave) {
                props.onSave({...massAction, values: values});
            }
        }}
    />;
}

export default PartyMassUpdateForm;
