import {FormProps} from "../model/form";
import {JsonFile, SaveAttachmentFileUsingPOSTFileTypeEnum} from "../generated-api";
import {useAppTranslation} from "../services/i18n";
import {useAppDispatch} from "../store";
import * as React from "react";
import {useCallback, useMemo} from "react";
import {saveAttachment} from "../store/files";
import {getApiResult} from "../helpers/api";
import {FormikErrors} from "formik";
import FormContainer from "./form/FormContainer";
import {FileFormField} from "./form/FileFormField";
import {Grid} from "@mui/material";

type FileFormValues = {
    files: File[]
}

export interface FileFormProps extends FormProps<FileFormValues> {
    onFileUploaded?: (files: JsonFile[]) => void,
    info?: JSX.Element,
    filesLimit?: number,
    acceptedFiles?: string[],
    maxFileSize?: number,
    onRequestChange?: (file: File) => Promise<JsonFile | undefined>,
    fileType?: SaveAttachmentFileUsingPOSTFileTypeEnum,
    inviteId?: number,
}

const FileForm = (props: FileFormProps) => {
    const {info, onFileUploaded, onCancel, filesLimit, acceptedFiles, maxFileSize, onRequestChange, fileType, inviteId} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const handleFileUpload = useCallback(async (values: FileFormValues) => {
        const results: JsonFile[] = [];
        for (const file of values.files) {
            const fileRes = onRequestChange
                ? await onRequestChange(file)
                : getApiResult<JsonFile>(await dispatch(saveAttachment({
                    attachment: file,
                    fileType: fileType || SaveAttachmentFileUsingPOSTFileTypeEnum.Attachment,
                    inviteId
                })));
            if (fileRes) {
                results.push(fileRes);
            }
        }

        if (onFileUploaded) {
            onFileUploaded(results);
        }

    }, [dispatch, fileType, onRequestChange, onFileUploaded, inviteId])

    const uploadFormValidate = useCallback((values: FileFormValues) => {
        let errors = {} as FormikErrors<FileFormValues>;
        // if (!values.file) {
        //     errors.file = t('Vložte prosím soubor');
        // }
        return errors;
    }, []);

    const initialValues: FileFormValues = useMemo(() => {
        return {
            files: []
        };

    }, []);

    const children = useCallback(() => {
        return <>
            {info}
            <Grid item xs={12}>
                <FileFormField name={'files'} filesLimit={filesLimit || 3} acceptedFiles={acceptedFiles} maxFileSize={maxFileSize}/>
            </Grid>
        </>
    }, [acceptedFiles, maxFileSize, filesLimit, info]);


    return <FormContainer
        key={'upload'}
        item={initialValues}
        validate={uploadFormValidate}
        children={children}
        saveButtonTitle={t('Použít soubory')}
        onCancel={onCancel}
        onSave={handleFileUpload}
    />
}

export default FileForm;
