import {AppDispatch, RootState} from '../index';
import {authReducer} from "./auth";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {codebooksReducer} from "./codebooks";
import {localAppReducer} from './localApp';
import {usersReducer} from './users';
import {eventsReducer} from "./events";
import {partiesReducer} from "./parties";
import {invitesReducer} from "./invites";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {AsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {eventDaysReducer} from "./eventDays";
import {placesReducer} from "./places";
import {articlesReducer} from "./articles";
import {contingentsReducer} from "./contingents";
import {articleTypesReducer} from "./articleTypes";
import {eventPartiesReducer} from "./eventParties";

export type ItemsState<E, F = {}> = {
    loading: boolean;
    count: number;
    items: E[];
    filter?: F;
    itemAction?: PayloadAction & { meta: { requestId: string } },
    countAction?: PayloadAction & { meta: { requestId: string } }
}

export const createDefaultListState = <E, F = {}>(): ItemsState<E, F> => {
    return {
        loading: true,
        count: 0,
        items: [] as Array<E>,
        filter: undefined
    }
}

export const createDefaultListReducer = <E, F, S extends ItemsState<E, F>>(
    builder: ActionReducerMapBuilder<S>,
    fetchItems: AsyncThunk<{ data?: Array<E>, filter: F }, any, {}>,
    fetchCount: AsyncThunk<{ data?: number }, any, {}>
) => {
    builder.addCase(fetchItems.pending, (state, action) => {
        return {...state, loading: true, itemAction: action};
    });
    builder.addCase(fetchItems.fulfilled, (state, action) => {
        if (state.itemAction && state.itemAction.meta.requestId !== action.meta.requestId) {
            console.log("Ignored obsolete result for " + JSON.stringify(state.itemAction));
            return state;
        }
        return {
            ...state,
            items: action.payload.data || [],
            filter: action.payload.filter,
            loading: false
        };
    });
    builder.addCase(fetchItems.rejected, (state, action) => ({...state, items: state.items || [], loading: false}));

    builder.addCase(fetchCount.pending, (state, action) => {
        return {...state, countAction: action};
    });
    builder.addCase(fetchCount.fulfilled, (state, action) => {
        if (state.countAction && state.countAction.meta.requestId !== action.meta.requestId) {
            console.log("Ignored obsolete count for " + JSON.stringify(state.countAction));
            return state;
        }
        return {
            ...state,
            count: action.payload.data || 0
        };
    });
    builder.addCase(fetchCount.rejected, (state, action) => ({...state, count: state.count || 0}));
}

export const reducers = {
    auth: authReducer,
    codebooks: codebooksReducer,
    localApp: localAppReducer,
    users: usersReducer,
    events: eventsReducer,
    eventDays: eventDaysReducer,
    parties: partiesReducer,
    eventParties: eventPartiesReducer,
    invites: invitesReducer,
    places: placesReducer,
    articles: articlesReducer,
    articleTypes: articleTypesReducer,
    contingents: contingentsReducer
};

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
    extra?: unknown;
    rejectValue?: unknown;
};
