/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventPartyInfo,
    JsonEventPartyInfoFromJSON,
    JsonEventPartyInfoFromJSONTyped,
    JsonEventPartyInfoToJSON,
} from './JsonEventPartyInfo';
import {
    JsonPartyInfo,
    JsonPartyInfoFromJSON,
    JsonPartyInfoFromJSONTyped,
    JsonPartyInfoToJSON,
} from './JsonPartyInfo';

/**
 * 
 * @export
 * @interface JsonSiwiPartyInfo
 */
export interface JsonSiwiPartyInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSiwiPartyInfo
     */
    allowedActions?: Array<JsonSiwiPartyInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSiwiPartyInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {JsonEventPartyInfo}
     * @memberof JsonSiwiPartyInfo
     */
    existingEventParty?: JsonEventPartyInfo;
    /**
     * 
     * @type {JsonPartyInfo}
     * @memberof JsonSiwiPartyInfo
     */
    existingParty?: JsonPartyInfo;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSiwiPartyInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSiwiPartyInfo
     */
    photoId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSiwiPartyInfo
     */
    photoScore?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    sex?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    siwiId?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSiwiPartyInfo
     */
    siwiPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSiwiPartyInfo
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSiwiPartyInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSiwiPartyInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonSiwiPartyInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonSiwiPartyInfoFromJSON(json: any): JsonSiwiPartyInfo {
    return JsonSiwiPartyInfoFromJSONTyped(json, false);
}

export function JsonSiwiPartyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSiwiPartyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'existingEventParty': !exists(json, 'existingEventParty') ? undefined : JsonEventPartyInfoFromJSON(json['existingEventParty']),
        'existingParty': !exists(json, 'existingParty') ? undefined : JsonPartyInfoFromJSON(json['existingParty']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'photoScore': !exists(json, 'photoScore') ? undefined : json['photoScore'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'siwiId': !exists(json, 'siwiId') ? undefined : json['siwiId'],
        'siwiPartyId': !exists(json, 'siwiPartyId') ? undefined : json['siwiPartyId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonSiwiPartyInfoToJSON(value?: JsonSiwiPartyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'existingEventParty': JsonEventPartyInfoToJSON(value.existingEventParty),
        'existingParty': JsonPartyInfoToJSON(value.existingParty),
        'firstName': value.firstName,
        'fullName': value.fullName,
        'lastName': value.lastName,
        'partyId': value.partyId,
        'photoGuid': value.photoGuid,
        'photoId': value.photoId,
        'photoScore': value.photoScore,
        'sex': value.sex,
        'siwiId': value.siwiId,
        'siwiPartyId': value.siwiPartyId,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

