import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridFilterProps, DataGridItemAction, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {GetPlaceListUsingGETRequest, GetPlaceListUsingGETStatusesEnum, JsonPlace, JsonPlaceInfo, JsonPlaceInfoFoodServicesEnum, JsonPlaceStatusEnum} from "../../generated-api";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {selectPlaces} from "../../store/selectors";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {CheckBoxOutlined, Edit} from "@mui/icons-material";
import {fetchPlace, fetchPlaces, savePlace} from "../../store/places";
import {OptionValue} from "../../model/form";
import {Button, Grid} from "@mui/material";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {useAppTranslation} from "../../services/i18n";
import PlaceModal from "../../pages/PlaceModal";
import {foodServiceOptions} from "../../model/place";
import {fetchCodebooks} from "../../store/codebooks";

interface LocalFilter {
    flags: string[]
}

interface EventPlacesGridFilter extends DataGridFilter, GetPlaceListUsingGETRequest, LocalFilter {
}

interface EventPlacesListGridState extends DataGridState<JsonPlaceInfo, EventPlacesGridFilter> {
}

const cols: DataGridCol<JsonPlaceInfo>[] = [
    // createCol('ID', 'placeId', 40, undefined, (v) => <small>{v}</small>),
    createCol('Název', 'title', 250, 'Název místa'),
    createCol('PP', 'isWork', 50, 'Pracovní prostor', (v) => v ? <CheckBoxOutlined/> : null),
    createCol('Meet', 'isMeet', 50, 'Meeting prostor', (v) => v ? <CheckBoxOutlined/> : null),
    createCol('Strava', 'isFood', 200, 'Je možné využít pro stravování',
        (v, item: JsonPlaceInfo) => v ? <>
            <CheckBoxOutlined/>
            <span className={'food-services'}>
                {foodServiceOptions
                    .filter((option) => item.foodServices && item.foodServices.indexOf(option.value as JsonPlaceInfoFoodServicesEnum) >= 0)
                    .map((option, i) => <span key={i} title={option.tooltip}>{option.label}</span>)
                }
            </span>
            {item.foodCapacity ? ' (' + item.foodCapacity + ')' : null}
        </> : null),
    createCol('Ubyt', 'isAcm', 50, 'Je možné využít pro ubytování', (v) => v ? <CheckBoxOutlined/> : null),
    createCol('Parkování', 'isPark', 100, 'Je možné využít pro parkování',
        (v, item) => v ? <><CheckBoxOutlined/>{item.parkCapacity ? ' (' + item.parkCapacity + ')' : null}</> : null),
];

const defaultState: EventPlacesListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'title',
        flags: []
    },
    filterCallback: (filter, item): boolean => {
        if (filter.flags?.indexOf('food') >= 0 && !item.isFood) {
            return false;
        }
        if (filter.flags?.indexOf('acm') >= 0 && !item.isAcm) {
            return false;
        }
        if (filter.flags?.indexOf('park') >= 0 && !item.isPark) {
            return false;
        }
        if (filter.flags?.indexOf('work') >= 0 && !item.isWork) {
            return false;
        }
        if (filter.flags?.indexOf('meet') >= 0 && !item.isMeet) {
            return false;
        }
        return true;
    }
};

const flagOptions: OptionValue[] = [
    {value: 'work', label: 'PP'},
    {value: 'meet', label: 'Meeting'},
    {value: 'food', label: 'Strava'},
    {value: 'acm', label: 'Ubytování'},
    {value: 'park', label: 'Parkování'},
]

const filterFields = (props: DataGridFilterProps<EventPlacesGridFilter>): JSX.Element => {
    const {formProps} = props;
    return <Grid container spacing={1}>
        <Grid item xs={3}>
            <ButtonGroupField name={'flags'} label={'Typ místa'} onChange={formProps.submitForm} options={flagOptions} isMulti={true}/>
        </Grid>
    </Grid>
}

const EventPlacesList = (props: { eventId: number, maxHeight?: string, minHeight?: string }) => {
    const {eventId, maxHeight, minHeight} = props;
    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const eventPlaces = useAppSelector<ItemsState<JsonPlaceInfo, EventPlacesGridFilter>>(selectPlaces);

    const [editPlace, setEditPlace] = useState<JsonPlace | undefined>(undefined);

    const handleFetchPlaces = useCallback(() => {
        dispatch(fetchPlaces({eventId, statuses: [GetPlaceListUsingGETStatusesEnum.Active]}));
    }, [eventId, dispatch]);

    const handleEditPlace = useCallback((placeId?: number) => {
        if (placeId) {
            dispatch(fetchPlace(placeId)).then((res) => setEditPlace(getApiResult(res)));
        } else {
            setEditPlace({eventId});
        }
    }, [dispatch, eventId]);

    const handleSavePlace = useCallback(async (place: JsonPlace, isDelete?: boolean) => {
        const res = await dispatch(savePlace({...place, status: isDelete ? JsonPlaceStatusEnum.Deleted : place.status}));
        const item = getApiResult<JsonPlace>(res);
        if (item) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Místo {{title}} ponecháno beze změn',
                [ApiChangeType.UPDATED]: isDelete ? 'Místo {{title}} úspěšně odstraněno' : 'Místo {{title}} úspěšně upraveno',
                [ApiChangeType.CREATED]: 'Místo {{title}} úspěšně vytvořeno'
            }, t, dispatch);
            setEditPlace(undefined);
            dispatch(fetchCodebooks());
            handleFetchPlaces();
        }

    }, [handleFetchPlaces, dispatch, t])

    const handleCancelPlace = useCallback(() => {
        setEditPlace(undefined);
    }, [])

    useEffect(() => {
        handleFetchPlaces();
    }, [handleFetchPlaces]);

    const actions = [
        {
            title: 'Upravit místo',
            icon: <Edit/>,
            callback: (item) => handleEditPlace(item.placeId)
        }
    ] as DataGridItemAction<JsonPlaceInfo>[];

    return <>
        <div className={'info-box-actions'}>
            <Button variant={'contained'} size={'small'} color={'primary'} onClick={() => handleEditPlace()}>{t('Nové místo')}</Button>
        </div>
        <DataGrid
            cols={cols}
            defaultState={defaultState}
            itemsState={eventPlaces}
            mode={DataGridMode.CLIENT}
            filterFields={filterFields}
            actions={actions}
            tableProps={{maxHeight, minHeight}}
        />
        {editPlace && <PlaceModal
			item={editPlace}
			onCancel={handleCancelPlace}
			onSave={handleSavePlace}
			onDelete={editPlace.placeId ? (place) => handleSavePlace(place, true) : undefined}
			deleteConfirmText={'Skutečně trvale odstranit místo {{title}}?'}
		/>}
    </>
}

export default EventPlacesList;
