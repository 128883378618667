/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonEventPartyArticleInfo
 */
export interface JsonEventPartyArticleInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventPartyArticleInfo
     */
    allowedActions?: Array<JsonEventPartyArticleInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    articleTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyArticleInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    eventPartyArticleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    eventPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    prefArticleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    prefCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    recArticleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    recCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyArticleInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyArticleInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyArticleInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventPartyArticleInfoFromJSON(json: any): JsonEventPartyArticleInfo {
    return JsonEventPartyArticleInfoFromJSONTyped(json, false);
}

export function JsonEventPartyArticleInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyArticleInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'articleTypeId': !exists(json, 'articleTypeId') ? undefined : json['articleTypeId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventPartyArticleId': !exists(json, 'eventPartyArticleId') ? undefined : json['eventPartyArticleId'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'prefArticleId': !exists(json, 'prefArticleId') ? undefined : json['prefArticleId'],
        'prefCnt': !exists(json, 'prefCnt') ? undefined : json['prefCnt'],
        'recArticleId': !exists(json, 'recArticleId') ? undefined : json['recArticleId'],
        'recCnt': !exists(json, 'recCnt') ? undefined : json['recCnt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventPartyArticleInfoToJSON(value?: JsonEventPartyArticleInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'articleTypeId': value.articleTypeId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventPartyArticleId': value.eventPartyArticleId,
        'eventPartyId': value.eventPartyId,
        'prefArticleId': value.prefArticleId,
        'prefCnt': value.prefCnt,
        'recArticleId': value.recArticleId,
        'recCnt': value.recCnt,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

