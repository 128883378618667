/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonGroupSettings,
    JsonGroupSettingsFromJSON,
    JsonGroupSettingsFromJSONTyped,
    JsonGroupSettingsToJSON,
} from './JsonGroupSettings';

/**
 * 
 * @export
 * @interface JsonGroupInfo
 */
export interface JsonGroupInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonGroupInfo
     */
    allowedActions?: Array<JsonGroupInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonGroupInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonGroupInfo
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupInfo
     */
    groupType?: JsonGroupInfoGroupTypeEnum;
    /**
     * 
     * @type {JsonGroupSettings}
     * @memberof JsonGroupInfo
     */
    settings?: JsonGroupSettings;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonGroupInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonGroupInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonGroupInfoGroupTypeEnum {
    Org = 'ORG',
    Supp = 'SUPP',
    Guest = 'GUEST'
}

export function JsonGroupInfoFromJSON(json: any): JsonGroupInfo {
    return JsonGroupInfoFromJSONTyped(json, false);
}

export function JsonGroupInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonGroupInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'groupType': !exists(json, 'groupType') ? undefined : json['groupType'],
        'settings': !exists(json, 'settings') ? undefined : JsonGroupSettingsFromJSON(json['settings']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonGroupInfoToJSON(value?: JsonGroupInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'groupId': value.groupId,
        'groupType': value.groupType,
        'settings': JsonGroupSettingsToJSON(value.settings),
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

