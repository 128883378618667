/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFoodData,
    JsonFoodDataFromJSON,
    JsonFoodDataFromJSONTyped,
    JsonFoodDataToJSON,
} from './JsonFoodData';

/**
 * 
 * @export
 * @interface JsonEventPartyDayDetails
 */
export interface JsonEventPartyDayDetails {
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    acmCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    acmId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    dayNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    foodBrId?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof JsonEventPartyDayDetails
     */
    foodCnt?: { [key: string]: number; };
    /**
     * 
     * @type {JsonFoodData}
     * @memberof JsonEventPartyDayDetails
     */
    foodData?: JsonFoodData;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    foodDiId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    foodLuId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    foodSnId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    parkCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    parkFlag?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    parkId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyDayDetails
     */
    parkPlates?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDayDetails
     */
    parkRec?: number;
}

export function JsonEventPartyDayDetailsFromJSON(json: any): JsonEventPartyDayDetails {
    return JsonEventPartyDayDetailsFromJSONTyped(json, false);
}

export function JsonEventPartyDayDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyDayDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acmCnt': !exists(json, 'acmCnt') ? undefined : json['acmCnt'],
        'acmId': !exists(json, 'acmId') ? undefined : json['acmId'],
        'dayNo': !exists(json, 'dayNo') ? undefined : json['dayNo'],
        'foodBrId': !exists(json, 'foodBrId') ? undefined : json['foodBrId'],
        'foodCnt': !exists(json, 'foodCnt') ? undefined : json['foodCnt'],
        'foodData': !exists(json, 'foodData') ? undefined : JsonFoodDataFromJSON(json['foodData']),
        'foodDiId': !exists(json, 'foodDiId') ? undefined : json['foodDiId'],
        'foodLuId': !exists(json, 'foodLuId') ? undefined : json['foodLuId'],
        'foodSnId': !exists(json, 'foodSnId') ? undefined : json['foodSnId'],
        'parkCnt': !exists(json, 'parkCnt') ? undefined : json['parkCnt'],
        'parkFlag': !exists(json, 'parkFlag') ? undefined : json['parkFlag'],
        'parkId': !exists(json, 'parkId') ? undefined : json['parkId'],
        'parkPlates': !exists(json, 'parkPlates') ? undefined : json['parkPlates'],
        'parkRec': !exists(json, 'parkRec') ? undefined : json['parkRec'],
    };
}

export function JsonEventPartyDayDetailsToJSON(value?: JsonEventPartyDayDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acmCnt': value.acmCnt,
        'acmId': value.acmId,
        'dayNo': value.dayNo,
        'foodBrId': value.foodBrId,
        'foodCnt': value.foodCnt,
        'foodData': JsonFoodDataToJSON(value.foodData),
        'foodDiId': value.foodDiId,
        'foodLuId': value.foodLuId,
        'foodSnId': value.foodSnId,
        'parkCnt': value.parkCnt,
        'parkFlag': value.parkFlag,
        'parkId': value.parkId,
        'parkPlates': value.parkPlates,
        'parkRec': value.parkRec,
    };
}

