import {ApiSerializedError} from '../model/api';
import {TAppFunction} from '../services/i18n';
import {AppDispatch} from "../index";
import {addMessage} from "../store/localApp";
import {AlertProps} from "@mui/material";

export function getApiErrorData(action: any, t: TAppFunction) {
    let message = null;
    let code = null;
    if ((action.payload as any)?.message) {
        message = (action.payload as any)?.message;
    } else if ('error' in action) {
        message = (action.error as ApiSerializedError)?.message;
    }

    if ((action.payload as any)?.code) {
        code = (action.payload as any)?.code;
    } else if ('error' in action) {
        code = (action.error as ApiSerializedError)?.code;
    }

    switch (message) {
        case 'Network request failed':
        case 'Failed to fetch (TypeError)':
            message = t('Aplikace je momentálně nedostupná, nebo není k dispozici připojení k internetu. Opaktujte akci později, prosím.');
            code = 'NETWORK_ERROR';
            break;
        case 'Timeout':
            message = t('Server neodpověděl v časovém limitu. Opaktujte akci později, prosím.');
            code = 'NETWORK_TIMEOUT';
            break;
        case 'Rejected':
            message =
                t('Chyba komunikace.') +
                `\n(${action.error}, ${JSON.stringify(action.payload)})`;
            code = 'ACTION_REJECTED'
            break;
    }

    return {message, code};
}

interface ApiResult<T> {
    data?: T;
    message?: string;
    messageValues?: object;
    result: string;
}

export const getApiResult = <T, >(res: any): T | undefined => {
    if (!res || 'error' in res) {
        // already reported
        return undefined;
    }
    const p = res.payload as ApiResult<T>;
    if (p.result !== 'OK') {
        console.log(res);
        // already reported XXX
        return undefined;
    }

    return p.data;
}

export enum ApiChangeType {
    NO_CHANGE = 'NO_CHANGE',
    UPDATED = 'UPDATED',
    CREATED = 'CREATED'
}

export type ResultMessages = { [k in ApiChangeType]?: string | [string, (item: any) => any] };

export const addApiResultMessage = (
    res: any,
    messages: ResultMessages,
    t: TAppFunction,
    dispatch: AppDispatch
) => {

    const apiChangeType = res.payload?.message ? res.payload.message as ApiChangeType : undefined;
    let message;
    const m = apiChangeType ? messages[apiChangeType] : undefined;
    if (m) {
        if (typeof m === 'string') {
            message = t(m, res.payload?.data)
        } else {
            message = t(m[0], {...res.payload?.data, title: m[1](res.payload?.data)});
        }
    } else {
        if (typeof res.payload?.data === 'string') {
            message = res.payload?.data;
        } else {
            console.log('Unknown change type: ', res);
            message = t('Provedeno (' + res.payload?.message + ')');
        }
    }

    showApiMessage(
        res,
        apiChangeType === ApiChangeType.NO_CHANGE ? 'info' : 'success',
        message,
        dispatch
    );
}

export const showApiMessage = (
    res: any,
    severity: AlertProps['severity'],
    title: string,
    dispatch: AppDispatch,
) => {
    dispatch(addMessage({
        code: res.payload?.result,
        action: res.type,
        severity,
        title
    }));
}