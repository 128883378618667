import {
    JsonArticleType,
    JsonEventDayInfo,
    JsonEventPartyTravelVehicleEnum,
    JsonFile,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteInfoSexEnum,
    JsonInviteReply,
    JsonInviteReplyReplyTypeEnum,
    JsonRsvp
} from "../../generated-api";
import {FormikProps} from "formik";
import {TAppFunction, useAppTranslation} from "../../services/i18n";
import {useAppDispatch} from "../../store";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {getApiResult} from "../../helpers/api";
import {saveRsvp, saveRsvpFile, saveRsvpFileTravel} from "../../store/rsvp";
import {Alert, AlertTitle, Button, Card, CardActions, CardContent, FormHelperText, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {RadioGroupField} from "../form/RadioGroupField";
import {TextFormField} from "../form/TextFormField";
import {PhotoFormField} from "../form/PhotoFormField";
import {createOption, isAnyOtherSexSelected, OptionValue} from "../../model/form";
import {articleSize} from "../../helpers/format";
import {SimpleSelectFormField} from "../form/SimpleSelectFormField";
import {CheckboxField, CheckboxPlain} from "../form/CheckboxField";
import {CloseRounded, HelpRounded, PlaylistAddCheck} from "@mui/icons-material";
import ScrollErrorsIntoView from "../form/ScrollErrorsIntoView";
import {dateToGui} from "../../helpers/date";
import RsvpInviteEventDay from "./RsvpInviteEventDay";
import RsvpInviteSupplierParties from "./RsvpInviteSupplierParties";
import {RsvpInviteTravels} from "./RsvpInviteTravels";
import {RsvpInviteComp} from "./RsvpInviteComp";
import {replyIsAcm} from "../../model/invite";
import {articleSexOptions} from "../../model/party";

const replyTypeOptions = [
    createOption(JsonInviteReplyReplyTypeEnum.Accept, 'Ano, mám zájem se události zúčastnit', undefined, undefined, 'success'),
    createOption(JsonInviteReplyReplyTypeEnum.Reject, 'Ne, o účast nemám zájem', undefined, undefined, 'error'),
];

const infoIsValidOptions = [
    createOption(true, 'Jméno a příjmení jsou v pořádku'),
    createOption(false, 'Údaje chci upravit'),
];

const eventDates = (rsvp: JsonRsvp, t: TAppFunction): string => {
    const {event, eventDays, invite} = rsvp;
    if (!event || !eventDays || !invite) {
        return '';
    }

    if (eventDays.length === 1) {
        return t('dne') + ' ' + dateToGui(eventDays[0].dayDate) || '';
    }

    return t('ve dnech') + ' ' + dateToGui(eventDays[0].dayDate) + ' - ' + dateToGui(eventDays[eventDays.length - 1].dayDate);
}

type RsvpInviteFormProps = {
    rsvp: JsonRsvp,
    token: string,
    formikProps: FormikProps<JsonInviteReply>,
    isDaysLocked: boolean
}

const RsvpInviteForm = (props: RsvpInviteFormProps) => {

    const {formikProps, rsvp, token, isDaysLocked} = props;
    const {values, initialValues} = formikProps;
    const {event, invite, eventDays} = rsvp;
    const {setFieldValue} = formikProps;

    const inviteData = invite?.inviteData;
    const inviteSex = invite?.sex;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isArticleOtherSex, setIsArticleOtherSex] = useState<boolean>(isAnyOtherSexSelected(inviteSex, initialValues.replyData?.articles));
    const [showArticleAllSex, setShowArticleAllSex] = useState<boolean>(isArticleOtherSex);

    const onReplySave = useCallback(async (reply: JsonInviteReply) => {
        return getApiResult(await dispatch(saveRsvp({token, reply})));

    }, [token, dispatch]);

    const onFileUpload = useCallback(async (file: File, replyValues: JsonInviteReply, isTravel?: boolean) => {
        if (isTravel) {
            return getApiResult<JsonFile>(await dispatch(saveRsvpFileTravel({token, file})));
        }
        if (await onReplySave(replyValues)) {
            const fileRes = await dispatch(saveRsvpFile({token, file}));
            return getApiResult<JsonFile>(fileRes);
        }

    }, [token, onReplySave, dispatch]);

    const articleTypeOptions = useMemo(() => {
        const articleTypeOptions: { [key in number]: OptionValue[] } = {};
        inviteData?.articleTypes?.forEach((at) => {
            if (!at.articleTypeId || !at.variants?.sizes) {
                return;
            }
            const options: OptionValue[] = [];
            if (at?.variants?.sizes) {
                Object.keys(at.variants.sizes).forEach((sex) => {
                    if (!showArticleAllSex && sex !== 'U' && !!inviteSex && inviteSex !== sex as JsonInviteInfoSexEnum) {
                        return; // do not offer opposite sex articles
                    }
                    const sexTitle = showArticleAllSex && articleSexOptions.find((o) => o.value === sex)?.tooltip;
                    const sizes = at.variants?.sizes ? at.variants.sizes[sex] : undefined;
                    sizes?.forEach((size) => {
                        const article = inviteData?.articles?.find((a) => a.articleTypeId === at.articleTypeId && a.size === size && a.sex === sex);
                        if (article?.articleId) {
                            options.push(createOption(article.articleId, articleSize(sex, size) + (sexTitle ? ' ' + sexTitle : '')));
                        }
                    })
                });
            }
            if (at.articleTypeId) {
                articleTypeOptions[at.articleTypeId] = options;
            }
        })
        return articleTypeOptions;
    }, [inviteData, inviteSex, showArticleAllSex]);

    const gdpr = useMemo(() => {
        if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
            return null;
        }
        const q = invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer || invite?.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg
            ? t('Potvrzuji, že mnou uvedené informace odpovídají skutečnosti, a souhlasím se zpracováním osobních údajů pro účely účasti na sportovní události.')
            : (invite?.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg
                ? !!values.replyData?.travels?.find((t) => t.vehicle === JsonEventPartyTravelVehicleEnum.Car)
                    ? t('Prohlašuji, že výše uvedené údaje jsou správné a souhlasím s proplacením cestovného podle směrnice ČSB o výši náhrad jízdného a stravného pro pořadatele soutěží ve výši 5,20 Kč za 1 km (automobil vlastní).')
                    : t('Prohlašuji, že výše uvedené údaje jsou správné a souhlasím s proplacením cestovného podle směrnice ČSB o výši náhrad jízdného a stravného pro pořadatele soutěží.')
                : t('Potvrzuji, že mnou uvedené informace odpovídají skutečnosti, a souhlasím se zpracováním osobních údajů pro účely umožnění přístupu na sportovní událost.'));
        return <Grid container>
            <Grid item xs={11}>
                <CheckboxField name={'replyData.isGdpr'} color={'default'} label={q}/>
            </Grid>
            <Grid item xs={1}>
                <Tooltip title={<div>
                    <p>{t('gdpr1')}</p>
                    <p>{t('gdpr2')}</p>
                </div>} placement={'top-end'} enterDelay={1000} arrow
                    PopperProps={{sx: {'& .MuiTooltip-tooltip': {maxWidth: 'clamp(200px, 600px, 80vw)'}}}}>
                    <HelpRounded sx={{fontSize: '150%', margin: '0 0 -5px 5px', color: 'var(--dark-gray)'}}/>
                </Tooltip>
            </Grid>
        </Grid>
    }, [invite?.inviteType, values.replyData?.travels, t]);

    const articleSelectCallback = useCallback((at: JsonArticleType, i: number, v: number) => {
        const a = inviteData?.articles?.find((a) => a.articleId === v);
        const article = {
            articleTypeId: at.articleTypeId,
            size: a?.size,
            sex: a?.sex
        };
        setFieldValue('replyData.articles.' + i + '.articleTypeId', article.articleTypeId, false);
        setFieldValue('replyData.articles.' + i + '.size', article.size, false);
        setFieldValue('replyData.articles.' + i + '.sex', article.sex, false);
        const newValues = values?.replyData?.articles || [];
        newValues[i] = article;
        setIsArticleOtherSex(isAnyOtherSexSelected(inviteSex, newValues));
    }, [setFieldValue, inviteData?.articles, inviteSex, values?.replyData?.articles]);

    const articleFormTables = useMemo(() => {
        if (!(inviteData?.articleTypes && inviteData?.articleTypes.length > 0)) {
            return null;
        }
        const tables = [];
        const ROW_SIZE = 4;
        const rowCount = Math.ceil(inviteData.articleTypes.length / ROW_SIZE);

        const padding: number[] = [];
        for (let i = inviteData.articleTypes.length % ROW_SIZE; i < ROW_SIZE; i++) {
            padding.push(i);
        }

        for (let p = 0; p < rowCount; p++) {
            const types = inviteData.articleTypes.slice(p * ROW_SIZE, (p + 1) * ROW_SIZE);
            tables.push(<Table key={p} className={'data-grid'}>
                <TableHead>
                    <TableRow>
                        {types.map((at, i) => {
                            return <TableCell key={i}>
                                <div style={{padding: '10px'}}>{at.title}</div>
                            </TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{'&:hover td': {background: 'inherit !important', borderBottom: '1px solid var(--gray-background) !important'}}}>
                        {types.map((at, i) => {
                            if (!at.articleTypeId || !at.variants?.sizes) {
                                return <TableCell key={i}>-</TableCell>;
                            }
                            const articleIndex = p * ROW_SIZE + i;
                            return <TableCell key={i}>
                                <div style={{padding: '10px'}}><SimpleSelectFormField
                                    placeholder='Velikost'
                                    name={'replyData.articles.' + articleIndex + '.articleId'}
                                    onChange={(v) => {
                                        articleSelectCallback(at, articleIndex, v);
                                    }}
                                    options={articleTypeOptions[at.articleTypeId]}/></div>
                            </TableCell>;
                        })}
                        {p === rowCount - 1 && padding.map((i) => {
                            return <TableCell key={i}></TableCell>
                        })}
                    </TableRow>
                </TableBody>
            </Table>);
        }
        return tables;

    }, [inviteData?.articleTypes, articleTypeOptions, articleSelectCallback]);

    if (!rsvp || !event || !invite || !eventDays) {
        return null;
    }

    let title;
    let header;
    let isPersonal;
    let isArticles: boolean;
    if (invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
        title = (event.title?.toLowerCase && event.title?.toLowerCase().indexOf('výzva') >= 0)
            ? event.title
            : <span>{t('Výzva k doplnění údajů')} {event.title} </span>;
        header = <Card>
            <CardContent className={'rsvp-header'}>
                <p dangerouslySetInnerHTML={{__html: t('Děkujeme za váš zájem účastnit se a pomáhat při organizaci události v biatlonu v Novém Městě na Moravě {{dates}}.', {dates: eventDates(rsvp, t)})}}/>
                <p>{t('Zkontrolujte prosím všechny údaje uvedené v tomto formuláři a v případě potřeby je doplňte či aktualizujte, děkujeme.')}</p>
                {!!invite.processNote &&
					<Alert severity={'warning'}>
						<AlertTitle>{t('Výzva vám byla vrácena k doplnění')}</AlertTitle>
						<blockquote>{invite.processNote}</blockquote>
					</Alert>
                }
            </CardContent>
        </Card>;
        isPersonal = true;
        isArticles = true;

    } else if (invite.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg) {
        title = (event.title?.toLowerCase && event.title?.toLowerCase().indexOf('výzva') >= 0)
            ? event.title
            : <span>{t('Výzva k zadání cestovních náhrad')} {event.title} </span>;
        header = <Card>
            <CardContent className={'rsvp-header'}>
                <p dangerouslySetInnerHTML={{__html: t('Děkujeme za vaši pomoc při organizaci události v biatlonu v Novém Městě na Moravě {{dates}}.', {dates: eventDates(rsvp, t)})}}/>
                <p>{t('Pokud si přejete uplatnit nárok na vyplacení cestovních náhrad, zadejte je prosím níže do formuláře.')}</p>
                {!!invite.processNote &&
					<Alert severity={'warning'}>
						<AlertTitle>{t('Výzva vám byla vrácena k doplnění')}</AlertTitle>
						<blockquote>{invite.processNote}</blockquote>
					</Alert>
                }
            </CardContent>
        </Card>;
        isPersonal = false;
        isArticles = false;

    } else if (invite.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
        title = (event.title?.toLowerCase && event.title?.toLowerCase().indexOf('výzva') >= 0)
            ? event.title
            : <span>{t('Výzva k potvrzení výplaty')} {event.title} </span>;
        header = <Card>
            <CardContent className={'rsvp-header'}>
                {rsvp.template?.bodyTop
                    ? <p dangerouslySetInnerHTML={{__html: rsvp.template.bodyTop}}/>
                    : <><p dangerouslySetInnerHTML={{
                        __html:
                            t('Děkujeme za vaši pomoc při organizaci události v biatlonu v Novém Městě na Moravě {{dates}}.', {dates: eventDates(rsvp, t)})
                    }}/>
                        <p>{t('Potvrďte prosím, zda souhlasíte s níže navrženou výší celkové výplaty, a ověřte správnost čísla bankovního účtu, na které budou prostředky poukázány.')}</p>
                    </>}
                {!!invite.processNote &&
					<Alert severity={'warning'}>
						<AlertTitle>{t('Výzva byla vrácena k nové kontrole')}</AlertTitle>
						<blockquote>{invite.processNote}</blockquote>
					</Alert>
                }
            </CardContent>
        </Card>;
        isPersonal = false;
        isArticles = false;

    } else if (invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier) {
        title = <span>{t('Doplnění personálu pro ')} {event.title} </span>;
        header = <Card>
            <CardContent className={'rsvp-header'}>
                <p dangerouslySetInnerHTML={{__html: t('Prosíme vás o potvrzení vaší účasti a doplnění všech dalších osob personálu, pro které požadujete akreditaci na událost v biatlonu v Novém Městě na Moravě {{dates}}.', {dates: eventDates(rsvp, t)})}}/>
                {!!invite.processNote &&
					<Alert severity={'warning'}>
						<AlertTitle>{t('Formulář vám byl vrácen k doplnění')}</AlertTitle>
						<blockquote>{invite.processNote}</blockquote>
					</Alert>
                }
            </CardContent>
        </Card>;
        isPersonal = true;
        isArticles = false;

    } else {
        title = (event.title?.toLowerCase && event.title?.toLowerCase().indexOf('pozvánka') >= 0)
            ? event.title
            : <span>{t('Pozvánka na událost')} {event.title} </span>;
        header = <Card>
            <CardContent className={'rsvp-header'}>
                <p dangerouslySetInnerHTML={{__html: t('Rádi bychom vás pozvali na událost v biatlonu <strong>{{dates}}</strong>.', {dates: eventDates(rsvp, t)})}}/>
                {!!inviteData?.extraLimit && inviteData.extraLimit > 0
                    && <p dangerouslySetInnerHTML={{
                        __html: eventDays.length > 1
                            ? (inviteData.extraLimit > 1
                                ? (inviteData.extraLimit > 4
                                    ? t('Na každý den je možné s sebou vzít <strong>až {{extraLimit}} dalších osob jako doprovod</strong> (mohou se libovolně opakovat a/nebo být každý den různé).', inviteData)
                                    : t('Na každý den je možné s sebou vzít <strong>doprovod až {{extraLimit}} další osoby</strong> (mohou se libovolně opakovat a/nebo být každý den různé).', inviteData))
                                : t('Na každý den je možné s sebou vzít <strong>doprovod</strong> (může být vždy stejný a/nebo každý den jiný).', inviteData))
                            : (inviteData.extraLimit > 1
                                ? (inviteData.extraLimit > 4
                                    ? t('Je možné s sebou vzít <strong>až {{extraLimit}} dalších osob jako doprovod</strong>.', inviteData)
                                    : t('Je možné s sebou vzít <strong>doprovod až {{extraLimit}} další osoby</strong>.', inviteData))
                                : t('Je možné s sebou vzít <strong>doprovod</strong>.', inviteData))
                    }}/>}
                {invite.inviteType === JsonInviteInfoInviteTypeEnum.Organizer && invite.groupTitle && <p>
                    {t('Na událost Vás zveme do skupiny') + ' '}
					<strong>{invite.groupTitle}</strong>
				</p>}
                {!!invite.processNote &&
					<Alert severity={'warning'}>
						<AlertTitle>{t('Pozvánka Vám byla vrácena k doplnění')}</AlertTitle>
						<blockquote>{invite.processNote}</blockquote>
					</Alert>
                }
            </CardContent>
            {!(isDaysLocked && values.replyType === JsonInviteReplyReplyTypeEnum.Accept) && <CardActions className={'rsvp-reply-type'}>
				<RadioGroupField options={replyTypeOptions} name={'replyType'}/>
			</CardActions>}
        </Card>;
        isPersonal = values.replyType === JsonInviteReplyReplyTypeEnum.Accept;
        isArticles = values.replyType === JsonInviteReplyReplyTypeEnum.Accept && invite.inviteType === JsonInviteInfoInviteTypeEnum.Organizer;
    }

    return <form onSubmit={formikProps.handleSubmit}>
        <h1>{title}</h1>
        {header}
        {isPersonal && <Card>
			<CardContent>
				<Grid container className={'rsvp-info'}>
					<Grid item sm={8}>
						<p>{t('Ověřte prosím správnost vašich osobních údajů, které budou společně s fotografií vytištěny na vaší přístupové kartě.')}</p>
						<Grid container>
							<Grid item sm={5} xs={12}>
								<TextFormField name={'newFirstName'} label={'Jméno'} readonly={values.infoIsValid !== false}/>
								<TextFormField name={'newLastName'} label={'Příjmení'} readonly={values.infoIsValid !== false}/>
							</Grid>
							<Grid item xs={12}>
								<RadioGroupField options={infoIsValidOptions} name={'infoIsValid'} onChange={(v) => {
                                    if (v) {
                                        setTimeout(() => {
                                            formikProps.setFieldValue('newFirstName', initialValues.newFirstName, false);
                                            formikProps.setFieldValue('newLastName', initialValues.newLastName, false);
                                        }, 100);
                                    }
                                }}/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item sm={4}>
						<p>{invite.photoGuid
                            ? t('Ověřte také aktuálnost níže uvedené fotografie a případně nahrajte novou.')
                            : t('Tlačítkem níže prosím nahrajte svou aktuální fotografii.')}</p>
						<PhotoFormField name={'newPhotoGuid'}
							title={((values.newFirstName || '') + ' ' + (values.newLastName || '')).trim()}
							onRequestChange={async (file) => {
                                return await onFileUpload(file, values);
                            }}
							onChange={async (file: JsonFile) => {
                                values.newPhotoGuid = file?.guid;
                                values.newPhotoCvResult = file?.cvResult;
                                await onReplySave(values);
                            }}
							defaultValue={invite.photoGuid}/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}
        {values.replyType === JsonInviteReplyReplyTypeEnum.Accept && invite.inviteType === JsonInviteInfoInviteTypeEnum.Guest && <>
			<h4>
                {eventDays.length > 1
                    ? t('Potvrďte prosím účast na každém níže uvedeném dni akce')
                    : t('Potvrďte prosím účast na uvedeném dni akce')}
                {!!inviteData?.extraLimit && inviteData.extraLimit > 0 && t(' a vyberte případný doprovod')}:
			</h4>

            {eventDays.map((ed: JsonEventDayInfo) => {
                return <RsvpInviteEventDay key={ed.dayNo} inviteData={inviteData} values={values} ed={ed} prevParties={rsvp.prevParties}
                    onFileUpload={onFileUpload} onReplySave={onReplySave} isDaysLocked={isDaysLocked}/>
            })}
		</>}
        {isArticles && !!articleFormTables?.length && <>
			<Card>
				<CardContent>
					<p>{t('Vyberte prosím vaši preferovanou velikost oblečení:')}</p>
					<em style={{display: 'block', paddingTop: '5px'}}>{t('Zobrazené velikosti: {{sex}}', {
                        sex: showArticleAllSex ? 'dámské i pánské' : (inviteSex === JsonInviteInfoSexEnum.F ? 'dámské' : 'pánské')
                    })}</em>
                    {!isArticleOtherSex &&
						<CheckboxPlain name={'isArticleAllSex'} currentValue={showArticleAllSex} label={t('Zobrazit dámské i pánské velikosti')} onChange={setShowArticleAllSex}/>}
                    {articleFormTables}
				</CardContent>
			</Card>
            {/*{!inviteData?.isUser && <Card className={'rsvp-info'}>*/}
            {/*	<CardContent>*/}
            {/*		<p>{t('Po potvrzení účasti Vám bude zřízen přístup do systému EMS, kde budete mít následně možnost spravovat agendu spojenou s událostí.')}</p>*/}
            {/*	</CardContent>*/}
            {/*</Card>}*/}
		</>}
        {values.replyType === JsonInviteReplyReplyTypeEnum.Accept && invite.inviteType === JsonInviteInfoInviteTypeEnum.Organizer && <>
			<Card>
				<CardContent>
					<p>{t('Potřebujete zajistit ubytování?')}</p>
					<Grid container columnSpacing={1}>
						<Grid item xs={12} sx={{marginTop: '10px'}}>
							<RadioGroupField name={'replyData.isAcm'} options={replyIsAcm}/>
						</Grid>
                        {/*{!!values.replyData?.isAcm && <Grid item xs={12}>*/}
                        {/*	<p>*/}
                        {/*		<em>{t('Preferujeme zájemce s vlastním ubytováním v NMNM nebo okolí. Máme omezenou kapacitu ubytování jen pro výjimečné případy. Pokud budeme se zajištěním ubytování pomáhat, počítejte se sdílením nákladů.')}</em>*/}
                        {/*	</p>*/}
                        {/*</Grid>}*/}
					</Grid>
				</CardContent>
			</Card>
		</>}
        {((values.replyType === JsonInviteReplyReplyTypeEnum.Accept && invite.inviteType === JsonInviteInfoInviteTypeEnum.Organizer)
            || invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) && <Card>
			<CardContent>
				<p>{t('Proveďte prosím kontrolu a doplnění níže uvedených údajů pro účely vytvoření smlouvy:')}</p>
				<Grid container columnSpacing={1}>
					<Grid item xs={12} sx={{marginTop: '10px'}}>
						<Grid container columnSpacing={2} rowSpacing={1}>
							<Grid item sm={4} xs={12}>
								<TextFormField name={'replyData.partyData.bankAccount'} label={t('Číslo bankovního účtu')} type={'text'}/>
								<FormHelperText>{t('12345678/1234, 123-4567890/1234 atp.')}</FormHelperText>
							</Grid>
							<Grid item sm={3} xs={12}>
								<TextFormField name={'replyData.partyData.birthDate'} label={t('Datum narození')} type={'date'}/>
							</Grid>
							<Grid item sm={3} xs={12}>
								<TextFormField name={'replyData.partyData.phone'} label={t('Telefon')} type={'text'} maxlength={16}/>
								<FormHelperText>{t('777123456, +421900123456 atp.')}</FormHelperText>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{marginTop: '-10px'}}>
						<h4>{t('Adresa trvalého bydliště')}</h4>
						<Grid container columnSpacing={2} rowSpacing={1}>
							<Grid item sm={4} xs={12}>
								<TextFormField name={'replyData.partyData.permAddress.street'} label={t('Ulice a číslo')} type={'text'}/>
								<FormHelperText>{t('Sportovní 12/34, Hoštice 12 atp.')}</FormHelperText>
							</Grid>
							<Grid item sm={2} xs={6}>
								<TextFormField name={'replyData.partyData.permAddress.zip'} label={t('PSČ')} type={'text'} maxlength={10}/>
								<FormHelperText>{t('132 45')}</FormHelperText>
							</Grid>
							<Grid item sm={6} xs={12}>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextFormField name={'replyData.partyData.permAddress.city'} label={t('Obec')} type={'text'}/>
								<FormHelperText>{t('Nové Město na Moravě, Vyškov atp.')}</FormHelperText>
							</Grid>
							<Grid item sm={2} xs={6}>
								<TextFormField name={'replyData.partyData.permAddress.country'} label={t('Stát')} type={'text'}/>
								<FormHelperText>{t('CZ, SK atp.')}</FormHelperText>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}
        {invite.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg && !!event.eventId && <Card>
			<RsvpInviteTravels token={token} eventId={event.eventId} values={values}
				onFileUpload={onFileUpload} onReplySave={onReplySave}/>
		</Card>}
        {invite.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg && !!event.eventId && !!rsvp.eventParties?.[0] && <Card>
			<RsvpInviteComp values={values} eventParty={rsvp.eventParties[0]} tariffs={rsvp.tariffs}/>
		</Card>}
        {invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier && !!event.eventId && <>
			<RsvpInviteSupplierParties token={token} eventId={event.eventId} values={values}
				onFileUpload={onFileUpload} onReplySave={onReplySave}/>
		</>}
        {(invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg || invite.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg) && <Card>
			<CardContent>
                {gdpr}
			</CardContent>
			<CardActions className={'rsvp-buttons'}>
				<Button variant="contained" type="submit"
					color={'success'}
					disabled={formikProps.isSubmitting}><PlaylistAddCheck/> <span>{t('Pokračovat na shrnutí a odeslání')}</span></Button>
			</CardActions>
		</Card>}
        {invite.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg && values.replyType && <Card>
			<CardContent>
                {values.replyType === JsonInviteReplyReplyTypeEnum.Reject && <TextFormField name={'replyNote'} type={'textarea'} rows={5} maxlength={1000}
					label={'Zdůvodnění'}/>}
			</CardContent>
			<CardActions className={'rsvp-buttons'}>
				<Button variant="contained" type="button"
					onClick={() => {
                        formikProps.setFieldValue('replyData.eventCompDetails', rsvp.eventParties?.[0]?.eventCompDetails);
                        formikProps.setFieldValue('replyData.eventTravelDetails', rsvp.eventParties?.[0]?.eventTravelDetails);
                        formikProps.setFieldValue('replyData.partyData.bankAccount', rsvp.eventParties?.[0]?.bankAccount);
                        formikProps.handleSubmit();
                    }}
					color={'success'}
					disabled={formikProps.isSubmitting}><PlaylistAddCheck/> <span>{t('Pokračovat na shrnutí a odeslání')}</span></Button>
			</CardActions>
		</Card>}
        {invite.inviteType !== JsonInviteInfoInviteTypeEnum.UpdateOrg && invite.inviteType !== JsonInviteInfoInviteTypeEnum.CompOrg && values.replyType && <Card>
			<CardContent>
				<TextFormField name={'replyNote'} type={'textarea'} rows={5} maxlength={1000}
					label={'Pokud si přejete uvést doplňující informace, napiště je prosím zde'}/>
                {values.replyType === JsonInviteReplyReplyTypeEnum.Accept && gdpr}
			</CardContent>
			<CardActions className={'rsvp-buttons'}>
				<Button variant="contained" type="submit"
					color={values.replyType === JsonInviteReplyReplyTypeEnum.Accept ? 'success' : 'error'}
					disabled={formikProps.isSubmitting}>{values.replyType === JsonInviteReplyReplyTypeEnum.Accept
                    ? <><PlaylistAddCheck/> <span>{t('Pokračovat na shrnutí a odeslání')}</span></>
                    : <><CloseRounded/> <span>{t('Odeslat odmítnutí účasti')}</span></>}</Button>
			</CardActions>
		</Card>}
        <ScrollErrorsIntoView/>
    </form>;
}

export default RsvpInviteForm;
