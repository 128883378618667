import {JsonEventPartyMassActionRequest, JsonEventPartyTravel, JsonEventPartyTravelVehicleEnum, JsonFile, JsonInviteReply, SaveAttachmentFileUsingPOSTFileTypeEnum} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {useCallback, useState} from "react";
import {FormikErrors, FormikProps, useFormikContext} from "formik";
import {FAKE_VALUE_AND, formatMoney, FormProps, isNumeric, isTime, isTimeAfter, normalizeMoney} from "../../model/form";
import {Box, Button, Grid} from "@mui/material";
import FormModal from "../form/FormModal";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {FormikContextType} from "formik/dist/types";
import {renderFile} from "../../helpers/files";
import {AttachFileRounded, Delete} from "@mui/icons-material";
import FileModal from "../../pages/FileModal";
import {vehicleOptions} from "../../model/invite";
import {SelectFormField} from "../form/SelectFormField";

type AttachmentsProps = {
    values: JsonEventPartyTravel,
    onFileUpload?: (file: File) => Promise<JsonFile | undefined>,
    inviteId?: number
}

const Attachments = (props: AttachmentsProps) => {
    const {values, onFileUpload, inviteId} = props;

    const t = useAppTranslation();

    const {setFieldValue} = useFormikContext<FormikContextType<JsonEventPartyMassActionRequest>>();
    const [editFiles, setEditFiles] = useState<JsonFile[] | undefined>(undefined);
    const files = values.files;

    const handleEditFiles = useCallback((files?: JsonFile[]) => {
        setEditFiles(files || []);
    }, []);

    const handleCancelFiles = useCallback(() => {
        setEditFiles(undefined);
    }, []);

    const handleSaveFiles = useCallback((values: JsonFile[] | undefined) => {
        let newFiles: JsonFile[] = [];
        if (files?.length) {
            newFiles = [...files];
        }
        if (values?.length) {
            newFiles = [...newFiles, ...values];
        }
        setFieldValue('files', newFiles.length > 0 ? newFiles : undefined, true);
        setEditFiles(undefined);
    }, [files, setFieldValue]);

    return <Box style={{color: 'var(--color-warning)'}} className={'event-action-errors file-list'}>{(files?.length || 0) > 0
        && <div style={{padding: '5px 10px', background: '#eee', display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>{files?.map((f, i) => {
            return <div key={i} style={{flexBasis: '100%', display: 'flex', padding: '5px 0'}}>
                <i style={{alignSelf: 'center', flexGrow: 1}}>{renderFile(f)}</i>
                <Button color={'warning'} size={'small'} variant={'contained'}
                    sx={{minWidth: 'unset'}}
                    title={t('Odebrat soubor')}
                    onClick={() => {
                        const newFiles: JsonFile[] = files?.filter((other) => other.guid !== f.guid) || [];
                        setFieldValue('files', newFiles.length > 0 ? newFiles : undefined, true);
                    }}
                ><Delete/></Button>
            </div>;
        })}
		</div>}
        <Button size={'small'} color={'secondary'} sx={{float: 'right'}} onClick={() => {
            handleEditFiles(files);
        }}><AttachFileRounded/> <span>{files?.length ? t('Přiložit další doklad') : t('Přiložit doklad')}</span></Button>
        {!!editFiles && <FileModal item={{files: []}}
			title={t('Doklad k cestě')}
			info={<Grid item xs={12}>
                <p>{t('Přikládejte prosím soubory ve formátu PDF nebo obrázky.')}</p>
            </Grid>}
			acceptedFiles={["image/*", "application/pdf"]}
			maxFileSize={4 * 1024 * 1024}
			onFileUploaded={handleSaveFiles}
			onCancel={handleCancelFiles}
			fileType={SaveAttachmentFileUsingPOSTFileTypeEnum.Travel}
            inviteId={inviteId}
			onRequestChange={onFileUpload ? async (file) => {
                return await onFileUpload(file);
            } : undefined}
		/>}
    </Box>
}

export interface RsvpInviteTravelFormProps extends FormProps<JsonEventPartyTravel> {
    eventId?: number,
    inviteId?: number,
    original?: JsonEventPartyTravel,
    replyValues?: JsonInviteReply,
    onFileUpload?: (file: File, replyValues: JsonInviteReply, isTravel?: boolean) => Promise<JsonFile | undefined>,
}

export const RsvpInviteTravelForm = (props: RsvpInviteTravelFormProps) => {
    const {onSave, replyValues, onFileUpload, original, inviteId} = props;

    const t = useAppTranslation();

    const validate = useCallback((values: JsonEventPartyTravel) => {
        let errors = {} as FormikErrors<JsonEventPartyTravel>;
        if (!values.beginLocation) {
            errors.beginLocation = t('Zadejte prosím místo');
        }
        if (!values.beginDate) {
            errors.beginDate = t('Zadejte prosím datum');
        }
        if (!values.beginTime) {
            errors.beginTime = t('Zadejte prosím čas');
        } else if (!isTime(values.beginTime)) {
            errors.beginTime = t('Neplatný čas');
        }

        if (!values.endLocation) {
            errors.endLocation = t('Zadejte prosím místo');
        }
        if (!values.endDate) {
            errors.endDate = t('Zadejte prosím datum');
        } else {
            if (values.beginDate && values.beginDate > values.endDate) {
                errors.endDate = t('Datum je před zahájením');
            }
        }

        if (!values.endTime) {
            errors.endTime = t('Zadejte prosím čas');
        } else if (!isTime(values.endTime)) {
            errors.endTime = t('Neplatný čas');
        } else {
            if (values.beginDate && values.beginTime
                && values.beginDate === values.endDate
                && isTimeAfter(values.beginTime, values.endTime)) {
                errors.endTime = t('Čas je před zahájením');
            }
        }

        if (!values.vehicle) {
            errors.vehicle = t('Zvolte prosím prostředek');
        } else {
            if (values.vehicle === JsonEventPartyTravelVehicleEnum.Car) {
                if (!values.plateNum || values.plateNum.length < 5) {
                    errors.plateNum = t('Zadejte prosím RZ');
                }

                if (!values.kmCnt) {
                    errors.kmCnt = t('Zadejte prosím km');
                } else if (!isNumeric(values.kmCnt, true) || values.kmCnt <= 1) {
                    errors.kmCnt = t('Zadejte prosím číslo');
                }
            } else {
                if (!values.compAmount) {
                    errors.compAmount = t('Zadejte prosím částku');

                } else if (!isNumeric(values.compAmount) || values.compAmount <= 1) {
                    errors.compAmount = t('Neplatná částka');

                } else if (!(values.files?.length && values.files.length > 0)) {
                    errors.compAmount = t('Nahrajte prosím doklad');
                }
            }
        }

        return errors;
    }, [t]);

    const actions = useCallback(
        ({values, setFieldValue, isSubmitting, setFormikState, handleSubmit, validateForm, setSubmitting}
             : FormikProps<JsonEventPartyTravel>, props: FormProps<JsonEventPartyTravel>) => {
            const keys: (keyof JsonEventPartyTravel)[] = ['beginLocation', 'beginDate', 'beginTime',
                'endLocation', 'endDate', 'endTime',
                'vehicle', 'plateNum', 'kmCnt', 'compAmount',
                'files'];

            let isChanged = false;
            keys.forEach((k) => {
                isChanged = isChanged || (!!original && original?.[k] !== values[k]);
            });
            return <>
                {isChanged && <Grid item sx={{flexGrow: 1}}>
					<Button variant="text" color={'info'} onClick={() => {
                        keys.forEach((k) => {
                            setFieldValue(k, original?.[k]);
                        });
                        setFormikState((s) => ({...s, errors: {}}));
                    }}>{t('Obnovit původní údaje')}</Button>
				</Grid>}
                {props.onCancel && <Grid item>
					<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
				</Grid>}
                {!values.eventPartyTravelId && <Grid item>
					<Button variant="contained" type="button" color={'success'} disabled={isSubmitting} onClick={() => {
                        validateForm(values).then((errors) => {
                            if (errors && Object.keys(errors).length > 0) {
                                setFormikState((s) => ({...s, submitCount: s.submitCount + 1}));
                            } else {
                                setFieldValue('eventPartyTravelId', FAKE_VALUE_AND, false);
                                handleSubmit();
                            }
                        });
                    }}>
                        {t('Uložit a předvyplnit cestu zpět')}
					</Button>
				</Grid>}
                <Grid item>
                    <Button variant="contained" type="submit" color={'success'} disabled={isSubmitting}>
                        {t('Uložit cestu')}
                    </Button>
                </Grid>
            </>
        }, [original, t]);

    return <FormModal title={t(props.title || 'Údaje o cestě')} {...props} maxWidth={'sm'}>
        <FormContainer {...props}
            actions={actions}
            onSave={(item) => {
                if (onSave) {
                    return onSave(item);
                }
            }}
            validate={validate} children={({values, setFieldValue, validateForm, setFormikState}) => {

            return <Grid item xs={12}>
                <Grid container spacing={2} className={'form-container'}>
                    <Grid item xs={12}>
                        <p>{t('Pro cesty automobilem zadejte prosím RZ a najeté kilometry. Pro cesty jinými prostředky nahrajte příslušný doklad (kopii lístku apod).')}</p>
                    </Grid>
                    <Grid item xs={5}>
                        <TextFormField name="beginLocation" label={t('Místo zahájení cesty')} type={'text'}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField name="beginDate" label={t('Datum zahájení')} type={'date'} maxlength={10}/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField name="beginTime" label={t('Čas zahájení')} placeholder={'HH:MM'} type={'text'} maxlength={5}/>
                    </Grid>

                    <Grid item xs={5}>
                        <TextFormField name="endLocation" label={t('Místo ukončení cesty')} type={'text'}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField name="endDate" label={t('Datum ukončení')} type={'date'} maxlength={10}/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField name="endTime" label={t('Čas ukončení')} placeholder={'HH:MM'} type={'text'} maxlength={5}/>
                    </Grid>

                    <Grid item xs={5}>
                        {/*<TextFormField name="vehicle" label={t('Dopravní prostředek')} type={'text'}/>*/}
                        <SelectFormField name="vehicle" label={t('Dopravní prostředek')} options={vehicleOptions}/>
                    </Grid>
                    {values.vehicle === JsonEventPartyTravelVehicleEnum.Car && <>
						<Grid item xs={4}>
							<TextFormField name="plateNum" label={t('RZ')} type={'text'} maxlength={20}/>
						</Grid>
						<Grid item xs={3}>
							<TextFormField name="kmCnt" label={t('Počet km')} type={'text'} maxlength={3}/>
						</Grid>
					</>}
                    {!!values.vehicle && values.vehicle !== JsonEventPartyTravelVehicleEnum.Car && <>
						<Grid item xs={4}>
							<TextFormField name="compAmount" label={t('Částka k proplacení')} type={'text'} maxlength={8}
								normalizeValue={normalizeMoney} formatValue={formatMoney}
							/>
						</Grid>
					</>}

                    <Grid item xs={12}>
                        <Attachments values={values} inviteId={inviteId} onFileUpload={onFileUpload && replyValues
                            ? (f) => onFileUpload(f, replyValues, true)
                            : undefined}/>
                        <input name={'files'} style={{display: 'none'}}/>
                    </Grid>
                </Grid>
            </Grid>
        }}/>
    </FormModal>;
}