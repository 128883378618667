/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonInvite,
    ApiResultOfJsonInviteFromJSON,
    ApiResultOfJsonInviteToJSON,
    ApiResultOfJsonInviteInfo,
    ApiResultOfJsonInviteInfoFromJSON,
    ApiResultOfJsonInviteInfoToJSON,
    ApiResultOfListOfJsonInviteInfo,
    ApiResultOfListOfJsonInviteInfoFromJSON,
    ApiResultOfListOfJsonInviteInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonInvite,
    JsonInviteFromJSON,
    JsonInviteToJSON,
} from '../models';

export interface GetInviteCountUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    partyIds?: Array<number>;
    inviteTypes?: Array<GetInviteCountUsingGETInviteTypesEnum>;
    processByIds?: Array<number>;
    groupIds?: Array<number>;
    extraLimit?: number;
    isPark?: boolean;
    companyIds?: Array<number>;
    tagIds?: Array<number>;
    relPartyId?: number;
    statuses?: Array<GetInviteCountUsingGETStatusesEnum>;
    inviteKinds?: Array<GetInviteCountUsingGETInviteKindsEnum>;
    isActive?: boolean;
    search?: string;
}

export interface GetInviteInfoUsingGETRequest {
    inviteId: number;
}

export interface GetInviteListUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    partyIds?: Array<number>;
    groupIds?: Array<number>;
    extraLimit?: number;
    isPark?: boolean;
    companyIds?: Array<number>;
    tagIds?: Array<number>;
    inviteTypes?: Array<GetInviteListUsingGETInviteTypesEnum>;
    processByIds?: Array<number>;
    relPartyId?: number;
    statuses?: Array<GetInviteListUsingGETStatusesEnum>;
    inviteKinds?: Array<GetInviteListUsingGETInviteKindsEnum>;
    isActive?: boolean;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetInviteUsingGETRequest {
    inviteId: number;
}

export interface SaveInviteUsingPOSTRequest {
    json: JsonInvite;
    testOnly?: boolean;
}

/**
 * 
 */
export class InviteControllerApi extends runtime.BaseAPI {

    /**
     * getInviteCount
     */
    async getInviteCountUsingGETRaw(requestParameters: GetInviteCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.inviteTypes) {
            queryParameters['inviteTypes'] = requestParameters.inviteTypes;
        }

        if (requestParameters.processByIds) {
            queryParameters['processByIds'] = requestParameters.processByIds;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.extraLimit !== undefined) {
            queryParameters['extraLimit'] = requestParameters.extraLimit;
        }

        if (requestParameters.isPark !== undefined) {
            queryParameters['isPark'] = requestParameters.isPark;
        }

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.relPartyId !== undefined) {
            queryParameters['relPartyId'] = requestParameters.relPartyId;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.inviteKinds) {
            queryParameters['inviteKinds'] = requestParameters.inviteKinds;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getInviteCount
     */
    async getInviteCountUsingGET(requestParameters: GetInviteCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getInviteCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInviteInfo
     */
    async getInviteInfoUsingGETRaw(requestParameters: GetInviteInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteInfo>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling getInviteInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite/info/{inviteId}`.replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteInfoFromJSON(jsonValue));
    }

    /**
     * getInviteInfo
     */
    async getInviteInfoUsingGET(requestParameters: GetInviteInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteInfo> {
        const response = await this.getInviteInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInviteList
     */
    async getInviteListUsingGETRaw(requestParameters: GetInviteListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonInviteInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.extraLimit !== undefined) {
            queryParameters['extraLimit'] = requestParameters.extraLimit;
        }

        if (requestParameters.isPark !== undefined) {
            queryParameters['isPark'] = requestParameters.isPark;
        }

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.inviteTypes) {
            queryParameters['inviteTypes'] = requestParameters.inviteTypes;
        }

        if (requestParameters.processByIds) {
            queryParameters['processByIds'] = requestParameters.processByIds;
        }

        if (requestParameters.relPartyId !== undefined) {
            queryParameters['relPartyId'] = requestParameters.relPartyId;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.inviteKinds) {
            queryParameters['inviteKinds'] = requestParameters.inviteKinds;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonInviteInfoFromJSON(jsonValue));
    }

    /**
     * getInviteList
     */
    async getInviteListUsingGET(requestParameters: GetInviteListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonInviteInfo> {
        const response = await this.getInviteListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInvite
     */
    async getInviteUsingGETRaw(requestParameters: GetInviteUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInvite>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling getInviteUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite/{inviteId}`.replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteFromJSON(jsonValue));
    }

    /**
     * getInvite
     */
    async getInviteUsingGET(requestParameters: GetInviteUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInvite> {
        const response = await this.getInviteUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveInvite
     */
    async saveInviteUsingPOSTRaw(requestParameters: SaveInviteUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInvite>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveInviteUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonInviteToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteFromJSON(jsonValue));
    }

    /**
     * saveInvite
     */
    async saveInviteUsingPOST(requestParameters: SaveInviteUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInvite> {
        const response = await this.saveInviteUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetInviteCountUsingGETInviteTypesEnum {
    Guest = 'GUEST',
    Organizer = 'ORGANIZER',
    Supplier = 'SUPPLIER',
    UpdateOrg = 'UPDATE_ORG',
    TravelOrg = 'TRAVEL_ORG',
    CompOrg = 'COMP_ORG'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteCountUsingGETStatusesEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteCountUsingGETInviteKindsEnum {
    Invite = 'INVITE',
    Update = 'UPDATE',
    Travel = 'TRAVEL',
    Comp = 'COMP'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteListUsingGETInviteTypesEnum {
    Guest = 'GUEST',
    Organizer = 'ORGANIZER',
    Supplier = 'SUPPLIER',
    UpdateOrg = 'UPDATE_ORG',
    TravelOrg = 'TRAVEL_ORG',
    CompOrg = 'COMP_ORG'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteListUsingGETStatusesEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteListUsingGETInviteKindsEnum {
    Invite = 'INVITE',
    Update = 'UPDATE',
    Travel = 'TRAVEL',
    Comp = 'COMP'
}
