import {Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import * as React from 'react';
import {PropsWithChildren} from 'react';
import {Close} from "@mui/icons-material";
import {Breakpoint} from "@mui/system";

interface Props {
    title: string | JSX.Element | undefined;
    onCancel?: () => void;
    maxWidth?: Breakpoint;
    dialogClassName?: string;
}

const FormModal = ({children, title, onCancel, maxWidth, dialogClassName}: PropsWithChildren<Props>) => {

    return (
        <>
            <Dialog open={true} maxWidth={maxWidth || 'sm'} fullWidth className={dialogClassName}>
                <DialogTitle>{title}
                    <IconButton
                        aria-label="close"
                        onClick={onCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.secondary.contrastText,
                        }}
                    >
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Dialog>
        </>
    );

}

export default FormModal;
