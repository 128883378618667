import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridItemAction, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {GetEventDayListUsingGETRequest, JsonEventDay, JsonEventDayInfo, JsonEventDayStatusEnum} from "../../generated-api";
import {dateToGui} from "../../helpers/date";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {selectEventDays} from "../../store/selectors";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {fetchEventDay, fetchEventDays, saveEventDay} from "../../store/eventDays";
import {Edit} from "@mui/icons-material";
import EventDayModal from "../../pages/EventDayModal";
import {useAppTranslation} from "../../services/i18n";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {fetchCodebooks} from "../../store/codebooks";

interface EventDaysGridFilter extends DataGridFilter, GetEventDayListUsingGETRequest {
}

interface EventDaysListGridState extends DataGridState<JsonEventDayInfo, EventDaysGridFilter> {
}

const eventDayCols: DataGridCol<JsonEventDayInfo>[] = [
    createCol('D', 'dayNo', 30),
    createCol('Datum', 'dayDate', 100, undefined, dateToGui),
    createCol('Areál', 'openFrom', 100, 'Areál otevřen od'),
    createCol('Program', 'description', 300, undefined, (v) => <div>{v && v.split("\n").map((line, i) => <span key={i}>{line}<br/></span>)}</div>),
    createCol('VIP', 'vipSold', '40C', 'Prodáno VIP'),
    createCol('VZ', 'voucherZ', '40C', 'Voucher Z'),
    createCol('VR', 'voucherR', '40C', 'Voucher R'),
];

const eventDaysDefaultState: EventDaysListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'dayDate',
    },
};

const EventDaysList = (props: { eventId: number }) => {
    const {eventId} = props;
    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const eventDays = useAppSelector<ItemsState<JsonEventDayInfo>>(selectEventDays);

    const [editEventDay, setEditEventDay] = useState<JsonEventDay | undefined>(undefined);

    const handleFetchEventDays = useCallback(() => {
        dispatch(fetchEventDays({eventId/*, statuses: [GetEventDayListUsingGETStatusesEnum.Active]*/}));
    }, [eventId, dispatch]);

    const handleEditEventDay = useCallback((eventDayId?: number) => {
        if (eventDayId) {
            dispatch(fetchEventDay(eventDayId)).then((res) => setEditEventDay(getApiResult(res)));
        } else {
            setEditEventDay({eventId});
        }
    }, [dispatch, eventId]);

    const handleSaveEventDay = useCallback(async (eventDay: JsonEventDay, isDelete?: boolean) => {
        const res = await dispatch(saveEventDay({...eventDay, status: isDelete ? JsonEventDayStatusEnum.Deleted : eventDay.status}));
        const item = getApiResult<JsonEventDay>(res);
        if (item) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Den {{dayDate}} ponechán beze změn',
                [ApiChangeType.UPDATED]: isDelete ? 'Den {{dayDate}} úspěšně odstraněn' : 'Den {{dayDate}} úspěšně upraven',
                [ApiChangeType.CREATED]: 'Den {{dayDate}} úspěšně vytvořen'
            }, t, dispatch);
            setEditEventDay(undefined);
            dispatch(fetchCodebooks());
            handleFetchEventDays();
        }

    }, [handleFetchEventDays, dispatch, t])

    const handleCancelEventDay = useCallback(() => {
        setEditEventDay(undefined);
    }, [])

    useEffect(() => {
        handleFetchEventDays();
    }, [handleFetchEventDays]);

    const actions = [
        {
            title: 'Upravit den akce',
            icon: <Edit/>,
            callback: (item) => handleEditEventDay(item.eventDayId)
        }
    ] as DataGridItemAction<JsonEventDayInfo>[];

    return <>
        <DataGrid
            cols={eventDayCols}
            defaultState={eventDaysDefaultState}
            itemsState={eventDays}
            mode={DataGridMode.CLIENT}
            actions={actions}
        />
        {editEventDay && <EventDayModal
			item={editEventDay}
			onCancel={handleCancelEventDay}
			onSave={handleSaveEventDay}
		/>}
    </>
}

export default EventDaysList;
