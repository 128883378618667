import {Alert, Snackbar} from '@mui/material';
import React, {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from '../../store';
import {FlashMessageWithId, removeMessage} from '../../store/localApp';
import {selectAppMessages} from '../../store/selectors';
import {useAppTranslation} from "../../services/i18n";

interface Props {

}

export const FlashMessages: React.FC<Props> = () => {
    const t = useAppTranslation();
    const messages = useAppSelector(selectAppMessages);
    const dispatch = useAppDispatch();
    const handleClose = useCallback((msg: FlashMessageWithId) => dispatch(removeMessage(msg)), [dispatch]);
    if (!(messages.length > 0)) {
        return null;
    }
    return <div className={'snackbar-container'}>
        {messages.slice(-3).map((msg) => {
            let title;
            if (msg.code === 'GENERAL_ERROR') {
                title = <><strong title={msg.action}>{t('Omlouváme se, ale služba "{{service}}" selhala, kód chyby', {service: msg.action})}:<br/></strong>{msg.title}</>
            } else if (msg.code === 'LOGIN_NEEDED') {
                title = <><strong title={msg.action}>Login</strong></>
            } else if (msg.code === 'UNAUTHORIZED') {
                title = <><strong title={msg.action}>{t('Omlouváme se, ale služba "{{service}}" není dostupná z důvodu nedostatečných oprávnění', {service: msg.action})}</strong></>
            } else {
        title = msg.title;
    }

        return <Snackbar key={msg.id} open autoHideDuration={msg.severity === 'success' || msg.severity === 'info' ? 6000 : undefined} onClose={(e, reason) => {
        if (reason === 'clickaway') {
            return null;
        }
        return handleClose(msg);
    }}>
        <Alert onClose={() => handleClose(msg)} severity={msg.severity} variant={'filled'}>
            {title}
        </Alert>
    </Snackbar>
        })}
    </div>;
}
    ;
