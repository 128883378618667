/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonFormat,
    ApiResultOfJsonFormatFromJSON,
    ApiResultOfJsonFormatToJSON,
    ApiResultOfJsonFormatInfo,
    ApiResultOfJsonFormatInfoFromJSON,
    ApiResultOfJsonFormatInfoToJSON,
    ApiResultOfListOfJsonFormatInfo,
    ApiResultOfListOfJsonFormatInfoFromJSON,
    ApiResultOfListOfJsonFormatInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonFormat,
    JsonFormatFromJSON,
    JsonFormatToJSON,
} from '../models';

export interface GetFormatCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetFormatInfoUsingGETRequest {
    formatId: number;
}

export interface GetFormatListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetFormatUsingGETRequest {
    formatId: number;
}

export interface SaveFormatUsingPOSTRequest {
    json: JsonFormat;
    testOnly?: boolean;
}

/**
 * 
 */
export class FormatControllerApi extends runtime.BaseAPI {

    /**
     * getFormatCount
     */
    async getFormatCountUsingGETRaw(requestParameters: GetFormatCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/format/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getFormatCount
     */
    async getFormatCountUsingGET(requestParameters: GetFormatCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getFormatCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFormatInfo
     */
    async getFormatInfoUsingGETRaw(requestParameters: GetFormatInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFormatInfo>> {
        if (requestParameters.formatId === null || requestParameters.formatId === undefined) {
            throw new runtime.RequiredError('formatId','Required parameter requestParameters.formatId was null or undefined when calling getFormatInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/format/info/{formatId}`.replace(`{${"formatId"}}`, encodeURIComponent(String(requestParameters.formatId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFormatInfoFromJSON(jsonValue));
    }

    /**
     * getFormatInfo
     */
    async getFormatInfoUsingGET(requestParameters: GetFormatInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFormatInfo> {
        const response = await this.getFormatInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFormatList
     */
    async getFormatListUsingGETRaw(requestParameters: GetFormatListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonFormatInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/format/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonFormatInfoFromJSON(jsonValue));
    }

    /**
     * getFormatList
     */
    async getFormatListUsingGET(requestParameters: GetFormatListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonFormatInfo> {
        const response = await this.getFormatListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFormat
     */
    async getFormatUsingGETRaw(requestParameters: GetFormatUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFormat>> {
        if (requestParameters.formatId === null || requestParameters.formatId === undefined) {
            throw new runtime.RequiredError('formatId','Required parameter requestParameters.formatId was null or undefined when calling getFormatUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/format/{formatId}`.replace(`{${"formatId"}}`, encodeURIComponent(String(requestParameters.formatId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFormatFromJSON(jsonValue));
    }

    /**
     * getFormat
     */
    async getFormatUsingGET(requestParameters: GetFormatUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFormat> {
        const response = await this.getFormatUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveFormat
     */
    async saveFormatUsingPOSTRaw(requestParameters: SaveFormatUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFormat>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveFormatUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/format`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonFormatToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFormatFromJSON(jsonValue));
    }

    /**
     * saveFormat
     */
    async saveFormatUsingPOST(requestParameters: SaveFormatUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFormat> {
        const response = await this.saveFormatUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
