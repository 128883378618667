import {Box, Tooltip} from "@mui/material";
import {getLink} from "../../helpers/files";
import placeholder from "../../assets/images/placeholder.png";
import {useAppTranslation} from "../../services/i18n";
import {SxProps} from "@mui/system";
import {CheckRounded, WarningAmberRounded} from "@mui/icons-material";
import * as React from "react";

type Props = {
    photoGuid?: string
    maxHeight?: number,
    maxWidth?: number
    alt?: string,
    sx?: SxProps,
    changed?: boolean
}

type TooltipProps = {
    photoGuid?: string,
    icon?: JSX.Element,
    emptyIcon?: JSX.Element,
    changed?: boolean
}

export const PartyPhotoTooltip = (props: TooltipProps) => {
    const {photoGuid, icon, emptyIcon, changed} = props;
    const t = useAppTranslation();
    return photoGuid
        ? <Tooltip title={<PartyPhoto photoGuid={photoGuid} changed={changed} maxWidth={180} maxHeight={180}/>} placement={'left-end'} enterDelay={500} enterNextDelay={500}>{icon || <CheckRounded/>}</Tooltip>
        : <div title={t('Chybí fotografie')}>{emptyIcon || <WarningAmberRounded color={'error'}/>}</div>;
}

const PartyPhoto = (props: Props) => {
    const t = useAppTranslation();

    return <Box
        component="img"
        loading="lazy"
        sx={{...props.sx,
            maxHeight: props.maxHeight || 'calc(100% - 10px)',
            maxWidth: props.maxWidth || '100%',
            background: props.changed ? 'var(--color-warning)' : undefined}}
        alt={props.alt || t('Fotografie')}
        src={props.photoGuid ? getLink(props.photoGuid) : placeholder}
    />;
}

export default PartyPhoto;