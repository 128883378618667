import {createAsyncThunk,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
    ApiResultOfJsonFile,
    ApiResultOfJsonFileInfo,
    FileControllerApi,
    GetFileCountUsingGETRequest,
    GetFileListUsingGETRequest,
    JsonFile,
    SaveAttachmentFileUsingPOSTRequest,
    SavePhotoFileUsingPOSTRequest
} from '../generated-api';

const filesApi = new FileControllerApi(API_CONFIG);

export const fetchFiles = createAsyncThunk('files/list', async (filter: GetFileListUsingGETRequest, thunkApi) => {
    return {...await filesApi.getFileListUsingGET(filter), filter};
});

export const fetchFilesCount = createAsyncThunk('files/count', async (params: GetFileCountUsingGETRequest = {}) => {
    return await filesApi.getFileCountUsingGET(params);
});

export const fetchFileInfo = createAsyncThunk('file/info', async (fileId: number): Promise<ApiResultOfJsonFileInfo> => {
    return await filesApi.getFileInfoUsingGET({fileId});
});

export const fetchFile = createAsyncThunk('file/fetch', async (fileId: number): Promise<ApiResultOfJsonFile> => {
    return await filesApi.getFileUsingGET({fileId});
});

export const saveFile = createAsyncThunk('file/save', async (file: JsonFile): Promise<ApiResultOfJsonFile> => {
    return await filesApi.saveFileUsingPOST({json: file});
});

export const savePhoto = createAsyncThunk('file/savePhoto', async (request: SavePhotoFileUsingPOSTRequest) => {
    return await filesApi.savePhotoFileUsingPOST(request);
});

export const saveAttachment = createAsyncThunk('file/saveAttachment', async (request: SaveAttachmentFileUsingPOSTRequest) => {
    return await filesApi.saveAttachmentFileUsingPOST(request);
});

// export const filesSlice = createSlice({
//     name: 'files',
//     initialState: (): ItemsState<JsonFileInfo> => createDefaultListState(),
//     reducers: {},
//     extraReducers: builder => {
//         createDefaultListReducer(builder, fetchFiles, fetchFilesCount);
//     }
// });
//
// export const filesReducer = filesSlice.reducer;
