import {Alert, Button, CircularProgress, Grid} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {createOption, FormProps, OptionValue} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {PartyMassActionProps, PartyMassActionValues, partyName, sexOptions} from "../../model/party";
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {
    JsonArticleInfo,
    JsonArticleSexEnum,
    JsonArticleTypeInfo,
    JsonEventParty,
    JsonEventPartyInfo,
    JsonEventPartyMassActionRequest,
    JsonEventPartyMassActionRequestActionEnum
} from "../../generated-api";
import CodebookValue from "../CodebookValue";
import {dateToGuiAs} from "../../helpers/date";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {ButtonGroupPlain} from "../form/ButtonGroupField";
import {EventPartyMissingInfo} from "../../pages/EventPartiesPage";
import {selectCodebooks} from "../../store/selectors";
import {getApiResult} from "../../helpers/api";
import {fetchArticles} from "../../store/articles";
import {fetchArticleTypes} from "../../store/articleTypes";
import {articleSize} from "../../helpers/format";

interface Props extends PartyMassActionProps<PartyMassActionValues<any>, any> {

}

interface LocalFilter extends DataGridFilter {
    action?: JsonEventPartyMassActionRequestActionEnum;
    eventId?: number;
}

interface EventPartiesGridState extends DataGridState<JsonEventPartyInfo, LocalFilter> {
}

type ShowMode = 'valid' | 'invalid' | 'all';

const showModeOptions: OptionValue[] = [
    createOption('valid', 'Ukázat jen ty v pořádku', 'Zobrazit jen osoby v pořádku', undefined, 'warning'),
    createOption('invalid', 'Chyby', 'Zobrazit jen osoby s chybou', undefined, 'warning'),
    createOption('all', 'Vše', 'Zobrazit vše', undefined, 'warning'),
];

const defaultState: EventPartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: undefined,
        action: undefined,
        eventId: undefined
    },
};

type RowStatusType = 'valid' | 'missingBirthDate' | 'missingBankAccount' | 'missingAddress' | 'invalidGroup';

const texts: { [key in JsonEventPartyMassActionRequestActionEnum]?: [string, string, string] } = {
    [JsonEventPartyMassActionRequestActionEnum.PrintContract]: [
        'Vytisknout smlouvy do souboru',
        'Soubor bude obsahovat smlouvy pro {{count}} osob',
        'Není nikdo, pro koho by bylo možné tisknout smlouvu'],
};

const PartyPrintContractForm = (props: Props) => {

    const {massAction, onSave} = props;
    const {eventParties} = massAction;
    const {action, eventId} = massAction.values;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector(selectCodebooks);
    const groupOrg = codebooks['groupOrg'];
    const [articleTypes, setArticleTypes] = useState<JsonArticleTypeInfo[] | undefined>(undefined);
    const [articles, setArticles] = useState<JsonArticleInfo[] | undefined>(undefined);
    const [showMode, setShowMode] = useState<ShowMode>('valid');


    const getRowStatus = useCallback((item: JsonEventPartyInfo): RowStatusType => {
        if (!item.groupId || !groupOrg || !groupOrg.find((p) => p.value === '' + item.groupId)) {
            return 'invalidGroup';
        }
        if (!item.birthDate) {
            return 'missingBirthDate';
        }
        if (!item.permAddress) {
            return 'missingAddress';
        }
        if (!item.bankAccount) {
            return 'missingBankAccount';
        }

        return 'valid';
    }, [groupOrg]);

    const getRowClassNames = useCallback((item: JsonEventPartyInfo): string[] | undefined => {
        switch (getRowStatus(item)) {
            case 'missingBirthDate':
            case 'missingAddress':
            case 'missingBankAccount':
                return ['data-grid-warning-row'];
            case 'invalidGroup':
                return ['data-grid-invalid-row'];
        }
        return undefined;
    }, [getRowStatus]);

    const filterValid = useCallback((action: JsonEventPartyMassActionRequestActionEnum | undefined, eventParties: JsonEventPartyInfo[], showMode: ShowMode) => {
        let missingBirthDate = 0, missingBankAccount = 0, missingAddress = 0, invalidGroup = 0;
        const filtered = eventParties.filter((item) => {
            let valid = false;
            switch (getRowStatus(item)) {
                case 'missingBirthDate':
                    missingBirthDate++;
                    valid = true;
                    break;
                case 'missingAddress':
                    missingAddress++;
                    valid = true;
                    break;
                case 'missingBankAccount':
                    missingBankAccount++;
                    valid = true;
                    break;
                case 'invalidGroup':
                    invalidGroup++;
                    break;
                case 'valid':
                default:
                    valid = true;
            }

            return showMode === 'all' || (valid && showMode === 'valid') || (!valid && showMode === 'invalid');
        });
        return {filtered, missingBirthDate, missingBankAccount, missingAddress, invalidGroup};
    }, [getRowStatus]);

    const handleFetchEvent = useCallback(async (eventId: number) => {
        setArticleTypes(getApiResult<JsonArticleTypeInfo[]>(await dispatch(fetchArticleTypes({eventId}))));
        setArticles(getApiResult<JsonArticleInfo[]>(await dispatch(fetchArticles({eventId}))));
    }, [dispatch]);

    const validate = useCallback((values: JsonEventPartyMassActionRequest) => {
        let errors = {} as FormikErrors<JsonEventPartyMassActionRequest>;
        if (!values.eventId) {
            errors.eventId = t('Vyberte událost');
        }
        return errors;
    }, [t]);

    const actions = useCallback(({values, isSubmitting}: FormikProps<JsonEventPartyMassActionRequest>, props: FormProps<JsonEventPartyMassActionRequest>) => {
        const {items} = values;
        const itemsCount = items?.length || 0;
        if (!action) {
            return null;
        }
        const textValues = texts[action];
        if (!textValues) {
            return null; // should never happen
        }

        return <Grid item xs={12} sx={{margin: '-5px 0 -10px 0'}}>
            <Grid container columnSpacing={2} justifyContent="flex-end">
                <Grid item sx={{flexGrow: 1}}>
                    {itemsCount > 0
                        ? <Alert severity={'info'} className={'event-action-errors'}>{t(textValues[1], {count: itemsCount})}</Alert>
                        : <Alert severity={'warning'} className={'event-action-errors'}>{t(textValues[2])}</Alert>}
                </Grid>
                {props.onCancel && <Grid item>
					<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
				</Grid>}
                <Grid item>
                    <Button variant="contained" type="submit" color={'success'} disabled={isSubmitting || !itemsCount}>
                        {isSubmitting ? <CircularProgress size={20}/> : null}
                        <span>{t(textValues[0])}</span>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    }, [action, t]);

    const {filtered, missingBirthDate, missingBankAccount, missingAddress, invalidGroup} = useMemo(() => {
        return filterValid(action, eventParties, showMode);
    }, [action, eventParties, showMode, filterValid])

    const cols = useMemo(() => {
        const cols: DataGridCol<JsonEventPartyInfo, LocalFilter>[] = [
            createCol('Jméno', 'fullName', 150, undefined, (v, item) => <strong>{partyName(item)}</strong>),
            createCol('P', 'sex', 15, 'Pohlaví', (v) => v ? <small><CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/></small> : null),
            createCol('R', 'birthDate', 35, 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null),
            {
                title: 'Ú',
                tooltip: 'Chybějící údaje',
                size: 20,
                align: "center",
                col: 'email',
                renderValue: (v, item) => {
                    return <EventPartyMissingInfo item={item}/>
                }
            },
            createCol('Email', 'email', 150, undefined, (v) => <small>{v}</small>),
            createCol('Skupina', 'groupId', 100, 'Pracovní skupina', (v) => v ? <CodebookValue value={v} name={'group'}/> : null)];
        return cols;
    }, []);

    const grid = useMemo(() => {
        const itemsState = {
            loading: false,
            count: 0,
            items: filtered,
            filter: {action, eventId}
        } as ItemsState<JsonEventPartyInfo, LocalFilter>;

        const articleMatrix = {} as {
            [key in number]: {
                [key in JsonArticleSexEnum]: {
                    [key in string]?: number
                }
            }
        };

        articleTypes?.forEach((at) => {
            if (!at.articleTypeId) {
                return;
            }
            const m = {} as { [key in JsonArticleSexEnum]: { [key in string]?: number } }
            Object.values(JsonArticleSexEnum).forEach((sex) => {
                at.variants?.sizes?.[sex]?.forEach((size) => {
                    if (!at.articleTypeId || !at.variants?.sizes || !at.variants.sizes[sex] || !(at.variants.sizes[sex].indexOf(size) >= 0)) {
                        return;
                    }
                    if (!m[sex]) {
                        m[sex] = {};
                    }
                    if (!m[sex][size]) {
                        m[sex][size] = 0;
                    }
                })
            })
            articleMatrix[at.articleTypeId] = m;
        })
        filtered.forEach((ep) => {
            ep.eventArticleDetails?.filter((epa) => !!epa.prefArticleId && !!epa.prefCnt).forEach((epa) => {
                const a = articles?.find((a) => a.articleTypeId === epa.articleTypeId && a.articleId === epa.prefArticleId);
                if (!epa.articleTypeId || !a || !a.sex || !a.size) {
                    return;
                }
                const m = articleMatrix[epa.articleTypeId]?.[a.sex];
                if (m?.[a.size] !== undefined && !!epa.prefCnt) {
                    (m[a.size] as number) += epa.prefCnt;
                }
            })
        });

        const totalCols = articleTypes?.reduce((a, b) => {
            return a + (!!b.variants?.sizes?.[JsonArticleSexEnum.U] ? 1 : 2);
        }, 0);

        return <>
            {!!articleTypes && <Grid container columns={totalCols} columnSpacing={1}>
                {articleTypes?.map((at) => {
                    const items: JSX.Element[] = [];
                    Object.values(JsonArticleSexEnum).forEach((sex) => {
                        const sexItems: JSX.Element[] = [];
                        const sexTitle = sexOptions.find((o) => o.value === sex)?.label || sex;
                        let sexTotal = 0;

                        const sizes = articleMatrix?.[at.articleTypeId || 0]?.[sex];
                        Object.keys(sizes || []).forEach((size) => {
                            if (!at.articleTypeId || !articleMatrix[at.articleTypeId]) {
                                return;
                            }
                            const count = articleMatrix[at.articleTypeId]?.[sex]?.[size];
                            if (!!count) {
                                sexTotal += count;
                                sexItems.push(<Grid key={size} container columns={6}>
                                    <Grid item xs={5}>{sexTitle} {articleSize(sex, size)}</Grid>
                                    <Grid item xs={1} style={{textAlign: 'right'}}><strong>{count}</strong></Grid>
                                </Grid>)
                            }
                        });
                        if (sexItems.length) {
                            items.push(<Grid key={sex} item xs={1}>
                                {sexItems}
                                <Grid container columns={6} sx={{color: 'var(--gray)'}}>
                                    <Grid item xs={5} title={t('Celkem')}>Σ</Grid>
                                    <Grid item xs={1} style={{textAlign: 'right'}}><strong>{sexTotal}</strong></Grid>
                                </Grid>
                            </Grid>)
                        }
                    });
                    if (!items.length) {
                        return null;
                    }
                    return <Grid key={at.articleTypeId} item xs={Math.min(2, items.length)}>
                        <h4>{at.title}</h4>
                        <Grid container columns={Math.max(1, items.length)} columnSpacing={2}>
                            {items}
                        </Grid>
                    </Grid>;
                })}
			</Grid>}
            {(invalidGroup > 0) && <Grid container style={{padding: '5px 0 10px 0', alignItems: 'center'}}><Grid item xs={8}>
				<Alert severity={'error'} className={'event-action-errors'}>
                    {t('Pro některé osoby není možné smlouvu vytisknout ({{skipped}}):', {skipped: invalidGroup})}
                    {invalidGroup > 0 && <span>{t('dle skupiny není organizátor ({{invalidGroup}})', {invalidGroup})}</span>}
				</Alert>
			</Grid><Grid item xs={4} sx={{textAlign: 'right'}}>
				<ButtonGroupPlain name={'showMode'} options={showModeOptions} currentValue={showMode} onChange={(v) => {
                    setShowMode(v || showMode);
                }}/>
			</Grid></Grid>}
            {(missingBirthDate > 0 || missingBankAccount > 0 || missingAddress > 0) && <Grid container style={{padding: '5px 0 10px 0', alignItems: 'center'}}><Grid item xs={12}>
				<Alert severity={'warning'} className={'event-action-errors'}>
                    {t('Některé osoby nemají vyplněné všechny údaje, ve smlouvě bude proto vynechané místo na doplnění ({{incomplete}}):',
                        {incomplete: missingBirthDate + missingBankAccount + missingAddress})}
                    {missingBirthDate > 0 && <span>{t('nemají datum narození ({{missingBirthDate}})', {missingBirthDate})}</span>}
                    {missingBankAccount > 0 && <span>{t('nemají bankovní účet ({{missingBankAccount}})', {missingBankAccount})}</span>}
                    {missingAddress > 0 && <span>{t('nemají adresu ({{missingAddress}})', {missingAddress})}</span>}
				</Alert>
			</Grid></Grid>}
            <DataGrid
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                mode={DataGridMode.CLIENT}
                tableProps={{
                    minHeight: '100px',
                    maxHeight: "clamp(100px, calc(100vh - 400px), 400px)"
                }}
                emptyListMessage={t('Není možné tisknout žádné smlouvy')}
                getRowClassNames={getRowClassNames}
            />
        </>;
    }, [filtered, missingBirthDate, missingBankAccount, missingAddress, invalidGroup,
        articles, articleTypes, action, cols, eventId, showMode, getRowClassNames, t]);

    const children = useCallback(() => {
        return <>
            <Grid item xs={12}>
                {grid}
            </Grid>
        </>;
    }, [grid]);

    const handleSave = useCallback(async (values: JsonEventPartyMassActionRequest) => {
        if (onSave && massAction) {
            await onSave({...massAction, values: values})
        }
    }, [massAction, onSave]);

    const initialValues = useMemo(() => {
        return {
            ...massAction.values,
            items: filtered as JsonEventParty[] | undefined,
        };
    }, [massAction, filtered]);

    useEffect(() => {
        if (eventId) {
            handleFetchEvent(eventId).then();
        }
    }, [eventId, handleFetchEvent]);

    return <FormContainer
        item={initialValues}
        validate={validate}
        actions={actions}
        children={children}
        onCancel={props.onCancel}
        onSave={handleSave}
    />;
}

export default PartyPrintContractForm;
