/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonAuthUserInfo,
    ApiResultOfJsonAuthUserInfoFromJSON,
    ApiResultOfJsonAuthUserInfoToJSON,
    ApiResultOfstring,
    ApiResultOfstringFromJSON,
    ApiResultOfstringToJSON,
    JsonAuthRequest,
    JsonAuthRequestFromJSON,
    JsonAuthRequestToJSON,
} from '../models';

export interface ChangePasswordUsingPOSTRequest {
    auth: JsonAuthRequest;
}

export interface ForgottenPasswordUsingPOSTRequest {
    auth: JsonAuthRequest;
}

export interface GetInfoUsingGETRequest {
    cookie?: string;
}

export interface LoginUsingPOSTRequest {
    auth: JsonAuthRequest;
}

export interface LogoutUsingGETRequest {
    cookie?: string;
}

/**
 * 
 */
export class AuthControllerApi extends runtime.BaseAPI {

    /**
     * changePassword
     */
    async changePasswordUsingPOSTRaw(requestParameters: ChangePasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling changePasswordUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonAuthRequestToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * changePassword
     */
    async changePasswordUsingPOST(requestParameters: ChangePasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.changePasswordUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * forgottenPassword
     */
    async forgottenPasswordUsingPOSTRaw(requestParameters: ForgottenPasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling forgottenPasswordUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/forgotten-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonAuthRequestToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * forgottenPassword
     */
    async forgottenPasswordUsingPOST(requestParameters: ForgottenPasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.forgottenPasswordUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInfo
     */
    async getInfoUsingGETRaw(requestParameters: GetInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAuthUserInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.cookie as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAuthUserInfoFromJSON(jsonValue));
    }

    /**
     * getInfo
     */
    async getInfoUsingGET(requestParameters: GetInfoUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfJsonAuthUserInfo> {
        const response = await this.getInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * login
     */
    async loginUsingPOSTRaw(requestParameters: LoginUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAuthUserInfo>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling loginUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonAuthRequestToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAuthUserInfoFromJSON(jsonValue));
    }

    /**
     * login
     */
    async loginUsingPOST(requestParameters: LoginUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonAuthUserInfo> {
        const response = await this.loginUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * logout
     */
    async logoutUsingGETRaw(requestParameters: LogoutUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.cookie as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * logout
     */
    async logoutUsingGET(requestParameters: LogoutUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.logoutUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
