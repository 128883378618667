import {FormControl, FormHelperText, MenuItem, Select} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {CodebookOptionsProps, createOptions, FAKE_VALUE_EMPTY, FAKE_VALUE_RESET, FormFieldProps, getOption, OptionValue} from '../../model/form';
import {useAppSelector} from "../../store";
import {selectCodebooks} from "../../store/selectors";
import * as React from "react";
import {useCallback, useMemo} from "react";

interface Props extends FormFieldProps {
    options?: OptionValue[],
    codebookProps?: CodebookOptionsProps,
    placeholder?: string;
    clearable?: boolean;
    useColors?: boolean;
}

export const SimpleSelectFormField = (props: Props) => {

    const {name, label, options, codebookProps, onChange, placeholder, useColors} = props;

    const codebooks = useAppSelector(selectCodebooks);
    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();
    const showError = !!meta.error && (meta.touched || submitCount > 0);
    const isCodebookPending = codebookProps && codebooks[codebookProps.codebookName] === undefined;
    // const hasEmpty = !!codebookProps?.addEmpty;
    // const hasReset = !!codebookProps?.addReset;

    const selectOptions = useMemo(() => {
        return codebookProps && !isCodebookPending ? createOptions(codebookProps, codebooks) : (options || []);
    }, [isCodebookPending, codebookProps, codebooks, options]);

    const menuItems = useMemo(() => {
        return selectOptions.map((o, i) => {
            return <MenuItem key={i} value={o.value as string} title={o.tooltip}>{o.label}</MenuItem>;
        });
    }, [selectOptions]);

    const renderValue = useCallback((v: any) => {
        if (v || v === 0) {
            const o = selectOptions.find((o) => o.value === v);
            return o?.label || v;
        }
        return placeholder || label || '';

    }, [selectOptions, label, placeholder]);

    const getColorClassName = useCallback((value: any) => {
        const o = getOption(value, selectOptions, codebookProps?.scope);
        if (o && useColors) {
            if (o.value === FAKE_VALUE_EMPTY) {
                return 'select-value-empty';
            } else if (o.value === FAKE_VALUE_RESET) {
                return 'select-value-reset';
            } else {
                return 'select-value-update';
            }
        }
        return o ? undefined : 'select-value-none';

    }, [selectOptions, codebookProps?.scope, useColors]);

    if (isCodebookPending) {
        return <span>...</span>;
    }

    const currentValue = (field.value || field.value === 0) ? field.value : '';
    return <FormControl size="small" fullWidth error={showError}>
        <Select
            label={label}
            displayEmpty={true}
            placeholder={placeholder}
            renderValue={renderValue}
            value={currentValue}
            inputProps={{name}}
            onChange={(e) => {
                const v = e.target.value;
                helpers.setValue(v, true);
                if (onChange) {
                    onChange(v);
                }
            }}
            className={getColorClassName(currentValue)}>
            {menuItems}
        </Select>
        {showError && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
}
