/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonFloorItem
 */
export interface JsonFloorItem {
    /**
     * 
     * @type {number}
     * @memberof JsonFloorItem
     */
    a?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorItem
     */
    s?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFloorItem
     */
    shapeCode: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFloorItem
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorItem
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorItem
     */
    y: number;
}

export function JsonFloorItemFromJSON(json: any): JsonFloorItem {
    return JsonFloorItemFromJSONTyped(json, false);
}

export function JsonFloorItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFloorItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'a': !exists(json, 'a') ? undefined : json['a'],
        'id': json['id'],
        's': !exists(json, 's') ? undefined : json['s'],
        'shapeCode': json['shapeCode'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'x': json['x'],
        'y': json['y'],
    };
}

export function JsonFloorItemToJSON(value?: JsonFloorItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'a': value.a,
        'id': value.id,
        's': value.s,
        'shapeCode': value.shapeCode,
        'text': value.text,
        'x': value.x,
        'y': value.y,
    };
}

