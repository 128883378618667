import FormModal from "../components/form/FormModal";
import {useAppTranslation} from "../services/i18n";
import CompanyForm, {CompanyFormProps} from "../components/company/CompanyForm";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import {fetchCompany, saveCompany} from "../store/companies";
import {useAppDispatch} from "../store";
import {JsonCompany} from "../generated-api";
import {fetchCodebooks} from "../store/codebooks";

interface CompanyModalProps extends CompanyFormProps {
}

const CompanyModal = (props: CompanyModalProps) => {

    const {item, onSave} = props;
    const {companyId} = item;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [editCompany, setEditCompany] = useState<JsonCompany | undefined>(undefined);

    const handleEditCompany = useCallback(() => {
        if (companyId) {
            dispatch(fetchCompany(companyId)).then((res) => setEditCompany(getApiResult(res)));
        } else {
            setEditCompany({companyId});
        }
    }, [companyId, dispatch]);

    const handleSaveCompany = useCallback(async (company: JsonCompany, isDelete?: boolean) => {
        const res = await dispatch(saveCompany({...company}));
        const item = getApiResult<JsonCompany>(res);
        if (item) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: ['Firma {{title}} ponechána beze změn', () => item.title],
                [ApiChangeType.UPDATED]: [isDelete
                    ? 'Firma {{title}} úspěšně odstraněna'
                    : 'Firma {{title}} úspěšně upravena', () => item.title],
                [ApiChangeType.CREATED]: ['Firma {{title}} úspěšně vytvořena', () => item.title]
            }, t, dispatch);

            dispatch(fetchCodebooks());

            if (onSave) {
                onSave(item);
            }
        }

    }, [onSave, dispatch, t])

    useEffect(() => {
        handleEditCompany();
    }, [handleEditCompany])

    return (
        <FormModal title={companyId ? t('Úprava firmy') : t('Nová firma')} {...props} maxWidth={'xs'}>
            {!!editCompany && <CompanyForm {...props} item={editCompany} onSave={handleSaveCompany}></CompanyForm>}
        </FormModal>
    );
}

export default CompanyModal;
