/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFloorShapeText,
    JsonFloorShapeTextFromJSON,
    JsonFloorShapeTextFromJSONTyped,
    JsonFloorShapeTextToJSON,
} from './JsonFloorShapeText';

/**
 * 
 * @export
 * @interface JsonFloorShape
 */
export interface JsonFloorShape {
    /**
     * 
     * @type {number}
     * @memberof JsonFloorShape
     */
    capacity?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFloorShape
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFloorShape
     */
    shapeCode: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFloorShape
     */
    shapeType: JsonFloorShapeShapeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonFloorShape
     */
    svg: string;
    /**
     * 
     * @type {JsonFloorShapeText}
     * @memberof JsonFloorShape
     */
    text?: JsonFloorShapeText;
}

/**
* @export
* @enum {string}
*/
export enum JsonFloorShapeShapeTypeEnum {
    Table = 'TABLE',
    Custom = 'CUSTOM'
}

export function JsonFloorShapeFromJSON(json: any): JsonFloorShape {
    return JsonFloorShapeFromJSONTyped(json, false);
}

export function JsonFloorShapeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFloorShape {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'id': json['id'],
        'shapeCode': json['shapeCode'],
        'shapeType': json['shapeType'],
        'svg': json['svg'],
        'text': !exists(json, 'text') ? undefined : JsonFloorShapeTextFromJSON(json['text']),
    };
}

export function JsonFloorShapeToJSON(value?: JsonFloorShape | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'capacity': value.capacity,
        'id': value.id,
        'shapeCode': value.shapeCode,
        'shapeType': value.shapeType,
        'svg': value.svg,
        'text': JsonFloorShapeTextToJSON(value.text),
    };
}

