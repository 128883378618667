import {JsonFile, JsonInviteReply, JsonInviteReplyExtraSexEnum, JsonPartyInfoSexEnum} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import {useFormikContext} from "formik";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {Alert, Box, Button, Card, CardContent, FormHelperText, Grid} from "@mui/material";
import {
    AccessTimeRounded,
    DeleteForeverRounded,
    DeleteRounded,
    EditRounded,
    GridViewRounded,
    KeyboardDoubleArrowRightRounded,
    PlusOneRounded,
    SortByAlphaRounded,
    ViewHeadlineRounded
} from "@mui/icons-material";
import RsvpInvitePartyBox from "./RsvpInvitePartyBox";
import RsvpInviteExtraImportForm, {JsonInviteReplyExtraWithResult} from "./RsvpInviteExtraImportForm";
import {FAKE_VALUE_EMPTY} from "../../model/form";
import {localCompare} from "../DataGrid";
import CodebookValue from "../CodebookValue";
import PartyPhoto from "../party/PartyPhoto";
import {ModalProps, useModal} from "../../services/modal";
import {isNameEqual, partyName} from "../../model/party";
import RsvpInviteExtraMultiForm from "./RsvpInviteExtraMultiForm";

type RsvpInviteSupplierPartiesProps = {
    token: string,
    eventId: number,
    values: JsonInviteReply,
    onFileUpload: (file: File, replyValues: JsonInviteReply) => Promise<JsonFile | undefined>,
    onReplySave: (values: JsonInviteReply) => void,
}

const RsvpInviteSupplierParties = (props: RsvpInviteSupplierPartiesProps) => {
    const {values, token, eventId, onFileUpload, onReplySave} = props;
    const {replyData} = values;

    const t = useAppTranslation();
    const modal = useModal();
    const {setFieldValue, errors} = useFormikContext<JsonInviteReply>();
    const [editExtra, setEditExtra] = useState<JsonInviteReplyExtraWithResult | undefined>(undefined);
    const [isEditMulti, setIsEditMulti] = useState<boolean>(false);
    const [editMulti, setEditMulti] = useState<string[] | undefined>(undefined);
    const [nextEditMulti, setNextEditMulti] = useState<string[] | undefined>(undefined);
    const [sortBy, setSortBy] = useState<'time' | 'name'>('time');
    const [displayAs, setDisplayAs] = useState<'grid' | 'table'>('grid');

    const onEdit = useCallback((extra: JsonInviteReplyExtraWithResult) => {
        const ee: JsonInviteReplyExtraWithResult = {...extra}
        if (!!extra.partyId) {
            if (extra.partyId !== FAKE_VALUE_EMPTY) {
                ee.resultParty = {
                    firstName: extra.firstName,
                    lastName: extra.lastName,
                    sex: extra.sex === undefined ? undefined : JsonPartyInfoSexEnum[extra.sex],
                    photoGuid: extra.photoGuid,
                    partyId: extra.partyId
                }
            } else {
                ee.resultParty = {
                    partyId: extra.partyId
                }
            }
        }
        setEditExtra(ee);
    }, [setEditExtra]);

    const onDelete = useCallback(async (extra: JsonInviteReplyExtraWithResult) => {
        const result = await modal.confirm({
            title: t('Potvrzení odstranění osoby'),
            message: <Alert severity={'error'}>
                {t('Skutečně trvale odstranit osobu {{title}} z formuláře?', {title: partyName(extra)})}
                &nbsp;<strong>{t('Tato akce je nevratná!')}</strong>
            </Alert>,
            cancelText: 'Zpět',
            confirmColor: 'error',
            confirmText: 'Trvale odstranit',
            confirmIcon: <DeleteForeverRounded/>,
        } as ModalProps);
        if (result !== 'CONFIRM') {
            return;
        }
        setFieldValue('replyData.extras', replyData?.extras?.filter((e) => e.extraId !== extra.extraId));
    }, [replyData?.extras, setFieldValue, modal, t]);

    const items: JSX.Element[] = useMemo(() => {
        const items: JSX.Element[] = [];
        const extras = replyData?.extras ? [...replyData.extras] : [];
        if (sortBy === 'name') {
            extras.sort((a, b) => localCompare(a.lastName + ' ' + a.firstName, b.lastName + ' ' + b.firstName))
        }

        extras.forEach((extra, i) => {
            const error = (errors.replyData as any)?.extras?.[i];
            if (displayAs === 'table') {
                items.push(<Grid key={i} item xs={12} style={{paddingTop: 0}}>
                    <Box sx={{padding: '10px 5px', backgroundColor: i % 2 ? undefined : 'var(--ultra-light-gray)'}}>
                        <Grid container columnSpacing={2}>
                            <Grid item md={1} xs={2} className={'rsvp-extra-match-box-photo'} sx={{marginTop: '-5px'}}>
                                <PartyPhoto photoGuid={extra.photoGuid} maxHeight={35}/>
                            </Grid>
                            <Grid item md={4} xs={8}>
                                <strong>{extra.lastName} {extra.firstName}</strong>
                                <input name={'replyData.extras.' + i} style={{display: 'none'}}/>
                            </Grid>
                            <Grid item md={1} xs={2}>
                                <CodebookValue value={extra.sex} name={'sex'} formatValue={(v) => v[0]}/>
                            </Grid>
                            <Grid item md={1} xs={2}>
                            </Grid>
                            <Grid item md={3} xs={6}>{!!extra.partyId && <>
							<span style={{color: extra.partyId === FAKE_VALUE_EMPTY ? 'var(--color-warning)' : 'var(--color-info)'}}>
                                {extra.partyId === FAKE_VALUE_EMPTY ? t('Nová osoba') : t('Existující osoba')}</span>
							</>}
                                {!!error && <FormHelperText sx={{color: 'var(--color-error)'}}>{error}</FormHelperText>}
                            </Grid>
                            <Grid item md={2} xs={4} sx={{textAlign: 'right'}}>
                                <Button title={t('Odstranit osobu')} variant={'contained'} size={'small'}
                                    color={'inherit'}
                                    onClick={() => {
                                        onDelete(extra);
                                    }}>
                                    <DeleteRounded/>
                                </Button>
                                &nbsp;
                                <Button title={t('Upravit osobu')} variant={'contained'} size={'small'}
                                    color={'secondary'}
                                    onClick={() => {
                                        onEdit(extra);
                                    }}>
                                    <EditRounded/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>);
                return;
            }

            items.push(<Grid key={i} item md={4} sm={6} xs={12}>
                <RsvpInvitePartyBox key={i} extra={extra}
                    deleteTitle={t('Odstranit osobu')}
                    editTitle={'Upravit osobu'}
                    showStatus={true}
                    error={error}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
                <input name={'replyData.extras.' + i} style={{display: 'none'}}/>
            </Grid>)
        });

        items.push(<Grid key={'add'} item md={4} sm={6} xs={12}>
            <Card className={'rsvp-extra-match-box'}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant={'contained'} size={'small'} title={t('Přidat osobu personálu')}
                                color={'secondary'}
                                onClick={() => {
                                    setEditExtra({})
                                }}>
                                <span>{items.length > 0 ? t('Přidat další osobu') : t('Přidat osobu personálu')}</span>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', flexFlow: 'column', alignItems: 'flex-end'}}>
                            <PlusOneRounded sx={{fontSize: '45px !important', color: 'silver'}}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>);

        items.push(<Grid key={'mass'} item md={4} sm={6} xs={12}>
            <Card className={'rsvp-extra-match-box'}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant={'contained'} size={'small'} title={t('Přidat více osob personálu najednou')}
                                color={'secondary'}
                                onClick={() => {
                                    setIsEditMulti(true)
                                }}>
                                <span>{t('Přidat hromadně')}</span>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', flexFlow: 'column', alignItems: 'flex-end'}}>
                            <KeyboardDoubleArrowRightRounded sx={{fontSize: '45px !important', color: 'silver'}}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>);

        return items;

    }, [replyData?.extras, errors.replyData, sortBy, displayAs, onDelete, onEdit, t]);

    const runNextMulti = useCallback((editMulti?: string[]) => {
        const lines = editMulti ? [...editMulti] : [];
        let nextLine;
        let extra;
        while (lines.length > 0) {
            nextLine = lines.shift();
            if (!nextLine?.trim()) {
                continue;
            }

            const tokens = nextLine.split(/[\t\n\r ]+/);
            const firstName = tokens.shift()?.trim();
            const sex = tokens.pop()?.trim();
            const lastName = tokens?.join(' ') || sex;

            if (values.replyData?.extras?.find((extra) => isNameEqual(extra.firstName, firstName) && isNameEqual(extra.lastName, lastName))) {
                continue;
            }

            extra = {
                firstName,
                lastName,
                sex: sex === JsonInviteReplyExtraSexEnum.F || sex === 'Ž' || sex === 'Z'
                    ? JsonInviteReplyExtraSexEnum.F
                    : (sex === JsonInviteReplyExtraSexEnum.M ? sex : undefined)
            }
            break;
        }

        if (!extra) {
            setNextEditMulti(undefined);
            setEditMulti(undefined);
            setIsEditMulti(false);
            return;
        }

        setEditExtra(extra);
        setNextEditMulti(lines);

    }, [values]);

    return <Card className={'rsvp-supplier-party'}>
        <CardContent>
            <p>{t('Pro každou osobu prosím zadejte její jméno, příjmení a pohlaví a proveďte vyhledání - systém vám nabídne možné shody dle záznamů z minulých let.' +
                ' Osobu následně spárujte s některou z nalezených, nebo ji založte jako zcela novou (v tom případě je nutné doplnit její fotografii).')}</p>

            <Grid container spacing={2} style={{marginTop: '0'}}>
                <Grid item md={items.length > 3 ? 7 : 12} xs={12}>
                    {t('Formulář je průběžně automaticky ukládán.')}
                </Grid>
                {items.length > 3 && <Grid item md={5} xs={12} sx={{textAlign: 'right', paddingBottom: '10px'}}>
                    {t('Řazení:')}&nbsp;
					<Button onClick={() => setSortBy('time')} size={'small'} title={t('Řadit dle času vložení do formuláře')}
						color={sortBy === 'time' ? 'secondary' : 'inherit'}><AccessTimeRounded/></Button>&nbsp;
					<Button onClick={() => setSortBy('name')} size={'small'} title={t('Řadit dle příjmení')}
						color={sortBy === 'name' ? 'secondary' : 'inherit'}><SortByAlphaRounded/></Button>
					&nbsp;&nbsp;&nbsp;
                    {t('Zobrazení:')}&nbsp;
					<Button onClick={() => setDisplayAs('grid')} size={'small'} title={t('Zobrazit jako boxy')}
						color={displayAs === 'grid' ? 'secondary' : 'inherit'}><GridViewRounded/></Button>&nbsp;
					<Button onClick={() => setDisplayAs('table')} size={'small'} title={t('Zobrazit jako tabulku')}
						color={displayAs === 'table' ? 'secondary' : 'inherit'}><ViewHeadlineRounded/></Button>
				</Grid>}
                {items}
            </Grid>
        </CardContent>
        {isEditMulti && <RsvpInviteExtraMultiForm
			token={token}
			eventId={eventId}
			item={{body: editMulti?.join("\n") || ''}}
			onSave={async (item) => {
                runNextMulti(item.body.split("\n"));
            }}
			onCancel={() => setIsEditMulti(false)}
		/>}
        {!!editExtra && <RsvpInviteExtraImportForm
            key={JSON.stringify(editExtra)}
			token={token}
			eventId={eventId}
			item={editExtra}
			original={editExtra}
			onFileUpload={onFileUpload}
			replyValues={values}
			onSave={async (extraWithResult) => {
                const extras = [...(replyData?.extras || [])];
                const extra = {...extraWithResult};
                if (!!extraWithResult.resultParty) {
                    extra.firstName = extraWithResult.resultParty.firstName || extra.firstName;
                    extra.lastName = extraWithResult.resultParty.lastName || extra.lastName;
                    extra.sex = (extraWithResult.resultParty.sex === undefined ? undefined : JsonInviteReplyExtraSexEnum[extraWithResult.resultParty.sex]) || extra.sex;
                    extra.photoGuid = extraWithResult.resultParty.photoGuid || extra.photoGuid;
                    extra.partyId = extraWithResult.resultParty.partyId;
                }

                let i: number | undefined;
                if (!!extra.extraId && extras) {
                    i = extras.findIndex((e) => e.extraId === extra.extraId);
                }
                if (i !== undefined) {
                    extras[i] = extra;
                } else {
                    extra.extraId = extras.map((e) => e.extraId || 0).reduce((a, b) => Math.max(a, b), 0) + 1;
                    extras.push(extra);
                }
                setFieldValue('replyData.extras', extras);
                await onReplySave({...values, replyData: {...values.replyData, extras}});
                setEditExtra(undefined);

                if (isEditMulti) {
                    setEditMulti(nextEditMulti);
                    runNextMulti(nextEditMulti);
                }
            }}
			onCancel={() => setEditExtra(undefined)}
		/>}
    </Card>;
}

export default RsvpInviteSupplierParties;
