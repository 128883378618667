/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonArticleVariants,
    JsonArticleVariantsFromJSON,
    JsonArticleVariantsFromJSONTyped,
    JsonArticleVariantsToJSON,
} from './JsonArticleVariants';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonArticleType
 */
export interface JsonArticleType {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonArticleType
     */
    allowedActions?: Array<JsonArticleTypeAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleType
     */
    articleTypeId?: number;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonArticleType
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleType
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleType
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleType
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleType
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleType
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleType
     */
    updatedBy?: number;
    /**
     * 
     * @type {JsonArticleVariants}
     * @memberof JsonArticleType
     */
    variants?: JsonArticleVariants;
}

/**
* @export
* @enum {string}
*/
export enum JsonArticleTypeAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonArticleTypeFromJSON(json: any): JsonArticleType {
    return JsonArticleTypeFromJSONTyped(json, false);
}

export function JsonArticleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonArticleType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'articleTypeId': !exists(json, 'articleTypeId') ? undefined : json['articleTypeId'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'variants': !exists(json, 'variants') ? undefined : JsonArticleVariantsFromJSON(json['variants']),
    };
}

export function JsonArticleTypeToJSON(value?: JsonArticleType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'articleTypeId': value.articleTypeId,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'variants': JsonArticleVariantsToJSON(value.variants),
    };
}

