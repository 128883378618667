import {Button, FormControl, FormHelperText, FormLabel} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps} from '../../model/form';
import * as React from "react";
import {createRef} from "react";
import {useAppTranslation} from "../../services/i18n";
import {DropzoneArea} from "mui-file-dropzone";
import {Delete} from "@mui/icons-material";

interface Props extends FormFieldProps {
    acceptedFiles?: string[],
    filesLimit?: number,
    maxFileSize?: number,
    dropzoneText?: string
}

export const FileFormField = (props: Props) => {
    const {name, label, onChange, acceptedFiles, filesLimit, maxFileSize, dropzoneText} = props;

    const t = useAppTranslation();
    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();
    const dzRef = createRef<DropzoneArea>();

    const showError = !!meta.error && submitCount > 0;

    const isMulti = (filesLimit || 1) > 1;
    const isValue = ((isMulti && field.value?.length && field.value.length > 0) || (!isMulti && !!field.value));
    const values: any[] = isMulti ? field.value : [field.value];

    return <FormControl error={showError} fullWidth>
        {label && <FormLabel>{typeof label === 'string' ? t(label) : undefined}</FormLabel>}
        <div style={isMulti || !isValue || showError ? undefined : {
            display: 'none'
        }}>
            <DropzoneArea
                ref={dzRef}
                clearOnUnmount={true}
                filesLimit={filesLimit || 1}
                acceptedFiles={acceptedFiles}
                maxFileSize={maxFileSize || 2 * 1024 * 1024}
                dropzoneText={dropzoneText || t('Klikněte pro vložení souboru nebo jej přetáhněte')}
                disableRejectionFeedback={true}
                getDropRejectMessage={(f, a, maxSize) =>
                    t('Soubor nemá platný typ nebo je větší než {{maxSize}} MB', {maxSize: Math.round(maxSize / 1024 / 1024)})}
                getFileLimitExceedMessage={() => (!isMulti
                    ? t('Nahrajte pouze jeden soubor')
                    : t('Maximální počet souborů je {{filesLimit}}', {filesLimit}))}
                dropzoneClass={'dropzone-area'}
                showPreviewsInDropzone={false}
                showAlerts={['error']}
                alertSnackbarProps={{autoHideDuration: null}}
                onChange={async (files) => {
                    helpers.setValue(isMulti ? files : files[0], true);
                    if (onChange) {
                        onChange(isMulti ? files : files[0])
                    }
                }}
                onDelete={() => {
                    helpers.setValue(undefined, true);
                    if (onChange) {
                        onChange(undefined);
                    }
                }}
                fileObjects={undefined}
                initialFiles={values}
            />
        </div>
        {isValue && <div style={{padding: '10px', background: '#eee', display: 'flex', flexDirection: 'column'}}>{values.map((f, i) => {
            return <div key={i} style={{flexBasis: '100%', display: 'flex', padding: '5px 0'}}>
                <i style={{alignSelf: 'center', flexGrow: 1}}>{f.name} ({Math.round(f.size / 1024)} kB)</i>
                <Button color={'warning'} size={'small'} variant={'contained'}
                    sx={{minWidth: 'unset'}}
                    title={isMulti ? t('Odebrat soubor') : t('Použít jiný soubor')}
                    onClick={() => {
                        if (dzRef.current?.deleteFile) {
                            dzRef.current.deleteFile(f, i);
                        }
                    }}
                ><Delete/></Button>
            </div>;
        })}
		</div>}
        {showError && meta.error && <FormHelperText sx={{paddingTop: "10px", textAlign: "center", fontSize: "100%"}}>{meta.error}</FormHelperText>}
    </FormControl>;
}
