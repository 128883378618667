/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonAddress,
    JsonAddressFromJSON,
    JsonAddressFromJSONTyped,
    JsonAddressToJSON,
} from './JsonAddress';
import {
    JsonPartyEventDetails,
    JsonPartyEventDetailsFromJSON,
    JsonPartyEventDetailsFromJSONTyped,
    JsonPartyEventDetailsToJSON,
} from './JsonPartyEventDetails';

/**
 * 
 * @export
 * @interface JsonPartyInfo
 */
export interface JsonPartyInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonPartyInfo
     */
    allowedActions?: Array<JsonPartyInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    cebId?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    companyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    email?: string;
    /**
     * 
     * @type {Array<JsonPartyEventDetails>}
     * @memberof JsonPartyInfo
     */
    eventDetails?: Array<JsonPartyEventDetails>;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    orgCompanyName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    partyType?: JsonPartyInfoPartyTypeEnum;
    /**
     * 
     * @type {JsonAddress}
     * @memberof JsonPartyInfo
     */
    permAddress?: JsonAddress;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    photoId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    sex?: JsonPartyInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiPhotoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    siwiPhotoId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiPropFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiPropLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiStatus?: JsonPartyInfoSiwiStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiSyncStatus?: JsonPartyInfoSiwiSyncStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    siwiUpdatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    status?: JsonPartyInfoStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonPartyInfo
     */
    tags?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyInfo
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyInfo
     */
    userStatus?: JsonPartyInfoUserStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonPartyInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyInfoPartyTypeEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyInfoSexEnum {
    M = 'M',
    F = 'F'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyInfoSiwiStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyInfoSiwiSyncStatusEnum {
    None = 'NONE',
    Synced = 'SYNCED',
    Dirty = 'DIRTY',
    Pushing = 'PUSHING',
    Conflict = 'CONFLICT',
    Error = 'ERROR'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyInfoUserStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}

export function JsonPartyInfoFromJSON(json: any): JsonPartyInfo {
    return JsonPartyInfoFromJSONTyped(json, false);
}

export function JsonPartyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPartyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'cebId': !exists(json, 'cebId') ? undefined : json['cebId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyNumber': !exists(json, 'companyNumber') ? undefined : json['companyNumber'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'eventDetails': !exists(json, 'eventDetails') ? undefined : ((json['eventDetails'] as Array<any>).map(JsonPartyEventDetailsFromJSON)),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'orgCompanyName': !exists(json, 'orgCompanyName') ? undefined : json['orgCompanyName'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'partyType': !exists(json, 'partyType') ? undefined : json['partyType'],
        'permAddress': !exists(json, 'permAddress') ? undefined : JsonAddressFromJSON(json['permAddress']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'siwiFirstName': !exists(json, 'siwiFirstName') ? undefined : json['siwiFirstName'],
        'siwiId': !exists(json, 'siwiId') ? undefined : json['siwiId'],
        'siwiLastName': !exists(json, 'siwiLastName') ? undefined : json['siwiLastName'],
        'siwiPhotoGuid': !exists(json, 'siwiPhotoGuid') ? undefined : json['siwiPhotoGuid'],
        'siwiPhotoId': !exists(json, 'siwiPhotoId') ? undefined : json['siwiPhotoId'],
        'siwiPropFirstName': !exists(json, 'siwiPropFirstName') ? undefined : json['siwiPropFirstName'],
        'siwiPropLastName': !exists(json, 'siwiPropLastName') ? undefined : json['siwiPropLastName'],
        'siwiStatus': !exists(json, 'siwiStatus') ? undefined : json['siwiStatus'],
        'siwiSyncStatus': !exists(json, 'siwiSyncStatus') ? undefined : json['siwiSyncStatus'],
        'siwiUpdatedAt': !exists(json, 'siwiUpdatedAt') ? undefined : json['siwiUpdatedAt'],
        'siwiUpdatedBy': !exists(json, 'siwiUpdatedBy') ? undefined : json['siwiUpdatedBy'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userStatus': !exists(json, 'userStatus') ? undefined : json['userStatus'],
    };
}

export function JsonPartyInfoToJSON(value?: JsonPartyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'bankAccount': value.bankAccount,
        'birthDate': value.birthDate,
        'cebId': value.cebId,
        'companyId': value.companyId,
        'companyName': value.companyName,
        'companyNumber': value.companyNumber,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'email': value.email,
        'eventDetails': value.eventDetails === undefined ? undefined : ((value.eventDetails as Array<any>).map(JsonPartyEventDetailsToJSON)),
        'firstName': value.firstName,
        'fullName': value.fullName,
        'lastName': value.lastName,
        'note': value.note,
        'orgCompanyName': value.orgCompanyName,
        'partyId': value.partyId,
        'partyType': value.partyType,
        'permAddress': JsonAddressToJSON(value.permAddress),
        'phone': value.phone,
        'photoGuid': value.photoGuid,
        'photoId': value.photoId,
        'roleId': value.roleId,
        'sex': value.sex,
        'siwiFirstName': value.siwiFirstName,
        'siwiId': value.siwiId,
        'siwiLastName': value.siwiLastName,
        'siwiPhotoGuid': value.siwiPhotoGuid,
        'siwiPhotoId': value.siwiPhotoId,
        'siwiPropFirstName': value.siwiPropFirstName,
        'siwiPropLastName': value.siwiPropLastName,
        'siwiStatus': value.siwiStatus,
        'siwiSyncStatus': value.siwiSyncStatus,
        'siwiUpdatedAt': value.siwiUpdatedAt,
        'siwiUpdatedBy': value.siwiUpdatedBy,
        'status': value.status,
        'tags': value.tags,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
        'userStatus': value.userStatus,
    };
}

