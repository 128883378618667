/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonAddress,
    JsonAddressFromJSON,
    JsonAddressFromJSONTyped,
    JsonAddressToJSON,
} from './JsonAddress';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserFromJSONTyped,
    JsonUserToJSON,
} from './JsonUser';

/**
 * 
 * @export
 * @interface JsonParty
 */
export interface JsonParty {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonParty
     */
    allowedActions?: Array<JsonPartyAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    cebId?: string;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonParty
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonParty
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    companyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonParty
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonParty
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    partyType?: JsonPartyPartyTypeEnum;
    /**
     * 
     * @type {JsonAddress}
     * @memberof JsonParty
     */
    permAddress?: JsonAddress;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonParty
     */
    photoId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonParty
     */
    removeTags?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof JsonParty
     */
    rowNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    sex?: JsonPartySexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiLastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonParty
     */
    siwiPhotoId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiPropFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiPropLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiStatus?: JsonPartySiwiStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiSyncStatus?: JsonPartySiwiSyncStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiUpdatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    siwiUpdatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    status?: JsonPartyStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonParty
     */
    tags?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof JsonParty
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonParty
     */
    updatedBy?: number;
    /**
     * 
     * @type {JsonUser}
     * @memberof JsonParty
     */
    user?: JsonUser;
}

/**
* @export
* @enum {string}
*/
export enum JsonPartyAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyPartyTypeEnum {
    Fo = 'FO',
    Po = 'PO',
    T = 'T'
}/**
* @export
* @enum {string}
*/
export enum JsonPartySexEnum {
    M = 'M',
    F = 'F'
}/**
* @export
* @enum {string}
*/
export enum JsonPartySiwiStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}/**
* @export
* @enum {string}
*/
export enum JsonPartySiwiSyncStatusEnum {
    None = 'NONE',
    Synced = 'SYNCED',
    Dirty = 'DIRTY',
    Pushing = 'PUSHING',
    Conflict = 'CONFLICT',
    Error = 'ERROR'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonPartyFromJSON(json: any): JsonParty {
    return JsonPartyFromJSONTyped(json, false);
}

export function JsonPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonParty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'cebId': !exists(json, 'cebId') ? undefined : json['cebId'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyNumber': !exists(json, 'companyNumber') ? undefined : json['companyNumber'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'partyType': !exists(json, 'partyType') ? undefined : json['partyType'],
        'permAddress': !exists(json, 'permAddress') ? undefined : JsonAddressFromJSON(json['permAddress']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'removeTags': !exists(json, 'removeTags') ? undefined : json['removeTags'],
        'rowNo': !exists(json, 'rowNo') ? undefined : json['rowNo'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'siwiFirstName': !exists(json, 'siwiFirstName') ? undefined : json['siwiFirstName'],
        'siwiId': !exists(json, 'siwiId') ? undefined : json['siwiId'],
        'siwiLastName': !exists(json, 'siwiLastName') ? undefined : json['siwiLastName'],
        'siwiPhotoId': !exists(json, 'siwiPhotoId') ? undefined : json['siwiPhotoId'],
        'siwiPropFirstName': !exists(json, 'siwiPropFirstName') ? undefined : json['siwiPropFirstName'],
        'siwiPropLastName': !exists(json, 'siwiPropLastName') ? undefined : json['siwiPropLastName'],
        'siwiStatus': !exists(json, 'siwiStatus') ? undefined : json['siwiStatus'],
        'siwiSyncStatus': !exists(json, 'siwiSyncStatus') ? undefined : json['siwiSyncStatus'],
        'siwiUpdatedAt': !exists(json, 'siwiUpdatedAt') ? undefined : json['siwiUpdatedAt'],
        'siwiUpdatedBy': !exists(json, 'siwiUpdatedBy') ? undefined : json['siwiUpdatedBy'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'user': !exists(json, 'user') ? undefined : JsonUserFromJSON(json['user']),
    };
}

export function JsonPartyToJSON(value?: JsonParty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'bankAccount': value.bankAccount,
        'birthDate': value.birthDate,
        'cebId': value.cebId,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyId': value.companyId,
        'companyName': value.companyName,
        'companyNumber': value.companyNumber,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'note': value.note,
        'partyId': value.partyId,
        'partyType': value.partyType,
        'permAddress': JsonAddressToJSON(value.permAddress),
        'phone': value.phone,
        'photoGuid': value.photoGuid,
        'photoId': value.photoId,
        'removeTags': value.removeTags,
        'rowNo': value.rowNo,
        'sex': value.sex,
        'siwiFirstName': value.siwiFirstName,
        'siwiId': value.siwiId,
        'siwiLastName': value.siwiLastName,
        'siwiPhotoId': value.siwiPhotoId,
        'siwiPropFirstName': value.siwiPropFirstName,
        'siwiPropLastName': value.siwiPropLastName,
        'siwiStatus': value.siwiStatus,
        'siwiSyncStatus': value.siwiSyncStatus,
        'siwiUpdatedAt': value.siwiUpdatedAt,
        'siwiUpdatedBy': value.siwiUpdatedBy,
        'status': value.status,
        'tags': value.tags,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'user': JsonUserToJSON(value.user),
    };
}

