import * as React from "react";
import {useCallback, useMemo} from "react";
import {FormControl, MenuItem, Select} from "@mui/material";
import {useAppSelector} from "../../store";
import {CodebookState} from "../../store/codebooks";
import {selectCodebooks} from "../../store/selectors";
import {createOptions} from "../../model/form";

interface Props {
    eventId?: number,
    onSetEventId: (eventId: number) => void
}

export const EventFilter = ({eventId, onSetEventId}: Props) => {

    const codebooks = useAppSelector<CodebookState>(selectCodebooks);

    const isCodebookPending = codebooks['event'] === undefined;

    const selectOptions = useMemo(() => {
        return isCodebookPending ? [] : createOptions({codebookName: 'event', asNumber: true, sortByKeys: true, reversed: true}, codebooks);
    }, [isCodebookPending, codebooks]);

    const menuItems = useMemo(() => {
        return selectOptions.map((o, i) => {
            return <MenuItem key={i} value={o.value as string} title={o.tooltip}>{o.label}</MenuItem>;
        });
    }, [selectOptions]);

    const renderValue = useCallback((v: any) => {
        if (v || v === 0) {
            const o = selectOptions.find((o) => o.value === v);
            return o?.label || v;
        }
        return 'Událost';

    }, [selectOptions]);

    if (isCodebookPending) {
        return null;
    }

    return <FormControl fullWidth>
        <Select
            displayEmpty={true}
            placeholder={'Událost'}
            renderValue={renderValue}
            value={eventId}
            inputProps={{name: 'eventId'}}
            onChange={(e) => {
                const v = e.target.value;
                if (v) {
                    onSetEventId(v as number);
                }
            }}>
            {menuItems}
        </Select>
    </FormControl>
}
