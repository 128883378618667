/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEvent,
    JsonEventFromJSON,
    JsonEventFromJSONTyped,
    JsonEventToJSON,
} from './JsonEvent';

/**
 * 
 * @export
 * @interface JsonConfiguration
 */
export interface JsonConfiguration {
    /**
     * 
     * @type {JsonEvent}
     * @memberof JsonConfiguration
     */
    defaultEvent?: JsonEvent;
    /**
     * 
     * @type {string}
     * @memberof JsonConfiguration
     */
    today?: string;
}

export function JsonConfigurationFromJSON(json: any): JsonConfiguration {
    return JsonConfigurationFromJSONTyped(json, false);
}

export function JsonConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultEvent': !exists(json, 'defaultEvent') ? undefined : JsonEventFromJSON(json['defaultEvent']),
        'today': !exists(json, 'today') ? undefined : json['today'],
    };
}

export function JsonConfigurationToJSON(value?: JsonConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultEvent': JsonEventToJSON(value.defaultEvent),
        'today': value.today,
    };
}

