import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonTariff, ApiResultOfJsonTariffInfo, GetTariffCountUsingGETRequest, GetTariffListUsingGETRequest, JsonTariff, JsonTariffInfo, TariffControllerApi} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const tariffsApi = new TariffControllerApi(API_CONFIG);

export const fetchTariffs = createAsyncThunk('tariffs/list', async (filter: GetTariffListUsingGETRequest, thunkApi) => {
    return {...await tariffsApi.getTariffListUsingGET(filter), filter};
});

export const fetchTariffsCount = createAsyncThunk('tariffs/count', async (params: GetTariffCountUsingGETRequest = {}) => {
    return await tariffsApi.getTariffCountUsingGET(params);
});

export const fetchTariffInfo = createAsyncThunk('tariff/info', async (tariffId: number): Promise<ApiResultOfJsonTariffInfo> => {
    return await tariffsApi.getTariffInfoUsingGET({tariffId});
});

export const fetchTariff = createAsyncThunk('tariff/fetch', async (tariffId: number): Promise<ApiResultOfJsonTariff> => {
    return await tariffsApi.getTariffUsingGET({tariffId});
});

export const saveTariff = createAsyncThunk('tariff/save', async (tariff: JsonTariff) => {
    return await tariffsApi.saveTariffUsingPOST({json: tariff});
});

export const tariffsSlice = createSlice({
    name: 'tariffs',
    initialState: (): ItemsState<JsonTariffInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchTariffs, fetchTariffsCount);
    }
});

export const tariffsReducer = tariffsSlice.reducer;
