import {JsonEventPartyTravel, JsonEventPartyTravelVehicleEnum, JsonFile, JsonInviteReply} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import {ModalProps, useModal} from "../../services/modal";
import {useFormikContext} from "formik";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {Alert, Button, Card, CardContent, FormHelperText, Grid} from "@mui/material";
import {CommuteRounded, DeleteForeverRounded, DeleteRounded, EditRounded} from "@mui/icons-material";
import {RsvpInviteTravelForm} from "./RsvpInviteTravelForm";
import {FAKE_VALUE_AND, FAKE_VALUE_EMPTY} from "../../model/form";
import {TravelInfo} from "../TravelInfo";

type RsvpInviteTravelsProps = {
    token: string,
    eventId: number,
    values: JsonInviteReply,
    onFileUpload: (file: File, replyValues: JsonInviteReply) => Promise<JsonFile | undefined>,
    onReplySave: (values: JsonInviteReply) => void,
}

export const RsvpInviteTravels = (props: RsvpInviteTravelsProps) => {
    const {values, eventId, onFileUpload, onReplySave} = props;
    const {replyData} = values;

    const t = useAppTranslation();
    const modal = useModal();
    const {setFieldValue, errors} = useFormikContext<JsonInviteReply>();
    const [editTravel, setEditTravel] = useState<JsonEventPartyTravel | undefined>(undefined);

    const onEdit = useCallback((travel: JsonEventPartyTravel) => {
        setEditTravel(travel);
    }, [setEditTravel]);

    const onDelete = useCallback(async (travel: JsonEventPartyTravel) => {
        const result = await modal.confirm({
            title: t('Potvrzení odstranění cesty'),
            message: <Alert severity={'error'}>
                {t('Skutečně trvale odstranit cestu z formuláře?')}
                &nbsp;<strong>{t('Tato akce je nevratná!')}</strong>
            </Alert>,
            cancelText: 'Zpět',
            confirmColor: 'error',
            confirmText: 'Trvale odstranit',
            confirmIcon: <DeleteForeverRounded/>,
        } as ModalProps);
        if (result !== 'CONFIRM') {
            return;
        }
        setFieldValue('replyData.travels', replyData?.travels?.filter((t) => t.eventPartyTravelId !== travel.eventPartyTravelId));
    }, [replyData?.travels, setFieldValue, modal, t]);

    const items: JSX.Element[] = useMemo(() => {
        const items: JSX.Element[] = [];
        const travels = replyData?.travels ? [...replyData.travels] : [];

        travels.forEach((travel, i) => {
            const error = (errors.replyData as any)?.travels?.[i];

            items.push(<Grid key={i} item lg={6} md={12}>
                <Card className={'rsvp-extra-match-box'}>
                    <CardContent>
                        <TravelInfo travel={travel}/>
                        <div style={{flexGrow: 1}}/>
                        <Grid container columnSpacing={2} columns={22}>
                            {!!onDelete && <Grid item xs={8}>
								<Button title={t('Odstranit cestu')} variant={'contained'} size={'small'}
									color={'inherit'}
									onClick={() => {
                                        onDelete(travel as JsonEventPartyTravel).then();
                                    }}>
									<DeleteRounded/>
									<span>{t('Odstranit')}</span>
								</Button>
							</Grid>}
                            {!!onEdit && <Grid item xs={8}>
								<Button title={t('Upravit cestu')} variant={'contained'} size={'small'}
									color={'secondary'}
									onClick={() => {
                                        onEdit(travel as JsonEventPartyTravel);
                                    }}>
									<EditRounded/>
									<span>{t('Upravit')}</span>
								</Button>
							</Grid>}
                        </Grid>
                        {!!error && <FormHelperText sx={{color: 'var(--color-error)'}}>{error}</FormHelperText>}
                    </CardContent>
                </Card>
                <input name={'replyData.travels.' + i} style={{display: 'none'}}/>
            </Grid>)
        });

        items.push(<Grid key={'add'} item md={6} xs={12}>
            <Card className={'rsvp-extra-match-box'}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant={'contained'} size={'small'} title={t('Přidat cestu')}
                                color={'secondary'}
                                onClick={() => {
                                    setEditTravel({})
                                }}>
                                <span>{items.length > 0 ? t('Přidat další cestu') : t('Přidat cestu')}</span>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', flexFlow: 'column', alignItems: 'flex-end'}}>
                            <CommuteRounded sx={{fontSize: '45px !important', color: 'silver'}}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>);

        return items;

    }, [replyData?.travels, errors.replyData, onDelete, onEdit, t]);

    return <Card className={'rsvp-supplier-party'}>
        <CardContent>
            <p>{t('Pro každou cestu prosím zadejte místo, datum a čas příjezdu a odjezdu.')}</p>
            <p>{t('Pro cestu automobilem zadejte celkové uplatňované kilometry (náhrada bude automaticky vypočítána), pro ostatní dopravní prostředky je třeba nahrát příslušný doklad a zadat částku ručně.')}</p>

            <Grid container spacing={2} style={{marginTop: '0'}}>
                {items}
            </Grid>
        </CardContent>
        {!!editTravel && <RsvpInviteTravelForm
			key={JSON.stringify(editTravel)}
			eventId={eventId}
			item={editTravel}
			onFileUpload={onFileUpload}
			replyValues={values}
			onSave={async (travel: JsonEventPartyTravel) => {
                const travels = [...(replyData?.travels || [])];

                let isReturn = false;
                if (travel.eventPartyTravelId === FAKE_VALUE_AND) {
                    isReturn = true;
                    travel.eventPartyTravelId = undefined;
                } else if (travel.eventPartyTravelId === FAKE_VALUE_EMPTY) {
                    travel.eventPartyTravelId = undefined;
                }

                let i: number | undefined;
                if (!!travel.eventPartyTravelId && travels) {
                    i = travels.findIndex((e) => e.eventPartyTravelId === travel.eventPartyTravelId);
                }
                if (i !== undefined) {
                    travels[i] = travel;
                } else {
                    travel.eventPartyTravelId = travels.map((e) => e.eventPartyTravelId || 0).reduce((a, b) => Math.max(a, b), 0) + 1;
                    travels.push(travel);
                }
                setFieldValue('replyData.travels', travels);
                await onReplySave({...values, replyData: {...values.replyData, travels}});

                if (isReturn) {
                    setEditTravel({
                        eventPartyTravelId: FAKE_VALUE_EMPTY,
                        beginLocation: travel.endLocation,
                        endLocation: travel.beginLocation,
                        vehicle: travel.vehicle,
                        plateNum: travel.vehicle === JsonEventPartyTravelVehicleEnum.Car ? travel.plateNum : undefined,
                        kmCnt: travel.vehicle === JsonEventPartyTravelVehicleEnum.Car ? travel.kmCnt : undefined,
                        compAmount: travel.vehicle !== JsonEventPartyTravelVehicleEnum.Car ? travel.compAmount : undefined,
                    });
                } else {
                    setEditTravel(undefined);
                }
            }}
			onCancel={() => setEditTravel(undefined)}
		/>}
    </Card>;
}