import {Autocomplete, AutocompleteRenderInputParams, Box, Button, Chip, createFilterOptions, Grid, TextField} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {CodebookOptionsProps, createOptions, FAKE_VALUE_EMPTY, FAKE_VALUE_RESET, FormFieldProps, getOption, OptionValue} from '../../model/form';
import {useAppTranslation} from "../../services/i18n";
import {useAppSelector} from "../../store";
import {selectCodebooks} from "../../store/selectors";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {AutocompleteRenderGetTagProps} from "@mui/material/Autocomplete/Autocomplete";
import {CreateFilterOptionsConfig, FilterOptionsState} from "@mui/base/AutocompleteUnstyled/useAutocomplete";
import {shorten} from "../../helpers/format";
import {AddRounded, EditRounded} from "@mui/icons-material";

interface Props extends FormFieldProps {
    options?: OptionValue[],
    codebookProps?: CodebookOptionsProps,
    placeholder?: string;
    isMulti?: boolean;
    clearable?: boolean;
    useColors?: boolean;
    showTooltip?: boolean;
    matchFromStart?: boolean;
    freeSolo?: boolean;
    shortValue?: boolean;
    getTagColor?: (option: OptionValue) => string;
    editProps?: {
        modal: (
            value: any,
            onCancel: () => void,
            onSave: (res: any) => void
        ) => JSX.Element
    }
}

const isOptionEqualToValue = (option: OptionValue, value: any) => {
    return option.value + '' === (value.value || value) + '';
}

export const SelectFormField = (props: Props) => {

    const {
        name, label, options, codebookProps, onChange, isMulti, clearable, placeholder, disabled, useColors,
        showTooltip, matchFromStart, freeSolo, shortValue, getTagColor, editProps
    } = props;

    const t = useAppTranslation();
    const codebooks = useAppSelector(selectCodebooks);
    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();
    const [editItem, setEditItem] = useState<any | undefined>(undefined);

    const showError = !!meta.error && (meta.touched || submitCount > 0);
    const metaError = meta.error;
    const formSetValue = helpers.setValue;

    const isCodebookPending = codebookProps && codebooks[codebookProps.codebookName] === undefined;
    const hasEmpty = !!codebookProps?.addEmpty;
    const hasReset = !!codebookProps?.addReset;

    const selectOptions = useMemo(() => {
        return codebookProps && !isCodebookPending ? createOptions(codebookProps, codebooks) : (options || []);
    }, [isCodebookPending, codebookProps, codebooks, options]);

    const renderOption = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: OptionValue) => (
        <Box component="li" {...props} title={option.tooltip}>
            {option.icon ? <small>{option.icon} </small> : null}{option.label}
            {showTooltip && option.tooltip ? <>&nbsp;<small>({option.tooltip})</small></> : null}
        </Box>
    ), [showTooltip]);

    const getOptionLabel = useCallback((value: any) => {
        return getOption(value, selectOptions, codebookProps?.scope).label || value;

    }, [selectOptions, codebookProps?.scope]);

    const getColorClassName = useCallback((value: any) => {
        const o = getOption(value, selectOptions, codebookProps?.scope);
        if (o && useColors) {
            if (hasEmpty && o.value === FAKE_VALUE_EMPTY) {
                return 'select-value-empty';
            } else if (hasReset && o.value === FAKE_VALUE_RESET) {
                return 'select-value-reset';
            } else {
                return 'select-value-update';
            }
        }
        return o ? undefined : 'select-value-none';

    }, [selectOptions, codebookProps?.scope, useColors, hasEmpty, hasReset]);

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return <TextField
            {...params}
            margin={'dense'}
            fullWidth
            id={name}
            name={name}
            label={label}
            hiddenLabel={!label}
            title={typeof label == 'string' ? label : placeholder}
            error={showError}
            helperText={showError && metaError}
            variant={'outlined'}
            size={'small'}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            placeholder={placeholder}
            disabled={disabled}
        />
    }, [showError, metaError, name, label, placeholder, disabled]);

    const renderTags = useCallback((value: readonly string[], getTagProps: AutocompleteRenderGetTagProps) => {
        return value.map((option: string, index: number) => {
            const o = selectOptions.find((o) => isOptionEqualToValue(o, option));
            const title = o?.label || option;
            return <Chip variant="filled"
                title={title}
                style={o && getTagColor ? {backgroundColor: getTagColor(o)} : undefined}
                label={shortValue ? shorten(title) : (title)}{...getTagProps({index})} />
        })
    }, [selectOptions, shortValue, getTagColor]);

    const handleOnChange = useCallback((event: any, newValue: OptionValue | OptionValue[] | null) => {
        let v;
        if (isMulti) {
            if (newValue instanceof Array) {
                v = newValue.map(o => o.value === undefined ? o : o.value);
            } else {
                v = [];
            }
        } else {
            const vv = freeSolo
                ? (newValue?.hasOwnProperty('value') ? (newValue as any).value : newValue)
                : (newValue as OptionValue)?.value;
            if (vv || vv === 0) {
                v = vv;
            } else {
                v = undefined;
            }
        }

        formSetValue(v, true);
        if (onChange) {
            onChange(v);
        }
    }, [isMulti, freeSolo, onChange, formSetValue]);

    const filterOptions: ((options: OptionValue[], state: FilterOptionsState<OptionValue>) => OptionValue[]) | undefined = useMemo(() => {
        let c: CreateFilterOptionsConfig<OptionValue> = {};
        if (matchFromStart) {
            c['matchFrom'] = "start";
        }
        if (showTooltip) {
            c['stringify'] = (o: OptionValue) => {
                return (o.label + ' ' + o.tooltip).toLowerCase();
            }
        }
        return Object.keys(c).length > 0 ? createFilterOptions(c) : undefined;
    }, [matchFromStart, showTooltip]);

    if (codebookProps && codebooks[codebookProps.codebookName] === undefined) {
        return <TextField
            margin={'dense'}
            fullWidth
            label={label}
            hiddenLabel={!label}
            variant={'outlined'}
            size={'small'}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />
    }

    const currentValue = (field.value || field.value === 0) ? field.value : (isMulti ? [] : null);
    const el = <Autocomplete
        disableClearable={!clearable}
        id={name}
        value={currentValue}
        filterOptions={filterOptions}
        // inputValue={inputValue}
        freeSolo={freeSolo}
        clearOnBlur={freeSolo}
        autoSelect={freeSolo}
        options={selectOptions}
        multiple={isMulti}
        autoHighlight={!freeSolo}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        isOptionEqualToValue={(o, v) => isOptionEqualToValue(o, v)}
        renderInput={renderInput}
        renderTags={renderTags}
        onChange={handleOnChange}
        componentsProps={{
            paper: {
                sx: {
                    minWidth: 'clamp(200px, 100%, 100%)',
                    width: 'fit-content'
                }
            }
        }}
        ChipProps={{size: 'small'}}
        clearText={t('Vymazat')}
        closeText={t('Zavřít')}
        noOptionsText={t('Nic nenalezeno')}
        openText={t('Otevřít')}
        className={getColorClassName(currentValue)}
    />;

    if (editProps?.modal) {
        return <><Grid container>
            <Grid item sx={{flexGrow: 1}}>
                {el}
            </Grid>
            <Grid item><Button variant={'contained'} color={'secondary'} className={'quick-add-button'} onClick={() => {
                setEditItem(currentValue);
            }}> {currentValue ? <EditRounded/> : <AddRounded/>}</Button>
            </Grid>
        </Grid>
            {editItem !== undefined && editProps.modal(editItem,
                () => {
                    setEditItem(undefined);
                },
                (newValue) => {
                    formSetValue(newValue, true);
                    setEditItem(undefined);
                    if (onChange) {
                        onChange(newValue);
                    }
                })}
        </>;
    }
    return el;
}
