import {Grid} from '@mui/material';
import {FormikErrors} from 'formik';
import {FormProps} from "../../model/form";
import {JsonCompany} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {useCallback} from "react";

export interface CompanyFormProps extends FormProps<JsonCompany> {
}

const CompanyForm = (props: CompanyFormProps) => {

    const t = useAppTranslation();

    const validate = useCallback((values: JsonCompany) => {
        let errors = {} as FormikErrors<JsonCompany>;
        if (!values.title) {
            errors.title = t('Zadejte název');
        }
        if (!values.reason) {
            errors.reason = t('Zadejte výchozí reason')
        }
        return errors;
    }, [t]);

    return <FormContainer {...props} validate={validate} children={() => {
        return <>
            <Grid item xs={8}>
                <TextFormField name="title" label={t('Název')} type={'text'}/>
            </Grid>
            <Grid item xs={8}>
                <TextFormField name="reason" label={t('Reason')} type={'text'}/>
            </Grid>
        </>
    }}/>;
}

export default CompanyForm;
