import FormModal from "../components/form/FormModal";
import {JsonArticle} from "../generated-api";
import {FormProps} from "../model/form";
import {useAppTranslation} from "../services/i18n";
import EventArticlesForm from "../components/event/EventArticlesForm";

const EventArticlesModal = (props: FormProps<JsonArticle[]>) => {

    const t = useAppTranslation();

    return (
        <FormModal title={t('Sklad - nakoupeno')} maxWidth={'lg'} {...props}>
            <EventArticlesForm {...props}></EventArticlesForm>
        </FormModal>
    );
}

export default EventArticlesModal;
