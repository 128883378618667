import {Grid} from '@mui/material';
import {FormikErrors} from 'formik';
import {FormProps, isNumeric} from "../../model/form";
import {JsonEventDay} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {useCallback} from "react";

interface Props extends FormProps<JsonEventDay> {
}

const EventDayForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = useCallback((values: JsonEventDay) => {
        const errors = {} as FormikErrors<JsonEventDay>;
        if (values.vipSold && !isNumeric(values.vipSold, true)) {
            errors.vipSold = t('Zadejte prosím celé číslo')
        }
        if (values.voucherZ && !isNumeric(values.voucherZ, true)) {
            errors.voucherZ = t('Zadejte prosím celé číslo')
        }
        if (values.voucherR && !isNumeric(values.voucherR, true)) {
            errors.voucherR = t('Zadejte prosím celé číslo')
        }
        return errors;
    }, [t]);

    return (
        <FormContainer {...props} validate={validate} children={(formikProps) => <>
            <Grid item xs={4}>
                <TextFormField name="openFrom" label={t('Areál od')} type={'text'} maxlength={5}/>
            </Grid>
            <Grid item xs={8}>
            </Grid>

            <Grid item xs={12}>
                <TextFormField name="description" label={t('Program')} type={'textarea'} minRows={2} maxRows={5} maxlength={1000}/>
            </Grid>

            <Grid item xs={4}>
                <TextFormField name={'vipSold'} label={t('VIP prodáno')} type={'text'} maxlength={5}/>
            </Grid>
            <Grid item xs={4}>
                <TextFormField name={'voucherZ'} label={t('Voucher Z')} type={'text'} maxlength={5}/>
            </Grid>
            <Grid item xs={4}>
                <TextFormField name={'voucherR'} label={t('Voucher R')} type={'text'} maxlength={5}/>
            </Grid>

        </>}/>
    );
}

export default EventDayForm;
