import FormModal from "../components/form/FormModal";
import {JsonEventDay} from "../generated-api";
import {FormProps} from "../model/form";
import {useAppTranslation} from "../services/i18n";
import EventDayForm from "../components/event/EventDayForm";

const EventDayModal = (props: FormProps<JsonEventDay>) => {

    const t = useAppTranslation();

    return (
        <FormModal title={props.item?.eventDayId ? t('Úprava dne akce') : t('Nový den akce')} {...props}>
            <EventDayForm {...props}></EventDayForm>
        </FormModal>
    );
}

export default EventDayModal;
