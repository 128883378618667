import {ButtonGroupField} from "./form/ButtonGroupField";
import * as React from "react";
import {FormikProps} from "formik";
import {useMemo} from "react";
import {createOptions, OptionValue} from "../model/form";
import {useAppSelector} from "../store";
import {CodebookState} from "../store/codebooks";
import {selectCodebooks} from "../store/selectors";
import {dateToGuiAs} from "../helpers/date";

interface EventDaysFilterProps {
    formProps: FormikProps<{ eventId?: number }>
}

export const EventDaysFilter = ({formProps: {submitForm, values: {eventId}}}: EventDaysFilterProps) => {

    const codebooks = useAppSelector<CodebookState>(selectCodebooks);

    const options: OptionValue[] = useMemo(() => {
        if (!codebooks) {
            return [];
        }
        return createOptions({codebookName: 'eventDay', scope: eventId, asNumber: true, sortByKeys: true}, codebooks)
            .map((o) => ({...o,
                label: dateToGuiAs(o.params?.dayDate as any as string, 'd') || ('#' + o.label),
                tooltip: o.tooltip + ' (#' + o.label + ')'
            }))
    }, [codebooks, eventId]);

    return <ButtonGroupField name="eventDay" label={'Den akce'} onChange={submitForm} maxPerRow={7} fixedHeight={30} options={options} fullWidth/>
}
