import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonInviteReplyInfo, GetInviteReplyCountUsingGETRequest, GetInviteReplyListUsingGETRequest, InviteReplyControllerApi, JsonInviteReply, JsonInviteReplyInfo,} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const inviteRepliesApi = new InviteReplyControllerApi(API_CONFIG);

export const fetchInviteReplies = createAsyncThunk('inviteReplies/list', async (filter: GetInviteReplyListUsingGETRequest, thunkApi) => {
    return {...await inviteRepliesApi.getInviteReplyListUsingGET(filter), filter};
});

export const fetchInviteRepliesCount = createAsyncThunk('inviteReplies/count', async (params: GetInviteReplyCountUsingGETRequest = {}) => {
    return await inviteRepliesApi.getInviteReplyCountUsingGET(params);
});

export const fetchInviteReplyInfo = createAsyncThunk('inviteReply/info', async (replyId: number): Promise<ApiResultOfJsonInviteReplyInfo> => {
    return await inviteRepliesApi.getInviteReplyInfoUsingGET({replyId});
});

export const fetchInviteReply = createAsyncThunk('inviteReply/fetch', async (replyId: number) => {
    return await inviteRepliesApi.getInviteReplyUsingGET({replyId});
});

export const saveInviteReply = createAsyncThunk('inviteReply/save', async (inviteReply: JsonInviteReply) => {
    return await inviteRepliesApi.saveInviteReplyUsingPOST({json: inviteReply});
});

export const inviteRepliesSlice = createSlice({
    name: 'inviteReplies',
    initialState: (): ItemsState<JsonInviteReplyInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchInviteReplies, fetchInviteRepliesCount);
    }
});

export const inviteRepliesReducer = inviteRepliesSlice.reducer;
