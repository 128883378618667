import {JsonEventPartyInfo, JsonInviteInfo, JsonInviteReplyExtra} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import {Button, Card, CardContent, FormHelperText, Grid} from "@mui/material";
import CodebookValue from "../CodebookValue";
import {DeleteRounded, EditRounded} from "@mui/icons-material";
import PartyPhoto from "../party/PartyPhoto";
import * as React from "react";
import {FAKE_VALUE_EMPTY} from "../../model/form";

type RsvpInvitePartyBoxProps = {
    extra: JsonInviteReplyExtra | JsonInviteInfo | JsonEventPartyInfo,
    onDelete?: (extra: JsonInviteReplyExtra) => void,
    onEdit?: (extra: JsonInviteReplyExtra) => void,
    onSelect?: (extra: JsonInviteReplyExtra) => void,
    editTitle?: string,
    deleteTitle?: string,
    showStatus?: boolean
    error?: string
}

const RsvpInvitePartyBox = (props: RsvpInvitePartyBoxProps) => {
    const {extra, onDelete, onEdit, onSelect,
        editTitle, deleteTitle, showStatus, error} = props;

    const t = useAppTranslation();

    return <Card className={'rsvp-extra-match-box'}>
        <CardContent>
            <Grid container columnSpacing={2}>
                <Grid item xs={7}>
                    <Grid container columnSpacing={4} rowSpacing={1} sx={{height: '100%'}}>
                        <Grid item xs={12}>
                            <h6>
                                {extra.firstName}
                                <br/>
                                {extra.lastName}
                            </h6>
                            <CodebookValue value={extra.sex} name={'sex'} formatValue={(v) => v[0]}/>
                        </Grid>
                        {!!extra.partyId && showStatus && <Grid item xs={12} sx={{margin: '-5px 0'}}>
							<small style={{color: extra.partyId === FAKE_VALUE_EMPTY ? 'var(--color-warning)' : 'var(--color-info)'}}>
                                {extra.partyId === FAKE_VALUE_EMPTY ? t('Nová osoba') : t('Existující osoba')}</small>
						</Grid>}
                        {!!onDelete && <Grid item xs={4}>
							<Button title={deleteTitle || t('Odstranit doprovod z tohoto dne')} variant={'contained'} size={'small'}
								color={'inherit'}
								onClick={() => {
                                    onDelete(extra as JsonInviteReplyExtra);
                                }}>
								<DeleteRounded/>
							</Button>
						</Grid>}
                        {!!onEdit && <Grid item xs={4}>
							<Button title={editTitle || t('Upravit doprovod')} variant={'contained'} size={'small'}
								color={'secondary'}
								onClick={() => {
                                    onEdit(extra as JsonInviteReplyExtra);
                                }}>
								<EditRounded/>
							</Button>
						</Grid>}
                        {!!onSelect && <Grid item xs={12} sx={{flexGrow: 1}}>
							<Button title={t('Použít tento doprovod')} variant={'contained'} size={'small'}
								color={'secondary'}
								onClick={() => {
                                    onSelect(extra as JsonInviteReplyExtra);
                                }}>
								<span>{t('Použít tuto osobu')}</span>
							</Button>
						</Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={5} className={'rsvp-extra-match-box-photo'}>
                    <PartyPhoto photoGuid={extra.photoGuid}/>
                </Grid>
            </Grid>
            {!!error && <FormHelperText sx={{color: 'var(--color-error)'}}>{error}</FormHelperText>}
        </CardContent>
    </Card>
}

export default RsvpInvitePartyBox;
