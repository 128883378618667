/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonArticleType,
    ApiResultOfJsonArticleTypeFromJSON,
    ApiResultOfJsonArticleTypeToJSON,
    ApiResultOfJsonArticleTypeInfo,
    ApiResultOfJsonArticleTypeInfoFromJSON,
    ApiResultOfJsonArticleTypeInfoToJSON,
    ApiResultOfListOfJsonArticleTypeInfo,
    ApiResultOfListOfJsonArticleTypeInfoFromJSON,
    ApiResultOfListOfJsonArticleTypeInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonArticleType,
    JsonArticleTypeFromJSON,
    JsonArticleTypeToJSON,
} from '../models';

export interface GetArticleTypeCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetArticleTypeInfoUsingGETRequest {
    articleTypeId: number;
}

export interface GetArticleTypeListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetArticleTypeUsingGETRequest {
    articleTypeId: number;
}

export interface SaveArticleTypeUsingPOSTRequest {
    json: JsonArticleType;
    testOnly?: boolean;
}

/**
 * 
 */
export class ArticleTypeControllerApi extends runtime.BaseAPI {

    /**
     * getArticleTypeCount
     */
    async getArticleTypeCountUsingGETRaw(requestParameters: GetArticleTypeCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/articleType/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getArticleTypeCount
     */
    async getArticleTypeCountUsingGET(requestParameters: GetArticleTypeCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getArticleTypeCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleTypeInfo
     */
    async getArticleTypeInfoUsingGETRaw(requestParameters: GetArticleTypeInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonArticleTypeInfo>> {
        if (requestParameters.articleTypeId === null || requestParameters.articleTypeId === undefined) {
            throw new runtime.RequiredError('articleTypeId','Required parameter requestParameters.articleTypeId was null or undefined when calling getArticleTypeInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/articleType/info/{articleTypeId}`.replace(`{${"articleTypeId"}}`, encodeURIComponent(String(requestParameters.articleTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonArticleTypeInfoFromJSON(jsonValue));
    }

    /**
     * getArticleTypeInfo
     */
    async getArticleTypeInfoUsingGET(requestParameters: GetArticleTypeInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonArticleTypeInfo> {
        const response = await this.getArticleTypeInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleTypeList
     */
    async getArticleTypeListUsingGETRaw(requestParameters: GetArticleTypeListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonArticleTypeInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/articleType/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonArticleTypeInfoFromJSON(jsonValue));
    }

    /**
     * getArticleTypeList
     */
    async getArticleTypeListUsingGET(requestParameters: GetArticleTypeListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonArticleTypeInfo> {
        const response = await this.getArticleTypeListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleType
     */
    async getArticleTypeUsingGETRaw(requestParameters: GetArticleTypeUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonArticleType>> {
        if (requestParameters.articleTypeId === null || requestParameters.articleTypeId === undefined) {
            throw new runtime.RequiredError('articleTypeId','Required parameter requestParameters.articleTypeId was null or undefined when calling getArticleTypeUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/articleType/{articleTypeId}`.replace(`{${"articleTypeId"}}`, encodeURIComponent(String(requestParameters.articleTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonArticleTypeFromJSON(jsonValue));
    }

    /**
     * getArticleType
     */
    async getArticleTypeUsingGET(requestParameters: GetArticleTypeUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonArticleType> {
        const response = await this.getArticleTypeUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveArticleType
     */
    async saveArticleTypeUsingPOSTRaw(requestParameters: SaveArticleTypeUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonArticleType>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveArticleTypeUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/articleType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonArticleTypeToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonArticleTypeFromJSON(jsonValue));
    }

    /**
     * saveArticleType
     */
    async saveArticleTypeUsingPOST(requestParameters: SaveArticleTypeUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonArticleType> {
        const response = await this.saveArticleTypeUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
