/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventFloorDay,
    ApiResultOfJsonEventFloorDayFromJSON,
    ApiResultOfJsonEventFloorDayToJSON,
    ApiResultOfJsonEventFloorDayInfo,
    ApiResultOfJsonEventFloorDayInfoFromJSON,
    ApiResultOfJsonEventFloorDayInfoToJSON,
    ApiResultOfListOfJsonEventFloorDayInfo,
    ApiResultOfListOfJsonEventFloorDayInfoFromJSON,
    ApiResultOfListOfJsonEventFloorDayInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventFloorDay,
    JsonEventFloorDayFromJSON,
    JsonEventFloorDayToJSON,
} from '../models';

export interface GetEventFloorDayCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetEventFloorDayInfoUsingGETRequest {
    eventFloorDayId: number;
}

export interface GetEventFloorDayListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventFloorDayUsingGETRequest {
    eventFloorDayId: number;
}

export interface SaveEventFloorDayUsingPOSTRequest {
    json: JsonEventFloorDay;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventFloorDayControllerApi extends runtime.BaseAPI {

    /**
     * getEventFloorDayCount
     */
    async getEventFloorDayCountUsingGETRaw(requestParameters: GetEventFloorDayCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor-day/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventFloorDayCount
     */
    async getEventFloorDayCountUsingGET(requestParameters: GetEventFloorDayCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventFloorDayCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventFloorDayInfo
     */
    async getEventFloorDayInfoUsingGETRaw(requestParameters: GetEventFloorDayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventFloorDayInfo>> {
        if (requestParameters.eventFloorDayId === null || requestParameters.eventFloorDayId === undefined) {
            throw new runtime.RequiredError('eventFloorDayId','Required parameter requestParameters.eventFloorDayId was null or undefined when calling getEventFloorDayInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor-day/info/{eventFloorDayId}`.replace(`{${"eventFloorDayId"}}`, encodeURIComponent(String(requestParameters.eventFloorDayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFloorDayInfoFromJSON(jsonValue));
    }

    /**
     * getEventFloorDayInfo
     */
    async getEventFloorDayInfoUsingGET(requestParameters: GetEventFloorDayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventFloorDayInfo> {
        const response = await this.getEventFloorDayInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventFloorDayList
     */
    async getEventFloorDayListUsingGETRaw(requestParameters: GetEventFloorDayListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventFloorDayInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor-day/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventFloorDayInfoFromJSON(jsonValue));
    }

    /**
     * getEventFloorDayList
     */
    async getEventFloorDayListUsingGET(requestParameters: GetEventFloorDayListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventFloorDayInfo> {
        const response = await this.getEventFloorDayListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventFloorDay
     */
    async getEventFloorDayUsingGETRaw(requestParameters: GetEventFloorDayUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventFloorDay>> {
        if (requestParameters.eventFloorDayId === null || requestParameters.eventFloorDayId === undefined) {
            throw new runtime.RequiredError('eventFloorDayId','Required parameter requestParameters.eventFloorDayId was null or undefined when calling getEventFloorDayUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor-day/{eventFloorDayId}`.replace(`{${"eventFloorDayId"}}`, encodeURIComponent(String(requestParameters.eventFloorDayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFloorDayFromJSON(jsonValue));
    }

    /**
     * getEventFloorDay
     */
    async getEventFloorDayUsingGET(requestParameters: GetEventFloorDayUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventFloorDay> {
        const response = await this.getEventFloorDayUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventFloorDay
     */
    async saveEventFloorDayUsingPOSTRaw(requestParameters: SaveEventFloorDayUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventFloorDay>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventFloorDayUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-floor-day`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventFloorDayToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFloorDayFromJSON(jsonValue));
    }

    /**
     * saveEventFloorDay
     */
    async saveEventFloorDayUsingPOST(requestParameters: SaveEventFloorDayUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventFloorDay> {
        const response = await this.saveEventFloorDayUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
