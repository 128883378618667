/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonTariff
 */
export interface JsonTariff {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonTariff
     */
    allowedActions?: Array<JsonTariffAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonTariff
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonTariff
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonTariff
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariff
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTariff
     */
    tariffId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTariff
     */
    tariffType?: JsonTariffTariffTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonTariff
     */
    unitRate?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTariff
     */
    unitTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonTariff
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonTariff
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonTariffAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonTariffTariffTypeEnum {
    Reward = 'REWARD'
}

export function JsonTariffFromJSON(json: any): JsonTariff {
    return JsonTariffFromJSONTyped(json, false);
}

export function JsonTariffFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonTariff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'tariffId': !exists(json, 'tariffId') ? undefined : json['tariffId'],
        'tariffType': !exists(json, 'tariffType') ? undefined : json['tariffType'],
        'unitRate': !exists(json, 'unitRate') ? undefined : json['unitRate'],
        'unitTitle': !exists(json, 'unitTitle') ? undefined : json['unitTitle'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonTariffToJSON(value?: JsonTariff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'tariffId': value.tariffId,
        'tariffType': value.tariffType,
        'unitRate': value.unitRate,
        'unitTitle': value.unitTitle,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

