import {useAppSelector} from "../../store";
import {selectCodebooks} from "../../store/selectors";
import {JsonPoplistItem} from "../../generated-api";
import {dateToGuiAs} from "../../helpers/date";

const EventDay = ({dayNo, eventId}: {dayNo: number, eventId: number | undefined}) => {
    const eventDayCodebook = useAppSelector(selectCodebooks)?.['eventDay'];
    const item = eventDayCodebook?.find((item: JsonPoplistItem) => item.value === eventId + ':' + dayNo);
    if (!item?.params?.dayDate ) {
        return <span className={'event-day'}>#{dayNo}</span>;
    }

    return <span className={'event-day'} title={dateToGuiAs(item.params.dayDate as any as string, "eeeee d.M.") + ' (#' + dayNo + ')'}>
        {dateToGuiAs(item.params?.dayDate as any as string, "d")}
    </span>;
}

export default EventDay;
