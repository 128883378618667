import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridFilterProps, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {GetArticleListUsingGETRequest, JsonArticle, JsonArticleInfo, JsonArticleInfoSexEnum, JsonArticleType} from "../../generated-api";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {selectArticles, selectArticleTypes} from "../../store/selectors";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {fetchArticles, saveArticles} from "../../store/articles";
import {OptionValue} from "../../model/form";
import {Button} from "@mui/material";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {fetchArticleTypes} from "../../store/articleTypes";
import {useAppTranslation} from "../../services/i18n";
import EventArticlesModal from "../../pages/EventArticlesModal";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {articleSize, shorten} from "../../helpers/format";

interface LocalFilter {
    articleTypeIds: number[]
}

interface EventArticlesGridFilter extends DataGridFilter, GetArticleListUsingGETRequest, LocalFilter {
}

interface EventArticlesListGridState extends DataGridState<JsonArticleInfo, EventArticlesGridFilter> {
}

const renderZeroAsEmpty = (v: number | undefined) => v || '';

const cols: DataGridCol<JsonArticleInfo>[] = [
    createCol('Název', 'title', 80, 'Název artiklu'),
    createCol('P', 'sex', 40, 'Pohlaví (U = unisex)'),
    createCol('V', 'size', 40, 'Velikost', (v, item) => {
        return articleSize(item.sex, v, true);
    }),
    createCol('Sklad', 'purchasedCnt', '300C', undefined, undefined, [
        createCol('Nákup', 'purchasedCnt', '100C', 'Celkem nakoupeno', renderZeroAsEmpty),
        createCol('Vydáno', 'recCnt', '100C', 'Celkem vydáno', renderZeroAsEmpty),
        createCol('Zbývá', 'recRemainCnt', '100C', 'Zbývá na skladě', renderZeroAsEmpty),
    ]),
    createCol('Preference', 'prefCnt', '200C', undefined, undefined, [
        createCol('Zadáno', 'prefCnt', '100C', 'Celkem preferováno', renderZeroAsEmpty),
        createCol('K vydání', 'prefRemainCnt', '100C', 'Celkem preferovaných, zatím nevydaných', renderZeroAsEmpty),
    ]),
    {
        title: 'Rozdíl',
        size: 100,
        align: 'center',
        tooltip: 'Rozdíl mezi "Celkem na skladě" a "K vydání", tj. delta poptávky/nabídky',
        col: 'prefRemainCnt',
        renderValue: (v, item) => {
            return renderZeroAsEmpty((item.recRemainCnt || 0) - (item.prefRemainCnt || 0))
        }
    }
];

const defaultState: EventArticlesListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: undefined,
        articleTypeIds: []
    },
    filterCallback: (filter, item): boolean => {
        if (filter.articleTypeIds.length > 0 && !(filter.articleTypeIds.indexOf(item.articleTypeId || 0) >= 0)) {
            return false;
        }
        return true;
    }
};

const filterFields = (props: DataGridFilterProps<EventArticlesGridFilter>, articleTypes: JsonArticleInfo[]): JSX.Element => {
    const {formProps} = props;
    const options = articleTypes?.length > 0
        ? articleTypes.map((at) => ({
            label: shorten(at.title, 3),
            value: at.articleTypeId
        } as OptionValue))
        : [];

    return <ButtonGroupField name={'articleTypeIds'} label={'Typ artiklu'} onChange={formProps.submitForm} options={options} isMulti={true}/>;
}

const EventArticlesList = (props: { eventId: number, maxHeight?: string }) => {
    const {eventId, maxHeight} = props;
    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const eventArticles = useAppSelector(selectArticles);
    const eventArticleTypes = useAppSelector(selectArticleTypes);

    const [editArticles, setEditArticles] = useState<JsonArticle[] | undefined>(undefined);
    const eventArticleItems = eventArticles.items;

    const handleFetchArticles = useCallback(() => {
        dispatch(fetchArticles({eventId}));
        dispatch(fetchArticleTypes({eventId}));
    }, [eventId, dispatch]);

    const handleEditArticles = useCallback(() => {
        setEditArticles(eventArticleItems); // compatible
    }, [eventArticleItems]);

    const handleSaveArticles = useCallback(async (items: JsonArticle[]) => {
        const res = await dispatch(saveArticles(items));
        const data = getApiResult<JsonArticle[]>(res);
        if (data) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: 'Sklad ponechán beze změn',
                [ApiChangeType.UPDATED]: 'Sklad úspěšně upraven',
                [ApiChangeType.CREATED]: 'Sklad úspěšně uložen'
            }, t, dispatch);
            setEditArticles(undefined);
            handleFetchArticles();
        }

    }, [handleFetchArticles, dispatch, t])

    const handleCancelArticles = useCallback(() => {
        setEditArticles(undefined);
    }, [])

    useEffect(() => {
        handleFetchArticles();
    }, [handleFetchArticles]);

    const itemsState = {
        loading: eventArticles.loading || eventArticleTypes.loading,
        count: eventArticles.count,
        items: []
    } as ItemsState<JsonArticleInfo, EventArticlesGridFilter>;

    if (!itemsState.loading
        && eventArticleTypes.items
        && eventArticleTypes.items.length > 0) {
        eventArticleTypes.items.forEach((at: JsonArticleType) => {
            if (!at.variants?.sizes) {
                return;
            }
            Object.values(JsonArticleInfoSexEnum).forEach((sex) => {
                at.variants?.sizes?.[sex]?.forEach((size) => {
                    if (!at.variants?.sizes || !at.variants.sizes[sex] || !(at.variants.sizes[sex].indexOf(size) >= 0)) {
                        return;
                    }
                    const it = eventArticles.items?.find((a: JsonArticleInfo) => a.articleTypeId === at.articleTypeId && a.size === size && a.sex === sex);
                    if (it) {
                        itemsState.items.push(it);
                    } else {
                        itemsState.items.push({
                            articleTypeId: at.articleTypeId,
                            title: at.title,
                            sex: sex as JsonArticleInfoSexEnum,
                            size: size
                        });
                    }
                })
            })
        });
    }

    return <>
        <div className={'info-box-actions'}>
            <Button variant={'contained'} size={'small'} color={'primary'}
                title={t('Upravit sklad')}
                disabled={!(eventArticleTypes.items && eventArticleTypes.items.length > 0)}
                onClick={() => handleEditArticles()}>{t('Sklad')}</Button>
        </div>
        <DataGrid
            cols={cols}
            defaultState={defaultState}
            itemsState={itemsState}
            mode={DataGridMode.CLIENT}
            filterFields={(props) => filterFields(props, eventArticleTypes.items)}
            tableProps={{maxHeight}}
        />
        {editArticles && <EventArticlesModal
			item={editArticles}
			onCancel={handleCancelArticles}
			onSave={handleSaveArticles}
		/>}
    </>
}

export default EventArticlesList;
