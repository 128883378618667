import {Box, Button, FormControl, FormHelperText, Grid} from '@mui/material';
import {FormikErrors, useField, useFormikContext} from 'formik';
import {FormFieldProps, FormProps} from '../../model/form';
import * as React from "react";
import {createRef, useCallback, useState} from "react";
import {TAppFunction, useAppTranslation} from "../../services/i18n";
import {getLink} from "../../helpers/files";
import placeholder from "../../assets/images/placeholder.png";
import {JsonFile, JsonFileStatusEnum} from "../../generated-api";
import FormModal from "./FormModal";
import FormContainer from "./FormContainer";
import {DropzoneArea, readFile} from "mui-file-dropzone";
import {Delete} from "@mui/icons-material";
import {ModalProps, useModal} from "../../services/modal";
import {savePhoto} from "../../store/files";
import {getApiResult} from "../../helpers/api";
import {useAppDispatch} from "../../store";

type ImgInfo = {
    width: number,
    height: number
} | undefined;

type FileValue = {
    file?: File,
    title?: string,
    guid?: string
}

interface Props extends FormFieldProps {
    title?: string,
    defaultValue?: string,
    onRequestChange?: (file: File) => Promise<JsonFile | undefined>,
    isInternal?: boolean,
    maxHeight?: number,
    maxWidth?: number
}

const MIN_HEIGHT = 400;
const MIN_WIDTH = 300;

type FileData = string | ArrayBuffer | null | undefined;
// gnnvmtopkxqpmgwb
const validate = (values: FileValue, imgInfo: ImgInfo, t: TAppFunction) => {
    let errors = {} as FormikErrors<FileValue>;
    if (!values.file) {
        errors.file = t('Zvolte prosím nový soubor');
    } else if (imgInfo && (imgInfo.height < MIN_HEIGHT || imgInfo.width < MIN_WIDTH)) {
        errors.file = t('Zvolte prosím obrázek o rozměru minimálně ' + MIN_WIDTH + ' x ' + MIN_HEIGHT);
    }

    return errors;
}

const PhotoModal = (props: FormProps<FileValue> & { isInternal?: boolean }) => {
    const {item, isInternal} = props;

    const t = useAppTranslation();
    const [fileData, setFileData] = useState<FileData>(undefined);
    const [imgInfo, setImgInfo] = useState<ImgInfo>(undefined);
    const imgEl = createRef<HTMLImageElement>();

    return <FormModal title={(item?.guid
        ? t('Aktualizace fotografie')
        : t('Nahrání fotografie')) + ' - ' + item?.title} {...props}>
        <FormContainer {...props}
            validate={(v) => validate(v, imgInfo, t)}
            saveButtonTitle={item?.guid
                ? t('Aktualizovat fotografii')
                : t('Nahrát fotografii')}
            children={(formikProps) => {
                const {values, errors, submitCount} = formikProps;
                const showError = !!errors.file && (!!fileData || submitCount > 0);
                return <>
                    <Grid item xs={12}>
                        <p>
                            {t('Fotografie bude sloužit k akreditaci a měla by proto splňovat běžné podmínky dokladové fotografie, tj. měla by zobrazovat horní část ramen a obličej v neutrálním výrazu na jednobarevném kontrastním pozadí.')}
                        </p>
                        {!isInternal && <p style={{marginTop: "10px"}}>
                            {t('Nahrávejte prosím fotografii s dostatečným odstupem obličeje od okrajů, systém provede oříznutí na požadovaný formát automaticky.')}
						</p>}
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                        <Box
                            ref={imgEl}
                            sx={{maxWidth: 220, maxHeight: 300, padding: '5px', border: '1px solid #eee'}}
                            component="img"
                            alt={item?.title}
                            src={fileData ? fileData as string : (values.guid ? getLink(values.guid) : placeholder)}
                            onLoad={() => setImgInfo(imgEl.current ? {
                                height: imgEl.current.naturalHeight,
                                width: imgEl.current.naturalWidth,
                            } : undefined)}
                        />
                        {imgInfo && !!fileData && <small style={{display: 'block'}}>{imgInfo.width + ' x ' + imgInfo.height}</small>}
                    </Grid>
                    <Grid item xs={12} sx={{position: 'relative'}}>
                        <FormControl error={showError} fullWidth>
                            <DropzoneArea
                                clearOnUnmount={true}
                                filesLimit={1}
                                acceptedFiles={["image/jpeg", "image/jpg"]}
                                maxFileSize={5 * 1024 * 1024}
                                dropzoneText={t('Klikněte zde pro vložení nové fotografie nebo ji sem přetáhněte')}
                                disableRejectionFeedback={true}
                                getDropRejectMessage={(f, a, maxSize) =>
                                    t('Soubor musí být obrázek (JPG) o maximální velikosti {{maxSize}} MB', {maxSize: Math.round(maxSize / 1024 / 1024)})}
                                getFileLimitExceedMessage={() => t('Nahrajte pouze jeden soubor')}
                                dropzoneClass={'dropzone-area' + (!!values.file ? ' dropzone-area-filled' : '')}
                                showPreviewsInDropzone={false}
                                showAlerts={['error']}
                                alertSnackbarProps={{autoHideDuration: null}}
                                onChange={async (files) => {
                                    setFileData(files[0] ? await readFile(files[0]) : undefined)
                                    setTimeout(() => {
                                        formikProps.setFieldValue('file', files[0], true)
                                    }, 1);
                                }}
                                onDelete={() => {
                                    setFileData(undefined);
                                    setTimeout(() => {
                                        formikProps.setFieldValue('file', undefined, true);
                                    }, 1);
                                }}
                                fileObjects={undefined}
                            />
                            {values.file && <p className={'dropzone-file-list'}>
								<i style={{alignSelf: 'center', flexGrow: 1}}>{values.file.name} ({Math.round(values.file.size / 1024)} kB)</i>
								<Button color={'warning'} size={'small'} variant={'contained'}
									sx={{minWidth: 'unset'}}
									title={t('Použít jiný soubor')}
									onClick={() => {
                                        setFileData(undefined);
                                        setTimeout(() => {
                                            formikProps.setFieldValue('file', undefined, true)
                                        }, 1);
                                    }}
								><Delete/></Button>
							</p>}
                            {showError && !!errors.file && <FormHelperText sx={
                                {paddingTop: "10px", textAlign: "center", fontSize: "100%"}
                            }>{errors.file as string}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </>
            }}/>
    </FormModal>;
}

export const PhotoFormField = (props: Props) => {

    const {name, title, onChange, defaultValue, onRequestChange, isInternal, maxWidth, maxHeight, readonly} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [field, meta, helpers] = useField(name);
    const modal = useModal();
    const {submitCount} = useFormikContext();
    const showError = !!meta.error && submitCount > 0;

    const [photoModal, setPhotoModal] = useState(false);

    const onFileUpload = useCallback(async (file: File) => {
        if (onRequestChange) {
            return await onRequestChange(file); // rsvp
        }
        const fileRes = await dispatch(savePhoto({photo: file}));
        return getApiResult<JsonFile>(fileRes);

    }, [onRequestChange, dispatch]);

    return <FormControl error={showError} fullWidth sx={{display: 'block', textAlign: 'center'}}>
        <Box
            component="img"
            sx={{maxWidth, maxHeight}}
            alt={title && t(title)}
            src={field.value || defaultValue ? getLink(field.value || defaultValue) : placeholder}
        />
        <div className={'rsvp-photo-buttons'}>
            {!!defaultValue && !!field.value && <Button variant="contained" color={'warning'} size={'small'}
				onClick={() => {
                    helpers.setValue(undefined, true);
                    if (onChange) {
                        onChange(undefined);
                    }
                }}
			>{t('Vrátit původní')}</Button>}
            {!readonly && <Button variant="contained" color={showError ? 'error' : 'secondary'} size={'small'}
                onClick={() => setPhotoModal(true)}
                fullWidth={!(!!defaultValue && !!field.value)}
            >{field.value || defaultValue
                ? t(defaultValue ? (field.value ? 'Upravit' : (isInternal ? 'Změnit foto' : 'Aktualizovat fotografii')) : (isInternal ? 'Upravit foto' : 'Upravit fotografii'))
                : t(isInternal ? 'Nahrát foto' : 'Nahrát fotografii')}</Button>}
        </div>
        <input name={field.name} value={field.value || ''} style={{display: 'none'}} readOnly/>
        {showError && meta.error && <FormHelperText sx={{paddingTop: "10px", textAlign: "center"}}>{meta.error}</FormHelperText>}
        {photoModal && <PhotoModal
			isInternal={isInternal}
			item={{
                guid: field.value || defaultValue,
                title,
                file: undefined
            }}
			onCancel={() => setPhotoModal(false)}
			onSave={async (values) => {
                if (!values.file) {
                    return;
                }
                const res = await onFileUpload(values.file);
                if (!res?.guid) {
                    return;
                }

                let use = false;
                if (res?.status === JsonFileStatusEnum.PendingOk || (res.cvResult && res.cvResult.indexOf('GENERAL_ERROR') >= 0)) {
                    use = true;

                } else {
                    let reason = t('rozměry, formátem nebo kvalitou');
                    if (res.cvResult) {
                        if (res.cvResult.indexOf('SMALL_FACE') >= 0) {
                            reason = t('obličej je příliš malý pro tisk na průkaz')
                        } else if (res.cvResult.indexOf('MULTI_FACE') >= 0) {
                            reason = t('na fotografii je více obličejů')
                        } else if (res.cvResult.indexOf('NO_FACE') >= 0) {
                            reason = t('na fotografii není obličej')
                        }
                    }

                    const result = await modal.confirm({
                        title: t('Fotografie neprošla kontrolou'),
                        message: t('Dle automatizované kontroly neodpovídá fotografie požadavkům ({{reason}}). Fotografii můžete přesto zkusit použít (kontrola může chybovat), nebo nahrajte jinou.', {reason}),
                        confirmColor: 'warning',
                        confirmText: t('Přesto fotografii použít'),
                        cancelText: t('Vybrat jinou fotografii')
                    } as ModalProps);
                    if (result === 'CONFIRM') {
                        use = true;
                    }
                }
                if (use) {
                    setPhotoModal(false);
                    helpers.setValue(res.guid, true);
                    if (onChange) {
                        onChange(res);
                    }
                }
            }}/>}
    </FormControl>
}
