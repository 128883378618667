import {Button, Grid} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {FormProps} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import * as React from "react";
import {useCallback} from "react";
import FormModal from "../form/FormModal";

export interface RsvpInviteExtraMultiFormValue {
    body: string;
}

export interface RsvpInviteExtraMultiFormProps extends FormProps<RsvpInviteExtraMultiFormValue> {
    token: string,
    eventId: number,
}

const RsvpInviteExtraMultiForm = (props: RsvpInviteExtraMultiFormProps) => {
    const {onSave} = props;

    const t = useAppTranslation();

    const validate = useCallback((values: RsvpInviteExtraMultiFormValue) => {
        let errors = {} as FormikErrors<RsvpInviteExtraMultiFormValue>;
        if (!values.body) {
            errors.body = t('Zadejte osoby');
        } else {
            let count = 0;
            const lines = values.body.split("\n");
            lines.forEach((line) => {
                if (!line?.trim()) {
                    return;
                }
                count++;
            });
            if (!count) {
                errors.body = t('Zadejte osoby');
            }
        }
        return errors;
    }, [t]);

    const actions = useCallback(({values, setFieldValue, isSubmitting, setFormikState}: FormikProps<RsvpInviteExtraMultiFormValue>, props: FormProps<RsvpInviteExtraMultiFormValue>) => {
        const isSubmittable = !!values.body;
        return <>
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
			</Grid>}
            <Grid item>
                <Button variant="contained" type="submit" color={'success'} disabled={!isSubmittable || isSubmitting}>
                    {t('Zahájit přidávání')}
                </Button>
            </Grid>
        </>
    }, [t]);

    return <FormModal title={t('Hromadné přidání dalších osob')} {...props} maxWidth={'md'}>
        <FormContainer {...props}
            actions={actions}
            onSave={(values) => {
                if (onSave) {
                    return onSave(values);
                }
            }}
            validate={validate} children={() => {

            return <Grid item xs={12}>
                <p>{t('Do pole níže vložte požadované osoby jako "Jméno Příjmení Pohlaví" (M / Ž), každou na nový řádek (např. zkopírováním z Excelu).')}</p>
                <p>{t('Následně bude pro každou osobu postupně otevřen formulář pro spárování či založení (s vložením fotografie).')}</p>
                <p>{t('Zadávání je možné kdykoli přerušit, systém automaticky odmaže již použité řádky.')}</p>
                <p>{t('Duplicitní řádky budou přeskočeny (pro dvě osoby se stejným jménem použijte zadání samostatným formulářem).')}</p>
                <TextFormField name="body" type={'textarea'} minRows={10} maxRows={30} maxlength={100000}
                    placeholder={"Jan Novák M\nJana Nováková Ž\nPaul van Dyk M\n..."}/>
            </Grid>
        }}/>
    </FormModal>;
}

export default RsvpInviteExtraMultiForm;
