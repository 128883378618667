import {
    JsonEventPartyInfoStatusEnum,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteInfoStatusEnum,
    JsonInviteReply,
    JsonInviteReplyDay,
    JsonInviteReplyDayReplyTypeEnum,
    JsonInviteReplyReplyTypeEnum,
    JsonRsvp
} from "../../generated-api";
import {TAppFunction, useAppTranslation} from "../../services/i18n";
import {ModalProps, useModal} from "../../services/modal";
import {FIXED_VIP_GROUP_ID} from "../../store/codebooks";
import * as React from "react";
import {useCallback, useMemo} from "react";
import {Formik, FormikErrors, FormikHelpers} from "formik";
import {Telegram} from "@mui/icons-material";
import RsvpInviteSummary from "./RsvpInviteSummary";
import RsvpInviteStatusInfo from "./RsvpInviteStatusInfo";
import RsvpInviteForm from "./RsvpInviteForm";
import {FAKE_VALUE_EMPTY, setNestedKey} from "../../model/form";
import {isAccountValid, isCityValid, isPhoneValid, isStreetValid, isZipValid} from "../../helpers/validators";
import {dateToGuiAs} from "../../helpers/date";

const validate = (values: JsonInviteReply, rsvp: JsonRsvp, t: TAppFunction) => {
    const errors = {} as FormikErrors<JsonInviteReply>;

    if (rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer || rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.Guest) {
        if (!values.replyType) {
            errors.replyType = t('Zvolte prosím možnost'); // should not happen
            return errors;
        }
        if (values.replyType === JsonInviteReplyReplyTypeEnum.Reject) {
            return errors;
        }
    }

    if (rsvp.invite?.inviteType !== JsonInviteInfoInviteTypeEnum.TravelOrg && rsvp.invite?.inviteType !== JsonInviteInfoInviteTypeEnum.CompOrg) {
        if (!(values.infoIsValid === true || values.infoIsValid === false)) {
            errors.infoIsValid = t('Potvrďte prosím, zda jsou údaje v pořádku');
        }
        if (!rsvp.invite?.photoGuid && !values.newPhotoGuid) {
            errors.newPhotoGuid = t('Nahrajte prosím svou fotografii');
        }
        if (values.infoIsValid === false) {
            if (!values.newLastName) {
                errors.newLastName = t('Uveďte prosím své příjmení');
            }
            if (!values.newFirstName) {
                errors.newFirstName = t('Uveďte prosím své jméno');
            }
        }
    }

    if (rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.Guest) {
        let isAnyDayAccepted = false;
        rsvp.eventDays?.forEach((ed) => {
            if (!ed.dayNo) {
                return;
            }
            const rd = values.replyData?.replyDays?.[ed.dayNo];
            const rdPrefix = 'replyData.replyDays.' + ed.dayNo;
            if (!rd?.replyType) {
                setNestedKey(errors, rdPrefix + '.replyType', t('Zvolte prosím, zda máte zájem se zúčastnit tohoto dne'));
            }
            if (rd?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept) {
                isAnyDayAccepted = true;

                if (!!rsvp.invite?.inviteData?.isPark && rd?.isParking === undefined) {
                    setNestedKey(errors, rdPrefix + '.isParking', t('Zvolte prosím, zda máte tento den zájem o parkování'));
                }
            }
        });
        if (!isAnyDayAccepted) {
            setNestedKey(errors, 'replyType', t('Není zvolen žádný den, kterého se chcete účastnit'));
        }
    }

    if (rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer || rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
        if (rsvp.invite?.inviteData?.articleTypes) {
            rsvp.invite.inviteData.articleTypes.forEach((at, i) => {
                const a = values.replyData?.articles?.find((a) => a.articleTypeId === at.articleTypeId);
                if (!a) {
                    setNestedKey(errors, 'replyData.articles.' + i + '.articleId', t('Zvolte prosím velikost'));
                }
            });
        }
    }
    if (rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer) {
        if (values.replyData?.isAcm === undefined) {
            setNestedKey(errors, 'replyData.isAcm', t('Zvolte prosím, zda požadujete zajištění ubytování'));
        }
    }

    if (rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer || rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
        const partyData = values.replyData?.partyData;
        if (!partyData?.bankAccount) {
            setNestedKey(errors, 'replyData.partyData.bankAccount', t('Uveďte prosím číslo bankovního účtu'));
        } else if (!isAccountValid(partyData.bankAccount)) {
            setNestedKey(errors, 'replyData.partyData.bankAccount', t('Číslo účtu není platné'));
        }
        if (!partyData?.birthDate) {
            setNestedKey(errors, 'replyData.partyData.birthDate', t('Uveďte prosím datum narození'));
        } else {
            const y = parseInt(dateToGuiAs(partyData.birthDate, 'Y'), 10);
            if (y < 1900 || y > 2020) {
                setNestedKey(errors, 'replyData.partyData.birthDate', t('Datum je mimo rozsah'));
            }
        }
        if (partyData?.phone && !isPhoneValid(partyData?.phone)) {
            setNestedKey(errors, 'replyData.partyData.phone', t('Číslo není ve správném formátu'));
        }

        const addr = partyData?.permAddress;
        if (!addr) {
            setNestedKey(errors, 'replyData.partyData.permAddress.street', t('Zadejte prosím adresu'));
        } else {
            if (!addr.street) {
                setNestedKey(errors, 'replyData.partyData.permAddress.street', t('Zadejte prosím ulici a číslo'));
            } else if (!isStreetValid(addr.street)) {
                setNestedKey(errors, 'replyData.partyData.permAddress.street', t('Ulice neodpovídá formátu'));
            }
            if (!addr.zip) {
                setNestedKey(errors, 'replyData.partyData.permAddress.zip', t('Zadejte prosím PSČ'));
            } else if (!isZipValid(addr.zip)) {
                setNestedKey(errors, 'replyData.partyData.permAddress.zip', t('Neplatné PSČ'));
            }
            if (!addr.city) {
                setNestedKey(errors, 'replyData.partyData.permAddress.city', t('Zadejte prosím obec'));
            } else if (!isCityValid(addr.city)) {
                setNestedKey(errors, 'replyData.partyData.permAddress.city', t('Obec neodpovídá formátu'));
            }
        }
    }

    if (rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.Supplier) {
        const extras = values.replyData?.extras;
        extras?.forEach((extra, i) => {
            if (!extra.partyId) {
                setNestedKey(errors, 'replyData.extras.' + i, t('Neúplně zadaná osoba'));
            } else if (extra.partyId !== FAKE_VALUE_EMPTY) {
                if (extras?.find((e) => e.extraId !== extra.extraId && e.partyId === extra.partyId)) {
                    setNestedKey(errors, 'replyData.extras.' + i, t('Osoba je zadána dvakrát'));
                } else if (!extra.photoGuid) {
                    setNestedKey(errors, 'replyData.extras.' + i, t('Doplňte prosím fotografii'));
                }
            } else if (!extra.photoGuid) {
                setNestedKey(errors, 'replyData.extras.' + i, t('Doplňte prosím fotografii'));
            }
        });
    }

    if (rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
        if (!values.replyType) {
            errors.replyType = t('Zvolte prosím možnost');
        } else if (values.replyType === JsonInviteReplyReplyTypeEnum.Reject) {
            if (!values.replyNote) {
                errors.replyNote = t('Uveďte prosím, v čem je návrh chybně');
            }
        }
        return errors;
    }

    if (!values.replyData?.isGdpr) {
        setNestedKey(errors, 'replyData.isGdpr', rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg
        || rsvp.invite?.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg
            ? t('Souhlas je pro zpracování údajů nezbytný')
            : t('Souhlas je pro zpracování pozvánky nezbytný'));
    }

    return errors;
}

const RsvpInvite = (props: {
    rsvp?: JsonRsvp,
    token: string,
    onSave: (values: JsonInviteReply, isSend: boolean) => any
}) => {
    const t = useAppTranslation();
    const modal = useModal();
    const {rsvp, token, onSave} = props;
    const {event, invite, eventDays, reply, eventParties, tariffs} = rsvp || {};
    const {inviteData} = invite || {};

    const owner = eventParties?.find((ep) => ep.partyId === invite?.partyId);
    const isAlreadyRegistered = owner?.status === JsonEventPartyInfoStatusEnum.Active || !!(owner && invite?.inviteType === JsonInviteInfoInviteTypeEnum.Supplier);
    const isDaysLocked = isAlreadyRegistered && !!owner.groupId && owner.groupId !== FIXED_VIP_GROUP_ID;

    const handleSubmit = useCallback(async (values: JsonInviteReply, actions: FormikHelpers<JsonInviteReply>) => {
        if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
            await modal.confirm({
                title: t('Shrnutí a odeslání aktualizovaných údajů'),
                message: <RsvpInviteSummary values={values} rsvp={{invite, eventDays}}/>,
                cancelText: 'Zpět do formuláře',
                confirmColor: 'success',
                confirmText: 'Odeslat údaje',
                confirmIcon: <Telegram/>,
                syncAction: () => onSave(values, true)
            } as ModalProps);

        } else if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg) {
            await modal.confirm({
                title: t('Shrnutí a odeslání cestovních náhrad'),
                message: <RsvpInviteSummary values={values} rsvp={{invite, eventDays}}/>,
                cancelText: 'Zpět do formuláře',
                confirmColor: 'success',
                confirmText: 'Odeslat údaje',
                confirmIcon: <Telegram/>,
                syncAction: () => onSave(values, true)
            } as ModalProps);

        } else if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
            await modal.confirm({
                title: t('Shrnutí a odeslání potvrzení výplaty'),
                message: <RsvpInviteSummary values={values} rsvp={{invite, eventDays, eventParties, tariffs}}/>,
                cancelText: 'Zpět do formuláře',
                confirmColor: 'success',
                confirmText: 'Odeslat',
                confirmIcon: <Telegram/>,
                syncAction: () => onSave(values, true)
            } as ModalProps);

        } else if (values.replyType === JsonInviteReplyReplyTypeEnum.Reject) {
            await modal.confirm({
                title: t('Potvrzení odmítnutí'),
                message: t('Skutečně si přejete odeslat odmítnutí a pozvánku uzavřít?'),
                cancelText: 'Zpět do pozvánky',
                confirmColor: 'error',
                confirmText: 'Ano, odeslat odmítnutí',
                confirmIcon: <Telegram/>,
                syncAction: () => onSave(values, true)
            } as ModalProps);

        } else if (invite && eventDays && await onSave(values, false)) {
            await modal.confirm({
                title: t('Shrnutí a odeslání potvrzení účasti'),
                message: <RsvpInviteSummary values={values} rsvp={{invite, eventDays}}/>,
                cancelText: 'Zpět do pozvánky',
                confirmColor: 'success',
                confirmText: 'Odeslat potvrzení účasti',
                confirmIcon: <Telegram/>,
                syncAction: () => onSave(values, true)
            } as ModalProps);
        }
        actions.setSubmitting(false);

    }, [invite, eventDays, eventParties, tariffs, onSave, modal, t]);

    const initialValues: JsonInviteReply = useMemo(() => {
        if (reply) {
            return reply;
        }

        if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer || invite?.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
            return {
                inviteId: invite?.inviteId,
                newFirstName: invite?.firstName,
                newLastName: invite?.lastName,
                replyType: isAlreadyRegistered && invite?.inviteType === JsonInviteInfoInviteTypeEnum.Organizer  ? JsonInviteReplyReplyTypeEnum.Accept : undefined,
                replyData: {
                    articles: owner?.eventArticleDetails?.filter((epa) => !!epa.prefArticleId)?.map((epa) => {
                        const a = invite.inviteData?.articles?.find((a) => a.articleId === epa.prefArticleId);
                        return {
                            articleTypeId: epa.articleTypeId,
                            articleId: epa.prefArticleId,
                            sex: a?.sex,
                            size: a?.size
                        }
                    }),
                    partyData: {
                        birthDate: owner?.birthDate,
                        bankAccount: owner?.bankAccount,
                        phone: owner?.phone,
                        permAddress: {...owner?.permAddress, country: owner?.permAddress?.country || 'CZ'},
                    }
                },
            }
        }

        if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg) {
            return {
                inviteId: invite?.inviteId,
                replyData: {
                    travels: []
                },
            }
        }

        if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
            return {
                inviteId: invite?.inviteId,
                replyData: {},
            }
        }

        const replyDays: { [key in number]: JsonInviteReplyDay } = [];
        if (invite?.inviteType === JsonInviteInfoInviteTypeEnum.Guest) {
            eventDays?.forEach((ed) => {
                if (ed.dayNo) {
                    replyDays[ed.dayNo] = {
                        replyType: isDaysLocked ? JsonInviteReplyDayReplyTypeEnum.Accept : undefined,
                        dayNo: ed.dayNo,
                        dayDate: ed.dayDate,
                    }
                }
            });
        }
        return {
            inviteId: invite?.inviteId,
            newFirstName: invite?.firstName,
            newLastName: invite?.lastName,
            replyType: isAlreadyRegistered ? JsonInviteReplyReplyTypeEnum.Accept : undefined,
            replyData: {
                replyDays: invite?.inviteType === JsonInviteInfoInviteTypeEnum.Guest
                    ? replyDays
                    : undefined,
                extras: (invite?.inviteType === JsonInviteInfoInviteTypeEnum.Guest && !!invite?.inviteData?.extraLimit)
                || invite?.inviteType === JsonInviteInfoInviteTypeEnum.Supplier
                    ? []
                    : undefined,
                articles:  undefined,
            },
        };
    }, [reply, invite, owner, eventDays, isAlreadyRegistered, isDaysLocked]);

    if (!rsvp || !event || !invite || !eventDays || !inviteData) {
        return null;
    }

    if (!(invite.status === JsonInviteInfoStatusEnum.Pending || invite.status === JsonInviteInfoStatusEnum.Returned)) {
        return <RsvpInviteStatusInfo rsvp={rsvp}/>;
    }

    return <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(v) => validate(v, rsvp, t)}
        onSubmit={handleSubmit}>
        {(formikProps) => <RsvpInviteForm rsvp={rsvp} token={token} formikProps={formikProps} isDaysLocked={isDaysLocked}/>}
    </Formik>;
}

export default RsvpInvite;
