import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps} from '../../model/form';
import * as React from "react";
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";
import {CheckBoxOutlineBlankRounded, CheckBoxRounded} from "@mui/icons-material";

interface Props extends FormFieldProps {
    color?: CheckboxProps['color'],
    offColor?: CheckboxProps['color'],
    triState?: boolean,
    emptyValue?: boolean
    title?: string;
}

interface PropsPlain extends Props {
    currentValue?: any,
    showError?: boolean,
    error?: string
}

export const CheckboxPlain = (props: PropsPlain) => {
    const {name, label, color, offColor,
        triState, emptyValue, onChange, currentValue,
        showError, error, disabled, title} = props;

    const indeterminate = triState && currentValue === undefined;

    return <FormControl error={showError}>
        <FormControlLabel sx={{alignSelf: 'flex-start'}}
            control={<Checkbox
                name={name}
                checked={currentValue || false}
                indeterminate={indeterminate}
                indeterminateIcon={triState && emptyValue !== undefined
                    ? (emptyValue
                            ? <CheckBoxRounded/>
                            : <CheckBoxOutlineBlankRounded/>
                    ) : undefined}
                color={offColor && currentValue === false ? offColor : (indeterminate ? 'default' : (color || 'secondary'))}
                disabled={disabled}
                onChange={() => {
                    let value;
                    if (triState) {
                        if (currentValue) {
                            if (emptyValue === false) {
                                value = undefined;
                            } else {
                                value = false;
                            }
                        } else if (currentValue === false) {
                            value = undefined;
                        } else {
                            if (emptyValue) {
                                value = false;
                            } else {
                                value = true;
                            }
                        }
                    } else {
                        value = !currentValue;
                    }
                    if (onChange) {
                        onChange(value);
                    }
                }}/>}
            label={label}
            title={title}
        />
        {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>;
}

export const CheckboxField = (props: Props) => {
    const {name, onChange} = props;

    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();
    const showError = !!meta.error && (meta.touched || submitCount > 0);

    return <CheckboxPlain {...props}
        currentValue={field.value}
        onChange={(value) => {
            helpers.setValue(value, true);
            if (onChange) {
                onChange(value);
            }
        }}
        showError={showError}
        error={meta.error}
    />;

}
