import {createSelector} from "@reduxjs/toolkit";
import {RootState} from '../index';
import {CodebookState} from "./codebooks";
import {LocalAppState} from './localApp';

export const featureAuth = (state: RootState) => state.auth;
export const featureCodebooks = (state: RootState) => state.codebooks;
export const featureUsers = (state: RootState) => state.users;
export const featureEvents = (state: RootState) => state.events;
export const featureEventDays = (state: RootState) => state.eventDays;
export const featureParties = (state: RootState) => state.parties;
export const featureInvites = (state: RootState) => state.invites;
export const featureEventParties = (state: RootState) => state.eventParties;
export const featurePlaces = (state: RootState) => state.places;
export const featureArticles = (state: RootState) => state.articles;
export const featureArticleTypes = (state: RootState) => state.articleTypes;
export const featureContingents = (state: RootState) => state.contingents;

export const selectCodebooks = createSelector([featureCodebooks], (codebooks: CodebookState) => codebooks);
export const selectAppMessages = createSelector([(state: RootState) => state.localApp], (localApp: LocalAppState) => localApp.messages);
export const selectAppLoading = createSelector([(state: RootState) => state.localApp], (localApp: LocalAppState) => localApp.showLoader);

export const selectAuthIsLogged = createSelector([featureAuth], (auth) => !!auth.user && !!auth.user.userId);
export const selectAuthInfo = createSelector([featureAuth], (auth) => auth);

export const selectUsers = createSelector([featureUsers], (users) => users);
export const selectEvents = createSelector([featureEvents], (events) => events);
export const selectEventDays = createSelector([featureEventDays], (eventDays) => eventDays);
export const selectParties = createSelector([featureParties], (parties) => parties);
export const selectEventParties = createSelector([featureEventParties], (eventParties) => eventParties);
export const selectInvites = createSelector([featureInvites], (invites) => invites);
export const selectPlaces = createSelector([featurePlaces], (places) => places);
export const selectArticles = createSelector([featureArticles], (articles) => articles);
export const selectArticleTypes = createSelector([featureArticleTypes], (articleTypes) => articleTypes);
export const selectContingents = createSelector([featureContingents], (contingents) => contingents);


