import {Alert, Box, Button, CircularProgress, Grid, Typography} from '@mui/material';
import {FormikErrors, FormikProps, useFormikContext} from 'formik';
import {createOption, FAKE_VALUE_ALL, FormProps, OptionValue, setNestedKey} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import {PartyMassActionProps, PartyMassActionValues, partyName, renderAccredStatus} from "../../model/party";
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridMode, DataGridState, defaultDataGridFilterState, localCompare} from "../DataGrid";
import {
    JsonEvent,
    JsonEventPartyAccredStatusEnum,
    JsonEventPartyInfo,
    JsonEventPartyInfoAccredStatusEnum,
    JsonEventPartyInfoPartyTypeEnum,
    JsonEventPartyInfoSiwiAccredStatusEnum,
    JsonFormatInfo,
    JsonGroupInfo,
    JsonGroupInfoGroupTypeEnum
} from "../../generated-api";
import CodebookValue from "../CodebookValue";
import {dateToGuiAs} from "../../helpers/date";
import {ItemsState, useAppDispatch} from "../../store";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {fetchEvent} from "../../store/events";
import {getApiResult} from "../../helpers/api";
import EventDaysValue from "../EventDaysValue";
import {fetchFormats} from "../../store/formats";
import {SelectFormField} from "../form/SelectFormField";
import {ClearRounded, ContentPasteOffRounded, CopyAllRounded, KeyboardDoubleArrowLeft, RefreshRounded, SyncRounded, WarningAmberRounded} from "@mui/icons-material";
import {fetchGroups} from "../../store/groups";
import {fetchEventPartiesOnline} from "../../store/eventParties";

interface Props extends PartyMassActionProps<PartyMassActionValues<any>, any> {

}

interface LocalFilter extends DataGridFilter {
}

export interface JsonEventPartyInfoWithChunkId extends JsonEventPartyInfo {
    chunkId: string,
    parents: JsonEventPartyInfo[],
    chunkNo?: number
}

type LocalFormValues = {
    [key in number]: JsonEventPartyInfoWithChunkId
}

interface PartiesGridState extends DataGridState<JsonEventPartyInfoWithChunkId, LocalFilter> {
}

type ChunkInfoProps = {
    item: JsonEventPartyInfoWithChunkId,
    dayNo?: number
}

const COLORS = [
    '35, 0, 85',
    '42, 192, 206',
    '252, 192, 55',
    '240, 68, 71',
    '8, 189, 90',
    '176, 68, 23',
    '1, 0, 53',
    '8, 141, 42',
    // '128, 174, 217',
    // '170, 230, 235',
    // '254, 230, 175',
    // '249, 180, 181',
    // '156, 229, 189',
]

export const ChunkInfo = (props: ChunkInfoProps) => {
    const {item, dayNo} = props;

    if (item.parents.length <= 1 && !(item.extraEventParties
        && item.extraEventParties.length > 0
        && (!dayNo || item.extraEventParties.find((extra) => extra.eventDays && extra.eventDays[dayNo - 1] === '1')))) {
        return null;
    }

    const color = COLORS[(item.chunkNo || 0) % COLORS.length];
    const parentsLength = item.parents.length;

    return <div className={'accred-chunk'} style={{color: 'rgb(' + color + ')'}}>
        <div style={{backgroundColor: 'rgb(' + color + ')'}}></div>
        <span title={item.parents.map((p) => p.lastName).join(' ↦ ')}>
            {parentsLength <= 1
                ? '★'
                : parentsLength === 3
                    ? '↠'
                    : item.parents.slice(1).map(() => ' ↳').join(' ')}
        </span>
    </div>
}

const accredButtonStyle = {
    '& svg': {color: 'var(--color-warning)'},
    '& button:hover svg': {color: '#fff'}
};

const PartyDetails = ({item}: { item: JsonEventPartyInfo, filter: LocalFilter }) => {
    const t = useAppTranslation();
    const {setFieldValue} = useFormikContext<LocalFormValues>();

    const rows: JSX.Element[] = [];

    const s = renderAccredStatus(item.siwiAccredStatus, item);
    if (s) {
        const isEqualWithSiwi = item.siwiAccredStatus
            && item.siwiEventDays === item.eventDays
            && isSettingEqual(item, {
                formatCode: item.siwiFormatCode,
                contingentOwner: item.siwiContingentOwner,
                reason: item.siwiReason,
                areas: item.siwiAreas
            });
        if (item.siwiAccredStatus && !isEqualWithSiwi) {
            rows.push(<Box key={'accred'} sx={accredButtonStyle}><Button
                color={'warning'}
                title={t('Nastavit vše dle SIWI')} onClick={() => {
                if (!(item.siwiEventDays === item.eventDays) && !!item.eventDayDetails
                    ?.filter((dd) => JSON.stringify({...dd, dayNo: undefined}) !== '{}')
                    ?.find((dd) => dd.dayNo && (!item.siwiEventDays || item.siwiEventDays[dd.dayNo - 1] !== '1'))) {
                    alert(
                        'Akreditaci nelze jednoduše převzít ze SIWI, protože by došlo k odebrání dne, na kterém je evidována nějaká informace (parkování apod).'
                        + '\n\n'
                        + 'Upravte tedy nejprve dny manuálně.'
                    );
                    return;
                }
                setFieldValue(item.partyId + '.formatCode', item.siwiFormatCode);
                setFieldValue(item.partyId + '.contingentOwner', item.siwiContingentOwner);
                setFieldValue(item.partyId + '.reason', item.siwiReason);
                setFieldValue(item.partyId + '.areas', item.siwiAreas);
                setFieldValue(item.partyId + '.eventDays', item.siwiEventDays);

            }}>{s}</Button></Box>)
        } else {
            rows.push(<Box key={'accred'}>{s}</Box>)
        }
    }
    if (!item.photoGuid) {
        rows.push(<Typography key={'photo'} color={'error'} title={t('Chybí fotografie')}><WarningAmberRounded/></Typography>)
    }
    if (!rows.length) {
        return null;
    }

    return <div className={'event-details'}>
        {rows}
    </div>
}

const isSettingEqual = (item?: JsonEventPartyInfo, settings?: JsonEventPartyInfo) => {
    return item?.formatCode === settings?.formatCode
        && item?.contingentOwner === settings?.contingentOwner
        && item?.reason === settings?.reason
        && item?.areas === settings?.areas;
}

type ActionButtonsProps = {
    item: JsonEventPartyInfo,
    defaults?: JsonEventPartyInfo,
    clipboard: JsonEventPartyInfo | undefined,
    setClipboard: (c: JsonEventPartyInfo) => void
}

const ActionButtons = (props: ActionButtonsProps) => {
    const {item, defaults, clipboard, setClipboard} = props;

    const t = useAppTranslation();
    const {setFieldValue} = useFormikContext<LocalFormValues>();

    const rows: JSX.Element[] = [];

    if (clipboard && !isSettingEqual(item, clipboard)) {
        rows.push(<Button key='paste' title={t('Vložit ze schránky {{formatCode}}, {{contingentOwner}}, {{reason}}, {{areas}}', clipboard)} onClick={() => {
            setFieldValue(item.partyId + '.formatCode', clipboard?.formatCode);
            setFieldValue(item.partyId + '.contingentOwner', clipboard?.contingentOwner);
            setFieldValue(item.partyId + '.reason', clipboard?.reason);
            setFieldValue(item.partyId + '.areas', clipboard?.areas);
        }}><KeyboardDoubleArrowLeft/></Button>)
    }

    if (item.formatCode) {
        if (!clipboard) {
            rows.push(<Button key='copy' title={t('Zkopírovat do schránky')} onClick={() => {
                setClipboard(item);
            }}><CopyAllRounded/></Button>)
        }

        if (defaults?.formatCode) {
            if (!isSettingEqual(item, defaults)) {
                rows.push(<Button key='reload' title={t('Obnovit')} onClick={() => {
                    setFieldValue(item.partyId + '.formatCode', defaults?.formatCode);
                    setFieldValue(item.partyId + '.contingentOwner', defaults?.contingentOwner);
                    setFieldValue(item.partyId + '.reason', defaults?.reason);
                    setFieldValue(item.partyId + '.areas', defaults?.areas);
                    if (!!defaults?.eventDays) {
                        setFieldValue(item.partyId + '.eventDays', defaults?.eventDays);
                    }
                }}><RefreshRounded/></Button>)
            }

        } else {
            rows.push(<Button key='clear' title={t('Vymazat')} onClick={() => {
                setFieldValue(item.partyId + '.formatCode', undefined);
                setFieldValue(item.partyId + '.contingentOwner', undefined);
                setFieldValue(item.partyId + '.reason', undefined);
                setFieldValue(item.partyId + '.areas', undefined);
            }}><ClearRounded/></Button>)
        }
    }

    return <div className={'event-details'}>
        {rows}
    </div>
}

type FormatSelectProps = {
    item: JsonEventPartyInfoWithChunkId,
    formatOptions: OptionValue[],
    areaDefaults: { [key in string]: string },
    areaOptions: { [key in string]: OptionValue[] },
    contingentDefaults: { [key in string]: string },
    defaults?: JsonEventPartyInfo,
}

const FormatSelect = (props: FormatSelectProps) => {
    const {item, formatOptions, areaDefaults, areaOptions, contingentDefaults, defaults} = props;
    const {partyId, parents} = item;

    const {setFieldValue, values} = useFormikContext<LocalFormValues>();

    const handleValueChange = useCallback((v: any, values: LocalFormValues) => {
        setFieldValue(partyId + '.contingentOwner', contingentDefaults[v] || defaults?.contingentOwner);
        let areas = areaDefaults[v];
        if (defaults?.areas && defaults?.areas.indexOf('Z') >= 0 && !(areas && areas.indexOf('Z') >= 0)
            && !!areaOptions[v]?.find((o) => o.value === 'Z')) {
            areas = (areas ? (areas + ' ') : '') + 'Z';
        }
        setFieldValue(partyId + '.areas', areas);
        setFieldValue(partyId + '.reason', undefined);

        const parentId = parents?.[0]?.partyId;
        Object.keys(values).forEach((k) => {
            const other = values[k as any as number];
            if (other.parents?.[0]?.partyId === parentId && other.partyId !== partyId && !other.formatCode) {
                setFieldValue(other.partyId + '.formatCode', v);
                setFieldValue(other.partyId + '.contingentOwner', contingentDefaults[v] || defaults?.contingentOwner);
                setFieldValue(other.partyId + '.areas', areas);
                setFieldValue(other.partyId + '.reason', undefined);
            }
        })
    }, [partyId, parents, areaDefaults, contingentDefaults, defaults, areaOptions, setFieldValue]);

    return <SelectFormField name={item.partyId + '.formatCode'} options={formatOptions} showTooltip onChange={(v) => {
        handleValueChange(v, values);
    }}/>
}

type ReasonSelectProps = {
    item: JsonEventPartyInfoWithChunkId,
    reasonOptions: { [key in string]: OptionValue[] }
}

const ReasonSelect = (props: ReasonSelectProps) => {
    const {item, reasonOptions} = props;
    const {partyId, parents} = item;

    const {setFieldValue, values} = useFormikContext<LocalFormValues>();

    const handleValueChange = useCallback((v: any, values: LocalFormValues) => {
        const parentId = parents?.[0]?.partyId;
        Object.keys(values).forEach((k) => {
            const other = values[k as any as number];
            if (other.parents?.[0]?.partyId === parentId && other.partyId !== partyId && other.formatCode && !other.reason) {
                setFieldValue(other.partyId + '.reason', v, true);
            }
        })
    }, [partyId, parents, setFieldValue]);

    return <SelectFormField name={item.partyId + '.reason'} options={reasonOptions[item.formatCode || '']} freeSolo onChange={(v) => {
        handleValueChange(v, values);
    }}/>
}

type AreaSelectProps = {
    item: JsonEventPartyInfoWithChunkId,
    areaOptions: { [key in string]: OptionValue[] },
    groups?: JsonGroupInfo[]
}

const AreaSelect = (props: AreaSelectProps) => {
    const {item, areaOptions, groups} = props;
    const {partyId, parents, formatCode, contingentOwner, groupId} = item;

    const {setFieldValue, values} = useFormikContext<LocalFormValues>();

    const options = useMemo(() => {
        const options = areaOptions[item.formatCode || ''];
        item.siwiAreas?.split(' ')?.forEach((area) => {
            if (!options.find((o) => o.value === area)) {
                // siwi area outside format
                const o = createOption(area, area);
                o.color = 'warning';
                options.push(o);
            }
        });

        item.areas?.split(' ')?.forEach((area) => {
            if (area && !options.find((o) => o.value === area)) {
                // inconsistency (format vs actual area)
                options.push(createOption(area, area));
            }
        });

        if (item.siwiAreas) {
            options.forEach((o) => {
                const area = o.value as string;
                if (item.siwiAreas && item.siwiAreas.indexOf(area) >= 0
                    && !(item.areas && item.areas.indexOf(area) >= 0)) {
                    o.color = 'warning';
                }
            });
        }
        return options.sort((a, b) => a.value < b.value ? -1 : 1);

    }, [item.formatCode, item.areas, item.siwiAreas, areaOptions]);

    const handleValueChange = useCallback((v: any, values: LocalFormValues) => {
        const isVipArea = v && (v.indexOf('Z') >= 0 || v.indexOf('R') >= 0);
        let contingent: undefined | null | string = null;
        if (isVipArea && !contingentOwner) {
            const groupType = groups?.find((g) => g.groupId === groupId)?.groupType;
            contingent = groupType === JsonGroupInfoGroupTypeEnum.Supp ? 'WORKERS' : 'LOC';

        } else if (!isVipArea && (contingentOwner === 'LOC' || contingentOwner === 'WORKERS')) {
            contingent = undefined;
        }

        if (contingent !== null) {
            setFieldValue(partyId + '.contingentOwner', contingent);
        }

        const parentId = parents?.[0]?.partyId;
        Object.keys(values).forEach((k) => {
            const other = values[k as any as number];
            if (other.parents?.[0]?.partyId === parentId && other.partyId !== partyId && other.formatCode) {
                if (other.formatCode === formatCode) {
                    setFieldValue(other.partyId + '.areas', v);
                    if (contingent !== null) {
                        setFieldValue(other.partyId + '.contingentOwner', contingent, true);
                    }
                } else {
                    const isPossibleZ = !!areaOptions[other.formatCode].find((o) => o.value === 'Z');
                    const isPossibleR = !!areaOptions[other.formatCode].find((o) => o.value === 'R');
                    let updated = false;
                    const newAreas = other.areas?.split(' ') || [];
                    if (v.indexOf('Z') >= 0 && newAreas.indexOf('Z') < 0 && isPossibleZ) {
                        newAreas.push('Z');
                        updated = true;
                    } else if (v.indexOf('Z') < 0 && newAreas.indexOf('Z') >= 0) {
                        newAreas.splice(newAreas.indexOf('Z'), 1);
                        updated = true;
                    }
                    if (v.indexOf('R') >= 0 && newAreas.indexOf('R') < 0 && isPossibleR) {
                        newAreas.push('R');
                        updated = true;
                    } else if (v.indexOf('R') < 0 && newAreas.indexOf('R') >= 0) {
                        newAreas.splice(newAreas.indexOf('R'), 1);
                        updated = true;
                    }
                    if (updated) {
                        setFieldValue(other.partyId + '.areas', newAreas.length > 0 ? newAreas.join(' ') : undefined);
                        if (contingent !== null) {
                            setFieldValue(other.partyId + '.contingentOwner', contingent, true);
                        }
                    }
                }
            }
        })
    }, [partyId, parents, formatCode, contingentOwner, groups, groupId, areaOptions, setFieldValue]);

    return <ButtonGroupField name={item.partyId + '.areas'} options={options} isMulti fullWidth splitBy={' '} debounce
        onChange={(v) => {
            handleValueChange(v, values);
        }}
    />;
}


type ContingentSelectProps = {
    item: JsonEventPartyInfoWithChunkId
}

const ContingentSelect = (props: ContingentSelectProps) => {
    const {item} = props;
    const {partyId, parents} = item;

    const {setFieldValue, values} = useFormikContext<LocalFormValues>();

    const handleValueChange = useCallback((v: any, values: LocalFormValues) => {
        const parentId = parents?.[0]?.partyId;
        Object.keys(values).forEach((k) => {
            const other = values[k as any as number];
            if (other.parents?.[0]?.partyId === parentId && other.partyId !== partyId && other.formatCode && !other.contingentOwner) {
                setFieldValue(other.partyId + '.contingentOwner', v, true);
            }
        })
    }, [partyId, parents, setFieldValue]);

    return <SelectFormField name={item.partyId + '.contingentOwner'} codebookProps={{codebookName: 'contingent', scope: item.eventId}} clearable onChange={(v) => {
        handleValueChange(v, values);
    }}/>
}

const defaultState: PartiesGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'chunkId, fullName',
    },
};

const getRowClassNames = (item: JsonEventPartyInfoWithChunkId, filter?: LocalFilter, i?: number): string[] | undefined | { classNames?: string[], style?: CSSProperties } => {

    if (!item.photoGuid) {
        return ['data-grid-invalid-row'];
    }
    if (item.parents.length <= 1 && !(item.extraEventParties && item.extraEventParties.length > 0)) {
        // not a chunk
    } else {
        const color = COLORS[(item.chunkNo || 0) % COLORS.length];
        return {classNames: [], style: {background: 'rgba(' + color + ', ' + (i && i % 2 > 0 ? '0.1' : '0.15') + ')'}};
    }
    // if (item.siwiAccredStatus) {
    //     return ['data-grid-warning-row'];
    // }

    return undefined;
}

const gridTableProps = {
    minHeight: '150px',
    maxHeight: "clamp(100px, calc(100vh - 300px), 600px)"
};

export const getChunks = (items: JsonEventPartyInfo[], dayNo?: number, areaCode?: string): [JsonEventPartyInfoWithChunkId[], boolean] => {
    // build chunks
    const chunks: JsonEventPartyInfoWithChunkId[] = [];
    const done: number[] = [];
    let hasChunks = false;

    const addEventPartyToChunk = (ep: JsonEventPartyInfo) => {
        if (!ep.eventPartyId || done.indexOf(ep.eventPartyId) >= 0) {
            return;
        }

        const parents: JsonEventPartyInfo[] = [];
        let p: JsonEventPartyInfo | undefined = ep;
        while (p?.parentEventParties?.[0]?.eventPartyId) {
            const parentId: number | undefined = p?.parentEventParties?.[0]?.eventPartyId;
            if (!parentId || parents.find((x) => x.eventPartyId === parentId)) {
                // cycle
                break;
            }
            p = items.find((x) => x.eventPartyId === parentId);
            if (dayNo && !(p?.eventDays && p.eventDays[dayNo - 1] === '1')) {
                break;
            }
            if (areaCode && !(p?.areas && p.areas.indexOf(areaCode) >= 0)) {
                break;
            }

            parents.unshift(p || {eventPartyId: parentId, lastName: 'MISSING'});
            if (!hasChunks) {
                hasChunks = true;
            }
        }
        parents.push(ep);

        chunks.push({
            ...ep,
            chunkId: parents.map(p => p.eventPartyId).join('_'),
            parents
        });
        done.push(ep.eventPartyId);
    }

    items.forEach((ep) => {
        if (ep.partyType === JsonEventPartyInfoPartyTypeEnum.T) {
            return;
        }
        if (dayNo && !(ep.eventDays && ep.eventDays[dayNo - 1] === '1')) {
            return;
        }
        if (areaCode && !(ep.areas && ep.areas.indexOf(areaCode) >= 0)) {
            return;
        }
        addEventPartyToChunk(ep);

        ep.extraEventParties?.forEach((e) => {
            const p = items.find((x) => x.eventPartyId === e.eventPartyId);
            if (p) {
                if (dayNo && !(p.eventDays && p.eventDays[dayNo - 1] === '1')) {
                    return;
                }
                if (areaCode && !(p.areas && p.areas.indexOf(areaCode) >= 0)) {
                    return;
                }
                addEventPartyToChunk(p);
            }
        });
    });

    let prevRoot: JsonEventPartyInfo;
    let chunkNo = 0;
    chunks.sort((a, b) => localCompare(a.chunkId, b.chunkId)).forEach((c) => {
        if (c.parents.length <= 1 && !(c.extraEventParties && c.extraEventParties.length > 0)) {
            return;
        }
        if (prevRoot && prevRoot !== c.parents[0]) {
            chunkNo++;
        }
        c.chunkNo = chunkNo;
        prevRoot = c.parents[0];
    });
    return [chunks, hasChunks];
}

const PartyAccredToEventForm = (props: Props) => {
    const {massAction} = props;
    const {eventParties, values} = massAction;
    const {action, eventId, items} = values;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [event, setEvent] = useState<JsonEvent | undefined>(undefined);
    const [formats, setFormats] = useState<JsonFormatInfo[] | undefined>(undefined);
    const [groups, setGroups] = useState<JsonGroupInfo[] | undefined>(undefined);
    const [clipboard, setClipboard] = useState<JsonEventPartyInfo | undefined>(undefined);
    const [chunks, setChunks] = useState<JsonEventPartyInfoWithChunkId[] | undefined>(undefined);
    const [hasChunks, setHasChunks] = useState<boolean>(false);

    const handleFetchEventAndChunks = useCallback(async (eventId: number) => {
        setEvent(getApiResult(await dispatch(fetchEvent(eventId))))
        setFormats(getApiResult<JsonFormatInfo[]>(await dispatch(fetchFormats({eventId, rows: 9999})))?.filter((f) => !f.formatCode?.endsWith('00')));
        setGroups(getApiResult(await dispatch(fetchGroups({orderCol: 'title'}))))

        const items = eventParties;
        do {
            // fetch dependent extras & parents (until it's all done - extra -> parent -> extras etc)
            const partyIds: number[] = [];
            items.forEach((ep) => {
                ep.extraEventParties?.forEach((e) => {
                    if (!e.partyId || partyIds.indexOf(e.partyId) >= 0) {
                        return;
                    }
                    if (!items.find((x) => x.partyId === e.partyId)) {
                        partyIds.push(e.partyId);
                    }
                });
                ep.parentEventParties?.forEach((p) => {
                    if (!p.partyId || partyIds.indexOf(p.partyId) >= 0) {
                        return;
                    }
                    if (!items.find((x) => x.partyId === p.partyId)) {
                        partyIds.push(p.partyId);
                    }
                });
            });
            if (partyIds.length > 0) {
                const extrasAndParents = getApiResult<JsonEventPartyInfo[]>(await dispatch(fetchEventPartiesOnline({rows: partyIds.length, partyIds, eventId})));
                extrasAndParents?.forEach((e) => {
                    items.push(e);
                })
            } else {
                break;
            }

        } while (true);

        const [chunks, hasChunks] = getChunks(items);

        setChunks(chunks);
        setHasChunks(hasChunks);

    }, [dispatch, eventParties])

    const validate = useCallback((values: LocalFormValues) => {
        const errors: FormikErrors<LocalFormValues> = {}
        Object.keys(values).forEach((k) => {
            const p = values[k as any as number];
            if (p.partyId && !!p.formatCode && !p.reason) {
                setNestedKey(errors, p.partyId + '.reason', t('Zadejte reason'));
            }
        });
        return errors;
    }, [t]);

    const formatOptions = useMemo(() => {
        if (!formats) {
            return [];
        }
        return formats.map((f) => createOption(f.formatCode as string, f.formatCode as string, f.title))

    }, [formats]);

    const reasonOptions = useMemo(() => {
        const reasonOptions: { [key in string]: OptionValue[] } = {};
        formats?.forEach((f) => {
            reasonOptions[f.formatCode as string] = f.formatReasons?.map((r) => createOption(r, r))
                .sort((a, b) => a.value === b.value ? 0 : (a.value > b.value ? 1 : -1)) || [];
        });
        return reasonOptions;

    }, [formats]);

    const areaOptions = useMemo(() => {
        const areaOptions: { [key in string]: OptionValue[] } = {};
        formats?.forEach((f) => {
            areaOptions[f.formatCode as string] = f.formatAreas?.map((a) => createOption(a.areaCode as string, a.areaCode as string))
                .sort((a, b) => a.value === b.value ? 0 : (a.value > b.value ? 1 : -1)) || [];
        });
        return areaOptions;

    }, [formats]);

    const areaDefaults = useMemo(() => {
        const areaDefaults: { [key in string]: string } = {};
        formats?.forEach((f) => {
            areaDefaults[f.formatCode as string] = (f.formatAreas?.filter((a) => a.isSet).map((a) => a.areaCode as string) || []).join(' ');
        });
        return areaDefaults;

    }, [formats]);

    const contingentDefaults = useMemo(() => {
        const contingentDefaults: { [key in string]: string } = {};
        formats?.forEach((f) => {
            if (f.formatCode && f.contingentOwner) {
                contingentDefaults[f.formatCode] = f.contingentOwner;
            }
        });
        return contingentDefaults;

    }, [formats]);

    const eventPartyDefaults = useMemo(() => {
        const eventPartyDefaults: LocalFormValues = {};
        chunks?.forEach((ep) => {
            if (ep && !ep.formatCode) {
                const defItem = items?.find((it) => it.partyId === FAKE_VALUE_ALL);
                if (defItem) {
                    ep.formatCode = defItem.formatCode;
                    ep.reason = defItem.reason;
                    ep.areas = defItem.areas;
                    ep.contingentOwner = defItem.contingentOwner;
                    return;
                }

                const settings = groups?.find((g) => g.groupId === ep.groupId)?.settings;
                if (settings?.formatCodeDefault) {
                    ep.formatCode = settings.formatCodeDefault;
                    if (ep.formatCode) {
                        ep.contingentOwner = contingentDefaults[ep.formatCode];
                    }
                    ep.reason = settings.reasonDefault;
                    if (settings.areasDefault) {
                        ep.areas = settings.areasDefault;
                    } else {
                        ep.areas = areaDefaults[ep.formatCode];
                    }
                }
                if ((!ep.areas || !(ep.areas.indexOf('Z') >= 0)) && (!!ep.extraEventParties || !!ep.parentEventParties)) {
                    // fallback for invited guests (hopefully)
                    ep.areas = (ep.areas ? (ep.areas + ' ') : '') + 'Z';

                    if (!ep.contingentOwner) {
                        ep.contingentOwner = 'LOC';
                    }
                }
            }
            eventPartyDefaults[ep.partyId as number] = ep;
        })
        return eventPartyDefaults;
    }, [chunks, groups, contingentDefaults, areaDefaults, items]);

    const cols = useMemo(() => {
        if (!event || !formatOptions || !reasonOptions || !groups) {
            return [];
        }
        const cols: DataGridCol<JsonEventPartyInfoWithChunkId, LocalFilter>[] = [
            createCol('Jméno', 'fullName', 70, undefined, (v, item) => <strong>
                {partyName(item)}
            </strong>),
            createCol('R', 'birthDate', 25, 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null),
            createCol('Skupina', 'groupId', 55, 'Skupina', (v) =>
                v ? <small><CodebookValue value={v} name={'group'}/></small> : null),
            createCol('Dny', 'eventDays', 40, undefined, (v, item) =>
                <EventDaysValue eventDays={v} eventId={item.eventId} siwiEventDays={item.siwiEventDays}/>),
            createCol('F', 'formatCode', 45, 'Formát', (v, item) => {
                return <div title={item.siwiAccredStatus ? 'Siwi: ' + (item.siwiFormatCode || '-') : undefined}>
                    {<FormatSelect item={item} formatOptions={formatOptions} areaOptions={areaOptions} areaDefaults={areaDefaults} contingentDefaults={contingentDefaults}
                        defaults={item.partyId ? eventPartyDefaults[item.partyId] : undefined}/>}</div>;
            }),
            createCol('Reason', 'reason', hasChunks ? 100 : 130, 'Reason', (v, item) => {
                return <div title={item.siwiAccredStatus ? 'Siwi: ' + (item.siwiReason || '-') : undefined}>{!item.formatCode
                    ? null
                    : <ReasonSelect item={item} reasonOptions={reasonOptions}/>}</div>;
            }),
            createCol('Zóny', 'areas', 340, 'Zóny', (v, item) => {
                return <div title={item.siwiAccredStatus ? 'Siwi: ' + (item.siwiAreas || '-') : undefined}>{!item.formatCode
                    ? null
                    : <AreaSelect item={item} areaOptions={areaOptions} groups={groups}/>}</div>
            }),
            createCol('Kont.', 'contingentOwner', 60, 'Kontingent', (v, item) => {
                return <div title={item.siwiAccredStatus ? 'Siwi: ' + (item.siwiContingentOwner || '-') : undefined}>{!item.formatCode
                    ? null
                    : <ContingentSelect item={item}/>}</div>;
            }), {
                title: clipboard ? <Button title={t('Smazat schránku')} sx={{margin: '-3px', padding: '3px'}} onClick={() => {
                    setClipboard(undefined);
                }}><ContentPasteOffRounded/></Button> : '',
                size: 35,
                col: 'eventPartyId',
                renderValue: (_, item) =>
                    <ActionButtons item={item} defaults={item.partyId ? eventPartyDefaults[item.partyId] : undefined} clipboard={clipboard} setClipboard={setClipboard}/>
            }, {
                title: 'S',
                tooltip: 'Stav / varování',
                size: 20,
                align: 'center',
                col: 'siwiAccredStatus',
                renderValue: (v, item, filter) => <PartyDetails item={item} filter={filter as LocalFilter}/>
            }
        ];
        if (hasChunks) {
            cols.unshift(createCol('Dopr.', 'chunkId', 25, undefined, (v, item) => <ChunkInfo item={item}/>));
        }

        return cols;

    }, [clipboard, hasChunks, event, groups, formatOptions, reasonOptions, areaOptions, areaDefaults, contingentDefaults, eventPartyDefaults, t]);

    const actions = useCallback(({values, isSubmitting, setSubmitting, handleSubmit, setFieldValue}: FormikProps<LocalFormValues>, props: FormProps<LocalFormValues>) => {
        const keys = Object.keys(values);
        return <>
            <Grid item>
                <Button variant="text" disabled={isSubmitting} onClick={async () => {
                    keys.forEach((k) => {
                        setFieldValue(k + '.accredStatus', JsonEventPartyInfoAccredStatusEnum.EmsManaged, false);
                    });
                    handleSubmit();
                }}>
                    {t('Uložit rozpracované')}</Button>
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
                <Alert severity={'info'} className={'event-action-errors'}>
                    {t('Upravujete akreditaci pro {{count}} osob', {count: keys.length})}</Alert>
            </Grid>
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
			</Grid>}
            <Grid item>
                <Button variant={'contained'} color={'success'} disabled={isSubmitting} onClick={async () => {
                    keys.forEach((k) => {
                        const ep = values[k as any as number];
                        const isChanged = ep.eventDays !== ep.siwiEventDays
                            || ep.formatCode !== ep.siwiFormatCode
                            || ep.reason !== ep.siwiReason
                            || ep.areas !== ep.siwiAreas
                            || ep.contingentOwner !== ep.siwiContingentOwner;
                        setFieldValue(k + '.accredStatus', isChanged || ep.siwiAccredStatus === JsonEventPartyInfoSiwiAccredStatusEnum.Deleted
                            ? JsonEventPartyInfoAccredStatusEnum.EmsApproved
                            : JsonEventPartyInfoAccredStatusEnum.EmsManaged, false);
                    });
                    handleSubmit();
                }}>
                    <SyncRounded/>
                    <span>{t('Potvrdit a odeslat do Siwi')}</span>
                </Button>
            </Grid>
        </>
    }, [t]);

    const itemsState = useMemo(() => {
        return {
            loading: false,
            count: 0,
            items: [],
            filter: {action, eventId}
        } as ItemsState<JsonEventPartyInfoWithChunkId, LocalFilter>;
    }, [action, eventId])

    const grid = useCallback((values: LocalFormValues) => {
        // const {filtered, alreadyThere, missingPhoto} = filterValid(action, eventParties, eventId, showMode);
        itemsState.items = Object.keys(values).map((k) => values[k as any as number]);

        return <>
            <DataGrid
                className={'accred-form'}
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                mode={DataGridMode.CLIENT}
                tableProps={gridTableProps}
                emptyListMessage={t('Není možné registrovat žádné osoby')}
                getRowClassNames={getRowClassNames}
                itemKey={'partyId'}
            />
        </>;
    }, [itemsState, cols, t]);

    const children = useCallback(({values}: FormikProps<LocalFormValues>) => {
        return <>
            <Grid item xs={12}>
                {grid(values)}
            </Grid>
        </>;
    }, [grid]);

    const initialValues = useMemo(() => {
        const initialValues: LocalFormValues = {};
        chunks?.forEach((ep) => {
            if (ep && !ep.formatCode && ep.partyId) {
                const eventPartyDefault = eventPartyDefaults[ep.partyId];
                if (eventPartyDefault) {
                    ep.formatCode = eventPartyDefault.formatCode;
                    ep.contingentOwner = eventPartyDefault.contingentOwner;
                    ep.reason = eventPartyDefault.reason;
                    ep.areas = eventPartyDefault.areas;
                }
            }

            initialValues[ep.partyId as number] = ep;
        })
        return initialValues;
    }, [chunks, eventPartyDefaults]);

    useEffect(() => {
        if (eventId) {
            handleFetchEventAndChunks(eventId).then();
        }
    }, [eventId, handleFetchEventAndChunks]);

    if (!formats || !event || !groups || !eventPartyDefaults || !chunks) {
        return <div style={{textAlign: "center", padding: "100px 200px"}}><CircularProgress size={50}/></div>;
    }

    return <FormContainer
        item={initialValues}
        validate={validate}
        actions={actions}
        children={children}
        onCancel={props.onCancel}
        onSave={(values) => props.onSave && props.onSave({
            ...massAction, values: {
                action,
                eventId,
                items: Object.keys(values).map((k) => {
                    const p = values[k as any as number];
                    return {
                        eventPartyId: p.eventPartyId,
                        partyId: p.partyId,
                        reason: p.reason,
                        formatCode: p.formatCode,
                        areas: p.areas,
                        contingentOwner: p.contingentOwner,
                        accredStatus: p.accredStatus ? p.accredStatus as string as JsonEventPartyAccredStatusEnum : undefined,
                        eventDays: p.eventDays
                    }
                })
            }
        })}
    />;
}

export default PartyAccredToEventForm;
