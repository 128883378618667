import * as React from 'react';
import {selectAuthIsLogged, selectAuthInfo} from '../../store/selectors';
import AppHeader from './AppHeader';
import {FlashMessages} from './FlashMessages';
import {Loader} from './Loader';
import {useAppSelector} from "../../store";
import {JsonAuthUserInfo} from "../../generated-api";
import {useLocation} from "react-router";

interface Props {
    noHeader?: boolean;
    noBackground?: boolean;
    children?: any;
}

const Layout = ({children, noHeader}: Props) => {

    const isLogged = useAppSelector(selectAuthIsLogged);
    const {user} = useAppSelector<JsonAuthUserInfo>(selectAuthInfo);
    const location = useLocation();
    const isWide = location.pathname.indexOf('guests/seating') >= 0 || location.pathname.indexOf('guests/floors') >= 0 || location.pathname.indexOf('/reports') >= 0;

    return (
        <div className={'container' + (isWide ? ' container-wide' : '')}>
            <FlashMessages/>
            <Loader/>
            {!noHeader && isLogged && <AppHeader user={user}/>}
            <div>{children}</div>
            <footer>
                <span title={(window as any).appContext?.version}>&copy; 2024 Český svaz biatlonu, z.s.</span>
            </footer>
        </div>
    );
}

export default Layout;
