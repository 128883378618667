import {renderExtraName} from "../model/invite";
import EventDaysValue from "./EventDaysValue";
import {JsonEventPartyInfo, JsonInviteReplyExtraInfo} from "../generated-api";
import {Tooltip} from "@mui/material";
import * as React from "react";
import {useMemo} from "react";
import PartyPhoto, {PartyPhotoTooltip} from "./party/PartyPhoto";
import {mergeEventDays} from "../model/party";

type EventPartyExtrasInfoProps = {
    extras: (JsonInviteReplyExtraInfo | JsonEventPartyInfo)[],
    asTooltip: boolean,
    asParent?: boolean,
    eventId?: number,
}

const EventPartyExtrasInfo = (props: EventPartyExtrasInfoProps) => {
    const {extras, asTooltip, eventId, asParent} = props;

    return useMemo(() => {
        const merged: (typeof extras[0])[] = [];
        const done: { [key in string]: typeof extras[0] } = {};
        extras?.forEach((extra, i) => {
            const k = extra.photoGuid || extra.partyId || JSON.stringify(extra);
            if (done[k]) {
                done[k].eventDays = mergeEventDays(done[k].eventDays, extra.eventDays);
                return;
            }
            done[k] = {...extra};
            merged.push(done[k]);
        });

        const extrasTable = <table className={'import-match-box'}>
            <tbody>
            {merged.map((extra, i) => {
                return <tr key={i}>
                    <td><PartyPhotoTooltip photoGuid={extra.photoGuid}
                        icon={<div><PartyPhoto photoGuid={extra.photoGuid} maxHeight={24} maxWidth={40}/></div>}
                        emptyIcon={<div><PartyPhoto photoGuid={undefined} maxHeight={24} maxWidth={40}/></div>}
                    /></td>
                    <td>{renderExtraName(extra, undefined, i, eventId)}</td>
                    <td>
                        <EventDaysValue eventDays={extra.eventDays} eventId={eventId}/>
                    </td>
                </tr>;
            })}</tbody>
        </table>

        if (asTooltip) {
            const cnt = merged.length;
            return <Tooltip title={extrasTable}>
                {asParent ? <small>⤷{cnt}</small> : <small>+{cnt || 0}</small>}
            </Tooltip>
        } else {
            return extrasTable;
        }
    }, [extras, asTooltip, asParent, eventId]);
}

export default EventPartyExtrasInfo;
