import FormModal from "../components/form/FormModal";
import {useAppTranslation} from "../services/i18n";
import PartyImportEditForm, {PartyImportEditFormProps} from "../components/party/PartyImportEditForm";

const PartyImportEditModal = (props: PartyImportEditFormProps & {title?: string}) => {

    const t = useAppTranslation();

    return (
        <FormModal title={t(props.title || 'Zpracování importované osoby')} {...props} maxWidth={props.item?.partyId ? 'sm' : 'md'}>
            <PartyImportEditForm {...props} />
        </FormModal>
    );
}

export default PartyImportEditModal;
