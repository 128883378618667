/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventPartyArticle,
    ApiResultOfJsonEventPartyArticleFromJSON,
    ApiResultOfJsonEventPartyArticleToJSON,
    ApiResultOfJsonEventPartyArticleInfo,
    ApiResultOfJsonEventPartyArticleInfoFromJSON,
    ApiResultOfJsonEventPartyArticleInfoToJSON,
    ApiResultOfListOfJsonEventPartyArticleInfo,
    ApiResultOfListOfJsonEventPartyArticleInfoFromJSON,
    ApiResultOfListOfJsonEventPartyArticleInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventPartyArticle,
    JsonEventPartyArticleFromJSON,
    JsonEventPartyArticleToJSON,
} from '../models';

export interface GetEventPartyArticleCountUsingGETRequest {
    eventId?: number;
    partyId?: number;
    search?: string;
}

export interface GetEventPartyArticleInfoUsingGETRequest {
    eventPartyArticleId: number;
}

export interface GetEventPartyArticleListUsingGETRequest {
    eventId?: number;
    partyId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventPartyArticleUsingGETRequest {
    eventPartyArticleId: number;
}

export interface SaveEventPartyArticleUsingPOSTRequest {
    json: JsonEventPartyArticle;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventPartyArticleControllerApi extends runtime.BaseAPI {

    /**
     * getEventPartyArticleCount
     */
    async getEventPartyArticleCountUsingGETRaw(requestParameters: GetEventPartyArticleCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyId !== undefined) {
            queryParameters['partyId'] = requestParameters.partyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-article/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventPartyArticleCount
     */
    async getEventPartyArticleCountUsingGET(requestParameters: GetEventPartyArticleCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventPartyArticleCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyArticleInfo
     */
    async getEventPartyArticleInfoUsingGETRaw(requestParameters: GetEventPartyArticleInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventPartyArticleInfo>> {
        if (requestParameters.eventPartyArticleId === null || requestParameters.eventPartyArticleId === undefined) {
            throw new runtime.RequiredError('eventPartyArticleId','Required parameter requestParameters.eventPartyArticleId was null or undefined when calling getEventPartyArticleInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-article/info/{eventPartyArticleId}`.replace(`{${"eventPartyArticleId"}}`, encodeURIComponent(String(requestParameters.eventPartyArticleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyArticleInfoFromJSON(jsonValue));
    }

    /**
     * getEventPartyArticleInfo
     */
    async getEventPartyArticleInfoUsingGET(requestParameters: GetEventPartyArticleInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventPartyArticleInfo> {
        const response = await this.getEventPartyArticleInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyArticleList
     */
    async getEventPartyArticleListUsingGETRaw(requestParameters: GetEventPartyArticleListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventPartyArticleInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyId !== undefined) {
            queryParameters['partyId'] = requestParameters.partyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-article/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventPartyArticleInfoFromJSON(jsonValue));
    }

    /**
     * getEventPartyArticleList
     */
    async getEventPartyArticleListUsingGET(requestParameters: GetEventPartyArticleListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventPartyArticleInfo> {
        const response = await this.getEventPartyArticleListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventPartyArticle
     */
    async getEventPartyArticleUsingGETRaw(requestParameters: GetEventPartyArticleUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventPartyArticle>> {
        if (requestParameters.eventPartyArticleId === null || requestParameters.eventPartyArticleId === undefined) {
            throw new runtime.RequiredError('eventPartyArticleId','Required parameter requestParameters.eventPartyArticleId was null or undefined when calling getEventPartyArticleUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-party-article/{eventPartyArticleId}`.replace(`{${"eventPartyArticleId"}}`, encodeURIComponent(String(requestParameters.eventPartyArticleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyArticleFromJSON(jsonValue));
    }

    /**
     * getEventPartyArticle
     */
    async getEventPartyArticleUsingGET(requestParameters: GetEventPartyArticleUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventPartyArticle> {
        const response = await this.getEventPartyArticleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventPartyArticle
     */
    async saveEventPartyArticleUsingPOSTRaw(requestParameters: SaveEventPartyArticleUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventPartyArticle>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventPartyArticleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-party-article`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventPartyArticleToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventPartyArticleFromJSON(jsonValue));
    }

    /**
     * saveEventPartyArticle
     */
    async saveEventPartyArticleUsingPOST(requestParameters: SaveEventPartyArticleUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventPartyArticle> {
        const response = await this.saveEventPartyArticleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
