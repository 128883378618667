import FormModal from "../components/form/FormModal";
import {JsonEventGroup} from "../generated-api";
import {FormProps} from "../model/form";
import EventGroupForm from "../components/event/EventGroupForm";
import {useAppTranslation} from "../services/i18n";

const EventGroupModal = (props: FormProps<JsonEventGroup>) => {

    const t = useAppTranslation();

    return (
        <FormModal title={props.title || t('Skupina')} {...props} maxWidth={'xl'}>
            <EventGroupForm {...props}></EventGroupForm>
        </FormModal>
    );
}

export default EventGroupModal;
