import {Grid} from '@mui/material';
import {FormikErrors} from 'formik';
import {FormProps, trueFalseOptions} from "../../model/form";
import {JsonPlace} from "../../generated-api";
import {TAppFunction, useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {RadioGroupField} from "../form/RadioGroupField";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {validateNumber} from "../../helpers/validators";
import {foodServiceOptions} from "../../model/place";
import {useCallback} from "react";

interface Props extends FormProps<JsonPlace> {
}

const validate = (values: JsonPlace, t: TAppFunction) => {
    let errors = {} as FormikErrors<JsonPlace>;
    if (!values.title) {
        errors.title = t('Zadejte prosím název místa');
    }
    if (!values.isFood && !(values.isFood === false)) {
        errors.isFood = t('Vybere prosím');

    } else if (values.isFood) {
        if (!(values.foodServices && values.foodServices.length > 0)) {
            errors.foodServices = t('Vyberte prosím služby');
        }
        if (!values.foodCapacity) {
            errors.foodCapacity = t('Zadejte kapacitu stravování');

        } else {
            validateNumber('foodCapacity', values, errors, t);
        }
    } else {
        values.foodServices = undefined;
        values.foodCapacity = undefined;
    }
    if (!values.isAcm && !(values.isAcm === false)) {
        errors.isAcm = t('Vybere prosím');
    }
    if (!values.isPark && !(values.isPark === false)) {
        errors.isPark = t('Vybere prosím');
    } else if (values.isPark) {
        if (!values.parkCapacity) {
            errors.parkCapacity = t('Zadejte kapacitu parkování');

        } else {
            validateNumber('parkCapacity', values, errors, t);
        }
    }
    if (!values.isWork && !(values.isWork === false)) {
        errors.isWork = t('Vybere prosím');
    }
    if (!values.isMeet && !(values.isMeet === false)) {
        errors.isMeet = t('Vybere prosím');
    }
    return errors;
}

const PlaceForm = (props: Props) => {

    const t = useAppTranslation();

    const handleValidate = useCallback((v: JsonPlace) => {
        return validate(v, t);
    }, [t]);

    return (
        <FormContainer {...props} validate={handleValidate} children={(formikProps) => <>
            <Grid item xs={12}>
                <TextFormField name="title" label={t('Název místa')} type={'text'} maxlength={100}/>
            </Grid>

            <Grid item xs={4}>
                <RadioGroupField options={trueFalseOptions} name={'isWork'} label={'Pracovní prostor'}/>
            </Grid>
            <Grid item xs={8}>
            </Grid>

            <Grid item xs={4}>
                <RadioGroupField options={trueFalseOptions} name={'isMeet'} label={'Meeting'}/>
            </Grid>
            <Grid item xs={8}>
            </Grid>

            <Grid item xs={4}>
                <RadioGroupField options={trueFalseOptions} name={'isFood'} label={'Strava'}/>
            </Grid>
            <Grid item xs={3}>
                {formikProps.values.isFood && <TextFormField name="foodCapacity" label={t('Kapacita')} type={'text'} maxlength={4}/>}
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={4}>
                {formikProps.values.isFood && <ButtonGroupField name={'foodServices'} options={foodServiceOptions} label={'Služby'} isMulti fullWidth/>}
            </Grid>

            <Grid item xs={4}>
                <RadioGroupField options={trueFalseOptions} name={'isAcm'} label={'Ubytování'}/>
            </Grid>
            <Grid item xs={8}>
            </Grid>

            <Grid item xs={4}>
                <RadioGroupField options={trueFalseOptions} name={'isPark'} label={'Parkování'}/>
            </Grid>
            <Grid item xs={3}>
                {formikProps.values.isPark && <TextFormField name="parkCapacity" label={t('Kapacita')} type={'text'} maxlength={4}/>}
            </Grid>
        </>}/>
    );
}

export default PlaceForm;
