import {JsonAddress, JsonArticleInfoSexEnum, JsonArticleSexEnum} from "../generated-api";

export const shorten = (v?: string, len?: number): string => {
    if (!v) {
        return v || '';
    }
    return v.replaceAll(/[()]/g, '').trim().split(' ')
        .map((w) => w && w.length > (len ? len + 1 : 3) ? (len ? w.substring(0, len) : w[0]) + '.' : w).join(' ');
}

export const shortenInitials = (v?: string): string => {
    if (!v) {
        return v || '';
    }
    return v.replaceAll(/[()]/g, '').trim().split(' ')
        .map((w) => w[0].toUpperCase()).join('');
}

export const shortId = (id?: number): string | number | undefined => {
    if (!id || id <= 9999) {
        return id;
    }
    let v = id + "";
    return '.' + v.substring(v.length - 3);
}

export const shortSiwi = (v?: string): string | undefined => {
    if (!v || v.length <= 4) {
        return v;
    }
    return '.' + v.substring(v.length - 3);
}

export const articleSize = (
    sex?: JsonArticleSexEnum | JsonArticleInfoSexEnum | string,
    size?: string,
    short?: boolean
): string => {
    if (short) {
        if (size === 'XXXL') {
            return '3XL';
        }
        if (size === 'XXL') {
            return '2XL';
        }
    }
    return (size || '').replace('_', '/')
        .replace('-', ' ')
        // .replace('1/3', '⅓')
        // .replace('2/3', '⅔')
        ;
}

export const renderAddress = (addr?: JsonAddress) => {
    if (!addr) {
        return null;
    }
    const {street, zip, city, country} = addr;
    return ((street || city)
        + (!!zip ? ', ' + zip : '')
        + (street ? ' ' + city : '')
        + (!!country && country !== 'CZ' ? ', ' + country : '')).trim()
}

export enum ArticleCommonSize {
    XXS = 'XXS',
    XS = 'XS',
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
    XXL = 'XXL',
    XXXL = 'XXXL',
    XS_S = 'XS_S',
    M_L = 'M_L',
    XL_XXL = 'XL_XXL',
    S_M = 'S_M',
    L_XL = 'L_XL'
}
