/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventFloor,
    ApiResultOfJsonEventFloorFromJSON,
    ApiResultOfJsonEventFloorToJSON,
    ApiResultOfJsonEventFloorInfo,
    ApiResultOfJsonEventFloorInfoFromJSON,
    ApiResultOfJsonEventFloorInfoToJSON,
    ApiResultOfListOfJsonEventFloorInfo,
    ApiResultOfListOfJsonEventFloorInfoFromJSON,
    ApiResultOfListOfJsonEventFloorInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventFloor,
    JsonEventFloorFromJSON,
    JsonEventFloorToJSON,
} from '../models';

export interface GetEventFloorCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetEventFloorInfoUsingGETRequest {
    eventFloorId: number;
}

export interface GetEventFloorListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventFloorUsingGETRequest {
    eventFloorId: number;
}

export interface SaveEventFloorUsingPOSTRequest {
    json: JsonEventFloor;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventFloorControllerApi extends runtime.BaseAPI {

    /**
     * getEventFloorCount
     */
    async getEventFloorCountUsingGETRaw(requestParameters: GetEventFloorCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventFloorCount
     */
    async getEventFloorCountUsingGET(requestParameters: GetEventFloorCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventFloorCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventFloorInfo
     */
    async getEventFloorInfoUsingGETRaw(requestParameters: GetEventFloorInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventFloorInfo>> {
        if (requestParameters.eventFloorId === null || requestParameters.eventFloorId === undefined) {
            throw new runtime.RequiredError('eventFloorId','Required parameter requestParameters.eventFloorId was null or undefined when calling getEventFloorInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor/info/{eventFloorId}`.replace(`{${"eventFloorId"}}`, encodeURIComponent(String(requestParameters.eventFloorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFloorInfoFromJSON(jsonValue));
    }

    /**
     * getEventFloorInfo
     */
    async getEventFloorInfoUsingGET(requestParameters: GetEventFloorInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventFloorInfo> {
        const response = await this.getEventFloorInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventFloorList
     */
    async getEventFloorListUsingGETRaw(requestParameters: GetEventFloorListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventFloorInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventFloorInfoFromJSON(jsonValue));
    }

    /**
     * getEventFloorList
     */
    async getEventFloorListUsingGET(requestParameters: GetEventFloorListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventFloorInfo> {
        const response = await this.getEventFloorListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventFloor
     */
    async getEventFloorUsingGETRaw(requestParameters: GetEventFloorUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventFloor>> {
        if (requestParameters.eventFloorId === null || requestParameters.eventFloorId === undefined) {
            throw new runtime.RequiredError('eventFloorId','Required parameter requestParameters.eventFloorId was null or undefined when calling getEventFloorUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-floor/{eventFloorId}`.replace(`{${"eventFloorId"}}`, encodeURIComponent(String(requestParameters.eventFloorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFloorFromJSON(jsonValue));
    }

    /**
     * getEventFloor
     */
    async getEventFloorUsingGET(requestParameters: GetEventFloorUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventFloor> {
        const response = await this.getEventFloorUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventFloor
     */
    async saveEventFloorUsingPOSTRaw(requestParameters: SaveEventFloorUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventFloor>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventFloorUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-floor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventFloorToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFloorFromJSON(jsonValue));
    }

    /**
     * saveEventFloor
     */
    async saveEventFloorUsingPOST(requestParameters: SaveEventFloorUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventFloor> {
        const response = await this.saveEventFloorUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
