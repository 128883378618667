import {useAppSelector} from "../store";
import {selectCodebooks} from "../store/selectors";
import {JsonPoplistItem} from "../generated-api";
import * as React from "react";
import {useMemo} from "react";
import {dateToGuiAs} from "../helpers/date";
import {Tooltip} from "@mui/material";

type EventDaysValueProps = {
    eventDays?: string | number[],
    siwiEventDays?: string,
    eventId?: number
}

const EventDaysValue = (props: EventDaysValueProps) => {
    const {eventDays, siwiEventDays, eventId} = props;

    const codebooks = useAppSelector(selectCodebooks);
    const eventDayCodebook = codebooks?.['eventDay'];

    return useMemo(() => {
        let humanDays = eventDays;
        let humanTooltip: string[] = [];

        const eventDayList = eventDayCodebook?.filter((item: JsonPoplistItem) => item.value?.indexOf(eventId + ':') === 0);
        const dayTitles = eventDayList?.reduce((map, item) => {
            if (item.label) {
                map[parseInt(item.label)] = dateToGuiAs(item.params?.dayDate as any as string, "d");
            }
            return map;
        }, {} as { [key in number]: string }); // 1: 27, 2: 28, 3: 1, ...

        if (eventId && eventDayList) {
            let isAll = true;
            const list = eventDayList
                .map((item, i) => {
                    const a = (typeof eventDays === 'string'
                        ? eventDays[i] === '1'
                        : eventDays && eventDays.indexOf(i + 1) >= 0);
                    if (a) {
                        humanTooltip.push(dateToGuiAs(item.params?.dayDate as any as string, "eeeee d.M.") + ' (#' + (i + 1) + ')');
                        return dayTitles[i + 1];
                    }
                    isAll = false;
                    return ' ' + (dayTitles[i + 1].length > 1 ? ' ' : '');
                });
            if (isAll) {
                humanDays = '(všechny)';
            } else {
                humanDays = list.join(' ').trimEnd();
            }
        }
        if (!humanTooltip.length && eventDays) {
            humanTooltip = typeof eventDays === 'string' ? [eventDays] : eventDays.map((ed) => '' + ed);
        }
        let siwiDays = undefined;
        if (siwiEventDays) {
            let isAll = true;
            const list = eventDayList
                .map((item, i) => {
                    const a = siwiEventDays[i] === '1';
                    if (a) {
                        return dayTitles[i + 1];
                    }
                    isAll = false;
                    return ' ' + (dayTitles[i + 1].length > 1 ? ' ' : '');
                });
            if (isAll) {
                siwiDays = '(všechny)';
            } else {
                siwiDays = list.join(' ').trimEnd();
            }
            if (siwiDays && siwiDays !== humanDays) {
                humanTooltip.push("Siwi: " + siwiDays);
            }
        }
        if (siwiDays && siwiDays !== humanDays && eventDays && siwiEventDays) {
            const emsDayNos = typeof eventDays === 'string'
                ? eventDays.split('').map((v, i) => v === '1' ? (i + 1) : 0).filter(v => !!v)
                : eventDays;
            const siwiDayNos = siwiEventDays.split('').map((v, i) => v === '1' ? (i + 1) : 0).filter(v => !!v)

            const merged: JSX.Element[] = [...emsDayNos, ...siwiDayNos]
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort((a, b) => a > b ? 1 : -1)
                .map((dayNo) => {
                    if (emsDayNos.indexOf(dayNo) >= 0) {
                        if (siwiDayNos.indexOf(dayNo) >= 0) {
                            return <span key={dayNo}>{dayTitles[dayNo]} </span>;
                        } else {
                            return <span key={dayNo} style={{color: 'var(--color-info)'}}>{dayTitles[dayNo]} </span>;
                        }
                    } else {
                        return <span key={dayNo} style={{color: 'var(--color-warning)'}}>{dayTitles[dayNo]} </span>;
                    }
                });
            return <Tooltip title={<div>
                <pre>{'EMS:  ' + (humanDays ? humanDays : '-')}</pre>
                <br/>
                <pre>{'Siwi: ' + (siwiDays ? siwiDays : '-')}</pre>
            </div>}>
                <pre className={'event-days'}>{merged}</pre>
            </Tooltip>;
        }

        return <pre className={'event-days'}
            title={humanTooltip?.join('\n')}
            style={siwiDays && siwiDays !== humanDays ? {color: 'var(--color-warning)'} : undefined}>{humanDays}</pre>;
    }, [eventDays, siwiEventDays, eventDayCodebook, eventId]);
}

export default EventDaysValue;
