/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventDay,
    ApiResultOfJsonEventDayFromJSON,
    ApiResultOfJsonEventDayToJSON,
    ApiResultOfJsonEventDayInfo,
    ApiResultOfJsonEventDayInfoFromJSON,
    ApiResultOfJsonEventDayInfoToJSON,
    ApiResultOfListOfJsonEventDayInfo,
    ApiResultOfListOfJsonEventDayInfoFromJSON,
    ApiResultOfListOfJsonEventDayInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventDay,
    JsonEventDayFromJSON,
    JsonEventDayToJSON,
} from '../models';

export interface GetEventDayCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetEventDayInfoUsingGETRequest {
    eventDayId: number;
}

export interface GetEventDayListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventDayUsingGETRequest {
    eventDayId: number;
}

export interface SaveEventDayUsingPOSTRequest {
    json: JsonEventDay;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventDayControllerApi extends runtime.BaseAPI {

    /**
     * getEventDayCount
     */
    async getEventDayCountUsingGETRaw(requestParameters: GetEventDayCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-day/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventDayCount
     */
    async getEventDayCountUsingGET(requestParameters: GetEventDayCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventDayCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventDayInfo
     */
    async getEventDayInfoUsingGETRaw(requestParameters: GetEventDayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventDayInfo>> {
        if (requestParameters.eventDayId === null || requestParameters.eventDayId === undefined) {
            throw new runtime.RequiredError('eventDayId','Required parameter requestParameters.eventDayId was null or undefined when calling getEventDayInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-day/info/{eventDayId}`.replace(`{${"eventDayId"}}`, encodeURIComponent(String(requestParameters.eventDayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventDayInfoFromJSON(jsonValue));
    }

    /**
     * getEventDayInfo
     */
    async getEventDayInfoUsingGET(requestParameters: GetEventDayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventDayInfo> {
        const response = await this.getEventDayInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventDayList
     */
    async getEventDayListUsingGETRaw(requestParameters: GetEventDayListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventDayInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-day/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventDayInfoFromJSON(jsonValue));
    }

    /**
     * getEventDayList
     */
    async getEventDayListUsingGET(requestParameters: GetEventDayListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventDayInfo> {
        const response = await this.getEventDayListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventDay
     */
    async getEventDayUsingGETRaw(requestParameters: GetEventDayUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventDay>> {
        if (requestParameters.eventDayId === null || requestParameters.eventDayId === undefined) {
            throw new runtime.RequiredError('eventDayId','Required parameter requestParameters.eventDayId was null or undefined when calling getEventDayUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-day/{eventDayId}`.replace(`{${"eventDayId"}}`, encodeURIComponent(String(requestParameters.eventDayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventDayFromJSON(jsonValue));
    }

    /**
     * getEventDay
     */
    async getEventDayUsingGET(requestParameters: GetEventDayUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventDay> {
        const response = await this.getEventDayUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventDay
     */
    async saveEventDayUsingPOSTRaw(requestParameters: SaveEventDayUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventDay>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventDayUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-day`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventDayToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventDayFromJSON(jsonValue));
    }

    /**
     * saveEventDay
     */
    async saveEventDayUsingPOST(requestParameters: SaveEventDayUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventDay> {
        const response = await this.saveEventDayUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
