import FormModal from "../components/form/FormModal";
import FileForm, {FileFormProps} from "../components/FileForm";

const FileModal = (props: FileFormProps & { title: string }) => {

    return (
        <FormModal title={props.title} onCancel={props.onCancel} maxWidth={'sm'}>
            <FileForm {...props}/>
        </FormModal>
    );
}

export default FileModal;
