/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonFileInfo
 */
export interface JsonFileInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonFileInfo
     */
    allowedActions?: Array<JsonFileInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    eventPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    eventPartyTravelId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    fileId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    fileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    fileType?: JsonFileInfoFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    mimeType?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    status?: JsonFileInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFileInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFileInfo
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonFileInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonFileInfoFileTypeEnum {
    Photo = 'PHOTO',
    Image = 'IMAGE',
    Attachment = 'ATTACHMENT',
    Travel = 'TRAVEL',
    Contract = 'CONTRACT'
}/**
* @export
* @enum {string}
*/
export enum JsonFileInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    PendingOk = 'PENDING_OK',
    PendingWarn = 'PENDING_WARN'
}

export function JsonFileInfoFromJSON(json: any): JsonFileInfo {
    return JsonFileInfoFromJSONTyped(json, false);
}

export function JsonFileInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFileInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'eventPartyTravelId': !exists(json, 'eventPartyTravelId') ? undefined : json['eventPartyTravelId'],
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'fileType': !exists(json, 'fileType') ? undefined : json['fileType'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'hash': !exists(json, 'hash') ? undefined : json['hash'],
        'mimeType': !exists(json, 'mimeType') ? undefined : json['mimeType'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonFileInfoToJSON(value?: JsonFileInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'eventPartyId': value.eventPartyId,
        'eventPartyTravelId': value.eventPartyTravelId,
        'fileId': value.fileId,
        'fileName': value.fileName,
        'fileSize': value.fileSize,
        'fileType': value.fileType,
        'guid': value.guid,
        'hash': value.hash,
        'mimeType': value.mimeType,
        'partyId': value.partyId,
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
    };
}

