import {CodebookState} from "../store/codebooks";
import {ReactNode} from "react";
import {FormikErrors} from "formik";
import {JsonArticle, JsonPoplistItem} from "../generated-api";
import {datetimeToGui, dateToGuiAs} from "../helpers/date";

export type Color = 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

export const FAKE_VALUE_EMPTY = -1;
export const FAKE_VALUE_ALL = -2;
export const FAKE_VALUE_AND = -3;
export const FAKE_VALUE_CUSTOM = -4;
export const FAKE_VALUE_RESET = -9;

export interface FormFieldProps {
    name: string;
    label?: string | JSX.Element;
    onChange?: (value: any) => void;
    onBlur?: (value: any) => void;
    disabled?: boolean,
    readonly?: boolean
}

export interface OptionValue {
    value: string | number | boolean,
    label: string,
    tooltip?: string,
    icon?: ReactNode,
    color?: Color,
    params?: { [key in string]: object }
}

export interface FormProps<E> {
    title?: string;
    item: E;
    saveButtonColor?: Color;
    saveButtonTitle?: string;
    cancelButtonTitle?: string;
    deleteButtonTitle?: string;
    deleteConfirmText?: string;
    validate?: (data: E) => FormikErrors<E> | undefined;
    onSave?: (data: E) => Promise<void> | void;
    onDelete?: (data: E) => void;
    onCancel?: () => void;
    isReadonly?: boolean;
}

export type CodebookOptionsProps = {
    codebookName: string,
    asNumber?: boolean,
    sortByKeys?: boolean,
    reversed?: boolean,
    scope?: number,
    addEmpty?: string,
    addReset?: string,
    addAnd?: string,
    addExtra?: OptionValue[],
    emptyValue?: string | number,
    allowedValues?: (string | number | boolean)[]
}

export type ImportAction<E, F> = {
    filter: F,
    onCancel?: () => void,
    onSave?: (result: E, filter: F) => void
}

export const createOption = (value: string | number | boolean, label: string, tooltip?: string, icon?: ReactNode, color?: Color): OptionValue => {
    return {
        value,
        label,
        tooltip,
        icon,
        color
    };
}

export const createOptions = (props: CodebookOptionsProps, codebooks: CodebookState): OptionValue[] => {
    const {codebookName, asNumber, sortByKeys, reversed, scope, addEmpty, addReset, addAnd, emptyValue, addExtra} = props;
    if (codebooks[codebookName]?.length > 0) {
        const options: OptionValue[] = codebooks[codebookName]
            .filter((c) => {
                return scope === undefined || ('' + c.value).indexOf(scope + ':') === 0
            })
            .map((c) => {
                const v = scope === undefined ? '' + c.value : ('' + c.value).substring((scope + ':').length);
                const value = asNumber ? parseInt(v, 10) : v as string;
                if (addEmpty && value === emptyValue) {
                    return {
                        value: FAKE_VALUE_EMPTY,
                        label: '(' + c.label + ')',
                        tooltip: addEmpty
                    };
                }
                let tooltip = undefined;
                switch (codebookName) {
                    case 'eventDay':
                        if (c.params && c.params.dayDate) {
                            tooltip = dateToGuiAs(c.params.dayDate as any as string, "eeeee d.M.");
                        }
                        break;
                }

                return {
                    value,
                    label: c.label as string,
                    tooltip,
                    params: c.params
                };
            });
        if (sortByKeys) {
            options.sort((a, b) => {
                if (a.value > b.value) {
                    return 1;
                } else if (a.value < b.value) {
                    return -1;
                }
                return 0;
            })
        }
        if (reversed) {
            return options.reverse();
        }
        if (addExtra) {
            addExtra.forEach((o) => {
                options.unshift(o);
            })
        }
        if (addReset && emptyValue !== FAKE_VALUE_RESET) {
            options.unshift({value: FAKE_VALUE_RESET, label: addReset})
        }
        if (addEmpty) {
            const o = options.find((o) => o.value === FAKE_VALUE_EMPTY);
            if (o) {
                options.splice(options.indexOf(o), 1);
                options.unshift(o);
            } else {
                options.unshift({
                    value: FAKE_VALUE_EMPTY,
                    label: (addReset && emptyValue === FAKE_VALUE_RESET ? addReset : addEmpty)
                })
            }
        }
        if (addAnd) {
            options.push({value: FAKE_VALUE_AND, label: addAnd})
        }
        if (props.allowedValues !== undefined) {
            return options.filter(o => props.allowedValues && props.allowedValues?.indexOf(o.value) >= 0);
        }

        return options;
    }
    return [];
}

export const getOption = (value: any, options: OptionValue[], scope?: number): OptionValue => {
    if (value?.label) {
        // multi
        return value as OptionValue;
    }
    const o = options
        .filter((c) => {
            return scope === undefined || ('' + c.value).indexOf(scope + ':') === 0
        })
        .find((c) => {
            const v = scope === undefined ? '' + c.value : ('' + c.value).substring((scope + ':').length);
            return v === value + '';
        });
    return o || {value, label: value};
}

export const getCodebookLabel = (codebooks: CodebookState, codebookName: string, value?: string | number, scope?: string | number): string => {
    if (!value) {
        return '';
    }
    const s = scope !== undefined ? scope + ':' + value : value;
    return codebooks[codebookName]?.find((item: JsonPoplistItem) => item.value === s || item.value === s + '')?.label || ('' + value);
}

export const trueFalseOptions: OptionValue[] = [
    {value: false, label: 'Ne'},
    {value: true, label: 'Ano'},
]

export const setNestedKey = (obj: any, path: string, val: any) => {
    const keys = path.split('.');
    const lastKey: string = keys.pop() as string;
    const lastObj = keys.reduce((obj, key) =>
            obj[key] = obj[key] || {},
        obj);
    lastObj[lastKey] = val;
};

export function isNumeric(v: any, wholeNumber?: boolean) {
    if (typeof v === 'number') {
        return !wholeNumber || (v % 1 === 0);
    }
    if (typeof v != "string") {
        return false;
    }
    if (isNaN(+v) || isNaN(parseFloat(v))) {
        return false;
    }
    return !wholeNumber || (parseFloat(v) % 1 === 0);
}

function timeParts(v: string): false | [number, number] {
    const p = v?.split(':');
    if (!(p && p.length === 2) || !isNumeric(p[0], true) || !isNumeric(p[1], true)) {
        return false;
    }
    return [parseInt(p[0], 10), parseInt(p[1], 10)];
}

export function isTime(v: string) {
    const p = timeParts(v);
    if (!p) {
        return false;
    }
    if (!(p[0] >= 0 && p[0] < 24 && p[1] >= 0 && p[1] <= 59)) {
        return false;
    }
    try {
        return !!datetimeToGui('2023-01-01', v);

    } catch (e) {
        return false;
    }
}

export function isTimeAfter(a: string, b: string) {
    const pa = timeParts(a);
    const pb = timeParts(b);
    if (!pa || !pb) {
        return undefined;
    }
    return pa[0] > pb[0] || (pa[0] === pb[0] && pa[1] > pb[1]);
}

export function formatMoney(v: string | number | undefined, pretty?: boolean) {
    if (!v) {
        return v;
    }
    if (pretty) {
        return new Intl.NumberFormat('cs-CZ', {
            style: 'currency',
            currency: 'CZK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(typeof v === 'string' ? parseFloat(v) : v);
    }

    let f = v + '';
    if (f.indexOf('.') >= 0) {
        f = f.replace('.', ',');
    }
    return f;
}

export function normalizeMoney(v: string) {
    if (v?.indexOf(',') >= 0) {
        v = v.replace(',', '.');
    }
    return v;
}

export function isAnyOtherSexSelected(inviteSex?: string, articles?: JsonArticle[]): boolean {
    return !!inviteSex && !!articles?.find((a) => a.sex !== inviteSex && a.sex !== 'U');
}
