/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonApiEventParty,
    ApiResultOfJsonApiEventPartyFromJSON,
    ApiResultOfJsonApiEventPartyToJSON,
} from '../models';

export interface GetEventPartyByBarcodeUsingGETRequest {
    barcode: string;
    token?: string;
    type?: string;
    placeId?: number;
    dateTime?: string;
    service?: string;
    testOnly?: boolean;
}

/**
 * 
 */
export class ApiControllerApi extends runtime.BaseAPI {

    /**
     * getEventPartyByBarcode
     */
    async getEventPartyByBarcodeUsingGETRaw(requestParameters: GetEventPartyByBarcodeUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonApiEventParty>> {
        if (requestParameters.barcode === null || requestParameters.barcode === undefined) {
            throw new runtime.RequiredError('barcode','Required parameter requestParameters.barcode was null or undefined when calling getEventPartyByBarcodeUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        if (requestParameters.dateTime !== undefined) {
            queryParameters['dateTime'] = requestParameters.dateTime;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/api/event-party/{barcode}`.replace(`{${"barcode"}}`, encodeURIComponent(String(requestParameters.barcode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonApiEventPartyFromJSON(jsonValue));
    }

    /**
     * getEventPartyByBarcode
     */
    async getEventPartyByBarcodeUsingGET(requestParameters: GetEventPartyByBarcodeUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonApiEventParty> {
        const response = await this.getEventPartyByBarcodeUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
