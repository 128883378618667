import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {AuthControllerApi, JsonAuthRequest, JsonAuthUserInfo} from '../generated-api';


const authApi = new AuthControllerApi(API_CONFIG);

export const fetchAuthUser = createAsyncThunk('auth/info', async (_dummy, thunkApi) => {
    return await authApi.getInfoUsingGET()
});

export const loginAuthUser = createAsyncThunk('auth/login', async (auth: JsonAuthRequest, thunkAPI) => {
    return await authApi.loginUsingPOST({auth});
});

export const forgottenPassword = createAsyncThunk('auth/forgottenPassword', async (auth: JsonAuthRequest, thunkAPI) => {
    return await authApi.forgottenPasswordUsingPOST({auth});
});

export const changePassword = createAsyncThunk('auth/changePassword', async (auth: JsonAuthRequest, thunkAPI) => {
    return await authApi.changePasswordUsingPOST({auth});
});

export const fetchAuthLogout = createAsyncThunk('auth/logout', async (_) => {
    await authApi.logoutUsingGET();
    window.location.reload(); // destroy all js data (store etc.)
});


export interface AuthState {
    user: JsonAuthUserInfo['user'];
    configuration: JsonAuthUserInfo['config'];
}

const initialState: AuthState = {
    user: undefined,
    configuration: undefined,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAuthUser.fulfilled, (state, action) => {
            state.user = action.payload?.data?.user;
            state.configuration = action.payload?.data?.config;
        });
        builder.addCase(loginAuthUser.fulfilled, (state, action) => {
            state.user = action.payload?.data?.user;
            state.configuration = action.payload?.data?.config;
        });
        builder.addCase(fetchAuthUser.rejected, (state, action) => {
            state.user = undefined;
            state.configuration = undefined;
        });
        builder.addCase(fetchAuthLogout.pending, (state, action) => initialState);
    }
});

export const authReducer = authSlice.reducer;

