import {JsonEventPartyInfo, JsonInviteReply, JsonInviteReplyReplyTypeEnum, JsonTariffInfo} from "../../generated-api";
import * as React from "react";
import {Alert, Card, CardActions, CardContent} from "@mui/material";
import {createOption, getOption} from "../../model/form";
import {PartyCompDetails} from "../party/PartyCompDetails";
import {RadioGroupField} from "../form/RadioGroupField";

const compReplyTypeOptions = [
    createOption(JsonInviteReplyReplyTypeEnum.Accept, 'Ano, výplata a účet jsou v pořádku', undefined, undefined, 'success'),
    createOption(JsonInviteReplyReplyTypeEnum.Reject, 'Ne, výplata nebo účet jsou chybně', undefined, undefined, 'error'),
];

type RsvpInviteCompProps = {
    eventParty: JsonEventPartyInfo,
    values: JsonInviteReply,
    tariffs?: JsonTariffInfo[],
    readonly?: boolean
}

export const RsvpInviteComp = (props: RsvpInviteCompProps) => {
    const {tariffs, eventParty, values, readonly} = props;

    return <Card className={'rsvp-supplier-party'}>
        <CardContent>
            <PartyCompDetails eventParty={eventParty} tariffs={tariffs} withAccount/>
            {readonly && <Alert severity={values.replyType === JsonInviteReplyReplyTypeEnum.Reject
                ? 'error'
                : 'success'}>{getOption(values.replyType, compReplyTypeOptions)?.label}</Alert>}
        </CardContent>
        {!readonly && <CardActions className={'rsvp-reply-type'}>
			<RadioGroupField options={compReplyTypeOptions} name={'replyType'}/>
		</CardActions>}
    </Card>;
}