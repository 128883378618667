import {createOption, getOption, OptionValue} from "./form";
import {
    GetEventPartyListUsingGETPartyTypesEnum,
    GetEventPartyListUsingGETSexTypeEnum,
    GetPartyListUsingGETPartyTypesEnum,
    JsonArticleInfoSexEnum,
    JsonEventParty,
    JsonEventPartyInfo,
    JsonEventPartyInfoAccredStatusEnum,
    JsonEventPartyInfoContractStatusEnum,
    JsonEventPartyInfoSiwiAccredStatusEnum,
    JsonEventPartyMassActionRequest,
    JsonEventPartyStatusEnum,
    JsonFindPartyResponse,
    JsonFindPartyResponseMatchTypesEnum,
    JsonParty,
    JsonPartyInfo,
    JsonPartyInfoPartyTypeEnum,
    JsonPartySiwiStatusEnum,
    JsonPartySiwiSyncStatusEnum,
    JsonPartyStatusEnum
} from "../generated-api";
import {datetimeToGui, dateToGui} from "../helpers/date";
import {
    AccessTimeRounded,
    BadgeOutlined,
    DeleteForeverRounded,
    Done,
    GradingRounded,
    HourglassEmptyRounded,
    NoAccountsRounded,
    Print,
    PublishedWithChangesRounded,
    QuestionMarkRounded,
    SettingsRounded,
    WarningAmberRounded
} from "@mui/icons-material";
import * as React from "react";
import {CSSProperties} from "react";
import {articleSize} from "../helpers/format";

type BasePartyInfo = {
    partyType?: string,
    firstName?: string,
    lastName?: string
    companyName?: string,
    birthDate?: string,
    partyId?: number,
    barcode?: string
}

export const partyName = (item: BasePartyInfo, full?: boolean): string => {
    if (!item) {
        return '';
    }
    if (item.partyType === JsonPartyInfoPartyTypeEnum.T || item.partyType === JsonPartyInfoPartyTypeEnum.Po) {
        if (item.companyName) {
            return (item.partyType === JsonPartyInfoPartyTypeEnum.T ? '* ' : '') + item.companyName;
        }
    }

    let n;
    if (item.lastName) {
        n = item.lastName + (item.firstName ? ' ' + item.firstName : '')
    } else if (item.firstName) {
        n = item.firstName;
    } else if (item.companyName) {
        n = item.companyName;
    }
    if (n) {
        return n + (full && item.birthDate ? ' (' + dateToGui(item.birthDate) + ')' : '');
    }

    return 'ID ' + (item.partyId || item.barcode);
}

export const parkPlates = (pp?: string) => {
    return pp?.split(',').map(v => v?.replaceAll(' ', '').trim()).filter(v => !!v).join(', ');
}

export const isNameEqual = (a?: string, b?: string): boolean => {
    if (!a || !b) {
        return false;
    }
    if (a === b) {
        return true;
    }
    const al = a.toLowerCase();
    const bl = b.toLowerCase();
    if (al === bl) {
        return true;
    }

    const an = al.normalize('NFD').replace(/\p{Diacritic}/gu, "");
    if (an === bl) {
        return true;
    }
    const bn = bl.normalize('NFD').replace(/\p{Diacritic}/gu, "");
    if (an === bn) {
        return true;
    }
    return an.replace(/sen\.|st\.|nejst\.|ml\.|nejml\.|jun\./, '').replace(/\.,/, '').trim()
        === bn.replace(/sen\.|st\.|nejst\.|ml\.|nejml\.|jun\./, '').replace(/\.,/, '').trim();
}

export const isStringEqualLenient = (a?: string, b?: string): boolean => {
    if (a === b) {
        return true;
    }
    return a?.trim() === b?.trim();
}

export const doesNameContains = (search: string, name?: string): boolean => {
    if (!search || !name) {
        return false;
    }
    if (search === name || name.indexOf(search) >= 0) {
        return true;
    }
    const s = search.toLowerCase();
    const n = name.toLowerCase();
    if (s === n || n.indexOf(s) >= 0) {
        return true;
    }

    const sn = s.normalize('NFD').replace(/\p{Diacritic}/gu, "");
    if (sn === n) {
        return true;
    }
    const nn = n.normalize('NFD').replace(/\p{Diacritic}/gu, "");
    if (sn === nn) {
        return true;
    }
    return nn.indexOf(sn) >= 0;
}


export const foundPartyScore = (f: JsonFindPartyResponse) => {
    let as = 0;
    if (!f.matchTypes) {
        return as;
    }
    if (f.matchTypes.indexOf(JsonFindPartyResponseMatchTypesEnum.Name) >= 0) {
        as += 0.1;
    }
    if (f.photoScore !== undefined && f.matchTypes.indexOf(JsonFindPartyResponseMatchTypesEnum.Photo) >= 0) {
        as += 0.9 - f.photoScore;
    }
    return as;
}

export const sortFoundParties = (items?: JsonFindPartyResponse[]): JsonFindPartyResponse[] => {
    if (!items) {
        return [];
    }
    const queryIds: string[] = [];
    items.forEach((f) => {
        if (f.queryId && queryIds.indexOf(f.queryId) < 0) {
            queryIds.push(f.queryId);
        }
    });

    const res: JsonFindPartyResponse[] = [];
    queryIds.forEach((queryId) => {
        const sorted = items
            .filter((f) => f.queryId === queryId)
            .sort((a, b) => {
                const as = foundPartyScore(a);
                const bs = foundPartyScore(b);
                return as === bs ? 0 : (as > bs ? -1 : 1);
            });
        let count = 0, maxScore = 0, limit = 4;
        sorted.forEach((f) => {
            const s = foundPartyScore(f);
            const isByName = f.matchTypes?.indexOf(JsonFindPartyResponseMatchTypesEnum.Name) !== undefined
                && f.matchTypes.indexOf(JsonFindPartyResponseMatchTypesEnum.Name) >= 0;
            if (maxScore > 0.8 && (maxScore - s) > 0.1 && !isByName) {
                return;
            }
            if (isByName) {
                limit++;
            }

            if (count >= limit) {
                return;
            }
            res.push(f);
            maxScore = s;
            count++;
        });
    });

    return res;
}

export const articleName = (article?: { sex?: string, size?: string }, short?: boolean, partySex?: string) => {
    if (!article) {
        return null;
    }
    const o = articleSexOptions.find((o) => o.value === article.sex);
    const sex = o?.label || article.sex || '';
    const isDiff = ((partySex === 'M' && article.sex === 'F') || (partySex === 'F' && article.sex === 'M'));

    const sizeLabel = articleSize(article.sex, article.size, short);
    const sexLabel = short
        ? (partySex && !isDiff ? '' : ' (' + sex[0] + ') ')
        : o?.tooltip ? ' ' + o.tooltip : '';

    return <span title={article.size + ' ' + (o?.tooltip || ('(' + article.sex + ')'))} style={isDiff ? {color: 'var(--color-warning)'} : undefined}>
            {sizeLabel}{sexLabel}
        </span>;
}

export const renderAccredStatus = (_: any, item: JsonEventPartyInfo) => {
    const style: CSSProperties = {};
    let icon, title;
    if (item.siwiAccredStatus) {
        const o = getOption(item.siwiAccredStatus, siwiAccredStatusOptions);
        icon = o.icon || item.siwiAccredStatus[0];
        title = (o.tooltip || o.label) + (item.accredAt ? ' (' + datetimeToGui(item.accredAt) + ')' : '');
    }
    if (item.accredStatus === JsonEventPartyInfoAccredStatusEnum.EmsApproved) {
        style.color = 'var(--color-info)';
        if (!icon) {
            const o = getOption(JsonEventPartyInfoSiwiAccredStatusEnum.EmsPending, siwiAccredStatusOptions);
            icon = o.icon;
            title = (o.tooltip || o.label)
        }
    }
    if (!icon) {
        return null;
    }

    return <span title={title} style={style}>{icon}</span>
}

export const mergeEventDays = (eventDays?: string, mergeEventDays?: string) => {
    let merged = '';
    for (let i = 0; i < Math.max(eventDays?.length || 0, mergeEventDays?.length || 0); i++) {
        if (eventDays?.[i] === '1' || mergeEventDays?.[i] === '1') {
            merged += '1';
        } else {
            merged += '0';
        }
    }
    return merged;
}

export interface PartyMassActionValues<F> {
    filter: F,
    parties: JsonPartyInfo[],
    eventParties: JsonEventPartyInfo[],
    values: JsonEventPartyMassActionRequest
}

export interface PartyMassActionProps<MA extends PartyMassActionValues<F>, F> {
    massAction: MA,
    onSave?: (values: MA) => any;
    onCancel?: () => void;
}

export interface EventPartyImportResult {
    parties: JsonParty[],
    eventParties: JsonEventParty[]
}

export const partyTypeOptions: OptionValue[] = [
    createOption(GetPartyListUsingGETPartyTypesEnum.Fo, 'FO', 'Fyzická osoba'),
    // createOption(GetPartyListUsingGETPartyTypesEnum.Po, 'PO', 'Právnická osoba'),
    createOption(GetPartyListUsingGETPartyTypesEnum.T, 'T', 'Technická osoba')
]

export const partyStatusOptions: OptionValue[] = [
    createOption(JsonPartyStatusEnum.Deleted, 'Neaktivní'),
    createOption(JsonPartyStatusEnum.Active, 'Aktivní'),
]

export const partySiwiStatusOptions: OptionValue[] = [
    createOption(JsonPartySiwiStatusEnum.Deleted, 'Neaktivní'),
    createOption(JsonPartySiwiStatusEnum.Active, 'Aktivní'),
]

export const eventPartyTypeOptions: OptionValue[] = [
    createOption(GetEventPartyListUsingGETPartyTypesEnum.Fo, 'FO', 'Fyzická osoba'),
    // createOption(GetEventPartyListUsingGETPartyTypesEnum.Po, 'PO', 'Právnická osoba'),
    createOption(GetEventPartyListUsingGETPartyTypesEnum.T, 'T', 'Technická osoba')
]

export const eventPartyContractStatusOptions: OptionValue[] = [
    createOption(JsonEventPartyInfoContractStatusEnum.None, 'Nepodepsána', 'Smlouva nepodepsána', <NoAccountsRounded/>),
    createOption(JsonEventPartyInfoContractStatusEnum.Signed, 'Podepsána', 'Smlouva podepsána', <GradingRounded/>),
]

export const eventPartyStatusOptions: OptionValue[] = [
    createOption(JsonEventPartyStatusEnum.Pending, 'Nepotvrzená', 'Čeká se na potvrzení účasti', <HourglassEmptyRounded/>),
    createOption(JsonEventPartyStatusEnum.Active, 'Aktivní', 'Registrována v události', <Done/>),
    createOption(JsonEventPartyStatusEnum.Deleted, 'Odstraněná', 'Osoba byla z události odstraněna', <DeleteForeverRounded/>),
]

export const sexOptions: OptionValue[] = [
    createOption(GetEventPartyListUsingGETSexTypeEnum.M, 'M', 'Muž'),
    createOption(GetEventPartyListUsingGETSexTypeEnum.F, 'Ž', 'Žena'),
]

export const articleSexOptions: OptionValue[] = [
    createOption(JsonArticleInfoSexEnum.M, 'M', '(muži)'),
    createOption(JsonArticleInfoSexEnum.F, 'Ž', '(ženy)'),
    createOption(JsonArticleInfoSexEnum.U, 'U', undefined),
]

export const isSiwiOptions: OptionValue[] = [
    createOption(true, 'SIWI', 'Je zaveden v SIWIDATA a má SIWI ID'),
    createOption(false, 'Nemá', 'Nemá SIWI ID'),
]

export const isCebOptions: OptionValue[] = [
    createOption(true, 'ČSB', 'Má evidované ČSB ID'),
    createOption(false, 'Nemá', 'Nemá ČSB ID'),
]

export const isUserOptions: OptionValue[] = [
    createOption(true, 'Uživatel', 'Má založen uživatelský přístup'),
    createOption(false, 'Ne', 'Nemá založen uživatelský přístup'),
]

export const isPhotoOptions: OptionValue[] = [
    createOption(true, 'Foto', 'Má fotografii'),
    createOption(false, 'Nemá', 'Nemá fotografii'),
]

export const isInvitedOptions: OptionValue[] = [
    createOption(true, 'Pozvaná', 'Osoba byla pozvána na událost'),
    createOption(false, 'Ne', 'Osoba nebyla pozvána na událost'),
]

export const siwiSyncStatusesOptions: OptionValue[] = [
    // createOption(JsonPartySiwiSyncStatusEnum.None, '(není)', 'xxx'),
    // createOption(JsonPartySiwiSyncStatusEnum.Synced, 'Zapsaná', 'xxx'),
    createOption(JsonPartySiwiSyncStatusEnum.Dirty, 'Čeká', 'Čeká se na propsání změn do Siwi', <HourglassEmptyRounded/>),
    createOption(JsonPartySiwiSyncStatusEnum.Conflict, 'Konflikt', 'Je nutné vyřešit možné shody', <QuestionMarkRounded/>),
    createOption(JsonPartySiwiSyncStatusEnum.Error, 'Chyba', 'Chyba synchronizace', <WarningAmberRounded/>),
]

export const isMissingInfoOptions: OptionValue[] = [
    createOption(true, 'Chybí', 'Chybí doplněné některé údaje'),
    createOption(false, 'Ne', 'Žádné údaje nechybí'),
]

export const isPrefAcmOptions: OptionValue[] = [
    createOption(true, 'MZ', 'Má zájem o ubytování'),
    createOption(false, 'Ne', 'Nemá zájem o ubytování'),
]

export const isRectArticleOptions: OptionValue[] = [
    createOption(true, 'AV', 'Artikly vydány'),
    createOption(false, 'Ne', 'Žádné artikly nevydány'),
]

export const isContractOptions: OptionValue[] = [
    createOption(true, 'Sm', 'Smlouva podepsána'),
    createOption(false, 'Ne', 'Bez podepsané smlouvy'),
]

export const isTravelOptions: OptionValue[] = [
    createOption(true, 'CN', 'Cestovní náhrady'),
    createOption(false, 'Ne', 'Bez cestovních náhrad'),
]

export const isCompOptions: OptionValue[] = [
    createOption(true, 'Od', 'Odměny'),
    createOption(false, 'Ne', 'Bez odměn'),
];

export const isPaidOptions: OptionValue[] = [
    createOption(true, 'Vy', 'Vyplaceno'),
    createOption(false, 'Ne', 'Nevyplaceno'),
];

export const siwiAccredStatusOptions: OptionValue[] = [
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.None, '(bez akr.)'),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.EmsDraft, 'Koncept', 'Rozpracovaná, zatím neodeslaná do Siwidata', <SettingsRounded/>),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.EmsPending, 'K odeslání', 'Potvrzená, čeká na odeslání do Siwidata', <PublishedWithChangesRounded/>),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.SiwiAny, '(jakákoli Siwi akr.)'),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.Entered, 'Vyžádaná', 'Čekáme na potvrzení ze strany Siwidata', <HourglassEmptyRounded/>),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.Approved, 'Potvrzená', 'Potvrzená za Siwidata, čekáme na tisk', <Done/>),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.Batchprint, 'V dávce', 'Potvrzená za Siwidata, čekáme dávkový tisk', <AccessTimeRounded/>),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.Printqueue, 'Tiskne se', 'Probíhá tisk na straně Siwidata', <Print/>),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.Printed, 'Vytištěná', 'Kartička fyzicky vytištěná', <BadgeOutlined/>),
    createOption(JsonEventPartyInfoSiwiAccredStatusEnum.Deleted, 'Zrušená', 'Zrušeno v Siwidata', <DeleteForeverRounded/>),
]

// export const isAccredOptions: OptionValue[] = [
//     createOption(true, 'Akreditace', 'Má zadanou akreditaci v Siwidata'),
//     createOption(false, 'Nemá', 'Nemá zadanou akreditaci v Siwidata'),
// ]
