import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonInviteInfo, GetInviteCountUsingGETRequest, GetInviteListUsingGETRequest, InviteControllerApi, JsonInvite, JsonInviteInfo,} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const invitesApi = new InviteControllerApi(API_CONFIG);

export const fetchInvites = createAsyncThunk('invites/list', async (filter: GetInviteListUsingGETRequest, thunkApi) => {
    return {...await invitesApi.getInviteListUsingGET(filter), filter};
});

export const fetchInvitesCount = createAsyncThunk('invites/count', async (params: GetInviteCountUsingGETRequest = {}) => {
    return await invitesApi.getInviteCountUsingGET(params);
});

export const fetchInvitesOnline = createAsyncThunk('invites/online', async (filter: GetInviteListUsingGETRequest, thunkApi) => {
    return {...await invitesApi.getInviteListUsingGET(filter), filter};
});

export const fetchInviteInfo = createAsyncThunk('invite/info', async (inviteId: number): Promise<ApiResultOfJsonInviteInfo> => {
    return await invitesApi.getInviteInfoUsingGET({inviteId});
});

export const fetchInvite = createAsyncThunk('invite/fetch', async (inviteId: number) => {
    return await invitesApi.getInviteUsingGET({inviteId});
});

export const saveInvite = createAsyncThunk('invite/save', async (invite: JsonInvite) => {
    return await invitesApi.saveInviteUsingPOST({json: invite});
});

export const invitesSlice = createSlice({
    name: 'invites',
    initialState: (): ItemsState<JsonInviteInfo> => createDefaultListState(),
    reducers: {
        clearInvites: state => createDefaultListState(),
    },
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchInvites, fetchInvitesCount);
    }
});

export const invitesReducer = invitesSlice.reducer;
export const {clearInvites} = invitesSlice.actions;
