import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonEvent, ApiResultOfJsonEventInfo, EventControllerApi, GetEventCountUsingGETRequest, GetEventListUsingGETRequest, JsonEvent, JsonEventInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const eventsApi = new EventControllerApi(API_CONFIG);

export const fetchEvents = createAsyncThunk('events/list', async (filter: GetEventListUsingGETRequest, thunkApi) => {
    return {...await eventsApi.getEventListUsingGET(filter), filter};
});

export const fetchEventsCount = createAsyncThunk('events/count', async (params: GetEventCountUsingGETRequest = {}) => {
    return await eventsApi.getEventCountUsingGET(params);
});

export const fetchEventInfo = createAsyncThunk('event/info', async (eventId: number): Promise<ApiResultOfJsonEventInfo> => {
    return await eventsApi.getEventInfoUsingGET({eventId});
});

export const fetchEvent = createAsyncThunk('event/fetch', async (eventId: number): Promise<ApiResultOfJsonEvent> => {
    return await eventsApi.getEventUsingGET({eventId});
});

export const saveEvent = createAsyncThunk('event/save', async (event: JsonEvent) => {
    return await eventsApi.saveEventUsingPOST({json: event});
});

export const eventsSlice = createSlice({
    name: 'events',
    initialState: (): ItemsState<JsonEventInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchEvents, fetchEventsCount);
    }
});

export const eventsReducer = eventsSlice.reducer;
