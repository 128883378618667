import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
    ApiResultOfJsonArticle,
    ApiResultOfJsonArticleInfo, ApiResultOfListOfJsonArticle,
    ArticleControllerApi,
    GetArticleCountUsingGETRequest,
    GetArticleListUsingGETRequest,
    JsonArticle,
    JsonArticleInfo,
    JsonArticleMassActionRequestActionEnum
} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const articlesApi = new ArticleControllerApi(API_CONFIG);

export const fetchArticles = createAsyncThunk('articles/list', async (filter: GetArticleListUsingGETRequest, thunkApi) => {
    return {...await articlesApi.getArticleListUsingGET(filter), filter};
});

export const fetchArticlesCount = createAsyncThunk('articles/count', async (params: GetArticleCountUsingGETRequest = {}) => {
    return await articlesApi.getArticleCountUsingGET(params);
});

export const fetchArticleInfo = createAsyncThunk('article/info', async (articleId: number): Promise<ApiResultOfJsonArticleInfo> => {
    return await articlesApi.getArticleInfoUsingGET({articleId});
});

export const fetchArticle = createAsyncThunk('article/fetch', async (articleId: number): Promise<ApiResultOfJsonArticle> => {
    return await articlesApi.getArticleUsingGET({articleId});
});

export const saveArticle = createAsyncThunk('article/save', async (article: JsonArticle) => {
    return await articlesApi.saveArticleUsingPOST({json: article});
});

export const saveArticles = createAsyncThunk('articles/save', async (articles: JsonArticle[]): Promise<ApiResultOfListOfJsonArticle> => {
    return await articlesApi.massArticleActionUsingPOST({
        json: {
            action: JsonArticleMassActionRequestActionEnum.Save,
            items: articles
        }
    });
});

export const articlesSlice = createSlice({
    name: 'articles',
    initialState: (): ItemsState<JsonArticleInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchArticles, fetchArticlesCount);
    }
});

export const articlesReducer = articlesSlice.reducer;
