/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonFormatAreaInfo
 */
export interface JsonFormatAreaInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonFormatAreaInfo
     */
    allowedActions?: Array<JsonFormatAreaInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatAreaInfo
     */
    areaCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatAreaInfo
     */
    areaId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatAreaInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatAreaInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatAreaInfo
     */
    formatAreaId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatAreaInfo
     */
    formatId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonFormatAreaInfo
     */
    isForbidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonFormatAreaInfo
     */
    isSet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatAreaInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatAreaInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonFormatAreaInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonFormatAreaInfoFromJSON(json: any): JsonFormatAreaInfo {
    return JsonFormatAreaInfoFromJSONTyped(json, false);
}

export function JsonFormatAreaInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFormatAreaInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'areaCode': !exists(json, 'areaCode') ? undefined : json['areaCode'],
        'areaId': !exists(json, 'areaId') ? undefined : json['areaId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'formatAreaId': !exists(json, 'formatAreaId') ? undefined : json['formatAreaId'],
        'formatId': !exists(json, 'formatId') ? undefined : json['formatId'],
        'isForbidden': !exists(json, 'isForbidden') ? undefined : json['isForbidden'],
        'isSet': !exists(json, 'isSet') ? undefined : json['isSet'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonFormatAreaInfoToJSON(value?: JsonFormatAreaInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'areaCode': value.areaCode,
        'areaId': value.areaId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'formatAreaId': value.formatAreaId,
        'formatId': value.formatId,
        'isForbidden': value.isForbidden,
        'isSet': value.isSet,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

