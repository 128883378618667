import {Alert, Button, Card, CardContent, Chip, CircularProgress, FormHelperText, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {createOption, createOptions, FAKE_VALUE_EMPTY, FAKE_VALUE_RESET, formatMoney, FormProps, getOption, isNumeric, normalizeMoney, OptionValue, setNestedKey} from "../../model/form";
import {
    JsonArticle,
    JsonArticleTypeInfo, JsonEvent,
    JsonEventPartyImportSexEnum,
    JsonEventPartyTravel,
    JsonEventPartyTravelVehicleEnum,
    JsonFindPartyRequest,
    JsonFindPartyRequestSexEnum,
    JsonFindPartyResponse,
    JsonInviteData,
    JsonInviteInfo,
    JsonInviteInfoInviteKindEnum,
    JsonInviteInfoInviteTypeEnum,
    JsonInviteInfoStatusEnum,
    JsonInviteReply,
    JsonInviteReplyDayReplyTypeEnum,
    JsonInviteReplyExtra,
    JsonInviteReplyExtraInfoApprovedStatusEnum,
    JsonInviteReplyExtraSexEnum,
    JsonInviteReplyReplyTypeEnum,
    JsonInviteReplyStatusEnum,
    JsonPartyInfo,
    JsonPlaceInfo,
    JsonReplyData,
    JsonReplyPartyData,
    JsonReplyProcessData,
    JsonTariffInfo
} from "../../generated-api";
import {TAppFunction, useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import PartyInfoBox from "../party/PartyInfoBox";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {fetchPartiesOnline, fetchPartyInfo, findParties} from "../../store/parties";
import {getApiResult} from "../../helpers/api";
import InfoBox, {InfoBoxBadge} from "../layout/InfoBox";
import {datetimeToGui, dateToGui} from "../../helpers/date";
import CodebookValue from "../CodebookValue";
import {SimpleSelectFormField} from "../form/SimpleSelectFormField";
import {articleName, sortFoundParties} from "../../model/party";
import PartyPhoto from "../party/PartyPhoto";
import {
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
    ChevronRight,
    ClearRounded,
    CloseRounded,
    DeleteForever,
    DeleteForeverRounded,
    Done,
    DoneRounded,
    EditRounded,
    FormatColorFillOutlined,
    KeyboardReturn,
    Telegram
} from "@mui/icons-material";
import {TextFormField} from "../form/TextFormField";
import {ButtonGroupField, ButtonGroupPlain} from "../form/ButtonGroupField";
import {ModalProps, useModal} from "../../services/modal";
import {selectArticleTypes, selectCodebooks} from "../../store/selectors";
import {fetchArticleTypes} from "../../store/articleTypes";
import {FoundParty} from "../party/FoundParty";
import PartyImportEditModal from "../../pages/PartyImportEditModal";
import {inviteStatusOptions, renderExtraName, updateInviteStatusOptions} from "../../model/invite";
import {fetchPlacesOnline} from "../../store/places";
import {renderFile} from "../../helpers/files";
import {formatPhone} from "../../helpers/formfield";
import {CheckboxField} from "../form/CheckboxField";
import {TravelInfo} from "../TravelInfo";
import {RsvpInviteTravelForm} from "../rsvp/RsvpInviteTravelForm";
import {fetchTariffs} from "../../store/tariffs";
import {PartyCompDetails} from "../party/PartyCompDetails";
import EventDay from "../seating/EventDay";
import {fetchEvent} from "../../store/events";

interface Props extends FormProps<JsonInviteReply> {
    invite: JsonInviteInfo,
    onEditInvite: () => void
}

const statusOptions: OptionValue[] = [
    createOption(JsonInviteReplyStatusEnum.Deleted, 'Stornovat pozvánku', 'Zcela stornuje pozvánku - akce je nevratná', <DeleteForever/>, 'error'),
    createOption(JsonInviteReplyStatusEnum.Rejected, 'Vrátit k doplnění', 'Vrátit pozvánku k doplnění / opravě', <KeyboardReturn/>, 'warning'),
    createOption(JsonInviteReplyStatusEnum.Accepted, 'Schválit a přijmout', 'Přijmout pozvánku a provést registraci', <Done/>, 'info')
];

const updateStatusOptions: OptionValue[] = [
    createOption(JsonInviteReplyStatusEnum.Deleted, 'Stornovat výzvu', 'Zcela stornuje výzvu - akce je nevratná', <DeleteForever/>, 'error'),
    createOption(JsonInviteReplyStatusEnum.Rejected, 'Vrátit k doplnění', 'Vrátit výzvu k doplnění / opravě', <KeyboardReturn/>, 'warning'),
    createOption(JsonInviteReplyStatusEnum.Accepted, 'Schválit a uložit údaje', 'Přijmout vložené údaje a uložit je', <Done/>, 'info')
];

const compStatusOptions: OptionValue[] = [
    createOption(JsonInviteReplyStatusEnum.Deleted, 'Stornovat výzvu', 'Zcela stornuje výzvu - akce je nevratná', <DeleteForever/>, 'error'),
    createOption(JsonInviteReplyStatusEnum.Rejected, 'Vrátit k nové kontrole', 'Vrátit výzvu k nové konmtrole', <KeyboardReturn/>, 'warning'),
];

export const acceptOptions: OptionValue[] = [
    createOption(true, 'Přijmout', 'Přepsat aktuální', undefined, 'info'),
    createOption(false, 'Ignorovat', 'Ponechat aktuální', undefined, 'error'),
]

const hasNewPhoto = (invite: JsonInviteInfo, reply: JsonInviteReply) => !!reply.newPhotoGuid && (reply.newPhotoGuid !== invite.photoGuid);
const hasParkingOffered = (invite: JsonInviteInfo) => !!invite?.inviteData?.isPark;
const hasExtraOffered = (invite: JsonInviteInfo) => !!invite.inviteData?.extraLimit && invite.inviteData?.extraLimit > 0;

const validate = (values: JsonInviteReply, invite: JsonInviteInfo, t: TAppFunction) => {
    let errors = {} as FormikErrors<JsonInviteReply>;

    if (values.status === JsonInviteReplyStatusEnum.Deleted) {
        if (!values.processData?.processNote) {
            setNestedKey(errors, 'processData.processNote', t('Zadejte prosím zdůvodnění storna'));
        }
        return errors;
    }
    if (values.status === JsonInviteReplyStatusEnum.Rejected) {
        if (!values.processData?.processNote) {
            setNestedKey(errors, 'processData.processNote', t('Zadejte prosím zdůvodnění vrácení - co je třeba opravit'));
        }
        return errors;
    }
    if (values.status === JsonInviteReplyStatusEnum.Accepted) {
        if (values.infoIsValid === false) {
            if (!(values.processData?.isInfoAccepted === true || values.processData?.isInfoAccepted === false)) {
                setNestedKey(errors, 'processData.isInfoAccepted', t('Zvolte prosím, zda nové údaje použít či nikoli'));
            }
        }
        if (hasNewPhoto(invite, values)) {
            if (!(values.processData?.isPhotoAccepted === true || values.processData?.isPhotoAccepted === false)) {
                setNestedKey(errors, 'processData.isPhotoAccepted', t('Zvolte prosím, zda novou fotografii použít či nikoli'));
            }
        }

        const isSupplier = invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier;
        if (hasExtraOffered(invite) || isSupplier) {
            const partyIds: number[] = [];
            values.replyData?.extras?.forEach((extra) => {
                if (!isSupplier && !isExtraActive(values.replyData, invite.inviteData, extra)) {
                    return;
                }
                const pe = values.processData?.extras && !!extra.extraId && values.processData?.extras[extra.extraId];
                if (!pe || !pe.partyId) {
                    setNestedKey(errors, 'processData.extras.' + extra.extraId + '.partyId',
                        isSupplier
                            ? t('Potvrďte prosím osobu')
                            : t('Potvrďte prosím doprovod'));
                } else if (pe.partyId > 0) {
                    if (partyIds.indexOf(pe.partyId) >= 0) {
                        setNestedKey(errors, 'processData.extras.' + extra.extraId + '.partyId',
                            t('Osoba je spojená duplicitně'));
                    }
                    partyIds.push(pe.partyId);
                }
            });
        }

        if (hasParkingOffered(invite) && values.replyData?.replyDays) {
            Object.keys(values.replyData.replyDays).forEach((k) => {
                const dayNo = parseInt(k, 10);
                if (!invite.inviteData?.eventDays || invite.inviteData.eventDays.indexOf(dayNo) < 0) {
                    return;
                }

                const rd = values.replyData?.replyDays?.[dayNo as number];
                if (rd?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept && rd.isParking) {
                    const eventPartyDay = !!values.processData?.eventPartyDays
                        && values.processData?.eventPartyDays[invite.partyId || 0][dayNo];
                    if (!eventPartyDay || !eventPartyDay.parkId) {
                        setNestedKey(errors, 'processData.eventPartyDays.' + invite.partyId + '.' + dayNo + '.parkId',
                            t('Vyberte prosím'));
                    }
                }
            })
        }

        if (invite.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg && values.replyData?.travels) {
            values.replyData?.travels.forEach((travel) => {
                const pt = values.processData?.travels && !!travel.eventPartyTravelId && values.processData.travels[travel.eventPartyTravelId];
                if (!pt || !pt.eventPartyId) {
                    setNestedKey(errors, 'processData.travels.' + travel.eventPartyTravelId + '.eventPartyId',
                        t('Potvrďte prosím cestu'));
                } else if (pt.eventPartyId === FAKE_VALUE_EMPTY) {
                    if (!pt.compAmount) {
                        setNestedKey(errors, 'processData.travels.' + travel.eventPartyTravelId + '.compAmount',
                            t('Zadejte prosím částku'));
                    } else if (!isNumeric(pt.compAmount) || pt.compAmount <= 1) {
                        setNestedKey(errors, 'processData.travels.' + travel.eventPartyTravelId + '.compAmount',
                            t('Neplatná částka'));
                    }
                }
            })
        }

    } else {
        errors.status = t('Zvolte prosím akci');
    }
    return errors;
}

type InviteReviewSummaryProps = {
    values: JsonInviteReply,
    invite: JsonInviteInfo,
    articleTypes: JsonArticleTypeInfo[] | undefined,
    extras: JsonInviteReplyExtra[],
    foundParties: JsonFindPartyResponse[] | undefined,
    eventKmRate: number,
}

const InviteReviewSummary = (props: InviteReviewSummaryProps) => {
    const {values, invite, articleTypes, extras, foundParties, eventKmRate} = props;
    const {processData} = values;

    const t = useAppTranslation();

    const isNewPhoto = hasNewPhoto(invite, values);
    const isNewPhotoAccepted = isNewPhoto && processData?.isPhotoAccepted;
    const isSupplier = invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier;
    const isPersonal = invite.inviteType !== JsonInviteInfoInviteTypeEnum.TravelOrg && invite.inviteType !== JsonInviteInfoInviteTypeEnum.CompOrg;
    const isExtraOffered = isSupplier || hasExtraOffered(invite);

    return <div className={'rsvp rsvp-summary rsvp-summary-' + invite.inviteType}>
        {isPersonal && <Card>
			<CardContent>
				<Grid container>
					<Grid item xs={2} className={'rsvp-head'}>
						<p>{invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg ? t('Organizátor') : t('Pozvaná osoba')}</p>
					</Grid>
					<Grid item xs={10}>
						<Grid container className={'rsvp-info'}>
							<Grid item xs={isNewPhotoAccepted ? 6 : 8}>
                                {isNewPhoto && <p><strong>{processData?.isPhotoAccepted
                                    ? t('Změna fotografie byla přijata.')
                                    : t('Změna fotografie byla zamítnuta.')}
								</strong></p>}
                                {!values.infoIsValid && <p><strong>{processData?.isInfoAccepted
                                    ? t('Změna údajů byla přijata.')
                                    : t('Změna údajů byla zamítnuta.')}
								</strong></p>}
								<p>
									<strong>
                                        {!values.infoIsValid && processData?.isInfoAccepted && (invite.firstName !== values.newFirstName)
                                            ? invite.firstName + ' → ' + values.newFirstName
                                            : invite.firstName}
										<br/>
                                        {!values.infoIsValid && processData?.isInfoAccepted && (invite.lastName !== values.newLastName)
                                            ? invite.lastName + ' → ' + values.newLastName
                                            : invite.lastName}
									</strong>
								</p>
							</Grid>
							<Grid item xs={isNewPhotoAccepted ? 6 : 4}>
                                {isNewPhotoAccepted
                                    ? <Grid container columns={13}>
                                        <Grid item xs={6}>
                                            <PartyPhoto photoGuid={invite.photoGuid}/>
                                        </Grid>
                                        <Grid item xs={1} className={'rsvp-extra-match-chevron'}>
                                            <ChevronRight/>
                                        </Grid>
                                        <Grid item xs={6} className={'rsvp-extra-match-extra'}>
                                            <PartyPhoto photoGuid={values.newPhotoGuid}/>
                                        </Grid>
                                    </Grid>
                                    : <PartyPhoto photoGuid={invite.photoGuid} maxHeight={180} maxWidth={180}/>}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}

        {!!invite.inviteData?.eventDays && invite.inviteData?.eventDays.length > 0 && <Card>
			<CardContent>
				<Grid container>
					<Grid item xs={2} className={'rsvp-head'}>
						<p>{t('Přijaté dny')}</p>
					</Grid>
					<Grid item xs={10}>
						<InviteDays invite={invite} reply={values} isReadonly={true}/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}

        {values.replyData?.articles && values.replyData?.articles.length > 0 && <Card>
			<CardContent>
				<Grid container>
					<Grid item xs={2} className={'rsvp-head'}>
						<p>{t('Artikly')}</p>
					</Grid>
					<Grid item xs={10}>
						<Articles articles={values.replyData.articles} articleTypes={articleTypes} inviteSex={invite.sex}/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}

        {!!values.replyData?.partyData && invite.inviteKind !== JsonInviteInfoInviteKindEnum.Comp && <Card>
			<CardContent>
				<Grid container>
					<Grid item xs={2} className={'rsvp-head'}>
						<p>{t('Další informace')}</p>
					</Grid>
					<Grid item xs={10}>
						<PartyData partyData={values.replyData.partyData}/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}

        {isExtraOffered && <Card>
			<CardContent>
				<Grid container>
                    {!isSupplier && <Grid item xs={2} className={'rsvp-head'}>
						<p>{t('Doprovod')}</p>
					</Grid>}
					<Grid item xs={isSupplier ? 12 : 10}>
						<Extras invite={invite} values={values} foundParties={foundParties} originalExtras={extras} formikProps={undefined}/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}

        {!!values.replyData?.travels && <Card>
			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<Travels invite={invite} values={values} originalTravels={values.replyData?.travels} formikProps={undefined}
							eventKmRate={eventKmRate}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>}

        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={2} className={'rsvp-head'}>
                        <span>{t('Volitelná odpověď')}</span>
                    </Grid>
                    <Grid item xs={10}>
                        {processData?.processNote
                            ? <pre className={'note'} style={{background: 'transparent'}}>{processData.processNote}</pre>
                            : <em>{t('Nevyplněna')}</em>}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        {!!values.skipEmail && <Alert severity={'warning'} sx={{margin: '10px 0'}}>{t('Ke přijetí nebude odeslán žádný email.')}</Alert>}
    </div>
}

export const isExtraActive = (replyData: JsonReplyData | undefined, inviteData: JsonInviteData | undefined, extra: JsonInviteReplyExtra): boolean => {
    return !!extra.eventDayNos?.find((dayNo) => dayNo
        && inviteData?.eventDays?.indexOf && inviteData.eventDays.indexOf(dayNo) >= 0
        && replyData?.replyDays?.[dayNo]?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept);
}

const InviteDays = ({invite, reply, isReadonly, setFieldValue}: { invite: JsonInviteInfo, reply: JsonInviteReply, isReadonly: boolean, setFieldValue?: FormikProps<any>['setFieldValue'] }) => {

    const {inviteData, partyId, eventId} = invite;
    const {replyData, processData} = reply;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector(selectCodebooks);
    const [placeInfos, setPlaceInfos] = useState<JsonPlaceInfo[] | undefined>(undefined);

    const isParkingOffered = hasParkingOffered(invite);
    const isExtraOffered = hasExtraOffered(invite);

    const dayNumbers: number[] = useMemo(() => {
        return inviteData?.eventDays || [];
    }, [inviteData]);

    const parkingOptions: { [key in number]: OptionValue[] } = useMemo(() => {
        const parkingOptions: { [key in number]: OptionValue[] } = {};
        if (!isParkingOffered || !codebooks || !codebooks['placePark']) {
            return parkingOptions;
        }

        const options = createOptions({codebookName: 'placePark', scope: eventId, asNumber: true, addEmpty: t('Bez parkování')}, codebooks);
        dayNumbers.forEach((dayNo) => {
            parkingOptions[dayNo] = options.map((o) => {
                const p = placeInfos?.find((p) => p.placeId === o.value);
                const pt = p?.placeTotals?.find((pt) => pt.dayNo === dayNo && pt.type === 'park');
                return {
                    ...o,
                    label: !!p
                        ? (o.label + ' (' + (pt?.count || 0) + '/' + p.parkCapacity + ')')
                        : o.label
                }
            });
        });

        return parkingOptions;

    }, [placeInfos, isParkingOffered, dayNumbers, codebooks, eventId, t]);

    const parking = useCallback((
        isAccepted: boolean,
        isParking: boolean,
        dayNo: number,
        processData?: JsonReplyProcessData) => {
        if (!isAccepted) {
            return null;
        }
        if (isParking) {
            if (isReadonly) {
                const parkId = processData?.eventPartyDays
                && partyId
                && processData.eventPartyDays[partyId]
                    ? processData.eventPartyDays[partyId][dayNo]?.parkId
                    : undefined;
                if (parkId === FAKE_VALUE_EMPTY) {
                    return <strong>{t('Bez parkování')}</strong>;
                } else {
                    const parkCnt = partyId ? processData?.eventPartyDays?.[partyId]?.[dayNo]?.parkCnt : undefined;
                    return <strong>
                        <CodebookValue name={'placePark'} value={parkId} scope={eventId}/>
                        {parkCnt && parkCnt > 1 ? ' (' + parkCnt + 'x)' : null}
                    </strong>;
                }
            } else {
                return <Grid container columnSpacing={1}>
                    <Grid item xs={9}>
                        <SimpleSelectFormField name={'processData.eventPartyDays.' + partyId + '.' + dayNo + '.parkId'}
                            placeholder={'Parkování'} options={parkingOptions[dayNo]}/>
                    </Grid>
                    <Grid item xs={3} sx={{marginTop: '-10px'}}>
                        <TextFormField name={'processData.eventPartyDays.' + partyId + '.' + dayNo + '.parkCnt'}
                            placeholder={'1'} maxlength={2}/>
                    </Grid>
                </Grid>;
            }
        } else {
            return <>{t('(nechce)')}</>
        }
    }, [parkingOptions, partyId, eventId, isReadonly, t]);

    useEffect(() => {
        if (isParkingOffered) {
            dispatch(fetchPlacesOnline({eventId})).then((res) => {
                setPlaceInfos(getApiResult(res));
            });
        }
    }, [isParkingOffered, eventId, dispatch]);

    const parkingButtons = useMemo(() => {
        if (!partyId || isReadonly || !setFieldValue || !processData?.eventPartyDays?.[partyId] || !isParkingOffered) {
            return null;
        }
        const parkingDayNumbers = dayNumbers.filter((dayNo) => {
            const rd = replyData?.replyDays?.[dayNo];
            return rd?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept;
        });
        const partyEventDays = processData.eventPartyDays[partyId];

        const buttons = [];
        if (!!partyEventDays
            && !!parkingDayNumbers.find(dayNo => !!partyEventDays[dayNo]?.parkId)) {
            buttons.push(<Button key={1} size={'small'} title={t('Doplnit všechny prázdné')} onClick={() => {
                if (partyId && partyEventDays) {
                    let lastParkId: number | undefined = undefined;
                    let lastParkCnt: number | undefined = undefined;
                    parkingDayNumbers.forEach((dayNo) => {
                        const epd = partyEventDays[dayNo];
                        if (epd?.parkId) {
                            lastParkId = epd.parkId;
                        } else if (!epd?.parkId && lastParkId) {
                            setFieldValue('processData.eventPartyDays.' + partyId + '.' + dayNo + '.parkId', lastParkId, true);
                        }
                        if (epd?.parkCnt) {
                            lastParkCnt = epd.parkCnt;
                        } else if (!epd?.parkCnt && lastParkCnt) {
                            setFieldValue('processData.eventPartyDays.' + partyId + '.' + dayNo + '.parkCnt', lastParkCnt, true);
                        }
                    });
                }
            }}><FormatColorFillOutlined/></Button>);

        }

        if (!!partyEventDays
            && !!parkingDayNumbers.find(dayNo => !!partyEventDays[dayNo]?.parkId || !!partyEventDays[dayNo]?.parkCnt)) {
            buttons.push(<Button key={2} size={'small'} title={t('Vymazat vše')} onClick={() => {
                if (partyId && partyEventDays) {
                    parkingDayNumbers.forEach((dayNo) => {
                        const epd = partyEventDays[dayNo];
                        if (!epd) {
                            return;
                        }
                        setFieldValue('processData.eventPartyDays.' + partyId + '.' + dayNo + '.parkId', undefined, true);
                        setFieldValue('processData.eventPartyDays.' + partyId + '.' + dayNo + '.parkCnt', undefined, true);
                    });
                }
            }}><ClearRounded/></Button>);
        }

        return buttons.length ? buttons : null;

    }, [processData, replyData, dayNumbers, isParkingOffered, setFieldValue, isReadonly, partyId, t]);

    return <TableContainer component={Paper}>
        <Table className={'data-grid data-grid-sticky-header'}>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: '8%', textAlign: 'center'}}><span>{t('Den')}</span></TableCell>
                    <TableCell style={{width: '13%', textAlign: 'center'}}><span>{t('Účast')}</span></TableCell>
                    {isParkingOffered && <TableCell style={{width: '45%', textAlign: 'center'}}>
						<span>{t('Parkování')}</span>
                        {parkingButtons}
					</TableCell>}
                    {isExtraOffered && <TableCell><span>{t('Doprovod')}</span></TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {dayNumbers.map((dayNo) => {
                    const rd = replyData?.replyDays?.[dayNo];
                    const isAccepted = rd?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept;
                    const isParking = !!(isAccepted && isParkingOffered && rd?.isParking);
                    const extras = replyData?.extras?.filter((e) => e.eventDayNos && e.eventDayNos.indexOf(dayNo) >= 0) || [];
                    const extraList = extras.map((extra, i) => {
                        return <div key={i}>{renderExtraName(extra, processData, i, eventId)}</div>;
                    });

                    return <TableRow key={dayNo}>
                        <TableCell sx={{textAlign: 'center'}}><EventDay dayNo={dayNo} eventId={eventId}/></TableCell>
                        <TableCell sx={{textAlign: 'center'}}><span>{isAccepted ? <strong>{t('Ano')}</strong> : t('(ne)')}</span></TableCell>
                        {isParkingOffered && <TableCell sx={{textAlign: 'center'}}>
							<span>{parking(isAccepted, isParking, dayNo, processData)}</span>
						</TableCell>}
                        {isExtraOffered && <TableCell>{isAccepted && <span>
                            {extraList?.filter(a => !!a)?.length ? extraList : t('(bez doprovodu)')}
                        </span>}
						</TableCell>}
                    </TableRow>;
                })}
            </TableBody>
        </Table>
    </TableContainer>;
}

type ExtrasProps = {
    invite: JsonInviteInfo,
    values: JsonInviteReply,
    foundParties: JsonFindPartyResponse[] | undefined,
    originalExtras: JsonInviteReplyExtra[],
    formikProps?: FormikProps<JsonInviteReply>,
    setFoundParties?: (fp?: JsonFindPartyResponse[]) => void
}

type ShowMode = 'new' | 'changed' | 'exact' | 'all';

const Extras = (props: ExtrasProps) => {

    const {invite, values, foundParties, originalExtras, formikProps, setFoundParties} = props;

    const {processData, replyData} = values;
    const readonly = formikProps === undefined;

    const t = useAppTranslation();
    const [showMode, setShowMode] = useState<ShowMode>('all');
    const [editItem, setEditItem] = useState<JsonInviteReplyExtra | undefined>(undefined);
    const original = !!editItem ? originalExtras?.find((e) => e.extraId === editItem.extraId) : undefined;

    const extras = invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier
        ? replyData?.extras
        : replyData?.extras?.filter((e) => isExtraActive(replyData, invite.inviteData, e));

    const filterItems = useCallback((items?: JsonInviteReplyExtra[], showMode?: ShowMode, foundParties?: JsonFindPartyResponse[]) => {
        let totalCount = 0, exactCount = 0, changedCount = 0, newCount = 0;
        const filtered = items?.filter((item) => {
            totalCount++;
            const foundParty = !!item.partyId && item.partyId !== FAKE_VALUE_EMPTY
                ? foundParties?.find((fp) => fp.party?.partyId === item.partyId)?.party
                : undefined;

            if (!foundParty) {
                newCount++;
                return showMode === 'all' || showMode === 'new';
            }

            if (foundParty.photoGuid !== item.photoGuid
                || foundParty.firstName !== item.firstName
                || foundParty.lastName !== item.lastName
                || foundParty.sex !== item.sex) {
                changedCount++;
                return showMode === 'all' || showMode === 'changed';
            }

            exactCount++;
            return showMode === 'all' || showMode === 'exact';
        })
        return {filtered, totalCount, exactCount, changedCount, newCount};
    }, []);

    const formikErrors = formikProps?.errors;
    const setFieldValue = formikProps?.setFieldValue;
    const submitCount = formikProps?.submitCount;
    const isSubmitting = formikProps?.isSubmitting;
    const isSupplier = invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier;

    const extraItems: JSX.Element = useMemo(() => {
        const {filtered, totalCount, exactCount, changedCount, newCount} = filterItems(extras, showMode, foundParties);

        const showModeOptions: OptionValue[] = [
            createOption('all', 'Všechny záznamy', 'Zobrazit vše',
                <span style={{padding: '0 5px'}}>{totalCount}</span>, 'secondary'),
        ];

        if (newCount) {
            showModeOptions.push(createOption('new', 'Zadané jako nové osoby', 'Zobrazit jen osoby s chybou',
                <span style={{padding: '0 5px'}}>{newCount}</span>, 'warning'));
        }
        if (changedCount) {
            showModeOptions.push(createOption('changed', 'Spojené se změnami', 'Zobrazit jen osoby v pořádku',
                <span style={{padding: '0 5px'}}>{changedCount}</span>, 'secondary'));
        }
        if (exactCount) {
            showModeOptions.push(createOption('exact', 'Spojené beze změn', 'Zobrazit jen osoby v pořádku',
                <span style={{padding: '0 5px'}}>{exactCount}</span>, 'success'));
        }

        const filter = isSupplier && foundParties !== undefined
            ? <Grid item xs={!setFoundParties ? 12 : 6} sx={{marginTop: !setFoundParties ? '10px' : '-30px'}}>
                <ButtonGroupPlain name={'showMode'} options={showModeOptions} currentValue={showMode} onChange={(v) => {
                    setShowMode(v || showMode);
                }} fullWidth/>
            </Grid>
            : null;

        const items = filtered?.map((extra) => {
            const extraId: number = extra.extraId as number;
            const extraIndex: number = extras?.findIndex((e) => e.extraId === extraId) || 0;
            const selectedPartyId: number = (processData?.extras ? processData.extras[extraId]?.partyId : undefined) || 0;
            const found: JSX.Element[] = foundParties?.filter((fp) =>
                fp.party && fp.queryId === "" + extraId
                && (!readonly || (processData?.extras && processData.extras[extraId]?.partyId === fp.party.partyId))
                && (readonly || selectedPartyId === 0 || fp.party.partyId === selectedPartyId)
            ).map((fp, j) => {
                return <FoundParty key={j} eventId={invite.eventId}
                    maxWidth={setFoundParties ? (isSupplier ? 100 : 120) : 100}
                    maxHeight={setFoundParties ? (isSupplier ? 140 : 160) : 100}
                    readonly={!setFieldValue}
                    sourceParty={extra as JsonPartyInfo}
                    foundPartyResponse={fp}
                    targetParty={processData?.extras?.[extraId] as JsonPartyInfo}
                    handleValueChange={!!setFieldValue ? (n, v) => {
                        setFieldValue('processData.extras.' + extraId + '.' + n, v, true);
                    } : undefined}/>
            }) || [];
            if (!found.length && foundParties !== undefined) {
                let alert;
                if (selectedPartyId === FAKE_VALUE_RESET) {
                    alert = <Alert severity={'error'} icon={false}>{t('Osoba odmítnuta')}</Alert>
                } else if (selectedPartyId === FAKE_VALUE_EMPTY) {
                    alert = <Alert severity={'success'} icon={false}>{t('Nová osoba')}</Alert>
                } else {
                    alert = <Alert severity={'warning'} icon={false}>
                        {t('Nenalezeny žádné shody dle jména a/nebo fotografie, osobu bude nutné založit.')}
                    </Alert>;
                }

                found.push(<Grid key={0} item xs={12} className={'rsvp-extra-match-alert'}>
                    <div>
                        {alert}
                    </div>
                </Grid>);
            }

            const isSelected = processData?.extras && processData.extras[extraId]?.partyId === FAKE_VALUE_EMPTY;
            const isDeleted = processData?.extras && processData.extras[extraId]?.partyId === FAKE_VALUE_RESET;
            const ee = (formikErrors?.processData as JsonReplyProcessData)?.extras;
            const error = !!ee && ee[extraId]?.partyId;
            const hasError = !!submitCount && submitCount > 0 && !!error;
            const approvedStatus = invite.replyExtras?.find((re) => re.extraId === extra.extraId)?.approvedStatus;

            return <Grid key={extraId} item xs={12} className={'rsvp-extra-match'}>
                <Grid container columns={readonly ? 17 : 25}>
                    <Grid item xs={8}>
                        <Card className={'rsvp-extra-match-box'
                            + (isSelected ? ' rsvp-extra-match-selected ' : '')
                            + (isDeleted ? ' rsvp-extra-match-deleted ' : '')
                            + (hasError ? ' Mui-error' : '')}>
                            <CardContent sx={{alignSelf: 'center'}}>
                                <Grid container>
                                    <Grid item xs={6} className={'rsvp-extra-match-box-info'} sx={{display: 'flex', flexDirection: 'column'}}>
                                        <h6>{extra.firstName}<br/>{extra.lastName}</h6>
                                        <div className={'rsvp-extra-match-approved'}>
                                            {approvedStatus === JsonInviteReplyExtraInfoApprovedStatusEnum.Approved
                                                && <Chip label={<DoneRounded/>} color={'success'} title={'Označeno k přijetí'}/>

                                            }
                                            {approvedStatus === JsonInviteReplyExtraInfoApprovedStatusEnum.Rejected
                                                && <Chip label={<CloseRounded/>} color={'error'} title={'Označeno k zamítnutí'}/>
                                            }
                                            {isSupplier && <span>#{extraIndex + 1}</span>}
                                        </div>
                                        <strong><CodebookValue value={extra.sex} name={'sex'} formatValue={(v) => v[0]}/></strong>
                                        {!!extra.eventDayNos &&
											<div>{t('Den')} {extra.eventDayNos
                                                .filter((dayNo) => replyData?.replyDays?.[dayNo]?.replyType === JsonInviteReplyDayReplyTypeEnum.Accept)
                                                .sort((a, b) => a > b ? 1 : -1)
                                                .map(dayNo => <EventDay key={dayNo} dayNo={dayNo} eventId={invite.eventId}/>)}</div>}

                                        {!readonly && setFieldValue && <Grid container columnSpacing={2} sx={{marginTop: '5px', marginBottom: '-5px'}}>
											<Grid item xs={6}>
												<Button title={t('Upravit údaje o doprovodu')} variant={'contained'} size={'small'} fullWidth
													disabled={foundParties === undefined}
													color={'inherit'}
													onClick={() => {
                                                        setEditItem(extra);
                                                    }}>
													<EditRounded/>
												</Button>
											</Grid>
											<Grid item xs={6}>
												<Button title={t('Odmítnout doprovod a ignorovat ho')} variant={'contained'} size={'small'} fullWidth
													disabled={foundParties === undefined}
													color={isDeleted ? 'error' : 'inherit'}
													onClick={() => {
                                                        setFieldValue('processData.extras.' + extraId + '.partyId', isDeleted ? undefined : FAKE_VALUE_RESET, true);
                                                    }}>
													<DeleteForeverRounded/>
												</Button>
											</Grid>
										</Grid>}

                                        {!readonly && setFieldValue && <Button title={undefined} variant={'contained'} size={'small'} fullWidth style={{marginBottom: '-5px'}}
											disabled={foundParties === undefined}
											color={isSelected ? 'info' : 'inherit'}
											onClick={() => {
                                                setFieldValue('processData.extras.' + extraId + '.partyId', isSelected ? undefined : FAKE_VALUE_EMPTY, true);
                                            }}>
                                            {isSelected ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
											<span>{t('Založit osobu')}</span>
										</Button>}
                                        <input name={'processData.extras.' + extraId + '.partyId'} style={{display: 'none'}}/>
                                        {hasError && <FormHelperText className={'Mui-error'} style={{margin: '10px 0 -15px 0'}}>{error}</FormHelperText>}

                                    </Grid>
                                    <Grid item xs={6} className={'rsvp-extra-match-box-photo'} sx={{marginBottom: '-10px'}}>
                                        <PartyPhoto photoGuid={extra.photoGuid}
                                            maxWidth={setFoundParties ? (isSupplier ? 100 : 120) : 100}
                                            maxHeight={setFoundParties ? (isSupplier ? 140 : 160) : 100}
                                        />
                                    </Grid>
                                </Grid>
                                {!readonly && !((processData?.extras?.[extraId]?.partyId || 0) > 0) && !!extra.photoCvResult && extra.photoCvResult !== 'OK' && <Alert severity={'error'}>
									<p>{t('Fotografie dle automatické kontroly nevyhovuje požadavkům, není dle ní možné hledat a ani nebyla správně oříznuta.')}</p>
									<p>{t('Pokud osobu chcete založit jako novou, prosím nahrajte jinou nebo pozvánku vraťte k doplnění.')}</p>
								</Alert>}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={1} className={'rsvp-extra-match-chevron'}>
                        <div>
                            <ChevronRight/>
                        </div>
                    </Grid>
                    <Grid item xs={readonly ? 8 : 16} className={'rsvp-extra-match-extra'}>
                        {!readonly && foundParties === undefined
                            ? <div style={{display: 'block', margin: '105px 0 0 20px'}}>
                                <CircularProgress size={30} color={'info'}/>
                            </div>
                            : (found && <Grid container spacing={1}>
                                {found}
							</Grid>)}
                    </Grid>
                </Grid>
            </Grid>;
        });

        return <>
            {filter}
            {items}
        </>;

    }, [extras, showMode, foundParties,
        invite.eventId, invite.replyExtras, processData?.extras, replyData?.replyDays,
        readonly, filterItems, isSupplier,
        setFieldValue, formikErrors, submitCount,
        setFoundParties, t]);

    useEffect(() => {
        if (isSubmitting && formikErrors?.processData) {
            setShowMode('all');
        }
    }, [formikErrors, isSubmitting, setShowMode]);

    return <>
        <Grid container className={'rsvp-extra-match-wrapper'}>
            {isSupplier
                ? <>
                    <Grid item xs={!setFoundParties ? 12 : 6}>
                        <div>{t('Celkem zadáno osob personálu: {{count}} (z toho nových: {{added}})', {
                            count: extras?.length,
                            added: extras?.filter((e) => e.partyId === FAKE_VALUE_EMPTY)?.length,
                        })}</div>
                    </Grid>
                    {(readonly || foundParties !== undefined)
                        ? extraItems
                        : <LinearProgress sx={{margin: '10px 0'}}/>}
                </>
                : extraItems
            }
        </Grid>
        {!!editItem && !!formikProps && <PartyImportEditModal
			title={'Zpracování doprovodu'}
			eventId={invite.eventId}
			item={{
                firstName: editItem.firstName,
                lastName: editItem.lastName,
                photoGuid: editItem.photoGuid,
                sex: !!editItem.sex ? JsonEventPartyImportSexEnum[editItem.sex] : undefined,
                foundParties: foundParties?.filter((fp) => fp.queryId === "" + editItem?.extraId)
            }}
			original={{
                firstName: original?.firstName,
                lastName: original?.lastName,
                photoGuid: original?.photoGuid,
                sex: !!original?.sex ? JsonEventPartyImportSexEnum[original.sex] : undefined,
                foundParties: foundParties?.filter((fp) => fp.queryId === "" + editItem?.extraId)
            }}
			onSave={(party) => {
                const index = replyData?.extras?.findIndex((e) => e.extraId === editItem.extraId);
                if (index !== undefined && replyData?.extras?.[index]) {
                    replyData.extras[index] = {
                        ...replyData.extras[index],
                        firstName: party.firstName,
                        lastName: party.lastName,
                        photoGuid: party.photoGuid,
                        photoCvResult: (party as any).photoCvResult,
                        sex: !!party.sex ? JsonInviteReplyExtraSexEnum[party.sex] : undefined
                    };
                    formikProps.setFieldValue('replyData.extras', replyData.extras);
                }
                if (setFoundParties) {
                    setFoundParties([
                        ...(foundParties?.filter((fp) => fp.queryId !== "" + editItem?.extraId) || []),
                        ...(party.foundParties?.map((fp) => ({...fp, queryId: "" + editItem?.extraId})) || [])]);
                }
                formikProps.setFieldValue('processData.extras.' + editItem.extraId, party.resultParty, true);
                setEditItem(undefined)
            }}
			onCancel={() => setEditItem(undefined)}
		/>}
    </>
}

const Articles = ({articles, articleTypes, inviteSex}: { articles: JsonArticle[], articleTypes?: JsonArticleTypeInfo[], inviteSex?: string }) => {
    return <Grid container>
        <Grid item xs={12}>
            <table className={'info-box-table'} style={{width: "100%"}}>
                <tbody>
                {!!articleTypes && articleTypes.map((at, i) => {
                    const v = articles?.find((a) => a.articleTypeId === at.articleTypeId);
                    return <tr key={i}>
                        <td style={{width: '100px'}}>{at.title}</td>
                        <th>{!v?.size ? '-' : articleName(v, true, inviteSex)}</th>
                    </tr>
                })}
                </tbody>
            </table>
        </Grid>
    </Grid>
}

const PartyData = ({partyData}: { partyData: JsonReplyPartyData }) => {
    const t = useAppTranslation();

    return <Grid container>
        <Grid item xs={12}>
            <table className={'info-box-table'} style={{width: "100%"}}>
                <tbody>
                <tr>
                    <td style={{width: '37%'}}>{t('Bankovní účet')}</td>
                    <th style={{width: '63%'}}>{partyData?.bankAccount}</th>
                </tr>
                <tr>
                    <td>{t('Datum narození')}</td>
                    <th>{dateToGui(partyData?.birthDate)}</th>
                </tr>
                <tr>
                    <td>{t('Telefon')}</td>
                    <th>{formatPhone(partyData?.phone)}</th>
                </tr>
                <tr>
                    <td>{t('Trvalé bydliště')}</td>
                    <th>{partyData?.permAddress?.street}<br/>
                        {partyData?.permAddress?.zip}, {partyData?.permAddress?.city}, {partyData?.permAddress?.country}
                    </th>
                </tr>
                </tbody>
            </table>
        </Grid>
    </Grid>
}


type TravelsProps = {
    invite: JsonInviteInfo,
    values: JsonInviteReply,
    originalTravels: JsonEventPartyTravel[],
    eventKmRate: number,
    formikProps?: FormikProps<JsonInviteReply>
}

const Travels = (props: TravelsProps) => {

    const {invite, values, originalTravels, eventKmRate, formikProps} = props;

    const {processData, replyData} = values;
    const readonly = formikProps === undefined;

    const t = useAppTranslation();
    const [editItem, setEditItem] = useState<JsonEventPartyTravel | undefined>(undefined);
    const original = !!editItem ? originalTravels?.find((e) => e.eventPartyTravelId === editItem.eventPartyTravelId) : undefined;

    const formikErrors = formikProps?.errors;
    const setFieldValue = formikProps?.setFieldValue;
    const submitCount = formikProps?.submitCount;

    const travelsHash = JSON.stringify(replyData?.travels);
    const travelItems: JSX.Element = useMemo(() => {
        if (travelsHash) {
            // no-op
        }
        const items = replyData?.travels?.map((travel) => {
            const eventPartyTravelId: number = travel.eventPartyTravelId as number;

            const isSelected = processData?.travels && processData.travels[eventPartyTravelId]?.eventPartyId === FAKE_VALUE_EMPTY;
            const isDeleted = processData?.travels && processData.travels[eventPartyTravelId]?.eventPartyId === FAKE_VALUE_RESET;
            const ee = (formikErrors?.processData as JsonReplyProcessData)?.travels;
            const error = !!ee && ee[eventPartyTravelId]?.eventPartyId;
            const hasError = !!submitCount && submitCount > 0 && !!error;

            return <Grid key={eventPartyTravelId} item xs={12} className={'rsvp-extra-match rsvp-extra-match-travel'}>
                <Grid container columns={17}>
                    <Grid item xs={8}>
                        <Card className={'rsvp-extra-match-box'
                            + (isSelected ? ' rsvp-extra-match-selected ' : '')
                            + (isDeleted ? ' rsvp-extra-match-deleted ' : '')
                            + (hasError ? ' Mui-error' : '')}>
                            <CardContent sx={{alignSelf: 'center'}}>
                                <Grid container>
                                    <Grid item xs={12} className={'rsvp-extra-match-box-info'} sx={{display: 'flex', flexDirection: 'column'}}>

                                        <TravelInfo travel={travel}/>

                                        <Grid container columnSpacing={2} sx={{marginBottom: '-20px'}}>
                                            {!readonly && setFieldValue && <>
												<Grid item xs={4}>
													<Button title={t('Upravit údaje o cestě')} variant={'contained'} size={'small'} fullWidth
														color={'inherit'}
														onClick={() => {
                                                            setEditItem(travel);
                                                        }}>
														<EditRounded/>
														<span>{t('Upravit')}</span>
													</Button>
												</Grid>
												<Grid item xs={4}>
													<Button title={t('Odmítnout cestu')} variant={'contained'} size={'small'} fullWidth
														color={isDeleted ? 'error' : 'inherit'}
														onClick={() => {
                                                            if (isDeleted) {
                                                                setFieldValue('processData.travels.' + eventPartyTravelId + '.eventPartyId', undefined, true);
                                                            } else {
                                                                setFieldValue('processData.travels.' + eventPartyTravelId + '.compAmount', undefined);
                                                                setFieldValue('processData.travels.' + eventPartyTravelId + '.eventPartyId', FAKE_VALUE_RESET, true);
                                                            }
                                                        }}>
														<DeleteForeverRounded/>
														<span>{t('Zamítnout')}</span>
													</Button>
												</Grid>
											</>}

                                            {!readonly && setFieldValue && <Grid item xs={4}>
												<Button title={undefined} variant={'contained'} size={'small'} fullWidth
													color={isSelected ? 'info' : 'inherit'}
													onClick={() => {
                                                        if (isSelected) {
                                                            setFieldValue('processData.travels.' + eventPartyTravelId + '.eventPartyId', undefined, true);
                                                        } else {
                                                            let compAmount;
                                                            if (travel.vehicle === JsonEventPartyTravelVehicleEnum.Car) {
                                                                compAmount = Math.round(100 * (travel.kmCnt || 0) * eventKmRate) / 100;
                                                            } else {
                                                                compAmount = Math.round(100 * (travel.compAmount || 0)) / 100;
                                                            }
                                                            setFieldValue('processData.travels.' + eventPartyTravelId + '.compAmount', compAmount);
                                                            setFieldValue('processData.travels.' + eventPartyTravelId + '.eventPartyId', FAKE_VALUE_EMPTY, true);
                                                        }
                                                    }}>
                                                    {isSelected ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
													<span>{t('Přijmout')}</span>
												</Button>
											</Grid>}

                                            <Grid item xs={12}>
                                                <input name={'processData.travels.' + eventPartyTravelId + '.eventPartyId'} style={{display: 'none'}}/>
                                                {hasError && <FormHelperText className={'Mui-error'}>{error}</FormHelperText>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={1} className={'rsvp-extra-match-chevron'}>
                        <div>
                            <ChevronRight/>
                        </div>
                    </Grid>
                    <Grid item xs={8} className={'rsvp-extra-match-extra'}>
                        <Grid container spacing={1}>
                            {isDeleted && <Grid key={0} item xs={12} className={'rsvp-extra-match-alert'}>
								<div>
									<Alert severity={'error'} icon={false}>{t('Cesta odmítnuta')}</Alert>
								</div>
							</Grid>}
                            {isSelected && <Grid item xs={readonly ? 12 : 6} sx={{display: 'flex'}}>
								<Card className={'rsvp-extra-match-box rsvp-extra-match-selected'}>
									<CardContent>
										<Grid container spacing={1}>
											<Grid item xs={12}>
                                                {travel.vehicle === JsonEventPartyTravelVehicleEnum.Car && <p>
                                                    {t('Dle km')}: {travel.kmCnt} x {eventKmRate} = <strong>{formatMoney(
                                                    (travel.kmCnt || 0) * eventKmRate, true)}</strong>
												</p>}
                                                {travel.vehicle !== JsonEventPartyTravelVehicleEnum.Car && <p>
                                                    {t('Dle částky')}: <strong>{formatMoney(travel.compAmount, true)}</strong>
												</p>}
											</Grid>
											<Grid item xs={12}>
                                                {readonly
                                                    ? <div style={{marginBottom: '20px'}}>{t('Náhrada')}: <strong>
                                                        {formatMoney(processData.travels?.[eventPartyTravelId]?.compAmount || 0, true)}</strong></div>
                                                    : <TextFormField name={'processData.travels.' + eventPartyTravelId + '.compAmount'} label={'Přiznaná výše náhrady'}
                                                        maxlength={8} normalizeValue={normalizeMoney} formatValue={formatMoney}
                                                    />}
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>;
        });

        return <>
            {items}
        </>;

    }, [travelsHash, processData?.travels, replyData?.travels, eventKmRate,
        readonly,
        setFieldValue, formikErrors, submitCount,
        t]);

    return <>
        <Grid container className={'rsvp-extra-match-wrapper'}>
            {travelItems}
        </Grid>
        {!!editItem && !!formikProps && <RsvpInviteTravelForm
			title={'Úprava cesty'}
			eventId={invite.eventId}
			item={editItem}
			original={original}
			onSave={(travel) => {
                const index = replyData?.travels?.findIndex((e) => e.eventPartyTravelId === editItem.eventPartyTravelId);
                if (index !== undefined && replyData?.travels?.[index]) {
                    replyData.travels[index] = {
                        ...travel,
                        eventPartyTravelId: replyData.travels[index].eventPartyTravelId
                    };
                    formikProps.setFieldValue('replyData.travels', replyData.travels, true);
                }
                // formikProps.setFieldValue('processData.travels.' + editItem.eventPartyTravelId, travel, true);
                setEditItem(undefined)
            }}
			onCancel={() => setEditItem(undefined)}
			inviteId={invite.inviteId}
		/>}
    </>
}

const InviteReviewForm = (props: Props) => {
    const {invite, onSave, onEditInvite} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const articleTypes = useAppSelector<ItemsState<JsonArticleTypeInfo>>(selectArticleTypes);
    const [isLoading, setIsLoading] = useState(true);
    const [party, setParty] = useState<JsonPartyInfo | undefined>(undefined);
    const [foundParties, setFoundParties] = useState<JsonFindPartyResponse[] | undefined>(undefined);
    const [newPhotoParties, setNewPhotoParties] = useState<JsonFindPartyResponse[] | undefined>(undefined);
    const [tariffs, setTariffs] = useState<JsonTariffInfo[] | undefined>(undefined);
    const [eventKmRate, setEventKmRate] = useState(invite.eventId && invite.eventId <= 19 ? 5.2 : 5.6); // defaults

    const {partyId, inviteData, eventId, inviteType, inviteKind} = invite;
    const reply = props.item;
    const {replyData} = reply;

    const isReadonly = !(invite.status === JsonInviteInfoStatusEnum.Accepted);
    const isWithAnyData = !!reply.replyType
        || (inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg && !!reply.replyData?.partyData)
        || (inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg && !!reply.replyData?.travels?.length);
    const isNewPhoto = hasNewPhoto(invite, reply);
    const isNewPhotoInvalid = isNewPhoto && !!reply.newPhotoCvResult && reply.newPhotoCvResult !== 'OK';
    const isParkingOffered = hasParkingOffered(invite);
    const isReplyAccepted = !!reply.replyAt && (reply.replyType === JsonInviteReplyReplyTypeEnum.Accept
        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Update
        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Travel);
    const isInviteEditable = invite.status === JsonInviteInfoStatusEnum.Pending
        || invite.status === JsonInviteInfoStatusEnum.Accepted
        || invite.status === JsonInviteInfoStatusEnum.Returned;

    const handleFetchParty = useCallback(() => {
        if (!partyId || !eventId) {
            return;
        }
        setIsLoading(true);
        dispatch(fetchPartyInfo(partyId)).then((res) => {
            setParty(getApiResult(res));

            dispatch(fetchEvent(eventId)).then((res) => {
                const event = getApiResult<JsonEvent>(res);
                if (event?.eventData?.kmRate) {
                    setEventKmRate(event.eventData.kmRate);
                }
                setIsLoading(false);
            });
        });

    }, [eventId, partyId, dispatch]);

    const extras = useMemo(() => {
        if (inviteType === JsonInviteInfoInviteTypeEnum.Supplier) {
            return replyData?.extras || [];
        }

        const extras: JsonInviteReplyExtra[] = [];
        if (isReplyAccepted
            && inviteData?.extraLimit
            && replyData?.extras
            && replyData?.extras.length) {
            replyData?.extras.forEach((extra, i) => {
                if (!isExtraActive(replyData, inviteData, extra)) {
                    return;
                }
                extras.push(extra);
            });
        }
        return extras;
    }, [isReplyAccepted, inviteData, replyData, inviteType]);

    const handleFindPartiesReadonly = useCallback(() => {
        if (!isReadonly) {
            return;
        }
        const partyIdToExtraId: { [key in number]: number } = {};
        reply.processData?.extras?.forEach((extra, extraId) => {
            if (extra && extra.partyId && extra.partyId > 0) {
                partyIdToExtraId[extra.partyId] = extraId;
            }
        });
        const partyIds = (Object.keys(partyIdToExtraId) as any) as number[];
        if (partyIds.length) {
            dispatch(fetchPartiesOnline({partyIds})).then((res) => {
                const parties = getApiResult<JsonPartyInfo[]>(res);
                setFoundParties(parties?.map(p => ({
                    party: p,
                    queryId: "" + partyIdToExtraId[p.partyId as number]
                })));
            });
        } else {
            setFoundParties([]);
        }
        return;

    }, [isReadonly, reply.processData?.extras, dispatch]);

    const handleFindParties = useCallback(() => {
        if (isReadonly) {
            return;
        }
        const requests: JsonFindPartyRequest[] = [];
        if (isNewPhoto) {
            requests.push({
                queryId: "0",
                firstName: reply.newFirstName,
                lastName: reply.newLastName,
                sex: !!invite?.sex ? JsonFindPartyRequestSexEnum[invite.sex] : undefined,
                photoGuid: reply.newPhotoGuid
            });
        }

        if (extras.length) {
            extras.forEach((extra) => {
                if (!!extra.lastName) {
                    requests.push({
                        queryId: "" + extra.extraId,
                        firstName: extra.firstName,
                        lastName: extra.lastName,
                        sex: !!extra.sex ? JsonFindPartyRequestSexEnum[extra.sex] : undefined,
                        photoGuid: extra.photoGuid
                    });
                }
            });
        }

        if (!requests.length) {
            return;
        }

        dispatch(findParties({requests})).then((res) => {
            const items = sortFoundParties(getApiResult(res));
            setFoundParties(items);
            if (isNewPhoto) {
                setNewPhotoParties(items.filter((fp) => fp.queryId === "0"
                    && fp.party?.partyId !== invite.partyId
                    && fp.photoScore
                    && fp.photoScore < 0.25));
            }
        })

    }, [reply, invite.sex, invite.partyId, isReadonly, isNewPhoto, extras, dispatch]);

    const handleFetchArticleTypes = useCallback(() => {
        dispatch(fetchArticleTypes({eventId}));
    }, [eventId, dispatch]);

    const handleFetchTariffs = useCallback(async () => {
        setTariffs(getApiResult<JsonTariffInfo[]>(await dispatch(fetchTariffs({eventId}))));
    }, [eventId, dispatch]);

    const handleSubmit = useCallback(async (values: JsonInviteReply) => {
        if (!onSave) {
            return;
        }
        if (inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg || inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg || inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
            if (values.status === JsonInviteReplyStatusEnum.Deleted) {
                await modal.confirm({
                    title: t('Potvrzení storna výzvy'),
                    message: <div>
                        <p>{t('Skutečně si přejete výzvu úplně stornovat?')}</p>
                        {!!values.skipEmail
                            ? <Alert severity={'warning'} sx={{margin: '10px 0'}}>{t('Ke stornu nebude odeslán žádný email, pouze se uloží interní poznámka:')}</Alert>
                            : <p>{t('Do oznámení bude připojena následující zpráva:')}</p>}
                        <pre className={'note'}>
                            {values.processData?.processNote}
                        </pre>
                    </div>,
                    cancelText: 'Zpět do výzvy',
                    confirmColor: 'error',
                    confirmText: 'Ano, trvale stornovat',
                    confirmIcon: <DeleteForever/>,
                    syncAction: () => onSave({...values, inviteId: invite.inviteId})
                } as ModalProps);

            } else if (values.status === JsonInviteReplyStatusEnum.Rejected) {
                await modal.confirm({
                    title: t('Potvrzení vrácení k doplnění'),
                    message: <div>
                        <p>{t('Skutečně si přejete výzvu vrátit k doplnění?')}</p>
                        {!!values.skipEmail
                            ? <Alert severity={'warning'} sx={{margin: '10px 0'}}>{t('K vrácení nebude odeslán žádný email, pouze se uloží interní poznámka:')}</Alert>
                            : <p>{t('Do oznámení bude připojena následující zpráva:')}</p>}
                        <pre className={'note'}>
                        {values.processData?.processNote}
                        </pre>
                    </div>,
                    cancelText: 'Zpět do výzvy',
                    confirmColor: 'warning',
                    confirmText: 'Ano, vrátit k doplnění',
                    confirmIcon: <Telegram/>,
                    syncAction: () => onSave(values)
                } as ModalProps);

            } else if (values.status === JsonInviteReplyStatusEnum.Accepted) {
                await modal.confirm({
                    title: t('Povtrzení přijetí údajů'),
                    message: <InviteReviewSummary values={values} invite={invite} articleTypes={articleTypes?.items} extras={extras} eventKmRate={eventKmRate} foundParties={foundParties}/>,
                    cancelText: 'Zpět do výzvy',
                    confirmColor: 'success',
                    confirmText: 'Schválit a informace uložit',
                    confirmIcon: <Telegram/>,
                    syncAction: () => onSave(values)
                } as ModalProps);
            }

        } else {
            if (values.status === JsonInviteReplyStatusEnum.Deleted) {
                await modal.confirm({
                    title: t('Potvrzení storna pozvánky'),
                    message: <div>
                        <p>{t('Skutečně si přejete pozvánku úplně stornovat?')}</p>
                        {!!values.skipEmail
                            ? <Alert severity={'warning'} sx={{margin: '10px 0'}}>{t('Ke stornu nebude odeslán žádný email, pouze se uloží interní poznámka:')}</Alert>
                            : <p>{t('Do oznámení bude připojena následující zpráva:')}</p>}
                        <pre className={'note'}>
                        {values.processData?.processNote}
                        </pre>
                    </div>,
                    cancelText: 'Zpět do pozvánky',
                    confirmColor: 'error',
                    confirmText: 'Ano, trvale stornovat',
                    confirmIcon: <DeleteForever/>,
                    syncAction: () => onSave({...values, inviteId: invite.inviteId})
                } as ModalProps);

            } else if (values.status === JsonInviteReplyStatusEnum.Rejected) {
                await modal.confirm({
                    title: t('Potvrzení vrácení k doplnění'),
                    message: <div>
                        <p>{t('Skutečně si přejete pozvánku vrátit k doplnění?')}</p>
                        {!!values.skipEmail
                            ? <Alert severity={'warning'} sx={{margin: '10px 0'}}>{t('K vrácení nebude odeslán žádný email, pouze se uloží interní poznámka:')}</Alert>
                            : <p>{t('Do oznámení bude připojena následující zpráva:')}</p>}
                        <pre className={'note'}>
                        {values.processData?.processNote}
                        </pre>
                    </div>,
                    cancelText: 'Zpět do pozvánky',
                    confirmColor: 'warning',
                    confirmText: 'Ano, vrátit k doplnění',
                    confirmIcon: <Telegram/>,
                    syncAction: () => onSave(values)
                } as ModalProps);

            } else if (values.status === JsonInviteReplyStatusEnum.Accepted) {
                await modal.confirm({
                    title: t('Povtrzení pozvánky'),
                    message: <InviteReviewSummary values={values} invite={invite} articleTypes={articleTypes?.items} extras={extras} eventKmRate={eventKmRate} foundParties={foundParties}/>,
                    cancelText: 'Zpět do pozvánky',
                    confirmColor: 'success',
                    confirmText: 'Schválit a provést registraci',
                    confirmIcon: <Telegram/>,
                    syncAction: () => onSave(values)
                } as ModalProps);
            }
        }

    }, [invite, inviteType, extras, foundParties, articleTypes?.items, eventKmRate, onSave, modal, t]);

    const statusActionOptions: OptionValue[] = useMemo(() => {
        const options = inviteKind === JsonInviteInfoInviteKindEnum.Update
        || inviteKind === JsonInviteInfoInviteKindEnum.Travel
            ? updateStatusOptions
            : (inviteKind === JsonInviteInfoInviteKindEnum.Comp
                ? compStatusOptions
                : statusOptions);
        if (!isInviteEditable) {
            if (inviteKind === JsonInviteInfoInviteKindEnum.Comp && isReplyAccepted) {
                return [createOption(JsonInviteReplyStatusEnum.Accepted, 'Výplata potvrzena', 'Výplata byla organizátorem potvrzena', undefined, 'success')];
            }
            return options;
        }
        if (!isReplyAccepted && !(inviteKind === JsonInviteInfoInviteKindEnum.Comp && !isReadonly)) {
            return [options[0]];
        }
        return options;

    }, [inviteKind, isReplyAccepted, isInviteEditable, isReadonly]);

    useEffect(() => {
        handleFetchParty();
        if (inviteType === JsonInviteInfoInviteTypeEnum.Organizer || inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg) {
            handleFetchArticleTypes();
        }
        if (inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
            handleFetchTariffs().then();
        }
        if (isReadonly) {
            handleFindPartiesReadonly();
        } else {
            handleFindParties();
        }
    }, [inviteType, isReadonly, handleFetchArticleTypes, handleFetchParty, handleFindParties, handleFindPartiesReadonly, handleFetchTariffs]);

    const inviteCreatedAt = invite.createdAt;
    const PartyInfo = useMemo(() => {
        if (inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg || inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg || inviteType === JsonInviteInfoInviteTypeEnum.CompOrg) {
            return <PartyInfoBox title={t('Organizátor')} party={party} isLoading={isLoading} eventId={eventId} simple
                badges={[{title: t('Odesláno {{title}}', {title: datetimeToGui(inviteCreatedAt)}), tooltip: t('Datum odeslání výzvy')}]}/>
        }
        return <PartyInfoBox title={t('Pozvaná osoba')} party={party} isLoading={isLoading} eventId={eventId} simple
            badges={[{title: t('Odesláno {{title}}', {title: datetimeToGui(inviteCreatedAt)}), tooltip: t('Datum odeslání pozvánky')}]}/>
    }, [eventId, party, inviteType, inviteCreatedAt, isLoading, t]);

    const InviteInfo = useMemo(() => {
        return <InfoBox title={<span>{t(invite.inviteKind === JsonInviteInfoInviteKindEnum.Update
        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Travel
        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Comp
            ? 'Výzva'
            : 'Pozvánka')}</span>}
            badges={[{
                title: getOption(invite.status, invite.inviteKind === JsonInviteInfoInviteKindEnum.Update
                || invite.inviteKind === JsonInviteInfoInviteKindEnum.Travel
                || invite.inviteKind === JsonInviteInfoInviteKindEnum.Comp
                    ? updateInviteStatusOptions
                    : inviteStatusOptions).tooltip
            }]}
            actions={isInviteEditable && invite.inviteType === JsonInviteInfoInviteTypeEnum.Guest ? [{
                title: t('Upravit'), action: onEditInvite, icon: <EditRounded/>
            }] : undefined}>
            <table className={'info-box-table'}>
                <tbody>
                <tr>
                    <th style={{width: '13%'}}>{t('Skupina')}</th>
                    <td style={{width: '55%'}} colSpan={3}>
                        <CodebookValue value={invite.inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg
                            ? party?.eventDetails?.find((ed) => ed.eventId === eventId)?.groupId
                            : invite.groupId} name={'group'}/>
                    </td>
                    <th style={{width: '16%'}}>{t('Uzávěrka')}</th>
                    <td style={{width: '16%'}}>{invite.replyUntil ? dateToGui(invite.replyUntil) : t('Není')}</td>
                </tr>
                {invite.inviteType === JsonInviteInfoInviteTypeEnum.Guest && <tr>
					<th>{t('Dny')}</th>
					<td style={{width: '22%'}}>{invite.inviteData?.eventDays?.map(dayNo => <EventDay key={dayNo} dayNo={dayNo} eventId={invite.eventId}/>)}</td>
					<th style={{width: '15%'}}>{t('Doprovod')}</th>
					<td style={{width: '18%'}}>{invite.inviteData?.extraLimit ? 'Max ' + invite.inviteData?.extraLimit : t('Nenabídnuto')}</td>
					<th>{t('Parkování')}</th>
					<td>{isParkingOffered ? t('Nabídnuto') : t('Nenabídnuto')}</td>
				</tr>}
                {(!!invite.inviteData?.bodyTop || !!invite.inviteData?.bodyBottom) && <tr>
					<td colSpan={6} title={'Text emailu'}>
                        <pre className={'note'} style={{maxHeight: "100px", overflowY: "auto"}}>
                            {invite.inviteData?.bodyTop || ''}
                            {invite.inviteData?.bodyBottom || ''}
                        </pre>
					</td>
				</tr>}
                {(!!invite.inviteData?.attachments?.length && invite.inviteData.attachments.length > 0) && <tr>
					<td colSpan={6} title={'Vlastní příloha'} className={'file-list'}>{invite.inviteData?.attachments.map((a, i) => {
                        return <span key={i}>{renderFile(a)}</span>;
                    })}</td>
				</tr>}
                {!!invite.processNote && invite.status !== JsonInviteInfoStatusEnum.Deleted && invite.status !== JsonInviteInfoStatusEnum.Confirmed && <tr>
					<td colSpan={6} title={'Zdůvodnění / poznámka'}>
						<Alert severity={'warning'} icon={false}>
							<blockquote className={'note'}>{invite.processNote}</blockquote>
						</Alert>
					</td>
				</tr>}
                </tbody>
            </table>
        </InfoBox>
    }, [isParkingOffered, isInviteEditable, invite, party?.eventDetails, eventId, onEditInvite, t]);

    const replyBadges: InfoBoxBadge[] = useMemo(() => {
        return [reply?.replyAt
            ? {
                title: t(isReplyAccepted ? '{{what}} přijata {{title}}' : '{{what}} odmítnuta {{title}}', {
                    what: inviteType === JsonInviteInfoInviteTypeEnum.UpdateOrg
                    || inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg
                    || inviteType === JsonInviteInfoInviteTypeEnum.CompOrg
                        ? 'Výzva'
                        : 'Účast',
                    title: datetimeToGui(reply.replyAt)
                }),
                color: isReplyAccepted ? 'info' : 'error'
            }
            : {
                title: t(isWithAnyData
                    ? 'Rozpracovaná odpověď {{title}}'
                    : 'Zatím bez odpovědi', {title: datetimeToGui(reply.updatedAt)}),
                color: 'warning'
            }
        ];
    }, [reply, inviteType, isWithAnyData, isReplyAccepted, t]);

    const handleValidate = useCallback((v: JsonInviteReply) => {
        return validate(v, invite, t);
    }, [invite, t]);

    const actions = useCallback(({values, isSubmitting}: FormikProps<JsonInviteReply>, props: FormProps<JsonInviteReply>) => {
        const statusOption = statusActionOptions.find((o) => o.value === values.status)
        const saveButtonTitle = statusOption?.label || (inviteKind === JsonInviteInfoInviteKindEnum.Update
        || inviteKind === JsonInviteInfoInviteKindEnum.Travel
        || inviteKind === JsonInviteInfoInviteKindEnum.Comp
            ? t('Vyřídit výzvu')
            : t('Vyřídit pozvánku'))
        return <>
            {!props.isReadonly && isReplyAccepted && <Grid item sx={{flexGrow: 1}}>
				<Button variant="text" onClick={() => {
                    if (onSave) {
                        const draft = {...values};
                        draft.status = reply.status; // keep current
                        onSave(draft);
                    }
                }}>{t('Uložit rozpracované')}</Button>
			</Grid>}
            {!props.isReadonly && (!!values.status && values.status !== JsonInviteReplyStatusEnum.Draft) && <Grid item>
				<CheckboxField name={'skipEmail'} color={'default'} label={t('Neposílat email')}/>
			</Grid>}
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || (props.isReadonly ? t('Zavřít') : t('Storno'))}</Button>
			</Grid>}
            {!props.isReadonly && <Grid item>
				<Button variant="contained" type="submit" color={statusOption?.color || 'success'} disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={20}/> : null}
					<span>{saveButtonTitle}</span>
				</Button>
			</Grid>}
        </>
    }, [reply.status, statusActionOptions, inviteKind, isReplyAccepted, onSave, t]);

    return <FormContainer {...props}
        onSave={handleSubmit}
        actions={actions}
        isReadonly={!isInviteEditable}
        validate={handleValidate} children={(formikProps) => {
        const {values} = formikProps;
        return <>
            <Grid item xs={6}>
                {PartyInfo}
            </Grid>
            <Grid item xs={6}>
                {InviteInfo}
            </Grid>
            <Grid item xs={12}>
                <Grid container columns={100} spacing={2}>
                    <Grid item xs={50}>
                        <InfoBox title={invite.inviteKind === JsonInviteInfoInviteKindEnum.Update
                        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Travel
                        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Comp
                            ? t('Odpověď na výzvu')
                            : t('Odpověď na pozvání')}
                            badges={replyBadges}>
                            {isWithAnyData && <Grid container columns={10}>
                                {isReplyAccepted && <Grid item xs={7}>
									<table className={'info-box-table'} style={{width: "calc(100% - 15px)"}}>
										<tbody>
                                        {reply.infoIsValid === false && <>
											<tr>
												<th>{t('Nové jméno')}</th>
												<td>{reply.newFirstName}</td>
											</tr>
											<tr>
												<th>{t('Nové příjmení')}</th>
												<td>
                                                    {reply.newLastName}
												</td>
											</tr>
										</>}
										<tr>
											<th>{'Úprava údajů'}</th>
											<td style={{width: '55%'}}>{reply.infoIsValid === false ? <ButtonGroupField
                                                disabled={isReadonly}
                                                label={'Ano, přijmout je?'}
                                                options={acceptOptions} name={'processData.isInfoAccepted'}/> : t('Ne')}</td>
										</tr>
										<tr>
											<th style={{width: '45%'}}>{'Nová fotografie'}</th>
											<td style={{width: '55%'}}>{isNewPhoto ? <ButtonGroupField
                                                disabled={isReadonly}
                                                label={'Ano, přijmout ji?'}
                                                options={acceptOptions} name={'processData.isPhotoAccepted'}/> : t('Ne')}</td>
										</tr>
                                        {isReplyAccepted && inviteType === JsonInviteInfoInviteTypeEnum.Organizer && <tr>
											<th>{t('Ubytování')}</th>
											<td>{replyData?.isAcm ? t('Má zájem o zajištění') : t('Nemá zájem o zajištění')}</td>
										</tr>}
										</tbody>
									</table>
                                    {!isReadonly && isNewPhotoInvalid && <Alert severity={'error'}>
										<p>{t('Fotografie dle automatické kontroly nevyhovuje požadavkům, není dle ní možné hledat a ani nebyla správně oříznuta.')}</p>
										<p>{t('Ignorujte ji, nebo vraťte pozvánku k doplnění.')}</p>
									</Alert>}
                                    {!!newPhotoParties?.length && newPhotoParties.length > 0 && <Alert severity={'error'}>
										<p>{t('Pro novou fotografii byla nalezena vysoká shoda s jinou osobou - pokud se jedná o stejnou osobu, zastavte prosím zpracovaní pozvánky, duplicitu je nutné vyřešit servisním zásahem do aplikace.')}</p>
                                        {newPhotoParties.map((fp, j) => {
                                            return <FoundParty key={j} eventId={invite.eventId}
                                                maxWidth={100}
                                                maxHeight={100}
                                                readonly={true}
                                                sourceParty={invite as JsonPartyInfo}
                                                foundPartyResponse={fp}/>
                                        })}
									</Alert>}
								</Grid>}
                                {isReplyAccepted && <Grid item xs={3} sx={{textAlign: 'right'}}>
                                    {isNewPhoto && <PartyPhoto photoGuid={reply.newPhotoGuid} maxHeight={180} maxWidth={180}/>}
								</Grid>}
                                {(reply.replyType === JsonInviteReplyReplyTypeEnum.Reject || !!reply.replyNote) && <Grid item xs={10} sx={{paddingTop: '10px'}}>
									<pre className={'note'} title={t('Poznámka')}>{reply.replyNote || t('(žádná poznámka nevyplněna)')}</pre>
								</Grid>}
							</Grid>}
                        </InfoBox>
                    </Grid>
                    {isReplyAccepted && !!invite.inviteData?.eventDays && invite.inviteData?.eventDays.length > 0 && <Grid item xs={50}>
						<InfoBox title={t('Přijaté dny')}>
							<InviteDays invite={invite} reply={values} isReadonly={isReadonly} setFieldValue={formikProps.setFieldValue}/>
						</InfoBox>
					</Grid>}
                    {isWithAnyData && !!reply.replyData?.partyData && invite.inviteKind !== JsonInviteInfoInviteKindEnum.Comp && <Grid item xs={30}>
						<InfoBox title={t('Další informace')}>
							<PartyData partyData={reply.replyData.partyData}/>
						</InfoBox>
					</Grid>}
                    {isWithAnyData && reply.replyData?.articles && reply.replyData?.articles.length > 0 && <Grid item xs={!!reply.replyData?.partyData ? 20 : 50}>
						<InfoBox title={t('Artikly')}>
							<Articles articles={reply.replyData.articles} articleTypes={articleTypes.items} inviteSex={invite.sex}/>
						</InfoBox>
					</Grid>}
                    {isReplyAccepted && extras.length > 0 && <Grid item xs={100}>
						<InfoBox title={t(inviteType === JsonInviteInfoInviteTypeEnum.Supplier ? 'Potvrzení personálu' : 'Potvrzení doprovodných osob')}>
							<Extras invite={invite} values={values}
								foundParties={foundParties}
								originalExtras={extras}
								formikProps={isReadonly ? undefined : formikProps}
								setFoundParties={setFoundParties}
							/>
						</InfoBox>
					</Grid>}
                    {isWithAnyData && reply.replyData?.travels && reply.replyData.travels.length > 0 && <Grid item xs={100}>
						<InfoBox title={t('Potvrzení cestovních náhrad')}>
							<Travels invite={invite} values={values}
								originalTravels={reply.replyData.travels}
								eventKmRate={eventKmRate}
								formikProps={isReadonly ? undefined : formikProps}
							/>
						</InfoBox>
					</Grid>}
                    {isWithAnyData && invite.inviteKind === JsonInviteInfoInviteKindEnum.Comp && <Grid item xs={50}>
						<InfoBox title={t('Potvrzení výplaty')}>
							<PartyCompDetails eventParty={{
                                eventCompDetails: replyData?.eventCompDetails,
                                eventTravelDetails: replyData?.eventTravelDetails,
                                bankAccount: replyData?.partyData?.bankAccount
                            }}
								tariffs={tariffs}
								withAccount/>
						</InfoBox>
					</Grid>}
                </Grid>
            </Grid>
            {(invite.status !== JsonInviteInfoStatusEnum.Rejected) && <Grid item xs={12}>
				<InfoBox title={t('Vyřízení')}
					badges={reply.processAt ? [{
                        title: <span>{values.processBy ? <CodebookValue value={values.processBy} name={'user'}/> : t('Systém')}&nbsp;
                            {datetimeToGui(reply.processAt)}</span>, tooltip: t('Datum vyřízení pozvánky')
                    }] : undefined}>
					<Grid container>
						<Grid item xs={3}>
						</Grid>
						<Grid item xs={6} sx={{textAlign: 'center'}}>
							<ButtonGroupField name={'status'} options={statusActionOptions} fullWidth={statusActionOptions.length > 1} disabled={isReadonly && !isInviteEditable}/>
						</Grid>
						<Grid item xs={3}>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>{(values.status === JsonInviteReplyStatusEnum.Deleted
                                || values.status === JsonInviteReplyStatusEnum.Rejected
                                || values.status === JsonInviteReplyStatusEnum.Accepted) &&
							<TextFormField name={'processData.processNote'} rows={4} maxlength={1000} type={'textarea'} disabled={isReadonly && !isInviteEditable} label={
                                values.status === JsonInviteReplyStatusEnum.Rejected
                                    ? 'Zdůvodnění vrácení'
                                    : (values.status === JsonInviteReplyStatusEnum.Deleted
                                        ? 'Zdůvodnění storna'
                                        : 'Volitelná odpověď (bude vložena do emailu s potvrzením)')
                            }/>}
						</Grid>
					</Grid>
				</InfoBox>
			</Grid>}
        </>
    }}/>;
}

export default InviteReviewForm;
