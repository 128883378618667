import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {FormikErrors} from 'formik';
import {FormProps, setNestedKey} from "../../model/form";
import {JsonArticle, JsonArticleSexEnum, JsonArticleTypeInfo} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {ItemsState, useAppSelector} from "../../store";
import {selectArticleTypes} from "../../store/selectors";
import {useCallback, useMemo} from "react";
import {ArticleCommonSize, articleSize} from "../../helpers/format";

interface Props extends FormProps<JsonArticle[]> {
}

type MatrixType = {
    [key in JsonArticleSexEnum]: {
        [key in string]?: {
            [key in number]?: JsonArticle
        }
    }
}

interface MatrixProps extends FormProps<MatrixType> {
}


const EventArticlesForm = (props: Props) => {

    const t = useAppTranslation();
    const {items} = useAppSelector<ItemsState<JsonArticleTypeInfo>>(selectArticleTypes);
    const {onSave} = props; /* item = article list */
    const articles = props.item;

    const validate = useCallback((values: MatrixType) => {
        let errors = {} as FormikErrors<MatrixType>;

        Object.keys(values).forEach((k) => {
            const sex = k as JsonArticleSexEnum;
            Object.keys(values[sex]).forEach((size) => {
                const m = values[sex][size];
                if (!m) {
                    return;
                }
                Object.keys(m).forEach((k) => {
                    const articleTypeId = k as any;
                    const item = m[articleTypeId as any];
                    if (item && item.purchasedCnt) {
                        if (!('' + item.purchasedCnt).match(/^(0|([1-9][0-9]*))*$/)) {
                            setNestedKey(errors, sex + '.' + size + '.' + articleTypeId + '.purchasedCnt', t('Zadejte celé kladné číslo'));
                        }
                    }
                });
            });
        });
        return errors;
    }, [t]);

    const handleSave = useCallback((values: MatrixType) => {
        const items: JsonArticle[] = [];

        Object.keys(values).forEach((k) => {
            const sex = k as JsonArticleSexEnum;
            Object.keys(values[sex]).forEach((size) => {
                const m = values[sex][size];
                if (!m) {
                    return;
                }
                Object.keys(m).forEach((k) => {
                    const articleTypeId = k as any;
                    const item = m[articleTypeId as any];
                    if (item) {
                        items.push({...item, articleTypeId, sex, size, purchasedCnt: item.purchasedCnt || 0})
                    }
                });
            });
        });

        return onSave ? onSave(items) : undefined;
    }, [onSave])

    const [matrix, tables, colCount] = useMemo(() => {
        const matrix: MatrixType = {} as MatrixType;
        const tables: { [key in string]: MatrixType } = {'0': {} as MatrixType};
        let colCount = 0;

        for (const sex of [JsonArticleSexEnum.U, JsonArticleSexEnum.M, JsonArticleSexEnum.F]) {
            matrix[sex] = {};
            tables['0'][sex] = {};
            for (const size of Object.keys(ArticleCommonSize)) {
                // if (size.indexOf("_") >= 0) {
                //     continue; // merged cells
                // }

                const articleTypes = items.filter((at: JsonArticleTypeInfo) => at.variants?.sizes?.[sex]
                    ?.find(s => s === size /*|| s.startsWith(size + "_") || s.endsWith("_" + size)*/));
                if (!articleTypes.length) {
                    continue; // no types with this sex/size combination
                }

                const m: { [key in number]?: JsonArticle } = {};
                for (const at of articleTypes) {
                    if (!at.articleTypeId) {
                        continue;
                    }
                    m[at.articleTypeId] = articles?.find((a: JsonArticle) => a.articleTypeId === at.articleTypeId
                        && a.sex === sex
                        && (a.size === size));
                }
                tables['0'][sex][size] = m;
                matrix[sex][size] = m;
                colCount++;
            }
        }

        items.filter((at) => !!at.variants?.isExtra).forEach((at) => {
            const articleTypeId = at.articleTypeId;
            if (!articleTypeId || !at.variants?.sizes) {
                return;
            }
            const table = {} as MatrixType;
            for (const sex of [JsonArticleSexEnum.U, JsonArticleSexEnum.M, JsonArticleSexEnum.F]) {
                if (!at.variants.sizes[sex]) {
                    continue;
                }
                table[sex] = {};

                for (const size of at.variants.sizes[sex]) {
                    const m: { [key in number]?: JsonArticle } = {};
                    m[articleTypeId] = articles?.find((a: JsonArticle) => a.articleTypeId === at.articleTypeId
                        && a.sex === sex
                        && (a.size === size));
                    table[sex][size] = m;
                    matrix[sex][size] = m;
                }
            }
            tables['' + articleTypeId] = table;
        });

        return [matrix, tables, colCount];
    }, [items, articles]);

    const tableRows = useMemo(() => {
        let tableRows: { [key in string]: JSX.Element[] } = {'0': []};
        for (let key of Object.keys(tables)) {
            tableRows[key] = items
                .filter((at) => (key === '0' && !at.variants?.isExtra) || (key === at.articleTypeId + ''))
                .map((at: JsonArticleTypeInfo) => {
                    return <TableRow key={at.articleTypeId}>
                        <TableCell colSpan={2} title={'' + at.articleTypeId}>
                            {at.title}
                        </TableCell>
                        {Object.keys(tables[key]).map((sex) => {
                            let lastSex: JsonArticleSexEnum | undefined = undefined;
                            return Object.keys(tables[key][sex as JsonArticleSexEnum]).map((size) => {
                                const m = tables[key][sex as JsonArticleSexEnum][size];
                                const className = sex !== lastSex ? 'form-grid-border' : undefined;
                                lastSex = sex as JsonArticleSexEnum;
                                return <TableCell key={size} className={className}>
                                    {m && at.articleTypeId && at.articleTypeId in m
                                        ? <TextFormField name={sex + "[" + size + "][" + at.articleTypeId + "][purchasedCnt]"} type={'text'} maxlength={4}/>
                                        : ''}
                                </TableCell>;
                            });
                        })}
                    </TableRow>
                });
        }
        return tableRows;
    }, [items, tables]);

    const matrixProps = {...props, item: matrix} as MatrixProps;
    return (
        <FormContainer {...matrixProps} validate={validate} onSave={handleSave} children={(formikProps) => {
            return Object.keys(tables).map((key, i) => <Table key={i} size={'small'} className={'data-grid form-grid'}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} rowSpan={2} style={{width: '10%'}}/>
                        {Object.keys(tables[key]).map((sex) => {
                            const c = Object.keys(tables[key][sex as JsonArticleSexEnum]).length;
                            return <TableCell key={sex} colSpan={c} style={{width: (c / colCount * 90) + '%'}} className={'form-grid-border'}>
                                <span>{sex}</span>
                            </TableCell>
                        })}
                    </TableRow>
                    <tr>
                        {Object.keys(tables[key]).map((sex) => {
                            const keys = Object.keys(tables[key][sex as JsonArticleSexEnum]);
                            return keys.map((size, i) => {
                                return <TableCell key={size} style={{width: (1 / colCount * 90) + '%'}} className={i === 0 ? 'form-grid-border' : undefined}>
                                    <span>{articleSize(sex, size)}</span>
                                </TableCell>
                            });
                        })}
                    </tr>
                </TableHead>
                <TableBody>
                    {tableRows[key]}
                </TableBody>
            </Table>)
        }}/>
    );
}

export default EventArticlesForm;
