/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonAddress,
    JsonAddressFromJSON,
    JsonAddressFromJSONTyped,
    JsonAddressToJSON,
} from './JsonAddress';

/**
 * 
 * @export
 * @interface JsonReplyPartyData
 */
export interface JsonReplyPartyData {
    /**
     * 
     * @type {string}
     * @memberof JsonReplyPartyData
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonReplyPartyData
     */
    birthDate?: string;
    /**
     * 
     * @type {JsonAddress}
     * @memberof JsonReplyPartyData
     */
    permAddress?: JsonAddress;
    /**
     * 
     * @type {string}
     * @memberof JsonReplyPartyData
     */
    phone?: string;
}

export function JsonReplyPartyDataFromJSON(json: any): JsonReplyPartyData {
    return JsonReplyPartyDataFromJSONTyped(json, false);
}

export function JsonReplyPartyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonReplyPartyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankAccount': !exists(json, 'bankAccount') ? undefined : json['bankAccount'],
        'birthDate': !exists(json, 'birthDate') ? undefined : json['birthDate'],
        'permAddress': !exists(json, 'permAddress') ? undefined : JsonAddressFromJSON(json['permAddress']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function JsonReplyPartyDataToJSON(value?: JsonReplyPartyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankAccount': value.bankAccount,
        'birthDate': value.birthDate,
        'permAddress': JsonAddressToJSON(value.permAddress),
        'phone': value.phone,
    };
}

