/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonTemplateData
 */
export interface JsonTemplateData {
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonTemplateData
     */
    attachFileIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof JsonTemplateData
     */
    headerFileId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTemplateData
     */
    title?: string;
}

export function JsonTemplateDataFromJSON(json: any): JsonTemplateData {
    return JsonTemplateDataFromJSONTyped(json, false);
}

export function JsonTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonTemplateData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachFileIds': !exists(json, 'attachFileIds') ? undefined : json['attachFileIds'],
        'headerFileId': !exists(json, 'headerFileId') ? undefined : json['headerFileId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function JsonTemplateDataToJSON(value?: JsonTemplateData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachFileIds': value.attachFileIds,
        'headerFileId': value.headerFileId,
        'title': value.title,
    };
}

