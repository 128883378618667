import {FormikErrors} from "formik";
import {TAppFunction} from "../services/i18n";

export function isEmailValid(email: string) {
    if (email.length > 256) {
        return false;
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function isPhoneValid(phone: string) {
    const re = /^\+?([0-9]{9,15})$/;
    return re.test(phone?.replaceAll(' ', ''));
}

export function isStreetValid(street: string) {
    const re = /^(.*[^0-9]+) (([1-9][0-9]*)\/)?([1-9][0-9]*[a-c]?)$/i;
    return re.test(street?.trim());
}

export function isZipValid(zip: string) {
    const re = /^[0-9]{3,}$/i;
    return re.test(zip?.replaceAll(' ', ''));
}

export function isCityValid(city: string) {
    const re = /^([^0-9]{2,})( ([1-9][0-9]*))?$/i;
    return re.test(city?.trim());
}

export const validateNumber = <T, >(name: keyof T, values: T, errors: FormikErrors<T>, t: TAppFunction, error?: string) => {
    const value = values[name];
    if (value === null || value === undefined || value === '') {
        return;
    }
    if (!('' + value).match(/^[1-9][0-9]*$/)) {
        errors[name] = error || t('Zadejte číslo') as any;
    }
}

export function padZeros(partNumber: string, width: number): string {
    const normPartNumber = partNumber.toString();
    return normPartNumber.length >= width ? normPartNumber : new Array(width - normPartNumber.length + 1).join('0') + normPartNumber;
}

export function isAccountValid(accNumber: string): boolean {
    const [account, bank] = accNumber?.split('/', 2);
    if (!account || !bank) {
        return checkIBAN(accNumber);
    }
    const bankRe = /^[0-9]{4}$/;
    if (!bankRe.test(bank)) {
        return false;
    }

    const re = /^([0-9]{1,6}-)?[0-9]{1,10}$/;
    if (!re.test(account)) {
        return false;
    }
    const accNumberParts = account.split('-');
    if (accNumberParts.length === 2) {
        if (!checkAccountPrefix(accNumberParts[0])) {
            return false;
        }
        if (!checkAccountNumber(accNumberParts[1])) {
            return false;
        }
    } else {
        if (!checkAccountNumber(accNumberParts[0])) {
            return false;
        }
    }
    return true;
}

export function checkAccountPrefix(accPrefix: string): boolean {
    const normPrefix = padZeros(accPrefix, 6);
    const mod11 = (
        10 * +normPrefix[0]
        + 5 * +normPrefix[1]
        + 8 * +normPrefix[2]
        + 4 * +normPrefix[3]
        + 2 * +normPrefix[4]
        + +normPrefix[5]) % 11;
    return mod11 === 0;
}

export function checkAccountNumber(accNumber: string): boolean {
    const normNumber = padZeros(accNumber, 10);
    const mod11 = (
        6 * +normNumber[0]
        + 3 * +normNumber[1]
        + 7 * +normNumber[2]
        + 9 * +normNumber[3]
        + 10 * +normNumber[4]
        + 5 * +normNumber[5]
        + 8 * +normNumber[6]
        + 4 * +normNumber[7]
        + 2 * +normNumber[8]
        + +normNumber[9]) % 11;
    return mod11 === 0;
}

function bigMod(num: string, mod: number): number {
    let remainder = 0;
    for (const val of num) {
        remainder = (remainder * 10 + (+val)) % mod;
    }
    return remainder;
}

/* test valid account: CZ3808000000000000000123 */
export function checkIBAN(iban: string): boolean {
    let newIban = iban.toUpperCase().replace(/ /gi, '');
    const re = /^((CZ|SK)[0-9]{2})([0-9]{20})$/;
    if (!re.test(newIban)) {
        return false;
    }
    newIban = newIban.substring(4) + newIban.substring(0, 4);
    newIban = newIban.replace(/[A-Z]/g, (match) => '' + (match.charCodeAt(0) - 55));
    // console.log({ iban, newIban, mode: bigMod(newIban, 97) });
    return bigMod(newIban, 97) === 1;
}
