import FormModal from "../components/form/FormModal";
import {useAppTranslation} from "../services/i18n";
import {partyName} from "../model/party";
import {JsonInvite, JsonInviteInfo, JsonInviteInfoInviteKindEnum, JsonInviteInfoInviteTypeEnum, JsonInviteInfoStatusEnum, JsonInviteReply, JsonInviteReplyStatusEnum} from "../generated-api";
import InviteReviewForm from "../components/invite/InviteReviewForm";
import CodebookValue from "../components/CodebookValue";
import {useCallback, useEffect, useState} from "react";
import {fetchInvite, fetchInviteInfo} from "../store/invites";
import {getApiResult} from "../helpers/api";
import {fetchInviteReply} from "../store/inviteReplies";
import {useAppDispatch} from "../store";
import InviteModal from "./InviteModal";
import {FAKE_VALUE_EMPTY} from "../model/form";

type Props = {
    inviteId: number,
    onSave: (values: JsonInviteReply) => void
    onCancel: () => void,
}

const InviteReviewModal = (props: Props) => {
    const {inviteId} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [inviteWithReply, setInviteWithReply] = useState<{ invite: JsonInviteInfo, reply: JsonInviteReply } | undefined>(undefined);
    const [editInvite, setEditInvite] = useState<JsonInvite | undefined>(undefined);

    const invite = inviteWithReply?.invite;
    const reply = inviteWithReply?.reply;

    const handleFetchInviteWithReply = useCallback(() => {
        if (!inviteId) {
            return;
        }
        dispatch(fetchInviteInfo(inviteId)).then((res) => {
            const invite = getApiResult<JsonInviteInfo>(res);
            if (!invite) {
                return;
            }
            if (!invite.replyId) {
                setInviteWithReply({invite, reply: {status: JsonInviteReplyStatusEnum.Draft}});
                return;
            }
            dispatch(fetchInviteReply(invite.replyId)).then((res) => {
                const reply = getApiResult(res);
                if (reply) {
                    setInviteWithReply({invite, reply});
                }
            });
        });

    }, [inviteId, dispatch]);

    const handleEditInvite = useCallback(async () => {
        if (invite?.inviteId) {
            setEditInvite(getApiResult(await dispatch(fetchInvite(invite.inviteId))));
        }
    }, [invite, dispatch]);

    const handleSaveInvite = useCallback(() => {
        handleFetchInviteWithReply();
        setEditInvite(undefined);
    }, [handleFetchInviteWithReply]);

    const handleCancelInvite = useCallback(() => {
        setEditInvite(undefined);
    }, []);

    useEffect(() => {
        handleFetchInviteWithReply();
    }, [handleFetchInviteWithReply])

    if (!invite || !reply) {
        return null;
    }
    if (reply.processData === undefined) {
        reply.processData = invite.inviteType === JsonInviteInfoInviteTypeEnum.TravelOrg
            ? {
                travels: []
            }
            : {
                extras: [],
                eventPartyDays: {
                    [invite.partyId as number]: {}
                }
            };
        if (invite.inviteType === JsonInviteInfoInviteTypeEnum.Supplier) {
            reply.replyData?.extras?.forEach((extra) => {
                if (!!extra.partyId && reply.processData?.extras && extra.extraId) {
                    if (extra.partyId !== FAKE_VALUE_EMPTY) {
                        reply.processData.extras[extra.extraId] = {...extra};
                    }
                }
            })
        }
        if (invite.inviteType === JsonInviteInfoInviteTypeEnum.Guest) {
            reply.replyData?.extras?.forEach((extra) => {
                if (!!extra.partyId && reply.processData?.extras && extra.extraId) {
                    if (extra.partyId !== FAKE_VALUE_EMPTY) {
                        reply.processData.extras[extra.extraId] = {...extra};
                    }
                }
            })
        }
    }
    let title;
    if (invite.inviteKind === JsonInviteInfoInviteKindEnum.Update
        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Travel
        || invite.inviteKind === JsonInviteInfoInviteKindEnum.Comp) {
        if (invite.status === JsonInviteInfoStatusEnum.Accepted) {
            title = t('Zpracování výzvy');
        } else {
            title = t('Náhled výzvy');
        }

    } else {
        if (invite.status === JsonInviteInfoStatusEnum.Accepted) {
            title = t('Zpracování pozvánky');
        } else {
            title = t('Náhled pozvánky');
        }
    }

    return <>
        <FormModal title={<span>{title} - {partyName(invite)} - <CodebookValue value={invite.eventId} name={'event'}/></span>}
            onCancel={props.onCancel}
            dialogClassName={'rsvp-review'}
            maxWidth={'lg'}>
            <InviteReviewForm {...props} invite={invite} item={reply} onEditInvite={handleEditInvite}/>
        </FormModal>
        {!!editInvite && <InviteModal
			item={editInvite}
			onCancel={handleCancelInvite}
			onSave={handleSaveInvite}
		/>}
    </>;
}

export default InviteReviewModal;
