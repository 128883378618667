import {Button, Container, Grid, Link} from '@mui/material';
import * as React from 'react';
import {useCallback} from 'react';
import {useAppTranslation} from '../services/i18n';
import {fetchEvents, fetchEventsCount} from '../store/events';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import PageHeader from '../components/layout/PageHeader';
import {GetEventCountUsingGETRequest, GetEventListUsingGETRequest, GetEventListUsingGETStatusesEnum, JsonEventInfo} from '../generated-api';
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridFilterProps, DataGridState, defaultDataGridFilterState} from "../components/DataGrid";
import {useAppDispatch, useAppSelector} from "../store";
import {selectEvents} from "../store/selectors";
import {TextFormField} from "../components/form/TextFormField";
import {ButtonGroupField} from "../components/form/ButtonGroupField";
import {OptionValue} from "../model/form";
import {dateDiff, dateToGui} from "../helpers/date";

interface EventsGridFilter extends DataGridFilter, GetEventListUsingGETRequest {
}

interface EventsGridState extends DataGridState<JsonEventInfo, EventsGridFilter> {
}

const cols: DataGridCol<JsonEventInfo>[] = [
    createCol('ID', 'eventId', 20, 'EMS ID', (v) => <small>{v}</small>),
    createCol('SIWI', 'siwiId', 80, 'Siwidata ID', (v) => <pre>{v}</pre>),
    createCol('Název', 'title', 190, undefined, (v, item) => <Link underline={'hover'} to={`/events/${item.eventId}`} component={RouterLink}>{v}</Link>),
    createCol('Zkratka', 'abbr', 55),
    createCol('Popis', 'description', 200),
    createCol('Datum', 'eventFrom', 135, 'Datum akce od - do', (v, item) => dateToGui(item.eventFrom)
        + ' - ' + dateToGui(item.eventTo)
        + ' (' + dateDiff(item.eventFrom, item.eventTo) + ')'),
    createCol('Σ Osob', 'partyTotalCnt', '50C', 'Celkem registrovaných (všechny stavy)', (v, item) => {
        return <Link underline={'hover'} to={`/event-parties?eventId=${item.eventId}`} component={RouterLink}>{v}</Link>
    }),
    createCol('Σ Nepo', 'partyPendingCnt', '50C', 'Celkem nepotvrzených registrovaných', (v, item) => {
        return <Link underline={'hover'} to={`/event-parties?eventId=${item.eventId}&statuses=PENDING`} component={RouterLink}>{v}</Link>
    }),
    createCol('Σ Pozv', 'invitePendingCnt', '50C', 'Celkem pozvánek bez odpovědi', (v, item) => {
        return <Link underline={'hover'} to={`/event-parties?eventId=${item.eventId}&inviteStatuses=PENDING&inviteStatuses=RETURNED`} component={RouterLink}>{v}</Link>
    }),
];

const defaultState: EventsGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'eventFrom',
        orderDir: 'desc',
        search: '',
        statuses: [GetEventListUsingGETStatusesEnum.Active]
    },
};

const statusOptions: OptionValue[] = [
    {value: GetEventListUsingGETStatusesEnum.Active, label: 'Aktivní'},
    {value: GetEventListUsingGETStatusesEnum.Deleted, label: 'Archivované'},
]

const filterFields = (props: DataGridFilterProps<EventsGridFilter>): JSX.Element => {
    const {formProps, buttons} = props;
    return <Grid container spacing={1}>
        <Grid item xs={3}>
            <TextFormField name="search" type={'text'} placeholder={'Název ...'} onBlur={formProps.submitForm} clearable/>
        </Grid>
        <Grid item xs={3}>
            <ButtonGroupField name={'statuses'} label={'Stav'} onChange={formProps.submitForm} options={statusOptions} isMulti={true}/>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={3}>
            {buttons}
        </Grid>
    </Grid>
}

const EventsPage = () => {
    const t = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const events = useAppSelector(selectEvents);

    const handleNewEvent = useCallback(() => {
        navigate('/events/new');
    }, [navigate])

    const handleFetchItems = useCallback((filter: EventsGridFilter) => {
        dispatch(fetchEventsCount(filter as any as GetEventCountUsingGETRequest)).then((r) => {
            dispatch(fetchEvents(filter));
        });

    }, [dispatch]);

    return (
        <Container>
            <PageHeader title={t('Události')}>
                <Button variant="contained" fullWidth onClick={handleNewEvent}>{t('Nová událost')}</Button>
            </PageHeader>

            <DataGrid
                cols={cols}
                defaultState={defaultState}
                handleFetchItems={handleFetchItems}
                itemsState={events}
                filterFields={filterFields}
            />
        </Container>
    );
}

export default EventsPage;
