/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventPartyImport,
    JsonEventPartyImportFromJSON,
    JsonEventPartyImportFromJSONTyped,
    JsonEventPartyImportToJSON,
} from './JsonEventPartyImport';

/**
 * 
 * @export
 * @interface JsonImportResultOfstringAndJsonEventPartyImport
 */
export interface JsonImportResultOfstringAndJsonEventPartyImport {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonImportResultOfstringAndJsonEventPartyImport
     */
    columnTypes?: Array<JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum>;
    /**
     * 
     * @type {Array<JsonEventPartyImport>}
     * @memberof JsonImportResultOfstringAndJsonEventPartyImport
     */
    rows?: Array<JsonEventPartyImport>;
}

/**
* @export
* @enum {string}
*/
export enum JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum {
    EmsId = 'EMS_ID',
    SiwiId = 'SIWI_ID',
    LastName = 'LAST_NAME',
    FirstName = 'FIRST_NAME',
    Sex = 'SEX',
    Email = 'EMAIL',
    Phone = 'PHONE',
    Address = 'ADDRESS',
    BirthDate = 'BIRTH_DATE',
    BankAccount = 'BANK_ACCOUNT',
    Group = 'GROUP',
    Article = 'ARTICLE',
    Company = 'COMPANY',
    Tags = 'TAGS',
    Note = 'NOTE'
}

export function JsonImportResultOfstringAndJsonEventPartyImportFromJSON(json: any): JsonImportResultOfstringAndJsonEventPartyImport {
    return JsonImportResultOfstringAndJsonEventPartyImportFromJSONTyped(json, false);
}

export function JsonImportResultOfstringAndJsonEventPartyImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonImportResultOfstringAndJsonEventPartyImport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnTypes': !exists(json, 'columnTypes') ? undefined : json['columnTypes'],
        'rows': !exists(json, 'rows') ? undefined : ((json['rows'] as Array<any>).map(JsonEventPartyImportFromJSON)),
    };
}

export function JsonImportResultOfstringAndJsonEventPartyImportToJSON(value?: JsonImportResultOfstringAndJsonEventPartyImport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnTypes': value.columnTypes,
        'rows': value.rows === undefined ? undefined : ((value.rows as Array<any>).map(JsonEventPartyImportToJSON)),
    };
}

