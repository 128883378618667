import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
    ApiResultOfJsonPartyInfo,
    FindPartyListUsingPOSTRequest,
    GetPartyCountUsingGETRequest,
    GetPartyListUsingGETRequest,
    JsonParty,
    JsonPartyInfo,
    MassPartyActionUsingPOSTRequest, MergePartiesUsingPOSTRequest,
    PartyControllerApi, PushPartyToSiwiUsingPOSTRequest
} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const partiesApi = new PartyControllerApi(API_CONFIG);

export const fetchParties = createAsyncThunk('parties/list', async (filter: GetPartyListUsingGETRequest, thunkApi) => {
    return {...await partiesApi.getPartyListUsingGET(filter), filter};
});

export const fetchPartiesCount = createAsyncThunk('parties/count', async (params: GetPartyCountUsingGETRequest = {}) => {
    return await partiesApi.getPartyCountUsingGET(params);
});

export const fetchPartiesOnline = createAsyncThunk('parties/online', async (filter: GetPartyListUsingGETRequest, thunkApi) => {
    return await partiesApi.getPartyListUsingGET(filter);
});

export const findParties = createAsyncThunk('parties/find', async ({requests, exactOnly}: FindPartyListUsingPOSTRequest, thunkApi) => {
    return await partiesApi.findPartyListUsingPOST({requests, exactOnly});
});

export const fetchPartyInfo = createAsyncThunk('party/info', async (partyId: number): Promise<ApiResultOfJsonPartyInfo> => {
    return await partiesApi.getPartyInfoUsingGET({partyId});
});

export const fetchParty = createAsyncThunk('party/fetch', async (partyId: number) => {
    return await partiesApi.getPartyUsingGET({partyId});
});

export const saveParty = createAsyncThunk('party/save', async (party: JsonParty) => {
    return await partiesApi.savePartyUsingPOST({json: party});
});

export const massParty = createAsyncThunk('party/mass', async (arg: MassPartyActionUsingPOSTRequest) => {
    return await partiesApi.massPartyActionUsingPOST({request: arg.request, testOnly: arg.testOnly});
});

export const mergeParties = createAsyncThunk('party/merge', async (request: MergePartiesUsingPOSTRequest) => {
    return await partiesApi.mergePartiesUsingPOST(request);
});

export const fetchSiwiPartyList = createAsyncThunk('party/siwiList', async (partyId: number) => {
    return await partiesApi.getSiwiPartiesUsingGET({partyId});
});

export const pushPartyToSiwi = createAsyncThunk('party/siwiPush', async (request: PushPartyToSiwiUsingPOSTRequest) => {
    return await partiesApi.pushPartyToSiwiUsingPOST(request);
});

export const partiesSlice = createSlice({
    name: 'parties',
    initialState: (): ItemsState<JsonPartyInfo, GetPartyListUsingGETRequest> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchParties, fetchPartiesCount);
    }
});

export const partiesReducer = partiesSlice.reducer;
