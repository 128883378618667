import {Button, CircularProgress, Grid} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {FAKE_VALUE_EMPTY, FormProps} from "../../model/form";
import {
    JsonEventPartyImport,
    JsonFindPartyRequest,
    JsonFindPartyResponse,
    JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum,
    JsonPartyInfo,
    JsonPartyInfoSexEnum
} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {CheckBoxOutlineBlankRounded, CheckBoxRounded, ChevronRight, SearchRounded} from "@mui/icons-material";
import * as React from "react";
import {useCallback, useState} from "react";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {sexOptions, sortFoundParties} from "../../model/party";
import {PhotoFormField} from "../form/PhotoFormField";
import {getApiResult} from "../../helpers/api";
import {useAppDispatch} from "../../store";
import {findParties} from "../../store/parties";
import {FoundParty} from "./FoundParty";
import CodebookValue from "../CodebookValue";
import PartyTags from "../PartyTags";
import {SelectFormField} from "../form/SelectFormField";

export interface PartyImportEditFormProps extends FormProps<JsonEventPartyImport> {
    eventId?: number;
    original?: JsonEventPartyImport;
    columnTypes?: JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum[];
}

const getSearchHash = (values: JsonEventPartyImport): string => {
    return JSON.stringify([values.firstName, values.lastName, values.sex, values.photoGuid]);
}

const PartyImportEditForm = (props: PartyImportEditFormProps) => {
    const {eventId, item, original, columnTypes, onSave} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [foundParties, setFoundParties] = useState<JsonFindPartyResponse[] | undefined>(item.foundParties);
    const [isFindRunning, setIsFindRunning] = useState<boolean>(false);
    const [lastSearchHash, setLastSearchHash] = useState<String | undefined>(getSearchHash(item));

    const handleFindParties = useCallback(async (item: JsonEventPartyImport) => {
        setIsFindRunning(true);
        const res = await dispatch(findParties({
            exactOnly: true,
            requests: [{
                ...item,
                queryId: "1",
            }] as JsonFindPartyRequest[]
        }))

        const items = getApiResult<JsonFindPartyResponse[]>(res);
        if (items) {
            setFoundParties(sortFoundParties(items));
            setLastSearchHash(getSearchHash(item));
        }
        setIsFindRunning(false);

    }, [dispatch]);

    const validate = useCallback((values: JsonEventPartyImport) => {
        let errors = {} as FormikErrors<JsonEventPartyImport>;
        if (!values.firstName) {
            errors.firstName = t('Zadejte prosím jméno');
        }
        if (!values.lastName) {
            errors.lastName = t('Zadejte prosím příjmení');
        }
        if (!values.sex) {
            errors.sex = t('Zvolte prosím');
        }
        return errors;
    }, [t]);

    const actions = useCallback(({values, setFieldValue, isSubmitting, setFormikState}: FormikProps<JsonEventPartyImport>, props: FormProps<JsonEventPartyImport>) => {
        const isChanged = original && JSON.stringify(
                {
                    firstName: original.firstName, lastName: original.lastName, sex: original.sex,
                    birthDate: original.birthDate, phone: original.phone, email: original.email, note: original.note,
                    photoGuid: original.photoGuid
                })
            !== JSON.stringify(
                {
                    firstName: values.firstName, lastName: values.lastName, sex: values.sex,
                    birthDate: values.birthDate, phone: values.phone, email: values.email, note: values.note,
                    photoGuid: values.photoGuid
                })
        const isSubmittable = !!values.partyId || (!!values.resultParty?.partyId && getSearchHash(values) === lastSearchHash);
        return <>
            {isChanged && <Grid item sx={{flexGrow: 1}}>
				<Button variant="text" color={'info'} onClick={() => {
                    setFieldValue('firstName', original?.firstName)
                    setFieldValue('lastName', original?.lastName);
                    setFieldValue('sex', original?.sex);
                    setFieldValue('birthDate', original?.birthDate);
                    setFieldValue('phone', original?.phone);
                    setFieldValue('email', original?.email);
                    setFieldValue('note', original?.note);
                    setFieldValue('photoGuid', original?.photoGuid);
                    setFieldValue('resultParty', original?.resultParty);
                    setFormikState((s) => ({...s, errors: {}}));
                }}>{t('Obnovit původní údaje')}</Button>
			</Grid>}
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
			</Grid>}
            <Grid item>
                <Button variant="contained" type="submit" color={'success'} disabled={!isSubmittable || isSubmitting}>
                    {t('Potvrdit')}
                </Button>
            </Grid>
        </>
    }, [lastSearchHash, original, t]);

    return <FormContainer {...props}
        actions={actions}
        onSave={(item) => {
            if (onSave) {
                if (!!item.partyId && item.resultParty) {
                    item.resultParty.firstName = item.firstName;
                    item.resultParty.lastName = item.lastName;
                    item.resultParty.sex = item.sex === undefined ? undefined : JsonPartyInfoSexEnum[item.sex];
                    item.resultParty.birthDate = item.birthDate;
                    item.resultParty.phone = item.phone;
                    item.resultParty.email = item.email;
                    item.resultParty.note = item.note;
                    item.resultParty.photoGuid = item.photoGuid;

                } else if (foundParties && !!item?.resultParty?.partyId && item?.resultParty?.partyId > 0) {
                    const p = foundParties.find((fp) => fp.party?.partyId === item?.resultParty?.partyId);
                    if (p) {
                        item.foundParties = [p];
                    }
                }
                return onSave(item);
            }
        }}
        validate={validate} children={({values, setFieldValue, validateForm, setFormikState}) => {
        const isHashEqual = getSearchHash(values) === lastSearchHash;
        const isPhotoUsed = !!values.photoGuid;
        const isAnyResult = isHashEqual && !!foundParties?.length && foundParties.length > 0;
        const isSelectedNew = values.resultParty?.partyId === FAKE_VALUE_EMPTY;
        const isAlreadyLinked = !!item.partyId;

        return <Grid item xs={12} sx={{minHeight: '400px'}}>
            <Grid container columns={45} columnSpacing={2}>
                <Grid item xs={isAlreadyLinked ? 45 : 15}>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={6} sx={{display: 'flex', alignItems: 'start'}}>
                            <Grid container columnSpacing={2} rowSpacing={1} className={'form-container'}>
                                <Grid item xs={12}>
                                    <TextFormField name="firstName" label={t('Jméno')} type={'text'}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFormField name="lastName" label={t('Příjmení')} type={'text'}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <ButtonGroupField name={'sex'} label={t('Pohlaví')} options={sexOptions}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <PhotoFormField name={'photoGuid'} isInternal maxWidth={112} maxHeight={112}
                                title={((values?.firstName || '') + ' ' + (values?.lastName || '')).trim()}
                                defaultValue={!!item.partyId ? item.foundParties?.[0]?.party?.photoGuid : undefined}
                                onChange={(file) => {
                                    setFieldValue('resultParty.photoCvResult', file?.cvResult, false);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: '10px'}}>
                            <Grid container columnSpacing={2} rowSpacing={1} className={'form-container'}>
                                {!!columnTypes && columnTypes.indexOf(JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum.BirthDate) >= 0 && <Grid item xs={6}>
									<TextFormField name={'birthDate'} label={t('Dat. nar.')} type={'date'}/>
								</Grid>}
                                {!!columnTypes && columnTypes.indexOf(JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum.Phone) >= 0 && <Grid item xs={6}>
									<TextFormField name="phone" label={t('Telefon')} type={'text'}/>
								</Grid>}
                                {!!columnTypes && columnTypes.indexOf(JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum.Email) >= 0 && <Grid item xs={12}>
									<TextFormField name="email" label={t('Email')} type={'text'}/>
								</Grid>}
                                {!!columnTypes && columnTypes.indexOf(JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum.Company) >= 0 && <Grid item xs={12}>
									<SelectFormField name={'companyId'} label={t('Firma')} codebookProps={{codebookName: 'company', asNumber: true}} clearable/>
								</Grid>}
                                {!!columnTypes && columnTypes.indexOf(JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum.Note) >= 0 && <Grid item xs={12}>
									<TextFormField name="note" label={t('Poznámka')} type={'textarea'} rows={2}/>
								</Grid>}
                                <Grid item xs={12}>
                                    {!!item.groupId && <>{t('Skupina')}: <CodebookValue value={item.groupId} name={'group'}/></>}
                                </Grid>
                                <Grid item xs={12}>
                                    {!!item.tags && <PartyTags tags={item.tags}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isAlreadyLinked && <Grid item xs={2} className={'rsvp-extra-match-chevron'}>
					<div>
						<ChevronRight style={{width: '450%', marginLeft: '-180%'}}/>
					</div>
				</Grid>}
                {!isAlreadyLinked && <Grid item xs={28}>{lastSearchHash === undefined
                    ? <p dangerouslySetInnerHTML={{
                        __html: t('Pokud si myslíte, že tato osoba již v systému existuje, ověřte zadané <strong>jméno a pohlaví</strong> a stiskněte "<i>Najít možné shody bez použití fotografie</i>".')
                    }}/>
                    : !isAnyResult && isHashEqual
                        ? isPhotoUsed
                            ? <>
                                <p>{t('Pro zadané jméno, pohlaví a fotografii nebyly nalezeny žádné shody.')}</p>
                                <p>{t('Ověřte prosím, že jsou údaje v pořádku, a potvrďte založení nové osoby.')}</p>
                            </>
                            : <>
                                <p>{t('Pro zadané jméno a pohlaví nebyly nalezeny žádné shody.')}</p>
                                <p>{t('Upravte prosím údaje a/nebo nahrajte fotografii a hledání opakujte, případně potvrďte založení nové osoby bez fotografie.')}</p>
                            </>
                        : isHashEqual
                            ? null
                            : <p>{t('Údaje byly od posledního hledání změněny, proveďte prosím hledání znovu.')}</p>}
                    {isFindRunning
                        ? <p><CircularProgress size={20}/></p>
                        : !isHashEqual
                            ? <p><Button size={'small'} variant={'contained'} onClick={async () => {
                                setFieldValue('resultParty', original?.resultParty);
                                validateForm(values).then((errors) => {
                                    if (errors && Object.keys(errors).length > 0) {
                                        setFormikState((s) => ({...s, submitCount: s.submitCount + 1}));
                                    } else {
                                        handleFindParties(values).then();
                                    }
                                });
                            }}>
                                <SearchRounded/>
                                <span>{!!values.photoGuid ? t('Najít možné shody') : t('Najít možné shody bez použití fotografie')}</span></Button></p>
                            : null}
                    {lastSearchHash === undefined && <p dangerouslySetInnerHTML={{
                        __html: t('Jinak prosím nahrajte fotografii tlačítkem "<i>Nahrát fotografii</i>", aby bylo možné hledání zpřesnit, případně osobu založte jako novou bez fotografie (bude nutné později doplnit).')
                    }}/>}
                    {isAnyResult
                        && <p>{t('Dle zadaných údajů byly nalezeny následující možné shody:')}</p>}
					<Grid container spacing={1}>
                        {isHashEqual && !!foundParties?.length && foundParties.length > 0 && foundParties.map((fp, i) => {
                            if (!!values.resultParty?.partyId && values.resultParty?.partyId > 0 && values.resultParty.partyId !== fp.party?.partyId) {
                                return null;
                            }
                            return <FoundParty key={i} eventId={eventId}
                                maxWidth={80}
                                maxHeight={180}
                                sourceParty={values as JsonPartyInfo}
                                foundPartyResponse={fp}
                                targetParty={values.resultParty as JsonPartyInfo}
                                withEmail
                                handleValueChange={(n, v) => {
                                    setFieldValue('resultParty.' + n, v, true);
                                }}/>

                        })}
					</Grid>
                    {isAnyResult && !values.photoGuid && <p style={{marginTop: "10px"}}>
                        {t('Pokud mezi výsledky osoba není, upravte údaje a/nebo nahrajte fotografii a hledání opakujte, případně potvrďte založení nové osoby bez fotografie.')}</p>}
                    {isAnyResult && !!values.photoGuid && <p style={{marginTop: "10px"}}>
                        {t('Pokud mezi výsledky osoba není, upravte údaje a vyhledání opakujte, nebo potvrďte založení nové osoby.')}</p>}
                    {(isHashEqual || lastSearchHash === undefined) && <Button title={undefined} variant={'contained'} size={'small'} style={{marginTop: "10px"}}
						color={isSelectedNew ? (!!values.photoGuid ? 'info' : 'warning') : 'inherit'}
						onClick={() => {
                            setFieldValue('resultParty.partyId', isSelectedNew ? undefined : FAKE_VALUE_EMPTY);
                        }}>
                        {isSelectedNew ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
						<span>{!!values.photoGuid ? t('Založit osobu jako novou') : t('Založit osobu jako novou bez fotografie')}</span>
					</Button>}
				</Grid>}
            </Grid>
        </Grid>
    }}/>;
}

export default PartyImportEditForm;
