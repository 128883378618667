import {selectCodebooks} from '../store/selectors';
import {useAppSelector} from "../store";
import {getCodebookLabel, getOption, OptionValue} from "../model/form";
import {Typography} from "@mui/material";
import * as React from "react";

export const OptionIcon = ({value, options}: { value: any, options: OptionValue[] }) => {
    const o = getOption(value, options);
    return <Typography component={'div'}
        sx={o.color ? {color: (theme) => theme.palette[o.color || 'primary'].main} : undefined}
        title={o.tooltip}>{o.icon}</Typography>
}

interface Props {
    value: any;
    name: string;
    formatValue?: (value: string) => string;
    scope?: number;
}

const CodebookValue = ({value, name, scope, formatValue}: Props) => {

    const codebooks = useAppSelector(selectCodebooks);
    const label = getCodebookLabel(codebooks, name, value, scope);
    return (
        <span>{formatValue ? formatValue(label) : label}</span>
    );
}

export default CodebookValue;
