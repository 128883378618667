import FormModal from "../components/form/FormModal";
import {useAppTranslation} from "../services/i18n";
import {PartyMassActionProps, PartyMassActionValues} from "../model/party";
import PartyRegisterToEventForm from "../components/party/PartyRegisterToEventForm";
import {
    ApiReport,
    JsonEventParty,
    JsonEventPartyAccredStatusEnum,
    JsonEventPartyInfoPartyTypeEnum,
    JsonEventPartyMassActionRequestActionEnum,
    JsonEventPartyMassActionRequestContractStatusEnum,
    JsonEventPartyMassActionRequestUpdateTypeEnum,
    JsonPartyInfo
} from "../generated-api";
import * as React from "react";
import {ReactNode, useCallback} from "react";
import PartyMassUpdateForm from "../components/party/PartyMassUpdateForm";
import PartyAccredToEventForm from "../components/party/PartyAccredToEventForm";
import {Breakpoint} from "@mui/system";
import {addApiResultMessage, ApiChangeType, getApiResult, ResultMessages} from "../helpers/api";
import {generateEventPartyPayment, massEventParty, printEventPartyContract, pushEventPartiesToSiwi} from "../store/eventParties";
import {FAKE_VALUE_EMPTY, getCodebookLabel} from "../model/form";
import {useAppDispatch, useAppSelector} from "../store";
import {selectCodebooks} from "../store/selectors";
import {CodebookState, FIXED_EVENT_DAY_NOS, FIXED_VIP_GROUP_ID} from "../store/codebooks";
import {SaveRounded} from "@mui/icons-material";
import {ModalProps, useModal} from "../services/modal";
import PartyUpdateOrgForm from "../components/party/PartyUpdateOrgForm";
import PartyPrintContractForm from "../components/party/PartyPrintContractForm";
import {dateToGuiAs} from "../helpers/date";
import {Alert} from "@mui/material";
import PartyPaymentForm from "../components/party/PartyPaymentForm";
import {addMessage} from "../store/localApp";

export const createMassAction = <F, >(
    action: JsonEventPartyMassActionRequestActionEnum,
    eventId: number,
    replyUntil: string | undefined,
    filter: F,
    parties: JsonPartyInfo[]
): PartyMassActionValues<F> => {
    return {
        filter,
        parties,
        eventParties: [],
        values: {
            action,
            eventId,
            groupId: action === JsonEventPartyMassActionRequestActionEnum.InviteGuest ? FIXED_VIP_GROUP_ID : undefined,
            invite: {
                replyUntil,
                inviteData: action === JsonEventPartyMassActionRequestActionEnum.InviteGuest ? {
                    extraLimit: 1,
                    isPark: true,
                    eventDays: FIXED_EVENT_DAY_NOS
                } : {}
            },
            items: []
        }
    };
}

const modalUpdateTitle = (updateType?: JsonEventPartyMassActionRequestUpdateTypeEnum) => {
    switch (updateType) {
        case JsonEventPartyMassActionRequestUpdateTypeEnum.ArticleContract:
            return 'Rychlé zadání smlouvy a vydaných artiklů';
        case JsonEventPartyMassActionRequestUpdateTypeEnum.Comp:
            return 'Úprava odměn';
        case JsonEventPartyMassActionRequestUpdateTypeEnum.Parking:
            return 'Úprava parkování / SPZ';
        default:
            return 'Úprava osoby v události';
    }
}

const PartyMassActionModal = <F, >(props: PartyMassActionProps<PartyMassActionValues<F>, F>) => {

    const {onSave} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();
    const codebooks = useAppSelector<CodebookState>(selectCodebooks);

    const handleSaveMassAction = useCallback(async (massAction: PartyMassActionValues<F>) => {
        const {values: {action, updateType}} = massAction;

        let skipConfirm = false;
        switch (action) {
            case JsonEventPartyMassActionRequestActionEnum.Register:
            case JsonEventPartyMassActionRequestActionEnum.InviteGuest:
            case JsonEventPartyMassActionRequestActionEnum.InviteOrg:
            case JsonEventPartyMassActionRequestActionEnum.InviteSupp:
            case JsonEventPartyMassActionRequestActionEnum.UpdateOrg:
            case JsonEventPartyMassActionRequestActionEnum.TravelOrg:
            case JsonEventPartyMassActionRequestActionEnum.CompOrg:
                break;

            case JsonEventPartyMassActionRequestActionEnum.Update:
            case JsonEventPartyMassActionRequestActionEnum.Delete:
            case JsonEventPartyMassActionRequestActionEnum.Accred:
            case JsonEventPartyMassActionRequestActionEnum.Comp:
                if (updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.ArticleContract) {
                    const alerts = [];
                    if (massAction.values.contractStatus !== JsonEventPartyMassActionRequestContractStatusEnum.Signed) {
                        alerts.push(<div key={0}>{t('BEZ podepsané smlouvy')}</div>);
                    }
                    const currentPref: { [key in number]: number } = {};
                    massAction.values.prefArticles?.forEach((pa) => {
                        if (!pa.articleTypeId) {
                            return;
                        }
                        if (pa.articleId && pa.articleId > 0) {
                            currentPref[pa.articleTypeId] = pa.articleId;
                        } else if (pa.articleId === FAKE_VALUE_EMPTY) {
                            const a = massAction.eventParties[0]?.eventArticleDetails
                                ?.find((epa) => epa.articleTypeId === pa.articleTypeId);
                            if (a?.prefArticleId) {
                                currentPref[pa.articleTypeId] = a.prefArticleId;
                            }
                        }
                    });
                    const missingRec = massAction.values.recArticles?.filter((ra) => !(ra.articleId && ra.articleId > 0)
                        && (ra.articleTypeId && !!currentPref[ra.articleTypeId])
                        && !massAction.eventParties.find((ep) => ep.eventArticleDetails?.find((epa) => epa.articleTypeId === ra.articleTypeId
                            && !!epa.recArticleId)));
                    if (missingRec?.length && missingRec.length > 0) {
                        alerts.push(<div key={1}>{t('BEZ vydaných artiklů ({{count}})', {count: missingRec.length})}</div>);
                    }
                    if (alerts.length > 0) {
                        const result = await modal.confirm({
                            title: t('Potvzení zadání smlouvy a artiklů'),
                            message: <Alert severity={'warning'}>
                                {t('Skutečně provést uložení:')}
                                {alerts}
                            </Alert>,
                            cancelText: 'Zpět',
                            confirmColor: 'success',
                            confirmText: 'Uložit změny',
                            confirmIcon: <SaveRounded/>,
                        } as ModalProps);
                        if (result !== 'CONFIRM') {
                            return;
                        }
                    }
                    skipConfirm = massAction.values.items?.length === 1;
                }

                const testRes = await dispatch(massEventParty({request: massAction.values, testOnly: true}));
                const testItems = getApiResult<JsonEventParty[]>(testRes);
                if (!testItems) {
                    return;
                }
                const updateCount = testItems?.filter((p) => (p.changes?.length && p.changes.length > 0))?.length || 0;
                if (updateCount <= 0) {
                    await modal.info({
                        title: t('Výsledek úprav'),
                        message: t('Uložení neprovede žádné změny'),
                        confirmColor: 'info',
                        confirmText: 'Rozumím',
                    })
                    return [];
                }

                if (!skipConfirm) {
                    const alerts: JSX.Element[] = [];
                    let title: undefined | string = undefined;
                    if (action === JsonEventPartyMassActionRequestActionEnum.Accred) {
                        massAction.values.items?.forEach((ep, i) => {
                            if (!ep.formatCode) {
                                return;
                            }
                            if (ep.accredStatus === JsonEventPartyAccredStatusEnum.EmsApproved) {
                                if (!title) {
                                    title = t('Potvrzení finální akreditace');
                                }
                            }
                            const item = massAction.eventParties.find((x) => x.eventPartyId === ep.eventPartyId);
                            if (item?.extraEventParties && item?.extraEventParties.length > 0) {
                                const hasParentZ = !!ep.areas && ep.areas.indexOf('Z') >= 0;
                                const hasParentR = !!ep.areas && ep.areas.indexOf('R') >= 0;
                                item?.extraEventParties.forEach((extra, j) => {
                                    const extraValue = massAction.values.items?.find((x) => x.eventPartyId === extra.eventPartyId);
                                    if (extraValue?.formatCode) {
                                        const hasExtraZ = !!extraValue.areas && extraValue.areas.indexOf('Z') >= 0;
                                        const hasExtraR = !!extraValue.areas && extraValue.areas.indexOf('R') >= 0;
                                        if (hasParentR !== hasExtraR) {
                                            alerts.push(<div key={i + '-' + j + '-r'}>{t('Nesoulad R: {{parent}}, {{extra}}', {
                                                parent: item.lastName + ' ' + (hasParentR ? t('Ano') : t('Ne')),
                                                extra: extra.lastName + ' ' + (hasExtraR ? t('Ano') : t('Ne'))
                                            })}</div>);
                                        } else if (hasParentZ !== hasExtraZ) {
                                            alerts.push(<div key={i + '-' + j + '-z'}>{t('Nesoulad R: {{parent}}, {{extra}}', {
                                                parent: item.lastName + ' ' + (hasParentZ ? t('Ano') : t('Ne')),
                                                extra: extra.lastName + ' ' + (hasExtraZ ? t('Ano') : t('Ne'))
                                            })}</div>);
                                        }
                                    }
                                })
                            }
                        });
                        if (!title) {
                            title = t('Potvrzení konceptu akreditace');
                        }
                    } else if (action === JsonEventPartyMassActionRequestActionEnum.Delete) {
                        title = t('Potvrzení odstranění');

                    } else {
                        title = t('Potvrzení úprav');
                    }

                    const result = await modal.confirm({
                        title,
                        message: <div>
                            <p>{t('Uložením dojde k úpravě celkem {{count}} osob, pokračovat?', {count: updateCount})}</p>
                            {alerts.length > 0 && <Alert severity={'error'}>
								<div style={{maxHeight: '100px', overflowY: 'auto', width: '100%'}}>
                                    {alerts}
								</div>
							</Alert>}
                        </div>,
                        cancelText: 'Zpět',
                        confirmColor: 'success',
                        confirmText: 'Uložit změny',
                        confirmIcon: <SaveRounded/>,
                    } as ModalProps);
                    if (result !== 'CONFIRM') {
                        return;
                    }
                }
                break;

            case JsonEventPartyMassActionRequestActionEnum.PrintContract:
                const res = await dispatch(printEventPartyContract(massAction.values));
                if (res.payload) {
                    const url = window.URL.createObjectURL(
                        new Blob([res.payload as string], {type: 'application/pdf'}),
                    );
                    let n = dateToGuiAs(new Date(), 'yyMMdd HHmm')
                    const items = massAction.values.items;
                    if (items?.length === 1 && !!items[0].lastName) {
                        n += '_' + items[0].lastName.normalize('NFD').replace(/\p{Diacritic}/gu, "").replaceAll(' ', '_');
                    } else {
                        n += '_' + items?.length + 'x';
                    }

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `EMS_Smlouvy_${n.replaceAll(' ', '_')}.pdf`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                    URL.revokeObjectURL(url);
                }

                if (onSave) {
                    onSave(massAction);
                }
                return;

            case JsonEventPartyMassActionRequestActionEnum.Payment: {
                const res = await dispatch(generateEventPartyPayment(massAction.values));
                if (res.payload) {
                    const url = window.URL.createObjectURL(
                        new Blob([res.payload as string], {type: 'text/plain'}),
                    );
                    let n = dateToGuiAs(new Date(), 'yyMMdd HHmm')
                    const items = massAction.values.items;
                    if (items?.length === 1 && !!items[0].lastName) {
                        n += '_' + items[0].lastName.normalize('NFD').replace(/\p{Diacritic}/gu, "").replaceAll(' ', '_');
                    } else {
                        n += '_' + items?.length + 'x';
                    }
                    if (!!massAction.values.confirmPayment) {
                        n += '_potvrzene';
                    } else {
                        n += '_koncept';
                    }

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `EMS_Platby_${n.replaceAll(' ', '_')}.kpc`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                    URL.revokeObjectURL(url);
                }

                if (onSave) {
                    onSave(massAction);
                }
                return;
            }

            case JsonEventPartyMassActionRequestActionEnum.Email:
            case JsonEventPartyMassActionRequestActionEnum.Import:
                break;
        }

        const res = await dispatch(massEventParty({request: massAction.values}));
        const items = getApiResult<JsonEventParty[]>(res);
        if (items) {
            const m: ResultMessages = {};
            switch (action) {
                case JsonEventPartyMassActionRequestActionEnum.Register:
                case JsonEventPartyMassActionRequestActionEnum.InviteGuest:
                case JsonEventPartyMassActionRequestActionEnum.InviteOrg:
                case JsonEventPartyMassActionRequestActionEnum.InviteSupp:
                    m[ApiChangeType.UPDATED] = [massAction.values.action === JsonEventPartyMassActionRequestActionEnum.Register
                        ? 'Osoby byly úspěšně přidány do události {{title}}'
                        : 'Pozvánky byly úspěšně rozeslány pro událost {{title}}',
                        ((items) => getCodebookLabel(codebooks, 'event', massAction.values.eventId) + ' (' + items.length + ')')];
                    break;

                case JsonEventPartyMassActionRequestActionEnum.UpdateOrg:
                    m[ApiChangeType.UPDATED] = 'Výzva k aktualizaci údajů byla úspěšně odeslána';
                    break;

                case JsonEventPartyMassActionRequestActionEnum.TravelOrg:
                    m[ApiChangeType.UPDATED] = 'Výzva k zadání cestovních náhrad byla úspěšně odeslána';
                    break;

                case JsonEventPartyMassActionRequestActionEnum.CompOrg:
                    m[ApiChangeType.UPDATED] = 'Výzva k potvrzení výplaty byla úspěšně odeslána';
                    break;

                default:
                    m[ApiChangeType.NO_CHANGE] = 'Nedošlo k žádným změnám';
                    m[ApiChangeType.UPDATED] = ['Změny byly uloženy (celkem {{title}})',
                        () => items?.filter((p) => (p.changes?.length && p.changes.length > 0))?.length];
                    break;
            }

            addApiResultMessage(res, m, t, dispatch);

            if (onSave) {
                onSave(massAction);
            }

            if (action === JsonEventPartyMassActionRequestActionEnum.Accred) {
                const confirmedPartyIds = massAction.values.items
                    ?.filter(ep => ep.accredStatus === JsonEventPartyAccredStatusEnum.EmsApproved && !!ep.partyId)
                    ?.map(ep => ep.partyId);
                if (!!confirmedPartyIds?.length && !!massAction.values.eventId) {
                    dispatch(pushEventPartiesToSiwi({
                        eventId: massAction.values.eventId,
                        partyIds: confirmedPartyIds as number[],
                        // testOnly: true
                    })).then((res) => {
                        const reports = getApiResult<{ [key in 'pushed' | 'updated' | 'accreditations']: ApiReport; }>(res);
                        if ((res.payload as any)?.result === 'OK') {
                            let title;
                            let severity: 'info' | 'warning' | 'error';
                            if (!!reports?.pushed?.errors || !!reports?.updated?.errors || !!reports?.accreditations?.errors) {
                                title = 'Během propisu do SIWI došlo k chybě, kontaktujte prosím servisní podporu';
                                severity = 'error';
                            } else if (!!reports?.pushed?.skipped) {
                                title = 'Při propisu nové osoby do SIWI byl nalezen potenciální duplikát';
                                severity = 'warning';
                            } else if (!!reports?.accreditations?.created || !!reports?.accreditations?.updated) {
                                title = 'Akreditace úspěšně propsána do SIWI (' + ((reports?.accreditations?.created || 0) + (reports?.accreditations?.updated || 0) + ')');
                                severity = 'info';
                            } else {
                                title = 'Do SIWI nebylo třeba propsat žádnou novou akreditaci';
                                severity = 'info';
                            }
                            dispatch(addMessage({
                                code: 'OK',
                                action: res.type,
                                severity,
                                title
                            }));
                        }
                    });
                }
            }
        }

    }, [codebooks, onSave, modal, dispatch, t])

    const action = props.massAction.values.action;
    if (!action) {
        return null;
    }
    const formProps = {...props, onSave: handleSaveMassAction};
    const updateType = props.massAction.values.updateType;

    const actions: { [key in JsonEventPartyMassActionRequestActionEnum]: [string, ReactNode, Breakpoint?] } = {
        [JsonEventPartyMassActionRequestActionEnum.Register]: ['Přímé vložení do události', <PartyRegisterToEventForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.InviteGuest]: ['Pozvání hosta do události', <PartyRegisterToEventForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.InviteOrg]: ['Pozvání organizátora do události', <PartyRegisterToEventForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.InviteSupp]: ['Výzva dodavateli k doplnění personálu', <PartyRegisterToEventForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.UpdateOrg]: ['Výzva k aktualizaci osobních údajů', <PartyUpdateOrgForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.Update]: [modalUpdateTitle(updateType),
            <PartyMassUpdateForm {...formProps}/>,
            updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.Parking ? 'sm' : 'lg'],
        [JsonEventPartyMassActionRequestActionEnum.Delete]: ['Odstranění z události', <PartyMassUpdateForm {...formProps} />],
        [JsonEventPartyMassActionRequestActionEnum.Comp]: ['Úprava odměn', <PartyMassUpdateForm {...formProps} />],
        [JsonEventPartyMassActionRequestActionEnum.TravelOrg]: ['Výzva k zadání cestovních náhrad', <PartyUpdateOrgForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.CompOrg]: ['Výzva k potvrzení výplaty', <PartyUpdateOrgForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.Accred]: ['Úprava akreditace', <PartyAccredToEventForm {...formProps}/>, 'lg'],
        [JsonEventPartyMassActionRequestActionEnum.PrintContract]: ['Tisk smlouvy', <PartyPrintContractForm {...formProps}/>, 'lg'],
        [JsonEventPartyMassActionRequestActionEnum.Payment]: ['Výplata odměn a cestovného', <PartyPaymentForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.Email]: ['Pozvat organizátory do události', <PartyRegisterToEventForm {...formProps}/>],
        [JsonEventPartyMassActionRequestActionEnum.Import]: ['', null], // handled elsewhere
        [JsonEventPartyMassActionRequestActionEnum.Seating]: ['', null], // handled elsewhere
    };
    const text = actions[action];
    if (!text) {
        return null;
    }
    if (action === JsonEventPartyMassActionRequestActionEnum.Update
        && props.massAction.values.updateType === JsonEventPartyMassActionRequestUpdateTypeEnum.ArticleContract
        && formProps.massAction.eventParties.length === 1
        && formProps.massAction.eventParties[0].partyType === JsonEventPartyInfoPartyTypeEnum.T) {
        text[2] = 'lg';
    }

    return (
        <FormModal title={t(text[0])} onCancel={props.onCancel} maxWidth={text[2] || 'md'}>
            {text[1]}
        </FormModal>
    );
}

export default PartyMassActionModal;
