import {createAsyncThunk, createSlice, PayloadAction,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {JsonPoplistItem, PoplistControllerApi} from '../generated-api';

export const FIXED_EVENT_DAY_NOS = [3, 5, 6, 7, 9, 10, 11, 13, 14]
export const FIXED_ORG_UPDATE_REPLY_UNTIL = '2024-02-22';
export const FIXED_ORG_COMP_REPLY_UNTIL = '2024-03-24';
export const FIXED_VIP_GROUP_ID = 44;
export const FIXED_VIP_TAG_ID = 1;
export const FIXED_INVITE_TAG_ID = -1;

export const ROLE_ADMIN = 1;
export const ROLE_SUPERVISOR = 2;

const codebooksApi = new PoplistControllerApi(API_CONFIG);

export type LocalCodebookItem = JsonPoplistItem;

export const fetchCodebooks = createAsyncThunk('codebooks/all', async () => {

    const list = await codebooksApi.getPoplistListUsingGET();

    if ('error' in list || !list.data) {
        return;
    }

    return await codebooksApi.getPoplistsUsingGET({
        names: Object.keys(list.data)
    });
});

const initialState = {};

export type CodebookState = {
    [name: string]: LocalCodebookItem[];
}
export type CodebookCodesType = keyof CodebookState;

export const codebooksSlice = createSlice({
    name: 'codebooks',
    initialState,
    reducers: {
        initStaticCodebooks: (state, action: PayloadAction<{ [name: string]: LocalCodebookItem[] }>) => {
            return {...state, ...action.payload};
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCodebooks.fulfilled, (state, action) => {
            return Object.keys(action.payload!.data || {}).reduce((acc, curr) => {
                acc[curr as CodebookCodesType] = action.payload!.data![curr];
                if (curr === 'tag') {
                    acc[curr as CodebookCodesType].sort((a, b) =>
                        a.value === b.value || !a.value || !b.value
                            ? 0
                            : (a.value > b.value ? 1 : -1))
                }
                return acc;
            }, state as CodebookState)
        });

    }
});

export const codebooksReducer = codebooksSlice.reducer;
export const {initStaticCodebooks} = codebooksSlice.actions;
