/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventDayInfo,
    JsonEventDayInfoFromJSON,
    JsonEventDayInfoFromJSONTyped,
    JsonEventDayInfoToJSON,
} from './JsonEventDayInfo';
import {
    JsonEventInfo,
    JsonEventInfoFromJSON,
    JsonEventInfoFromJSONTyped,
    JsonEventInfoToJSON,
} from './JsonEventInfo';
import {
    JsonEventPartyInfo,
    JsonEventPartyInfoFromJSON,
    JsonEventPartyInfoFromJSONTyped,
    JsonEventPartyInfoToJSON,
} from './JsonEventPartyInfo';
import {
    JsonFileInfo,
    JsonFileInfoFromJSON,
    JsonFileInfoFromJSONTyped,
    JsonFileInfoToJSON,
} from './JsonFileInfo';
import {
    JsonInviteInfo,
    JsonInviteInfoFromJSON,
    JsonInviteInfoFromJSONTyped,
    JsonInviteInfoToJSON,
} from './JsonInviteInfo';
import {
    JsonInviteReply,
    JsonInviteReplyFromJSON,
    JsonInviteReplyFromJSONTyped,
    JsonInviteReplyToJSON,
} from './JsonInviteReply';
import {
    JsonPartyInfo,
    JsonPartyInfoFromJSON,
    JsonPartyInfoFromJSONTyped,
    JsonPartyInfoToJSON,
} from './JsonPartyInfo';
import {
    JsonTariffInfo,
    JsonTariffInfoFromJSON,
    JsonTariffInfoFromJSONTyped,
    JsonTariffInfoToJSON,
} from './JsonTariffInfo';
import {
    JsonTemplate,
    JsonTemplateFromJSON,
    JsonTemplateFromJSONTyped,
    JsonTemplateToJSON,
} from './JsonTemplate';

/**
 * 
 * @export
 * @interface JsonRsvp
 */
export interface JsonRsvp {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof JsonRsvp
     */
    codebooks?: { [key: string]: string; };
    /**
     * 
     * @type {JsonFileInfo}
     * @memberof JsonRsvp
     */
    contract?: JsonFileInfo;
    /**
     * 
     * @type {JsonEventInfo}
     * @memberof JsonRsvp
     */
    event?: JsonEventInfo;
    /**
     * 
     * @type {Array<JsonEventDayInfo>}
     * @memberof JsonRsvp
     */
    eventDays?: Array<JsonEventDayInfo>;
    /**
     * 
     * @type {Array<JsonEventPartyInfo>}
     * @memberof JsonRsvp
     */
    eventParties?: Array<JsonEventPartyInfo>;
    /**
     * 
     * @type {JsonInviteInfo}
     * @memberof JsonRsvp
     */
    invite?: JsonInviteInfo;
    /**
     * 
     * @type {Array<JsonPartyInfo>}
     * @memberof JsonRsvp
     */
    prevParties?: Array<JsonPartyInfo>;
    /**
     * 
     * @type {JsonInviteReply}
     * @memberof JsonRsvp
     */
    reply?: JsonInviteReply;
    /**
     * 
     * @type {Array<JsonTariffInfo>}
     * @memberof JsonRsvp
     */
    tariffs?: Array<JsonTariffInfo>;
    /**
     * 
     * @type {JsonTemplate}
     * @memberof JsonRsvp
     */
    template?: JsonTemplate;
}

export function JsonRsvpFromJSON(json: any): JsonRsvp {
    return JsonRsvpFromJSONTyped(json, false);
}

export function JsonRsvpFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonRsvp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codebooks': !exists(json, 'codebooks') ? undefined : json['codebooks'],
        'contract': !exists(json, 'contract') ? undefined : JsonFileInfoFromJSON(json['contract']),
        'event': !exists(json, 'event') ? undefined : JsonEventInfoFromJSON(json['event']),
        'eventDays': !exists(json, 'eventDays') ? undefined : ((json['eventDays'] as Array<any>).map(JsonEventDayInfoFromJSON)),
        'eventParties': !exists(json, 'eventParties') ? undefined : ((json['eventParties'] as Array<any>).map(JsonEventPartyInfoFromJSON)),
        'invite': !exists(json, 'invite') ? undefined : JsonInviteInfoFromJSON(json['invite']),
        'prevParties': !exists(json, 'prevParties') ? undefined : ((json['prevParties'] as Array<any>).map(JsonPartyInfoFromJSON)),
        'reply': !exists(json, 'reply') ? undefined : JsonInviteReplyFromJSON(json['reply']),
        'tariffs': !exists(json, 'tariffs') ? undefined : ((json['tariffs'] as Array<any>).map(JsonTariffInfoFromJSON)),
        'template': !exists(json, 'template') ? undefined : JsonTemplateFromJSON(json['template']),
    };
}

export function JsonRsvpToJSON(value?: JsonRsvp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codebooks': value.codebooks,
        'contract': JsonFileInfoToJSON(value.contract),
        'event': JsonEventInfoToJSON(value.event),
        'eventDays': value.eventDays === undefined ? undefined : ((value.eventDays as Array<any>).map(JsonEventDayInfoToJSON)),
        'eventParties': value.eventParties === undefined ? undefined : ((value.eventParties as Array<any>).map(JsonEventPartyInfoToJSON)),
        'invite': JsonInviteInfoToJSON(value.invite),
        'prevParties': value.prevParties === undefined ? undefined : ((value.prevParties as Array<any>).map(JsonPartyInfoToJSON)),
        'reply': JsonInviteReplyToJSON(value.reply),
        'tariffs': value.tariffs === undefined ? undefined : ((value.tariffs as Array<any>).map(JsonTariffInfoToJSON)),
        'template': JsonTemplateToJSON(value.template),
    };
}

