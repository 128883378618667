import {createAsyncThunk,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {GetNotificationTemplatesUsingGETRequest, NotificationControllerApi, PreviewNotificationUsingPOSTRequest} from '../generated-api';

const notificationsApi = new NotificationControllerApi(API_CONFIG);

// export const fetchNotifications = createAsyncThunk('notifications/list', async (filter: GetNotificationListUsingGETRequest, thunkApi) => {
//     return {...await notificationsApi.getNotificationListUsingGET(filter), filter};
// });
//
// export const fetchNotificationsCount = createAsyncThunk('notifications/count', async (params: GetNotificationCountUsingGETRequest = {}) => {
//     return await notificationsApi.getNotificationCountUsingGET(params);
// });
//
// export const fetchNotificationInfo = createAsyncThunk('notification/info', async (notificationId: number): Promise<ApiResultOfJsonNotificationInfo> => {
//     return await notificationsApi.getNotificationInfoUsingGET({notificationId});
// });
//
// export const fetchNotification = createAsyncThunk('notification/fetch', async (notificationId: number): Promise<ApiResultOfJsonNotification> => {
//     return await notificationsApi.getNotificationUsingGET({notificationId});
// });
//
// export const saveNotification = createAsyncThunk('notification/save', async (notification: JsonNotification): Promise<ApiResultOfJsonNotification> => {
//     return await notificationsApi.saveNotificationUsingPOST({json: notification});
// });

export const fetchNotificationTemplates = createAsyncThunk('notification/templates', async (request: GetNotificationTemplatesUsingGETRequest) => {
    return await notificationsApi.getNotificationTemplatesUsingGET(request);
});

export const previewNotification = createAsyncThunk('notification/preview', async (request: PreviewNotificationUsingPOSTRequest) => {
    return await notificationsApi.previewNotificationUsingPOST(request);
});

// export const notificationsSlice = createSlice({
//     name: 'notifications',
//     initialState: (): ItemsState<JsonNotificationInfo> => createDefaultListState(),
//     reducers: {},
//     extraReducers: builder => {
//         createDefaultListReducer(builder, fetchNotifications, fetchNotificationsCount);
//     }
// });
//
// export const notificationsReducer = notificationsSlice.reducer;
