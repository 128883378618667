import './wdyr'; // <--- first import

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import {AppProviders} from './app/AppProviders';
import configureAppStore from './store/configureStore';
import './assets/styles/index.css';

export const store = configureAppStore();

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <AppProviders store={store}>
        <App/>
    </AppProviders>
);

