/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonTagInfo
 */
export interface JsonTagInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonTagInfo
     */
    allowedActions?: Array<JsonTagInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonTagInfo
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonTagInfo
     */
    partyCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonTagInfo
     */
    tagId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTagInfo
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonTagInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonTagInfoFromJSON(json: any): JsonTagInfo {
    return JsonTagInfoFromJSONTyped(json, false);
}

export function JsonTagInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonTagInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'partyCnt': !exists(json, 'partyCnt') ? undefined : json['partyCnt'],
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function JsonTagInfoToJSON(value?: JsonTagInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'color': value.color,
        'partyCnt': value.partyCnt,
        'tagId': value.tagId,
        'title': value.title,
    };
}

