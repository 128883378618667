import {Button, Grid, LinearProgress} from "@mui/material";
import {datetimeToGui, dateToGui} from "../../helpers/date";
import {getOption} from "../../model/form";
import {eventPartyStatusOptions, partySiwiStatusOptions} from "../../model/party";
import InfoBox, {InfoBoxProps} from "../layout/InfoBox";
import * as React from "react";
import {JsonAuthUserInfo, JsonPartyInfo, JsonPartyInfoPartyTypeEnum} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import CodebookValue from "../CodebookValue";
import PartyPhoto from "./PartyPhoto";
import PartyTags from "../PartyTags";
import {FIXED_INVITE_TAG_ID, ROLE_ADMIN} from "../../store/codebooks";
import {useAppSelector} from "../../store";
import {selectAuthInfo} from "../../store/selectors";
import {SiwiStatusDetail} from "../../pages/PartiesPage";

interface Props extends InfoBoxProps {
    party?: JsonPartyInfo,
    isLoading?: boolean,
    simple?: boolean,
    eventId?: number,
    photoTopMargin?: string
}

const PartyInfoBox = (props: Props) => {
    const {isLoading, party, actions, simple, eventId, photoTopMargin} = props;

    const t = useAppTranslation();
    const {user} = useAppSelector<JsonAuthUserInfo>(selectAuthInfo);
    const isAdmin = user?.roleId === ROLE_ADMIN;

    const isPerson = party?.partyType === JsonPartyInfoPartyTypeEnum.Fo;
    const isNoSiwi = party?.partyType === JsonPartyInfoPartyTypeEnum.T;

    const ed = party?.eventDetails?.find((ed) => ed.eventId === eventId);

    const infoBox = !!party && <Grid container columns={10}>
		<Grid item xs={7}>
			<table className={'info-box-table'}>
				<tbody>
                {isPerson
                    ? <>
                        <tr>
                            <th style={{width: '25%'}}>{t('Jméno')}</th>
                            <td style={{width: '75%'}} colSpan={3}>
                                {party.firstName}
                                {party.siwiFirstName && party.siwiFirstName !== party.firstName && <i title={'Siwidata jméno'}> ({party.siwiFirstName})</i>}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('Příjmení')}</th>
                            <td colSpan={3}>
                                {party.lastName}
                                {party.siwiLastName && party.siwiLastName !== party.lastName && <i title={'Siwidata příjmení'}> ({party.siwiLastName})</i>}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('Pohlaví')}</th>
                            <td style={{width: '30%'}}><CodebookValue value={party.sex} name={'sex'} formatValue={(v) => v[0]}/></td>
                            <th style={{width: '17%'}}>{t('Dat. nar.')}</th>
                            <td style={{width: '28%'}}>{dateToGui(party.birthDate)}</td>
                        </tr>
                        {!simple && !!party.partyId && <tr>
							<th>{t('ČSB ID')}</th>
							<td colSpan={3}>
								<pre>{party.cebId}</pre>
							</td>
						</tr>}
                    </>
                    : <>
                        <tr>
                            <th style={{width: '25%'}}>{t('Název')}</th>
                            <td style={{width: '75%'}} colSpan={3}>
                                {party.companyName}
                            </td>
                        </tr>
                    </>}
                {!!party.partyId && <tr>
					<th>{t('Štítky')}</th>
					<td colSpan={3}>
						<PartyTags tags={party.tags} partyId={party.partyId} quickEditTagId={isAdmin ? FIXED_INVITE_TAG_ID : undefined}/>
					</td>
				</tr>}
                {!simple
                    ? <>
                        {!isNoSiwi && <tr>
							<th>
                                {t('Siwidata ID')}
                                {party.siwiStatus && <i title={'Siwidata stav'}><br/>{getOption(party.siwiStatus, partySiwiStatusOptions).label}</i>}
							</th>
							<td colSpan={3}>
                                {/*<pre>{party.siwiId}</pre>*/}
								<SiwiStatusDetail item={party}/>
                                {party.siwiUpdatedAt && <i title={'Siwidata poslední změna'}>({datetimeToGui(party.siwiUpdatedAt)})</i>}
							</td>
						</tr>}
                        {/*<tr>*/}
                        {/*    <th>{t('Stav')}</th>*/}
                        {/*    <td colSpan={3}>*/}
                        {/*        {getOption(party.status, partyStatusOptions).label}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td colSpan={4} title={t('Poznámka')}>
                                {!!party.note ? <pre className={'note'}>{party.note}</pre> : null}
                            </td>
                        </tr>
                    </>
                    : <>
                        <tr>
                            <th>{t('E-mail')}</th>
                            <td colSpan={3}>
                                {!!party.email && <a href={'mailto:' + party.email}>{party.email}</a>}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('Telefon')}</th>
                            <td>
                                {party.phone}
                            </td>
                            <th>{t('Firma')}</th>
                            <td>
                                <CodebookValue value={party.companyId} name={'company'}/>
                            </td>
                        </tr>
                        {!!party.note && <tr>
                            <td colSpan={4} title={t('Poznámka')}>
                                <pre className={'note'}>{party.note}</pre>
                            </td>
                        </tr>}
                    </>}
                {!!ed &&
					<tr>
						<th>{t('V události')}</th>
						<td colSpan={3}>
                            {getOption(ed.status, eventPartyStatusOptions)?.label} (<CodebookValue value={ed.groupId} name={'group'}/>) {ed.formatCode} {ed.reason}
                        </td>
					</tr>
                }
                </tbody>
			</table>
		</Grid>
		<Grid item xs={3} sx={isNoSiwi ? {display: 'flex', flexDirection: 'column-reverse', padding: '0 0 7px 20px'} : {textAlign: 'right'}}>
            {!isNoSiwi && <PartyPhoto photoGuid={party.photoGuid} sx={{margin: simple ? '5px 0 0 0' : ((photoTopMargin || '0') + ' 0 10px 0')}} maxHeight={180} maxWidth={180}/>}
            {actions?.map((action, i) =>
                <Button key={i} variant={'contained'} size={'small'} color={action.color} onClick={() => action.action()}>
                    {action.icon ? <>{action.icon} <span>{t(action.title)}</span></> : t(action.title)}
                </Button>)}
		</Grid>
	</Grid>;

    return <InfoBox {...props} actions={undefined}>
        {isLoading && <LinearProgress sx={{margin: '-15px 0'}}/>}
        {infoBox}
    </InfoBox>
}

export default PartyInfoBox;
