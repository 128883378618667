/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonEventGroupSettings,
    JsonEventGroupSettingsFromJSON,
    JsonEventGroupSettingsFromJSONTyped,
    JsonEventGroupSettingsToJSON,
} from './JsonEventGroupSettings';

/**
 * 
 * @export
 * @interface JsonEventGroup
 */
export interface JsonEventGroup {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventGroup
     */
    allowedActions?: Array<JsonEventGroupAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventGroup
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroup
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroup
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroup
     */
    eventGroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroup
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroup
     */
    groupId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEventGroup
     */
    sendEmail?: boolean;
    /**
     * 
     * @type {JsonEventGroupSettings}
     * @memberof JsonEventGroup
     */
    settings?: JsonEventGroupSettings;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroup
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventGroup
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventGroupAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventGroupFromJSON(json: any): JsonEventGroup {
    return JsonEventGroupFromJSONTyped(json, false);
}

export function JsonEventGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventGroupId': !exists(json, 'eventGroupId') ? undefined : json['eventGroupId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'sendEmail': !exists(json, 'sendEmail') ? undefined : json['sendEmail'],
        'settings': !exists(json, 'settings') ? undefined : JsonEventGroupSettingsFromJSON(json['settings']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventGroupToJSON(value?: JsonEventGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventGroupId': value.eventGroupId,
        'eventId': value.eventId,
        'groupId': value.groupId,
        'sendEmail': value.sendEmail,
        'settings': JsonEventGroupSettingsToJSON(value.settings),
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

