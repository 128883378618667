import {OptionValue} from "./form";
import {JsonEventPartyDay, JsonPlaceFoodServicesEnum} from "../generated-api";

export const foodServiceOptions: OptionValue[] = [
    {value: JsonPlaceFoodServicesEnum.Br, label: 'S', tooltip: 'Snídaně'},
    {value: JsonPlaceFoodServicesEnum.Lu, label: 'O', tooltip: 'Oběd'},
    {value: JsonPlaceFoodServicesEnum.Sn, label: 'Sv', tooltip: 'Svačina'},
    {value: JsonPlaceFoodServicesEnum.Di, label: 'V', tooltip: 'Večeře'},
]

export const foodServiceFlags: {[key in JsonPlaceFoodServicesEnum]: number} = {
    [JsonPlaceFoodServicesEnum.Br]: 2,
    [JsonPlaceFoodServicesEnum.Lu]: 4,
    [JsonPlaceFoodServicesEnum.Sn]: 16,
    [JsonPlaceFoodServicesEnum.Di]: 8,
}

export const foodServiceProps: {[key in JsonPlaceFoodServicesEnum]: keyof JsonEventPartyDay & 'foodBrId' | 'foodLuId' | 'foodDiId' | 'foodSnId'} = {
    [JsonPlaceFoodServicesEnum.Br]: 'foodBrId',
    [JsonPlaceFoodServicesEnum.Lu]: 'foodLuId',
    [JsonPlaceFoodServicesEnum.Sn]: 'foodSnId',
    [JsonPlaceFoodServicesEnum.Di]: 'foodDiId',
}
