import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonEventFloor, ApiResultOfJsonEventFloorInfo, EventFloorControllerApi, GetEventFloorCountUsingGETRequest, GetEventFloorListUsingGETRequest, JsonEventFloor, JsonEventFloorInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const eventFloorsApi = new EventFloorControllerApi(API_CONFIG);

export const fetchEventFloors = createAsyncThunk('eventFloors/list', async (filter: GetEventFloorListUsingGETRequest, thunkApi) => {
    return {...await eventFloorsApi.getEventFloorListUsingGET(filter), filter};
});

export const fetchEventFloorsCount = createAsyncThunk('eventFloors/count', async (params: GetEventFloorCountUsingGETRequest = {}) => {
    return await eventFloorsApi.getEventFloorCountUsingGET(params);
});

export const fetchEventFloorInfo = createAsyncThunk('eventFloor/info', async (eventFloorId: number): Promise<ApiResultOfJsonEventFloorInfo> => {
    return await eventFloorsApi.getEventFloorInfoUsingGET({eventFloorId});
});

export const fetchEventFloor = createAsyncThunk('eventFloor/fetch', async (eventFloorId: number): Promise<ApiResultOfJsonEventFloor> => {
    return await eventFloorsApi.getEventFloorUsingGET({eventFloorId});
});

export const saveEventFloor = createAsyncThunk('eventFloor/save', async (eventFloor: JsonEventFloor) => {
    return await eventFloorsApi.saveEventFloorUsingPOST({json: eventFloor});
});

export const eventFloorsSlice = createSlice({
    name: 'eventFloors',
    initialState: (): ItemsState<JsonEventFloorInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchEventFloors, fetchEventFloorsCount);
    }
});

export const eventFloorsReducer = eventFloorsSlice.reducer;
