import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps, OptionValue} from '../../model/form';
import {useAppTranslation} from "../../services/i18n";

interface Props extends FormFieldProps {
    options: OptionValue[],
    title?: string,
    fullWidth?: boolean
}

export const RadioGroupField = ({name, label, options, onChange, title, fullWidth}: Props) => {
    const t = useAppTranslation();
    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();
    const showError = !!meta.error && (meta.touched || submitCount > 0);

    return <FormControl error={showError} title={title} fullWidth={fullWidth}>
        {label && <FormLabel>{typeof label === 'string' ? t(label) : undefined}</FormLabel>}
        <RadioGroup row name={name}>
            {options.map((option, i) => {
                const isSelected = field.value === option.value;

                return <FormControlLabel key={i}
                    value={option.value}
                    title={option.tooltip}
                    sx={{
                        color: (theme) => isSelected ? (option.color ? theme.palette[option.color].main : undefined) : undefined,
                        '&:hover': {color: (theme) => option.color ? theme.palette[option.color].main : theme.palette['secondary'].main}
                    }}
                    onChange={() => {
                        helpers.setValue(option.value, true);
                        if (onChange) {
                            onChange(option.value);
                        }
                    }}
                    control={<Radio size="small" color={option.color || 'secondary'} checked={isSelected}/>}
                    label={option.label}/>
            })}
        </RadioGroup>
        {showError && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>;

}
