import {Middleware, PayloadAction, SerializedError,} from '@reduxjs/toolkit';
import {t} from './i18n';
import {getApiErrorData} from '../helpers/api';
import {addMessage, removeAllApiMessages} from "../store/localApp";
import {fetchAuthLogout, fetchAuthUser} from "../store/auth";

export const apiErrorMiddlewareFactory: () => Middleware =
    () =>
        store =>
            next =>
                (action: PayloadAction<never, any, any, SerializedError>) => {
                    if (
                        action.error &&
                        !action.meta.rejectedWithValue &&
                        action.error.code !== 'abort'
                    ) {
                        const {message, code} = getApiErrorData(action, t);
                        if (code === 'LOGIN_NEEDED') {
                            store.dispatch(fetchAuthUser() as any).then((res: any) => {
                                if (!!res.payload.data.user) {
                                    console.log("LOGOUT???", res, fetchAuthLogout)
                                    // store.dispatch(fetchAuthLogout() as any)
                                }
                            })
                        } else {
                            console.log('apiErrorMiddleware', action, message);
                            store.dispatch(addMessage({
                                    severity: 'error',
                                    code: code,
                                    action: action.type.replace('/rejected', ''),
                                    title: message,
                                }
                            ));
                        }
                    } else if (action.type?.indexOf('/pending') && action.type.indexOf('localApp') < 0) {
                        store.dispatch(removeAllApiMessages(action.type.replace('/pending', '')))
                    }

                    return next(action);
                };
