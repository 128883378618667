import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonGroup, ApiResultOfJsonGroupInfo, GetGroupCountUsingGETRequest, GetGroupListUsingGETRequest, GroupControllerApi, JsonGroup, JsonGroupInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const groupsApi = new GroupControllerApi(API_CONFIG);

export const fetchGroups = createAsyncThunk('groups/list', async (filter: GetGroupListUsingGETRequest, thunkApi) => {
    return {...await groupsApi.getGroupListUsingGET(filter), filter};
});

export const fetchGroupsCount = createAsyncThunk('groups/count', async (params: GetGroupCountUsingGETRequest = {}) => {
    return await groupsApi.getGroupCountUsingGET(params);
});

export const fetchGroupInfo = createAsyncThunk('group/info', async (groupId: number): Promise<ApiResultOfJsonGroupInfo> => {
    return await groupsApi.getGroupInfoUsingGET({groupId});
});

export const fetchGroupsOnline = createAsyncThunk('groups/online', async (filter: GetGroupListUsingGETRequest, thunkApi) => {
    return {...await groupsApi.getGroupListUsingGET(filter), filter};
});

export const fetchGroup = createAsyncThunk('group/fetch', async (groupId: number): Promise<ApiResultOfJsonGroup> => {
    return await groupsApi.getGroupUsingGET({groupId});
});

export const saveGroup = createAsyncThunk('group/save', async (group: JsonGroup): Promise<ApiResultOfJsonGroup> => {
    return await groupsApi.saveGroupUsingPOST({json: group});
});

export const groupsSlice = createSlice({
    name: 'groups',
    initialState: (): ItemsState<JsonGroupInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchGroups, fetchGroupsCount);
    }
});

export const groupsReducer = groupsSlice.reducer;
