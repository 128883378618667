/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonFoodDataLog
 */
export interface JsonFoodDataLog {
    /**
     * 
     * @type {string}
     * @memberof JsonFoodDataLog
     */
    serviceAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFoodDataLog
     */
    serviceType?: JsonFoodDataLogServiceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonFoodDataLogServiceTypeEnum {
    Br = 'BR',
    Lu = 'LU',
    Di = 'DI',
    Sn = 'SN'
}

export function JsonFoodDataLogFromJSON(json: any): JsonFoodDataLog {
    return JsonFoodDataLogFromJSONTyped(json, false);
}

export function JsonFoodDataLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFoodDataLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceAt': !exists(json, 'serviceAt') ? undefined : json['serviceAt'],
        'serviceType': !exists(json, 'serviceType') ? undefined : json['serviceType'],
    };
}

export function JsonFoodDataLogToJSON(value?: JsonFoodDataLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceAt': value.serviceAt,
        'serviceType': value.serviceType,
    };
}

