/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonArticle,
    JsonArticleFromJSON,
    JsonArticleFromJSONTyped,
    JsonArticleToJSON,
} from './JsonArticle';
import {
    JsonArticleType,
    JsonArticleTypeFromJSON,
    JsonArticleTypeFromJSONTyped,
    JsonArticleTypeToJSON,
} from './JsonArticleType';
import {
    JsonFile,
    JsonFileFromJSON,
    JsonFileFromJSONTyped,
    JsonFileToJSON,
} from './JsonFile';

/**
 * 
 * @export
 * @interface JsonInviteData
 */
export interface JsonInviteData {
    /**
     * 
     * @type {Array<JsonArticleType>}
     * @memberof JsonInviteData
     */
    articleTypes?: Array<JsonArticleType>;
    /**
     * 
     * @type {Array<JsonArticle>}
     * @memberof JsonInviteData
     */
    articles?: Array<JsonArticle>;
    /**
     * 
     * @type {Array<JsonFile>}
     * @memberof JsonInviteData
     */
    attachments?: Array<JsonFile>;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteData
     */
    bodyBottom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteData
     */
    bodyTop?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof JsonInviteData
     */
    eventDays?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteData
     */
    extraLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteData
     */
    isPark?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonInviteData
     */
    isUser?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteData
     */
    templateId?: number;
}

export function JsonInviteDataFromJSON(json: any): JsonInviteData {
    return JsonInviteDataFromJSONTyped(json, false);
}

export function JsonInviteDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonInviteData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleTypes': !exists(json, 'articleTypes') ? undefined : ((json['articleTypes'] as Array<any>).map(JsonArticleTypeFromJSON)),
        'articles': !exists(json, 'articles') ? undefined : ((json['articles'] as Array<any>).map(JsonArticleFromJSON)),
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(JsonFileFromJSON)),
        'bodyBottom': !exists(json, 'bodyBottom') ? undefined : json['bodyBottom'],
        'bodyTop': !exists(json, 'bodyTop') ? undefined : json['bodyTop'],
        'eventDays': !exists(json, 'eventDays') ? undefined : json['eventDays'],
        'extraLimit': !exists(json, 'extraLimit') ? undefined : json['extraLimit'],
        'isPark': !exists(json, 'isPark') ? undefined : json['isPark'],
        'isUser': !exists(json, 'isUser') ? undefined : json['isUser'],
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
    };
}

export function JsonInviteDataToJSON(value?: JsonInviteData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleTypes': value.articleTypes === undefined ? undefined : ((value.articleTypes as Array<any>).map(JsonArticleTypeToJSON)),
        'articles': value.articles === undefined ? undefined : ((value.articles as Array<any>).map(JsonArticleToJSON)),
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(JsonFileToJSON)),
        'bodyBottom': value.bodyBottom,
        'bodyTop': value.bodyTop,
        'eventDays': value.eventDays,
        'extraLimit': value.extraLimit,
        'isPark': value.isPark,
        'isUser': value.isUser,
        'templateId': value.templateId,
    };
}

