/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonInviteReplyExtraInfo
 */
export interface JsonInviteReplyExtraInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonInviteReplyExtraInfo
     */
    allowedActions?: Array<JsonInviteReplyExtraInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    approvedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    approvedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    approvedStatus?: JsonInviteReplyExtraInfoApprovedStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    eventDays?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    extraId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    photoId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    replyExtraId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    replyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    sex?: JsonInviteReplyExtraInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    status?: JsonInviteReplyExtraInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonInviteReplyExtraInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonInviteReplyExtraInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyExtraInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyExtraInfoApprovedStatusEnum {
    Rejected = 'REJECTED',
    Approved = 'APPROVED'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyExtraInfoSexEnum {
    M = 'M',
    F = 'F'
}/**
* @export
* @enum {string}
*/
export enum JsonInviteReplyExtraInfoStatusEnum {
    Rejected = 'REJECTED',
    Pending = 'PENDING',
    Linked = 'LINKED',
    Created = 'CREATED'
}

export function JsonInviteReplyExtraInfoFromJSON(json: any): JsonInviteReplyExtraInfo {
    return JsonInviteReplyExtraInfoFromJSONTyped(json, false);
}

export function JsonInviteReplyExtraInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonInviteReplyExtraInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'approvedAt': !exists(json, 'approvedAt') ? undefined : json['approvedAt'],
        'approvedBy': !exists(json, 'approvedBy') ? undefined : json['approvedBy'],
        'approvedStatus': !exists(json, 'approvedStatus') ? undefined : json['approvedStatus'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventDays': !exists(json, 'eventDays') ? undefined : json['eventDays'],
        'extraId': !exists(json, 'extraId') ? undefined : json['extraId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'replyExtraId': !exists(json, 'replyExtraId') ? undefined : json['replyExtraId'],
        'replyId': !exists(json, 'replyId') ? undefined : json['replyId'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonInviteReplyExtraInfoToJSON(value?: JsonInviteReplyExtraInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'approvedAt': value.approvedAt,
        'approvedBy': value.approvedBy,
        'approvedStatus': value.approvedStatus,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventDays': value.eventDays,
        'extraId': value.extraId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'partyId': value.partyId,
        'photoGuid': value.photoGuid,
        'photoId': value.photoId,
        'replyExtraId': value.replyExtraId,
        'replyId': value.replyId,
        'sex': value.sex,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

