import {AlertProps} from '@mui/material';
import {createSlice, PayloadAction,} from '@reduxjs/toolkit'
import {RunningApiInfo} from '../services/appFetch';

export interface FlashMessage {
    severity: AlertProps['severity'];
    code: string;
    action: string;
    title: string;
}

export interface FlashMessageWithId extends FlashMessage {
    id: number;
}

const initialState = {
    messages: [] as FlashMessageWithId[],
    lastMessageId: 0,
    showLoader: false,
    runningApi: [] as RunningApiInfo[],
};

export type LocalAppState = typeof initialState;

export const localAppSlice = createSlice({
    name: 'localApp',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<FlashMessage>) => {
            state.lastMessageId++;
            // state.messages = state.messages.filter((m) => m.title !== action.payload.title);
            state.messages = state.messages.filter((m) => m.action !== action.payload.action);
            state.messages.push({id: state.lastMessageId, ...action.payload});
        },
        removeAllApiMessages: (state, action: PayloadAction<string>) => {
            state.messages = state.messages.filter((m) => m.action !== action.payload);
        },
        removeMessage: (state, action: PayloadAction<FlashMessageWithId>) => {
            state.messages = state.messages.filter((m) => m.id !== action.payload.id);
        },
        setAppApiRunning: (
            state,
            action: PayloadAction<{ info: RunningApiInfo; active: boolean }>,
        ) => {
            state.runningApi = action.payload.active
                ? state.runningApi.concat(action.payload.info)
                : state.runningApi.filter(
                    info => info.code !== action.payload.info.code,
                );
        },
        clearApiRunning: state => {
            state.runningApi = [];
        },
        showLoader: (state, action: PayloadAction<boolean>) => {
            state.showLoader = action.payload;
        },
    },
    extraReducers: builder => {
    }
});

export const localAppReducer = localAppSlice.reducer;
export const {addMessage, removeMessage, setAppApiRunning, removeAllApiMessages, showLoader} = localAppSlice.actions;
