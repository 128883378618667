/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonFindPartyRequest
 */
export interface JsonFindPartyRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonFindPartyRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFindPartyRequest
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFindPartyRequest
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFindPartyRequest
     */
    photoGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFindPartyRequest
     */
    queryId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFindPartyRequest
     */
    sex?: JsonFindPartyRequestSexEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonFindPartyRequest
     */
    skipPartyId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonFindPartyRequestSexEnum {
    M = 'M',
    F = 'F'
}

export function JsonFindPartyRequestFromJSON(json: any): JsonFindPartyRequest {
    return JsonFindPartyRequestFromJSONTyped(json, false);
}

export function JsonFindPartyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFindPartyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'queryId': !exists(json, 'queryId') ? undefined : json['queryId'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'skipPartyId': !exists(json, 'skipPartyId') ? undefined : json['skipPartyId'],
    };
}

export function JsonFindPartyRequestToJSON(value?: JsonFindPartyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'partyId': value.partyId,
        'photoGuid': value.photoGuid,
        'queryId': value.queryId,
        'sex': value.sex,
        'skipPartyId': value.skipPartyId,
    };
}

