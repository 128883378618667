/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonTag
 */
export interface JsonTag {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonTag
     */
    allowedActions?: Array<JsonTagAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonTag
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonTag
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonTag
     */
    tagId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonTag
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonTagAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonTagFromJSON(json: any): JsonTag {
    return JsonTagFromJSONTyped(json, false);
}

export function JsonTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'color': !exists(json, 'color') ? undefined : json['color'],
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function JsonTagToJSON(value?: JsonTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'color': value.color,
        'tagId': value.tagId,
        'title': value.title,
    };
}

