/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonArticle
 */
export interface JsonArticle {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonArticle
     */
    allowedActions?: Array<JsonArticleAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonArticle
     */
    articleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticle
     */
    articleTypeId?: number;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonArticle
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonArticle
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticle
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticle
     */
    purchasedCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonArticle
     */
    sex?: JsonArticleSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonArticle
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonArticle
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticle
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonArticleAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonArticleSexEnum {
    M = 'M',
    F = 'F',
    U = 'U'
}

export function JsonArticleFromJSON(json: any): JsonArticle {
    return JsonArticleFromJSONTyped(json, false);
}

export function JsonArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'articleTypeId': !exists(json, 'articleTypeId') ? undefined : json['articleTypeId'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'purchasedCnt': !exists(json, 'purchasedCnt') ? undefined : json['purchasedCnt'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonArticleToJSON(value?: JsonArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'articleId': value.articleId,
        'articleTypeId': value.articleTypeId,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'purchasedCnt': value.purchasedCnt,
        'sex': value.sex,
        'size': value.size,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

