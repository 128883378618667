/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventFloorDayInfo,
    JsonEventFloorDayInfoFromJSON,
    JsonEventFloorDayInfoFromJSONTyped,
    JsonEventFloorDayInfoToJSON,
} from './JsonEventFloorDayInfo';

/**
 * 
 * @export
 * @interface ApiResultOfJsonEventFloorDayInfo
 */
export interface ApiResultOfJsonEventFloorDayInfo {
    /**
     * 
     * @type {JsonEventFloorDayInfo}
     * @memberof ApiResultOfJsonEventFloorDayInfo
     */
    data?: JsonEventFloorDayInfo;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonEventFloorDayInfo
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResultOfJsonEventFloorDayInfo
     */
    messageValues?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonEventFloorDayInfo
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonEventFloorDayInfo
     */
    result?: ApiResultOfJsonEventFloorDayInfoResultEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApiResultOfJsonEventFloorDayInfoResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

export function ApiResultOfJsonEventFloorDayInfoFromJSON(json: any): ApiResultOfJsonEventFloorDayInfo {
    return ApiResultOfJsonEventFloorDayInfoFromJSONTyped(json, false);
}

export function ApiResultOfJsonEventFloorDayInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResultOfJsonEventFloorDayInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : JsonEventFloorDayInfoFromJSON(json['data']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'messageValues': !exists(json, 'messageValues') ? undefined : json['messageValues'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function ApiResultOfJsonEventFloorDayInfoToJSON(value?: ApiResultOfJsonEventFloorDayInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': JsonEventFloorDayInfoToJSON(value.data),
        'message': value.message,
        'messageValues': value.messageValues,
        'requestId': value.requestId,
        'result': value.result,
    };
}

