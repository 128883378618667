/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventTicketInfo,
    JsonEventTicketInfoFromJSON,
    JsonEventTicketInfoFromJSONTyped,
    JsonEventTicketInfoToJSON,
} from './JsonEventTicketInfo';

/**
 * 
 * @export
 * @interface ApiResultOfListOfJsonEventTicketInfo
 */
export interface ApiResultOfListOfJsonEventTicketInfo {
    /**
     * 
     * @type {Array<JsonEventTicketInfo>}
     * @memberof ApiResultOfListOfJsonEventTicketInfo
     */
    data?: Array<JsonEventTicketInfo>;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfListOfJsonEventTicketInfo
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResultOfListOfJsonEventTicketInfo
     */
    messageValues?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfListOfJsonEventTicketInfo
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfListOfJsonEventTicketInfo
     */
    result?: ApiResultOfListOfJsonEventTicketInfoResultEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApiResultOfListOfJsonEventTicketInfoResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

export function ApiResultOfListOfJsonEventTicketInfoFromJSON(json: any): ApiResultOfListOfJsonEventTicketInfo {
    return ApiResultOfListOfJsonEventTicketInfoFromJSONTyped(json, false);
}

export function ApiResultOfListOfJsonEventTicketInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResultOfListOfJsonEventTicketInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(JsonEventTicketInfoFromJSON)),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'messageValues': !exists(json, 'messageValues') ? undefined : json['messageValues'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function ApiResultOfListOfJsonEventTicketInfoToJSON(value?: ApiResultOfListOfJsonEventTicketInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(JsonEventTicketInfoToJSON)),
        'message': value.message,
        'messageValues': value.messageValues,
        'requestId': value.requestId,
        'result': value.result,
    };
}

