/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonParty,
    JsonPartyFromJSON,
    JsonPartyFromJSONTyped,
    JsonPartyToJSON,
} from './JsonParty';

/**
 * 
 * @export
 * @interface JsonPartyMassActionRequest
 */
export interface JsonPartyMassActionRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonPartyMassActionRequest
     */
    action?: JsonPartyMassActionRequestActionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonPartyMassActionRequest
     */
    ids?: Array<string>;
    /**
     * 
     * @type {Array<JsonParty>}
     * @memberof JsonPartyMassActionRequest
     */
    items?: Array<JsonParty>;
}

/**
* @export
* @enum {string}
*/
export enum JsonPartyMassActionRequestActionEnum {
    Import = 'IMPORT',
    UpdateTags = 'UPDATE_TAGS'
}

export function JsonPartyMassActionRequestFromJSON(json: any): JsonPartyMassActionRequest {
    return JsonPartyMassActionRequestFromJSONTyped(json, false);
}

export function JsonPartyMassActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPartyMassActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JsonPartyFromJSON)),
    };
}

export function JsonPartyMassActionRequestToJSON(value?: JsonPartyMassActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'ids': value.ids,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JsonPartyToJSON)),
    };
}

