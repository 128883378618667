/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonArticle,
    ApiResultOfJsonArticleFromJSON,
    ApiResultOfJsonArticleToJSON,
    ApiResultOfJsonArticleInfo,
    ApiResultOfJsonArticleInfoFromJSON,
    ApiResultOfJsonArticleInfoToJSON,
    ApiResultOfListOfJsonArticle,
    ApiResultOfListOfJsonArticleFromJSON,
    ApiResultOfListOfJsonArticleToJSON,
    ApiResultOfListOfJsonArticleInfo,
    ApiResultOfListOfJsonArticleInfoFromJSON,
    ApiResultOfListOfJsonArticleInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonArticle,
    JsonArticleFromJSON,
    JsonArticleToJSON,
    JsonArticleMassActionRequest,
    JsonArticleMassActionRequestFromJSON,
    JsonArticleMassActionRequestToJSON,
} from '../models';

export interface GetArticleCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetArticleInfoUsingGETRequest {
    articleId: number;
}

export interface GetArticleListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetArticleUsingGETRequest {
    articleId: number;
}

export interface MassArticleActionUsingPOSTRequest {
    json: JsonArticleMassActionRequest;
    testOnly?: boolean;
}

export interface SaveArticleUsingPOSTRequest {
    json: JsonArticle;
    testOnly?: boolean;
}

/**
 * 
 */
export class ArticleControllerApi extends runtime.BaseAPI {

    /**
     * getArticleCount
     */
    async getArticleCountUsingGETRaw(requestParameters: GetArticleCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/article/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getArticleCount
     */
    async getArticleCountUsingGET(requestParameters: GetArticleCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getArticleCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleInfo
     */
    async getArticleInfoUsingGETRaw(requestParameters: GetArticleInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonArticleInfo>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticleInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/article/info/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonArticleInfoFromJSON(jsonValue));
    }

    /**
     * getArticleInfo
     */
    async getArticleInfoUsingGET(requestParameters: GetArticleInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonArticleInfo> {
        const response = await this.getArticleInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleList
     */
    async getArticleListUsingGETRaw(requestParameters: GetArticleListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonArticleInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/article/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonArticleInfoFromJSON(jsonValue));
    }

    /**
     * getArticleList
     */
    async getArticleListUsingGET(requestParameters: GetArticleListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonArticleInfo> {
        const response = await this.getArticleListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticle
     */
    async getArticleUsingGETRaw(requestParameters: GetArticleUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonArticle>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling getArticleUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/article/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters.articleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonArticleFromJSON(jsonValue));
    }

    /**
     * getArticle
     */
    async getArticleUsingGET(requestParameters: GetArticleUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonArticle> {
        const response = await this.getArticleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * massArticleAction
     */
    async massArticleActionUsingPOSTRaw(requestParameters: MassArticleActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonArticle>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling massArticleActionUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/article/mass`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonArticleMassActionRequestToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonArticleFromJSON(jsonValue));
    }

    /**
     * massArticleAction
     */
    async massArticleActionUsingPOST(requestParameters: MassArticleActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonArticle> {
        const response = await this.massArticleActionUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveArticle
     */
    async saveArticleUsingPOSTRaw(requestParameters: SaveArticleUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonArticle>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveArticleUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/article`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonArticleToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonArticleFromJSON(jsonValue));
    }

    /**
     * saveArticle
     */
    async saveArticleUsingPOST(requestParameters: SaveArticleUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonArticle> {
        const response = await this.saveArticleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
