/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonUserData,
    JsonUserDataFromJSON,
    JsonUserDataFromJSONTyped,
    JsonUserDataToJSON,
} from './JsonUserData';

/**
 * 
 * @export
 * @interface JsonUserInfo
 */
export interface JsonUserInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonUserInfo
     */
    allowedActions?: Array<JsonUserInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    cebId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonUserInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    loggedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonUserInfo
     */
    partyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    photoGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonUserInfo
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    status?: JsonUserInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonUserInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {JsonUserData}
     * @memberof JsonUserInfo
     */
    userData?: JsonUserData;
    /**
     * 
     * @type {number}
     * @memberof JsonUserInfo
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonUserInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonUserInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}

export function JsonUserInfoFromJSON(json: any): JsonUserInfo {
    return JsonUserInfoFromJSONTyped(json, false);
}

export function JsonUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'cebId': !exists(json, 'cebId') ? undefined : json['cebId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'loggedAt': !exists(json, 'loggedAt') ? undefined : json['loggedAt'],
        'partyId': !exists(json, 'partyId') ? undefined : json['partyId'],
        'photoGuid': !exists(json, 'photoGuid') ? undefined : json['photoGuid'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userData': !exists(json, 'userData') ? undefined : JsonUserDataFromJSON(json['userData']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonUserInfoToJSON(value?: JsonUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'cebId': value.cebId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'loggedAt': value.loggedAt,
        'partyId': value.partyId,
        'photoGuid': value.photoGuid,
        'roleId': value.roleId,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userData': JsonUserDataToJSON(value.userData),
        'userId': value.userId,
    };
}

