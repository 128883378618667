/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonFile,
    JsonFileFromJSON,
    JsonFileFromJSONTyped,
    JsonFileToJSON,
} from './JsonFile';

/**
 * 
 * @export
 * @interface JsonEventPartyTravel
 */
export interface JsonEventPartyTravel {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventPartyTravel
     */
    allowedActions?: Array<JsonEventPartyTravelAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    beginLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    beginTime?: string;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventPartyTravel
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravel
     */
    compAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravel
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    endLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravel
     */
    eventPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravel
     */
    eventPartyTravelId?: number;
    /**
     * 
     * @type {Array<JsonFile>}
     * @memberof JsonEventPartyTravel
     */
    files?: Array<JsonFile>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravel
     */
    kmCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    plateNum?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    status?: JsonEventPartyTravelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravel
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravel
     */
    vehicle?: JsonEventPartyTravelVehicleEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyTravelAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyTravelStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    Paid = 'PAID'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyTravelVehicleEnum {
    Car = 'CAR',
    Bus = 'BUS',
    Train = 'TRAIN'
}

export function JsonEventPartyTravelFromJSON(json: any): JsonEventPartyTravel {
    return JsonEventPartyTravelFromJSONTyped(json, false);
}

export function JsonEventPartyTravelFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyTravel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'beginDate': !exists(json, 'beginDate') ? undefined : json['beginDate'],
        'beginLocation': !exists(json, 'beginLocation') ? undefined : json['beginLocation'],
        'beginTime': !exists(json, 'beginTime') ? undefined : json['beginTime'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'compAmount': !exists(json, 'compAmount') ? undefined : json['compAmount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'endLocation': !exists(json, 'endLocation') ? undefined : json['endLocation'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'eventPartyTravelId': !exists(json, 'eventPartyTravelId') ? undefined : json['eventPartyTravelId'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(JsonFileFromJSON)),
        'kmCnt': !exists(json, 'kmCnt') ? undefined : json['kmCnt'],
        'plateNum': !exists(json, 'plateNum') ? undefined : json['plateNum'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
    };
}

export function JsonEventPartyTravelToJSON(value?: JsonEventPartyTravel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'beginDate': value.beginDate,
        'beginLocation': value.beginLocation,
        'beginTime': value.beginTime,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'compAmount': value.compAmount,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'endDate': value.endDate,
        'endLocation': value.endLocation,
        'endTime': value.endTime,
        'eventPartyId': value.eventPartyId,
        'eventPartyTravelId': value.eventPartyTravelId,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(JsonFileToJSON)),
        'kmCnt': value.kmCnt,
        'plateNum': value.plateNum,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'vehicle': value.vehicle,
    };
}

