/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFormatAreaInfo,
    JsonFormatAreaInfoFromJSON,
    JsonFormatAreaInfoFromJSONTyped,
    JsonFormatAreaInfoToJSON,
} from './JsonFormatAreaInfo';

/**
 * 
 * @export
 * @interface JsonFormatInfo
 */
export interface JsonFormatInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonFormatInfo
     */
    allowedActions?: Array<JsonFormatInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatInfo
     */
    contingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatInfo
     */
    eventId?: number;
    /**
     * 
     * @type {Array<JsonFormatAreaInfo>}
     * @memberof JsonFormatInfo
     */
    formatAreas?: Array<JsonFormatAreaInfo>;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatInfo
     */
    formatCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatInfo
     */
    formatId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonFormatInfo
     */
    formatReasons?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonFormatInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonFormatInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonFormatInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonFormatInfoFromJSON(json: any): JsonFormatInfo {
    return JsonFormatInfoFromJSONTyped(json, false);
}

export function JsonFormatInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFormatInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'contingentOwner': !exists(json, 'contingentOwner') ? undefined : json['contingentOwner'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'formatAreas': !exists(json, 'formatAreas') ? undefined : ((json['formatAreas'] as Array<any>).map(JsonFormatAreaInfoFromJSON)),
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'formatId': !exists(json, 'formatId') ? undefined : json['formatId'],
        'formatReasons': !exists(json, 'formatReasons') ? undefined : json['formatReasons'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonFormatInfoToJSON(value?: JsonFormatInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'contingentOwner': value.contingentOwner,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'formatAreas': value.formatAreas === undefined ? undefined : ((value.formatAreas as Array<any>).map(JsonFormatAreaInfoToJSON)),
        'formatCode': value.formatCode,
        'formatId': value.formatId,
        'formatReasons': value.formatReasons,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

