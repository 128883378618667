import {FormProps} from "../../model/form";
import {JsonEventDayInfo, JsonInviteReplyExtra} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {useCallback} from "react";
import {FormikProps} from "formik";
import {Button, Card, CardContent, Grid} from "@mui/material";
import {PlusOneRounded} from "@mui/icons-material";
import FormModal from "../form/FormModal";
import {dateToGuiAs} from "../../helpers/date";
import FormContainer from "../form/FormContainer";
import RsvpInvitePartyBox from "./RsvpInvitePartyBox";

interface RsvpInviteExtraSelectPartyFormProps extends FormProps<JsonInviteReplyExtra> {
    extras: JsonInviteReplyExtra[],
    ed: JsonEventDayInfo
}

const RsvpInviteExtraSelectPartyForm = (props: RsvpInviteExtraSelectPartyFormProps) => {
    const {item, ed, onSave, onCancel, extras} = props;

    const t = useAppTranslation();

    const validate = useCallback((values: JsonInviteReplyExtra) => {
        return undefined;
    }, [])

    const children = useCallback(({values}: FormikProps<JsonInviteReplyExtra>) => {

        const extrasOtherDay = [];
        const extrasPrevEvent = [];
        for (let e of extras) {
            if (e.extraId && (!e.partyId || !!e.eventDayNos?.length)) {
                extrasOtherDay.push(e);
            } else {
                extrasPrevEvent.push(e);
            }
        }

        return <Grid item xs={12}>
            <Grid container spacing={2} sx={{marginTop: '-10px'}}>
                <Grid item xs={12}>
                    <p>{t('Zvolte prosím existující osobu nebo přidejte novou:')}</p>
                </Grid>
                {extrasOtherDay.map((extra, i) => <Grid key={i} item xs={6}>
                    <RsvpInvitePartyBox key={i} extra={extra} onSelect={(extra) => {
                        if (onSave) {
                            onSave(extra);
                        }
                    }}/>
                </Grid>)}
                <Grid item xs={6}>
                    <Card className={'rsvp-extra-match-box'}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Button variant={'contained'} size={'small'}
                                        color={'secondary'}
                                        onClick={() => {
                                            if (onSave) {
                                                onSave({});
                                            }
                                        }}>
                                        <span>{t('Přidat novou osobu')}</span>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sx={{display: 'flex', flexFlow: 'column', alignItems: 'flex-end'}}>
                                    <PlusOneRounded sx={{fontSize: '45px !important', color: 'silver'}}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {!!extrasPrevEvent.length && <><Grid item xs={12}>
					<p>{t('Doprovodné osoby z předchozích událostí:')}</p>
				</Grid>
                    {extrasPrevEvent.map((extra, i) => <Grid key={i} item xs={6}>
                        <RsvpInvitePartyBox key={i} extra={extra} onSelect={(extra) => {
                            if (onSave) {
                                onSave(extra);
                            }
                        }}/>
                    </Grid>)}
					<Grid item xs={12}></Grid>
				</>}
            </Grid>
        </Grid>;
    }, [onSave, extras, t]);

    return <FormModal title={t('Přidat doprovod na {{title}}', {title: dateToGuiAs(ed.dayDate, 'eeee P')})} onCancel={onCancel} maxWidth={'sm'}>
        <FormContainer
            actions={() => {
                return <Grid item>
                    <Button variant="text" onClick={props.onCancel}>{t('Storno')}</Button>
                </Grid>;
            }}
            item={item}
            onSave={onSave} onCancel={onCancel}
            validate={validate} children={children}/>
    </FormModal>;
}

export default RsvpInviteExtraSelectPartyForm;
