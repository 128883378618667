import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonEventFloorDay, ApiResultOfJsonEventFloorDayInfo, EventFloorDayControllerApi, GetEventFloorDayCountUsingGETRequest, GetEventFloorDayListUsingGETRequest, JsonEventFloorDay, JsonEventFloorDayInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const eventFloorDaysApi = new EventFloorDayControllerApi(API_CONFIG);

export const fetchEventFloorDays = createAsyncThunk('eventFloorDays/list', async (filter: GetEventFloorDayListUsingGETRequest, thunkApi) => {
    return {...await eventFloorDaysApi.getEventFloorDayListUsingGET(filter), filter};
});

export const fetchEventFloorDaysCount = createAsyncThunk('eventFloorDays/count', async (params: GetEventFloorDayCountUsingGETRequest = {}) => {
    return await eventFloorDaysApi.getEventFloorDayCountUsingGET(params);
});

export const fetchEventFloorDayInfo = createAsyncThunk('eventFloorDay/info', async (eventFloorDayId: number): Promise<ApiResultOfJsonEventFloorDayInfo> => {
    return await eventFloorDaysApi.getEventFloorDayInfoUsingGET({eventFloorDayId});
});

export const fetchEventFloorDay = createAsyncThunk('eventFloorDay/fetch', async (eventFloorDayId: number): Promise<ApiResultOfJsonEventFloorDay> => {
    return await eventFloorDaysApi.getEventFloorDayUsingGET({eventFloorDayId});
});

export const saveEventFloorDay = createAsyncThunk('eventFloorDay/save', async (eventFloorDay: JsonEventFloorDay) => {
    return await eventFloorDaysApi.saveEventFloorDayUsingPOST({json: eventFloorDay});
});

export const eventFloorDaysSlice = createSlice({
    name: 'eventFloorDays',
    initialState: (): ItemsState<JsonEventFloorDayInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchEventFloorDays, fetchEventFloorDaysCount);
    }
});

export const eventFloorDaysReducer = eventFloorDaysSlice.reducer;
