import {JsonEventPartyInfo} from "../generated-api";
import * as React from "react";
import {Tooltip} from "@mui/material";

type EventPartyAccredDetailProps = {
    item: JsonEventPartyInfo,
    col: 'formatCode' | 'areas' | 'reason' | 'contingentOwner'
}

const siwiAccredCol: { [key in EventPartyAccredDetailProps['col']]: keyof JsonEventPartyInfo } = {
    'formatCode': 'siwiFormatCode',
    'areas': 'siwiAreas',
    'reason': 'siwiReason',
    'contingentOwner': 'siwiContingentOwner',
}

const EventPartyAccredDetail = (props: EventPartyAccredDetailProps) => {
    const {item, col} = props;

    const val = item[col];
    const siwiVal = item[siwiAccredCol[col]];

    if (val === siwiVal) {
        return <>{val}</>;
    }
    if (val) {
        if (!siwiVal && !item.siwiFormatCode) {
            return <em>{val}</em>
        }
        if (col === 'areas' && siwiVal) {
            const siwiAreas = (siwiVal as string);
            const areas: JSX.Element[] = [...val.split(' '), ...siwiAreas.split(' ')]
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort()
                .map((area) => {
                    if (val.indexOf(area) >= 0) {
                        if (siwiAreas.indexOf(area) >= 0) {
                            return <span key={area}>{area} </span>;
                        } else {
                            return <span key={area} style={{color: 'var(--color-info)'}}>{area} </span>;
                        }
                    } else {
                        return <span key={area} style={{color: 'var(--color-warning)'}}>{area} </span>;
                    }
                });
            return <Tooltip title={<div>
                <pre>{'EMS:  ' + (val ? val : '-')}</pre>
                <br/>
                <pre>{'Siwi: ' + (siwiVal ? siwiVal : '-')}</pre>
            </div>}>
                <div>{areas}</div>
            </Tooltip>

        }
        return <span title={'Siwi: ' + (siwiVal ? siwiVal : '-')} style={{color: 'var(--color-warning)'}}>{val}</span>
    }

    return <span title={'EMS: není'} style={{color: 'var(--color-warning)'}}>{siwiVal as any}</span>;
}

export default EventPartyAccredDetail;
