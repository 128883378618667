/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonPlaceTotals
 */
export interface JsonPlaceTotals {
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceTotals
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPlaceTotals
     */
    dayNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPlaceTotals
     */
    type?: string;
}

export function JsonPlaceTotalsFromJSON(json: any): JsonPlaceTotals {
    return JsonPlaceTotalsFromJSONTyped(json, false);
}

export function JsonPlaceTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPlaceTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'dayNo': !exists(json, 'dayNo') ? undefined : json['dayNo'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function JsonPlaceTotalsToJSON(value?: JsonPlaceTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'dayNo': value.dayNo,
        'type': value.type,
    };
}

