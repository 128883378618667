/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonEventFloorData,
    JsonEventFloorDataFromJSON,
    JsonEventFloorDataFromJSONTyped,
    JsonEventFloorDataToJSON,
} from './JsonEventFloorData';

/**
 * 
 * @export
 * @interface JsonEventFloor
 */
export interface JsonEventFloor {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventFloor
     */
    allowedActions?: Array<JsonEventFloorAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventFloor
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloor
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloor
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloor
     */
    eventFloorId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloor
     */
    eventId?: number;
    /**
     * 
     * @type {JsonEventFloorData}
     * @memberof JsonEventFloor
     */
    floorData?: JsonEventFloorData;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloor
     */
    planGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloor
     */
    planId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloor
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloor
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloor
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventFloorAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventFloorFromJSON(json: any): JsonEventFloor {
    return JsonEventFloorFromJSONTyped(json, false);
}

export function JsonEventFloorFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventFloor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventFloorId': !exists(json, 'eventFloorId') ? undefined : json['eventFloorId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'floorData': !exists(json, 'floorData') ? undefined : JsonEventFloorDataFromJSON(json['floorData']),
        'planGuid': !exists(json, 'planGuid') ? undefined : json['planGuid'],
        'planId': !exists(json, 'planId') ? undefined : json['planId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventFloorToJSON(value?: JsonEventFloor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventFloorId': value.eventFloorId,
        'eventId': value.eventId,
        'floorData': JsonEventFloorDataToJSON(value.floorData),
        'planGuid': value.planGuid,
        'planId': value.planId,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

