/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFileInfo,
    JsonFileInfoFromJSON,
    JsonFileInfoFromJSONTyped,
    JsonFileInfoToJSON,
} from './JsonFileInfo';

/**
 * 
 * @export
 * @interface JsonEventPartyTravelInfo
 */
export interface JsonEventPartyTravelInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventPartyTravelInfo
     */
    allowedActions?: Array<JsonEventPartyTravelInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    beginLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    beginTime?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravelInfo
     */
    compAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravelInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    endLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravelInfo
     */
    eventPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravelInfo
     */
    eventPartyTravelId?: number;
    /**
     * 
     * @type {Array<JsonFileInfo>}
     * @memberof JsonEventPartyTravelInfo
     */
    files?: Array<JsonFileInfo>;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravelInfo
     */
    kmCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    plateNum?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    status?: JsonEventPartyTravelInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyTravelInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyTravelInfo
     */
    vehicle?: JsonEventPartyTravelInfoVehicleEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyTravelInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyTravelInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    Paid = 'PAID'
}/**
* @export
* @enum {string}
*/
export enum JsonEventPartyTravelInfoVehicleEnum {
    Car = 'CAR',
    Bus = 'BUS',
    Train = 'TRAIN'
}

export function JsonEventPartyTravelInfoFromJSON(json: any): JsonEventPartyTravelInfo {
    return JsonEventPartyTravelInfoFromJSONTyped(json, false);
}

export function JsonEventPartyTravelInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyTravelInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'beginDate': !exists(json, 'beginDate') ? undefined : json['beginDate'],
        'beginLocation': !exists(json, 'beginLocation') ? undefined : json['beginLocation'],
        'beginTime': !exists(json, 'beginTime') ? undefined : json['beginTime'],
        'compAmount': !exists(json, 'compAmount') ? undefined : json['compAmount'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'endLocation': !exists(json, 'endLocation') ? undefined : json['endLocation'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'eventPartyTravelId': !exists(json, 'eventPartyTravelId') ? undefined : json['eventPartyTravelId'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(JsonFileInfoFromJSON)),
        'kmCnt': !exists(json, 'kmCnt') ? undefined : json['kmCnt'],
        'plateNum': !exists(json, 'plateNum') ? undefined : json['plateNum'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
    };
}

export function JsonEventPartyTravelInfoToJSON(value?: JsonEventPartyTravelInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'beginDate': value.beginDate,
        'beginLocation': value.beginLocation,
        'beginTime': value.beginTime,
        'compAmount': value.compAmount,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'endDate': value.endDate,
        'endLocation': value.endLocation,
        'endTime': value.endTime,
        'eventPartyId': value.eventPartyId,
        'eventPartyTravelId': value.eventPartyTravelId,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(JsonFileInfoToJSON)),
        'kmCnt': value.kmCnt,
        'plateNum': value.plateNum,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'vehicle': value.vehicle,
    };
}

