import {Card, CardContent, CardHeader, Chip, Container, Grid, LinearProgress, Link, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs} from '@mui/material';
import * as React from 'react';
import {CSSProperties, Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useAppTranslation} from '../services/i18n';
import {fetchEventPartiesOnline, fetchEventPartyInfo, massEventParty} from '../store/eventParties';
import PageHeader from '../components/layout/PageHeader';
import {
    GetEventPartyListUsingGETStatusesEnum,
    JsonEventDayInfo,
    JsonEventPartyDay,
    JsonEventPartyInfo,
    JsonEventPartyInfoPartyTypeEnum,
    JsonEventPartyMassActionRequestActionEnum,
    JsonPlaceFoodServicesEnum,
    JsonPlaceInfo,
} from '../generated-api';
import {useAppDispatch, useAppSelector} from "../store";
import {selectAuthInfo, selectCodebooks} from "../store/selectors";
import {createOption, createOptions, FAKE_VALUE_ALL, FAKE_VALUE_EMPTY, OptionValue} from "../model/form";
import {getApiResult} from "../helpers/api";
import {fetchEventDays} from "../store/eventDays";
import {CodebookState, FIXED_EVENT_DAY_NOS} from "../store/codebooks";
import {fetchPlacesOnline} from "../store/places";
import {dateToGuiAs} from "../helpers/date";
import {Link as RouterLink, useParams} from "react-router-dom";
import {foodServiceOptions, foodServiceProps} from "../model/place";
import {useNavigate} from "react-router";
import {AuthState} from "../store/auth";
import {SimpleSelectFormField} from "../components/form/SimpleSelectFormField";
import {Formik} from "formik";
import {ButtonGroupPlain} from "../components/form/ButtonGroupField";

type ParkPlaceGroup = {
    groupId: number,
    title: string,
    days: { [key in number]: JsonEventPartyInfo[] }
}

type ParkPlace = {
    placeId: number,
    title: string,
    capacity?: number,
    groups: ParkPlaceGroup[]
}

type EventParkPlaceProps = {
    eventId: number,
    pp: ParkPlace,
    days: JsonEventDayInfo[]
}

const h100 = {height: '100%'};

const cateringStyle = {
    height: '100%',
    '& .data-grid tbody > tr > td > span': {
        padding: '4px 0px'
    }
}

const EventParkPlace = (props: EventParkPlaceProps) => {
    const {eventId, pp, days} = props;

    const t = useAppTranslation();

    return useMemo(() => {
        const totals: { [key in number]: number } = {};

        const rows = pp.groups.map((g) => {
            return <TableRow key={g.groupId}>
                <TableCell><span><strong>{g.title}</strong></span></TableCell>
                {days?.map((d) => {
                    if (!d.dayNo) {
                        return null;
                    }
                    const total = g.days[d.dayNo]
                        ?.map((ep) => {
                            const dd = ep.eventDayDetails?.find((dd) => dd.dayNo === d.dayNo);
                            if (dd?.parkCnt !== undefined) {
                                return dd.parkCnt; // can be 0
                            }
                            return 1;
                        })
                        ?.reduce((total, cnt) => total + cnt, 0) || 0;
                    if (!totals[d.dayNo]) {
                        totals[d.dayNo] = 0;
                    }
                    totals[d.dayNo] += total;

                    return <TableCell key={d.dayNo} style={{textAlign: 'center'}}><span>{!!total && <Link underline={'hover'}
						to={`/event-parties/?eventId=${eventId}&eventDay=${d.dayNo}&groupIds=${g.groupId}&parkIds=${pp.placeId}`}
						component={RouterLink}>{total}</Link>}</span>
                    </TableCell>;
                })}
            </TableRow>
        });
        if (rows.length <= 0) {
            return null;
        }
        if (rows.length > 1) {
            rows.push(<TableRow key={'total'}>
                <TableCell><span><strong>{t('Celkem')}</strong></span></TableCell>
                {days?.map((d) => {
                    if (!d.dayNo) {
                        return null;
                    }
                    return <TableCell key={d.dayNo} style={{textAlign: 'center'}}><span><strong>{totals[d.dayNo] || 0}</strong></span>
                    </TableCell>;
                })}
            </TableRow>);
        }

        const isWide = days.length > 6;
        return <Grid key={pp.title} item md={6} lg={isWide ? 6 : 4}>
            <Card sx={h100}>
                <CardHeader title={<span>{pp.title}<span style={{float: 'right'}}>{pp.capacity}</span></span>}/>
                <CardContent>
                    <Table className={'data-grid'}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: isWide ? '20%' : '40%'}}><span>{t('Dny')}</span></TableCell>
                                {days?.map((d) => {
                                    return <TableCell key={d.dayNo} style={{width: ((isWide ? 80 : 60) / days.length) + '%', textAlign: 'center'}}>
                                        <span>{dateToGuiAs(d.dayDate, "eeeee d.M.")}</span>
                                    </TableCell>;
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Grid>
    }, [eventId, pp, days, t]);
}

const EventReportParking = ({eventId, eventDays}: { eventId: number, eventDays: JsonEventDayInfo[] }) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector<CodebookState>(selectCodebooks);
    const placeParkList = codebooks['placePark'];
    const groupList = codebooks['group'];

    const [eventParties, setEventParties] = useState<JsonEventPartyInfo[] | undefined>(undefined);
    const [techParties, setTechParties] = useState<JsonEventPartyInfo[] | undefined>(undefined);
    const [placeInfos, setPlaceInfos] = useState<JsonPlaceInfo[] | undefined>(undefined);

    const handleFetchItems = useCallback(async () => {
        setPlaceInfos(getApiResult(await dispatch(fetchPlacesOnline({eventId}))));
        const eventParties = getApiResult<JsonEventPartyInfo[]>(await dispatch(fetchEventPartiesOnline({
            orderCol: 'fullName',
            rows: 10000,
            eventId,
            parkIds: [FAKE_VALUE_ALL],
            statuses: [GetEventPartyListUsingGETStatusesEnum.Pending, GetEventPartyListUsingGETStatusesEnum.Active]
        })));

        setEventParties(eventParties);
        setTechParties(eventParties?.filter((ep) => ep.partyType === JsonEventPartyInfoPartyTypeEnum.T));

    }, [eventId, dispatch]);

    const handleAddParking = useCallback(async (ep: JsonEventPartyInfo, dayNo: number) => {
        if (!ep.eventPartyId) {
            return;
        }

        const eventParty = getApiResult<JsonEventPartyInfo>(await dispatch(fetchEventPartyInfo(ep.eventPartyId)));
        if (eventParty?.eventPartyId) {
            const eventPartyDays: JsonEventPartyDay[] = Array.from({length: dayNo}, () => ({} as JsonEventPartyDay));
            eventPartyDays[dayNo - 1] = {
                parkCnt: (eventParty.eventDayDetails?.find((dd) => dd.dayNo === dayNo)?.parkCnt || 1) + 1,
                parkRec: (eventParty.eventDayDetails?.find((dd) => dd.dayNo === dayNo)?.parkRec || 1) + 1,
            };
            await dispatch(massEventParty({
                request: {
                    groupId: FAKE_VALUE_EMPTY,
                    action: JsonEventPartyMassActionRequestActionEnum.Update,
                    eventId: eventParty.eventId,
                    items: [{eventPartyId: eventParty?.eventPartyId}],
                    eventPartyDays
                }
            }));
            await handleFetchItems();
        }

    }, [handleFetchItems, dispatch]);

    const parkPlaces = useMemo(() => {
        const parkPlaces: ParkPlace[] = [];
        if (!eventParties || !placeParkList || !groupList || !eventDays || !placeInfos) {
            return parkPlaces;
        }

        const options = createOptions({codebookName: 'placePark', scope: eventId, asNumber: true}, {placePark: placeParkList});
        options.forEach((o) => {
            const p = placeInfos?.find((p) => p.placeId === o.value);
            // const pt = p?.placeTotals?.find((pt) => pt.dayNo === dayNo && pt.type === 'park');
            const parkPlace: ParkPlace = {
                placeId: o.value as number,
                title: p?.title || o.label || o.value + '',
                capacity: p?.parkCapacity,
                groups: []
            }

            const groupsById: { [key in number]: ParkPlaceGroup } = {};

            eventParties.forEach((ep) => {
                ep.eventDayDetails?.forEach((dd) => {
                    if (!dd.parkId) {
                        return;
                    }
                    if (dd.parkId === o.value
                        && dd.parkFlag
                        && dd.parkFlag > 0
                        && dd.dayNo
                        && FIXED_EVENT_DAY_NOS.indexOf(dd.dayNo) >= 0
                        && ep.eventDays?.[dd.dayNo - 1] === '1') {
                        const groupId = ep.groupId || FAKE_VALUE_EMPTY;
                        if (!groupsById[groupId]) {
                            const g = groupList.find((o) => o.value === '' + groupId);
                            groupsById[groupId] = {
                                groupId,
                                title: g?.label || t('Bez skupiny'),
                                days: {}
                            }
                            parkPlace.groups.push(groupsById[groupId]);
                        }
                        const g = groupsById[groupId];
                        if (!g.days[dd.dayNo]) {
                            g.days[dd.dayNo] = [];
                        }
                        g.days[dd.dayNo].push(ep);
                    }
                })
            })

            parkPlaces.push(parkPlace);
        })
        return parkPlaces;

    }, [eventId, eventParties, placeParkList, groupList, eventDays, placeInfos, t]);

    useEffect(() => {
        handleFetchItems().then();
    }, [handleFetchItems, dispatch]);

    const days = eventDays?.filter((ed) => ed.dayNo && FIXED_EVENT_DAY_NOS.indexOf(ed.dayNo) >= 0);
    const isWide = days?.length && days.length > 6;
    const isLoading = !eventDays || !placeParkList || !eventParties || !groupList || !placeInfos || !days || !techParties;
    return isLoading
        ? <LinearProgress/>
        : <Grid container spacing={2}>
            <Grid item md={isWide ? 7 : 8} sm={6}>
                <Grid container spacing={2}>
                    {parkPlaces.map((pp) => {
                        return <EventParkPlace key={pp.title} eventId={eventId} pp={pp} days={days}/>;
                    })}
                </Grid>
            </Grid>
            {!!techParties?.length && techParties.length > 0 && <Grid item md={isWide ? 5 : 4} sm={6}>
				<Card>
					<CardHeader title={<span>{t('Technické osoby')}</span>}/>
					<CardContent>
						<Table className={'data-grid tech-parties'}>
							<TableHead>
								<TableRow>
									<TableCell style={{width: '25%'}}><span>{t('Dny')}</span></TableCell>
                                    {days?.map((d) => {
                                        return <TableCell key={d.dayNo} style={{width: (75 / days.length) + '%', textAlign: 'center'}}>
                                            <span>{dateToGuiAs(d.dayDate, "eeeee d.M.")}</span>
                                        </TableCell>;
                                    })}
								</TableRow>
							</TableHead>
							<TableBody>
                                {techParties.map((ep) => {
                                    return <TableRow key={ep.partyId}>
                                        <TableCell><span><Link underline={'hover'}
                                            to={`/parties/${ep.partyId}/${ep.eventId}`}
                                            component={RouterLink}>{ep.companyName}</Link></span></TableCell>
                                        {days?.map((d) => {
                                            if (!d.dayNo) {
                                                return null;
                                            }
                                            let total;
                                            const dd = ep.eventDayDetails?.find((dd) => dd.dayNo === d.dayNo);
                                            if (dd?.parkCnt !== undefined) {
                                                total = dd.parkCnt;
                                            } else {
                                                total = !!dd?.parkFlag ? 1 : 0;
                                            }

                                            return <TableCell key={d.dayNo} style={{textAlign: 'center'}}><span><Link underline={'hover'}
                                                to={`/event-parties/?eventId=${eventId}&eventDay=${d.dayNo}&search=${ep.companyName}`}
                                                component={RouterLink}>{total}</Link>
                                                {total > 0 && <Chip key={'quick'} size={'small'} className={'party-tags-add'} style={{marginLeft: '2px'}}
													label={'+'} title={t('Kliknutím přidat 1 kartu')}
													onClick={() => {
                                                        if (d.dayNo) {
                                                            handleAddParking(ep, d.dayNo).then();
                                                        }
                                                    }}
												/>}
                                            </span></TableCell>;
                                        })}
                                    </TableRow>;
                                })}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Grid>
            }
        </Grid>
        ;
}

type EventFoodPlaceProps = {
    eventId: number,
    pp: ParkPlace,
    days: JsonEventDayInfo[]
}

const EventFoodPlace = (props: EventFoodPlaceProps) => {
    const {eventId, pp, days} = props;

    const t = useAppTranslation();

    return useMemo(() => {
        const totals: { [key in string]: number } = {};
        const useds: { [key in string]: number } = {};

        const foodCountStyle: CSSProperties = {textAlign: 'center'};
        const rows = pp.groups.map((g) => {
            return <TableRow key={g.groupId}>
                <TableCell><span><strong>{g.title}</strong></span></TableCell>
                {days?.map((d, i) => {
                    return foodServiceOptions.map((fs) => {
                        if (!d.dayNo) {
                            return null;
                        }
                        const serviceType = fs.value as JsonPlaceFoodServicesEnum;
                        const foodProp = foodServiceProps[serviceType];
                        const dd = g.days[d.dayNo]
                            ?.map((ep) => ep.eventDayDetails?.find((dd) => dd.dayNo === d.dayNo
                                && ((pp.placeId === FAKE_VALUE_ALL && !!dd[foodProp]) || dd[foodProp] === pp.placeId)))
                            ?.filter(dd => !!dd);

                        const total = dd?.reduce((total, dd) => total + (dd?.foodCnt?.[foodProp] || 1), 0);
                        const used = dd?.filter(dd => dd?.foodData?.log?.find(l => l.serviceType as any === serviceType as any)).length;

                        const k = d.dayNo + '-' + serviceType;
                        if (!totals[k]) {
                            totals[k] = 0;
                            useds[k] = 0;
                        }
                        if (total) {
                            totals[k] += total;
                        }
                        if (used) {
                            useds[k] += used;
                        }

                        const cn = i % 2 > 0 ? 'high-col' : undefined;
                        return <Fragment key={k}>
                            <TableCell style={foodCountStyle} className={cn}><span>{!!total && <Link underline={'hover'}
								to={`/event-parties/?eventId=${eventId}&eventDay=${d.dayNo}&groupIds=${g.groupId}&foodIds=${pp.placeId}`}
								component={RouterLink}>{total}</Link>}</span>
                            </TableCell>
                            <TableCell style={foodCountStyle} className={cn}>
                                <span>{!!used ? used : null}</span>
                            </TableCell>
                        </Fragment>;
                    })
                })}
            </TableRow>
        });
        if (rows.length <= 0) {
            return null;
        }

        if (rows.length > 1) {
            rows.push(<TableRow key={'total'}>
                <TableCell><span><strong>{t('Celkem')}</strong></span></TableCell>
                {days?.map((d, i) => {
                    if (!d.dayNo) {
                        return null;
                    }
                    return foodServiceOptions.map((fs) => {
                        const k = d.dayNo + '-' + fs.value;
                        const total = totals[k] || 0;
                        const used = useds[k] || 0;
                        const cn = i % 2 > 0 ? 'high-col' : undefined;
                        return <Fragment key={k}>
                            <TableCell style={foodCountStyle} className={cn}><span><strong>{!!total && <Link underline={'hover'}
								to={`/event-parties/?eventId=${eventId}&eventDay=${d.dayNo}&foodIds=${pp.placeId}`}
								component={RouterLink}>{total}</Link>}</strong></span>
                            </TableCell>
                            <TableCell style={foodCountStyle} className={cn}>
                                <span>{used}</span>
                            </TableCell>
                        </Fragment>;
                    });
                })}
            </TableRow>);
        }
        const foodStyle: CSSProperties = {width: ((90 / (days.length + foodServiceOptions.length)) / 2) + '%', textAlign: 'center'};

        return <Grid key={pp.title} item xs={12}>
            <Card sx={cateringStyle}>
                <CardHeader title={<span>{pp.title}{!!pp.capacity && pp.capacity < 9999 && <span style={{float: 'right'}}>{pp.capacity}</span>}</span>}/>
                <CardContent>
                    <Table className={'data-grid'}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '8%'}} rowSpan={2}><span>{t('Dny')}</span></TableCell>
                                {days?.map((d, i) => {
                                    return <TableCell key={d.dayNo} style={{width: (90 / days.length) + '%', textAlign: 'center'}}
                                        colSpan={foodServiceOptions.length * 2} className={i % 2 > 0 ? 'high-col' : undefined}>
                                        <span>{dateToGuiAs(d.dayDate, "eeeee d.M.")}</span>
                                    </TableCell>;
                                })}
                            </TableRow>
                            <TableRow>
                                {days?.map((d, i) => {
                                    const cn = i % 2 > 0 ? 'high-col' : undefined;
                                    return foodServiceOptions.map((fs) => {
                                        return <Fragment key={d.dayNo + '-' + fs.value}>
                                            <TableCell style={foodStyle}
                                                className={cn}>
                                                <span>{fs.label}</span>
                                            </TableCell>
                                            <TableCell style={foodStyle}
                                                className={cn}>
                                                <span></span>
                                            </TableCell>
                                        </Fragment>;
                                    })
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Grid>
    }, [eventId, pp, days, t]);
}

const EventReportCatering = ({eventId, eventDays}: { eventId: number, eventDays: JsonEventDayInfo[] }) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector<CodebookState>(selectCodebooks);
    const placeFoodList = codebooks['placeFood'];
    const groupList = codebooks['group'];

    const [eventParties, setEventParties] = useState<JsonEventPartyInfo[] | undefined>(undefined);
    const [placeInfos, setPlaceInfos] = useState<JsonPlaceInfo[] | undefined>(undefined);

    const handleFetchItems = useCallback(async () => {
        setPlaceInfos(getApiResult(await dispatch(fetchPlacesOnline({eventId}))));
        setEventParties(getApiResult(await dispatch(fetchEventPartiesOnline({
            orderCol: 'fullName',
            rows: 10000,
            eventId,
            foodIds: [FAKE_VALUE_ALL],
            statuses: [GetEventPartyListUsingGETStatusesEnum.Pending, GetEventPartyListUsingGETStatusesEnum.Active]
        }))));
    }, [eventId, dispatch]);

    const foodPlaces = useMemo(() => {
        const foodPlaces: ParkPlace[] = [];
        if (!eventParties || !placeFoodList || !groupList || !eventDays || !placeInfos) {
            return foodPlaces;
        }

        const options = createOptions({codebookName: 'placeFood', scope: eventId, asNumber: true}, {placeFood: placeFoodList});
        options.push(createOption(FAKE_VALUE_ALL, t('Celkem')));
        options.forEach((o) => {
            const p = placeInfos?.find((p) => p.placeId === o.value);
            const foodPlace: ParkPlace = {
                placeId: o.value as number,
                title: p?.title || o.label || o.value + '',
                capacity: p?.foodCapacity,
                groups: []
            }

            const groupsById: { [key in number]: ParkPlaceGroup } = {};

            eventParties.forEach((ep) => {
                ep.eventDayDetails?.forEach((dd) => {
                    if (!dd['foodBrId'] && !dd['foodLuId'] && !dd['foodDiId'] && !dd['foodSnId']) {
                        return;
                    }
                    if ((o.value === FAKE_VALUE_ALL
                            || dd['foodBrId'] === o.value
                            || dd['foodLuId'] === o.value
                            || dd['foodDiId'] === o.value
                            || dd['foodSnId'] === o.value)
                        && dd.dayNo
                        && ep.eventDays?.[dd.dayNo - 1] === '1') {
                        const groupId = ep.groupId || FAKE_VALUE_EMPTY;
                        if (!groupsById[groupId]) {
                            const g = groupList.find((o) => o.value === '' + groupId);
                            groupsById[groupId] = {
                                groupId,
                                title: g?.label || t('Bez skupiny'),
                                days: {}
                            }
                            foodPlace.groups.push(groupsById[groupId]);
                        }
                        const g = groupsById[groupId];
                        if (!g.days[dd.dayNo]) {
                            g.days[dd.dayNo] = [];
                        }
                        g.days[dd.dayNo].push(ep);
                    }
                })
            })

            foodPlaces.push(foodPlace);
        })
        return foodPlaces;

    }, [eventId, eventParties, placeFoodList, groupList, eventDays, placeInfos, t]);

    useEffect(() => {
        handleFetchItems().then();
    }, [eventId, handleFetchItems]);

    const isLoading = !eventDays || !placeFoodList || !eventParties || !groupList || !placeInfos;
    return isLoading
        ? <LinearProgress/>
        : <Grid container spacing={2}>
            {foodPlaces.map((pp) => {
                return <EventFoodPlace key={pp.title} eventId={eventId} pp={pp} days={eventDays}/>;
            })}
        </Grid>;
}

const WEEK_SIZE = 7;
const EventReportsPage = () => {
    const t = useAppTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {configuration} = useAppSelector<AuthState>(selectAuthInfo);
    const [eventId, setEventId] = useState<number>(configuration?.defaultEvent?.eventId as number);
    const [eventDays, setEventDays] = useState<JsonEventDayInfo[] | undefined>(undefined);
    const [filteredEventDays, setFilteredEventDays] = useState<JsonEventDayInfo[] | undefined>(undefined);
    const [weekNo, setWeekNo] = useState<number>(0);

    const tab = params.report || 'parking';

    const weekOptions: OptionValue[] | undefined = useMemo(() => {
        if (!eventDays || eventDays.length <= 7) {
            return undefined;
        }
        const options: OptionValue[] = [];
        for (let i = 0; i < Math.ceil(eventDays.length / WEEK_SIZE); i++) {
            const dayNos = eventDays.slice(i * WEEK_SIZE, (i + 1) * WEEK_SIZE).map(d => dateToGuiAs(d.dayDate, 'd.M.'));
            if (!dayNos) {
                return;
            }
            options.push(createOption(i, 'Dny ' + dayNos[0] + ' - ' + dayNos[dayNos.length - 1]));
        }

        return options;

    }, [eventDays]);

    useEffect(() => {
        dispatch(fetchEventDays({eventId})).then((res) => {
            setEventDays(getApiResult<JsonEventDayInfo[]>(res));
        });
    }, [eventId, dispatch]);

    useEffect(() => {
        setFilteredEventDays(weekNo === undefined
            ? eventDays
            : eventDays?.slice(weekNo * WEEK_SIZE, (weekNo + 1) * WEEK_SIZE));

    }, [weekNo, eventDays]);

    return <Container>
        <Grid container spacing={2} className={'page-content page-tabs'}>
            <Grid item sm={2}>
                <PageHeader title={t('Reporty')}/>
            </Grid>
            <Grid item sm={tab === 'catering' && !!weekOptions ? 4 : 8}>
                <Tabs
                    value={tab}
                    onChange={(_, v) => navigate('/reports/' + v)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab key={0} value={'parking'} label={t('Parkování')}/>
                    <Tab key={1} value={'catering'} label={t('Catering')}/>
                </Tabs>
            </Grid>
            {tab === 'catering' && !!weekOptions && <Grid item sm={4} sx={{display: 'flex', justifyContent: 'center', placeItems: 'center'}}>
				<ButtonGroupPlain currentValue={weekNo} options={weekOptions} name={'eventDay'} onChange={(weekNo) => {
                    if (weekNo !== undefined) {
                        setWeekNo(weekNo);
                    }
                }}/>
			</Grid>}
            <Grid item sm={2} sx={{display: 'flex', alignItems: 'center', placeItems: 'center'}}>
                <Formik
                    initialValues={{
                        eventId,
                    }}
                    onSubmit={(values) => {
                        setEventId(values.eventId);
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                            <div className={'form-container'}>
                                <SimpleSelectFormField name="eventId" placeholder={'Událost'} onChange={props.handleSubmit}
                                    codebookProps={{codebookName: 'event', asNumber: true, sortByKeys: true, reversed: true}}/>
                            </div>
                        </form>
                    )}
                </Formik>
            </Grid>
            {<Grid item sm={12}>
                {tab === 'parking' && eventDays && <EventReportParking eventId={eventId} eventDays={eventDays}/>}
                {tab === 'catering' && !!filteredEventDays && <EventReportCatering eventId={eventId} eventDays={filteredEventDays}/>}
            </Grid>}
        </Grid>
    </Container>;
}

export default EventReportsPage;
