import {useEffect, useState} from "react";
import {useFormikContext} from "formik";

const isElVisible = (el: HTMLElement) => {
    return el && !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
}

const findErrorInput = (name: string) => {
    const el = document.querySelector('[name="' + name + '"]');
    let cardEl: HTMLElement | null = null;
    let blinkEl: HTMLElement | null = null;

    let parentEl: HTMLElement | null | undefined = el?.parentElement;
    while (parentEl) {
        if (!blinkEl && isElVisible(parentEl)) {
            blinkEl = parentEl;
        }
        if (parentEl.className?.indexOf('MuiCardContent-root') >= 0) {
            cardEl = parentEl;
            break;
        }
        parentEl = parentEl.parentElement;
    }

    const container = (cardEl ?? (el?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement ?? el));
    return {blinkEl, container};
}

const ScrollErrorsIntoView = () => {
    const {submitCount, isValid, errors, isValidating} = useFormikContext();
    const [lastSubmitCount, setLastSubmitCount] = useState<number>(0);

    useEffect(() => {
        if (isValidating) {
            return;
        }
        setLastSubmitCount(submitCount);

    }, [isValidating, submitCount]);

    useEffect(() => {
        if (isValid) {
            return
        }

        const errorNames = Object.keys(errors);
        if (!errorNames?.length) {
            return;
        }
        let errorName = errorNames[0];
        let errorVal = (errors as any)[errorName];
        let inputNames = [errorName];
        while (errorVal && typeof errorVal !== 'string') {
            errorName = Object.keys(errorVal)[0];
            errorVal = (errorVal as any)[errorName];
            inputNames.push(errorName);
        }
        setTimeout(() => {
            const name = inputNames.join('.');

            const {blinkEl, container} = findErrorInput(name);
            if (container) {
                container.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
            if (blinkEl) {
                blinkEl.className = (blinkEl.className ? blinkEl.className + ' ' : '') + 'blink-me';
                setTimeout(() => {
                    const {blinkEl} = findErrorInput(name);
                    if (blinkEl?.className) {
                        blinkEl.className = blinkEl.className?.replaceAll('blink-me', '')?.trim();
                    }
                }, 1000);
            }

        }, 100);

    }, [lastSubmitCount]) // eslint-disable-line react-hooks/exhaustive-deps

    return null;
}

export default ScrollErrorsIntoView;