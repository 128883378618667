/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonFloorDayData,
    JsonFloorDayDataFromJSON,
    JsonFloorDayDataFromJSONTyped,
    JsonFloorDayDataToJSON,
} from './JsonFloorDayData';

/**
 * 
 * @export
 * @interface JsonEventFloorDay
 */
export interface JsonEventFloorDay {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventFloorDay
     */
    allowedActions?: Array<JsonEventFloorDayAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventFloorDay
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorDay
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDay
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDay
     */
    eventDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDay
     */
    eventFloorDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDay
     */
    eventFloorId?: number;
    /**
     * 
     * @type {JsonFloorDayData}
     * @memberof JsonEventFloorDay
     */
    floorData?: JsonFloorDayData;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorDay
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDay
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventFloorDayAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventFloorDayFromJSON(json: any): JsonEventFloorDay {
    return JsonEventFloorDayFromJSONTyped(json, false);
}

export function JsonEventFloorDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventFloorDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventDayId': !exists(json, 'eventDayId') ? undefined : json['eventDayId'],
        'eventFloorDayId': !exists(json, 'eventFloorDayId') ? undefined : json['eventFloorDayId'],
        'eventFloorId': !exists(json, 'eventFloorId') ? undefined : json['eventFloorId'],
        'floorData': !exists(json, 'floorData') ? undefined : JsonFloorDayDataFromJSON(json['floorData']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventFloorDayToJSON(value?: JsonEventFloorDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventDayId': value.eventDayId,
        'eventFloorDayId': value.eventFloorDayId,
        'eventFloorId': value.eventFloorId,
        'floorData': JsonFloorDayDataToJSON(value.floorData),
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

