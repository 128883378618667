/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonPlace,
    ApiResultOfJsonPlaceFromJSON,
    ApiResultOfJsonPlaceToJSON,
    ApiResultOfJsonPlaceInfo,
    ApiResultOfJsonPlaceInfoFromJSON,
    ApiResultOfJsonPlaceInfoToJSON,
    ApiResultOfListOfJsonPlaceInfo,
    ApiResultOfListOfJsonPlaceInfoFromJSON,
    ApiResultOfListOfJsonPlaceInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonPlace,
    JsonPlaceFromJSON,
    JsonPlaceToJSON,
} from '../models';

export interface GetPlaceCountUsingGETRequest {
    eventId?: number;
    statuses?: Array<GetPlaceCountUsingGETStatusesEnum>;
    search?: string;
}

export interface GetPlaceInfoUsingGETRequest {
    placeId: number;
}

export interface GetPlaceListUsingGETRequest {
    eventId?: number;
    statuses?: Array<GetPlaceListUsingGETStatusesEnum>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetPlaceUsingGETRequest {
    placeId: number;
}

export interface SavePlaceUsingPOSTRequest {
    json: JsonPlace;
    testOnly?: boolean;
}

/**
 * 
 */
export class PlaceControllerApi extends runtime.BaseAPI {

    /**
     * getPlaceCount
     */
    async getPlaceCountUsingGETRaw(requestParameters: GetPlaceCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/place/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getPlaceCount
     */
    async getPlaceCountUsingGET(requestParameters: GetPlaceCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getPlaceCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPlaceInfo
     */
    async getPlaceInfoUsingGETRaw(requestParameters: GetPlaceInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPlaceInfo>> {
        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling getPlaceInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/place/info/{placeId}`.replace(`{${"placeId"}}`, encodeURIComponent(String(requestParameters.placeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPlaceInfoFromJSON(jsonValue));
    }

    /**
     * getPlaceInfo
     */
    async getPlaceInfoUsingGET(requestParameters: GetPlaceInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPlaceInfo> {
        const response = await this.getPlaceInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPlaceList
     */
    async getPlaceListUsingGETRaw(requestParameters: GetPlaceListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonPlaceInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/place/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonPlaceInfoFromJSON(jsonValue));
    }

    /**
     * getPlaceList
     */
    async getPlaceListUsingGET(requestParameters: GetPlaceListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonPlaceInfo> {
        const response = await this.getPlaceListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPlace
     */
    async getPlaceUsingGETRaw(requestParameters: GetPlaceUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPlace>> {
        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling getPlaceUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/place/{placeId}`.replace(`{${"placeId"}}`, encodeURIComponent(String(requestParameters.placeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPlaceFromJSON(jsonValue));
    }

    /**
     * getPlace
     */
    async getPlaceUsingGET(requestParameters: GetPlaceUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPlace> {
        const response = await this.getPlaceUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * savePlace
     */
    async savePlaceUsingPOSTRaw(requestParameters: SavePlaceUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPlace>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling savePlaceUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/place`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonPlaceToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPlaceFromJSON(jsonValue));
    }

    /**
     * savePlace
     */
    async savePlaceUsingPOST(requestParameters: SavePlaceUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPlace> {
        const response = await this.savePlaceUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetPlaceCountUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPlaceListUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
