/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonEventDay
 */
export interface JsonEventDay {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventDay
     */
    allowedActions?: Array<JsonEventDayAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventDay
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDay
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDay
     */
    dayDate?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    dayNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDay
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    eventDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDay
     */
    openFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDay
     */
    status?: JsonEventDayStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDay
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    vipSold?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    voucherR?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDay
     */
    voucherZ?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventDayAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventDayStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonEventDayFromJSON(json: any): JsonEventDay {
    return JsonEventDayFromJSONTyped(json, false);
}

export function JsonEventDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'dayDate': !exists(json, 'dayDate') ? undefined : json['dayDate'],
        'dayNo': !exists(json, 'dayNo') ? undefined : json['dayNo'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'eventDayId': !exists(json, 'eventDayId') ? undefined : json['eventDayId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'openFrom': !exists(json, 'openFrom') ? undefined : json['openFrom'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'vipSold': !exists(json, 'vipSold') ? undefined : json['vipSold'],
        'voucherR': !exists(json, 'voucherR') ? undefined : json['voucherR'],
        'voucherZ': !exists(json, 'voucherZ') ? undefined : json['voucherZ'],
    };
}

export function JsonEventDayToJSON(value?: JsonEventDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'dayDate': value.dayDate,
        'dayNo': value.dayNo,
        'description': value.description,
        'eventDayId': value.eventDayId,
        'eventId': value.eventId,
        'openFrom': value.openFrom,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'vipSold': value.vipSold,
        'voucherR': value.voucherR,
        'voucherZ': value.voucherZ,
    };
}

