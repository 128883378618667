import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonContingent, ApiResultOfJsonContingentInfo, ContingentControllerApi, GetContingentCountUsingGETRequest, GetContingentListUsingGETRequest, JsonContingent, JsonContingentInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const contingentsApi = new ContingentControllerApi(API_CONFIG);

export const fetchContingents = createAsyncThunk('contingents/list', async (filter: GetContingentListUsingGETRequest, thunkApi) => {
    return {...await contingentsApi.getContingentListUsingGET(filter), filter};
});

export const fetchContingentsCount = createAsyncThunk('contingents/count', async (params: GetContingentCountUsingGETRequest = {}) => {
    return await contingentsApi.getContingentCountUsingGET(params);
});

export const fetchContingentInfo = createAsyncThunk('contingent/info', async (contingentId: number): Promise<ApiResultOfJsonContingentInfo> => {
    return await contingentsApi.getContingentInfoUsingGET({contingentId});
});

export const fetchContingent = createAsyncThunk('contingent/fetch', async (contingentId: number): Promise<ApiResultOfJsonContingent> => {
    return await contingentsApi.getContingentUsingGET({contingentId});
});

export const saveContingent = createAsyncThunk('contingent/save', async (contingent: JsonContingent) => {
    return await contingentsApi.saveContingentUsingPOST({json: contingent});
});

export const contingentsSlice = createSlice({
    name: 'contingents',
    initialState: (): ItemsState<JsonContingentInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchContingents, fetchContingentsCount);
    }
});

export const contingentsReducer = contingentsSlice.reducer;
