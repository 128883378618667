import {Grid} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {FormProps, setNestedKey} from "../../model/form";
import {JsonInvite} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import * as React from "react";
import {useCallback} from "react";
import {TextFormField} from "../form/TextFormField";
import {dateToGuiAs} from "../../helpers/date";
import {EventDaysField} from "../EventDaysField";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {inviteIsParkOptions} from "../../model/invite";

export interface InviteFormProps extends FormProps<JsonInvite> {
}

const InviteForm = (props: InviteFormProps) => {

    const t = useAppTranslation();

    const validate = useCallback((values: JsonInvite) => {
        let errors = {} as FormikErrors<JsonInvite>;

        const inviteData = values?.inviteData;
        if (!inviteData?.eventDays?.length) {
            setNestedKey(errors, 'inviteData.eventDays', t('Vyberte alespoň jeden den'));
        }
        if (!inviteData?.extraLimit && inviteData?.extraLimit !== 0) {
            setNestedKey(errors, 'inviteData.extraLimit', t('Zvolte doprovod'));
        }
        if (!(inviteData?.isPark === true || inviteData?.isPark === false)) {
            setNestedKey(errors, 'inviteData.isPark', t('Vyberte parkování'));
        }

        return errors;
    }, [t]);

    const children = useCallback(({values}: FormikProps<JsonInvite>) => {
        const {eventId} = values;
        return <>
            <Grid item xs={7}>
                <EventDaysField name="inviteData.eventDays" eventId={eventId}/>
            </Grid>
            <Grid item xs={5}>
                <TextFormField name="replyUntil" label={t('Uzávěrka')} type={'date'} maxlength={10} clearable
                    minValue={dateToGuiAs(new Date(), 'yyyy-MM-dd')}/>
            </Grid>
            <Grid item xs={7}>
                <TextFormField name="inviteData.extraLimit" label={'Doprovod max. osob'} type={'number'} minValue={0} maxValue={99}/>
            </Grid>
            <Grid item xs={5}>
                <ButtonGroupField name="inviteData.isPark" options={inviteIsParkOptions} fullWidth/>
            </Grid>
        </>;
    }, [t]);

    return <FormContainer {...props} validate={validate} children={children}/>;
}

export default InviteForm;
