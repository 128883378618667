/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFloorDayData,
    JsonFloorDayDataFromJSON,
    JsonFloorDayDataFromJSONTyped,
    JsonFloorDayDataToJSON,
} from './JsonFloorDayData';

/**
 * 
 * @export
 * @interface JsonEventFloorDayInfo
 */
export interface JsonEventFloorDayInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventFloorDayInfo
     */
    allowedActions?: Array<JsonEventFloorDayInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorDayInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDayInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorDayInfo
     */
    dayDate?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDayInfo
     */
    dayNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDayInfo
     */
    eventDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDayInfo
     */
    eventFloorDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDayInfo
     */
    eventFloorId?: number;
    /**
     * 
     * @type {JsonFloorDayData}
     * @memberof JsonEventFloorDayInfo
     */
    floorData?: JsonFloorDayData;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorDayInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorDayInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventFloorDayInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventFloorDayInfoFromJSON(json: any): JsonEventFloorDayInfo {
    return JsonEventFloorDayInfoFromJSONTyped(json, false);
}

export function JsonEventFloorDayInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventFloorDayInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'dayDate': !exists(json, 'dayDate') ? undefined : json['dayDate'],
        'dayNo': !exists(json, 'dayNo') ? undefined : json['dayNo'],
        'eventDayId': !exists(json, 'eventDayId') ? undefined : json['eventDayId'],
        'eventFloorDayId': !exists(json, 'eventFloorDayId') ? undefined : json['eventFloorDayId'],
        'eventFloorId': !exists(json, 'eventFloorId') ? undefined : json['eventFloorId'],
        'floorData': !exists(json, 'floorData') ? undefined : JsonFloorDayDataFromJSON(json['floorData']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventFloorDayInfoToJSON(value?: JsonEventFloorDayInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'dayDate': value.dayDate,
        'dayNo': value.dayNo,
        'eventDayId': value.eventDayId,
        'eventFloorDayId': value.eventFloorDayId,
        'eventFloorId': value.eventFloorId,
        'floorData': JsonFloorDayDataToJSON(value.floorData),
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

