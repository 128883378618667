/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonFile,
    ApiResultOfJsonFileFromJSON,
    ApiResultOfJsonFileToJSON,
    ApiResultOfJsonFileInfo,
    ApiResultOfJsonFileInfoFromJSON,
    ApiResultOfJsonFileInfoToJSON,
    ApiResultOfListOfJsonFileInfo,
    ApiResultOfListOfJsonFileInfoFromJSON,
    ApiResultOfListOfJsonFileInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonFile,
    JsonFileFromJSON,
    JsonFileToJSON,
} from '../models';

export interface GetFileCountUsingGETRequest {
    eventId?: number;
    partyId?: number;
    search?: string;
}

export interface GetFileInfoUsingGETRequest {
    fileId: number;
}

export interface GetFileListUsingGETRequest {
    eventId?: number;
    partyId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetFileUsingGETRequest {
    fileId: number;
}

export interface SaveAttachmentFileUsingPOSTRequest {
    attachment: Blob;
    fileType: SaveAttachmentFileUsingPOSTFileTypeEnum;
    inviteId?: number;
}

export interface SaveFileUsingPOSTRequest {
    json: JsonFile;
    testOnly?: boolean;
}

export interface SavePhotoFileUsingPOSTRequest {
    photo: Blob;
    partyId?: number;
}

/**
 * 
 */
export class FileControllerApi extends runtime.BaseAPI {

    /**
     * getFileCount
     */
    async getFileCountUsingGETRaw(requestParameters: GetFileCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyId !== undefined) {
            queryParameters['partyId'] = requestParameters.partyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/file/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getFileCount
     */
    async getFileCountUsingGET(requestParameters: GetFileCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getFileCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFileInfo
     */
    async getFileInfoUsingGETRaw(requestParameters: GetFileInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFileInfo>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling getFileInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/file/info/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFileInfoFromJSON(jsonValue));
    }

    /**
     * getFileInfo
     */
    async getFileInfoUsingGET(requestParameters: GetFileInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFileInfo> {
        const response = await this.getFileInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFileList
     */
    async getFileListUsingGETRaw(requestParameters: GetFileListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonFileInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.partyId !== undefined) {
            queryParameters['partyId'] = requestParameters.partyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/file/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonFileInfoFromJSON(jsonValue));
    }

    /**
     * getFileList
     */
    async getFileListUsingGET(requestParameters: GetFileListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonFileInfo> {
        const response = await this.getFileListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFile
     */
    async getFileUsingGETRaw(requestParameters: GetFileUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFile>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling getFileUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFileFromJSON(jsonValue));
    }

    /**
     * getFile
     */
    async getFileUsingGET(requestParameters: GetFileUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFile> {
        const response = await this.getFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveAttachmentFile
     */
    async saveAttachmentFileUsingPOSTRaw(requestParameters: SaveAttachmentFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFile>> {
        if (requestParameters.attachment === null || requestParameters.attachment === undefined) {
            throw new runtime.RequiredError('attachment','Required parameter requestParameters.attachment was null or undefined when calling saveAttachmentFileUsingPOST.');
        }

        if (requestParameters.fileType === null || requestParameters.fileType === undefined) {
            throw new runtime.RequiredError('fileType','Required parameter requestParameters.fileType was null or undefined when calling saveAttachmentFileUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.attachment !== undefined) {
            formParams.append('attachment', requestParameters.attachment as any);
        }

        if (requestParameters.inviteId !== undefined) {
            formParams.append('inviteId', requestParameters.inviteId as any);
        }

        if (requestParameters.fileType !== undefined) {
            formParams.append('fileType', requestParameters.fileType as any);
        }

        const response = await this.request({
            path: `/rest-service/file/attachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFileFromJSON(jsonValue));
    }

    /**
     * saveAttachmentFile
     */
    async saveAttachmentFileUsingPOST(requestParameters: SaveAttachmentFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFile> {
        const response = await this.saveAttachmentFileUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveFile
     */
    async saveFileUsingPOSTRaw(requestParameters: SaveFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFile>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveFileUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonFileToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFileFromJSON(jsonValue));
    }

    /**
     * saveFile
     */
    async saveFileUsingPOST(requestParameters: SaveFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFile> {
        const response = await this.saveFileUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * savePhotoFile
     */
    async savePhotoFileUsingPOSTRaw(requestParameters: SavePhotoFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFile>> {
        if (requestParameters.photo === null || requestParameters.photo === undefined) {
            throw new runtime.RequiredError('photo','Required parameter requestParameters.photo was null or undefined when calling savePhotoFileUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.partyId !== undefined) {
            formParams.append('partyId', requestParameters.partyId as any);
        }

        if (requestParameters.photo !== undefined) {
            formParams.append('photo', requestParameters.photo as any);
        }

        const response = await this.request({
            path: `/rest-service/file/photo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFileFromJSON(jsonValue));
    }

    /**
     * savePhotoFile
     */
    async savePhotoFileUsingPOST(requestParameters: SavePhotoFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFile> {
        const response = await this.savePhotoFileUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SaveAttachmentFileUsingPOSTFileTypeEnum {
    Photo = 'PHOTO',
    Image = 'IMAGE',
    Attachment = 'ATTACHMENT',
    Travel = 'TRAVEL',
    Contract = 'CONTRACT'
}
