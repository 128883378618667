/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEventTicket,
    ApiResultOfJsonEventTicketFromJSON,
    ApiResultOfJsonEventTicketToJSON,
    ApiResultOfJsonEventTicketInfo,
    ApiResultOfJsonEventTicketInfoFromJSON,
    ApiResultOfJsonEventTicketInfoToJSON,
    ApiResultOfListOfJsonEventTicket,
    ApiResultOfListOfJsonEventTicketFromJSON,
    ApiResultOfListOfJsonEventTicketToJSON,
    ApiResultOfListOfJsonEventTicketInfo,
    ApiResultOfListOfJsonEventTicketInfoFromJSON,
    ApiResultOfListOfJsonEventTicketInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEventTicket,
    JsonEventTicketFromJSON,
    JsonEventTicketToJSON,
    JsonEventTicketMassActionRequest,
    JsonEventTicketMassActionRequestFromJSON,
    JsonEventTicketMassActionRequestToJSON,
} from '../models';

export interface GetEventTicketCountUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    ticketTypes?: Array<GetEventTicketCountUsingGETTicketTypesEnum>;
    reasonSearch?: string;
    formatCodeSearch?: string;
    areaSearch?: string;
    areas?: Array<string>;
    notAreas?: Array<string>;
    notEventDays?: Array<number>;
    contingents?: Array<string>;
    statuses?: Array<GetEventTicketCountUsingGETStatusesEnum>;
    search?: string;
}

export interface GetEventTicketInfoUsingGETRequest {
    ticketId: number;
}

export interface GetEventTicketListUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    ticketTypes?: Array<GetEventTicketListUsingGETTicketTypesEnum>;
    reasonSearch?: string;
    formatCodeSearch?: string;
    areaSearch?: string;
    areas?: Array<string>;
    notAreas?: Array<string>;
    notEventDays?: Array<number>;
    contingents?: Array<string>;
    statuses?: Array<GetEventTicketListUsingGETStatusesEnum>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventTicketUsingGETRequest {
    ticketId: number;
}

export interface MassEventTicketActionUsingPOSTRequest {
    request: JsonEventTicketMassActionRequest;
    testOnly?: boolean;
}

export interface SaveEventTicketUsingPOSTRequest {
    json: JsonEventTicket;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventTicketControllerApi extends runtime.BaseAPI {

    /**
     * getEventTicketCount
     */
    async getEventTicketCountUsingGETRaw(requestParameters: GetEventTicketCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.ticketTypes) {
            queryParameters['ticketTypes'] = requestParameters.ticketTypes;
        }

        if (requestParameters.reasonSearch !== undefined) {
            queryParameters['reasonSearch'] = requestParameters.reasonSearch;
        }

        if (requestParameters.formatCodeSearch !== undefined) {
            queryParameters['formatCodeSearch'] = requestParameters.formatCodeSearch;
        }

        if (requestParameters.areaSearch !== undefined) {
            queryParameters['areaSearch'] = requestParameters.areaSearch;
        }

        if (requestParameters.areas) {
            queryParameters['areas'] = requestParameters.areas;
        }

        if (requestParameters.notAreas) {
            queryParameters['notAreas'] = requestParameters.notAreas;
        }

        if (requestParameters.notEventDays) {
            queryParameters['notEventDays'] = requestParameters.notEventDays;
        }

        if (requestParameters.contingents) {
            queryParameters['contingents'] = requestParameters.contingents;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-ticket/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventTicketCount
     */
    async getEventTicketCountUsingGET(requestParameters: GetEventTicketCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventTicketCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventTicketInfo
     */
    async getEventTicketInfoUsingGETRaw(requestParameters: GetEventTicketInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventTicketInfo>> {
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId','Required parameter requestParameters.ticketId was null or undefined when calling getEventTicketInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-ticket/info/{ticketId}`.replace(`{${"ticketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventTicketInfoFromJSON(jsonValue));
    }

    /**
     * getEventTicketInfo
     */
    async getEventTicketInfoUsingGET(requestParameters: GetEventTicketInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventTicketInfo> {
        const response = await this.getEventTicketInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventTicketList
     */
    async getEventTicketListUsingGETRaw(requestParameters: GetEventTicketListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventTicketInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.ticketTypes) {
            queryParameters['ticketTypes'] = requestParameters.ticketTypes;
        }

        if (requestParameters.reasonSearch !== undefined) {
            queryParameters['reasonSearch'] = requestParameters.reasonSearch;
        }

        if (requestParameters.formatCodeSearch !== undefined) {
            queryParameters['formatCodeSearch'] = requestParameters.formatCodeSearch;
        }

        if (requestParameters.areaSearch !== undefined) {
            queryParameters['areaSearch'] = requestParameters.areaSearch;
        }

        if (requestParameters.areas) {
            queryParameters['areas'] = requestParameters.areas;
        }

        if (requestParameters.notAreas) {
            queryParameters['notAreas'] = requestParameters.notAreas;
        }

        if (requestParameters.notEventDays) {
            queryParameters['notEventDays'] = requestParameters.notEventDays;
        }

        if (requestParameters.contingents) {
            queryParameters['contingents'] = requestParameters.contingents;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-ticket/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventTicketInfoFromJSON(jsonValue));
    }

    /**
     * getEventTicketList
     */
    async getEventTicketListUsingGET(requestParameters: GetEventTicketListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventTicketInfo> {
        const response = await this.getEventTicketListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventTicket
     */
    async getEventTicketUsingGETRaw(requestParameters: GetEventTicketUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventTicket>> {
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId','Required parameter requestParameters.ticketId was null or undefined when calling getEventTicketUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event-ticket/{ticketId}`.replace(`{${"ticketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventTicketFromJSON(jsonValue));
    }

    /**
     * getEventTicket
     */
    async getEventTicketUsingGET(requestParameters: GetEventTicketUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventTicket> {
        const response = await this.getEventTicketUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * massEventTicketAction
     */
    async massEventTicketActionUsingPOSTRaw(requestParameters: MassEventTicketActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventTicket>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling massEventTicketActionUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-ticket/mass`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventTicketMassActionRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventTicketFromJSON(jsonValue));
    }

    /**
     * massEventTicketAction
     */
    async massEventTicketActionUsingPOST(requestParameters: MassEventTicketActionUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventTicket> {
        const response = await this.massEventTicketActionUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEventTicket
     */
    async saveEventTicketUsingPOSTRaw(requestParameters: SaveEventTicketUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventTicket>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventTicketUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event-ticket`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventTicketToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventTicketFromJSON(jsonValue));
    }

    /**
     * saveEventTicket
     */
    async saveEventTicketUsingPOST(requestParameters: SaveEventTicketUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventTicket> {
        const response = await this.saveEventTicketUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetEventTicketCountUsingGETTicketTypesEnum {
    SiwiVoucher = 'SIWI_VOUCHER',
    EgTicket = 'EG_TICKET'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventTicketCountUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventTicketListUsingGETTicketTypesEnum {
    SiwiVoucher = 'SIWI_VOUCHER',
    EgTicket = 'EG_TICKET'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventTicketListUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
