import {Alert, Box, Button, Container, Grid, LinearProgress, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppTranslation} from '../services/i18n';
import {fetchParty, fetchPartyInfo} from '../store/parties';
import {useParams} from "react-router-dom";
import {
    JsonArticleInfo,
    JsonArticleTypeInfo,
    JsonAuthRequestTokenTypeEnum,
    JsonEmailMessage,
    JsonEventInfo,
    JsonEventInfoStatusEnum,
    JsonEventPartyImportSexEnum,
    JsonEventPartyInfo,
    JsonEventPartyInfoContractStatusEnum,
    JsonEventPartyInfoSiwiAccredStatusEnum,
    JsonEventPartyInfoStatusEnum,
    JsonEventPartyMassActionRequestActionEnum,
    JsonEventPartyMassActionRequestUpdateTypeEnum,
    JsonFileInfo,
    JsonFileInfoFileTypeEnum,
    JsonParty,
    JsonPartyInfo,
    JsonPartyInfoPartyTypeEnum,
    JsonPartyPartyTypeEnum,
    JsonPlaceFoodServicesEnum,
    JsonTariffInfo,
    JsonUser,
    JsonUserInfo,
    PreviewNotificationUsingPOSTNotificationTypeEnum
} from '../generated-api';
import {useAppDispatch, useAppSelector} from "../store";
import PageHeader from "../components/layout/PageHeader";
import InfoBox, {InfoBoxAction} from "../components/layout/InfoBox";
import {getApiResult, showApiMessage} from "../helpers/api";
import PartyModal from "./PartyModal";
import {articleName, eventPartyContractStatusOptions, parkPlates, PartyMassActionValues, partyName, renderAccredStatus} from "../model/party";
import {fetchEventPartiesOnline} from "../store/eventParties";
import CodebookValue from "../components/CodebookValue";
import {OrderDirType} from "../components/DataGrid";
import PartyInfoBox from "../components/party/PartyInfoBox";
import {
    AttachMoneyRounded,
    CalendarMonthRounded,
    CheckroomRounded,
    CommuteRounded,
    DeleteForeverOutlined,
    EditRounded,
    EmailRounded,
    EventAvailableRounded,
    GroupRounded,
    HowToRegRounded,
    InterpreterModeRounded,
    ManageAccountsRounded,
    OpenInNewRounded,
    PriceCheckRounded,
    SendRounded,
    WorkspacePremiumRounded
} from "@mui/icons-material";
import {ModalProps, useModal} from "../services/modal";
import {useNavigate} from "react-router";
import EventDaysValue from "../components/EventDaysValue";
import {foodServiceOptions, foodServiceProps} from "../model/place";
import {createOptions, getOption} from "../model/form";
import {CodebookState, fetchCodebooks, ROLE_ADMIN, ROLE_SUPERVISOR} from "../store/codebooks";
import {selectAuthInfo, selectCodebooks} from "../store/selectors";
import {fetchArticleTypes} from "../store/articleTypes";
import {fetchArticles} from "../store/articles";
import {fetchEvents} from "../store/events";
import PartyMassActionModal, {createMassAction} from "./PartyMassActionModal";
import {previewNotification} from "../store/notifications";
import {EmailPreview} from "../components/EmailPreview";
import {AuthState, forgottenPassword} from "../store/auth";
import PartyInvitesList from "../components/party/PartyInvitesList";
import EventPartyAccredDetail from "../components/EventPartyAccredDetail";
import {fetchUser, fetchUserInfo} from "../store/users";
import {fetchTariffs} from "../store/tariffs";
import {PartyCompDetails} from "../components/party/PartyCompDetails";
import {fetchFiles} from "../store/files";
import {renderFile} from "../helpers/files";
import EventDay from "../components/seating/EventDay";

type EventArticleAndCompState = {
    articleTypes?: JsonArticleTypeInfo[],
    articles?: JsonArticleInfo[],
    tariffs?: JsonTariffInfo[],
    files?: JsonFileInfo[]
}

type EventPartyProps = {
    eventId: number,
    eventParty: JsonEventPartyInfo | undefined,
    party: JsonPartyInfo,
    handleMassAction: (action: JsonEventPartyMassActionRequestActionEnum, eventId: number, updateType?: JsonEventPartyMassActionRequestUpdateTypeEnum) => void,
    readonly?: boolean,
    onSave: () => void,
    isAdmin: boolean
}

const EventParty = (props: EventPartyProps) => {
    const {party, eventId, eventParty, handleMassAction, readonly, onSave, isAdmin} = props;
    const {partyId, partyType} = party;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const codebooks = useAppSelector<CodebookState>(selectCodebooks);

    const [articleState, setArticleState] = useState<EventArticleAndCompState>({});
    const {articleTypes, articles, tariffs, files} = articleState;

    const handleFetchArticles = useCallback(async () => {
        const as: EventArticleAndCompState = {};
        as.articleTypes = getApiResult<JsonArticleTypeInfo[]>(await dispatch(fetchArticleTypes({eventId})));
        as.articles = getApiResult<JsonArticleInfo[]>(await dispatch(fetchArticles({eventId})));
        as.tariffs = getApiResult<JsonTariffInfo[]>(await dispatch(fetchTariffs({eventId})));
        as.files = getApiResult<JsonFileInfo[]>(await dispatch(fetchFiles({eventId, partyId})));
        setArticleState(as);
    }, [eventId, partyId, dispatch]);

    useEffect(() => {
        if (eventParty) {
            handleFetchArticles().then();
        }
    }, [eventParty, handleFetchArticles]);


    const isActive = eventParty?.status && eventParty.status !== JsonEventPartyInfoStatusEnum.Deleted;
    const registerBox = isActive || readonly
        ? null
        : <InfoBox title={undefined} actions={isAdmin ? [{
            title: 'Pozvat jako hosta',
            color: 'primary', action: () => {
                handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteGuest, eventId);
            },
            icon: <WorkspacePremiumRounded/>
        }, {
            title: 'Pozvat jako organizátora',
            color: 'primary', action: () => {
                handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteOrg, eventId);
            },
            icon: <ManageAccountsRounded/>
        }, {
            title: 'Pozvat jako dodavatele',
            color: 'primary', action: () => {
                handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteSupp, eventId);
            },
            icon: <InterpreterModeRounded/>
        }, {
            title: 'Přidat do události',
            color: 'primary', action: () => {
                handleMassAction(JsonEventPartyMassActionRequestActionEnum.Register, eventId);
            },
            icon: <EventAvailableRounded/>
        }] : undefined}>
            {isAdmin ? null : t('Osoba není v události, nebo není ve vaší skupině.')}
        </InfoBox>;

    const daysActions = useMemo(() => {
        if (readonly || !eventParty) {
            return undefined;
        }
        const daysActions: InfoBoxAction[] = [{
            title: 'Upravit dny',
            color: 'primary',
            action: () => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Update, eventId, JsonEventPartyMassActionRequestUpdateTypeEnum.Days),
            icon: <CalendarMonthRounded/>
        }]

        if (isAdmin && (!eventParty.siwiAccredStatus || eventParty.siwiAccredStatus === JsonEventPartyInfoSiwiAccredStatusEnum.Deleted)) {
            daysActions.unshift({
                title: 'Odstranit z události',
                tooltip: 'Odstranit osobu z události',
                color: 'inherit',
                action: () => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Delete, eventId, JsonEventPartyMassActionRequestUpdateTypeEnum.Days),
                icon: <DeleteForeverOutlined/>
            });
        }
        return daysActions;
    }, [eventParty, readonly, isAdmin, eventId, handleMassAction]);

    if (!partyId || !eventParty) {
        return <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {registerBox}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>;
    }

    const contractStatusOption = getOption(eventParty.contractStatus || JsonEventPartyInfoContractStatusEnum.None, eventPartyContractStatusOptions);
    const contractFile = files?.find((f) => f.fileType === JsonFileInfoFileTypeEnum.Contract);
    const isShowContract = eventParty.contractStatus === JsonEventPartyInfoContractStatusEnum.Signed
        || !!eventParty.eventArticleDetails?.find((a) => !!a.recArticleId)
        || !!contractFile;
    const isOrg = partyType !== JsonPartyInfoPartyTypeEnum.T && !!codebooks?.['groupOrg']?.find((p) => p.value === '' + eventParty?.groupId);

    const actions: InfoBoxAction[] | undefined = !isActive || readonly ? undefined : isAdmin ? [{
        title: 'Host',
        tooltip: 'Pozvat jako VIP hosta',
        color: 'primary', action: () => {
            handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteGuest, eventId);
        },
        icon: <WorkspacePremiumRounded/>
    }, {
        title: 'Org',
        tooltip: 'Pozvat jako organizátora',
        color: 'primary', action: () => {
            handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteOrg, eventId);
        },
        icon: <ManageAccountsRounded/>
    }, {
        title: 'Dodavatel',
        tooltip: 'Pozvat jako dodavatele',
        color: 'primary', action: () => {
            handleMassAction(JsonEventPartyMassActionRequestActionEnum.InviteSupp, eventId);
        },
        icon: <InterpreterModeRounded/>
    }] : [];

    if (actions && isOrg) {
        actions.push({
            title: 'Cesty',
            tooltip: 'Vyzvak k zadání cestovních náhrad',
            color: 'primary', action: () => {
                handleMassAction(JsonEventPartyMassActionRequestActionEnum.TravelOrg, eventId);
            },
            icon: <CommuteRounded/>
        });
        if (eventParty.eventCompDetails?.length && eventParty.eventCompDetails?.length > 0) {
            actions.push({
                title: 'Potvr.',
                tooltip: 'Vyzvat k potvrzení výplaty',
                color: 'primary', action: () => {
                    handleMassAction(JsonEventPartyMassActionRequestActionEnum.CompOrg, eventId);
                },
                icon: <PriceCheckRounded/>
            });
        }
    }

    return <div>
        <Grid container spacing={2}>
            <Grid item md={6}>
                {!isActive && registerBox}
                {!isActive && <Alert severity={'warning'}>{t('Osoba byla z události odstraněna.')}</Alert>}
                {isActive && <Grid container spacing={2}>
                    {partyType !== JsonPartyInfoPartyTypeEnum.T && <Grid item xs={12}>
						<InfoBox title={t('Upravit akreditaci')} actions={readonly || !isAdmin ? undefined : [{
                            title: 'Akreditovat',
                            color: 'primary',
                            action: () => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Accred, eventId),
                            icon: <HowToRegRounded/>
                        }]}>
							<table className={'info-box-table'}>
								<tbody>
								<tr>
									<th style={{width: '10%'}}>{t('Formát')}</th>
									<td style={{width: '15%'}}><EventPartyAccredDetail item={eventParty} col={'formatCode'}/></td>
									<th style={{width: '10%'}}>{t('Zóny')}</th>
									<td style={{width: '65%'}} colSpan={3}><EventPartyAccredDetail item={eventParty} col={'areas'}/></td>
								</tr>
								<tr>
									<th>{t('Kont.')}</th>
									<td><EventPartyAccredDetail item={eventParty} col={'contingentOwner'}/></td>
									<th>{t('Reason')}</th>
									<td colSpan={3}><EventPartyAccredDetail item={eventParty} col={'reason'}/></td>
								</tr>
								<tr>
									<th>{t('Stav')}</th>
									<td>{renderAccredStatus(eventParty.siwiAccredStatus, eventParty)}</td>
									<th>{t('Dny')}</th>
									<td><EventDaysValue eventDays={eventParty.eventDays} eventId={eventId} siwiEventDays={eventParty.siwiEventDays}/></td>
									<th style={{width: '7%'}}>{t('QR')}</th>
									<th style={{width: '20%'}}><pre>{eventParty.siwiBarcode}</pre></th>
								</tr>
								</tbody>
							</table>
						</InfoBox>
					</Grid>}
					<Grid item md={12}>
						<InfoBox title={t('Ubytování, strava, parkování')} actions={daysActions}>
							<TableContainer component={Paper} sx={{order: 50}}>
								<Table className={'data-grid'}>
									<TableHead>
										<TableRow>
											<TableCell style={{width: '6%', textAlign: 'center'}}><span>{t('Den')}</span></TableCell>
                                            {foodServiceOptions.map((fs, i) => <TableCell key={i} style={
                                                {width: Math.round(58 / foodServiceOptions.length) + '%', textAlign: 'center'}}><span>{fs.label}</span></TableCell>)}
											<TableCell style={{width: '17%', textAlign: 'center'}}><span>{t('Ubytování')}</span></TableCell>
											<TableCell style={{width: '19%', textAlign: 'center'}}><span>{t('Parkování')}</span></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
                                        {!!codebooks && createOptions({codebookName: 'eventDay', scope: eventParty.eventId, asNumber: true, sortByKeys: true}, codebooks)
                                            .filter((o) => eventParty?.eventDays?.[o.label as any as number - 1] === '1' || eventParty.realEventDays?.[o.label as any as number - 1] === '1')
                                            .map((o, i) => {
                                                const ed = eventParty.eventDayDetails?.find((ed) => ed.dayNo + '' === o.label);
                                                const dayNo = parseInt(o.label);
                                                const realDay = eventParty.realEventDays?.[dayNo - 1] === '1';
                                                return <TableRow key={i}>
                                                    <TableCell className={'form-grid-checkbox'}
                                                        style={realDay ? {color: ( eventParty?.eventDays?.[dayNo - 1] === '1' ? 'var(--green)' : 'var(--yellow)')} : undefined}>
                                                        <EventDay dayNo={dayNo} eventId={eventId}/>
                                                    </TableCell>
                                                    {!!ed ? <>
                                                        {foodServiceOptions.map((fs, j) => {
                                                            const serviceType = fs.value as JsonPlaceFoodServicesEnum;
                                                            const foodProp = foodServiceProps[serviceType];
                                                            const used = ed.foodData?.log?.filter(l => l.serviceType as any === serviceType as any)?.length;
                                                            return <TableCell key={j} style={{textAlign: 'center'}}>
                                                            <span style={!!used ? {color: 'var(--green)'} : undefined}>
                                                                <CodebookValue value={ed[foodProp]} name={'placeFood'} scope={eventParty.eventId}/>
                                                                {!!ed[foodProp] && !!ed.foodCnt?.[serviceType] && <div> ({ed.foodCnt?.[serviceType]}x)</div>}
                                                            </span>
                                                            </TableCell>
                                                        })}
                                                        <TableCell sx={{textAlign: 'center'}}>
                                                            <span>
                                                                <CodebookValue value={ed.acmId} name={'placeAcm'} scope={eventParty.eventId}/>
                                                                {ed.acmCnt && ed.acmCnt > 1 ? ' (' + ed.acmCnt + 'x)' : null}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell sx={{textAlign: 'center'}}>
                                                            <span style={!!ed.parkRec
                                                                ? (ed.parkRec >= (ed.parkCnt || 1)
                                                                    ? {color: 'var(--green)'}
                                                                    : {color: 'var(--yellow)'})
                                                                : undefined
                                                            } title={t('Vydáno: {{a}} / {{b}}', {a: ed.parkRec || 0, b: ed.parkCnt || 1})}>
                                                                <CodebookValue value={ed.parkId} name={'placePark'} scope={eventParty.eventId}/>
                                                                {ed.parkCnt && ed.parkCnt > 1 ? ' (' + ed.parkCnt + 'x)' : null}
                                                            </span>
                                                            {!!ed.parkPlates && <div>
																<pre className={'event-days'}>{parkPlates(ed.parkPlates)}</pre>
															</div>}
                                                        </TableCell>
                                                    </> : <TableCell colSpan={3 + foodServiceOptions.length}>
                                                        <div style={{minHeight: '34px'}}>&nbsp;</div>
                                                    </TableCell>}
                                                </TableRow>;
                                            })}
									</TableBody>
								</Table>
							</TableContainer>
						</InfoBox>
					</Grid>
				</Grid>}
            </Grid>
            <Grid item md={6}>
                <Grid container spacing={2}>
                    {isActive && <Grid item xs={12}>
						<InfoBox title={<Grid container spacing={2} style={{fontSize: 'var(--font-size)', flexGrow: 1}}>
                            <Grid item>{t('Skupina: ')}{eventParty.groupId
                                ? <strong><CodebookValue value={eventParty.groupId} name={'group'}/></strong>
                                : t('bez skupiny')}</Grid>
                            {eventParty.prefAcm !== undefined && <Grid item>{t('Ubytování')}: <strong
								title={eventParty.prefAcm ? t('Má zájem o ubytování') : t('Nemá zájem o ubytování')}>
                                {eventParty.prefAcm ? t('Zájem') : t('Nezájem')}</strong></Grid>}
                            {isOrg && <Grid item>
								<a href={'/info/' + eventParty.eventPartyGuid} target={'_blank'} rel="noreferrer" title={t('Dashboard organizátora')}>
									<OpenInNewRounded sx={{fontSize: '100%', margin: '0 5px -2px 0'}}/>
									<span>{t('Dashboard')}</span></a></Grid>}
                        </Grid>}
							actions={readonly || !isAdmin ? undefined : [{
                                title: 'Upravit',
                                color: 'primary',
                                action: () => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Update, eventId, JsonEventPartyMassActionRequestUpdateTypeEnum.Group),
                                icon: <GroupRounded/>
                            }]}>
						</InfoBox>
					</Grid>}
                    <PartyInvitesList key={JSON.stringify([eventParty.inviteId, eventParty.updateInviteId])} eventId={eventId} partyId={partyId} onSave={onSave}
                        actions={actions}/>
                    {isActive && !!articleTypes?.length && articleTypes.length > 0 && <Grid item xs={12}>
						<InfoBox title={t('Artikly')} actions={readonly || !isAdmin ? undefined : [{
                            title: 'Upravit artikly',
                            color: 'primary',
                            action: () => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Update, eventId, JsonEventPartyMassActionRequestUpdateTypeEnum.Article),
                            icon: <CheckroomRounded/>
                        }]}>
							<TableContainer component={Paper} sx={{order: 50}}>
								<Table className={'data-grid'}>
									<TableHead>
										<TableRow>
											<TableCell sx={{width: articleTypes.length > 4 ? '100px' : '180px'}}>
											</TableCell>
                                            {articleTypes.map((at, i) => {
                                                return <TableCell key={i}>
                                                    <span>{at.title}</span>
                                                </TableCell>
                                            })}
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell>
												<span>{t('Preference')}</span>
											</TableCell>
                                            {articleTypes.map((at, i) => {
                                                const prefArticleId = eventParty.eventArticleDetails?.find((ad) => ad.articleTypeId === at.articleTypeId)?.prefArticleId;
                                                return <TableCell key={i}>
                                                    {prefArticleId
                                                        ? articleName(articles?.find((a) => a.articleId === prefArticleId), true, party.sex ? JsonEventPartyImportSexEnum[party.sex] : undefined)
                                                        : null}
                                                </TableCell>;
                                            })}
										</TableRow>
										<TableRow>
											<TableCell>
												<span>{t('Vydáno')}</span>
											</TableCell>
                                            {articleTypes.map((at, i) => {
                                                const recArticleId = eventParty.eventArticleDetails?.find((ad) => ad.articleTypeId === at.articleTypeId)?.recArticleId;
                                                return <TableCell key={i}>
                                                    {recArticleId
                                                        ? articleName(articles?.find((a) => a.articleId === recArticleId), true, party.sex ? JsonEventPartyImportSexEnum[party.sex] : undefined)
                                                        : null}
                                                </TableCell>;
                                            })}
										</TableRow>
                                        {isShowContract && <TableRow>
											<TableCell colSpan={1 + articleTypes.length}>
                                                {(eventParty.contractStatus === JsonEventPartyInfoContractStatusEnum.Signed || eventParty.eventArticleDetails?.find((a) => !!a.recArticleId))
                                                    && <span title={contractStatusOption.tooltip} style={!eventParty.contractStatus && eventParty.eventArticleDetails?.find((a) => !!a.recArticleId)
                                                        ? {color: 'var(--color-error'} : undefined}>
                                                        {t('Smlouva:')}&nbsp;
														<strong>{contractStatusOption.label}</strong>
                                                </span>}
                                                {!!contractFile && <span>{renderFile(contractFile)}</span>}
											</TableCell>
										</TableRow>}
									</TableBody>
								</Table>
							</TableContainer>
						</InfoBox>
					</Grid>}
                    {isActive && <Grid item xs={12}>
						<InfoBox title={t('Odměny / náhrady')} actions={readonly ? undefined : [{
                            title: 'Upravit odměny',
                            color: 'primary',
                            action: () => handleMassAction(JsonEventPartyMassActionRequestActionEnum.Comp, eventId),
                            icon: <AttachMoneyRounded/>
                        }]}>
							<PartyCompDetails eventParty={eventParty} tariffs={tariffs}/>
						</InfoBox>
					</Grid>}
                </Grid>
            </Grid>
        </Grid>
    </div>
}

const PartyPage = () => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const modal = useModal();

    const {user: authUser, configuration} = useAppSelector<AuthState>(selectAuthInfo);
    const isAdmin = authUser?.roleId === ROLE_ADMIN;

    const [party, setParty] = useState<JsonPartyInfo | undefined>(undefined);
    const [events, setEvents] = useState<JsonEventInfo[] | undefined>(undefined);
    const [eventParties, setEventParties] = useState<JsonEventPartyInfo[] | undefined>(undefined);
    const [user, setUser] = useState<JsonUserInfo | undefined>(undefined);
    const [editParty, setEditParty] = useState<JsonParty | undefined>(undefined);
    const [editPartyContact, setEditPartyContact] = useState<JsonParty | undefined>(undefined);
    const [eventId, setEventId] = useState<number | undefined>(params.eventId
        ? parseInt(params.eventId, 10)
        : configuration?.defaultEvent?.eventId);
    const [massAction, setMassAction] = useState<PartyMassActionValues<any> | undefined>(undefined);

    const partyId = params.partyId ? parseInt(params.partyId, 10) : undefined;
    const userId = party?.userId;
    const defaultReplyUntilOrg = configuration?.defaultEvent?.eventData?.replyUntilOrg;
    const defaultReplyUntilGuest = configuration?.defaultEvent?.eventData?.replyUntilGuest;

    const handleFetchParty = useCallback(async () => {
        if (partyId) {
            const party = getApiResult<JsonPartyInfo>(await dispatch(fetchPartyInfo(partyId)));
            if (!party?.partyId) {
                navigate('/parties');
                return;
            }
            const events = getApiResult<JsonEventInfo[]>(await dispatch(fetchEvents({orderCol: 'eventId', orderDir: 'desc'})));
            const ep = getApiResult<JsonEventPartyInfo[]>(await dispatch(fetchEventPartiesOnline({partyIds: [partyId], orderCol: 'eventId', orderDir: OrderDirType.DESC})));
            setParty(party);
            setEventParties(ep);
            setEvents(events);
            if (party.userId && (isAdmin || authUser?.userId === party.userId)) {
                setUser(getApiResult(await dispatch(fetchUserInfo(party.userId))));
            }
            // if (!eventId) {
            //     setEventId(FIXED_EVENT_ID); // events?.[0].eventId)
            // }

        } else {
            setParty({});
            setEditParty({partyType: JsonPartyPartyTypeEnum.Fo});
        }

    }, [authUser, isAdmin, partyId, dispatch, navigate]);

    const handleEditParty = useCallback(async () => {
        if (partyId) {
            const item = getApiResult<JsonParty>(await dispatch(fetchParty(partyId)));
            if (item && !!userId) {
                item.user = getApiResult<JsonUser>(await dispatch(fetchUser(userId)));
            }
            setEditParty(item);
        } else {
            setEditParty({partyId});
        }
    }, [partyId, userId, dispatch]);

    // const handleEditPartyContact = useCallback(() => {
    //     if (partyId) {
    //         dispatch(fetchParty(partyId)).then((res) => setEditPartyContact(getApiResult(res)));
    //     } else {
    //         setEditPartyContact({partyId});
    //     }
    // }, [partyId, dispatch]);
    //
    const handleSaveParty = useCallback((item: JsonParty) => {
        setEditParty(undefined);
        setEditPartyContact(undefined);
        if (!partyId) {
            navigate('/parties/' + item.partyId);
            return;
        }
        handleFetchParty().then();

    }, [partyId, handleFetchParty, navigate]);

    const handleCancelParty = useCallback(() => {
        setEditParty(undefined);
        setEditPartyContact(undefined);
        if (!partyId) {
            navigate('/parties');
        }
    }, [partyId, navigate]);

    const handleMassAction = useCallback(async (action: JsonEventPartyMassActionRequestActionEnum, eventId: number, updateType?: JsonEventPartyMassActionRequestUpdateTypeEnum) => {
        if (!partyId) {
            return;
        }
        if (action === JsonEventPartyMassActionRequestActionEnum.Update
            || action === JsonEventPartyMassActionRequestActionEnum.Delete
            || action === JsonEventPartyMassActionRequestActionEnum.TravelOrg
            || action === JsonEventPartyMassActionRequestActionEnum.CompOrg
            || action === JsonEventPartyMassActionRequestActionEnum.Accred
            || action === JsonEventPartyMassActionRequestActionEnum.Comp) {
            dispatch(fetchEventPartiesOnline({partyIds: [partyId], eventId})).then((res) => {
                setMassAction({
                    filter: {},
                    parties: [],
                    eventParties: getApiResult(res) || [],
                    values: {
                        updateType,
                        eventId,
                        action,
                        items: []
                    }
                });
            });
        } else {
            const party = getApiResult<JsonPartyInfo>(await dispatch(fetchPartyInfo(partyId)));
            if (party) {
                setMassAction(createMassAction(action, eventId, action === JsonEventPartyMassActionRequestActionEnum.InviteGuest
                    ? defaultReplyUntilGuest
                    : defaultReplyUntilOrg, {}, [party]));
            }
        }
    }, [partyId, defaultReplyUntilOrg, defaultReplyUntilGuest, dispatch]);

    const handleSaveMassAction = useCallback(async () => {
        setMassAction(undefined);
        handleFetchParty().then();
    }, [handleFetchParty])

    const handleCancelMassAction = useCallback(() => {
        setMassAction(undefined);
    }, [])

    const handlePasswordEmail = useCallback(async () => {
        if (!party?.partyId) {
            return;
        }
        const notificationType = !party.userId
            ? PreviewNotificationUsingPOSTNotificationTypeEnum.UserActivate
            : PreviewNotificationUsingPOSTNotificationTypeEnum.UserRecover;
        const email = getApiResult<JsonEmailMessage>(await dispatch(previewNotification({
            request: {
                user: {userId: party.userId, partyId: party.partyId, email: party.email}
            },
            notificationType
        })));

        const result = await modal.confirm({
            title: !!party?.userId
                ? t('Potvrzení yodeslání obnovy hesla')
                : t('Potvrzení založení přístupu'),
            message: <div>
                <p>{!!party?.userId
                    ? t('Skutečně odeslat instrukce k nastavení hesla?')
                    : t('Skutečně založit uživatelský přístup a odeslat instrukce k nastavení hesla?')}</p>
                <EmailPreview email={email} showRecipient={true}/>
            </div>,
            cancelText: 'Zpět',
            confirmColor: 'success',
            confirmText: !!party?.userId
                ? t('Odeslat obnovu hesla')
                : t('Založit přístup a odeslat instrukce'),
            confirmIcon: <SendRounded/>,
        } as ModalProps);
        if (result !== 'CONFIRM') {
            return;
        }
        const res = await dispatch(forgottenPassword({
            email: party.email, tokenType: !party.userId
                ? JsonAuthRequestTokenTypeEnum.Activate
                : JsonAuthRequestTokenTypeEnum.Recover
        }));
        const m = getApiResult<string>(res);
        if (m) {
            showApiMessage(res, 'success', m, dispatch);
            if (!party?.userId) {
                dispatch(fetchCodebooks());
                await handleFetchParty();
            }
        }

    }, [party, handleFetchParty, modal, dispatch, t])

    const eventTabs = useMemo(() => {
        const eventTabs: JSX.Element[] = [];
        events?.forEach((e, i) => {
            if (e.status === JsonEventInfoStatusEnum.Active || eventParties?.find((ep) => ep.eventId === e.eventId)) {
                eventTabs.push(<Tab key={i} value={e.eventId} label={e.abbr} title={e.title}/>);
            }
        });
        return eventTabs;
    }, [events, eventParties]);

    useEffect(() => {
        handleFetchParty().then();
    }, [handleFetchParty])

    if (!party) {
        return <LinearProgress/>
    }

    let type;
    let isAddress = true;
    switch (party.partyType) {
        case JsonPartyInfoPartyTypeEnum.Po:
            type = 'Organizace';
            isAddress = false;
            break;
        case JsonPartyInfoPartyTypeEnum.T:
            type = 'Technická osoba';
            isAddress = false;
            break;
        case JsonPartyInfoPartyTypeEnum.Fo:
        default:
            type = 'Osoba'
    }

    return <Container>
        <PageHeader title={(!!party.partyId && partyName(party, true)) || ''}/>

        {!!party.partyId && <Grid container spacing={2} className={'page-content'}>
			<Grid item md={6} sm={12}>
				<PartyInfoBox title={t(type)} party={party} photoTopMargin={'-20px'} actions={!isAdmin ? undefined
                    : [{title: 'Upravit osobu', color: 'primary', action: handleEditParty, icon: <EditRounded/>}]}
				/>
			</Grid>

			<Grid item md={6} sm={12}>
				<InfoBox sx={{minHeight: {md: '100%'}}} title={t('Kontakt a přístupy')}>
					<table className={'info-box-table'}>
						<tbody>
						<tr>
							<th style={{width: '15%'}}>{t('Email')}</th>
							<td style={{width: '40%'}}>{party.email}</td>
							<th style={{width: '20%'}}>{t('Telefon')}</th>
							<td style={{width: '25%'}}>{party.phone}</td>
						</tr>
						<tr>
							<th>{t('Firma')}</th>
							<td>{party.orgCompanyName}</td>
							<th>{isAddress ? t('Bankovní účet') : null}</th>
							<td>{party.bankAccount}</td>
						</tr>
						</tbody>
                        {isAddress && <tbody>
						<tr>
							<th>{t('Ulice')}</th>
							<td>{party.permAddress?.street}</td>
							<th>{t('PSČ')}</th>
							<td>{party.permAddress?.zip}</td>
						</tr>
						<tr>
							<th>{t('Město')}</th>
							<td>{party.permAddress?.city}</td>
							<th>{t('Stát')}</th>
							<td>{party.permAddress?.country}</td>
						</tr>
						</tbody>}
                        {!!party.userId && <tbody>
						<tr>
							<th>{t('EMS přístup')}</th>
							<td colSpan={3}>
								<CodebookValue value={party.roleId} name={'role'}/>
                                {user?.roleId === ROLE_SUPERVISOR && <>
									&nbsp;({user.userData?.groupIds?.map((groupId, i) => {
                                    return <span key={i}>{i > 0 ? ', ' : null}<CodebookValue value={groupId} name={'group'}/></span>
                                })})
								</>}
							</td>
						</tr>
						</tbody>}
					</table>
					<Box className={'info-box-footer-actions'} sx={{position: {md: 'absolute'}}}>
                        {/*<Button variant={'contained'} size={'small'} color={'primary'} onClick={() => handleEditPartyContact()}>*/}
                        {/*	<MapRounded/>*/}
                        {/*	<span>{t('Upravit kontaktní údaje')}</span></Button>*/}
                        {!!party.email && isAdmin && <Button variant={'text'} size={'small'} onClick={handlePasswordEmail}>
							<EmailRounded/>
							<span>{!!party.userId ? 'Odeslat přístupové údaje' : 'Založit uživatelský přístup'}</span>
						</Button>}
					</Box>
				</InfoBox>
			</Grid>

			<Grid item sm={12}>
				<Paper>
					<Tabs
						value={eventId}
						onChange={(_, v) => setEventId(v)}
						variant="scrollable"
						scrollButtons="auto"
					>
                        {eventTabs}
					</Tabs>
				</Paper>
			</Grid>

            {!!eventId && <Grid item sm={12}>
				<EventParty
					eventId={eventId}
					readonly={events?.find((e) => e.eventId === eventId)?.status === JsonEventInfoStatusEnum.Deleted}
					eventParty={eventParties?.find((ep) => ep.eventId === eventId)}
					party={party}
					handleMassAction={handleMassAction}
					onSave={handleFetchParty}
					isAdmin={isAdmin}
				/>
			</Grid>}
		</Grid>}
        {editParty && <PartyModal
			type={'quick'}
			item={editParty}
			partyInfo={party}
			onCancel={handleCancelParty}
			onSave={handleSaveParty}
            // onDelete={editParty.partyId ? (party) => handleSaveParty(party, true) : undefined}
            // deleteButtonTitle={editParty.status === JsonPartyStatusEnum.Deleted ? 'Obnovit' : 'Archivovat'}
            // deleteConfirmText={editParty.status === JsonPartyStatusEnum.Deleted ? 'Skutečně obnovit událost {{title}}?' : 'Skutečně archivovat událost {{title}}?'}
		/>}
        {editPartyContact && <PartyModal
			type={'contact'}
			item={editPartyContact}
			partyInfo={party}
			onCancel={handleCancelParty}
			onSave={handleSaveParty}
		/>}
        {massAction && <PartyMassActionModal
			massAction={massAction}
			onCancel={handleCancelMassAction}
			onSave={handleSaveMassAction}
		/>}
    </Container>
}

export default PartyPage;
