/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventFloorDayInfo,
    JsonEventFloorDayInfoFromJSON,
    JsonEventFloorDayInfoFromJSONTyped,
    JsonEventFloorDayInfoToJSON,
} from './JsonEventFloorDayInfo';
import {
    JsonEventFloorInfo,
    JsonEventFloorInfoFromJSON,
    JsonEventFloorInfoFromJSONTyped,
    JsonEventFloorInfoToJSON,
} from './JsonEventFloorInfo';
import {
    JsonFloorShape,
    JsonFloorShapeFromJSON,
    JsonFloorShapeFromJSONTyped,
    JsonFloorShapeToJSON,
} from './JsonFloorShape';

/**
 * 
 * @export
 * @interface JsonRsvpFloorDayPlan
 */
export interface JsonRsvpFloorDayPlan {
    /**
     * 
     * @type {JsonEventFloorInfo}
     * @memberof JsonRsvpFloorDayPlan
     */
    eventFloor?: JsonEventFloorInfo;
    /**
     * 
     * @type {JsonEventFloorDayInfo}
     * @memberof JsonRsvpFloorDayPlan
     */
    eventFloorDay?: JsonEventFloorDayInfo;
    /**
     * 
     * @type {Array<JsonFloorShape>}
     * @memberof JsonRsvpFloorDayPlan
     */
    floorShapes?: Array<JsonFloorShape>;
}

export function JsonRsvpFloorDayPlanFromJSON(json: any): JsonRsvpFloorDayPlan {
    return JsonRsvpFloorDayPlanFromJSONTyped(json, false);
}

export function JsonRsvpFloorDayPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonRsvpFloorDayPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventFloor': !exists(json, 'eventFloor') ? undefined : JsonEventFloorInfoFromJSON(json['eventFloor']),
        'eventFloorDay': !exists(json, 'eventFloorDay') ? undefined : JsonEventFloorDayInfoFromJSON(json['eventFloorDay']),
        'floorShapes': !exists(json, 'floorShapes') ? undefined : ((json['floorShapes'] as Array<any>).map(JsonFloorShapeFromJSON)),
    };
}

export function JsonRsvpFloorDayPlanToJSON(value?: JsonRsvpFloorDayPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventFloor': JsonEventFloorInfoToJSON(value.eventFloor),
        'eventFloorDay': JsonEventFloorDayInfoToJSON(value.eventFloorDay),
        'floorShapes': value.floorShapes === undefined ? undefined : ((value.floorShapes as Array<any>).map(JsonFloorShapeToJSON)),
    };
}

