import {Grid} from '@mui/material';
import {FormikErrors} from 'formik';
import {FormProps} from "../../model/form";
import {JsonEvent} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {useCallback} from "react";

export interface EventFormProps extends FormProps<JsonEvent> {
    mode: 'event' | 'orgInfoText';
}

const EventForm = (props: EventFormProps) => {
    const {mode} = props;

    const t = useAppTranslation();

    const validate = useCallback((values: JsonEvent) => {
        let errors = {} as FormikErrors<JsonEvent>;
        if (mode === 'orgInfoText') {
            return errors;
        }
        if (!values.title) {
            errors.title = t('Zadejte prosím název události');
        }
        if (!values.eventFrom) {
            errors.eventFrom = t('Zadejte počátek akce')
        }
        if (!values.eventTo) {
            errors.eventTo = t('Zadejte konec akce')
        }
        if (values.prepareFrom && values.prepareTo && values.prepareFrom > values.prepareTo) {
            errors.prepareTo = t('Konec příprav je zvolen před začátkem');
        } else if (values.eventFrom && values.prepareTo && values.prepareTo >= values.eventFrom) {
            errors.prepareTo = t('Konec příprav je zvolen po začátku akce');
        }

        if (values.cleanFrom && values.cleanTo && values.cleanFrom > values.cleanTo) {
            errors.cleanTo = t('Konec úklidu je zvolen před začátkem');
        }
        if (values.eventTo && values.cleanFrom && values.cleanFrom <= values.eventTo) {
            errors.cleanFrom = t('Začátek úklidu je zvolen před koncem akce');
        }
        return errors;
    }, [mode, t]);

    return <FormContainer {...props} validate={validate} children={(formikProps) => {
        if (mode === 'orgInfoText') {
            return <>
                <Grid item xs={12}>
                    <TextFormField name="eventData.orgGroupText" label={t('Informace')} type={'textarea'} minRows={15} maxRows={30} maxlength={100_000}/>
                </Grid>
            </>
        }
        const isSiwi = !!formikProps.initialValues.siwiId;
        return <>
            {isSiwi && <Grid item xs={12}>
				<p>{t('Událost spravuje Siwidata, proto je možné měnit jen některá pole.')}</p>
			</Grid>}

            <Grid item xs={8}>
                <TextFormField name="title" label={t('Název události')} type={'text'} disabled={isSiwi}/>
            </Grid>
            <Grid item xs={4}>
                <TextFormField name="abbr" label={t('Zkratka')} type={'text'}/>
            </Grid>

            <Grid item xs={12}>
                <TextFormField name="description" label={t('Popis události')} type={'textarea'} rows={3} maxlength={1000} disabled={isSiwi}/>
            </Grid>

            <Grid item xs={6}>
                <TextFormField name="prepareFrom" label={t('Příprava od')} type={'date'} maxlength={10}/>
            </Grid>
            <Grid item xs={6}>
                <TextFormField name="prepareTo" label={t('Příprava do')} type={'date'} maxlength={10}/>
            </Grid>

            <Grid item xs={6}>
                <TextFormField name="eventFrom" label={t('Akce od')} type={'date'} maxlength={10} disabled={isSiwi}/>
            </Grid>
            <Grid item xs={6}>
                <TextFormField name="eventTo" label={t('Akce do')} type={'date'} maxlength={10} disabled={isSiwi}/>
            </Grid>

            <Grid item xs={6}>
                <TextFormField name="cleanFrom" label={t('Úklid od')} type={'date'} maxlength={10}/>
            </Grid>
            <Grid item xs={6}>
                <TextFormField name="cleanTo" label={t('Úklid do')} type={'date'} maxlength={10}/>
            </Grid>

            <Grid item xs={12}>
                <TextFormField name="siwiId" label={t('Siwidata ID')} type={'text'} disabled={isSiwi}/>
            </Grid>
        </>
    }}/>;
}

export default EventForm;
