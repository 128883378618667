/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonInviteReply,
    ApiResultOfJsonInviteReplyFromJSON,
    ApiResultOfJsonInviteReplyToJSON,
    ApiResultOfJsonInviteReplyInfo,
    ApiResultOfJsonInviteReplyInfoFromJSON,
    ApiResultOfJsonInviteReplyInfoToJSON,
    ApiResultOfListOfJsonInviteReplyInfo,
    ApiResultOfListOfJsonInviteReplyInfoFromJSON,
    ApiResultOfListOfJsonInviteReplyInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonInviteReply,
    JsonInviteReplyFromJSON,
    JsonInviteReplyToJSON,
} from '../models';

export interface GetInviteReplyCountUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    partyIds?: Array<number>;
    inviteReplyTypes?: Array<GetInviteReplyCountUsingGETInviteReplyTypesEnum>;
    groupIds?: Array<number>;
    statuses?: Array<GetInviteReplyCountUsingGETStatusesEnum>;
    search?: string;
}

export interface GetInviteReplyInfoUsingGETRequest {
    replyId: number;
}

export interface GetInviteReplyListUsingGETRequest {
    eventId?: number;
    eventDay?: number;
    partyIds?: Array<number>;
    groupIds?: Array<number>;
    inviteReplyTypes?: Array<GetInviteReplyListUsingGETInviteReplyTypesEnum>;
    statuses?: Array<GetInviteReplyListUsingGETStatusesEnum>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetInviteReplyUsingGETRequest {
    replyId: number;
}

export interface SaveInviteReplyUsingPOSTRequest {
    json: JsonInviteReply;
    testOnly?: boolean;
}

/**
 * 
 */
export class InviteReplyControllerApi extends runtime.BaseAPI {

    /**
     * getInviteReplyCount
     */
    async getInviteReplyCountUsingGETRaw(requestParameters: GetInviteReplyCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.inviteReplyTypes) {
            queryParameters['inviteReplyTypes'] = requestParameters.inviteReplyTypes;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite-reply/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getInviteReplyCount
     */
    async getInviteReplyCountUsingGET(requestParameters: GetInviteReplyCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getInviteReplyCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInviteReplyInfo
     */
    async getInviteReplyInfoUsingGETRaw(requestParameters: GetInviteReplyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteReplyInfo>> {
        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId','Required parameter requestParameters.replyId was null or undefined when calling getInviteReplyInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite-reply/info/{replyId}`.replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteReplyInfoFromJSON(jsonValue));
    }

    /**
     * getInviteReplyInfo
     */
    async getInviteReplyInfoUsingGET(requestParameters: GetInviteReplyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteReplyInfo> {
        const response = await this.getInviteReplyInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInviteReplyList
     */
    async getInviteReplyListUsingGETRaw(requestParameters: GetInviteReplyListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonInviteReplyInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.eventDay !== undefined) {
            queryParameters['eventDay'] = requestParameters.eventDay;
        }

        if (requestParameters.partyIds) {
            queryParameters['partyIds'] = requestParameters.partyIds;
        }

        if (requestParameters.groupIds) {
            queryParameters['groupIds'] = requestParameters.groupIds;
        }

        if (requestParameters.inviteReplyTypes) {
            queryParameters['inviteReplyTypes'] = requestParameters.inviteReplyTypes;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite-reply/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonInviteReplyInfoFromJSON(jsonValue));
    }

    /**
     * getInviteReplyList
     */
    async getInviteReplyListUsingGET(requestParameters: GetInviteReplyListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonInviteReplyInfo> {
        const response = await this.getInviteReplyListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInviteReply
     */
    async getInviteReplyUsingGETRaw(requestParameters: GetInviteReplyUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteReply>> {
        if (requestParameters.replyId === null || requestParameters.replyId === undefined) {
            throw new runtime.RequiredError('replyId','Required parameter requestParameters.replyId was null or undefined when calling getInviteReplyUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite-reply/{replyId}`.replace(`{${"replyId"}}`, encodeURIComponent(String(requestParameters.replyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteReplyFromJSON(jsonValue));
    }

    /**
     * getInviteReply
     */
    async getInviteReplyUsingGET(requestParameters: GetInviteReplyUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteReply> {
        const response = await this.getInviteReplyUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveInviteReply
     */
    async saveInviteReplyUsingPOSTRaw(requestParameters: SaveInviteReplyUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteReply>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveInviteReplyUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/invite-reply`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonInviteReplyToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteReplyFromJSON(jsonValue));
    }

    /**
     * saveInviteReply
     */
    async saveInviteReplyUsingPOST(requestParameters: SaveInviteReplyUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteReply> {
        const response = await this.saveInviteReplyUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetInviteReplyCountUsingGETInviteReplyTypesEnum {
    Accept = 'ACCEPT',
    Reject = 'REJECT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteReplyCountUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Draft = 'DRAFT',
    Sent = 'SENT',
    Rejected = 'REJECTED',
    Accepted = 'ACCEPTED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteReplyListUsingGETInviteReplyTypesEnum {
    Accept = 'ACCEPT',
    Reject = 'REJECT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInviteReplyListUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Draft = 'DRAFT',
    Sent = 'SENT',
    Rejected = 'REJECTED',
    Accepted = 'ACCEPTED'
}
