import {FormProps} from "../../model/form";
import {JsonFile, JsonInviteReply, JsonInviteReplyExtra} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {useCallback} from "react";
import {FormikErrors, FormikProps} from "formik";
import {Grid} from "@mui/material";
import {TextFormField} from "../form/TextFormField";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {sexOptions} from "../../model/party";
import {PhotoFormField} from "../form/PhotoFormField";
import FormModal from "../form/FormModal";
import FormContainer from "../form/FormContainer";

interface RsvpInviteExtraPartyFormProps extends FormProps<JsonInviteReplyExtra> {
    replyValues: JsonInviteReply,
    onFileUpload: (file: File, replyValues: JsonInviteReply) => Promise<JsonFile | undefined>,
}

const RsvpInviteExtraPartyForm = (props: RsvpInviteExtraPartyFormProps) => {
    const {item, onSave, onCancel, replyValues, onFileUpload} = props;

    const t = useAppTranslation();

    const validate = useCallback((values: JsonInviteReplyExtra) => {
        let errors = {} as FormikErrors<JsonInviteReplyExtra>;
        if (!values.firstName || values.firstName.length < 2) {
            errors.firstName = t('Zadejte prosím jméno');
        }
        if (!values.lastName || values.lastName.length < 2) {
            errors.lastName = t('Zadejte prosím příjmení');
        }
        if (!values.sex) {
            errors.sex = t('Zvolte prosím');
        }
        if (!values.photoGuid) {
            errors.photoGuid = t('Nahrajte prosím fotografii');
        }
        return errors;
    }, [t])

    const children = useCallback(({values}: FormikProps<JsonInviteReplyExtra>) => {
        return <>
            <Grid item sm={7} xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <p>
                            {t('Zadejte prosím jméno a příjmení doprovodu, které budou společně s fotografií vytištěny na jejich přístupové kartě.')}
                        </p>
                    </Grid>
                    <Grid item xs={8}>
                        <TextFormField name={'firstName'} label={'Doprovod jméno'}/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextFormField name={'lastName'} label={'Doprovod příjmení'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroupField name={'sex'} label={'Pohlaví'} options={sexOptions}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={5} xs={12}>
                <PhotoFormField name={'photoGuid'}
                    maxHeight={185} maxWidth={180}
                    title={((values?.firstName || '') + ' ' + (values?.lastName || '')).trim()}
                    onRequestChange={async (file) => {
                        return await onFileUpload(file, replyValues);
                    }}
                />
            </Grid>
        </>;
    }, [replyValues, onFileUpload, t]);

    return <FormModal title={!item.extraId ? t('Nový doprovod') : t('Úprava doprovodu')} onCancel={onCancel} maxWidth={'sm'}>
        <FormContainer
            saveButtonTitle={t('Uložit doprovod')}
            onSave={onSave} onCancel={onCancel}
            item={item} validate={validate} children={children}/>
    </FormModal>;
}

export default RsvpInviteExtraPartyForm;