import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonArticleType, ApiResultOfJsonArticleTypeInfo, ArticleTypeControllerApi, GetArticleTypeCountUsingGETRequest, GetArticleTypeListUsingGETRequest, JsonArticleType, JsonArticleTypeInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const articleTypesApi = new ArticleTypeControllerApi(API_CONFIG);

export const fetchArticleTypes = createAsyncThunk('articleTypes/list', async (filter: GetArticleTypeListUsingGETRequest, thunkApi) => {
    return {...await articleTypesApi.getArticleTypeListUsingGET(filter), filter};
});

export const fetchArticleTypesCount = createAsyncThunk('articleTypes/count', async (params: GetArticleTypeCountUsingGETRequest = {}) => {
    return await articleTypesApi.getArticleTypeCountUsingGET(params);
});

export const fetchArticleTypeInfo = createAsyncThunk('articleType/info', async (articleTypeId: number): Promise<ApiResultOfJsonArticleTypeInfo> => {
    return await articleTypesApi.getArticleTypeInfoUsingGET({articleTypeId});
});

export const fetchArticleType = createAsyncThunk('articleType/fetch', async (articleTypeId: number): Promise<ApiResultOfJsonArticleType> => {
    return await articleTypesApi.getArticleTypeUsingGET({articleTypeId});
});

export const saveArticleType = createAsyncThunk('articleType/save', async (articleType: JsonArticleType) => {
    return await articleTypesApi.saveArticleTypeUsingPOST({json: articleType});
});

export const articleTypesSlice = createSlice({
    name: 'articleTypes',
    initialState: (): ItemsState<JsonArticleTypeInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchArticleTypes, fetchArticleTypesCount);
    }
});

export const articleTypesReducer = articleTypesSlice.reducer;
