import {CircularProgress} from "@mui/material";
import {selectAppLoading} from "../../store/selectors";
import {useAppSelector} from "../../store";

export const Loader = () => {
    const isLoading = useAppSelector(selectAppLoading);
    return (
        <>
            {isLoading &&
				<div style={{width: '100%', height: '100vh', position: 'fixed', background: 'rgba(255,255,255,0.5)', zIndex: '200', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<CircularProgress/>
				</div>
            }
        </>
    );
};
