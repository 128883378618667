import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonEventGroup, ApiResultOfJsonEventGroupInfo, EventGroupControllerApi, GetEventGroupCountUsingGETRequest, GetEventGroupListUsingGETRequest, JsonEventGroup, JsonEventGroupInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const eventGroupsApi = new EventGroupControllerApi(API_CONFIG);

export const fetchEventGroups = createAsyncThunk('eventGroups/list', async (filter: GetEventGroupListUsingGETRequest, thunkApi) => {
    return {...await eventGroupsApi.getEventGroupListUsingGET(filter), filter};
});

export const fetchEventGroupsCount = createAsyncThunk('eventGroups/count', async (params: GetEventGroupCountUsingGETRequest = {}) => {
    return await eventGroupsApi.getEventGroupCountUsingGET(params);
});

export const fetchEventGroupInfo = createAsyncThunk('eventGroup/info', async (eventGroupId: number): Promise<ApiResultOfJsonEventGroupInfo> => {
    return await eventGroupsApi.getEventGroupInfoUsingGET({eventGroupId});
});

export const fetchEventGroup = createAsyncThunk('eventGroup/fetch', async (eventGroupId: number): Promise<ApiResultOfJsonEventGroup> => {
    return await eventGroupsApi.getEventGroupUsingGET({eventGroupId});
});

export const saveEventGroup = createAsyncThunk('eventGroup/save', async (eventGroup: JsonEventGroup) => {
    return await eventGroupsApi.saveEventGroupUsingPOST({json: eventGroup});
});

export const eventGroupsSlice = createSlice({
    name: 'eventGroups',
    initialState: (): ItemsState<JsonEventGroupInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchEventGroups, fetchEventGroupsCount);
    }
});

export const eventGroupsReducer = eventGroupsSlice.reducer;
