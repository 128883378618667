import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {ApiResultOfJsonCompany, ApiResultOfJsonCompanyInfo, CompanyControllerApi, GetCompanyCountUsingGETRequest, GetCompanyListUsingGETRequest, JsonCompany, JsonCompanyInfo} from '../generated-api';
import {createDefaultListReducer, createDefaultListState, ItemsState} from "./index";

const companiesApi = new CompanyControllerApi(API_CONFIG);

export const fetchCompanies = createAsyncThunk('companies/list', async (filter: GetCompanyListUsingGETRequest, thunkApi) => {
    return {...await companiesApi.getCompanyListUsingGET(filter), filter};
});

export const fetchCompaniesCount = createAsyncThunk('companies/count', async (params: GetCompanyCountUsingGETRequest = {}) => {
    return await companiesApi.getCompanyCountUsingGET(params);
});

export const fetchCompanyInfo = createAsyncThunk('company/info', async (companyId: number): Promise<ApiResultOfJsonCompanyInfo> => {
    return await companiesApi.getCompanyInfoUsingGET({companyId});
});

export const fetchCompaniesOnline = createAsyncThunk('companies/online', async (filter: GetCompanyListUsingGETRequest, thunkApi) => {
    return {...await companiesApi.getCompanyListUsingGET(filter), filter};
});

export const fetchCompany = createAsyncThunk('company/fetch', async (companyId: number): Promise<ApiResultOfJsonCompany> => {
    return await companiesApi.getCompanyUsingGET({companyId});
});

export const saveCompany = createAsyncThunk('company/save', async (company: JsonCompany): Promise<ApiResultOfJsonCompany> => {
    return await companiesApi.saveCompanyUsingPOST({json: company});
});

export const companiesSlice = createSlice({
    name: 'companies',
    initialState: (): ItemsState<JsonCompanyInfo> => createDefaultListState(),
    reducers: {},
    extraReducers: builder => {
        createDefaultListReducer(builder, fetchCompanies, fetchCompaniesCount);
    }
});

export const companiesReducer = companiesSlice.reducer;
