/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonArticleInfo
 */
export interface JsonArticleInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonArticleInfo
     */
    allowedActions?: Array<JsonArticleInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    articleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    articleTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    prefCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    prefRemainCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    purchasedCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    recCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    recRemainCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleInfo
     */
    sex?: JsonArticleInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleInfo
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonArticleInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonArticleInfoSexEnum {
    M = 'M',
    F = 'F',
    U = 'U'
}

export function JsonArticleInfoFromJSON(json: any): JsonArticleInfo {
    return JsonArticleInfoFromJSONTyped(json, false);
}

export function JsonArticleInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonArticleInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'articleTypeId': !exists(json, 'articleTypeId') ? undefined : json['articleTypeId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'prefCnt': !exists(json, 'prefCnt') ? undefined : json['prefCnt'],
        'prefRemainCnt': !exists(json, 'prefRemainCnt') ? undefined : json['prefRemainCnt'],
        'purchasedCnt': !exists(json, 'purchasedCnt') ? undefined : json['purchasedCnt'],
        'recCnt': !exists(json, 'recCnt') ? undefined : json['recCnt'],
        'recRemainCnt': !exists(json, 'recRemainCnt') ? undefined : json['recRemainCnt'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonArticleInfoToJSON(value?: JsonArticleInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'articleId': value.articleId,
        'articleTypeId': value.articleTypeId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'prefCnt': value.prefCnt,
        'prefRemainCnt': value.prefRemainCnt,
        'purchasedCnt': value.purchasedCnt,
        'recCnt': value.recCnt,
        'recRemainCnt': value.recRemainCnt,
        'sex': value.sex,
        'size': value.size,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

