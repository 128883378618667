/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonEventDayInfo
 */
export interface JsonEventDayInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventDayInfo
     */
    allowedActions?: Array<JsonEventDayInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDayInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDayInfo
     */
    dayDate?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    dayNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDayInfo
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    eventDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDayInfo
     */
    openFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventDayInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    vipSold?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    voucherR?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventDayInfo
     */
    voucherZ?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventDayInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventDayInfoFromJSON(json: any): JsonEventDayInfo {
    return JsonEventDayInfoFromJSONTyped(json, false);
}

export function JsonEventDayInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventDayInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'dayDate': !exists(json, 'dayDate') ? undefined : json['dayDate'],
        'dayNo': !exists(json, 'dayNo') ? undefined : json['dayNo'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'eventDayId': !exists(json, 'eventDayId') ? undefined : json['eventDayId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'openFrom': !exists(json, 'openFrom') ? undefined : json['openFrom'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'vipSold': !exists(json, 'vipSold') ? undefined : json['vipSold'],
        'voucherR': !exists(json, 'voucherR') ? undefined : json['voucherR'],
        'voucherZ': !exists(json, 'voucherZ') ? undefined : json['voucherZ'],
    };
}

export function JsonEventDayInfoToJSON(value?: JsonEventDayInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'dayDate': value.dayDate,
        'dayNo': value.dayNo,
        'description': value.description,
        'eventDayId': value.eventDayId,
        'eventId': value.eventId,
        'openFrom': value.openFrom,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'vipSold': value.vipSold,
        'voucherR': value.voucherR,
        'voucherZ': value.voucherZ,
    };
}

