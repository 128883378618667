/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonInvite,
    JsonInviteFromJSON,
    JsonInviteFromJSONTyped,
    JsonInviteToJSON,
} from './JsonInvite';
import {
    JsonUserInfo,
    JsonUserInfoFromJSON,
    JsonUserInfoFromJSONTyped,
    JsonUserInfoToJSON,
} from './JsonUserInfo';

/**
 * 
 * @export
 * @interface JsonEmailPreviewRequest
 */
export interface JsonEmailPreviewRequest {
    /**
     * 
     * @type {JsonInvite}
     * @memberof JsonEmailPreviewRequest
     */
    invite?: JsonInvite;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEmailPreviewRequest
     */
    recipients?: Array<string>;
    /**
     * 
     * @type {JsonUserInfo}
     * @memberof JsonEmailPreviewRequest
     */
    user?: JsonUserInfo;
}

export function JsonEmailPreviewRequestFromJSON(json: any): JsonEmailPreviewRequest {
    return JsonEmailPreviewRequestFromJSONTyped(json, false);
}

export function JsonEmailPreviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEmailPreviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invite': !exists(json, 'invite') ? undefined : JsonInviteFromJSON(json['invite']),
        'recipients': !exists(json, 'recipients') ? undefined : json['recipients'],
        'user': !exists(json, 'user') ? undefined : JsonUserInfoFromJSON(json['user']),
    };
}

export function JsonEmailPreviewRequestToJSON(value?: JsonEmailPreviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invite': JsonInviteToJSON(value.invite),
        'recipients': value.recipients,
        'user': JsonUserInfoToJSON(value.user),
    };
}

