/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEventFloorData,
    JsonEventFloorDataFromJSON,
    JsonEventFloorDataFromJSONTyped,
    JsonEventFloorDataToJSON,
} from './JsonEventFloorData';

/**
 * 
 * @export
 * @interface JsonEventFloorInfo
 */
export interface JsonEventFloorInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventFloorInfo
     */
    allowedActions?: Array<JsonEventFloorInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorInfo
     */
    eventFloorId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorInfo
     */
    eventId?: number;
    /**
     * 
     * @type {JsonEventFloorData}
     * @memberof JsonEventFloorInfo
     */
    floorData?: JsonEventFloorData;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorInfo
     */
    planGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorInfo
     */
    planId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventFloorInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventFloorInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventFloorInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventFloorInfoFromJSON(json: any): JsonEventFloorInfo {
    return JsonEventFloorInfoFromJSONTyped(json, false);
}

export function JsonEventFloorInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventFloorInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventFloorId': !exists(json, 'eventFloorId') ? undefined : json['eventFloorId'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'floorData': !exists(json, 'floorData') ? undefined : JsonEventFloorDataFromJSON(json['floorData']),
        'planGuid': !exists(json, 'planGuid') ? undefined : json['planGuid'],
        'planId': !exists(json, 'planId') ? undefined : json['planId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventFloorInfoToJSON(value?: JsonEventFloorInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventFloorId': value.eventFloorId,
        'eventId': value.eventId,
        'floorData': JsonEventFloorDataToJSON(value.floorData),
        'planGuid': value.planGuid,
        'planId': value.planId,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

