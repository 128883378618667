/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonFoodData,
    JsonFoodDataFromJSON,
    JsonFoodDataFromJSONTyped,
    JsonFoodDataToJSON,
} from './JsonFoodData';

/**
 * 
 * @export
 * @interface JsonEventPartyDay
 */
export interface JsonEventPartyDay {
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    acmCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    acmId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventPartyDay
     */
    allowedActions?: Array<JsonEventPartyDayAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventPartyDay
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyDay
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    eventDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    eventPartyDayId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    eventPartyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    foodBrId?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof JsonEventPartyDay
     */
    foodCnt?: { [key: string]: number; };
    /**
     * 
     * @type {JsonFoodData}
     * @memberof JsonEventPartyDay
     */
    foodData?: JsonFoodData;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    foodDiId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    foodLuId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    foodSnId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    parkCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    parkFlag?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    parkId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyDay
     */
    parkPlates?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    parkRec?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventPartyDay
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventPartyDay
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventPartyDayAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonEventPartyDayFromJSON(json: any): JsonEventPartyDay {
    return JsonEventPartyDayFromJSONTyped(json, false);
}

export function JsonEventPartyDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventPartyDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acmCnt': !exists(json, 'acmCnt') ? undefined : json['acmCnt'],
        'acmId': !exists(json, 'acmId') ? undefined : json['acmId'],
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventDayId': !exists(json, 'eventDayId') ? undefined : json['eventDayId'],
        'eventPartyDayId': !exists(json, 'eventPartyDayId') ? undefined : json['eventPartyDayId'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'foodBrId': !exists(json, 'foodBrId') ? undefined : json['foodBrId'],
        'foodCnt': !exists(json, 'foodCnt') ? undefined : json['foodCnt'],
        'foodData': !exists(json, 'foodData') ? undefined : JsonFoodDataFromJSON(json['foodData']),
        'foodDiId': !exists(json, 'foodDiId') ? undefined : json['foodDiId'],
        'foodLuId': !exists(json, 'foodLuId') ? undefined : json['foodLuId'],
        'foodSnId': !exists(json, 'foodSnId') ? undefined : json['foodSnId'],
        'parkCnt': !exists(json, 'parkCnt') ? undefined : json['parkCnt'],
        'parkFlag': !exists(json, 'parkFlag') ? undefined : json['parkFlag'],
        'parkId': !exists(json, 'parkId') ? undefined : json['parkId'],
        'parkPlates': !exists(json, 'parkPlates') ? undefined : json['parkPlates'],
        'parkRec': !exists(json, 'parkRec') ? undefined : json['parkRec'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventPartyDayToJSON(value?: JsonEventPartyDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acmCnt': value.acmCnt,
        'acmId': value.acmId,
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventDayId': value.eventDayId,
        'eventPartyDayId': value.eventPartyDayId,
        'eventPartyId': value.eventPartyId,
        'foodBrId': value.foodBrId,
        'foodCnt': value.foodCnt,
        'foodData': JsonFoodDataToJSON(value.foodData),
        'foodDiId': value.foodDiId,
        'foodLuId': value.foodLuId,
        'foodSnId': value.foodSnId,
        'parkCnt': value.parkCnt,
        'parkFlag': value.parkFlag,
        'parkId': value.parkId,
        'parkPlates': value.parkPlates,
        'parkRec': value.parkRec,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

