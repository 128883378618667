import {Button, CircularProgress, Grid} from '@mui/material';
import {FormikErrors, FormikProps} from 'formik';
import {FAKE_VALUE_EMPTY, FormProps} from "../../model/form";
import {JsonFile, JsonFindPartyRequest, JsonFindPartyResponse, JsonInviteReply, JsonInviteReplyExtra, JsonPartyInfo} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import {CheckBoxOutlineBlankRounded, CheckBoxRounded, ChevronRight, SearchRounded} from "@mui/icons-material";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {ButtonGroupField} from "../form/ButtonGroupField";
import {sexOptions, sortFoundParties} from "../../model/party";
import {PhotoFormField} from "../form/PhotoFormField";
import {getApiResult} from "../../helpers/api";
import {useAppDispatch} from "../../store";
import {FoundParty} from "../party/FoundParty";
import FormModal from "../form/FormModal";
import {findRsvpParty} from "../../store/rsvp";

export interface JsonInviteReplyExtraWithResult extends JsonInviteReplyExtra {
    foundParties?: Array<JsonFindPartyResponse>,
    resultParty?: JsonPartyInfo,
}

export interface RsvpInviteExtraImportFormProps extends FormProps<JsonInviteReplyExtraWithResult> {
    token: string,
    eventId: number,
    original: JsonInviteReplyExtraWithResult,
    replyValues: JsonInviteReply,
    onFileUpload: (file: File, replyValues: JsonInviteReply) => Promise<JsonFile | undefined>,
}

const getSearchHash = (values: JsonInviteReplyExtraWithResult): string | undefined => {
    if (!values.firstName || !values.lastName || !values.sex) {
        return undefined;
    }
    return JSON.stringify([values.firstName, values.lastName, values.sex]);
}

const RsvpInviteExtraImportForm = (props: RsvpInviteExtraImportFormProps) => {
    const {eventId, item, original, token, onSave, onFileUpload, replyValues} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [foundParties, setFoundParties] = useState<JsonFindPartyResponse[] | undefined>(item.foundParties);
    const [isFindRunning, setIsFindRunning] = useState<boolean>(false);
    const [lastSearchHash, setLastSearchHash] = useState<String | undefined>(getSearchHash(item));

    const handleFindParties = useCallback(async (item: JsonInviteReplyExtraWithResult) => {
        setIsFindRunning(true);
        const res = await dispatch(findRsvpParty({
            token,
            requests: [{
                ...item,
                photoGuid: undefined, // disable
                queryId: "1",
            }] as JsonFindPartyRequest[]
        }))

        const items = getApiResult<JsonFindPartyResponse[]>(res);
        if (items) {
            setFoundParties(sortFoundParties(items));
            setLastSearchHash(getSearchHash(item));
        }
        setIsFindRunning(false);

    }, [token, dispatch]);

    const validate = useCallback((values: JsonInviteReplyExtraWithResult) => {
        let errors = {} as FormikErrors<JsonInviteReplyExtraWithResult>;
        if (!values.firstName) {
            errors.firstName = t('Zadejte prosím jméno');
        }
        if (!values.lastName) {
            errors.lastName = t('Zadejte prosím příjmení');
        }
        if (!values.sex) {
            errors.sex = t('Zvolte prosím');
        }
        if (values.partyId === FAKE_VALUE_EMPTY && !values.photoGuid) {
            errors.photoGuid = t('Nahrejte prosím fotografii');
        }
        return errors;
    }, [t]);

    const actions = useCallback(({values, setFieldValue, isSubmitting, setFormikState}: FormikProps<JsonInviteReplyExtraWithResult>, props: FormProps<JsonInviteReplyExtraWithResult>) => {
        const isSubmittable = !!values.resultParty?.partyId
            && (getSearchHash(values) === lastSearchHash || values.resultParty.partyId > 0)
        return <>
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || t('Storno')}</Button>
			</Grid>}
            <Grid item>
                <Button variant="contained" type="submit" color={'success'} disabled={!isSubmittable || isSubmitting}>
                    {t('Potvrdit')}
                </Button>
            </Grid>
        </>
    }, [lastSearchHash, t]);

    useEffect(() => {
        if ((!!item.resultParty?.partyId || (item.firstName && item.lastName && item.sex)) && foundParties === undefined) {
            handleFindParties(item).then();
        }

    }, [item, foundParties, handleFindParties, dispatch]);

    return <FormModal title={t(props.title || 'Osoba personálu')} {...props} maxWidth={'md'}>
        <FormContainer {...props}
            actions={actions}
            onSave={(item) => {
                if (onSave) {
                    if (item.resultParty?.partyId && item.resultParty?.partyId > 0 && !item.photoGuid) {
                        item.photoGuid = foundParties?.find((fp) => fp?.party?.partyId === item?.resultParty?.partyId)?.party?.photoGuid;
                    }
                    return onSave(item);
                }
            }}
            validate={validate} children={({values, setFieldValue, validateForm, setFormikState}) => {
            const isHashEqual = getSearchHash(values) === lastSearchHash || (!!values.resultParty?.partyId && values.resultParty.partyId > 0);
            const isAnyResult = isHashEqual && !!foundParties?.length && foundParties.length > 0;
            const isSelectedNew = values.resultParty?.partyId === FAKE_VALUE_EMPTY;

            return <Grid item xs={12} sx={{minHeight: foundParties === undefined ? undefined : '260px'}}>
                <Grid container columns={45} columnSpacing={2}>
                    <Grid item xs={15}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={6} sx={{display: 'flex', alignItems: 'start'}}>
                                <Grid container columnSpacing={2} rowSpacing={1} className={'form-container'}>
                                    <Grid item xs={12}>
                                        <TextFormField name="firstName" label={t('Jméno')} type={'text'}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFormField name="lastName" label={t('Příjmení')} type={'text'}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ButtonGroupField name={'sex'} label={t('Pohlaví')} options={sexOptions}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <PhotoFormField name={'photoGuid'} isInternal maxWidth={112} maxHeight={112} readonly={!lastSearchHash}
                                    title={((values?.firstName || '') + ' ' + (values?.lastName || '')).trim()}
                                    onRequestChange={async (file) => {
                                        return await onFileUpload(file, replyValues);
                                        // if (foundParties?.length === 0 && values.resultParty?.partyId === undefined) {
                                        //     setFieldValue('resultParty', {
                                        //         partyId: isSelectedNew ? undefined : FAKE_VALUE_EMPTY,
                                        //     });
                                        // }
                                    }}
                                />
                            </Grid>
                            {!!values.resultParty?.partyId && values.resultParty?.partyId > 0 && <Grid item xs={12} sx={{marginTop: "10px", fontSize: "90%"}}>
                                <p>{t('Pokud si u nalezené osoby přejete aktualizovat fotografii, nahrajte ji tlačítkem výše a následně  zaškrtněte volbu "Aktualizovat fotografii" v pravé části zeleného boxu.')}</p>
                                <p>{t('Podobným způsobem je možné aktualizovat jméno a příjmení (volba "Aktualizovat" se objeví jen v případě, že vámi zadané údaje jsou odlišné od těch nalezených).')}</p>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className={'rsvp-extra-match-chevron'}>
                        <div>
                            <ChevronRight style={{width: '450%', marginLeft: '-180%'}}/>
                        </div>
                    </Grid>
                    <Grid item xs={28}>{lastSearchHash === undefined
                        ? <p>{t('Zadejte prosím jméno, příjmení a pohlaví osoby, a stiskněte "Najít možné shody".')}</p>
                        : !isAnyResult && isHashEqual && foundParties !== undefined
                            ? <>
                                <p>{t('Pro zadané jméno a pohlaví nebyly nalezeny žádné shody s osobami z minulých let.')}</p>
                                {!!values.photoGuid
                                    ? <p>{t('Upravte prosím údaje (překlepy, prohozené jméno a příjmení) a hledání opakujte, nebo potvrďte založení nové osoby.')}</p>
                                    : <p>{t('Upravte prosím údaje (překlepy, prohozené jméno a příjmení) a hledání opakujte, případně nahrajte fotografii a uložte osobu jako novou.')}</p>
                                }
                            </>
                            : isHashEqual
                                ? null
                                : <p>{t('Údaje byly od posledního hledání změněny, proveďte prosím hledání znovu.')}</p>}
                        {isFindRunning
                            ? <p><CircularProgress size={20}/></p>
                            : !isHashEqual
                                ? <p><Button size={'small'} variant={'contained'} onClick={async () => {
                                    setFieldValue('resultParty', original?.resultParty);
                                    validateForm(values).then((errors) => {
                                        if (errors && Object.keys(errors).length > 0) {
                                            setFormikState((s) => ({...s, submitCount: s.submitCount + 1}));
                                        } else {
                                            handleFindParties(values).then();
                                        }
                                    });
                                }}>
                                    <SearchRounded/>
                                    <span>{t('Najít možné shody')}</span></Button></p>
                                : null}
                        {isAnyResult &&
							<p style={{paddingBottom: '5px'}}>{t('Dle zadaných údajů byly nalezeny následující možné shody - pokud je osoba mezi nimi, klikněte na "Spojit" a uložte formulář tlačítkem "Potvrdit" vpravo dole:')}</p>}
                        <Grid container spacing={1}>
                            {isHashEqual && !!foundParties?.length && foundParties.length > 0 && foundParties.map((fp, i) => {
                                if (!!values.resultParty?.partyId && values.resultParty?.partyId > 0 && values.resultParty.partyId !== fp.party?.partyId) {
                                    return null;
                                }
                                return <FoundParty key={i} eventId={eventId} noDetail
                                    maxWidth={80}
                                    maxHeight={180}
                                    sourceParty={values as JsonPartyInfo}
                                    foundPartyResponse={fp}
                                    targetParty={values.resultParty as JsonPartyInfo}
                                    handleValueChange={(n, v) => {
                                        setFieldValue('resultParty.' + n, v, true);
                                    }}/>

                            })}
                        </Grid>
                        {isAnyResult && !values.photoGuid && <p style={{marginTop: "10px"}}>
                            {t('Pokud mezi výsledky osoba není, upravte údaje a hledání opakujte, nebo nahrajte fotografii a potvrďte založení nové osoby.')}</p>}
                        {isAnyResult && !!values.photoGuid && <p style={{marginTop: "10px", marginBottom: "-10px"}}>
                            {t('Pokud mezi výsledky osoba není, upravte údaje a vyhledání opakujte, nebo potvrďte založení nové osoby.')}</p>}
                        {(isHashEqual || lastSearchHash === undefined) && !!values.photoGuid && foundParties !== undefined &&
							<Button title={undefined} variant={'contained'} size={'small'} style={{marginTop: "20px"}}
								color={isSelectedNew ? (!!values.photoGuid ? 'info' : 'warning') : 'inherit'}
								onClick={() => {
                                    setFieldValue('resultParty', {
                                        partyId: isSelectedNew ? undefined : FAKE_VALUE_EMPTY,
                                    });
                                }}>
                                {isSelectedNew ? <CheckBoxRounded/> : <CheckBoxOutlineBlankRounded/>}
								<span>{t('Založit osobu jako novou')}</span>
							</Button>}
                    </Grid>
                </Grid>
            </Grid>
        }}/>
    </FormModal>;
}

export default RsvpInviteExtraImportForm;
