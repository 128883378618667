/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonFile,
    JsonFileFromJSON,
    JsonFileFromJSONTyped,
    JsonFileToJSON,
} from './JsonFile';

/**
 * 
 * @export
 * @interface JsonEventGroupSettings
 */
export interface JsonEventGroupSettings {
    /**
     * 
     * @type {Array<JsonFile>}
     * @memberof JsonEventGroupSettings
     */
    attachments?: Array<JsonFile>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroupSettings
     */
    infoSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventGroupSettings
     */
    infoText?: string;
}

export function JsonEventGroupSettingsFromJSON(json: any): JsonEventGroupSettings {
    return JsonEventGroupSettingsFromJSONTyped(json, false);
}

export function JsonEventGroupSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventGroupSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(JsonFileFromJSON)),
        'infoSubject': !exists(json, 'infoSubject') ? undefined : json['infoSubject'],
        'infoText': !exists(json, 'infoText') ? undefined : json['infoText'],
    };
}

export function JsonEventGroupSettingsToJSON(value?: JsonEventGroupSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(JsonFileToJSON)),
        'infoSubject': value.infoSubject,
        'infoText': value.infoText,
    };
}

