/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonFile,
    ApiResultOfJsonFileFromJSON,
    ApiResultOfJsonFileToJSON,
    ApiResultOfJsonInviteReply,
    ApiResultOfJsonInviteReplyFromJSON,
    ApiResultOfJsonInviteReplyToJSON,
    ApiResultOfJsonRsvp,
    ApiResultOfJsonRsvpFromJSON,
    ApiResultOfJsonRsvpToJSON,
    ApiResultOfJsonRsvpFloorDayPlan,
    ApiResultOfJsonRsvpFloorDayPlanFromJSON,
    ApiResultOfJsonRsvpFloorDayPlanToJSON,
    ApiResultOfListOfJsonFindPartyResponse,
    ApiResultOfListOfJsonFindPartyResponseFromJSON,
    ApiResultOfListOfJsonFindPartyResponseToJSON,
    JsonFindPartyRequest,
    JsonFindPartyRequestFromJSON,
    JsonFindPartyRequestToJSON,
    JsonInviteReply,
    JsonInviteReplyFromJSON,
    JsonInviteReplyToJSON,
} from '../models';

export interface FindPartyUsingPOSTRequest {
    token: string;
    requests: Array<JsonFindPartyRequest>;
}

export interface GetRsvpEventFloorDayPlanUsingGETRequest {
    dayDate: string;
    eventFloorDayId: number;
}

export interface GetRsvpUsingGETRequest {
    token: string;
    email?: string;
}

export interface SaveRsvpFileTravelUsingPOSTRequest {
    token: string;
    attachment: Blob;
}

export interface SaveRsvpFileUsingPOSTRequest {
    token: string;
    photo: Blob;
}

export interface SaveRsvpReplyUsingPOSTRequest {
    token: string;
    json: JsonInviteReply;
    isSend?: boolean;
    testOnly?: boolean;
}

/**
 * 
 */
export class RsvpControllerApi extends runtime.BaseAPI {

    /**
     * findParty
     */
    async findPartyUsingPOSTRaw(requestParameters: FindPartyUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonFindPartyResponse>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling findPartyUsingPOST.');
        }

        if (requestParameters.requests === null || requestParameters.requests === undefined) {
            throw new runtime.RequiredError('requests','Required parameter requestParameters.requests was null or undefined when calling findPartyUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/rsvp/{token}/find`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requests.map(JsonFindPartyRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonFindPartyResponseFromJSON(jsonValue));
    }

    /**
     * findParty
     */
    async findPartyUsingPOST(requestParameters: FindPartyUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonFindPartyResponse> {
        const response = await this.findPartyUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRsvpEventFloorDayPlan
     */
    async getRsvpEventFloorDayPlanUsingGETRaw(requestParameters: GetRsvpEventFloorDayPlanUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonRsvpFloorDayPlan>> {
        if (requestParameters.dayDate === null || requestParameters.dayDate === undefined) {
            throw new runtime.RequiredError('dayDate','Required parameter requestParameters.dayDate was null or undefined when calling getRsvpEventFloorDayPlanUsingGET.');
        }

        if (requestParameters.eventFloorDayId === null || requestParameters.eventFloorDayId === undefined) {
            throw new runtime.RequiredError('eventFloorDayId','Required parameter requestParameters.eventFloorDayId was null or undefined when calling getRsvpEventFloorDayPlanUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/rsvp/plan/{dayDate}/{eventFloorDayId}`.replace(`{${"dayDate"}}`, encodeURIComponent(String(requestParameters.dayDate))).replace(`{${"eventFloorDayId"}}`, encodeURIComponent(String(requestParameters.eventFloorDayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonRsvpFloorDayPlanFromJSON(jsonValue));
    }

    /**
     * getRsvpEventFloorDayPlan
     */
    async getRsvpEventFloorDayPlanUsingGET(requestParameters: GetRsvpEventFloorDayPlanUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonRsvpFloorDayPlan> {
        const response = await this.getRsvpEventFloorDayPlanUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRsvp
     */
    async getRsvpUsingGETRaw(requestParameters: GetRsvpUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonRsvp>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getRsvpUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/rsvp/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonRsvpFromJSON(jsonValue));
    }

    /**
     * getRsvp
     */
    async getRsvpUsingGET(requestParameters: GetRsvpUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonRsvp> {
        const response = await this.getRsvpUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveRsvpFileTravel
     */
    async saveRsvpFileTravelUsingPOSTRaw(requestParameters: SaveRsvpFileTravelUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFile>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling saveRsvpFileTravelUsingPOST.');
        }

        if (requestParameters.attachment === null || requestParameters.attachment === undefined) {
            throw new runtime.RequiredError('attachment','Required parameter requestParameters.attachment was null or undefined when calling saveRsvpFileTravelUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.attachment !== undefined) {
            formParams.append('attachment', requestParameters.attachment as any);
        }

        const response = await this.request({
            path: `/rest-service/rsvp/{token}/file/travel`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFileFromJSON(jsonValue));
    }

    /**
     * saveRsvpFileTravel
     */
    async saveRsvpFileTravelUsingPOST(requestParameters: SaveRsvpFileTravelUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFile> {
        const response = await this.saveRsvpFileTravelUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveRsvpFile
     */
    async saveRsvpFileUsingPOSTRaw(requestParameters: SaveRsvpFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonFile>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling saveRsvpFileUsingPOST.');
        }

        if (requestParameters.photo === null || requestParameters.photo === undefined) {
            throw new runtime.RequiredError('photo','Required parameter requestParameters.photo was null or undefined when calling saveRsvpFileUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.photo !== undefined) {
            formParams.append('photo', requestParameters.photo as any);
        }

        const response = await this.request({
            path: `/rest-service/rsvp/{token}/file`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonFileFromJSON(jsonValue));
    }

    /**
     * saveRsvpFile
     */
    async saveRsvpFileUsingPOST(requestParameters: SaveRsvpFileUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonFile> {
        const response = await this.saveRsvpFileUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveRsvpReply
     */
    async saveRsvpReplyUsingPOSTRaw(requestParameters: SaveRsvpReplyUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteReply>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling saveRsvpReplyUsingPOST.');
        }

        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveRsvpReplyUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.isSend !== undefined) {
            queryParameters['isSend'] = requestParameters.isSend;
        }

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/rsvp/{token}/reply`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonInviteReplyToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteReplyFromJSON(jsonValue));
    }

    /**
     * saveRsvpReply
     */
    async saveRsvpReplyUsingPOST(requestParameters: SaveRsvpReplyUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteReply> {
        const response = await this.saveRsvpReplyUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
