/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonAuthRequest
 */
export interface JsonAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonAuthRequest
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonAuthRequest
     */
    newRoleId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonAuthRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonAuthRequest
     */
    rememberMe?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonAuthRequest
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonAuthRequest
     */
    tokenType?: JsonAuthRequestTokenTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonAuthRequestTokenTypeEnum {
    Invite = 'INVITE',
    Remember = 'REMEMBER',
    Recover = 'RECOVER',
    Activate = 'ACTIVATE'
}

export function JsonAuthRequestFromJSON(json: any): JsonAuthRequest {
    return JsonAuthRequestFromJSONTyped(json, false);
}

export function JsonAuthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonAuthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'newRoleId': !exists(json, 'newRoleId') ? undefined : json['newRoleId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'rememberMe': !exists(json, 'rememberMe') ? undefined : json['rememberMe'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'tokenType': !exists(json, 'tokenType') ? undefined : json['tokenType'],
    };
}

export function JsonAuthRequestToJSON(value?: JsonAuthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'newRoleId': value.newRoleId,
        'password': value.password,
        'rememberMe': value.rememberMe,
        'token': value.token,
        'tokenType': value.tokenType,
    };
}

