/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonPartyEventDetails
 */
export interface JsonPartyEventDetails {
    /**
     * 
     * @type {number}
     * @memberof JsonPartyEventDetails
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyEventDetails
     */
    eventPartyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyEventDetails
     */
    formatCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPartyEventDetails
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyEventDetails
     */
    inviteCreatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyEventDetails
     */
    inviteReplyUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyEventDetails
     */
    inviteStatus?: JsonPartyEventDetailsInviteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyEventDetails
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyEventDetails
     */
    status?: JsonPartyEventDetailsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPartyEventDetails
     */
    updateInviteStatus?: JsonPartyEventDetailsUpdateInviteStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonPartyEventDetailsInviteStatusEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyEventDetailsStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    Pending = 'PENDING'
}/**
* @export
* @enum {string}
*/
export enum JsonPartyEventDetailsUpdateInviteStatusEnum {
    None = 'NONE',
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Returned = 'RETURNED',
    Rejected = 'REJECTED',
    Confirmed = 'CONFIRMED'
}

export function JsonPartyEventDetailsFromJSON(json: any): JsonPartyEventDetails {
    return JsonPartyEventDetailsFromJSONTyped(json, false);
}

export function JsonPartyEventDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPartyEventDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventPartyId': !exists(json, 'eventPartyId') ? undefined : json['eventPartyId'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'inviteCreatedAt': !exists(json, 'inviteCreatedAt') ? undefined : json['inviteCreatedAt'],
        'inviteReplyUntil': !exists(json, 'inviteReplyUntil') ? undefined : json['inviteReplyUntil'],
        'inviteStatus': !exists(json, 'inviteStatus') ? undefined : json['inviteStatus'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updateInviteStatus': !exists(json, 'updateInviteStatus') ? undefined : json['updateInviteStatus'],
    };
}

export function JsonPartyEventDetailsToJSON(value?: JsonPartyEventDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'eventPartyId': value.eventPartyId,
        'formatCode': value.formatCode,
        'groupId': value.groupId,
        'inviteCreatedAt': value.inviteCreatedAt,
        'inviteReplyUntil': value.inviteReplyUntil,
        'inviteStatus': value.inviteStatus,
        'reason': value.reason,
        'status': value.status,
        'updateInviteStatus': value.updateInviteStatus,
    };
}

