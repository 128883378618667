/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonArticleVariants,
    JsonArticleVariantsFromJSON,
    JsonArticleVariantsFromJSONTyped,
    JsonArticleVariantsToJSON,
} from './JsonArticleVariants';

/**
 * 
 * @export
 * @interface JsonArticleTypeInfo
 */
export interface JsonArticleTypeInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonArticleTypeInfo
     */
    allowedActions?: Array<JsonArticleTypeInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleTypeInfo
     */
    articleTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleTypeInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleTypeInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleTypeInfo
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleTypeInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonArticleTypeInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonArticleTypeInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {JsonArticleVariants}
     * @memberof JsonArticleTypeInfo
     */
    variants?: JsonArticleVariants;
}

/**
* @export
* @enum {string}
*/
export enum JsonArticleTypeInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonArticleTypeInfoFromJSON(json: any): JsonArticleTypeInfo {
    return JsonArticleTypeInfoFromJSONTyped(json, false);
}

export function JsonArticleTypeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonArticleTypeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'articleTypeId': !exists(json, 'articleTypeId') ? undefined : json['articleTypeId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'variants': !exists(json, 'variants') ? undefined : JsonArticleVariantsFromJSON(json['variants']),
    };
}

export function JsonArticleTypeInfoToJSON(value?: JsonArticleTypeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'articleTypeId': value.articleTypeId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventId': value.eventId,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'variants': JsonArticleVariantsToJSON(value.variants),
    };
}

