import {Alert, Box, Button, Chip, Grid, Typography} from '@mui/material';
import {FormikErrors, FormikProps, useFormikContext} from 'formik';
import {createOption, FAKE_VALUE_EMPTY, FormProps, ImportAction, OptionValue} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    JsonArticleInfo,
    JsonArticleSexEnum,
    JsonArticleTypeInfo,
    JsonEventParty,
    JsonEventPartyImport,
    JsonEventPartyMassActionRequestActionEnum,
    JsonImportResultOfstringAndJsonEventPartyImport,
    JsonImportResultOfstringAndJsonEventPartyImportColumnTypesEnum as ColumnType,
    JsonParty,
    JsonPartyInfo,
    JsonPartyMassActionRequestActionEnum,
    JsonPartySexEnum,
    PrepareEventPartyImportUsingPOSTRequest
} from "../../generated-api";
import {ItemsState, useAppDispatch} from "../../store";
import {massEventParty, prepareEventPartyImport} from "../../store/eventParties";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../../helpers/api";
import {FileFormField} from "../form/FileFormField";
import {Breakpoint} from "@mui/system";
import CodebookValue from "../CodebookValue";
import {articleName, EventPartyImportResult, isNameEqual} from "../../model/party";
import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridItemAction, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {dateToGuiAs} from "../../helpers/date";
import {ButtonGroupPlain} from "../form/ButtonGroupField";
import {CheckBoxOutlineBlankRounded, DoneRounded, Edit, LinkRounded, PlusOneRounded} from "@mui/icons-material";
import PartyImportEditModal from "../../pages/PartyImportEditModal";
import PartyPhoto, {PartyPhotoTooltip} from "./PartyPhoto";
import {fetchArticleTypes} from "../../store/articleTypes";
import {fetchArticles} from "../../store/articles";
import {massParty} from "../../store/parties";
import {AlertColor} from "@mui/material/Alert/Alert";
import PartyTags from "../PartyTags";
import {ModalProps, useModal} from "../../services/modal";
import {FoundPartyEventInfo} from "./FoundParty";

interface Props extends ImportAction<EventPartyImportResult, { eventId?: number }> {
    onSetMaxWidth: (maxWidth: Breakpoint) => void;
}

type ShowMode = 'valid' | 'invalid' | 'needAction' | 'all';

interface LocalFilter extends DataGridFilter {
    showMode?: ShowMode;
}

interface PartyImportGridState extends DataGridState<JsonEventPartyImport, LocalFilter> {
}

const defaultState: PartyImportGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: undefined,
        showMode: undefined
    },
};

const getRowClassNames = (item: JsonEventPartyImport, filter?: LocalFilter): string[] | undefined => {
    if (!!item.saveErrors) {
        return ['data-grid-invalid-row'];
    }
    // if (item.resultParty?.partyId === FAKE_VALUE_EMPTY) {
    //     return ['data-grid-info-row'];
    // }
    if (!(item.foundParties?.length && item.foundParties.length > 0)) {
        return ['data-grid-warning-row'];
    }
    return undefined;
}

const FoundPartyBox = (props: { item: JsonEventPartyImport, foundParty: JsonPartyInfo, eventId?: number, onPair?: () => void, resultParty?: JsonPartyInfo, readonly?: boolean }) => {
    const {item, foundParty, eventId, onPair, resultParty, readonly} = props;
    const t = useAppTranslation();

    const photoGuid = !!resultParty?.photoGuid && resultParty.photoGuid !== foundParty.photoGuid ? resultParty.photoGuid : foundParty.photoGuid;
    const lastName = !!resultParty?.lastName && resultParty.lastName !== foundParty.lastName ? resultParty.lastName : foundParty.lastName;
    const firstName = !!resultParty?.firstName && resultParty.firstName !== foundParty.firstName ? resultParty.firstName : foundParty.firstName;
    const email = !!resultParty?.email && !!foundParty.email && resultParty.email.toLowerCase() !== foundParty.email.toLowerCase() ? resultParty.email : foundParty.email;
    const isLikelyValid = !!foundParty.partyId
        && (!(item.foundParties?.length && item.foundParties?.length > 1) || (foundParty.email && item.email && item.email.toLowerCase() === foundParty.email.toLowerCase()))
        && isNameEqual(item.lastName, foundParty.lastName)
        && isNameEqual(item.firstName, foundParty.firstName);

    return <Box color={(theme) => theme.palette['info'].main} className={'import-match-box'}>
        {!readonly && (!!onPair
            ? <Button
                color={isLikelyValid ? 'success' : 'warning'}
                variant={'text'}
                title={!!foundParty.partyId
                    ? t('Potvrdit spárování s touto osobou')
                    : t('Potvrdit založení nové osoby')}
                onClick={onPair}>{!!foundParty.partyId
                ? <CheckBoxOutlineBlankRounded/>
                : <CheckBoxOutlineBlankRounded/>}</Button>
            : <Typography component={'div'} className={'MuiButtonBase-root MuiButton-root'}
                style={{color: isLikelyValid ? 'var(--color-success)' : 'var(--color-warning)'}}
                title={!!foundParty.partyId
                    ? (foundParty.partyId === item.partyId ? t('Spárováno dle ID v souboru') : t('Bude spárováno s touto osobou'))
                    : t('Bude založena jako nová osoba')}
            >{!!foundParty.partyId
                ? (foundParty.partyId === item.partyId ? <DoneRounded/> : <LinkRounded/>)
                : <PlusOneRounded/>}</Typography>)}
        <PartyPhotoTooltip photoGuid={photoGuid}
            icon={<div><PartyPhoto photoGuid={photoGuid} maxHeight={24} maxWidth={40} changed={!foundParty.partyId || photoGuid !== foundParty.photoGuid}/></div>}
            emptyIcon={<div><PartyPhoto photoGuid={undefined} maxHeight={24} maxWidth={40}/></div>}
        />
        <div>
            {(!!foundParty.partyId || !!resultParty?.partyId) && <>
                {lastName !== foundParty.lastName || !foundParty.partyId
                    ? <span style={{color: 'var(--color-warning)'}} title={foundParty.lastName}>{lastName}</span>
                    : <span>{lastName}</span>}
				&nbsp;
                {firstName !== foundParty.firstName || !foundParty.partyId
                    ? <span style={{color: 'var(--color-warning)'}} title={foundParty.firstName}>{firstName}</span>
                    : <span>{firstName}</span>}
			</>}
            <div>
                {!foundParty.partyId && <Alert severity={'warning'} icon={false}>
                    {!!resultParty?.partyId ? t('Bude založena nová osoba') : t('Potvrdit založení nové osoby')}
				</Alert>}
                <FoundPartyEventInfo foundParty={foundParty} eventId={eventId}/>
                {(!!foundParty.partyId || !!resultParty?.partyId) && (email !== foundParty.email || !foundParty.partyId
                    ? <div className='event-details' style={{color: 'var(--color-warning)'}} title={foundParty.email}>{email}</div>
                    : <div className='event-details'>{email}</div>)}
            </div>
        </div>
    </Box>;
}

const PartyImportStatus = ({item, eventId, readonly}: { item: JsonEventPartyImport, eventId?: number, setEditItem: (item: JsonEventPartyImport) => void, readonly: boolean }) => {
    const t = useAppTranslation();
    const {values, setFieldValue} = useFormikContext<JsonEventPartyImport[]>();

    if (!!item.saveErrors) {
        const m: JSX.Element[] = [];
        item.saveErrors.forEach((err, i) => {
            const values = (err.values as any);
            let e;
            if (err.errorCode === 'INVALID_PARAMETER' && values?.error === 'VALIDATION_ERROR') {
                e = values.description + ' (' + values.invalidValue + ')';
            } else {
                e = t('Chyba') + ' ' + JSON.stringify(err);
            }
            let c = '';
            if (values.parameterName) {
                c = values.parameterName + ': ';
            } else if (values.cell !== undefined) {
                c = String.fromCharCode('A'.charCodeAt(0) + values.cell) + ': ';
            }
            m.push(<div key={i}><small>{c}{e}</small></div>)
        });
        return <Typography color={(theme) => theme.palette['error'].main} component={'div'}>
            {m}
        </Typography>
    }
    if (readonly) {
        return null;
    }

    if (!!item.resultParty?.partyId) {
        if (item.resultParty.partyId === FAKE_VALUE_EMPTY) {
            return <FoundPartyBox item={item} foundParty={item as JsonPartyInfo} resultParty={item.resultParty} eventId={eventId} readonly={readonly}/>;
        }
        const p = item.foundParties?.find((fp) => fp.party?.partyId === item.resultParty?.partyId)?.party;
        if (p) {
            return <FoundPartyBox item={item} foundParty={p} resultParty={item.resultParty} eventId={eventId} readonly={readonly}/>;
        }
    }

    return (item.foundParties?.length && item.foundParties?.length > 0)
        ? <>
            {item.foundParties.length > 1 && <div style={{padding: '0 5px 5px 5px', fontSize: '80%', color: 'var(--color-warning)'}}>
                {t('Nalezeno více možných shod')}
			</div>}
            {item.foundParties.length === 1 && !!item.email && !!item.foundParties[0].party?.email
                && item.email.toLowerCase() !== item.foundParties[0].party?.email?.toLowerCase()
                && <div style={{padding: '0 5px 5px 5px', fontSize: '80%', color: 'var(--color-error)'}}>
                    {t('Rozdíl v emailové adrese')}
				</div>}
            {item.foundParties.map((foundParty, i) => {
                return <FoundPartyBox key={i} item={item} foundParty={foundParty.party as JsonPartyInfo} eventId={eventId} onPair={() => {
                    const index = values.findIndex((p) => p.rowNo === item.rowNo);
                    setFieldValue(index + '.resultParty.partyId', foundParty.party?.partyId);
                }} readonly={readonly}/>
            })}</>
        : <FoundPartyBox item={item} foundParty={item as JsonPartyInfo} eventId={eventId} onPair={() => {
            const index = values.findIndex((p) => p.rowNo === item.rowNo);
            setFieldValue(index + '.resultParty.partyId', FAKE_VALUE_EMPTY);
            // setEditItem(item);
        }} readonly={readonly}/>;
}

const createColumn = (columnType: ColumnType): DataGridCol<JsonEventPartyImport, LocalFilter> => {
    switch (columnType) {
        // case ColumnType.EmsId:
        //     return createCol('ID', 'partyId', 30, 'EMS ID', (v) => <small>{v}</small>);
        // case ColumnType.SiwiId:
        //     return createCol('SIWI', 'siwiId', 40, 'Siwidata ID', (v) => v?.length && v?.length > 8
        //         ? <small title={v}>{v.substring(0, 3) + '..' + v.substring(v.length - 3)}</small>
        //         : <small>{v}</small>);
        case ColumnType.LastName:
            return createCol('Jméno', 'lastName', 85, 'Příjmení a křestní jméno', (v, item) => <strong>{item.lastName}<br/>{item.firstName}</strong>);
        case ColumnType.Sex:
            return createCol('P', 'sex', '20C', 'Pohlaví', (v) => v ? <small><CodebookValue value={v} name={'sex'} formatValue={(v) => v[0]}/></small> : null);
        case ColumnType.BirthDate:
            return createCol('R', 'birthDate', '35C', 'Rok narození', (v) => v ? <small>{dateToGuiAs(v, 'Y')}</small> : null);
        case ColumnType.Email:
            return createCol('Email', 'email', 155, undefined, (v) => <small>{v}</small>);
        case ColumnType.Phone:
            return createCol('Telefon', 'phone', '80C', undefined, (v) => <small>{v}</small>);
        case ColumnType.Address:
            return createCol('Adresa', 'permAddress', 170, undefined, (v) => {
                return v ? <small>{v.street}<br/><span style={{whiteSpace: "nowrap"}}>{v.zip}</span> {v.city}</small> : null;
            });
        case ColumnType.BankAccount:
            return createCol('Účet', 'bankAccount', 130, undefined, (v) => <small>{v}</small>);
        case ColumnType.Group:
            return createCol('Skupina', 'groupId', '100C', 'Pracovní skupina', (v) => v ? <small><CodebookValue value={v} name={'group'}/></small> : null);
        case ColumnType.Tags:
            return createCol('Štítky', 'tags', 130, undefined, (v) => <PartyTags tags={v}/>);
        case ColumnType.Company:
            return createCol('Firma', 'companyId', 80, undefined, (v) => <small><CodebookValue value={v} name={'company'}/></small>);
        case ColumnType.Note:
            return createCol('Poznámka', 'note', 130, undefined, (v) => <small>{v}</small>);
        default:
            return undefined;
    }
}

const getChangeCounts = (partyResItems: JsonParty[], eventPartyResItems?: JsonEventParty[]) => {
    const newPartyIds = (partyResItems
        ?.filter((p) => (p.changes?.filter((c) => c.name === 'Party[*]')?.length || 0) > 0)?.map(p => p.partyId)) || [];
    const changedPartyIds = (partyResItems
        ?.filter((p) => newPartyIds.indexOf(p.partyId) < 0 && (p.changes?.length || 0) > 0)?.map(p => p.partyId)) || [];
    const newEventPartyIds = (eventPartyResItems
        ?.filter((p) => newPartyIds.indexOf(p.partyId) < 0 && (p.changes?.filter((c) => c.name === 'EventParty[*]')?.length || 0) > 0)?.map(p => p.partyId)) || [];
    const changedEventPartyIds = (eventPartyResItems
        ?.filter((p) => newPartyIds.indexOf(p.partyId) < 0 && newEventPartyIds.indexOf(p.partyId) < 0 && (p.changes?.length || 0) > 0)?.map(p => p.partyId)) || [];

    return {newPartyIds, changedPartyIds, newEventPartyIds, changedEventPartyIds}
}

const PartyImportForm = (props: Props) => {
    const {filter, onSave, onSetMaxWidth} = props;
    const {eventId} = filter;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [articleTypes, setArticleTypes] = useState<JsonArticleTypeInfo[] | undefined>(undefined);
    const [articles, setArticles] = useState<JsonArticleInfo[] | undefined>(undefined);
    const [showMode, setShowMode] = useState<ShowMode>('all');
    const [importItems, setImportItems] = useState<JsonEventPartyImport[] | undefined>(undefined);
    const [importColumns, setImportColumns] = useState<ColumnType[] | undefined>(undefined);
    const [readonly, setReadonly] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<JsonEventPartyImport | undefined>(undefined);

    const handleFileUpload = useCallback(async (values: PrepareEventPartyImportUsingPOSTRequest) => {
        const res = await dispatch(prepareEventPartyImport(values));
        const result = getApiResult<JsonImportResultOfstringAndJsonEventPartyImport>(res);
        if (result?.rows) {
            const hasAnyError = result.rows.find((imp) => (imp.saveErrors?.length && imp.saveErrors.length > 0));
            if (hasAnyError) {
                setReadonly(true);
                setShowMode('invalid');
            }
            setImportItems(result.rows);
            setImportColumns(result.columnTypes);
        }

    }, [dispatch])

    const uploadFormValidate = useCallback((values: PrepareEventPartyImportUsingPOSTRequest) => {
        let errors = {} as FormikErrors<PrepareEventPartyImportUsingPOSTRequest>;
        if (!values.file) {
            errors.file = t('Vložte prosím soubor');
        }
        return errors;
    }, [t]);

    const cols: DataGridCol<JsonEventPartyImport, LocalFilter>[] = useMemo(() => {
        const cols: DataGridCol<JsonEventPartyImport, LocalFilter>[] = [
            createCol('#', 'rowNo', 30, 'Řádek', (v) => <small>{v}</small>),
        ];

        importColumns?.forEach((ct) => {
            if (ColumnType.Article === ct) {
                articleTypes?.forEach((at) => {
                    cols.push({
                        title: at.title?.[0] || '',
                        tooltip: at.title,
                        size: 25,
                        align: 'center',
                        col: 'articles',
                        renderValue: (v, item) => {
                            const id = v?.find((a) => a.articleTypeId === at.articleTypeId)?.prefArticleId;
                            return id ? <small>{articleName(articles?.find((a) => a.articleId === id), true, item.sex)}</small> : null;
                        }
                    });
                })
            }
            const col = createColumn(ct);
            if (col) {
                cols.push(col);
            }
        })
        cols.push({
            title: readonly ? 'Chyby' : 'Párování / založení',
            size: 200,
            col: 'saveErrors',
            renderValue: (_, item) => <PartyImportStatus item={item} eventId={eventId} setEditItem={setEditItem} readonly={readonly}/>
        });
        return cols;
    }, [importColumns, readonly, articleTypes, articles, eventId]);

    const filterItems = useCallback((items: JsonEventPartyImport[], showMode?: ShowMode) => {
        let totalCount = 0, validCount = 0, errorCount = 0, needActionCount = 0;
        const filtered = items.filter((item) => {
            totalCount++;
            if (!!item.saveErrors) {
                errorCount++;
                return showMode === 'all' || showMode === 'invalid';

            } else if (!!item.resultParty?.partyId) {
                validCount++;
                return showMode === 'all' || showMode === 'valid';

            } else {
                needActionCount++;
                return showMode === 'all' || showMode === 'needAction';
            }
        })
        return {filtered, totalCount, validCount, errorCount, needActionCount};
    }, []);

    const initialValues: JsonEventPartyImport[] = useMemo(() => {
        if (!importItems) {
            return [];
        }
        return importItems.map((p) => {
            return {
                ...p,
                resultParty: {
                    partyId: p.partyId
                }
            };
        });

    }, [importItems]);

    const actions: DataGridItemAction<JsonEventPartyImport>[] | undefined = useMemo(() => {
        if (readonly) {
            return undefined;
        }
        return [{
            title: 'Upravit',
            icon: <Edit/>,
            color: 'info',
            callback: setEditItem,
            // isApplicable: (item) => !item.partyId
        }]
    }, [readonly, setEditItem]);

    const children = useCallback(({values, setFieldValue}: FormikProps<JsonEventPartyImport[]>) => {
        const {filtered, totalCount, validCount, errorCount, needActionCount} = filterItems(values, showMode);
        const showModeOptions: OptionValue[] = [
            createOption('all', 'Všechny záznamy', 'Zobrazit vše',
                <span style={{padding: '0 5px'}}>{totalCount}</span>, 'secondary'),
        ];

        let severity: AlertColor;
        const alerts: JSX.Element[] = [];
        if (errorCount > 0) {
            showModeOptions.push(createOption('invalid', 'Jen s chybou', 'Zobrazit jen osoby s chybou',
                <span style={{padding: '0 5px'}}>{errorCount}</span>, 'error'));
            alerts.push(<span key={1}>{t('V souboru byly nalezeny neplatné záznamy (celkem {{errorCount}}), problémy jsou uvedeny ve sloupci "Chyby". ' +
                'Opravte prosím soubor a import opakujte.', {errorCount})}</span>);
            severity = 'warning';
        } else {
            showModeOptions.push(createOption('needAction', 'Záznamy k potvrzení', 'Zobrazit jen osoby s chybou',
                <span style={{padding: '0 5px'}}>{needActionCount}</span>, 'warning'));
            showModeOptions.push(createOption('valid', 'Potvrzené záznamy', 'Zobrazit jen osoby v pořádku',
                <span style={{padding: '0 5px'}}>{validCount}</span>, 'success'));
            if (needActionCount) {
                alerts.push(<span key={1}>{t('Záznamy ({{needActionCount}}) je nutné postupně označit tlačítkem ve sloupci "Párování / založení", ' +
                    'zda se shodují s existující osobou, nebo potvrdit založení nové osoby. ' +
                    'Základní údaje (vč. fotografie) je možné upravit tlačítkem "✎".', {needActionCount})}</span>);
            } else {
                alerts.push(<span key={1}>{t('Zkontrolujte prosím, že všechny údaje jsou v pořádku, a uložte osoby tlačítkem níže.', {needActionCount})}</span>);
            }
            severity = 'info';
        }

        const itemsState = {
            loading: false,
            count: 0,
            items: filtered,
            filter: {showMode}
        } as ItemsState<JsonEventPartyImport, LocalFilter>;

        return <Grid item xs={12} className={'import-grid'}>
            <Grid container style={{paddingBottom: '5px'}}>
                <Grid item xs={showModeOptions.length > 1 ? 8 : 12}>
                    <Alert severity={severity}>
                        {alerts}
                    </Alert>
                </Grid>
                {showModeOptions.length > 1 && <Grid item xs={4} sx={{paddingLeft: "10px"}}>
					<ButtonGroupPlain name={'showMode'} options={showModeOptions} currentValue={showMode} onChange={(v) => {
                        setShowMode(v || showMode);
                    }} fullWidth/>
				</Grid>}
            </Grid>
            <DataGrid
                cols={cols}
                defaultState={defaultState}
                itemsState={itemsState}
                actions={actions}
                mode={DataGridMode.CLIENT}
                tableProps={{
                    minHeight: 'clamp(300px, calc(100vh - 350px), 1000px)',
                    maxHeight: "clamp(300px, calc(100vh - 350px), 1000px)"
                }}
                emptyListMessage={t('Nenalezeny žádné záznamy')}
                getRowClassNames={getRowClassNames}
            />
            {!!editItem && <PartyImportEditModal
				columnTypes={importColumns}
				eventId={eventId}
				item={editItem}
				original={initialValues.find((p) => p.rowNo === editItem?.rowNo)}
				onSave={(party) => {
                    const index = values.findIndex((p) => p.rowNo === party.rowNo);
                    setFieldValue(index + '', party);
                    setEditItem(undefined)
                }}
				onCancel={() => setEditItem(undefined)}
			/>}
        </Grid>
    }, [showMode, editItem, cols, actions, filterItems, initialValues, importColumns, eventId, t]);

    const formActions = useCallback(({values, isSubmitting}: FormikProps<JsonEventPartyImport[]>, props: FormProps<JsonEventPartyImport[]>) => {
        const {validCount, errorCount, needActionCount} = filterItems(values);
        const isSubmittable = validCount > 0 && !needActionCount && !errorCount;
        return <>
            <Grid item sx={{flexGrow: 1}}>
                {!errorCount && !!needActionCount && <Alert severity={'warning'} className={'event-action-errors'}>
                    {t('Zbývá potvrdit zpracování {{count}} osob', {count: needActionCount})}</Alert>}
                {isSubmittable && <Alert severity={'info'} className={'event-action-errors'}>
                    {t('Všechny osoby připraveny k uložení ({{count}})', {count: validCount})}</Alert>}
            </Grid>
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{t('Storno')}</Button>
			</Grid>}
            <Grid item>
                {!errorCount && <Button variant="contained" type="submit" color={'success'} disabled={!isSubmittable || isSubmitting}>
                    {t('Pokračovat na shrnutí')}
				</Button>}
            </Grid>
        </>
    }, [filterItems, t]);

    const runImport = useCallback(async (partyItems: JsonParty[], eventPartyItems: JsonEventParty[], testOnly: boolean) => {
        // save parties
        const partyRes = await dispatch(massParty({
            request: {
                action: JsonPartyMassActionRequestActionEnum.Import,
                items: partyItems
            },
            testOnly
        }));
        const partyResItems = getApiResult<JsonParty[]>(partyRes);
        if (!partyResItems || eventPartyItems.length <= 0) {
            // error or no event parties to import
            return {partyRes, partyResItems};
        }
        // map parties to event parties
        eventPartyItems.forEach((ep) => {
            const p = partyResItems.find((p) => p.rowNo === ep.rowNo);
            if (!p) {
                window.alert("Osoba na ř. " + ep.rowNo + " nebyla korektně založena"); // XXX
                return;
            }
            if (!ep.partyId) {
                if (!testOnly) {
                    ep.partyId = p.partyId; // just created
                }

            } else if (ep.partyId !== p.partyId) {
                window.alert("Osoba na ř. " + ep.rowNo + " nebyla korektně napárována na osobu v události"); // XXX
                return;
            }
        });
        // save event parties
        const eventPartyRes = await dispatch(massEventParty({
            request: {
                action: JsonEventPartyMassActionRequestActionEnum.Import,
                eventId,
                items: eventPartyItems.filter((ep) => !!ep.partyId)
            },
            testOnly
        }));
        const eventPartyResItems = getApiResult<JsonEventParty[]>(eventPartyRes);

        return {partyRes, partyResItems, eventPartyRes, eventPartyResItems};

    }, [eventId, dispatch]);

    const handleSave = useCallback(async (values: JsonEventPartyImport[]) => {
        // party request
        const partyItems: JsonParty[] = [];
        const eventPartyItems: JsonEventParty[] = [];
        values.forEach((imp) => {
            const resultParty = imp.resultParty;
            if (!resultParty || !resultParty?.partyId) {
                window.alert("Zkontrolujte zpracování osoby na ř. " + imp.rowNo); // XXX
                return;
            }
            let partyItem: JsonParty = {
                rowNo: imp.rowNo,
                birthDate: imp.birthDate,
                email: imp.email,
                phone: imp.phone,
                permAddress: imp.permAddress,
                bankAccount: imp.bankAccount,
                note: imp.note,
                companyId: imp.companyId,
                tags: imp.tags,
            };
            let eventPartyItem: JsonEventParty = {
                rowNo: imp.rowNo,
                eventId,
                groupId: imp.groupId,
                note: imp.note,
                prefArticles: imp.articles?.map((a) => {
                    const article = articles?.find((article) => article.articleId === a.prefArticleId)
                    return {
                        articleTypeId: a.articleTypeId,
                        articleId: a.prefArticleId,
                        sex: article?.sex ? JsonArticleSexEnum[article.sex] : undefined, // will fail
                        size: article?.size,
                    }
                })
            };
            if (resultParty?.partyId === FAKE_VALUE_EMPTY) {
                partyItem = {
                    ...partyItem,
                    partyId: undefined,
                    firstName: imp.firstName,
                    lastName: imp.lastName,
                    sex: imp.sex ? JsonPartySexEnum[imp.sex] : undefined,
                    photoGuid: imp.photoGuid,
                }

                eventPartyItem = {
                    ...eventPartyItem,
                    firstName: partyItem.firstName,
                    lastName: partyItem.lastName,
                    eventPartyId: undefined,
                    partyId: undefined,
                }

            } else {
                const foundParty = imp.foundParties?.find((fp) => fp.party?.partyId === resultParty.partyId)?.party;
                if (!foundParty) {
                    window.alert("Zkontrolujte napárování osoby na ř. " + imp.rowNo); // XXX
                    return;
                }
                partyItem = {
                    ...partyItem,
                    partyId: foundParty.partyId,
                    firstName: !!resultParty.firstName && resultParty.firstName !== foundParty.firstName ? resultParty.firstName : undefined, // set only if changed
                    lastName: !!resultParty.lastName && resultParty.lastName !== foundParty.lastName ? resultParty.lastName : undefined, // set only if changed
                    sex: !!resultParty.sex && resultParty.sex !== foundParty.sex ? JsonPartySexEnum[resultParty.sex] : undefined, // set only if changed
                    photoGuid: !!resultParty.photoGuid && resultParty.photoGuid !== foundParty.photoGuid ? resultParty.photoGuid : undefined, // set only if changed
                }

                eventPartyItem = {
                    ...eventPartyItem,
                    firstName: partyItem.firstName,
                    lastName: partyItem.lastName,
                    eventPartyId: foundParty.eventDetails?.find((ed) => ed.eventId === eventId)?.eventPartyId,
                    partyId: foundParty.partyId,
                }
            }
            partyItems.push(partyItem);
            if (!!eventId) {
                eventPartyItems.push(eventPartyItem);
            }
        });

        const {partyRes, partyResItems, eventPartyRes, eventPartyResItems} = await runImport(partyItems, eventPartyItems, true);
        if (partyResItems === undefined || (!!eventId && eventPartyResItems === undefined)) {
            // error
            return;
        }

        if ((partyRes?.payload as any)?.message !== ApiChangeType.UPDATED && (eventPartyRes?.payload as any)?.message !== ApiChangeType.UPDATED) {
            addApiResultMessage(partyRes, {
                [ApiChangeType.NO_CHANGE]: t('Importem nedojde k žádným změnám'),
            }, t, dispatch);
            return;
        }

        const {newPartyIds, changedPartyIds, newEventPartyIds, changedEventPartyIds} = getChangeCounts(partyResItems, eventPartyResItems);

        const result = await modal.confirm({
            title: t('Povtrzení uložení'),
            message: <div>
                <p>{t('Uložením všech záznamů ({{totalCount}}) dojde k následujícím změnám', {totalCount: partyItems.length})}:</p>
                <table style={{marginTop: '15px'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '80%'}}>{t('Založení nových osob a jejich vložení do události')}</td>
                        <th><Chip size={'small'} label={newPartyIds.length} color={'warning'}/></th>
                    </tr>
                    <tr>
                        <td>{t('Úprava údajů existujících osob')}</td>
                        <th><Chip size={'small'} label={changedPartyIds.length} color={'info'}/></th>
                    </tr>
                    {!!eventId && <tr>
						<td>{t('Přidání existujících osob do události')}</td>
						<th><Chip size={'small'} label={newEventPartyIds.length} color={'warning'}/></th>
					</tr>}
                    {!!eventId && <tr>
						<td>{t('Úprava existujících osob v události')}</td>
						<th><Chip size={'small'} label={changedEventPartyIds.length} color={'info'}/></th>
					</tr>}
                    </tbody>
                </table>
            </div>,
            cancelText: 'Zpět',
            confirmColor: 'success',
            confirmText: 'Uložit všechny osoby',
            confirmIcon: <DoneRounded/>,
        } as ModalProps);
        if (result === 'CONFIRM') {
            const {partyRes, partyResItems, eventPartyRes, eventPartyResItems} = await runImport(partyItems, eventPartyItems, false);

            if (partyResItems) {
                const {newPartyIds, changedPartyIds, newEventPartyIds, changedEventPartyIds} = getChangeCounts(partyResItems, eventPartyResItems);

                const h: [string, (item: any) => any] = ['Změny byly uloženy ({{title}})',
                    () => t(!!eventId
                        ? 'nové osoby {{a}}, upravené osoby {{b}}, vložené do události {{c}}, upravené v události {{d}}'
                        : 'nové osoby {{a}}, upravené osoby {{b}}', {
                        a: newPartyIds.length,
                        b: changedPartyIds.length,
                        c: newEventPartyIds.length,
                        d: changedEventPartyIds.length,
                    })];
                if ((eventPartyRes as any)?.payload?.message === ApiChangeType.UPDATED) {
                    (partyRes as any).payload.message = ApiChangeType.UPDATED; // force updated even if event parties were not
                }
                addApiResultMessage(partyRes, {
                    [ApiChangeType.NO_CHANGE]: t('Importem nedošlo k žádným změnám'),
                    [ApiChangeType.UPDATED]: h,
                }, t, dispatch);

                if (onSave) {
                    onSave({parties: partyResItems, eventParties: eventPartyItems}, filter);
                }
            }
        }

    }, [articles, onSave, eventId, filter, runImport, modal, dispatch, t]);

    const hasImportItems = (importItems !== undefined);
    useEffect(() => {
        onSetMaxWidth((importItems !== undefined) ? 'lg' : 'sm');
    }, [importItems, onSetMaxWidth]);

    useEffect(() => {
        dispatch(fetchArticleTypes({eventId})).then((res) => {
            setArticleTypes(getApiResult<JsonArticleTypeInfo[]>(res));
        });
        dispatch(fetchArticles({eventId})).then((res) => {
            setArticles(getApiResult<JsonArticleInfo[]>(res));
        });
    }, [eventId, dispatch]);

    if (!hasImportItems) {
        return <FormContainer
            key={'upload'}
            item={{eventId} as PrepareEventPartyImportUsingPOSTRequest}
            validate={uploadFormValidate}
            children={() => {
                return <>
                    <Grid item xs={12}>
                        <p>{t('Nahrajte prosím soubor v požadované struktuře pro zahájení importu osob.')}</p>
                        {!!eventId
                            ? <p><strong>{t('Všechny osoby budou automaticky zaregistrovány do události')}
                                &nbsp;<CodebookValue value={eventId} name={'event'}/></strong>.</p>
                            : <p><strong>{t('Vkládané osoby nebudou registrovány do žádné události (pokud již nejsou)')}</strong>.</p>}
                        <p dangerouslySetInnerHTML={{__html: t('Soubor musí být ve formátu <kbd>XLS</kbd> nebo <kbd>XLSX</kbd>.')}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FileFormField name={'file'} acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}/>
                    </Grid>
                </>
            }}
            saveButtonTitle={t('Pokračovat')}
            onCancel={props.onCancel}
            onSave={handleFileUpload}
        />;
    }

    return <>
        <FormContainer
            key={'import'}
            item={initialValues}
            actions={formActions}
            children={children}
            onCancel={props.onCancel}
            onSave={handleSave}
        />
    </>;
}

export default PartyImportForm;
