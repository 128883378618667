import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {JsonEventPartyInfo, JsonTariffInfo} from "../../generated-api";
import {formatMoney, getOption} from "../../model/form";
import {useAppTranslation} from "../../services/i18n";
import {vehicleOptions} from "../../model/invite";
import {datetimeToGui, dateToGuiAs} from "../../helpers/date";

type PartyCompDetailsProps = {
    eventParty: JsonEventPartyInfo,
    tariffs?: JsonTariffInfo[],
    withAccount?: boolean
}

export const PartyCompDetails = (props: PartyCompDetailsProps) => {
    const {eventParty, tariffs, withAccount} = props;

    const t = useAppTranslation();

    const items: JSX.Element[] = [];

    let total = 0;

    if (eventParty.eventCompDetails?.length && eventParty.eventCompDetails.length > 0) {
        items.push(<TableContainer key={0} component={Paper} sx={{order: 50}}>
            <Table className={'data-grid'}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: '50%'}}><span>{t('Tarif')}</span></TableCell>
                        <TableCell sx={{width: '13%', textAlign: 'right'}}><span>{t('Sazba')}</span></TableCell>
                        <TableCell sx={{width: '14%', textAlign: 'right'}}><span>{t('Jednotek')}</span></TableCell>
                        <TableCell sx={{width: '15%', textAlign: 'right'}}><span>{t('Odměna')}</span></TableCell>
                        <TableCell sx={{width: '8%'}}><span></span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventParty.eventCompDetails.map((epc, i) => {
                        const t = tariffs?.find((t) => t.tariffId === epc.tariffId);
                        total += epc.compAmount || 0;
                        return <TableRow key={epc.eventPartyCompId}>
                            <TableCell>
                                <span>{epc.title || t?.unitTitle || epc.tariffId}</span>
                            </TableCell>
                            <TableCell sx={{textAlign: 'right'}}>
                                <span>{formatMoney(epc.unitAmount || t?.unitRate || 0, true)}</span>
                            </TableCell>
                            <TableCell sx={{textAlign: 'right'}}>
                                <span>{epc.unitCnt || 0}</span>
                            </TableCell>
                            <TableCell sx={{textAlign: 'right'}}>
                                <span>{formatMoney(epc.compAmount || 0, true)}</span>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>;
                    })}
                    <TableRow>
                        <TableCell colSpan={3}><span><strong>{t('Celkem odměny')}</strong></span></TableCell>
                        <TableCell sx={{textAlign: 'right'}}>
					<span><strong>{formatMoney(eventParty.eventCompDetails.reduce(
                        (s, epc) => s + (epc.compAmount || 0), 0) || 0, true)}
					</strong></span>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>);
    }

    if (eventParty.eventTravelDetails?.length && eventParty.eventTravelDetails.length > 0) {
        items.push(<TableContainer key={1} component={Paper} sx={{order: 50}}>
            <Table className={'data-grid'}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: '7%'}}><span title={t('Dopravní prostředek')}>P</span></TableCell>
                        <TableCell sx={{width: '10%'}}><span>{t('Odjezd')}</span></TableCell>
                        <TableCell sx={{width: '20%'}}><span>{t('Z')}</span></TableCell>
                        <TableCell sx={{width: '10%'}}><span>{t('Příjezd')}</span></TableCell>
                        <TableCell sx={{width: '20%'}}><span>{t('Do')}</span></TableCell>
                        <TableCell sx={{width: '10%'}}><span></span></TableCell>
                        <TableCell sx={{width: '15%', textAlign: 'right'}}><span>{t('Náhrada')}</span></TableCell>
                        <TableCell sx={{width: '8%'}}><span></span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventParty.eventTravelDetails.map((ept, i) => {
                        const o = getOption(ept.vehicle, vehicleOptions);
                        total += ept.compAmount || 0;
                        return <TableRow key={ept.eventPartyTravelId}>
                            <TableCell>
                                <span title={o.label + (!!ept.plateNum ? ' ' + ept.plateNum : '')}>{o.icon || ept.vehicle}</span>
                            </TableCell>
                            <TableCell>
                                <span title={datetimeToGui(ept.beginDate, ept.beginTime)}>{dateToGuiAs(ept.beginDate, 'd. M.')}</span>
                            </TableCell>
                            <TableCell>
                                <span>{ept.beginLocation}</span>
                            </TableCell>
                            <TableCell>
                                <span title={datetimeToGui(ept.endDate, ept.endTime)}>{dateToGuiAs(ept.endDate, 'd. M.')}</span>
                            </TableCell>
                            <TableCell>
                                <span>{ept.endLocation}</span>
                            </TableCell>
                            <TableCell>
						<span>
							{!!ept.kmCnt && <>{ept.kmCnt} km</>}
						</span>
                            </TableCell>
                            <TableCell sx={{textAlign: 'right'}}>
                                <span>{formatMoney(ept.compAmount || 0, true)}</span>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>;
                    })}
                    <TableRow>
                        <TableCell colSpan={6}><span><strong>{t('Celkem náhrady')}</strong></span></TableCell>
                        <TableCell sx={{textAlign: 'right'}}>
					<span><strong>{formatMoney(eventParty.eventTravelDetails.reduce(
                        (s, ept) => s + (ept.compAmount || 0), 0) || 0, true)}
					</strong></span>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>);
    }

    if (items.length > 0) {
        items.push(<TableContainer key={'total'} component={Paper} sx={{order: 50}}>
            <Table className={'data-grid'}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: '77%', textAlign: 'left'}}><span><strong>{withAccount && !!eventParty.bankAccount
                            ? t('Celkem k výplatě na účet {{bankAccount}}', eventParty)
                            : t('Celkem k výplatě')}</strong></span></TableCell>
                        <TableCell sx={{width: '15%', textAlign: 'right'}}>
					<span><strong>{formatMoney(total, true)}
					</strong></span>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </TableContainer>);
    }

    return items.length > 0 ? <>{items}</> : null;
}