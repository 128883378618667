/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonArticle,
    JsonArticleFromJSON,
    JsonArticleFromJSONTyped,
    JsonArticleToJSON,
} from './JsonArticle';

/**
 * 
 * @export
 * @interface JsonArticleMassActionRequest
 */
export interface JsonArticleMassActionRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonArticleMassActionRequest
     */
    action?: JsonArticleMassActionRequestActionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonArticleMassActionRequest
     */
    ids?: Array<string>;
    /**
     * 
     * @type {Array<JsonArticle>}
     * @memberof JsonArticleMassActionRequest
     */
    items?: Array<JsonArticle>;
}

/**
* @export
* @enum {string}
*/
export enum JsonArticleMassActionRequestActionEnum {
    Save = 'SAVE'
}

export function JsonArticleMassActionRequestFromJSON(json: any): JsonArticleMassActionRequest {
    return JsonArticleMassActionRequestFromJSONTyped(json, false);
}

export function JsonArticleMassActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonArticleMassActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(JsonArticleFromJSON)),
    };
}

export function JsonArticleMassActionRequestToJSON(value?: JsonArticleMassActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'ids': value.ids,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(JsonArticleToJSON)),
    };
}

