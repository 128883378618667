/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonContingent,
    ApiResultOfJsonContingentFromJSON,
    ApiResultOfJsonContingentToJSON,
    ApiResultOfJsonContingentInfo,
    ApiResultOfJsonContingentInfoFromJSON,
    ApiResultOfJsonContingentInfoToJSON,
    ApiResultOfListOfJsonContingentInfo,
    ApiResultOfListOfJsonContingentInfoFromJSON,
    ApiResultOfListOfJsonContingentInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonContingent,
    JsonContingentFromJSON,
    JsonContingentToJSON,
} from '../models';

export interface GetContingentCountUsingGETRequest {
    eventId?: number;
    search?: string;
}

export interface GetContingentInfoUsingGETRequest {
    contingentId: number;
}

export interface GetContingentListUsingGETRequest {
    eventId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetContingentUsingGETRequest {
    contingentId: number;
}

export interface SaveContingentUsingPOSTRequest {
    json: JsonContingent;
    testOnly?: boolean;
}

/**
 * 
 */
export class ContingentControllerApi extends runtime.BaseAPI {

    /**
     * getContingentCount
     */
    async getContingentCountUsingGETRaw(requestParameters: GetContingentCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/contingent/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getContingentCount
     */
    async getContingentCountUsingGET(requestParameters: GetContingentCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getContingentCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getContingentInfo
     */
    async getContingentInfoUsingGETRaw(requestParameters: GetContingentInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonContingentInfo>> {
        if (requestParameters.contingentId === null || requestParameters.contingentId === undefined) {
            throw new runtime.RequiredError('contingentId','Required parameter requestParameters.contingentId was null or undefined when calling getContingentInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/contingent/info/{contingentId}`.replace(`{${"contingentId"}}`, encodeURIComponent(String(requestParameters.contingentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonContingentInfoFromJSON(jsonValue));
    }

    /**
     * getContingentInfo
     */
    async getContingentInfoUsingGET(requestParameters: GetContingentInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonContingentInfo> {
        const response = await this.getContingentInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getContingentList
     */
    async getContingentListUsingGETRaw(requestParameters: GetContingentListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonContingentInfo>> {
        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/contingent/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonContingentInfoFromJSON(jsonValue));
    }

    /**
     * getContingentList
     */
    async getContingentListUsingGET(requestParameters: GetContingentListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonContingentInfo> {
        const response = await this.getContingentListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getContingent
     */
    async getContingentUsingGETRaw(requestParameters: GetContingentUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonContingent>> {
        if (requestParameters.contingentId === null || requestParameters.contingentId === undefined) {
            throw new runtime.RequiredError('contingentId','Required parameter requestParameters.contingentId was null or undefined when calling getContingentUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/contingent/{contingentId}`.replace(`{${"contingentId"}}`, encodeURIComponent(String(requestParameters.contingentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonContingentFromJSON(jsonValue));
    }

    /**
     * getContingent
     */
    async getContingentUsingGET(requestParameters: GetContingentUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonContingent> {
        const response = await this.getContingentUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveContingent
     */
    async saveContingentUsingPOSTRaw(requestParameters: SaveContingentUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonContingent>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveContingentUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/contingent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonContingentToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonContingentFromJSON(jsonValue));
    }

    /**
     * saveContingent
     */
    async saveContingentUsingPOST(requestParameters: SaveContingentUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonContingent> {
        const response = await this.saveContingentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
