import {Box, Button, CircularProgress, Grid} from '@mui/material';
import {FormikErrors, FormikProps, useFormikContext} from 'formik';
import {FormProps} from "../../model/form";
import {GetEventPartyListUsingGETStatusesEnum, JsonEmailMessage, JsonEventGroup, JsonEventPartyInfo, JsonFile, PreviewNotificationUsingPOSTNotificationTypeEnum} from "../../generated-api";
import {useAppTranslation} from "../../services/i18n";
import FormContainer from "../form/FormContainer";
import {TextFormField} from "../form/TextFormField";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {useAppDispatch} from "../../store";
import {getApiResult} from "../../helpers/api";
import {previewNotification} from "../../store/notifications";
import {EmailPreview} from "../EmailPreview";
import {AttachFileRounded, DeleteForeverRounded, SendRounded} from "@mui/icons-material";
import {ModalProps, useModal} from "../../services/modal";
import {CheckboxField} from "../form/CheckboxField";
import {fetchEventParties} from "../../store/eventParties";
import {renderFile} from "../../helpers/files";
import FileModal from "../../pages/FileModal";

const Attachments = () => {

    const t = useAppTranslation();
    const {values, setFieldValue} = useFormikContext<JsonEventGroup>();
    const [editFiles, setEditFiles] = useState<JsonFile[] | undefined>(undefined);
    const files = values.settings?.attachments;

    const handleEditFiles = useCallback((files?: JsonFile[]) => {
        setEditFiles(files || []);
    }, []);

    const handleCancelFiles = useCallback(() => {
        setEditFiles(undefined);
    }, []);

    const handleSaveFiles = useCallback((values: JsonFile[] | undefined) => {
        setFieldValue('settings.attachments', values);
        setEditFiles(undefined);
    }, [setFieldValue]);

    return <Box style={{color: 'var(--color-warning)'}} className={'event-action-errors file-list'}>{(files?.length || 0) > 0
        ? <>
            {files?.map((a, i) => {
                return <span key={i}>{renderFile(a)}</span>
            })}
            <Button size={'small'} color={'warning'} onClick={() => {
                handleSaveFiles(undefined);
            }}><DeleteForeverRounded/> <span>{t('Zrušit')}</span></Button>
        </>
        : <>
            <Button size={'small'} color={'secondary'} sx={{float: 'right'}} onClick={() => {
                handleEditFiles(files);
            }}><AttachFileRounded/> <span>{t('Přiložit soubor')}</span></Button>
        </>}
        {!!editFiles && <FileModal item={{files: []}}
			title={t('Upravit přílohy skupiny')}
			info={<Grid item xs={12}>
                <p dangerouslySetInnerHTML={{__html: t('Soubory by měly být co nejmenší a ideálně obrázky nebo PDF (riziko pádu do spamu).')}}></p>
            </Grid>}
			onFileUploaded={handleSaveFiles}
			onCancel={handleCancelFiles}
		/>}
    </Box>
}

interface Props extends FormProps<JsonEventGroup> {
}

const EventGroupForm = (props: Props) => {
    const {item, onSave} = props;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [preview, setPreview] = useState<JsonEmailMessage | undefined>(undefined);

    const handlePreview = useCallback(async (text: any, recipients?: string[], attachments?: JsonFile[]) => {
        const email = getApiResult<JsonEmailMessage>(await dispatch(previewNotification({
            request: {
                invite: {
                    eventId: item.eventId,
                    inviteData: {
                        bodyBottom: text,
                        attachments
                    },
                },
                recipients: recipients || ['prijemce@email.cz']
            },
            notificationType: PreviewNotificationUsingPOSTNotificationTypeEnum.EventGroupOrg
        })));
        setPreview(email);
        return email;

    }, [dispatch, item.eventId]);

    const handleSave = useCallback(async (item: JsonEventGroup) => {
        if (!!item.sendEmail && !!item.groupId) {
            const eventParties = getApiResult<JsonEventPartyInfo[]>(await dispatch(fetchEventParties({
                eventId: item.eventId,
                groupIds: [item.groupId],
                statuses: [GetEventPartyListUsingGETStatusesEnum.Active]
            })));

            if (!eventParties?.length) {
                await modal.info({
                    title: t('Odeslání emailu'),
                    message: t('Neexistují žádní aktivní členové této skupiny.'),
                    confirmColor: 'info',
                    confirmText: 'Rozumím',
                })
                return;
            }

            const recipients = eventParties.filter(ep => !!ep.email).map(ep => ep.email || '');

            if (!recipients.length) {
                await modal.info({
                    title: t('Odeslání emailu'),
                    message: t('Neexistují žádní aktivní členové této skupiny s vyplněnou emailovou adresou.'),
                    confirmColor: 'info',
                    confirmText: 'Rozumím',
                })
                return;
            }

            const email = await handlePreview(item.settings?.infoText, recipients, item.settings?.attachments);

            const result = await modal.confirm({
                title: t('Potvrzení odeslání emailu'),
                message: <div>
                    <p>{t('Skutečně odeslat emailovou notifikaci na všechny aktivní členy skupiny?')}</p>
                    <EmailPreview email={email} showRecipient={true}/>
                </div>,
                cancelText: 'Zpět',
                confirmColor: 'success',
                confirmText: t('Uložit a odeslat email na všechny'),
                confirmIcon: <SendRounded/>,
            } as ModalProps);
            if (result !== 'CONFIRM') {
                return;
            }
        }
        if (onSave) {
            return onSave(item);
        }

    }, [t, onSave, handlePreview, modal, dispatch]);

    useEffect(() => {
        handlePreview(item.settings?.infoText || '').then();
    }, [item.settings?.infoText, handlePreview]);

    const validate = useCallback((values: JsonEventGroup) => {
        const errors = {} as FormikErrors<JsonEventGroup>;
        // if (values.vipSold && !isNumeric(values.vipSold, true)) {
        //     errors.vipSold = t('Zadejte prosím celé číslo')
        // }
        // if (values.voucherZ && !isNumeric(values.voucherZ, true)) {
        //     errors.voucherZ = t('Zadejte prosím celé číslo')
        // }
        // if (values.voucherR && !isNumeric(values.voucherR, true)) {
        //     errors.voucherR = t('Zadejte prosím celé číslo')
        // }
        return errors;
    }, []);

    const actions = useCallback(({isSubmitting}: FormikProps<JsonEventGroup>, props: FormProps<JsonEventGroup>) => {
        return <>
            <Grid item>
                <CheckboxField name={'sendEmail'} color={'default'} label={t('Poslat mail na všechny aktuální členy')}/>
            </Grid>
            {props.onCancel && <Grid item>
				<Button variant="text" onClick={props.onCancel}>{props.cancelButtonTitle || (props.isReadonly ? t('Zavřít') : t('Storno'))}</Button>
			</Grid>}
            <Grid item>
                <Button variant="contained" type="submit" color={'success'} disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={20}/> : null}
                    <span>{t('Uložit')}</span>
                </Button>
            </Grid>
        </>
    }, [t]);

    return (
        <FormContainer {...props} validate={validate} onSave={handleSave} actions={actions} children={<>
            <Grid item lg={6} md={6}>
                <Box component={'div'} className='html-preview'
                    dangerouslySetInnerHTML={{__html: preview?.body?.replaceAll('href=', 'target="_blank" href=') || ''}}/>
            </Grid>
            <Grid item lg={6} md={6}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                        <TextFormField name="settings.infoText" label={t('Organizační informace')} type={'textarea'} minRows={25} maxRows={25} maxlength={100_000}
                            onChange={handlePreview}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Attachments/>
                    </Grid>
                </Grid>
            </Grid>
        </>}/>
    );
}

export default EventGroupForm;
