import {ButtonGroupField} from "./form/ButtonGroupField";
import * as React from "react";
import {useMemo} from "react";
import {useAppSelector} from "../store";
import {CodebookState} from "../store/codebooks";
import {createOptions, OptionValue} from "../model/form";
import {selectCodebooks} from "../store/selectors";

type Props = {
    name: string,
    label?: string,
    eventId?: number,
}

export const EventDaysField = (props: Props) => {
    const {eventId, name, label} = props;

    const codebooks = useAppSelector<CodebookState>(selectCodebooks);

    const options: OptionValue[] = useMemo(() => {
        if (!codebooks) {
            return [];
        }
        return createOptions({codebookName: 'eventDay', scope: eventId, asNumber: true, sortByKeys: true}, codebooks)
            .map((o) => ({...o, label: o.tooltip || o.label, tooltip: o.tooltip + ' (#' + o.label + ')'}))
    }, [codebooks, eventId]);

    if (!eventId || !codebooks['eventDay']) {
        return null;
    }

    return <ButtonGroupField name={name} label={label} options={options} isMulti fullWidth maxPerRow={7} />;
}
