/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonEventPartySeating,
    JsonEventPartySeatingFromJSON,
    JsonEventPartySeatingFromJSONTyped,
    JsonEventPartySeatingToJSON,
} from './JsonEventPartySeating';

/**
 * 
 * @export
 * @interface JsonEventTicket
 */
export interface JsonEventTicket {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEventTicket
     */
    allowedActions?: Array<JsonEventTicketAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    areas?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    barcode?: string;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEventTicket
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    contingentOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventTicket
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    eventDays?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventTicket
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    formatCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    reason?: string;
    /**
     * 
     * @type {JsonEventPartySeating}
     * @memberof JsonEventTicket
     */
    seating?: JsonEventPartySeating;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    status?: JsonEventTicketStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonEventTicket
     */
    ticketId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    ticketType?: JsonEventTicketTicketTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEventTicket
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEventTicket
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEventTicketAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEventTicketStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}/**
* @export
* @enum {string}
*/
export enum JsonEventTicketTicketTypeEnum {
    SiwiVoucher = 'SIWI_VOUCHER',
    EgTicket = 'EG_TICKET'
}

export function JsonEventTicketFromJSON(json: any): JsonEventTicket {
    return JsonEventTicketFromJSONTyped(json, false);
}

export function JsonEventTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEventTicket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'areas': !exists(json, 'areas') ? undefined : json['areas'],
        'barcode': !exists(json, 'barcode') ? undefined : json['barcode'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'contingentOwner': !exists(json, 'contingentOwner') ? undefined : json['contingentOwner'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'eventDays': !exists(json, 'eventDays') ? undefined : json['eventDays'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'formatCode': !exists(json, 'formatCode') ? undefined : json['formatCode'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'seating': !exists(json, 'seating') ? undefined : JsonEventPartySeatingFromJSON(json['seating']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'ticketType': !exists(json, 'ticketType') ? undefined : json['ticketType'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEventTicketToJSON(value?: JsonEventTicket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'areas': value.areas,
        'barcode': value.barcode,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyName': value.companyName,
        'contingentOwner': value.contingentOwner,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'eventDays': value.eventDays,
        'eventId': value.eventId,
        'firstName': value.firstName,
        'formatCode': value.formatCode,
        'lastName': value.lastName,
        'reason': value.reason,
        'seating': JsonEventPartySeatingToJSON(value.seating),
        'status': value.status,
        'ticketId': value.ticketId,
        'ticketType': value.ticketType,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

