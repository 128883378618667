/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEvent,
    ApiResultOfJsonEventFromJSON,
    ApiResultOfJsonEventToJSON,
    ApiResultOfJsonEventInfo,
    ApiResultOfJsonEventInfoFromJSON,
    ApiResultOfJsonEventInfoToJSON,
    ApiResultOfListOfJsonEventInfo,
    ApiResultOfListOfJsonEventInfoFromJSON,
    ApiResultOfListOfJsonEventInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEvent,
    JsonEventFromJSON,
    JsonEventToJSON,
} from '../models';

export interface GetEventCountUsingGETRequest {
    statuses?: Array<GetEventCountUsingGETStatusesEnum>;
    search?: string;
}

export interface GetEventInfoUsingGETRequest {
    eventId: number;
}

export interface GetEventListUsingGETRequest {
    statuses?: Array<GetEventListUsingGETStatusesEnum>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEventUsingGETRequest {
    eventId: number;
}

export interface SaveEventUsingPOSTRequest {
    json: JsonEvent;
    testOnly?: boolean;
}

/**
 * 
 */
export class EventControllerApi extends runtime.BaseAPI {

    /**
     * getEventCount
     */
    async getEventCountUsingGETRaw(requestParameters: GetEventCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEventCount
     */
    async getEventCountUsingGET(requestParameters: GetEventCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEventCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventInfo
     */
    async getEventInfoUsingGETRaw(requestParameters: GetEventInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEventInfo>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling getEventInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event/info/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventInfoFromJSON(jsonValue));
    }

    /**
     * getEventInfo
     */
    async getEventInfoUsingGET(requestParameters: GetEventInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEventInfo> {
        const response = await this.getEventInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEventList
     */
    async getEventListUsingGETRaw(requestParameters: GetEventListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEventInfo>> {
        const queryParameters: any = {};

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEventInfoFromJSON(jsonValue));
    }

    /**
     * getEventList
     */
    async getEventListUsingGET(requestParameters: GetEventListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEventInfo> {
        const response = await this.getEventListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEvent
     */
    async getEventUsingGETRaw(requestParameters: GetEventUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEvent>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling getEventUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/event/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFromJSON(jsonValue));
    }

    /**
     * getEvent
     */
    async getEventUsingGET(requestParameters: GetEventUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEvent> {
        const response = await this.getEventUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEvent
     */
    async saveEventUsingPOSTRaw(requestParameters: SaveEventUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEvent>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEventUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/event`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEventToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEventFromJSON(jsonValue));
    }

    /**
     * saveEvent
     */
    async saveEventUsingPOST(requestParameters: SaveEventUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEvent> {
        const response = await this.saveEventUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetEventCountUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}
/**
    * @export
    * @enum {string}
    */
export enum GetEventListUsingGETStatusesEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}
