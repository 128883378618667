/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiReport
 */
export interface ApiReport {
    /**
     * 
     * @type {number}
     * @memberof ApiReport
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiReport
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiReport
     */
    errors?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiReport
     */
    removed?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiReport
     */
    skipped?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiReport
     */
    updated?: number;
}

export function ApiReportFromJSON(json: any): ApiReport {
    return ApiReportFromJSONTyped(json, false);
}

export function ApiReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : json['created'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'removed': !exists(json, 'removed') ? undefined : json['removed'],
        'skipped': !exists(json, 'skipped') ? undefined : json['skipped'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
    };
}

export function ApiReportToJSON(value?: ApiReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created,
        'error': value.error,
        'errors': value.errors,
        'removed': value.removed,
        'skipped': value.skipped,
        'updated': value.updated,
    };
}

