import * as React from 'react';
import {PropsWithChildren, useCallback} from 'react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import {useNavigate} from 'react-router';
import {Button, Typography} from "@mui/material";
import {ButtonTypeMap} from "@mui/material/Button/Button";

type PageButton = {
    title?: string,
    icon?: JSX.Element,
    color?: ButtonTypeMap['props']['color'],
    action: () => any,
    tooltip?: string,
}

interface Props {
    title: string;
    subPages?: PageButton[];
    buttons?: PageButton[];
    showBack?: boolean;
}

const PageHeader = (props: PropsWithChildren<Props>) => {
    const {title, showBack, subPages, buttons, children} = props;
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate])

    return (
        <div className={'page-header-container'}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {showBack && <ArrowBackIosOutlinedIcon onClick={goBack} sx={{marginRight: '10px', cursor: 'pointer', color: 'white'}}/>}
                <Typography component="h2" variant={'h2'}>{title}</Typography>
            </div>
            {!!subPages && <div className={'page-header-sub-pages'}>{subPages.map((b, i) => {
                return <Button key={i} variant={'text'} size={'small'} color={b.color || 'secondary'} title={b.tooltip}
                    onClick={b.action}>{b.icon} {!!b.title && <span>{b.title}</span>}</Button>
            })}</div>}
            <div>{children}</div>
            {!!buttons && <div>{buttons.map((b, i) => {
                return <Button key={i} variant={'text'} size={'small'} color={b.color || 'primary'} title={b.tooltip}
                    onClick={b.action}>{b.icon} {!!b.title && <span>{b.title}</span>}</Button>
            })}</div>}
        </div>
    );
}

export default PageHeader;
