import {createCol, DataGrid, DataGridCol, DataGridFilter, DataGridMode, DataGridState, defaultDataGridFilterState} from "../DataGrid";
import {GetContingentListUsingGETRequest, JsonContingentInfo, JsonEventDayInfo} from "../../generated-api";
import {ItemsState, useAppDispatch, useAppSelector} from "../../store";
import {selectContingents, selectEventDays} from "../../store/selectors";
import * as React from "react";
import {useEffect, useMemo} from "react";
import {fetchContingents} from "../../store/contingents";
import {dateToGui} from "../../helpers/date";

interface ContingentMatrix {
    dayNo: number | undefined,
    dayDate: string | undefined,

    [key: string]: string | number | undefined
}

interface EventContingentsGridFilter extends DataGridFilter, GetContingentListUsingGETRequest {
}

interface EventContingentsListGridState extends DataGridState<ContingentMatrix, EventContingentsGridFilter> {
}

const defaultState: EventContingentsListGridState = {
    filter: {
        ...defaultDataGridFilterState,
        orderCol: 'dayNo, owner, zone'
    }
};

const EventContingentsList = (props: { eventId: number, maxHeight?: string }) => {
    const {eventId, maxHeight} = props;
    const dispatch = useAppDispatch();
    const eventContingents = useAppSelector(selectContingents);
    const eventDays = useAppSelector(selectEventDays);

    useEffect(() => {
        dispatch(fetchContingents({eventId, orderCol: defaultState.filter.orderCol}));
    }, [dispatch, eventId]);


    const cols: DataGridCol<ContingentMatrix>[] = useMemo(() => {
        const cols: DataGridCol<ContingentMatrix>[] = [
            createCol('D', 'dayNo', 50, 'Den akce'),
            createCol('Datum', 'dayDate', 100, undefined, dateToGui),
        ];
        if (eventContingents.items.length > 0) {
            const done: string[] = [];
            eventContingents.items.forEach((c: JsonContingentInfo) => {
                const k = c.owner + '_' + c.zone;
                if (done.indexOf(k) >= 0) {
                    return;
                }
                cols.push(
                    createCol(<span>{c.owner}<br/>{c.zone}</span>, c.contingentId + '_head', '100C', undefined, undefined, [
                        createCol('Ck', k + '_limit', '50C', c.owner + ' ' + c.zone + ' celkem'),
                        createCol('Zb', k + '_remainCnt', '50C', c.owner + ' ' + c.zone + ' zbývá')
                    ]));

                done.push(k);
            });
        }
        return cols;
    }, [eventContingents]);


    const itemsState: ItemsState<ContingentMatrix> = useMemo(() => {
        const itemsState: ItemsState<ContingentMatrix> = {
            loading: eventContingents.loading || eventDays.loading,
            count: 0,
            items: []
        }

        if (eventDays.items.length > 0) {
            eventDays.items.forEach((ed: JsonEventDayInfo) => {
                const item: ContingentMatrix = {
                    dayNo: ed.dayNo,
                    dayDate: ed.dayDate,
                };
                if (eventContingents.items.length > 0) {
                    eventContingents.items.filter((c: JsonContingentInfo) => c.dayNo === ed.dayNo).forEach((c: JsonContingentInfo) => {
                        const k = c.owner + '_' + c.zone;
                        item[k + '_head'] = c.limit;
                        item[k + '_limit'] = c.limit;
                        item[k + '_remainCnt'] = (c.limit || 0) - (c.partyCnt || 0);
                    });
                }
                itemsState.items.push(item);
            });
        }
        return itemsState
    }, [eventContingents, eventDays]);

    return <DataGrid
        cols={cols}
        defaultState={defaultState}
        itemsState={itemsState}
        mode={DataGridMode.CLIENT}
        tableProps={{maxHeight}}
    />
}

export default EventContingentsList;
