/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonInviteReplyExtra,
    ApiResultOfJsonInviteReplyExtraFromJSON,
    ApiResultOfJsonInviteReplyExtraToJSON,
    ApiResultOfJsonInviteReplyExtraInfo,
    ApiResultOfJsonInviteReplyExtraInfoFromJSON,
    ApiResultOfJsonInviteReplyExtraInfoToJSON,
    JsonInviteReplyExtra,
    JsonInviteReplyExtraFromJSON,
    JsonInviteReplyExtraToJSON,
} from '../models';

export interface GetInviteReplyExtraInfoUsingGETRequest {
    replyExtraId: number;
}

export interface GetInviteReplyExtraUsingGETRequest {
    replyExtraId: number;
}

export interface SaveInviteReplyExtraUsingPOSTRequest {
    json: JsonInviteReplyExtra;
    testOnly?: boolean;
}

/**
 * 
 */
export class InviteReplyExtraControllerApi extends runtime.BaseAPI {

    /**
     * getInviteReplyExtraInfo
     */
    async getInviteReplyExtraInfoUsingGETRaw(requestParameters: GetInviteReplyExtraInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteReplyExtraInfo>> {
        if (requestParameters.replyExtraId === null || requestParameters.replyExtraId === undefined) {
            throw new runtime.RequiredError('replyExtraId','Required parameter requestParameters.replyExtraId was null or undefined when calling getInviteReplyExtraInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite-reply-extra/info/{replyExtraId}`.replace(`{${"replyExtraId"}}`, encodeURIComponent(String(requestParameters.replyExtraId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteReplyExtraInfoFromJSON(jsonValue));
    }

    /**
     * getInviteReplyExtraInfo
     */
    async getInviteReplyExtraInfoUsingGET(requestParameters: GetInviteReplyExtraInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteReplyExtraInfo> {
        const response = await this.getInviteReplyExtraInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInviteReplyExtra
     */
    async getInviteReplyExtraUsingGETRaw(requestParameters: GetInviteReplyExtraUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteReplyExtra>> {
        if (requestParameters.replyExtraId === null || requestParameters.replyExtraId === undefined) {
            throw new runtime.RequiredError('replyExtraId','Required parameter requestParameters.replyExtraId was null or undefined when calling getInviteReplyExtraUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/invite-reply-extra/{replyExtraId}`.replace(`{${"replyExtraId"}}`, encodeURIComponent(String(requestParameters.replyExtraId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteReplyExtraFromJSON(jsonValue));
    }

    /**
     * getInviteReplyExtra
     */
    async getInviteReplyExtraUsingGET(requestParameters: GetInviteReplyExtraUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteReplyExtra> {
        const response = await this.getInviteReplyExtraUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveInviteReplyExtra
     */
    async saveInviteReplyExtraUsingPOSTRaw(requestParameters: SaveInviteReplyExtraUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonInviteReplyExtra>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveInviteReplyExtraUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/invite-reply-extra`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonInviteReplyExtraToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonInviteReplyExtraFromJSON(jsonValue));
    }

    /**
     * saveInviteReplyExtra
     */
    async saveInviteReplyExtraUsingPOST(requestParameters: SaveInviteReplyExtraUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonInviteReplyExtra> {
        const response = await this.saveInviteReplyExtraUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
