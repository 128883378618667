import {JsonEventPartyDayDetails, JsonPlaceFoodServicesEnum} from "../../generated-api";
import CodebookValue from "../CodebookValue";
import {Button, Chip, Tooltip} from "@mui/material";
import * as React from "react";
import {Fragment, useMemo} from "react";
import {useAppTranslation} from "../../services/i18n";
import {shorten} from "../../helpers/format";
import {foodServiceOptions, foodServiceProps} from "../../model/place";
import EventDay from "../seating/EventDay";
import {parkPlates} from "../../model/party";


type FoodDetail = {
    day: number,
    foodServices: { [key in JsonPlaceFoodServicesEnum]?: { placeId: number, count: number, used?: number } }
}

type ParkingDetail = {
    day: number,
    parkId?: number,
    parkCnt?: number,
    parkRec?: number,
    parkPlates?: string
}

const multiParkingStyle = {fontSize: '80%'}

const EventDayDetails = ({dd, eventId, filter, type, onEdit}: {
    dd: JsonEventPartyDayDetails[] | undefined,
    eventId: number | undefined,
    filter: { eventDay?: number } | undefined,
    type: 'food' | 'acm' | 'park',
    onEdit?: () => void
}) => {
    const filterDay = filter?.eventDay;

    const t = useAppTranslation();

    const [details, foodDetails, parkingDetails] = useMemo(() => {
        const details: { value: any, flag: number, eventDays: number[], count?: number, extra?: string }[] = [];
        const foodDetails: FoodDetail[] = [];
        const parkingDetails: ParkingDetail[] = [];
        if (dd && dd.length > 0) {
            dd.forEach((ed) => {
                if (!ed.dayNo || (filterDay && parseInt('' + filterDay, 10) !== ed.dayNo)) {
                    return;
                }
                switch (type) {
                    case 'food':
                        if (!ed.foodBrId && !ed.foodLuId && !ed.foodDiId && !ed.foodSnId) {
                            return;
                        }

                        const foodDetail: FoodDetail = {
                            day: ed.dayNo,
                            foodServices: {}
                        };
                        for (const k of Object.keys(foodServiceProps)) {
                            const serviceType = k as JsonPlaceFoodServicesEnum;
                            const foodProp = foodServiceProps[serviceType];
                            const placeId = ed[foodProp];
                            if (!placeId) {
                                continue;
                            }

                            foodDetail.foodServices[serviceType] = {
                                placeId,
                                count: Math.max(1, ed.foodCnt?.[serviceType] || 0),
                                used: ed.foodData?.log?.filter(l => l.serviceType as any === serviceType as any)?.length
                            }
                            const count = Math.max(1, ed.foodCnt?.[serviceType] || 0);
                            const f = details.find((d) => d.value === ed[foodProp] && d.count === count);
                            if (f) {
                                f.eventDays.push(ed.dayNo);
                            } else {
                                details.push({value: ed[foodProp], flag: 0, count: count, eventDays: [ed.dayNo]});
                            }
                        }
                        foodDetails.push(foodDetail);
                        break;

                    case 'acm':
                        if (!ed.acmId) {
                            return;
                        }

                        const a = details.find((d) => d.value === ed.acmId && d.count === ed.acmCnt);
                        if (a) {
                            a.eventDays.push(ed.dayNo);
                        } else {
                            details.push({value: ed.acmId, flag: 0, count: ed.acmCnt, eventDays: [ed.dayNo]});
                        }
                        break;

                    case 'park':
                        if (!(ed.parkFlag && ed.parkFlag > 0 && ed.parkId)) {
                            return;
                        }

                        const p = details.find((d) => d.value === ed.parkId && d.flag === ed.parkFlag && d.count === ed.parkCnt);
                        if (p) {
                            p.eventDays.push(ed.dayNo);
                            if (ed.parkPlates && (!p.extra || p.extra.indexOf(ed.parkPlates) < 0)) {
                                ed.parkPlates.split(',').filter(v => !!v?.trim()).forEach(plate => {
                                    if (!p.extra || p.extra.indexOf(plate) < 0) {
                                        p.extra = (p.extra ? p.extra + ', ' : '') + plate.trim();
                                    }
                                })
                            }
                        } else {
                            details.push({value: ed.parkId, flag: ed.parkFlag, count: ed.parkCnt, eventDays: [ed.dayNo], extra: ed.parkPlates});
                        }


                        const parkingDetail: ParkingDetail = {
                            ...ed,
                            day: ed.dayNo,
                        };
                        parkingDetails.push(parkingDetail);
                        break;
                }
            });
        }
        return [details,
            foodDetails.sort((a, b) => a.day > b.day ? 1 : -1),
            parkingDetails.sort((a, b) => a.day > b.day ? 1 : -1)];
    }, [dd, filterDay, type]);

    const content = useMemo(() => {
        if (type === 'food') {
            if (!foodDetails) {
                return null;
            }
            return <Tooltip enterDelay={500} enterNextDelay={500} title={<table style={{width: '300px', textAlign: 'center'}}>
                <thead>
                <tr>
                    <th>{t('Den')}</th>
                    {foodServiceOptions.map((o, i) => {
                        return <th key={i}>{o.tooltip}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                {foodDetails.map((fd, i) => {
                    return <tr key={i}>
                        <th><EventDay dayNo={fd.day} eventId={eventId}/></th>
                        {Object.keys(foodServiceProps).map((k) => {
                            const serviceType = k as JsonPlaceFoodServicesEnum;
                            const fs = fd.foodServices[serviceType];
                            return <td key={k} style={!!fs?.used ? {color: 'lightgreen'} : undefined}>{!!fs?.placeId && <><CodebookValue key={i} value={eventId + ':' + fs.placeId} name={'placeFood'}
								formatValue={shorten}/>
                                {(fs.count || 0) > 1 ? <span> ({fs.count}x)</span> : null}</>}</td>;
                        })}
                    </tr>;
                })}
                </tbody>
            </table>}><span>{foodDetails
                .map((fd) => Object.keys(fd.foodServices).map(k => fd.foodServices[k as JsonPlaceFoodServicesEnum]?.placeId))
                .reduce((a, b) => {
                    const merged = [...a, ...b].filter(id => !!id);
                    return merged.filter((id, i) => merged.indexOf(id) === i);
                }, [])
                .map((id, i) => {
                    return <span key={i}>{i > 0 && <span>, </span>}<CodebookValue key={i} value={eventId + ':' + id} name={'placeFood'} formatValue={shorten}/></span>
                })}</span></Tooltip>;

        } else if (type === 'park') {
            const effStyle = details.length > 1 ? multiParkingStyle : undefined;

            const content = !!parkingDetails.length && <Tooltip enterDelay={500} enterNextDelay={500}
				className={'park-details'}
				PopperProps={{sx: {'& .MuiTooltip-tooltip': {maxWidth: 'clamp(300px, 600px, 80vw)'}}}}
				title={<table style={{minWidth: '300px', textAlign: 'center'}}>
                    <thead>
                    <tr>
                        <th>{t('Den')}</th>
                        <th>Parkování</th>
                        <th>Počet</th>
                        <th>Vydáno</th>
                        <th>SPZ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {parkingDetails.map((pd, i) => {
                        return <tr key={i}>
                            <th><EventDay dayNo={pd.day} eventId={eventId}/></th>
                            <td><CodebookValue value={eventId + ':' + pd.parkId} name={'placePark'}/></td>
                            <td>{pd.parkCnt || 1}</td>
                            <td style={!!pd.parkRec
                                ? (pd.parkRec >= (pd.parkCnt || 1)
                                    ? {color: 'lightgreen'}
                                    : {color: 'yellow'})
                                : undefined
                            }>{pd.parkRec || null}</td>
                            <td className={'park-plates'}>{parkPlates(pd.parkPlates)}</td>
                        </tr>;
                    })}
                    </tbody>
                </table>}>
				<div>{details?.map((detail, i) => {
                    let v = detail.value;
                    return <span key={i} style={effStyle}><CodebookValue value={eventId + ':' + v} name={'placePark'}
                        formatValue={shorten}/>{detail.count && detail.count > 1 ? ' (' + detail.count + ')' : ''}</span>;
                })}</div>
			</Tooltip>;

            if (!!onEdit) {
                if (!!content) {
                    return <Button variant={'text'} onClick={onEdit} color={'inherit'}>
                        {content}
                    </Button>;
                }
                return <div className={'party-tags contract-status'}><Chip key={'quick'} size={'small'} className={'party-tags-add'}
                    label={'+'} title={t('Kliknutím přidat parkování / SPZ')}
                    onClick={onEdit}
                /></div>
            }
            return content;

        } else if (details) {
            const content = details?.map((detail, i) => {
                let v = detail.value;
                let codebook = null;
                switch (type) {
                    case 'acm':
                        codebook = 'placeAcm';
                        break;
                }
                return <Tooltip enterDelay={500} enterNextDelay={500} key={i} title={<p>
                    {codebook ? <CodebookValue value={eventId + ':' + v} name={codebook}/> : v}{detail.count && detail.count > 1 ? ' ' + detail.count + 'x' : ''}<br/>
                    {t('Dny')}: {detail.eventDays.join(', ')}
                    {!!detail.extra ? <div>{detail.extra}</div> : null}
                </p>}><span>{codebook ?
                    <CodebookValue value={eventId + ':' + v} name={codebook} formatValue={shorten}/> : v}{detail.count && detail.count > 1 ? ' (' + detail.count + ')' : ''}</span></Tooltip>
            });
            return content;
        }
    }, [details, foodDetails, parkingDetails, type, onEdit, eventId, t]);

    return <div className={'event-details event-details-' + type}>
        {content}
    </div>;
}

export default EventDayDetails;
