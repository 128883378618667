import FormModal from "../components/form/FormModal";
import {JsonFindPartyRequestSexEnum, JsonFindPartyResponse, JsonParty, JsonPartyInfo, JsonPartyPartyTypeEnum, JsonPartyStatusEnum} from "../generated-api";
import {useAppTranslation} from "../services/i18n";
import PartyForm, {PartyFormProps} from "../components/party/PartyForm";
import * as React from "react";
import {useCallback} from "react";
import {addApiResultMessage, ApiChangeType, getApiResult} from "../helpers/api";
import {findParties, saveParty} from "../store/parties";
import {Grid} from "@mui/material";
import {FoundParty} from "../components/party/FoundParty";
import {DoneRounded} from "@mui/icons-material";
import {ModalProps, useModal} from "../services/modal";
import {partyName, sortFoundParties} from "../model/party";
import {useAppDispatch} from "../store";

interface PartyModalProps extends PartyFormProps {
}

const PartyModal = (props: PartyModalProps) => {

    const {type, item, onSave, partyInfo} = props;
    const {partyId, partyType} = item;
    const photoGuid = partyInfo?.photoGuid;

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const handleSaveParty = useCallback(async (party: JsonParty, isDelete?: boolean) => {
        const isNew = !party.partyId;
        if (isNew || (!photoGuid && !!party.photoGuid)) {
            const findRes = getApiResult<JsonFindPartyResponse[]>(await dispatch(findParties({
                exactOnly: true,
                requests: [{
                    queryId: "1",
                    firstName: party.firstName,
                    lastName: isNew ? party.lastName : undefined,
                    sex: party.sex ? JsonFindPartyRequestSexEnum[party.sex] : undefined,
                    photoGuid: party.photoGuid
                }]
            })))
            if (findRes?.length && findRes?.length > 0) {
                const result = await modal.confirm({
                    title: isNew ? t('Potvrzení založení nové osoby') : t('Potvrzení aktualizace fotografie'),
                    message: <div>
                        <p>{t('Dle zadaných údajů byly nalezeny následující možné shody')}:</p>
                        <Grid container spacing={1}>
                            {sortFoundParties(findRes).map((fp, i) => {
                                return <FoundParty key={i}
                                    maxWidth={100}
                                    maxHeight={180}
                                    sourceParty={party as JsonPartyInfo}
                                    foundPartyResponse={fp}
                                />
                            })}
                        </Grid>
                    </div>,
                    confirmColor: 'success',
                    confirmIcon: <DoneRounded/>,
                    confirmText: isNew ? t('Přesto novou osobu založit') : t('Přesto fotografii použít')
                } as ModalProps);
                if (result !== 'CONFIRM') {
                    return;
                }
            }
        }
        const res = await dispatch(saveParty({...party, status: isDelete ? JsonPartyStatusEnum.Deleted : party.status}));
        const item = getApiResult<JsonParty>(res);
        if (item) {
            addApiResultMessage(res, {
                [ApiChangeType.NO_CHANGE]: ['Osoba {{title}} ponechána beze změn', partyName],
                [ApiChangeType.UPDATED]: [isDelete ? 'Osoba {{title}} úspěšně odstraněna' : 'Osoba {{title}} úspěšně upravena', partyName],
                [ApiChangeType.CREATED]: ['Osoba {{title}} úspěšně vytvořena', partyName]
            }, t, dispatch);

            if (onSave) {
                onSave(item);
            }
        }

    }, [photoGuid, onSave, dispatch, modal, t])

    let title;
    if (partyId) {
        if (type === 'contact') {
            title = t('Úprava kontaktních údajů');
        } else if (partyType === JsonPartyPartyTypeEnum.T) {
            title = t('Úprava technické osoby');
        } else {
            title = t('Úprava osoby');
        }
    } else {
        if (partyType === JsonPartyPartyTypeEnum.T) {
            title = t('Nová technická osoba');
        } else {
            title = t('Nová osoba');
        }
    }

    return (
        <FormModal title={title} {...props}>
            <PartyForm {...props} onSave={handleSaveParty}></PartyForm>
        </FormModal>
    );
}

export default PartyModal;
