import {createTheme, GlobalStyles, LinearProgress, Theme, ThemeProvider} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useAppTranslation} from '../services/i18n';
import {useAppDispatch, useAppSelector} from '../store';
import {fetchAuthUser} from '../store/auth';
import {fetchCodebooks, initStaticCodebooks, LocalCodebookItem} from '../store/codebooks';
import {AppRouter, RsvpRouter, PublicAppRouter} from './AppRouter';
import {isRsvp, isPublic} from './config';
import {selectAuthIsLogged} from "../store/selectors";

// https://color.adobe.com/cs/create/color-wheel
const theme = createTheme({
    typography: {
        // "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
        "fontFamily": `"Helvetica", "Arial", sans-serif`,
    },
    palette: {
        primary: {
            main: '#004494',
            contrastText: '#fff'
        },
        secondary: {
            main: '#1675E0',
            contrastText: '#fff'
        },
        text: {
            primary: '#333',
            secondary: '#808080'
        },
        // action: {
        //     hover: '#00449411'
        // }
    }
});

const setGlobalStyles = (theme: Theme) => (
    <GlobalStyles
        styles={{
            ':root': {
                '--color-primary': theme.palette.primary.main,
                '--color-primary-text-contrast': theme.palette.primary.contrastText,
                '--color-secondary': theme.palette.secondary.main,
                '--color-secondary-text-contrast': theme.palette.secondary.contrastText,
                '--color-text-primary': theme.palette.text.primary,
                '--color-text-secondary': theme.palette.text.secondary,
                '--color-action-selected': theme.palette.action.selected,
                '--color-action-hover': theme.palette.action.hover,
                '--color-error': theme.palette.error.main,
                '--color-warning': theme.palette.warning.main,
                '--color-success': theme.palette.success.main,
                '--color-info': theme.palette.info.main
            },
        }}
    />
);

const App = () => {

    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const [loginChecked, setLoginChecked] = useState<boolean>(false);
    const isLogged = useAppSelector(selectAuthIsLogged);

    useEffect(() => {
        const enums = t('enums', {returnObjects: true});
        let initEnums = {} as { [name: string]: LocalCodebookItem[] };
        Object.keys(enums).forEach((item: string) => {
            initEnums[item] = [];
            Object.keys(enums[item as any]).forEach((value: string) => {
                initEnums[item].push({value, label: enums[item as any][value as any]});
            });
        });
        dispatch(initStaticCodebooks(initEnums));
        if (!isRsvp) {
            dispatch(fetchAuthUser())
                .then(() => setLoginChecked(true)); // try session login (eg. page refresh)
        }
    }, [dispatch, t]);

    useEffect(() => {
        if (isLogged) {
            dispatch(fetchCodebooks());
        }
    }, [dispatch, isLogged])

    // 	<React.StrictMode> renders app twice in dev!
    return (
        <ThemeProvider theme={theme}>
            {setGlobalStyles(theme)}
            {isRsvp
                ? <RsvpRouter/>
                : isPublic
                    ? <PublicAppRouter/>
                    : !loginChecked
                        ? <LinearProgress/>
                        : <AppRouter/>}
        </ThemeProvider>
    )
}

export default App;
