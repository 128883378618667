import {Box} from "@mui/material";
import * as React from "react";
import {useAppTranslation} from "../services/i18n";
import {JsonEmailMessage} from "../generated-api";
import {renderFile} from "../helpers/files";

type Props = {
    email?: JsonEmailMessage;
    showRecipient?: boolean;
}

export const EmailPreview = ({email, showRecipient}: Props) => {
    const t = useAppTranslation();

    return <div className={'email-header'}>
        <table>
            <tbody>
            <tr>
                <th style={{width: '15%'}}>{t('Od')}</th>
                <td style={{width: showRecipient ? '35%' : '85%'}}>{email?.mailFromTitle}</td>
                {showRecipient && <th style={{width: '10%'}}>{t('Komu')}</th>}
                {showRecipient && <td style={{width: '40%'}} title={email?.mailToTitles?.join(', ')}>{!!email?.mailToTitles && (email.mailToTitles.length > 1
                    ? <>{email.mailToTitles[0]} + {email.mailToTitles.length - 1} {t('další')}</>
                    : email.mailToTitles?.[0])}</td>}
            </tr>
            <tr>
                <th>{t('Předmět')}</th>
                <td colSpan={showRecipient ? 3 : undefined}>{email?.subject}</td>
            </tr>
            {!!email?.attachFileInfos?.length && email.attachFileInfos.length > 0 && <tr>
				<th>{t('Příloha')}</th>
				<td  colSpan={showRecipient ? 3 : undefined} className={'file-list'}>{email.attachFileInfos.map((a, i) => {
                    return <span key={i}>{renderFile(a)}</span>;
                })}</td>
			</tr>}
            </tbody>
        </table>
        <Box component={'div'} className='html-preview'
            dangerouslySetInnerHTML={{__html: email?.body?.replaceAll('href=', 'target="_blank" href=') || ''}}/>
    </div>
}