/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonPartyInfo,
    JsonPartyInfoFromJSON,
    JsonPartyInfoFromJSONTyped,
    JsonPartyInfoToJSON,
} from './JsonPartyInfo';

/**
 * 
 * @export
 * @interface JsonFindPartyResponse
 */
export interface JsonFindPartyResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonFindPartyResponse
     */
    matchTypes?: Array<JsonFindPartyResponseMatchTypesEnum>;
    /**
     * 
     * @type {JsonPartyInfo}
     * @memberof JsonFindPartyResponse
     */
    party?: JsonPartyInfo;
    /**
     * 
     * @type {number}
     * @memberof JsonFindPartyResponse
     */
    photoScore?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonFindPartyResponse
     */
    queryId?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonFindPartyResponseMatchTypesEnum {
    Photo = 'PHOTO',
    Name = 'NAME'
}

export function JsonFindPartyResponseFromJSON(json: any): JsonFindPartyResponse {
    return JsonFindPartyResponseFromJSONTyped(json, false);
}

export function JsonFindPartyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonFindPartyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matchTypes': !exists(json, 'matchTypes') ? undefined : json['matchTypes'],
        'party': !exists(json, 'party') ? undefined : JsonPartyInfoFromJSON(json['party']),
        'photoScore': !exists(json, 'photoScore') ? undefined : json['photoScore'],
        'queryId': !exists(json, 'queryId') ? undefined : json['queryId'],
    };
}

export function JsonFindPartyResponseToJSON(value?: JsonFindPartyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matchTypes': value.matchTypes,
        'party': JsonPartyInfoToJSON(value.party),
        'photoScore': value.photoScore,
        'queryId': value.queryId,
    };
}

