/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonCompanyInfo
 */
export interface JsonCompanyInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanyInfo
     */
    allowedActions?: Array<JsonCompanyInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    partyCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonCompanyInfoFromJSON(json: any): JsonCompanyInfo {
    return JsonCompanyInfoFromJSONTyped(json, false);
}

export function JsonCompanyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'partyCnt': !exists(json, 'partyCnt') ? undefined : json['partyCnt'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonCompanyInfoToJSON(value?: JsonCompanyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyId': value.companyId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'partyCnt': value.partyCnt,
        'reason': value.reason,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

