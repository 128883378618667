import {Box, Button, CircularProgress, Modal} from '@mui/material';
import React, {useState} from 'react';
import {Subject} from 'rxjs';
import {useAppTranslation} from "./i18n";
import {ArrowBack, CheckRounded} from "@mui/icons-material";
import {Color} from "../model/form";

interface Props {
}

export type ModalButtons = 'CONFIRM' | 'CANCEL';
export type ModalMode = 'INFO' | 'CONFIRM';

export type ModalProps = {
    title: string,
    message: string | JSX.Element,
    mode: ModalMode;
    confirmColor?: Color,
    confirmText?: string,
    confirmIcon?: JSX.Element,
    cancelText?: string,
    cancelIcon?: JSX.Element,
    syncAction?: () => any
}

export interface ModalState extends ModalProps {
    isOpen: boolean;
}

const style = {
    // fontFamily: 'ProximaNovaWebRegular',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 660,
    maxWidth: '100%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const {createContext, useContext} = React;

export const ModalContext = createContext<any>(null);

export const ModalProvider: React.FC<Props> = (props: any) => {

    // const button = new Subject<ModalButtons>();
    const t = useAppTranslation();

    const [modal, setModal] = useState({
        isOpen: false,
        mode: 'INFO',
        title: 'Informace',
        message: 'Potvrzovací informace',
        confirmColor: "primary"
    } as ModalState);
    const [button, setButton] = useState(new Subject<ModalButtons>());
    const [isCallingAction, setIsCallingAction] = useState(false);

    const open = (props: ModalProps) => {
        setModal({...props, isOpen: true});
    }

    const close = () => {
        setModal({...modal, isOpen: false});
    }

    const value = {
        info: (props: ModalProps) => {
            open({...props, mode: 'INFO'});
            const myButton = new Subject<ModalButtons>();
            setButton(myButton);
            return new Promise((resolve, reject) => {
                const subscription = myButton && myButton.subscribe((value: ModalButtons) => {
                    close();
                    subscription.unsubscribe();
                    resolve(value);
                });
            });
        },
        confirm: (props: ModalProps) => {
            open({...props, mode: 'CONFIRM'});
            const myButton = new Subject<ModalButtons>();
            setButton(myButton);
            return new Promise((resolve, reject) => {
                const subscription = myButton && myButton.subscribe(async (value: ModalButtons) => {
                    if (props.syncAction && value === 'CONFIRM') {
                        setIsCallingAction(true);
                        await props.syncAction();
                        setIsCallingAction(false);
                    }
                    close();
                    subscription.unsubscribe();
                    resolve(value);
                });
            });
        }
    };

    return (
        <>
            <ModalContext.Provider value={value}>
                {props.children}
            </ModalContext.Provider>

            <Modal
                open={modal.isOpen}
                onClose={(e, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        return;
                    }
                    button && button.next('CANCEL');
                }}
            >
                <Box sx={{...style}}>
                    <h2 style={{marginTop: '10px'}}>{modal.title}</h2>
                    <div style={{marginBottom: '20px', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>
                        {typeof modal.message === 'string' ? <p>{modal.message}</p> : modal.message}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {modal.mode === 'CONFIRM' &&
							<>
								<Button variant="contained" color="primary" sx={{width: 150, marginRight: 2}} disabled={isCallingAction} onClick={() => {
                                    button && button.next('CANCEL');
                                }}>
									<ArrowBack/>
									<span>{t(modal.cancelText || 'Zpět')}</span>
								</Button>
								<Button variant="contained" color={modal.confirmColor} disabled={isCallingAction} onClick={() => {
                                    button && button.next('CONFIRM');
                                }}>
                                    {isCallingAction ? <CircularProgress size={20}/> : (modal.confirmIcon || <CheckRounded/>)}
									<span>{t(modal.confirmText || 'Potvrdit')}</span>
								</Button>
							</>
                        }
                        {modal.mode === 'INFO' &&
							<>
								<Button variant="contained" color={modal.confirmColor} disabled={isCallingAction} onClick={() => {
                                    button && button.next('CONFIRM');
                                }}>
                                    {isCallingAction ? <CircularProgress size={20}/> : (modal.confirmIcon || <CheckRounded/>)}
									<span>{t(modal.confirmText || 'Rozumím')}</span>
								</Button>
							</>
                        }
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export const useModal = () => {
    return useContext(ModalContext);
}
