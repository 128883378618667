import FormModal from "../components/form/FormModal";
import {useAppTranslation} from "../services/i18n";
import EventForm, {EventFormProps} from "../components/event/EventForm";

const EventModal = (props: EventFormProps) => {

    const t = useAppTranslation();

    return (
        <FormModal title={props.mode === 'orgInfoText' ? t('Úprava společných informací') : (props.item?.eventId ? t('Úprava události') : t('Nová událost'))} {...props}>
            <EventForm {...props}></EventForm>
        </FormModal>
    );
}

export default EventModal;
